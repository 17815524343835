import React, { useState, useEffect } from 'react';
import { DateCalendar, LocalizationProvider } from '@mui/x-date-pickers';
import { TextField, Box, Button, Grid, Typography } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'; // Import the adapter
import dayjs from 'dayjs';
import axios from 'axios';
import { AVAILABILITY, AVAILABILITY_SLOTS } from '../../../api/booking';

const DateTimeSelection = ({ formData, setFormData }) => {
  const [availableDates, setAvailableDates] = useState([]);
  const [selectedDate, setSelectedDate] = useState(dayjs()); // Initialize with a valid Day.js object
  const [timeSlots, setTimeSlots] = useState([]);

  useEffect(() => {
    axios.get(AVAILABILITY)
      .then(res => {
        setAvailableDates(res.data);
      })
      .catch(err => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    if (!selectedDate) return;
    setTimeSlots([]);
    axios.get(`${AVAILABILITY_SLOTS}?date=${dayjs(selectedDate).format('YYYY-MM-DD')}`)
      .then(res => {
        setTimeSlots(res.data);
      })
      .catch(err => {
        console.error(err);
      });
  }, [selectedDate]);

  const handleSelectTimeSlot = (slot) => {
    setFormData({ ...formData, date: dayjs(selectedDate).format('YYYY-MM-DD'), timeSlot: slot });
  };

  return (
    <Box sx={{ marginTop: '40px'  , width: '100%'}}>
    <LocalizationProvider dateAdapter={AdapterDayjs} sx={{}}> {/* Wrap with LocalizationProvider */}
      <Box >
        <h3  style={{textAlign: 'center' , marginBottom: '20px'}}>Select Date and Time Slot</h3>
        <Box
          sx={{
            display: 'flex',
            flexDirection: {md: 'row' , xs: 'column'},
            gap: '10px',
            alignItems: {xs: 'center' , md: ''}
          }}
        >
          <DateCalendar
            value={selectedDate} // Ensure selectedDate is a valid Day.js object
            onChange={(date) => setSelectedDate(date)}
            minDate={dayjs().startOf('day')}
            renderInput={(params) => <TextField {...params} />}
          />
         <Grid container spacing={2} mt={2}  sx={{width: {md: '60%' , xs: '90%'} , alignItems: 'center' }}>
          {timeSlots && timeSlots.length > 0 ? timeSlots.map((slot) => (
            <Grid item xs={12} sm={6} md={4} key={slot._id}>
            <Button
        variant={formData.timeSlot === slot.time ? 'contained' : 'outlined'}
        onClick={() => handleSelectTimeSlot(slot.time)}
        disabled={slot.isBooked}
        fullWidth
        sx={{
          borderColor: formData.timeSlot === slot.time ? 'transparent' : '#6a58c6', // Transparent border for 'contained'
          backgroundColor: formData.timeSlot === slot.time ? '#6a58c6' : '#fff', // Set background for 'contained' and 'outlined'
          color: formData.timeSlot === slot.time ? '#fff' : '#6a58c6', // Text color changes
          '&:hover': {
            backgroundColor: formData.timeSlot === slot.time ? '#6a58c6' : '#f0f0f0', // Optional hover effect
            borderColor: formData.timeSlot === slot.time ? 'transparent' : '#6a58c6',
          },
        }}
      >
        {slot.time}
      </Button> 


            </Grid>
          )) : (
            <Box sx={{
              width: '100%',
              alignItems: 'center',
            }}>
            <Typography textAlign={'center'}>No Slots Are Open For This Date</Typography>
            </Box>
            
          )}
        </Grid>
        </Box>
      </Box>
    </LocalizationProvider>
    </Box>
  );
};

export default DateTimeSelection;
