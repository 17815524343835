import React, { useState, useEffect } from "react";
import axios from "axios";
import "../../stylesheets/myDeshboard.css";
import PropTypes from "prop-types";
import Loader from "./Loader"; // Import the loader component

import { isBrowser } from "react-device-detect";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import SchoolOutlinedIcon from "@mui/icons-material/SchoolOutlined";
import { Link } from "react-router-dom";
import eye from "./Images/eye.svg";
import check from "./Images/check.svg";
import course_1 from "./Images/mvp.png";
import course_2 from "./Images/gtm.png";
import course_3 from "./Images/bmc.png";
import RecordVoiceOverOutlinedIcon from "@mui/icons-material/RecordVoiceOverOutlined";
import AutoAwesomeOutlinedIcon from "@mui/icons-material/AutoAwesomeOutlined";
import EventNoteOutlinedIcon from "@mui/icons-material/EventNoteOutlined";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import DateRangeIcon from "@mui/icons-material/DateRange";
import ScheduleIcon from "@mui/icons-material/Schedule";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import ArrowBackIosOutlinedIcon from "@mui/icons-material/ArrowBackIosOutlined";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { NavLink , useNavigate } from "react-router-dom";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MailIcon from "@mui/icons-material/Mail";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import RefferalTable from "./referralTable";
import './Dashboard.css'
import blogPic from './Images/blog.png'
import caseStudyPic from './Images/case-study-3.png'
import streamingPic from './Images/streaming-4.png'
import { Button , useMediaQuery , Modal} from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import LinearProgressWithLabel from "../NewVirtualCampusStartup/Program/VideoPlayer/LinearProgressWithLabel";
import CardMembershipIcon from '@mui/icons-material/CardMembership';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

// import { bool } from "joi";

const drawerWidth = 250;
const MyDeshboard = ({ isAuth, setIsAuth }, props) => {
  const { USER} = require("../../api/vsc");
  const [data, setData] = useState({});
  useEffect(() => {
    axios
      .get(USER, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        // handle error
        console.error(error);
      });
  }, []);
  

  useEffect(() => {
  }, [data]);
  // const [userData, setUserData] = useState({}); // State to manage user data

  const [showComponent1, setShowComponent1] = useState(false); // State to manage Component1's visibility
  const [showComponent2, setShowComponent2] = useState(true); // State to manage Component2's visibility
  const [showComponent3, setShowComponent3] = useState(false); // State to manage Component3's visibility
  const [showComponent4, setShowComponent4] = useState(false);

  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  // Function to handle button click event
  const handleButtonClick1 = () => {
    setShowComponent1(true);
    setShowComponent2(false);
    setShowComponent3(false);
    setShowComponent4(false);
  };

  const handleButtonClick2 = () => {
    setShowComponent2(true);
    setShowComponent1(false);
    setShowComponent3(false);
    setShowComponent4(false);
  };

  const handleButtonClick3 = () => {
    setShowComponent3(true);
    setShowComponent2(false);
    setShowComponent1(false);
    setShowComponent4(false);
  };

  const handleButtonClick4 = () => {
    setShowComponent4(true);
    setShowComponent3(false);
    setShowComponent2(false);
    setShowComponent1(false);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
      <Toolbar
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          height: "120px",
        }}
      >
        <IconButton
          color="blue"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ mr: 2, display: { sm: "none" } }}
        >
          <ArrowBackIosOutlinedIcon />
        </IconButton>
      </Toolbar>

      <Divider />
      <List>
        <ListItem className="my_courses">
          <div onClick={handleButtonClick1} className="my_courses_name">
            <SchoolOutlinedIcon style={{ fontSize: "30px" }} />

            <div
              style={{
                fontFamily: "sans-serif",
                fontSize: "20px",
                fontWeight: "600",
                marginLeft: "10px",
              }}
            >
              My Courses
            </div>
          </div>
        </ListItem>
        <ListItem className="my_courses">
          <div onClick={handleButtonClick2} className="my_courses_name">
            <SchoolOutlinedIcon style={{ fontSize: "30px" }} />

            <div
              style={{
                fontFamily: "sans-serif",
                fontSize: "20px",
                fontWeight: "600",
                marginLeft: "10px",
              }}
            >
              All Courses
            </div>
          </div>
        </ListItem>
        <ListItem className="my_courses">
          <NavLink  to={`/watch_now`}>
            <div className="my_courses_name">
              <SchoolOutlinedIcon style={{ fontSize: "30px" }} />

              <div
                style={{
                  fontFamily: "sans-serif",
                  fontSize: "20px",
                  fontWeight: "600",
                  marginLeft: "10px",
                }}
              >
                Learning Streak
              </div>
            </div>
          </NavLink>
        </ListItem>
        <ListItem className="my_courses">
        <div onClick={handleButtonClick4} className="my_courses_name">
            <SchoolOutlinedIcon style={{ fontSize: "30px" }} />

            <div
              style={{
                fontFamily: "sans-serif",
                fontSize: "20px",
                fontWeight: "600",
                marginLeft: "10px",
              }}
            >
              Free Resources
            </div>
          </div>
        </ListItem>
        <ListItem className="my_courses">
          <div className="my_courses_name">
            <SchoolOutlinedIcon style={{ fontSize: "30px" }} />

            <div
              style={{
                fontFamily: "sans-serif",
                fontSize: "20px",
                fontWeight: "600",
                marginLeft: "10px",
              }}
            >
              Apply for Pitching at Markoknow
            </div>
          </div>
        </ListItem>
        <ListItem className="my_courses">
          <div onClick={handleButtonClick3} className="my_courses_name">
            <SchoolOutlinedIcon style={{ fontSize: "30px" }} />

            <div
              style={{
                fontFamily: "sans-serif",
                fontSize: "20px",
                fontWeight: "600",
                marginLeft: "10px",
              }}
            >
              Referral
            </div>
          </div>
        </ListItem>
        {/* <ListItem className="my_courses">
          <div className="my_courses_name">
            <SchoolOutlinedIcon style={{ fontSize: "30px" }} />

            <div
              style={{
                fontFamily: "sans-serif",
                fontSize: "20px",
                fontWeight: "600",
                marginLeft: "10px",
              }}
            >
              Contact Us
            </div>
          </div>
        </ListItem> */}

        <ListItem className="my_courses">
          <NavLink target="_blank" to={`https://markoknow.com/`}>
            <div className="my_courses_name">
              <SchoolOutlinedIcon style={{ fontSize: "30px" }} />

              <div
                style={{
                  fontFamily: "sans-serif",
                  fontSize: "20px",
                  fontWeight: "600",
                  marginLeft: "10px",
                }}
              >
                Visit Markoknow
              </div>
            </div>
          </NavLink>
        </ListItem>
      </List>
      <Divider />

      {/* <List>
        {["All mail", "Trash", "Spam"].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List> */}
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;
  return (
    <div className="main_myDeshboard">
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          style={{
            backgroundColor: "#ffffff",
            height: "120px",
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
          }}
          sx={{
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            ml: { sm: `${drawerWidth}px` },
          }}
        >
          <Toolbar>
            <IconButton
              color="blue"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: "none" } }}
            >
              <MenuIcon />
            </IconButton>
            {/* <Typography
              variant="h4"
              noWrap
              component="div"
              style={{
                color: "#7070E5",
                fontWeight: "700",
                fontFamily: "sans-serif",
              }}
            >
              My Deshboard
            </Typography> */}
          </Toolbar>
        </AppBar>
        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
        >
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Drawer
            style={{ width: "100%" }}
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: "100%",
                height: "100%",
                // border: "1px solid red",
              },
            }}
          >
            {drawer}
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: "none", sm: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
            open
          >
            {drawer}
          </Drawer>
        </Box>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 2,
            width: { sm: `calc(100% - ${drawerWidth}px)` },
          }}
        >
          <Toolbar />
          <Typography
            variant="h4"
            gutterBottom
            component="div"
            style={{
              color: "#7070E5",
              fontWeight: "700",
              fontFamily: "sans-serif",
              marginTop: "5rem",
            }}
          >
            {/* {userName == "null" ? (
              <span> Hi {userName2} !👋</span>
            ) : (
              <span> Hi {userName} !👋 </span>
            )} */}
            <span> Hi {data.name} !👋 </span>
          </Typography>
          {/* <Typography paragraph></Typography> */}
          {/* <Typography paragraph>
            Consequat mauris nunc congue nisi vitae suscipit. Fringilla est
            ullamcorper eget nulla facilisi etiam dignissim diam. Pulvinar
            elementum integer enim neque volutpat ac tincidunt. Ornare
            suspendisse sed nisi lacus sed viverra tellus. Purus sit amet
            volutpat consequat mauris. Elementum eu facilisis sed odio morbi.
            Euismod lacinia at quis risus sed vulputate odio. Morbi tincidunt
            ornare massa eget egestas purus viverra accumsan in. In hendrerit
            gravida rutrum quisque non tellus orci ac. Pellentesque nec nam
            aliquam sem et tortor. Habitant morbi tristique senectus et.
            Adipiscing elit duis tristique sollicitudin nibh sit. Ornare aenean
            euismod elementum nisi quis eleifend. Commodo viverra maecenas
            accumsan lacus vel facilisis. Nulla posuere sollicitudin aliquam
            ultrices sagittis orci a.
          </Typography> */}
          <div>
            {showComponent1 && (
              <>
                <Component1 />
              </>
            )}{" "}
            {/* Render Component1 conditionally based on visibility state */}
            {showComponent2 && (
              <>
                <Component2 isAuth={isAuth} />
              </>
            )}{" "}
            {/* Render Component2 conditionally based on visibility state */}
            {showComponent3 && (
              <>
                <Component3 />
              </>
            )}{" "}
            {/* Render Component3 conditionally based on visibility state */}
            {showComponent4 && (
              <>
              <Component4 />
              </>
            )}{" "}
          </div>
        </Box>
      </Box>
    </div>
  );
};

const Component1 = () => {
  const { USER,USER_ACCESS, GET_USER } = require("../../api/vsc");
  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(true); // Add a loading state
  const [paid , setPaid] = useState(false);
  const [course1P ,setCourse1P ] = useState(0);
  const [course2P ,setCourse2P ] = useState(0);
  const [course3P ,setCourse3P ] = useState(0);
  const isMobile = useMediaQuery('(max-width:820px)');
  const [openE, setOpenE] = React.useState(false);
  const handleOpen = () => setOpenE(true);
  const handleCloseE = () => setOpenE(false);
  const [activateDate , setActivateDate] = React.useState('');
  const [activateTime , setActivateTime] = React.useState('');
  const [cA , setCa] = React.useState(false);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: !isMobile?400:'100%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const navigate = useNavigate();
  useEffect(() => {
    axios
      .get(USER_ACCESS + window.localStorage.id)
      .then((res) => {
        setPaid(res.data.access);

      })
      .catch((err) => {});
      axios
      .get(GET_USER+window.localStorage.id+"/"+"64fb08d372d0fde41877df63")
      .then(res => {
         setCourse1P((res.data.course.completed/res.data.course.total)*100);
      })
      .catch(err => {
      })
      axios
      .get(GET_USER+window.localStorage.id+"/"+"64fb15b672d0fde41877e193")
      .then(res => {
         setCourse2P((res.data.course.completed/res.data.course.total)*100);
      })
      .catch(err => {
      })
      axios
      .get(GET_USER+window.localStorage.id+"/"+"64fb105b72d0fde41877e101")
      .then(res => {
         setCourse3P((res.data.course.completed/res.data.course.total)*100);
      })
      .catch(err => {
      })
  }, []);
  useEffect(() => {
    axios
      .get(USER, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setUserData(response.data);
        setLoading(false); // Set loading to false once data is fetched
        setCourse1P(((response.data.courseDetails.find(course => course.courseId === "64fb08d372d0fde41877df63").videosCompleted.length+response.data.courseDetails.find(course => course.courseId === "64fb08d372d0fde41877df63").pdfsCompleted.length)/response.data.courseDetails.find(course => course.courseId === "64fb08d372d0fde41877df63").total)*100);
        setCourse2P(((response.data.courseDetails.find(course => course.courseId === "64fb15b672d0fde41877e193").videosCompleted.length+response.data.courseDetails.find(course => course.courseId === "64fb15b672d0fde41877e193").pdfsCompleted.length)/response.data.courseDetails.find(course => course.courseId === "64fb15b672d0fde41877e193").total)*100);
        setCourse3P(((response.data.courseDetails.find(course => course.courseId === "64fb105b72d0fde41877e101").videosCompleted.length+response.data.courseDetails.find(course => course.courseId === "64fb105b72d0fde41877e101").pdfsCompleted.length)/response.data.courseDetails.find(course => course.courseId === "64fb105b72d0fde41877e101").total)*100);
        var currentDate = new Date();
        const course = response.data.certificateActivate.find(
          (course) => course.courseId === "64fb08d372d0fde41877df63"
        ).dateOfActivation;
        const activate = new Date(course)
        var array = activate.toLocaleString().split(',');
        setActivateDate(array[0]);
        setActivateTime(array[1]);
        if (currentDate > activate) {
          setCa(true);
        }
      })
      .catch((error) => {
        console.error(error);
        setLoading(false); // Set loading to false in case of error
      });
  }, []);

  useEffect(() => {
  }, [userData]);

  if (loading) {
    return <Loader />; // Render the loader component while loading
  }

  if (userData.course1 != 1 && userData.course2 != 2 && userData.course3 != 3) {
    return <h1>No courses added</h1>;
  }

  return (
    <>
      <div className="kickstart_courses_vsc">
        <h2>My Courses</h2>
        <div className="level_courses_vsc">
          {userData.course1 == 1 && (
            <div className="course_1_vsc">
              <img
                src={course_1}
                style={{
                  objectFit: "fill",
                  width: "100%",
                  height: "auto",
                  display: "block",
                  borderStyle: "none",
                  marginBottom: "20px",
                  borderRadius: "20px 20px 0px 0px",
                }}
              />
              <div className="course_data">
                <p
                  style={{
                    padding: "2px 10px",
                    backgroundColor: "#F2F6FF",
                    borderRadius: "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "233px",
                    height: "20px",
                    color: "#6E71CC",
                    textTransform: "uppercase",
                    fontFamily: "Open Sans",
                    fontSize: "12px",
                    lineHeight: "16px",
                    fontWeight: "600",
                  }}
                >
                  Undergrad Students or Grad
                </p>
                <p
                  style={{
                    color: "#3B3435",
                    fontSize: "1.125rem",
                    fontWeight: "600",
                    marginTop: "10px",
                    fontFamily: "Poppins",
                  }}
                >
                  MVP Building (Step 1 )
                </p>
                <p
                  style={{
                    color: "#21191B",
                    fontSize: "24px",
                    fontWeight: "700",
                    fontFamily: "Poppins",
                  }}
                >
                  MVP Building
                </p>
                <div
                  style={{
                    margin: "10px 0px 0px",
                    padding: "0px 10px",
                  }}
                >
                  <p
                    style={{
                      color: "#21191B",
                      marginBottom: "10px",
                      fontWeight: "600",
                    }}
                  >
                    <DateRangeIcon
                      style={{
                        color: "gray",
                        fontSize: "18px",
                        position: "relative",
                        top: "5px",
                        marginRight: "5px",
                        marginLeft: "-13px",
                      }}
                    />
                    Minimum Viable Product Building
                  </p>
                  <p
                    style={{
                      color: "#21191B",
                      marginBottom: "10px",
                      fontWeight: "600",
                    }}
                  >
                    <span>
                      <ScheduleIcon
                        style={{
                          color: "gray",
                          fontSize: "18px",
                          position: "relative",
                          top: "5px",
                          marginRight: "5px",
                          marginLeft: "-13px",
                        }}
                      />
                    </span>
                    2 Real Time Cases
                  </p>
                  <p
                    style={{
                      color: "#21191B",
                      marginBottom: "10px",
                      fontWeight: "600",
                    }}
                  >
                    <span>
                      <CurrencyRupeeIcon
                        style={{
                          color: "gray",
                          fontSize: "18px",
                          position: "relative",
                          top: "5px",
                          // marginRight: "5px",
                          marginLeft: "-13px",
                        }}
                      />
                    </span>{" "}
                    Outcome: Problem Selection, Product decision, Product
                    features, Idea Execution
                  </p>
                  <LinearProgressWithLabel value={course1P} color={"#000"}/>
                  <p style={{fontWeight:'900'}}>{Math.round(course1P)}% Completed</p>

                </div>
              </div>
              {paid&&<div className="course_1_button">
                <a href="/program/videoplayer/64fb08d372d0fde41877df63">
                  <Button variant="contained" style={{backgroundColor:'#6E71CC', color:'#fff'}}>Open</Button>
                </a></div>}

                
              
            </div>
          ) }
          {userData.course2 == 2 && (
            <div className="course_2_vsc">
              <img
                src={course_2}
                style={{
                  objectFit: "fill",
                  width: "100%",
                  height: "auto",
                  display: "block",
                  borderStyle: "none",
                  marginBottom: "20px",
                  borderRadius: "20px 20px 0px 0px",
                }}
              />
              <div className="course_data">
                <p
                  style={{
                    padding: "2px 10px",
                    backgroundColor: "#F2F6FF",
                    borderRadius: "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "233px",
                    height: "20px",
                    color: "#6E71CC",
                    textTransform: "uppercase",
                    fontFamily: "Open Sans",
                    fontSize: "12px",
                    lineHeight: "16px",
                    fontWeight: "600",
                  }}
                >
                  Undergrad Students or Grad
                </p>
                <p
                  style={{
                    color: "#3B3435",
                    fontSize: "1.125rem",
                    fontWeight: "600",
                    marginTop: "10px",
                    fontFamily: "Poppins",
                  }}
                >
                  GTM Strategy (Step 2)
                </p>
                <p
                  style={{
                    color: "#21191B",
                    fontSize: "24px",
                    fontWeight: "700",
                    fontFamily: "Poppins",
                  }}
                >
                  GTM Strategy
                </p>
                <div
                  style={{
                    margin: "10px 0px 0px",
                    padding: "0px 10px",
                  }}
                >
                  <p
                    style={{
                      color: "#21191B",
                      marginBottom: "10px",
                      fontWeight: "600",
                    }}
                  >
                    <DateRangeIcon
                      style={{
                        color: "gray",
                        fontSize: "18px",
                        position: "relative",
                        top: "5px",
                        marginRight: "5px",
                        marginLeft: "-13px",
                      }}
                    />
                    Go To Market Strategy
                  </p>
                  <p
                    style={{
                      color: "#21191B",
                      marginBottom: "10px",
                      fontWeight: "600",
                    }}
                  >
                    <span>
                      <ScheduleIcon
                        style={{
                          color: "gray",
                          fontSize: "18px",
                          position: "relative",
                          top: "5px",
                          marginRight: "5px",
                          marginLeft: "-13px",
                        }}
                      />
                    </span>
                    2 Real Time Cases
                  </p>
                  <p
                    style={{
                      color: "#21191B",
                      marginBottom: "10px",
                      fontWeight: "600",
                    }}
                  >
                    <span>
                      <CurrencyRupeeIcon
                        style={{
                          color: "gray",
                          fontSize: "18px",
                          position: "relative",
                          top: "5px",
                          // marginRight: "5px",
                          marginLeft: "-13px",
                        }}
                      />
                    </span>{" "}
                    Outcome: Deciding Target Audience,Planning Buyer's Journey,
                    Creating Process of Execution
                  </p>
                  <LinearProgressWithLabel value={course2P} color={"#000"}/>
                  <p style={{fontWeight:'900'}}>{Math.round(course2P)}% Completed</p>

                </div>
              </div>
              {paid&&<div className="course_2_button">
                <a href="/program/videoplayer/64fb15b672d0fde41877e193">
                <Button variant="contained" style={{backgroundColor:'#6E71CC', color:'#fff'}}>Open</Button>

                </a></div>}
            </div>
          )} 
          {userData.course3 == 3 && (
            <div className="course_3_vsc">
              <img
                src={course_3}
                style={{
                  objectFit: "fill",
                  width: "100%",
                  height: "auto",
                  display: "block",
                  borderStyle: "none",
                  marginBottom: "20px",
                  borderRadius: "20px 20px 0px 0px",
                }}
              />
              <div className="course_data">
                <p
                  style={{
                    padding: "2px 10px",
                    backgroundColor: "#F2F6FF",
                    borderRadius: "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "233px",
                    height: "20px",
                    color: "#6E71CC",
                    textTransform: "uppercase",
                    fontFamily: "Open Sans",
                    fontSize: "12px",
                    lineHeight: "16px",
                    fontWeight: "600",
                  }}
                >
                  Undergrad Students or Grad
                </p>
                <p
                  style={{
                    color: "#3B3435",
                    fontSize: "1.125rem",
                    fontWeight: "600",
                    marginTop: "10px",
                    fontFamily: "Poppins",
                  }}
                >
                  BMC Creation (Step 3)
                </p>
                <p
                  style={{
                    color: "#21191B",
                    fontSize: "24px",
                    fontWeight: "700",
                    fontFamily: "Poppins",
                  }}
                >
                  BMC Creation
                </p>
                <div
                  style={{
                    margin: "10px 0px 0px",
                    padding: "0px 10px",
                  }}
                >
                  <p
                    style={{
                      color: "#21191B",
                      marginBottom: "10px",
                      fontWeight: "600",
                    }}
                  >
                    <DateRangeIcon
                      style={{
                        color: "gray",
                        fontSize: "18px",
                        position: "relative",
                        top: "5px",
                        marginRight: "5px",
                        marginLeft: "-13px",
                      }}
                    />
                    Business Model Canvas Creation
                  </p>
                  <p
                    style={{
                      color: "#21191B",
                      marginBottom: "10px",
                      fontWeight: "600",
                    }}
                  >
                    <span>
                      <ScheduleIcon
                        style={{
                          color: "gray",
                          fontSize: "18px",
                          position: "relative",
                          top: "5px",
                          marginRight: "5px",
                          marginLeft: "-13px",
                        }}
                      />
                    </span>
                    7 Business Models
                  </p>
                  <p
                    style={{
                      color: "#21191B",
                      marginBottom: "10px",
                      fontWeight: "600",
                    }}
                  >
                    <span>
                      <CurrencyRupeeIcon
                        style={{
                          color: "gray",
                          fontSize: "18px",
                          position: "relative",
                          top: "5px",
                          marginRight: "5px",
                          marginLeft: "-13px",
                        }}
                      />
                    </span>
                    Outcome : Decision factor for business model, shifting
                    business models
                  </p>
                  <LinearProgressWithLabel value={course3P} color={"#000"}/>
                  <p style={{fontWeight:'900'}}>{Math.round(course3P)}% Completed</p>

                </div>
              </div>
              {paid&&<div className="course_3_button">
                <a href="/program/videoplayer/64fb105b72d0fde41877e101">
                <Button variant="contained" style={{backgroundColor:'#6E71CC', color:'#fff'}}>Open</Button>

                </a>
                <IconButton
                  onClick={() => {
                    if (course3P<100) setOpenE(true);

                    else navigate(`/certificate/64fb105b72d0fde41877e101`, {state:`Product To Model`});
                  }}
                  style={{marginLeft:'10px', background:'#6E71CC'}}><CardMembershipIcon/></IconButton></div>}
            </div>
          ) }
        </div>
        <Modal
        open={openE}
        onClose={handleCloseE}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} style={{width:isMobile?'70%':400}}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {!cA ?'Activate The Certicate' :'Complete The Course'}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          {!cA ? `The Certificate Would Be Activated After ${activateTime} on ${activateDate}`:'To Generate Your Certificate First Complete The Course.'}
          </Typography>
        </Box>
      </Modal>
      </div>
    </>
  );
};

const Component2 = ({isAuth}) => {
  const { MARKOKNOW_KICKSTART, USER , USER_ACCESS , GET_USER} = require("../../api/vsc");
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [course1, setCourse1] = useState("");
  const [course2, setCourse2] = useState("");
  const [course3, setCourse3] = useState("");
  const [paid , setPaid] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [course1P ,setCourse1P ] = useState(0);
  const [course2P ,setCourse2P ] = useState(0);
  const [course3P ,setCourse3P ] = useState(0);
  const [openE, setOpenE] = React.useState(false);
  const handleOpen = () => setOpenE(true);
  const handleCloseE = () => setOpenE(false);
  const isMobile = useMediaQuery('(max-width:820px)');
  const [activateDate , setActivateDate] = React.useState('');
  const [activateTime , setActivateTime] = React.useState('');
  const [cA , setCa] = React.useState(false);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: !isMobile?400:'100%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  useEffect(() => {
    axios
      .get(USER_ACCESS + window.localStorage.id)
      .then((res) => {
        setPaid(res.data.access);

      })
      .catch((err) => {});
      axios
      .get(GET_USER+window.localStorage.id+"/"+"64fb08d372d0fde41877df63")
      .then(res => {
         setCourse1P((res.data.course.completed/res.data.course.total)*100);
      })
      .catch(err => {
      })
      axios
      .get(GET_USER+window.localStorage.id+"/"+"64fb15b672d0fde41877e193")
      .then(res => {
         setCourse2P((res.data.course.completed/res.data.course.total)*100);
      })
      .catch(err => {
      })
      axios
      .get(GET_USER+window.localStorage.id+"/"+"64fb105b72d0fde41877e101")
      .then(res => {
         setCourse3P((res.data.course.completed/res.data.course.total)*100);
      })
      .catch(err => {
      })
  }, []);
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(USER, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setData(response.data);
        setCourse1(response.data.course1 || ""); // Set the initial state of course1
        setCourse2(response.data.course2 || ""); // Set the initial state of course2
        setCourse3(response.data.course3 || ""); // Set the initial state of course3
        setCourse1P(((response.data.courseDetails.find(course => course.courseId === "64fb08d372d0fde41877df63").videosCompleted.length+response.data.courseDetails.find(course => course.courseId === "64fb08d372d0fde41877df63").pdfsCompleted.length)/response.data.courseDetails.find(course => course.courseId === "64fb08d372d0fde41877df63").total)*100);
        setCourse2P(((response.data.courseDetails.find(course => course.courseId === "64fb15b672d0fde41877e193").videosCompleted.length+response.data.courseDetails.find(course => course.courseId === "64fb15b672d0fde41877e193").pdfsCompleted.length)/response.data.courseDetails.find(course => course.courseId === "64fb15b672d0fde41877e193").total)*100);
        setCourse3P(((response.data.courseDetails.find(course => course.courseId === "64fb105b72d0fde41877e101").videosCompleted.length+response.data.courseDetails.find(course => course.courseId === "64fb105b72d0fde41877e101").pdfsCompleted.length)/response.data.courseDetails.find(course => course.courseId === "64fb105b72d0fde41877e101").total)*100);
        var currentDate = new Date();
        const course = response.data.certificateActivate.find(
          (course) => course.courseId === "64fb08d372d0fde41877df63"
        ).dateOfActivation;
        const activate = new Date(course)
        var array = activate.toLocaleString().split(',');
        setActivateDate(array[0]);
        setActivateTime(array[1]);
        if (currentDate > activate) {
          setCa(true);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);
  useEffect(() => {
  }, [data]);
  const user = localStorage.getItem("email");
  const addCourse1 = () => {
    const newCourse1 = course1 === "1" ? "0" : "1";
    const newCourse2 = course2 === "2" ? "0" : "2"; 
    const newCourse3 = course3 === "3" ? "0" : "3"; 
    setCourse1(newCourse1);
    setCourse2(newCourse2); 
    setCourse3(newCourse3); 

    axios
      .post(MARKOKNOW_KICKSTART, {
        course1: newCourse1,
        course2 : newCourse2 ,
        course3 : newCourse3,
        user,
      })
      .then((response) => {setOpen(true);})
      .catch((err) => {
      });
  };
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  const addCourse2 = () => {
    const newCourse2 = course2 === "2" ? "0" : "2"; // Toggle the value between 0 and 2
    setCourse2(newCourse2); // Update the state with the new value
    axios
      .post(MARKOKNOW_KICKSTART, {
        course2: newCourse2, // Pass the new value in the request body
        course1,
        course3,
        user,
      })
      .then((response) => {})
      .catch((err) => {
      });
  };
  const addCourse3 = () => {
    const newCourse3 = course3 === "3" ? "0" : "3"; // Toggle the value between 0 and 3
    setCourse3(newCourse3); // Update the state with the new value
    axios
      .post(MARKOKNOW_KICKSTART, {
        course3: newCourse3, // Pass the new value in the request body
        course1,
        course2,
        user,
      })
      .then((response) => {})
      .catch((err) => {
      });
  };
  return (
    <>
      <div className="kickstart_courses_vsc">
        <h2 id="our-courses">All Courses</h2>
        <div className="level_courses_vsc">
          <div className="course_1_vsc">
            <img
              src={course_1}
              style={{
                objectFit: "fill",
                width: "100%",
                height: "auto",
                display: "block",
                borderStyle: "none",
                marginBottom: "20px",
                borderRadius: "20px 20px 0px 0px",
              }}
            />
            <div className="course_data">
              <p
                style={{
                  padding: "2px 10px",
                  backgroundColor: "#F2F6FF",
                  borderRadius: "10px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "233px",
                  height: "20px",
                  color: "#6E71CC",
                  textTransform: "uppercase",
                  fontFamily: "Open Sans",
                  fontSize: "12px",
                  lineHeight: "16px",
                  fontWeight: "600",
                }}
              >
                Undergrad Students or Grad
              </p>
              <p
                style={{
                  color: "#3B3435",
                  fontSize: "1.125rem",
                  fontWeight: "600",
                  marginTop: "10px",
                  fontFamily: "Poppins",
                }}
              >
                MVP Building (Step 1 )
              </p>
              <p
                style={{
                  color: "#21191B",
                  fontSize: "24px",
                  fontWeight: "700",
                  fontFamily: "Poppins",
                }}
              >
                MVP Building
              </p>
              <div
                style={{
                  margin: "10px 0px 0px",
                  padding: "0px 10px",
                }}
              >
                <p
                  style={{
                    color: "#21191B",
                    marginBottom: "10px",
                    fontWeight: "600",
                  }}
                >
                  <DateRangeIcon
                    style={{
                      color: "gray",
                      fontSize: "18px",
                      position: "relative",
                      top: "5px",
                      marginRight: "5px",
                      marginLeft: "-13px",
                    }}
                  />
                  Minimum Viable Product Building
                </p>
                <p
                  style={{
                    color: "#21191B",
                    marginBottom: "10px",
                    fontWeight: "600",
                  }}
                >
                  <span>
                    <ScheduleIcon
                      style={{
                        color: "gray",
                        fontSize: "18px",
                        position: "relative",
                        top: "5px",
                        marginRight: "5px",
                        marginLeft: "-13px",
                      }}
                    />
                  </span>
                  2 Real Time Cases
                </p>
                <p
                  style={{
                    color: "#21191B",
                    marginBottom: "10px",
                    fontWeight: "600",
                  }}
                >
                  <span>
                    <CurrencyRupeeIcon
                      style={{
                        color: "gray",
                        fontSize: "18px",
                        position: "relative",
                        top: "5px",
                        // marginRight: "5px",
                        marginLeft: "-13px",
                      }}
                    />
                  </span>{" "}
                  Outcome: Problem Selection, Product decision, Product
                  features, Idea Execution
                </p>
                <LinearProgressWithLabel value={course1P} color={"#000"}/>
                  <p style={{fontWeight:'900'}}>{Math.round(course1P)}% Completed</p>
                
              </div>
            </div>
            <div className="course_1_button">
            {
              paid ? (
                  <div className="course_1_button">
                <a href="/program/videoplayer/64fb08d372d0fde41877df63">
                  <Button variant="contained" style={{backgroundColor:'#6E71CC', color:'#fff'}}>Open</Button>
                </a></div>
        
              ): 
              isAuth ? (
                  <button
                    onClick={addCourse1}
                    style={{
                      backgroundColor:
                        course1 === "1"
                          ? "green"
                          : course1 === "0"
                          ? "#6a58c6"
                          : "initial",
                    }}
                  >
                    {course1 === "1"
                      ? "Course  Added"
                      : course1 === "0"
                      ? "Add Course "
                      : "Button Text"}
                  </button>
                ) : (
                  <button
                    onClick={(e) => {
                        navigate('/login' , {state:{redirect:'/courses'}});
                    }}
                    style={{
                      backgroundColor:"#6a58c6"
                        
                    }}
                  >
                    Read More
                  </button>
            
                )
            }
            {/* {isAuth ? (
                  <button
                    onClick={addCourse1}
                    style={{
                      backgroundColor:
                        course1 === "1"
                          ? "green"
                          : course1 === "0"
                          ? "#6a58c6"
                          : "initial",
                    }}
                  >
                    {course1 === "1"
                      ? "Course  Added"
                      : course1 === "0"
                      ? "Add Course "
                      : "Button Text"}
                  </button>
                ) : (
                  <button
                    onClick={(e) => {
                        navigate('/login' , {state:{redirect:'/Course_Landing'}});
                    }}
                    style={{
                      backgroundColor:"#6a58c6"
                        
                    }}
                  >
                    Read More
                  </button>
            
                )}
                {isAuth && paid  && (
                  <div className="course_1_button">
                <a href="/program/videoplayer/64fb08d372d0fde41877df63">
                  <Button variant="contained" style={{backgroundColor:'#6E71CC', color:'#fff'}}>Open</Button>
                </a></div>
                )} */}
            
            </div>
          </div>
          <div className="course_2_vsc">
            <img
              src={course_2}
              style={{
                objectFit: "fill",
                width: "100%",
                height: "auto",
                display: "block",
                borderStyle: "none",
                marginBottom: "20px",
                borderRadius: "20px 20px 0px 0px",
              }}
            />
            <div className="course_data">
              <p
                style={{
                  padding: "2px 10px",
                  backgroundColor: "#F2F6FF",
                  borderRadius: "10px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "233px",
                  height: "20px",
                  color: "#6E71CC",
                  textTransform: "uppercase",
                  fontFamily: "Open Sans",
                  fontSize: "12px",
                  lineHeight: "16px",
                  fontWeight: "600",
                }}
              >
                Undergrad Students or Grad
              </p>
              <p
                style={{
                  color: "#3B3435",
                  fontSize: "1.125rem",
                  fontWeight: "600",
                  marginTop: "10px",
                  fontFamily: "Poppins",
                }}
              >
                GTM Strategy (Step 2)
              </p>
              <p
                style={{
                  color: "#21191B",
                  fontSize: "24px",
                  fontWeight: "700",
                  fontFamily: "Poppins",
                }}
              >
                GTM Strategy
              </p>
              <div
                style={{
                  margin: "10px 0px 0px",
                  padding: "0px 10px",
                }}
              >
                <p
                  style={{
                    color: "#21191B",
                    marginBottom: "10px",
                    fontWeight: "600",
                  }}
                >
                  <DateRangeIcon
                    style={{
                      color: "gray",
                      fontSize: "18px",
                      position: "relative",
                      top: "5px",
                      marginRight: "5px",
                      marginLeft: "-13px",
                    }}
                  />
                  Go To Market Strategy
                </p>
                <p
                  style={{
                    color: "#21191B",
                    marginBottom: "10px",
                    fontWeight: "600",
                  }}
                >
                  <span>
                    <ScheduleIcon
                      style={{
                        color: "gray",
                        fontSize: "18px",
                        position: "relative",
                        top: "5px",
                        marginRight: "5px",
                        marginLeft: "-13px",
                      }}
                    />
                  </span>
                  2 Real Time Cases
                </p>
                <p
                  style={{
                    color: "#21191B",
                    marginBottom: "10px",
                    fontWeight: "600",
                  }}
                >
                  <span>
                    <CurrencyRupeeIcon
                      style={{
                        color: "gray",
                        fontSize: "18px",
                        position: "relative",
                        top: "5px",
                        // marginRight: "5px",
                        marginLeft: "-13px",
                      }}
                    />
                  </span>{" "}
                  Outcome: Deciding Target Audience,Planning Buyer's Journey,
                  Creating Process of Execution
                </p>
                <LinearProgressWithLabel value={course2P} color={"#000"}/>
                <p style={{fontWeight:'900'}}>{Math.round(course2P)}% Completed</p>
                
              </div>
            </div>
            <div className="course_2_button">
              {/* <a href="/program/markoknow-kickstart/landing-page">
                <div className="details">View Details</div>
              </a> */}
              {
              paid ? (
                  <div className="course_2_button">
                <a href="/program/videoplayer/64fb15b672d0fde41877e193">
                  <Button variant="contained" style={{backgroundColor:'#6E71CC', color:'#fff'}}>Open</Button>
                </a></div>
        
              ): 
              isAuth ? (
                  <button
                    onClick={addCourse1}
                    style={{
                      backgroundColor:
                        course2 === "2"
                          ? "green"
                          : course1 === "0"
                          ? "#6a58c6"
                          : "initial",
                    }}
                  >
                    {course2 === "2"
                      ? "Course  Added"
                      : course2 === "0"
                      ? "Add Course "
                      : "Button Text"}
                  </button>
                ) : (
                  <button
                    onClick={(e) => {
                        navigate('/login' , {state:{redirect:'/courses'}});
                    }}
                    style={{
                      backgroundColor:"#6a58c6"
                        
                    }}
                  >
                    Read More
                  </button>
            
                )
            }

              
            </div>
          </div>
          <div className="course_3_vsc">
            <img
              src={course_3}
              style={{
                objectFit: "fill",
                width: "100%",
                height: "auto",
                display: "block",
                borderStyle: "none",
                marginBottom: "20px",
                borderRadius: "20px 20px 0px 0px",
              }}
            />
            <div className="course_data">
              <p
                style={{
                  padding: "2px 10px",
                  backgroundColor: "#F2F6FF",
                  borderRadius: "10px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "233px",
                  height: "20px",
                  color: "#6E71CC",
                  textTransform: "uppercase",
                  fontFamily: "Open Sans",
                  fontSize: "12px",
                  lineHeight: "16px",
                  fontWeight: "600",
                }}
              >
                Undergrad Students or Grad
              </p>
              <p
                style={{
                  color: "#3B3435",
                  fontSize: "1.125rem",
                  fontWeight: "600",
                  marginTop: "10px",
                  fontFamily: "Poppins",
                }}
              >
                BMC Creation (Step 3)
              </p>
              <p
                style={{
                  color: "#21191B",
                  fontSize: "24px",
                  fontWeight: "700",
                  fontFamily: "Poppins",
                }}
              >
                BMC Creation
              </p>
              <div
                style={{
                  margin: "10px 0px 0px",
                  padding: "0px 10px",
                }}
              >
                <p
                  style={{
                    color: "#21191B",
                    marginBottom: "10px",
                    fontWeight: "600",
                  }}
                >
                  <DateRangeIcon
                    style={{
                      color: "gray",
                      fontSize: "18px",
                      position: "relative",
                      top: "5px",
                      marginRight: "5px",
                      marginLeft: "-13px",
                    }}
                  />
                  Business Model Canvas Creation
                </p>
                <p
                  style={{
                    color: "#21191B",
                    marginBottom: "10px",
                    fontWeight: "600",
                  }}
                >
                  <span>
                    <ScheduleIcon
                      style={{
                        color: "gray",
                        fontSize: "18px",
                        position: "relative",
                        top: "5px",
                        marginRight: "5px",
                        marginLeft: "-13px",
                      }}
                    />
                  </span>
                  7 Business Models
                </p>
                <p
                  style={{
                    color: "#21191B",
                    marginBottom: "10px",
                    fontWeight: "600",
                  }}
                >
                  <span>
                    <CurrencyRupeeIcon
                      style={{
                        color: "gray",
                        fontSize: "18px",
                        position: "relative",
                        top: "5px",
                        marginRight: "5px",
                        marginLeft: "-13px",
                      }}
                    />
                  </span>
                  Outcome : Decision factor for business model, shifting
                  business models
                </p>
                <LinearProgressWithLabel value={course3P} color={"#000"}/>
                <p style={{fontWeight:'900'}}>{Math.round(course3P)}% Completed</p>
                
              </div>
            </div>
            <div className="course_3_button">
              {/* <a href="/program/markoknow-kickstart/landing-page">
                <div className="details">View Details</div>
              </a> */}
              {
              paid ? (
                  <div className="course_3_button">
                <a href="/program/videoplayer/64fb105b72d0fde41877e101">
                  <Button variant="contained" style={{backgroundColor:'#6E71CC', color:'#fff'}}>Open</Button>
                </a>
                <IconButton
                  onClick={() => {
                    if (course3P<100) setOpenE(true);

                    else navigate(`/certificate/64fb105b72d0fde41877e101`, {state:`Product To Model`});
                  }}
                 style={{marginLeft:'10px', background:'#6E71CC'}}><CardMembershipIcon/></IconButton>
                </div>
        
              ): 
              isAuth ? (
                  <button
                    onClick={addCourse1}
                    style={{
                      backgroundColor:
                        course3 === "3"
                          ? "green"
                          : course3 === "0"
                          ? "#6a58c6"
                          : "initial",
                    }}
                  >
                    {course3 === "3"
                      ? "Course  Added"
                      : course1 === "0"
                      ? "Add Course "
                      : "Button Text"}
                  </button>
                ) : (
                  <button
                    onClick={(e) => {
                        navigate('/login' , {state:{redirect:'/courses'}});
                    }}
                    style={{
                      backgroundColor:"#6a58c6"
                        
                    }}
                  >
                    Read More
                  </button>
            
                )
            }

              
            </div>
          </div>
          <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="info" sx={{ width: '100%' , zindex:'1' }}>
          {course1==='1'&& 'All the three courses are added together. As its a bundle'}
          {course1==='0'&& 'All the three courses are removed together. As its a bundle'}
        </Alert>
      </Snackbar>
        </div>
        <Modal
        open={openE}
        onClose={handleCloseE}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} style={{width:isMobile?'70%':400}}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {!cA ?'Activate The Certicate' :'Complete The Course'}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          {!cA ? `The Certificate Would Be Activated After ${activateTime} on ${activateDate}`:'To Generate Your Certificate First Complete The Course.'}
          </Typography>
        </Box>
      </Modal>
      </div>
    </>
  ); // Component 2 for Recommended Courses
};

const Component3 = () => {
  // require("dotenv").config();
  // const DOMAIN = process.env.REACT_APP_DOMAIN;
  const { USER } = require("../../api/vsc");
  const [userData, setUserData] = useState({});
  const [successfullRefferal , setSuccessfullRefferal] = useState(0);
  useEffect(() => {
    axios
      .get(USER, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setUserData(response.data);
        const successfulReferrals = response.data.referredEmails
        .filter((referral) => referral.paid === true);
        setSuccessfullRefferal(successfulReferrals.length);
      })
      .catch((error) => {
        // handle error
        console.error(error);
      });
  }, []);

  const link = `https://campus.markoknow.com/signup?referrer=${userData.referralId}`;
  function handleCopy() {
    navigator.clipboard.writeText(link);
    alert("Link copied to clipboard!");
  }

  async function handleShare() {
    const shareData = {
      title: "Check out this link!",
      text: "Hey, check out this cool link!",
      url: link,
    };

    try {
      await navigator.share({
        title: shareData.title,
        text: shareData.text,
        url: shareData.url,
        // Add WhatsApp and Email to the share targets
        // Only works on mobile devices with WhatsApp and Email apps installed
        shareTarget: [
          {
            target: "whatsapp",
            action: "share",
            params: { text: shareData.text + " " + shareData.url },
          },
          {
            target: "email",
            action: "share",
            params: {
              subject: shareData.title,
              body: shareData.text + " " + shareData.url,
            },
          },
        ],
      });

    } catch (error) {
      // console.error("Error sharing link:", error);
    }
  }

  return (
    <>
      <div>
        <div className="referral">
          <h2>Referral </h2>

          <div className="referral-box">
            <div className="referral-box-1">
              <div className="referral-box-1-1">
                <img
                  src={eye}
                  style={{
                    width: "30px",
                    height: "30px",
                    color: "rgba(0, 0, 0, 0.88)",
                  }}
                />
                <div className="referral-box-1-1-1">
                  <p>Referred People</p>
                  <div className="referral-box-1-1-1-1">
                    {userData.referralCount}
                  </div>
                </div>
              </div>
            </div>

            <div className="referral-box-2">
              <div className="referral-box-2-1">
                <img
                  src={eye}
                  style={{
                    width: "30px",
                    height: "30px",
                    color: "rgba(0, 0, 0, 0.88)",
                  }}
                />
                <div className="referral-box-2-1-1">
                  <p>Successful referral</p>
                  <div className="referral-box-2-1-1-1">
                    {successfullRefferal}
                  </div>
                </div>
              </div>
            </div>
            <div className="referral-box-3">
              <div className="referral-box-3-1">
                <img
                  src={check}
                  style={{
                    width: "30px",
                    height: "30px",
                    color: "rgba(0, 0, 0, 0.88)",
                  }}
                />
                <div className="referral-box-3-1-1">
                  <p>Earning</p>
                  <div className="referral-box-3-1-1-1">₹{successfullRefferal*100}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="referral-box-4">
          <div id="Left-box">
            <div id="heading-leftBox">
              <h2>Invite & Get</h2>
              <div className="left-box-1">
                <p >Rs 100 
                  On inviting Your friends and making them to their first purchase
                </p>
              </div>
            </div>

            <p
              style={{
                marginTop: "15px",
                fontFamily: "sans-serif",
                fontSize: "14px",
              }}
            >
              Share Your link
            </p>
            <div>
              <div className="link">
                <input
                  type="text"
                  id="link"
                  name="link"
                  value={link}
                  className="link-input"
                />
                <button
                  type="button"
                  onClick={handleCopy}
                  style={{
                    background: "#000",
                    color:'#fff',
                    borderRadius: "0.3rem",
                    border: "none",
                    fontSize: "12px",
                    fontWeight: "600",
                    width: "5rem",
                    padding: "0px 6px",
                    border: "1px solid gray",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                  }}
                >
                  Copy Link
                </button>
              </div>
              <button
                className="share-button"
                type="button"
                onClick={handleShare}
              >
                Share Link
              </button>
            </div>
            <RefferalTable userId={userData._id}/>  
          </div>
        </div>
      </div>
    </>
  );
};

const Component4 = () =>{
 
  return(
    <>
    <h1>Free Courses</h1>
    <div className="cards">
      <NavLink to="/blog" className="card">
        <img src={blogPic} alt="" width="250px" height="250px" />
        Insights</NavLink>
      <NavLink to="/watch_now" className="card">
        <img src={streamingPic} alt="" />
        Streaming</NavLink>
        
      <NavLink to="/projectLogin" className="card" >
        <img src={caseStudyPic} alt=""  width="250px" height="250px"/>
        Startup Cases</NavLink>
    </div>
    </>
  )
}
export default MyDeshboard;

// import React from "react";
// import "../../stylesheets/myDeshboard.css";
// const MyDeshboard = () => {
//   return (
//     <>
//       <div className="main_myDeshboard">
//         <div className="drawer">
//           <div className="deshboard_items">
//             <p>Courses</p>
//           </div>
//         </div>
//         <div>dsfdsfds</div>
//       </div>
//     </>
//   );
// };

// export default MyDeshboard;
