import React from 'react';
import PropTypes from 'prop-types';
import { Carousel } from 'react-responsive-carousel';
import { Box, Typography, Skeleton } from '@mui/material';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useMediaQuery } from '@mui/material';
import png from '../CaseStudy.jsx/Photos/1.png'
import { GET_BLOGS , IMAGE , GET_BLOG} from "../../api/blog";
import { GET_BADGES } from '../../api/badge';
import { LinkedinShareButton , LinkedinIcon , WhatsappShareButton , WhatsappIcon} from 'react-share';
import Badge from './index';
import Modal from '@mui/material/Modal';
import { USER_REFFERAL } from '../../api/vsc';

const Media = ({props}) => {
  const [blogs, setBlogs] = React.useState([]);
  const [completedBadges , setCompletedBadges] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const isMobile = useMediaQuery('(max-width: 600px)');
  const isSmallMobile = useMediaQuery('(max-width: 410px)');
  const isGreaterMobile = useMediaQuery('(max-width: 500px)');
  const [op, setOp] = React.useState(false);
  const [sBadge , setSBadge] = React.useState({});

   React.useEffect(() => {
        axios
            .get(USER_REFFERAL+window.localStorage.id).then(resp => {
              setCompletedBadges(resp.data.data.completedBadges);
            })
   },[])
  const handleClose = () => setOp(false);
  const getNumBlogsPerSlide = () => {
    if (isGreaterMobile) {
      return 1;
    } else if (isSmallMobile) {
      return 3;
    } else {
      return 5;
    }
  };
  let chunkSize = getNumBlogsPerSlide();
  const arrowStyles = {
    position: "absolute",
    top: ".7em",
    bottom: "auto",
    padding: ".4em",
    zIndex: 2,
 };
  React.useEffect(() => {
    setLoading(true);
   
      axios
      .get(GET_BADGES)
      .then((res) => {
        // setBlogs(props==="Reverse"?res.data.reverse():res.data);
        if(props==="Earned Badges"){
          axios
          .get(USER_REFFERAL+window.localStorage.id)
          .then(resp => {
            const filteredBlogs = res.data.data.filter(badge =>
              resp.data.data.completedBadges.includes(badge.tag.split(" ").join(""))
            );
            setBlogs(filteredBlogs);

          })
               
        }
      })
      .catch((err) => {
      });
   
  }, []);

  const blogChunks = blogs.length > 0 ? blogs.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / chunkSize);

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = []; // start a new chunk
    }

    resultArray[chunkIndex].push(item);
    return resultArray;
  }, []) : [];

  return (
    <>
      <Typography variant="h5" style={{color:'#000' , textAlign:'left', fontFamily:'Poppins' , marginBottom:'10px' , fontWeight:'900'}}>
      {blogChunks.length>0&&`${props}`}
        </Typography>
            <Carousel
      className="carousel"
      style={{
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        alignItems: 'center',
        flexDirection: 'row',
        background: 'white', 
      }}
      showArrows={true}
      showIndicators={false}
      renderArrowPrev={(onClickHandler, hasPrev, label) =>
        hasPrev && (
          <button
            type="button"
            onClick={onClickHandler}
            title={label}
            style={{
              ...arrowStyles,
              left: !isGreaterMobile?'0.2em':0 ,
              top: '50%',
              transform: 'translateY(-50%)',
              backgroundColor: '#6a58c6',
              color: '#fff',
              borderRadius: '50%',
              border: 'none',
              padding: '10px',
              zIndex: 1,
            }}
          >
            <ArrowBackIosNewRoundedIcon />
          </button>
        )
      }
      renderArrowNext={(onClickHandler, hasNext, label) =>
        hasNext && (
          <button
            type="button"
            onClick={onClickHandler}
            title={label}
            style={{
              ...arrowStyles,
              right: !isGreaterMobile?'0.5em':'0',
              top: '50%',
              transform: 'translateY(-50%)',
              backgroundColor: '#6a58c6',
              color: '#fff',
              borderRadius: '50%',
              border: 'none',
              padding: '10px',
              zIndex: 1,
            }}
          >
            <ArrowForwardIosRoundedIcon />
          </button>
        )
      }
    >
      {blogChunks.length>0&&blogChunks.map((chunk, chunkIndex) => (
        <div key={chunkIndex} style={{ display: 'flex', flexDirection: 'row' , padding:!isGreaterMobile?'15px':'5px', gap:'10px' , background:'#fff' }}>
          {chunk.map((item, index) => (
            <Link onClick={(e)=>{setSBadge(item); setOp(true);}} style={{ color: '#000' }} key={index}>
              <Box
                sx={{
                  width: 300,
                  height: '100%',
                  // boxShadow: 'rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px',
                  '&:hover': {
                    transform: 'scale(1.1)',
                    transition: 'transform 0.3s ease-in-out',
                    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                    borderRadius: '20px'
                  },
                  // clipPath: 'polygon(0 0, 100% 0, 100% 66%, 50% 100%, 0 66%)',
                  // border: 'solid #000 5px',
                  boxShadow: 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'
                  ,borderRadius: '10px'
                }}
              >
                {item ? (
                  <img 
                  style={{ width: '100%', height: 180 }} 
                  alt={item.title} 
                //   src={png} 
                src={item.badgeUrl}

                  />
                ) : (
                  <Skeleton variant="rectangular" width={300} height={180} />
                )}

                {item ? (
                  <Box sx={{ pr: 2 }}>
                    <Typography gutterBottom variant="subtitle1">
                      {item.title}
                    </Typography>
                    <Typography gutterBottom variant="body2">
                      {item.message}
                    </Typography>
                    <div style={{marginTop:'10px', display:'flex' , justifyContent:'center'}}>
                    <LinkedinShareButton url={item.badgeUrl} >
                        <LinkedinIcon round={true} size="25px"/>
                    </LinkedinShareButton>
                    <WhatsappShareButton url={item.badgeUrl}>
                        <WhatsappIcon round={true} size="25px"/>
                    </WhatsappShareButton>
                </div>
                  </Box>
                ) : (
                  <Box sx={{ pt: 0.5 }}>
                    <Skeleton />
                    <Skeleton width="60%" />
                  </Box>
                )}
              </Box>
              
            </Link>
          ))}
          
        </div>
      ))}
    </Carousel>
    <Modal
    open={op}
    onClose={handleClose}
    >
        <Box style={{display:'flex', alignItems:'center' , justifyContent:'center', position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)', border:'none'}}>
        <Badge sBadge={sBadge} />

    </Box>
    
    </Modal>
    </>
    
  );
};


export default Media;
