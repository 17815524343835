import React, { useState, useEffect } from "react";
import axios from "axios";
import "../../../stylesheets/Kickstart_details.css";
import course_1 from "../Images/mvp.png";
import course_2 from "../Images/gtm.png";
import course_3 from "../Images/bmc.png";
import DateRangeIcon from "@mui/icons-material/DateRange";
import ScheduleIcon from "@mui/icons-material/Schedule";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import Footer from "../../Footer/Footer";
import {Link , useNavigate} from 'react-router-dom';
import { Button, Typography , IconButton , Box , Modal } from "@mui/material";
import {useMediaQuery} from '@mui/material';
import Cart from '../../Navbar/cart';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { USER_ACCESS , MARKOKNOW_CHECKOUT } from "../../../api/vsc";
import LinearProgressWithLabel from "./VideoPlayer/LinearProgressWithLabel";
import LinearProgress from '@mui/material/LinearProgress';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import Stack from '@mui/material/Stack';
import TrapFocus from '@mui/material/Unstable_TrapFocus';
import Paper from '@mui/material/Paper';
import Fade from '@mui/material/Fade';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const Course_Landing = ({ isAuth, setAuth  }) => {
   const navigate = useNavigate();
  const { MARKOKNOW_KICKSTART, USER , GET_USER } = require("../../../api/vsc");
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [course1, setCourse1] = useState("");
  const [course2, setCourse2] = useState("");
  const [course3, setCourse3] = useState("");
  const [course1P ,setCourse1P ] = useState(0);
  const [course2P ,setCourse2P ] = useState(0);
  const [course3P ,setCourse3P ] = useState(0);
  const [selectedField, setSelectedField] = useState("All");
  const isMobile = useMediaQuery('(max-width:1100px)');
  const isLMobile = useMediaQuery('(max-width:1300px)');
  const [refresh , setRefresh] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [paid , setPaid] = useState(false);
  const [openE, setOpenE] = React.useState(false);
  const handleOpen = () => setOpenE(true);
  const handleCloseE = () => setOpenE(false);
  const [count , setCount] = React.useState(0);
  const [activateDate , setActivateDate] = React.useState('');
  const [activateTime , setActivateTime] = React.useState('');
  const [cA , setCa] = React.useState(false);
  const [op, setOp] = React.useState(false);
  const [bannerOpen, setBannerOpen] = React.useState(false);

  const handleCl = () => {
    setOp(false);
  }
  
  const closeBanner = () => {
    setBannerOpen(false);
  };
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: !isMobile?400:'70%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  useEffect(() => {
    axios
      .get(USER_ACCESS + window.localStorage.id)
      .then((res) => {
        setPaid(res.data.access);

      })
      .catch((err) => {});
      axios
      .get(GET_USER+window.localStorage.id+"/"+"64fb08d372d0fde41877df63")
      .then(res => {
      })
      .catch(err => {
      })
      axios
      .get(GET_USER+window.localStorage.id+"/"+"64fb15b672d0fde41877e193")
      .then(res => {
         setCourse2P((res.data.course.completed/res.data.course.total)*100);
      })
      .catch(err => {
      })
      axios
      .get(GET_USER+window.localStorage.id+"/"+"64fb105b72d0fde41877e101")
      .then(res => {
         setCourse3P((res.data.course.completed/res.data.course.total)*100);
      })
      .catch(err => {
      })
  }, []);
  const handleClick = () => {
    setOpen(true);
  };


  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  useEffect(() => {
    axios
      .get(USER, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setData(response.data);
        setCourse1(response.data.course1 || ""); // Set the initial state of course1
        setCourse2(response.data.course2 || ""); // Set the initial state of course2
        setCourse3(response.data.course3 || ""); // Set the initial state of course3
        if(response.data.course1==1) setCount(3);
        setCourse1P(((response.data.courseDetails.find(course => course.courseId === "64fb08d372d0fde41877df63").videosCompleted.length+response.data.courseDetails.find(course => course.courseId === "64fb08d372d0fde41877df63").pdfsCompleted.length)/response.data.courseDetails.find(course => course.courseId === "64fb08d372d0fde41877df63").total)*100);
        setCourse2P(((response.data.courseDetails.find(course => course.courseId === "64fb15b672d0fde41877e193").videosCompleted.length+response.data.courseDetails.find(course => course.courseId === "64fb15b672d0fde41877e193").pdfsCompleted.length)/response.data.courseDetails.find(course => course.courseId === "64fb15b672d0fde41877e193").total)*100);
        setCourse3P(((response.data.courseDetails.find(course => course.courseId === "64fb105b72d0fde41877e101").videosCompleted.length+response.data.courseDetails.find(course => course.courseId === "64fb105b72d0fde41877e101").pdfsCompleted.length)/response.data.courseDetails.find(course => course.courseId === "64fb105b72d0fde41877e101").total)*100);
        var currentDate = new Date();
        const course = response.data.certificateActivate.find(
          (course) => course.courseId === "64fb08d372d0fde41877df63"
        ).dateOfActivation;
        const activate = new Date(course)
        var array = activate.toLocaleString().split(',');
        setActivateDate(array[0]);
        setActivateTime(array[1]);
        if (currentDate > activate) {
          setCa(true);
        }
        setRefresh(false);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [refresh]);
  const refe = () => {
    setRefresh(true);
  }
  useEffect(() => {
  }, [data]);
  const user = localStorage.getItem("email");

  const addCourse1 = (refresh) => {
    const newCourse1 = course1 === "1" ? "0" : "1";
    const newCourse2 = course2 === "2" ? "0" : "2"; 
    const newCourse3 = course3 === "3" ? "0" : "3"; 
    setCourse1(newCourse1);
    setCourse2(newCourse2); 
    setCourse3(newCourse3); 

    axios
      .post(MARKOKNOW_KICKSTART, {
        course1: newCourse1,
        course2 : newCourse2 ,
        course3 : newCourse3,
        user,
      })
      .then((response) => {
        setRefresh(true);
        setOp(true);
        setOpen(true);
        
        // if(refreshCart){
        //   refreshCart();
        // }
        newCourse1==="1" && setBannerOpen(true);
        
        refresh();
        
      })
      .catch((err) => {
      });
  };

  return (
    <div>
      <div className="course_landing" style={{marginBottom:'50px'}}>
      <TrapFocus open >
        <Fade appear={false} in={bannerOpen}>
          <Paper
            role="dialog"
            aria-modal="false"
            aria-label="Cookie banner"
            square
            variant="contained"
            tabIndex={-1}
            sx={{
              position: 'fixed',
              bottom:isMobile&& 0,
              display:!isMobile&&'none',
              left: 0,
              right: 0,
              m: 0,
              p: 2,
              borderWidth: 0,
              borderTopWidth: 1,
              background:'#fff',
              zIndex: '999'
            }}
          >
            <Stack
              direction={{ xs: 'column', sm: 'column' }}
              justifyContent="space-between"
              gap={2}
            >
              <Box
                sx={{
                  flexShrink: 1,
                  alignSelf: { xs: 'flex-start', sm: 'center' },
                }}
              >
                <Typography fontWeight="bold" style={{display:'flex' , alignItems:'center' }}><CheckCircleIcon style={{fontSize:'30px' , color:'green'}} /> Added To Cart</Typography>
                <Typography variant="body2">
                Startup :Product to Model 
                </Typography>
                <ol style={{margin:'10px', padding:'2px'}}>
                <li><Typography variant="subtitle" >MVP BUILDING</Typography></li>
                <li><Typography variant="subtitle">GTM Strategy</Typography></li>
                <li><Typography variant="subtitle">BMC Creation</Typography></li>
                </ol>
                <Typography>Total : ₹ <span style={{
                background:!paid && course1 === "1" && 'linear-gradient(to left top, transparent 47.75%, currentColor 49.5%, currentColor 50.5%, transparent 52.25%)',
                      color: paid || course1 !== "1" ? 'black' : 'red'
                  }}>{!paid && course1 === "1" ? 350 : 0}</span>&nbsp;{!paid && course1 === "1"&&250}</Typography>
              </Box>
              <Stack
                gap={2}
                direction={{
                  xs: 'row-reverse',
                  sm: 'row',
                }}
                sx={{
                  flexShrink: 0,
                  alignSelf: { xs: 'flex-end', sm: 'center' },
                }}
              >
                <Button href={MARKOKNOW_CHECKOUT+"64fb08d372d0fde41877df63/"+window.localStorage.id} size="small" onClick={closeBanner} variant="contained" style={{background:'#6a58c6'}}>
                  Proceed To Checkout
                </Button>
                <Button size="small" onClick={closeBanner}>
                  Close
                </Button>
              </Stack>
            </Stack>
          </Paper>
        </Fade>
      </TrapFocus>
      <h2 id="our-courses-course" style={{textAlign:'center'}}>Our Courses</h2>

          <div style={{display:'flex' , justifyContent:'center' , flexDirection:isMobile&&'column'
}}>
          <div style={{display:'flex' , flexDirection:'row' , justifyContent:'center'}}>
           <Button 
            variant="contained" 
            style={{background:selectedField==='All'?'#000':'#6a58c6' , borderRadius:'15px', margin:'8px'}}
            onClick={() => setSelectedField('All')}
            >All</Button>
            <Button 
            variant="contained" 
            style={{background:selectedField==='startup'?'#000':'#6a58c6' , borderRadius:'15px', margin:'8px'}}
            onClick={() => setSelectedField('startup')}
            >Startup
            </Button>
            <Button 
            variant="contained" 
            style={{background:selectedField==='product'?'#000':'#6a58c6' , borderRadius:'15px', margin:'8px'}}
            onClick={() => setSelectedField('product')}
            >Product</Button>
            </div>
            <div style={{display:'flex' , flexDirection:'row' , justifyContent:'center'}}>
            <Button 
            variant="contained" 
            style={{background:selectedField==='marketing'?'#000':'#6a58c6' , borderRadius:'15px', margin:'8px'}}
            onClick={() => setSelectedField('marketing')}
            >Marketing</Button>
            <Button 
            variant="contained" 
            style={{background:'#6a58c6' , borderRadius:'15px', margin:'8px'}}
            >Analytics</Button>
            <Button 
            variant="contained" 
            style={{background:'#6a58c6' , borderRadius:'15px', margin:'8px'}}
            >Strategy</Button>
            </div>
            <div style={{display:'flex' , flexDirection:'row', justifyContent:'center'}}>
            <Button 
            variant="contained" 
            style={{background:'#6a58c6' , borderRadius:'15px', margin:'8px'}}
            >Fundraising</Button>
            <Button 
            variant="contained" 
            style={{background:'#6a58c6' , borderRadius:'15px', margin:'8px'}}
            >Revenue</Button>
            </div>
          </div>
        <div className="kickstart_courses_box" style={{  borderRadius:'10px' ,       boxShadow: 'rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px',}}>
          
          <div className="level_courses" style={{display:'flex' , justifyContent:'center' , alignItems:'center'}}>
          {(selectedField === 'startup' || selectedField === 'All')  && (
            <div className='course_1' style={{    marginRight: isMobile&&'0px'}}>
              <img
                src={course_1}
                style={{
                  objectFit: "fill",
                  width: "100%",
                  height: "auto",
                  display: "block",
                  borderStyle: "none",
                  marginBottom: "20px",
                  borderRadius: "20px 20px 0px 0px",
                }}
                alt='Course 1'
              />
              <div 
               style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                   
                  }}>
                  <p
                  style={{
                    padding: "2px 10px",
                    backgroundColor: "#F2F6FF",
                    borderRadius: "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "233px",
                    height: "20px",
                    color: "#6E71CC",
                    textTransform: "uppercase",
                    fontFamily: "Open Sans",
                    fontSize: "12px",
                    lineHeight: "16px",
                    fontWeight: "600",
                    textAlign:'center'
                  }}
                  >
                  Undergrad Students or Grad
                  </p>
              </div>
              <div className="course_data">
               
                <p
                  style={{
                    color: "#3B3435",
                    fontSize: "1.125rem",
                    fontWeight: "600",
                    marginTop: "10px",
                    fontFamily: "Poppins",
                  }}
                >
                  MVP Building (Step 1 )
                </p>
                <p
                  style={{
                    color: "#21191B",
                    fontSize: "24px",
                    fontWeight: "700",
                    fontFamily: "Poppins",
                  }}
                >
                  MVP Building
                </p>
                <div
                  style={{
                    margin: "10px 0px 0px",
                    padding: "0px 10px",
                  }}
                >
                  <p
                    style={{
                      color: "#21191B",
                      marginBottom: "10px",
                      fontWeight: "600",
                    }}
                  >
                    <DateRangeIcon
                      style={{
                        color: "gray",
                        fontSize: "18px",
                        position: "relative",
                        top: "5px",
                        marginRight: "5px",
                        marginLeft: "-13px",
                      }}
                    />
                    Minimum Viable Product Building
                  </p>
                  <p
                    style={{
                      color: "#21191B",
                      marginBottom: "10px",
                      fontWeight: "600",
                    }}
                  >
                    <span>
                      <ScheduleIcon
                        style={{
                          color: "gray",
                          fontSize: "18px",
                          position: "relative",
                          top: "5px",
                          marginRight: "5px",
                          marginLeft: "-13px",
                        }}
                      />
                    </span>
                    2 Real Time Cases
                  </p>
                  <p
                    style={{
                      color: "#21191B",
                      marginBottom: "10px",
                      fontWeight: "600",
                    }}
                  >
                    <span>
                      <CurrencyRupeeIcon
                        style={{
                          color: "gray",
                          fontSize: "18px",
                          position: "relative",
                          top: "5px",
                          marginLeft: "-13px",
                        }}
                      />
                    </span>{" "}
                    Outcome: Problem Selection, Product decision, Product
                    features, Idea Execution
                  </p>
                  <LinearProgressWithLabel value={course1P} color={"#000"}/>
                  <p style={{fontWeight:'900'}}>{Math.round(course1P)}% Completed</p>

                </div>
              </div>
                

              <div className={`course_1_button`}>
                {/* <a href="/program/markoknow-kickstart/landing-page">
                  <div className="details">View Details</div>
                </a> */}

                {
               paid ? (
                  <div className="course_1_button">
                <a href="/program/videoplayer/64fb08d372d0fde41877df63">
                  <Button variant="contained" style={{backgroundColor:'#6E71CC', color:'#fff'}}>Open</Button>
                </a></div>
        
              ): 
              isAuth ? (
                  <button
                    onClick={addCourse1}
                    style={{
                      backgroundColor:
                        course1 === "1"
                          ? "green"
                          : course1 === "0"
                          ? "#6a58c6"
                          : "initial",
                    }}
                  >
                    {course1 === "1"
                      ? "Course  Added"
                      : course1 === "0"
                      ? "Add Course "
                      : "Button Text"}
                  </button>
                ) : (
                  <button
                    onClick={(e) => {
                        navigate('/login' , {state:{redirect:'/Course_Landing'}});
                    }}
                    style={{
                      backgroundColor:"#6a58c6"
                        
                    }}
                  >
                    Read More
                  </button>
            
                )
            }
              </div>
            </div>
          )}
          {(selectedField === 'product' || selectedField === 'All') && (
            <div className="course_2" style={{    marginRight: isMobile&&'0px'}} >
              <img
                src={course_2}
                style={{
                  objectFit: "fill",
                  width: "100%",
                  height: "auto",
                  display: "block",
                  borderStyle: "none",
                  marginBottom: "20px",
                  borderRadius: "20px 20px 0px 0px",
                }}
                alt = 'Course 2'
              />
              <div 
               style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                   
               }}>
                <p
                  style={{
                    padding: "2px 10px",
                    backgroundColor: "#F2F6FF",
                    borderRadius: "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "233px",
                    height: "20px",
                    color: "#6E71CC",
                    textTransform: "uppercase",
                    fontFamily: "Open Sans",
                    fontSize: "12px",
                    lineHeight: "16px",
                    fontWeight: "600",
                  }}
                >
                  Undergrad Students or Grad
                </p>
              </div>
              <div className="course_data">
                <p
                  style={{
                    color: "#3B3435",
                    fontSize: "1.125rem",
                    fontWeight: "600",
                    marginTop: "10px",
                    fontFamily: "Poppins",
                  }}
                >
                  GTM Strategy (Step 2)
                </p>
                <p
                  style={{
                    color: "#21191B",
                    fontSize: "24px",
                    fontWeight: "700",
                    fontFamily: "Poppins",
                  }}
                >
                  GTM Strategy
                </p>
                <div
                  style={{
                    margin: "10px 0px 0px",
                    padding: "0px 10px",
                  }}
                >
                  <p
                    style={{
                      color: "#21191B",
                      marginBottom: "10px",
                      fontWeight: "600",
                    }}
                  >
                    <DateRangeIcon
                      style={{
                        color: "gray",
                        fontSize: "18px",
                        position: "relative",
                        top: "5px",
                        marginRight: "5px",
                        marginLeft: "-13px",
                      }}
                    />
                    Go To Market Strategy
                  </p>
                  <p
                    style={{
                      color: "#21191B",
                      marginBottom: "10px",
                      fontWeight: "600",
                    }}
                  >
                    <span>
                      <ScheduleIcon
                        style={{
                          color: "gray",
                          fontSize: "18px",
                          position: "relative",
                          top: "5px",
                          marginRight: "5px",
                          marginLeft: "-13px",
                        }}
                      />
                    </span>
                    2 Real Time Cases
                  </p>
                  <p
                    style={{
                      color: "#21191B",
                      marginBottom: "10px",
                      fontWeight: "600",
                    }}
                  >
                    <span>
                      <CurrencyRupeeIcon
                        style={{
                          color: "gray",
                          fontSize: "18px",
                          position: "relative",
                          top: "5px",
                          // marginRight: "5px",
                          marginLeft: "-13px",
                        }}
                      />
                    </span>{" "}
                    Outcome: Deciding Target Audience,Planning Buyer's Journey,
                    Creating Process of Execution
                  </p><LinearProgressWithLabel value={course2P} color={"#000"}/>
                  <p style={{fontWeight:'900'}}>{Math.round(course2P)}% Completed</p>

                </div>
              </div>
              <div>
              


              </div>
              <div className="course_2_button">
                {/* <a href="/program/markoknow-kickstart/landing-page">
                  <div className="details">View Details</div>
                </a> */}

                {
               paid ? (
                  <div className="course_2_button">
                <a href="/program/videoplayer/64fb15b672d0fde41877e193">
                  <Button variant="contained" style={{backgroundColor:'#6E71CC', color:'#fff'}}>Open</Button>
                </a></div>
        
              ): 
              isAuth ? (
                  <button
                    onClick={addCourse1}
                    style={{
                      backgroundColor:
                        course2 === "2"
                          ? "green"
                          : course2 === "0"
                          ? "#6a58c6"
                          : "initial",
                    }}
                  >
                    {course2 === "2"
                      ? "Course  Added"
                      : course2 === "0"
                      ? "Add Course "
                      : "Button Text"}
                  </button>
                ) : (
                  <button
                    onClick={(e) => {
                        navigate('/login' , {state:{redirect:'/Course_Landing'}});
                    }}
                    style={{
                      backgroundColor:"#6a58c6"
                        
                    }}
                  >
                    Read More
                  </button>
            
                )
            }
              </div>
            </div>
          )}
          {(selectedField === 'marketing' || selectedField === 'All') && (
            <div className="course_3" >
              <img
                src={course_3}
                style={{
                  objectFit: "fill",
                  width: "100%",
                  height: "auto",
                  display: "block",
                  borderStyle: "none",
                  marginBottom: "20px",
                  borderRadius: "20px 20px 0px 0px",
                }}
                alt='Course 2'
              />
              <div 
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}>
 <p
                  style={{
                    padding: "2px 10px",
                    backgroundColor: "#F2F6FF",
                    borderRadius: "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "233px",
                    height: "20px",
                    color: "#6E71CC",
                    textTransform: "uppercase",
                    fontFamily: "Open Sans",
                    fontSize: "12px",
                    lineHeight: "16px",
                    fontWeight: "600",
                  }}
                >
                  Undergrad Students or Grad
                </p>
              </div>
              <div className="course_data">
               
                <p
                  style={{
                    color: "#3B3435",
                    fontSize: "1.125rem",
                    fontWeight: "600",
                    marginTop: "10px",
                    fontFamily: "Poppins",
                  }}
                >
                  BMC Creation (Step 3)
                </p>
                <p
                  style={{
                    color: "#21191B",
                    fontSize: "24px",
                    fontWeight: "700",
                    fontFamily: "Poppins",
                  }}
                >
                  BMC Creation
                </p>
                <div
                  style={{
                    margin: "10px 0px 0px",
                    padding: "0px 10px",
                  }}
                >
                  <p
                    style={{
                      color: "#21191B",
                      marginBottom: "10px",
                      fontWeight: "600",
                    }}
                  >
                    <DateRangeIcon
                      style={{
                        color: "gray",
                        fontSize: "18px",
                        position: "relative",
                        top: "5px",
                        marginRight: "5px",
                        marginLeft: "-13px",
                      }}
                    />
                    Business Model Canvas Creation
                  </p>
                  <p
                    style={{
                      color: "#21191B",
                      marginBottom: "10px",
                      fontWeight: "600",
                    }}
                  >
                    <span>
                      <ScheduleIcon
                        style={{
                          color: "gray",
                          fontSize: "18px",
                          position: "relative",
                          top: "5px",
                          marginRight: "5px",
                          marginLeft: "-13px",
                        }}
                      />
                    </span>
                    7 Business Models
                  </p>
                  <p
                    style={{
                      color: "#21191B",
                      marginBottom: "10px",
                      fontWeight: "600",
                    }}
                  >
                    <span>
                      <CurrencyRupeeIcon
                        style={{
                          color: "gray",
                          fontSize: "18px",
                          position: "relative",
                          top: "5px",
                          marginRight: "5px",
                          marginLeft: "-13px",
                        }}
                      />
                    </span>
                    Outcome : Decision factor for business model, shifting
                    business models
                  </p>
                  <LinearProgressWithLabel value={course3P} color={"#000"}/>
                  <p style={{fontWeight:'900'}}>{Math.round(course3P)}% Completed</p>
                </div>
              </div>
             

            
              <div className="course_3_button">
                {/* <a href="/program/markoknow-kickstart/landing-page">
                  <div className="details">View Details</div>
                </a> */}

                {
               paid ? (
                  <div className="course_3_button">
                <a href="/program/videoplayer/64fb105b72d0fde41877e101">
                  <Button variant="contained" style={{backgroundColor:'#6E71CC', color:'#fff'}}>Open</Button>
                </a>
                <IconButton
                  onClick={() => {
                    if (course3P<100 || !cA) setOpenE(true);

                    else navigate(`/certificate/64fb105b72d0fde41877e101`, {state:`Product To Model`});
                  }}
                 style={{marginLeft:'10px'}}><CardMembershipIcon/></IconButton>
                </div>
        
              ): 
              isAuth ? (
                  <button
                    onClick={()=> {
                      addCourse1(refe);
                      }}
                    style={{
                      backgroundColor:
                        course3 === "3"
                          ? "green"
                          : course3 === "0"
                          ? "#6a58c6"
                          : "initial",
                    }}
                  >
                    {course3 === "3"
                      ? "Course  Added"
                      : course3 === "0"
                      ? "Add Course "
                      : "Button Text"}
                  </button>
                ) : (
                  <button
                    onClick={(e) => {
                        navigate('/login' , {state:{redirect:'/Course_Landing'}});
                    }}
                    style={{
                      backgroundColor:"#6a58c6"
                        
                    }}
                  >
                    Read More
                  </button>
            
                )
            }
              </div>
            </div>
          )}  
            
          </div>
        </div>
      </div>
      {course1==='1'&&<Snackbar open={op} autoHideDuration={6000} onClose={handleCl} style={{ zIndex:'999999' }}>
        <Alert onClose={handleCl} severity="info" sx={{ width: '100%' , display:isMobile&&'none'  }}>
          Click To Proceed To Checkout <a href={MARKOKNOW_CHECKOUT+"64fb08d372d0fde41877df63/"+window.localStorage.id}>Proceed To Checkout</a>
        </Alert>
      </Snackbar>}
      {/* <Snackbar open={open&&!isMobile} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="info" sx={{ width: '100%'  }}>
          {course1==='1'&& 'All the three courses are added together. As its a bundle'}
          {course1==='0'&& 'All the three courses are removed together. As its a bundle'}
        </Alert> 
      </Snackbar> */}
      <Modal
        open={openE}
        onClose={handleCloseE}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} style={{width:isMobile?'70%':400}}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
          {!cA ?'Activate The Certificate' :'Complete The Course'}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          {!cA ? `The Certificate Would Be Activated After ${activateTime} on ${activateDate}`:'To Generate Your Certificate First Complete The Course.'}
          </Typography>
        </Box>
      </Modal>
      <div>
      <Footer />
      </div>
      
    </div>
  );
};

export default Course_Landing;
