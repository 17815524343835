export const questions = [
  {
    id: 1,
    question: "In how many days would I get the access to the course?",
    answer:
      "You will get the access to the course in a day after payment. You will receive the course via mail. ",
  },
  {
    id: 2,
    question: "Do I necessarily need to start a startup to attend the course?",
    answer:
      "The course is a vocational course to upskill you. While starting a startup would help you in understanding the course better, you can start the course to learn and understand how business functions, without starting a startup",
  },
  {
    id: 3,
    question:
      "I am a working professional but want to start something like a Startup. Is this course relevant?",
    answer:
      "Yes, if you are willing to learn about startups from startup cases and want to take a structured approach to executing your idea, this course is for you as well. ",
  },
  {
    id: 4,
    question:
      "I am a school student but very passionate for startup. Would this course be something I can understand? ",
    answer:
      "Hey Jr., Yup, this course is designed in simple cases that is understandable, so you can try this. You might face a little difficulty in some key terms, which you can google parallel while going through the course.",
  },
  {
    id: 5,
    question: "Will I get certificate on completion?",
    answer: "Yes, you will get the certificate on completion of the course.",
  },
  {
    id: 6,
    question: "How much time will it take to complete the course ?",
    answer: "It will take 1 hour to complete the course. ",
  },
  {
    id: 7,
    question: "Will this course help me get job?",
    answer:
      "This course is an upskilling course that helps you enhance your skills. However, it doesn't directly gets you job or guarantees job. ",
  },
  {
    id: 8,
    question: "I am from an engineering background, does this course help me ?",
    answer:
      "As an engineer, you will get perspective on business and startups and how your decisions are impacting the business. This would help you become more solution oriented. ",
  },
  {
    id: 9,
    question: "I read a lot on startups, is this course still relevant for me?",
    answer:
      "The course is s fresh approach towards startups in a structured manner that helps you decide and execute the startup in an actionable model. The focus is more towards turning ideas into actions and laying steps with help on how to take decisions on various levels. Its stepping into the shoes of a founder itself.",
  },
];
