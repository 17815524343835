import kanak from './Images/kanak.jpg';
import sneha from './Images/sneha.jpg';
import sahil from './Images/sahil.jpg';
import mahak from './Images/Mahek.jpg';
import ramaira from './Images/Ramaira.jpg';
import manan from './Images/manan.jpg';
import ayush from './Images/ayush.jpg';
import deepesh from './Images/deepesh.jpg';
import devang from './Images/devang.jpg';
import pranshul from './Images/pranshul.jpg';
import radhika from './Images/radhika.jpg';
import suhani from './Images/suhani.jpg';
import ashwiniThorve from './Images/ashwinit.jpg';
import yunus from './Images/yunus.jpg';
import yashasvi from './Images/yashasvi.jpg';
import nitin from './Images/nitin.jpg';
import yash from './Images/yash.jpg';
import akshat from './Images/akshat.jpg';
import sunnySingh from './Images/sunnysingh.jpg';




export const blogs = [
    {
        title: 'I found the aspects of Implementation oriented learning really enticing as I am into management and we encounter application oriented mindset at each step of our learning which aligns with Virtual Startup Campus',
        name: 'Kanak',
        place: 'IIM Indore',
        imgUrl: kanak,
        linkedIn: 'https://www.linkedin.com/in/kanak-rustagi/'
    },
    {
        title: 'The course was really informative. I got to know about a lot of things and specifically the key points of starting up a startup',
        name: 'Manan',
        place: 'Jain University',
        imgUrl: manan,
        linkedIn: 'https://www.linkedin.com/in/manan-jain-52157a1ab/'
    },
    {
        title: 'My experience with Virtual Startup Campus has been amazing as I got to learn new things that helped me improve my understanding of various fields and learn it by implementing in small projects',
        name: 'Sneha',
        place: 'HDFC Life',
        imgUrl: sneha,
        linkedIn: 'https://www.linkedin.com/in/sneha-aiyangar-8847731b3/'
    },
    {
        title: 'The quality of knowledge is amazing and the fact of learning from founders, implementors and CMOs etc on various topics really helps in getting the insider understanding of topics beyond our courses',
        name: 'Sahil',
        place: 'IIT Kanpur',
        imgUrl: sahil,
        linkedIn: 'https://www.linkedin.com/in/sahilgala2682/'
    },
    {
        title: 'I discovered the course to be incredibly beneficial in imparting fundamental knowledge crucial for any startup. It enabled me to grasp the startup environment and offered valuable insights into the functioning of startups.',
        name: 'Mahak',
        place: 'VP Enactus, SSCBS',
        imgUrl: mahak,
        linkedIn: 'https://www.linkedin.com/in/mahak-bansal-315108256/'
    },
    {
        title: 'This course provides vital insights for aspiring entrepreneurs, emphasizing the importance of comprehensive analysis for business success. With practical illustrations and real-life examples, it addresses key startup questions. Highly recommended for aspiring entrepreneurs, commerce students, and professionals.',
        name: 'Ramaira',
        place: 'Kirori Mal, DU',
        imgUrl: ramaira,
        linkedIn: 'https://www.linkedin.com/in/ramaira-singh-731202218/'
    },
    {
        title: 'Prompt Engineering is a very important skill to apply. Organizations are turning ai ready with automation plugged in and VSC offers the perfect program of learning and application that gets us to keep up for job market',
        name: 'Pranshul',
        place: 'Partnership Manager',
        imgUrl: pranshul,
        linkedIn: 'https://www.linkedin.com/in/pranshul-junghare-a895b01a3/'
    },
    {
        title: 'The course is very much in depth and has covered many practical examples which brings a deeper understanding of chatgpt and how to use it in our day to day life for some small query to even bigger complex tasks . I really like to know its usage in such a wide aspect like planning any budget or get some expert opinions so easily',
        name: 'Devang',
        place: 'HDFC Bank',
        imgUrl: devang,
        linkedIn: 'https://www.linkedin.com/in/devangpurnavairagi/'
    },{
        title: 'This course is really unique as it has both practical examples and labs to perform and clear the concepts. The best part I like in this course it the way concepts are explained with the appropriate pace in which any new concepts could be understood.',
        name: 'Ayush Gupta',
        place: 'Strategic Partnership Rental Space',
        imgUrl: ayush,
        linkedIn: 'https://www.linkedin.com/in/ayushgupta16/'
    },{
        title: 'Matching my interest for a new skillset to keep me updated always helps me stay on the VSC platform. Prompting is something new and its proper understanding is crucial to generate best results.',
        name: 'Deepesh',
        place: 'Bain & Co.',
        imgUrl: deepesh,
        linkedIn: 'https://www.linkedin.com/in/deepeshjoshi/'
    },{
        title: 'Really helpful course as it has some practical examples which makes it easier to understand prompt usage and application. I liked the course as all the modules are short , crisp and easy to grasp in a short span of time',
        name: 'Suhani',
        place: 'SGKTB, DU',
        imgUrl: suhani,
        linkedIn: 'https://www.linkedin.com/in/suhani-agrawal-43ab59249/ '
    },
    {
        title: 'Prompt engineering is the much needed skill set I wanted to learn to make my way towards those jobs which can get me good opportunities for a relevant career path. And VSC made this possible to learn & apply.',
        name: 'Radhika',
        place: 'NMIMS',
        imgUrl: radhika,
        linkedIn: 'https://www.linkedin.com/in/radhika-rathi-a92821274/ '
    },
    {
        title: 'The program was really valuable. I learned a lot of things here. And the trainer is very supportive. You will also learn about resume and interview. And every student who wants to work in corporate should take it.',
        name: 'Sunny Singh',
        place: 'Business School',
        imgUrl: sunnySingh,
        linkedIn: 'https://www.linkedin.com/in/sunny-singh-6062212a5/'
    },
    {
        title: 'Virtual Startup Campus is fantastic! Their courses are practical, the industry experience program is invaluable, and the instructors are top-notch. Being a part of the product management program, I got a chance to be a part of building a product right from scratch. Perfect for upskilling and gaining real-world experience. Highly recommend!',
        name: 'Ashwini T.',
        place: 'BBA Student, Pune',
        imgUrl: ashwiniThorve,
        linkedIn: 'https://www.linkedin.com/in/ashwini-thorve-a06408293/'
    },
    {
        title: 'My experience at the virtual startup campus has been phenomenal! I learned so much from the course, discovering various aspects of the startup world. The expert guest lecture was outstanding and provided invaluable insights. I gained a wealth of experience here and highly recommend it to anyone looking to deepen their knowledge and skills in this field.',
        name: 'Yash Kumar',
        place: 'Geeta University',
        linkedIn: 'https://www.linkedin.com/in/yashkumar80/',
        imgUrl: yash,

    },
    {
        title: 'Virtual Startup Campus offers exceptional Corporate Trainings that provide deep insights into real-world corporate settings. Their practical, hands-on method simplifies understanding role expectations and operations, making it essential for career preparation and professional growth.',
        name: 'Yunus',
        place: 'Intern, Bajaj Capital',
        linkedIn: 'https://www.linkedin.com/in/yunus-hasan/',
        imgUrl: yunus,

    },
    {
        title: 'The program was highly valuable, providing a wealth of knowledge. The trainer is very supportive, and you will also gain insights into resume writing and interview skills. Any student aiming to work in the corporate world should definitely consider taking it.',
        name: 'Akshat',
        place: 'BBA Student',
        linkedIn: 'https://www.linkedin.com/in/akshat-shrey-68a893301/',
        imgUrl: akshat,

    },
    {
        title: 'Virtual Startup Campus\' corporate trainings provide invaluable exposure to real-world corporate environments, offering practical insights into role expectations and operations. The hands-on approach makes them highly beneficial for understanding and preparing for specific roles. Highly beneficial for anyone looking to excel in a corporate career!',
        name: 'Yashasvi',
        place: 'Zakir Khan College, DU',
        linkedIn: 'https://www.linkedin.com/in/yashasvi-bali-36309a292/',
        imgUrl: yashasvi,

    },
    {
        title: 'The Corporate Trainings provided by Virtual Startup Campus are truly enlightening. They offer a deep dive into corporate operations making it easier to grasp what is expected in different roles. This practical exposure is crucial for professional growth and has been very helpful in preparing me for my career. Excellent training!',
        name: 'Nitin',
        place: 'IIT Madras, Data Science',
        linkedIn: 'https://www.linkedin.com/in/nitin-dixit-101637290/',
        imgUrl: nitin,

    }

];
