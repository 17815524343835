import React,{useState} from 'react';
import Typography from '@mui/material/Typography';
import Fade from '@mui/material/Fade';
import { Button } from '@mui/material';
import { CoronavirusRounded } from '@mui/icons-material';

const CourseBanner = ({ startCourse,course }) => {
  const [countdown, setCountdown] = useState(10);
  const [show , setShow] = useState(false);

  const handleStartCourse = () => {
    // Perform any actions needed before starting the course
    startCourse();
    // setShow(true);
    // // Start the countdown
    // const intervalId = setInterval(() => {
    //   setCountdown((prevCount) => prevCount - 1);
    // }, 1000);

    // // After 5 seconds, stop the countdown and start the course
    // setTimeout(() => {
    //   clearInterval(intervalId);
    //   startCourse();
    // }, 6000);
  };
  return (
    <Fade in={true} timeout={2000} style={{marginLeft:'0'}}> 
      <div style={bannerStyles}>
        <Typography variant="h6" style={titleStyles}>
          Welcome To {course.courseName}
          </Typography>
          <br />
          <Typography variant='caption' style={captionStyles}>
          By Markoknow
          </Typography>
          <Button variant='contained' onClick={handleStartCourse} style={{background: '#6A58C6' , margin:'20px'}}>Start Course</Button>
          {/* {show && (
          <Typography variant='h6' style={{ marginTop: '10px' }}>
            Course Starting In: {countdown}s
          </Typography>
          )} */}
          <Typography>Guidelines</Typography>
          <ol style={{
            margin:'10px',
            padding: '5px',
            width:'80%'
          }}>
            <li>
              <Typography variant='subtitle2' gutterBottom>After every video completion , click the button “Completed” on the upside right corner for course completion </Typography>
            </li>
            <li>
              <Typography variant='subtitle2' gutterBottom>You would be required to complete the course 100% to generate the certificate  </Typography>
            </li>
            <li>
              <Typography variant='subtitle2' gutterBottom>The certificate would be generated after 48 hours of the course</Typography>
            </li>
            <li>
              <Typography variant='subtitle2' gutterBottom>For any query, send an email to support@virtualstartupcampus.com </Typography>
            </li>
          </ol>
          <img src={course.coursePreview} style={imageStyles}/>

      </div>
    </Fade>
  );
};

// Styles
const bannerStyles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  // textAlign: 'center',
  background: '#f2f2f2',
};

const titleStyles = {
  fontSize: '35px',
  fontWeight: '700',
  lineHeight: '62px',
  fontFamily: 'Poppins, sans-serif',
  color: '#1a202c',
  textAlign:'center'
};
const captionStyles = {
    fontSize: "25px",
              width: "75%",
              padding: "20px",
              fontWeight: "700",
              marginTop: "-2rem",
              fontFamily: "Poppins, sans-serif",
              textAlign: "center",
              color: "#1a202c",
}

const imageStyles = {
  width: '100%',
  maxWidth: '500px',
  height: 'auto',
};

export default CourseBanner;
