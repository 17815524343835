import React from 'react';
import {Box , Typography} from '@mui/material'
import Testimonial from '../../Testimonial';

const Testimonials = () => {
  return (
    <Box
        sx={{
            display: 'flex',
            flexDirection: {md: 'row' , xs: 'column'},
            justifyContent: 'center' , 
            alignItems: 'center',
            padding: '20px'
        }}
    >
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column', 
                alignItems: {xs: 'center' , md: 'flex-start'}, 
                justifyContent: {xs: 'center' , md: 'flex-start'}, 
                gap: '10px',
                width: {md:'20%' , xs: '100%'},
                padding: {xs: '10px', md: '20px'}
            }}
        >
            <Typography variant="h6"  sx={{textAlign: {xs: 'center' , md: 'left'},mb: 2 , width: 140 , height: 50 , background: '#FFBD59' , borderRadius: '10px' , display: 'flex' , justifyContent: 'center' , alignItems: 'center'}}>Testimonials</Typography>
            <Typography variant="subtitle1" sx={{textAlign: {xs: 'center' , md: 'left'}}}>Virtual Startup Campus<br/> <span style={{fontWeight: 500}}>Trainees have acknowledged</span></Typography>

        </Box>
        <Box
            sx={{
                width: {md: '80%' , xs: '100%'}
            }}
        >
            <Testimonial from={'show'}/>
        </Box>
    </Box>
  )
}

export default Testimonials