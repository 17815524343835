import React from "react";
import {useNavigate} from 'react-router-dom';
import { useState, useEffect } from "react";
import { validateEmail } from "../../../auth/validateEmail";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { Typography } from "@material-ui/core";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { GET_COURSES } from "../../../../api/vsc";
import { APPLY_COURSE } from "../../../../api/course";
import { makeStyles } from "@mui/styles";
import { GET_EVENTS, POST_CERTIFICATE_REGISTER } from "../../../../api/event";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const CertificateGenForm = () => {
  const [isAuth, setIsAuth] = React.useState(window.localStorage.getItem('isAuth') || false);
  const navigate = useNavigate();
  const [fName, setFName] = useState("");
  const [lName, setLName] = useState("");
  const [contactNo, setContactNo] = useState("");
  const [email, setEmail] = useState("");
  const [collegeName, setCollegeName] = useState("");
  const [startupIdea, setStartupIdea] = useState("");
  const [topics, setTopics] = useState("");
  const [open, setOpen] = useState(false);
  const [currencies , setCurrencies] = useState([])
  const [courseId  , setCourseId] = useState(null);
  const [emailError, setEmailError] = useState("");
  const [contactNoError, setContactNoError] = useState("");
  const [submitted , setSubmitted] = useState("");


  useEffect(() => {
    if (!email) {
      setEmailError("");
    } else {
      if (validateEmail(email)) {
        setEmailError("");
      } else {
        setEmailError("Please enter a valid email.");
      }
    }
  }, [email]);

  useEffect(() => {
    if (!contactNo) {
      setContactNoError("");
    } else {
      if (contactNo.length === 10) {
        setContactNoError("");
      } else {
        setContactNoError("Please Enter valid contactNo.");
      }
    }
  }, [contactNo]);
  useEffect(() => {
    axios
    .get(GET_EVENTS)
    .then((data) => {
      const fetchedCourses = data.data.data.map((course) => ({
        value: course._id,
        label: course.title,
      }));
      setCurrencies(fetchedCourses);
    })
    .catch((err) => {
    });
  }, []);
  const MakeAllFalse = () => {
    setFName("");
    setLName("");
    setContactNo("");
    setEmail("");
    setCollegeName("");
    setStartupIdea("");
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      fName: fName,
      lName: lName,
      wNumber: contactNo,
      email: email,
      collegeName: collegeName,
      topics: topics
    };

    axios
      .post(POST_CERTIFICATE_REGISTER+window.localStorage.id+"/"+courseId, data)
      .then((res) => {
        MakeAllFalse();
        setSubmitted(true);
        setOpen(true);
        setTimeout(() => {
        // isAuth? navigate('/myDashboard/'+window.localStorage.id):navigate('/login' , {state:{redirect:'/myDashboard/'+window.localStorage.id}})
          
        }, 6000);
      })
      .catch((err) => {});
  };
  const classes = useStyles();
  const styles = {
    radioButton: {
      display: "inline-block",
      marginRight: "5px",
      backgroundColor: "red",
      borderRadius: "50%",
      width: "30px",
      height: "30px",
    },
    label: {
      display: "flex",
      fontFamily: "sans-serif",
      fontSize: "20px",
      fontWeight: "bold",
      color: "black",
    },
  };
  return (
    <>
      <div className={classes.fullpage} style={{width:'100%'}}>
        <div className={classes.centerdiv}>
          <Typography variant="h3" className={classes.head} style={{fontSize:'32px' , textAlign:'center'}}>
          Claim Your Certificate
          </Typography>
          <div className={classes.formdiv} style={{width:'100%'}}>
          {submitted ? (
            <>
            <Typography variant="h6" className={classes.head} style={{color:'#fff' ,padding:'0 20px 20px 20px'}}>
            The form was submitted successfully.
          </Typography>
          <Typography variant="body2" className={classes.head} style={{color:'#fff' , padding:'0 20px 20px 20px'}}>
            You Would Soon Receive Your Certificate Once Admin Approves Your Certificate
          </Typography>
          </>
          ): (
            
            <form className={classes.form} onSubmit={handleSubmit}>
              <div className={classes.rowinput}>
                <div className={classes.inputcontainer}>
                  <input
                    type="text"
                    class={classes.input_box}
                    required
                    value={fName}
                    onChange={(e) => setFName(e.target.value)}
                    placeholder="First Name"
                  />
                </div>
                <div className={classes.inputcontainer}>
                  <input
                    type="text"
                    class={classes.input_box}
                    required
                    value={lName}
                    onChange={(e) => setLName(e.target.value)}
                    placeholder="Last Name"
                  />
                </div>
              </div>
              <span style={{ color: "red" }}>{contactNoError}</span>
              <div className={classes.rowinput}>
                <input
                  type="text"
                  class={classes.input_box2}
                  required
                  value={contactNo}
                  onChange={(e) => setContactNo(e.target.value)}
                  placeholder="Contact No."
                />
              </div>
              <span style={{ color: "red" }}>{emailError}</span>
              <div className={classes.rowinput}>
                <input
                  type="email"
                  class={classes.input_box2}
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Email"
                />
              </div>
              <div className={classes.rowinput}>
                <input
                  type="text"
                  class={classes.input_box2}
                  value={collegeName}
                  onChange={(e) => setCollegeName(e.target.value)}
                  placeholder="College Name / Organization / Not Applicable (N/A)"
                />
              </div>
              <div className={classes.rowinput}>
                <div
                  class={classes.input_box2}
                  style={{
                    color: "black",
                    textAlign: "start",
                    fontFamily:'Poppins'
                  }}
                >
                  Event Attended ? Mention Name !
                </div>
              </div>
              <div className={classes.rowinput}>
              <TextField
          id="outlined-select-currency"
          select
          label="Select Event"
          className={classes.input_box2}
          InputLabelProps={{
            style:{color:'#000' , borderColor:'#000' , fontFamily:'Poppins' }
            // style={styles.label}
          }}s
          onChange={(e) => setCourseId(e.target.value)}
        >
          {currencies.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
              </div>
              <div className={classes.rowinput}>
                <input
                  type="text"
                  class={classes.input_box2}
                  value={topics}
                  onChange={(e) => setTopics(e.target.value)}
                  placeholder="What other topics are you interested in?"
                />
              </div>
              <div className={classes.rowinput}>
                <button type="submit" className={classes.submitbtn}>
                  Submit
                </button>
              </div>
            </form>
          
          )}
          </div>
        </div>
      </div>
    </>
  );
};

const useStyles = makeStyles({
  fullpage: {
    width: "100%",
    // height: "100%",
    // background: "linear-gradient(to top left, #6A58C6, #a8f5ff) !important",
    background:'#fff',
    display: "flex",
    // justifyContent: "center",
    // alignItems:'center',
    marginTop:'20px'
  },
  head: {
    fontWeight: "bold",
    color: "#000",
    fontFamily: "Poppins",
    marginTop: "2rem",
    padding:'10px 10px 0 10px'
  },
  centerdiv: {
    width: "90%",
    color: "white",
    // padding: "10px 10px 0px 10px",
    margin: "5px",
    // border: "1px solid white",
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    paddingBottom: "100px",
  
  },
  formdiv: {
    margin: "2rem 10px 10px 10px ",
    padding: "1%",
    borderRadius: "10px",
    backdropFilter: "blur(20px)",
    // border: "1px solid white",
    // background: "linear-gradient(to top left, #ffffffff,#ffffff33)",
    width: '100%',
    background: '#6A58C6',
    boxShadow: "2px 2px 5px #6A58C6",
  },
  rowinput: {
    display: "flex",
    width: "100%",
    // border: "1px solid red",
    padding: "5px",
    alignItems: "center",
    justifyContent: "center",
  },
  inputcontainer: {
    width: "50%",
    // border: "1px solid yellow",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    // padding:'5px'
  },
  input_box: {
    width: "90%",
    padding: "10px",
    borderRadius: "10px",
    fontSize: "1.2rem",
    fontFamily: "sans-serif",
    backgroundColor: "#ffffff",
    border: "none",
    outline: "none",
  },
  input_box2: {
    width: "95%",
    padding: "10px",
    borderRadius: "10px",
    fontSize: "1.2rem",
    fontFamily: "sans-serif",
    backgroundColor: "#ffffff",
    border: "none",
    outline: "none",
  },
  submitbtn: {
    width: "100%",
    padding: "8px",
    fontSize: "1.5rem",
    fontFamily: "sans-serif",
    fontWeight: "600",
    borderRadius: "10px",
    border: "none",
    outline: "none",
    cursor: "pointer",
    color: "white",
    backgroundColor: "#000",
  },
  "@media(max-width:1400px)": {
    centerdiv: {
      width: "85%",
    },
  },

  "@media(max-width:480px)": {
    fullpage: {
      height: "45%",
      paddingBottom: "28%",
    },
    centerdiv: {
      width: "100%",
      paddingBottom: "0",
    },
    head: {
      fontSize: "7vw",
    },
    submitbtn: {
      padding: "18px",
      fontSize: "1.2rem",
    },
  },
  "@media(max-width:345px)": {
    input_box: {
      fontSize: "1rem",
    },
    input_box2: {
      fontSize: "1rem",
    },
    submitbtn: {
      padding: "18px",
      fontSize: "1rem",
    },
  },
});
export default CertificateGenForm;
