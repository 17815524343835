import { Box , Typography , Divider ,Button , useMediaQuery , useTheme} from '@mui/material';
import {useParams} from 'react-router-dom'
import * as React from 'react';
import sign from '../../../Certificate/Signature.png';
import TC from './Images/T&B.png'
import shortid from 'short-uuid';
import { LinkedinShareButton , LinkedinIcon , WhatsappShareButton , WhatsappIcon} from 'react-share';
import html2canvas from 'html2canvas';
import axios from 'axios';
import { GET_EVENT , GET_SINGLE_EVENT_CERTI, SET_OPENED } from '../../../../api/event';

const Certificate = () => {
    const [name , setName] = React.useState('');
    const [eventName , setEventName] = React.useState('');
    const [topics , setTopics] = React.useState([]);
    const [pending , setPending] = React.useState(false);
    const dividerStyle = {
        bgcolor: '#000',
        height: '2px',
        width: '100%',
      };
      const params = useParams();
      const theme = useTheme();
      const certificateRef = React.useRef(null); 
      const [earned , setEarned] = React.useState(false);
      const uid = 'VSC-'+ shortid().new();
      const cLink = 'https://www.campus.markoknow.com/viewcertificate/'+uid;
      const downloadCertificate = async () => {
        const canvas = await html2canvas(certificateRef.current);
        const dataURL = canvas.toDataURL('image/png');
        const link = document.createElement('a');
        link.href = dataURL;
        link.download = `${window.localStorage.name}-${uid}-certificate.png`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      };
      const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
      React.useEffect(() => {
        axios
        .get(GET_SINGLE_EVENT_CERTI+params.id)
        .then(res => {
            setEarned(res.data.data.status === "accepted" && true);
            setPending(res.data.data.status === "pending" && true);
            setName(res.data.data.fName+" "+res.data.data.lName);
            setTopics(res.data.data.topics.split(','))
            axios
            .get(GET_EVENT + res.data.data.eventId)
            .then(res => {
                setEventName(res.data.data.title);
            })
            .catch(err => {
            })
            axios.patch(SET_OPENED+params.id).then(res => {}).catch(err => {});
        })
        .catch(err => {
        })
      },[]);
  return (
    <Box sx={{
        marginTop:'100px',
        width: '100%',
        height: '100%',
        display:'flex',
        justifyContent:'center',
        alignItems: 'center',
        flexDirection: {xs: 'column', sm: 'row'},
        gap:'20px'
    }}>
    {earned ? (
        <>
        <Box ref={certificateRef} sx = {{
            width: {xs: '98%',sm:'70%'},
            height: {xs: '32vh' , sm:'100%'},
            background:'#6a58c6',
            display:'flex',
            justifyContent:'center',
            alignItems: 'center',
            padding: '10px',
        }}>
            <Box
                sx={{
                    borderRadius:'20px',
                    width: '100%',
                    bgcolor:"white",
                    height: '100%',
                    display:'flex',
                    flexDirection:'column',
                    justifyContent:'center',
                    alignItems: 'center',
                    gap:{xs: '5px' , sm: '20px'},
                    position: 'relative',
                    padding: '10px'
                }}
            >   
                <Box 
                    sx={{
                        position:'absolute',
                        top: '8%',
                        left: '3%'
                    }}
                >
                    <img 
                        src={TC} 
                        alt="image"
                        style={{
                                width: fullScreen ? 60: 130,
                                height: fullScreen ? 60: 130,
                        }}  
                        />
                </Box>
                <Typography 
                    sx={{
                        background:'#FFBD59',
                        // width: '200px',
                        height: {xs: '25px',sm:'40px'},
                        textAlign: 'left',
                        display:'flex' , 
                        justifyContent:'center',
                        alignItems:'center',
                        borderRadius: '10px' ,
                        fontWeight:'700',
                        fontFamily:'Poppins',
                        padding: {xs:'2px',sm:'5px'} ,
                        fontSize: {xs: '10px' , sm:'14px'}

                    }}
                >
                    Virtual Startup Campus
                </Typography>
                <Typography 
                variant='subtitle1'
                 sx={{
                    fontFamily:'Poppins',
                    fontSize: {xs: '10px' , sm:'14px'}

                }}>
                     Participation Certificate in
                </Typography>
                <Typography 
                    variant="h3"
                    sx={{
                    fontFamily:'Poppins',
                    fontSize: {xs: '14px' , sm: '28px'},
                    fontWeight:'700',

                    }}
                >
                    {eventName}
                </Typography>
                <Typography 
                variant='subtitle1'
                 sx={{
                    fontFamily:'Poppins',
                }}>
                     awarded to
                </Typography>
                <Typography 
                    variant="h2"
                    sx={{
                    fontFamily:'Poppins',
                    fontSize: {xs: '18px' , sm: '32px'},
                    fontWeight:'700',

                    }}
                >
                    {name}
                </Typography>
                <Typography 
                variant='subtitle1'
                 sx={{
                    fontFamily:'Poppins',
                    fontSize: {xs: '10px' , sm:'14px'}


                }}>
                     For attending Bootcamp with Virtual Startup Campus learning
                </Typography>
                <Typography 
                variant='subtitle1'
                 sx={{
                    fontFamily:'Poppins',
                    fontSize: {xs: '10px' , sm:'14px'}
                }}>
                     {topics[0]} | {topics[1]} | {topics[2]}
                </Typography>   
                <Box
                    sx={{
                        textAlign: 'center',
                        fontFamily: 'Montserrat',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: {xs:'30%',sm:'20%'},
                    }}
                    >
                    <span style={{ fontSize: fullScreen?'6px':'12px' }}>
                        <img src={sign} alt='Kaushiki Kesarwani' width={fullScreen?'50':'100'} height={fullScreen?'25':'50'} />
                        <Divider sx={dividerStyle} />
                    </span>
                    <Typography variant="h5" sx={{
                        fontSize: {xs: '16px' , sm: '26px'}
                    }}>
                        Kaushiki K
                    </Typography>
                    <Typography variant='subtitle' sx={{
                             fontSize: {xs: '10px' , sm:'14px'}

                    }}>
                        Chief Executive Officer
                    </Typography>
                    <Typography variant='subtitle2' sx={{
                            fontSize: {xs: '10px' , sm:'14px'}

                    }}>
                        Virtual Startup Campus
                    </Typography>
                    </Box>
                    <Box 
                        sx={{
                            position:'absolute',
                            bottom: '5%',
                            right: '3%'
                        }}
                    >
                        <img 
                            src={TC} 
                            alt="image"
                            style={{
                                width: fullScreen?40 :80,
                                height: fullScreen?40 :80
                            }}    
                            />
                    </Box>
            </Box>
        </Box>
        <Box
            sx={{
                alignSelf: {xs:'center' , sm: 'flex-start'}
            }}
        >           
                <Button variant='contained' onClick={downloadCertificate} sx={{ marginTop: {xs: '15px' , sm: '30px'} , backgroundColor:'#6A58C6' }}>
                    Download Certificate
                </Button>
                <Box style={{marginTop:'10px'}}>
                <LinkedinShareButton url={cLink}>
                    <LinkedinIcon round={true}/>
                </LinkedinShareButton>
                <WhatsappShareButton url={cLink}>
                    <WhatsappIcon round={true}/>
                </WhatsappShareButton>
                </Box>
        </Box>
        </>
    ): (
        pending ? (
            <Typography> Your Certificate Request Is Pending Please Wait For It To Get Accepted.(It Takes 7-10 Days)</Typography>
        ):(
            <Typography> Your Certificate Request Is Rejected.</Typography>
        
        )
    )}
        
    </Box>
  )
}

export default Certificate