import React from 'react';
import { Box , Typography , Button } from '@mui/material';
import product from '../Images/product.jpg';
import management from '../Images/management.jpg';
import finance from '../Images/finance.jpg';
import ApplyDialog from '../ApplyDialog';
const ProgramCovers = () => {
  return (
    <Box
        sx={{
            display: 'flex' , 
            flexDirection: 'column',
            justifyContent: 'center' , 
            alignItems: 'center'
        }}
    >
         <Typography variant="h4" align="center" sx={{ mb: 2, width: { xs: '100%', md: '30%' } }}>
                    What this <span style={{ color: "#6a58c6", fontWeight: 600 }}>Program Covers</span>
        </Typography>
        <Box
            sx={{
                display: 'flex', 
                alignItems: 'center',
                flexWrap: 'wrap',
                justifyContent: 'center',
                gap: '20px'
            }}
        >
            <Box
                component={'card'}
                sx={{
                    width: {md: 300, xs: 250},
                    height: 400,
                    display: 'flex' , 
                    flexDirection: 'column' , 
                    alignItems: 'center' , 
                    justifyContent: 'center' , 
                    gap: '10px',
                    padding: '10px'

                }}
            >
                <Box
                    component={'img'}
                    src={finance}
                    sx={{
                        width: '100%',
                        height: 200
                    }}
                />
                <Typography
                    variant="h6"
                    sx={{
                        textAlign: {md: 'left' , xs: 'center'}
                    }}
                >Finance Manager</Typography>
                <Typography
                    variant="body1"
                    sx={{
                        textAlign: {md: 'left' , xs: 'center'}
                    }}
                >Application of Finance in project based and case basis to apply financial analysis and modelling on excel</Typography>
                {/* <Button variant="outlined" sx={{color: 'black' , border: '2px solid #000' , mt: 2}}>Know More</Button> */}
                <ApplyDialog/>


            </Box>
            <Box
                component={'card'}
                sx={{
                    width: {md: 300, xs: 250},
                    height: 400,
                    display: 'flex' , 
                    flexDirection: 'column' , 
                    alignItems: 'center' , 
                    justifyContent: 'center' , 
                    gap: '10px',
                    padding: '10px'

                }}
            >
                <Box
                    component={'img'}
                    src={product}
                    sx={{
                        width: '100%',
                        height: 200
                    }}
                />
                <Typography
                    variant="h6"
                    sx={{
                        textAlign: {md: 'left' , xs: 'center'}
                    }}
                >Product Manager</Typography>
                <Typography
                    variant="body1"
                    sx={{
                        textAlign: {md: 'left' , xs: 'center'}
                    }}
                >Application of product research to mapping for product feature prioritization with project and portfolio</Typography>
                {/* <Button variant="outlined" sx={{color: 'black' , border: '2px solid #000', mt: 2}}>Know More</Button> */}
                <ApplyDialog/>

            </Box>
            <Box
                component={'card'}
                sx={{
                    width: {md: 300, xs: 250},
                    height: 400,
                    display: 'flex' , 
                    flexDirection: 'column' , 
                    alignItems: 'center' , 
                    justifyContent: 'center' , 
                    gap: '10px',
                    padding: '10px'
                }}
            >
                <Box
                    component={'img'}
                    src={management}
                    sx={{
                        width: '100%',
                        height: 200
                    }}
                />
                <Typography
                    variant="h6"
                    sx={{
                        textAlign: {md: 'left' , xs: 'center'}
                    }}
                >Management Consultant</Typography>
                <Typography
                    variant="body1"
                    sx={{
                        textAlign: {md: 'left' , xs: 'center'}
                    }}
                >Application of problem solving techniques and strategy development used at top consulting firms on real time cases</Typography>
                {/* <Button variant="outlined" sx={{color: 'black' , border: '2px solid #000'}}>Know More</Button> */}
                <ApplyDialog/>

            </Box>
        </Box>
    </Box>
  )
}

export default ProgramCovers