import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { IconButton } from '@mui/material';
import { Link } from 'react-router-dom';
import LinkedInIcon from '@mui/icons-material/LinkedIn';


export default function Singlecard({ testimonial }) {
    
  return (
    <><span style={{fontSize:'84px' , color:'#6a58c6' , fontWeight:'700'}}> &#x275D;</span>
    <Card sx={{ maxWidth: 345  }} style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px, rgb(51, 51, 51) 0px 0px 0px 3px'}}>
      <CardContent style={{height:'70%'}}>
        <Typography variant="h6" style={{color:'#000', fontWeight:'500' , fontSize:'17px'}}>
          {testimonial.title}
        </Typography>
      </CardContent>
      <CardActions style={{position:'relative' , height: '30%' }}>
       
        <CardMedia
           sx={{ width: 60 , height: 70 , borderRadius: '10px'}}
           image={testimonial.imgUrl} 
        />
        <div>
        <Typography variant="h6" textAlign={'left'} margin={0} style={{fontFamily:'Poppins'}}>{testimonial.name}
        <Typography variant="subtitle1" textAlign={'left'} style={{fontFamily:'Poppins' , fontWeight:'300'}}>{testimonial.place}</Typography></Typography>
        
        </div>
        <Link style={{position:'absolute' , bottom:'0' , right:'0'}} to={testimonial.linkedIn}><IconButton><LinkedInIcon style={{fontSize:'30px',  color: '#0077b5'}}/></IconButton></Link>

      </CardActions>
      {/* <CardContent style={{display:'flex',  justifyContent:'flex-end' , alignItems:'flex-start'}}>
      <Link to="https://www.linkedin.com/in/vivek-mishra-11a7b433/"><IconButton><LinkedInIcon style={{fontSize:'50px',  color: '#0077b5'}}/></IconButton></Link>
      </CardContent> */}
    </Card>
    </>
  );
}