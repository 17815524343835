import React, { useEffect, useState } from "react";
import { useMediaQuery, Box, Typography, Button, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Footer from "../../Footer/Footer";
import DownloadBrochureDialog from '../DownloadForOthers';
import Testimonial from "../../Testimonial";
import Accordion from "../Accordion/index";
import InfoBox from "../InfoBox";
import { faqs } from '../FAQDATA';
import weekend from '../Images/weekend.png';
import resume from '../Images/resume.png';
import mock from '../Images/mock.png';
import internship from '../Images/internship.png';
import senior from '../Images/senior.png';
import management from '../Images/management.png';
import capstone from '../Images/capstone.png';
import profile from '../Images/profile.png';
import certificate from '../Images/financeworkex.png';
import workex from '../Images/financeprogram.png';
import project from '../Images/financeproject.png';
import "../../../stylesheets/financeanalyst.css";
import ImageMover from '../../CorporateExposureProgram/ImageMover';
import IndustryExperts from '../../CorporateExposureProgram/IndustryExperts';
import Colleges from '../../CorporateExposureProgram/Colleges';
import EnrollUserDialog from '../EnrollUserDialog';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { GET_COURSE } from "../../../api/course";
import { GET_MODULES, VIDEO , PDF} from "../../../api/vsc";
import axios from 'axios';
import ProgramCurriculum from "../ProgramCurriculum";
const FullStackDeveloper = ({ isAuth, setAuth }) => {
  const [sidebarFixed, setSidebarFixed] = useState(true);
  const [img, setImg] = useState(certificate);
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width:820px)');
  const [loading , setLoading] = useState(true);
  
  useEffect(() => {
    const handleScroll = () => {
      const certificateBox = document.getElementById('training');
      const sidebar = document.querySelector('.sidebar');
      
      if (certificateBox && sidebar) {
        const rect = certificateBox.getBoundingClientRect();
        const sidebarHeight = sidebar.offsetHeight;
        
        if (rect.top <= sidebarHeight) {
          setSidebarFixed(false);
        } else {
          setSidebarFixed(true);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const [courseData, setCourseData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Step 1: Fetch the course
        const courseResponse = await axios.get(`${GET_COURSE}Finance Manager Pathway`);
        const courseId = courseResponse.data.data._id;

        // Step 2: Fetch the modules for the course
        const modulesResponse = await axios.get(`${GET_MODULES}${courseId}`);
        const modules = modulesResponse.data.message;

        // Step 3: Fetch the videos and PDFs for each module
        const modulesWithContent = await Promise.all(modules.map(async (module) => {
          const [videosResponse, pdfsResponse] = await Promise.all([
            axios.get(`${VIDEO}${module._id}`),
            axios.get(`${PDF}${module._id}`)
          ]);

          return {
            ...module,
            videos: videosResponse.data.data,
            pdfs: pdfsResponse.data.data
          };
        }));

        // Step 4: Set the course data with modules and their content
        setCourseData({
          ...courseResponse.data,
          modules: modulesWithContent
        });
        setLoading(false);
        console.log({
          ...courseResponse.data,
          modules: modulesWithContent
        })
      } catch (error) {
        console.error("Error fetching course data:", error);
      }
    };

    fetchData();
  }, []);
  const training_elements = [
    "Apply financial analysis in real time cases",
    "Create Financial Models in excel",
    "Get trainings in the role of Financial Analyst",
  ];

  const arr = [
    { title: "8 Weekend Corporate Training Live Session", imgPath: weekend },
    { title: "Resume Building Session", imgPath: resume },
    { title: "Mock Interview Practice Scheduling", imgPath: mock },
    { title: "Intership/Job Profile Review", imgPath: internship },
    { title: "Senior Management Interaction", imgPath: senior },
    { title: "Finance Portfolio Building", imgPath: management },
    { title: "Capstone Project", imgPath: capstone },
    { title: "Profile SWOT Analysis", imgPath: profile }
  ];

  return (
    <>
      <div className="details_main_prompt-f">
        <div className="headingp-f" >
          <h1>Financial Analysis & Management <br/> Program in 1 Month</h1>
          <Typography className="yellow-box-f" style={yellowBoxStyle}>Learn -> Apply -> Job Ready</Typography>
          <Typography variant="h4" className="yellow-box-f" style={headingStyle}>You Get To:</Typography>
          <div className="tick-boxes-prompt-box3-f">
            {training_elements.map((t, i) => (
              <p style={tickBoxStyle} key={i}>
                <CheckCircleIcon style={checkCircleStyle} />
                {t}
              </p>
            ))}
          </div>
          <div className="buttons-promtl-f">
          <Box
            sx={{
              width: '100%',
            }}
          >
          <EnrollUserDialog isMobile={isMobile} courseName={'Finance Manager Pathway'}/>
          <DownloadBrochureDialog isMobile={isMobile} courseName={'Finance Manager Pathway'} />
          </Box>
          </div>
        </div>

        <div className="box_of_data-f">
          <div className="sidebar2"></div>
          <div className={`sidebar`} style={{position: !sidebarFixed && 'absolute' , top: !sidebarFixed && 700}}>
            <div style={{ display: "flex justify-around" }}>
              <p className="sidebar_heading">Financial Analyst<br /></p>
              <a href="#outcome-box"><p className="sidebar_data">Programme Snapshot</p></a>
              <a href="#certificate-box"><p className="sidebar_data">Work Ex Certificates</p></a>
              <a href="#vsc-students"><p className="sidebar_data">Vsc Students</p></a>
              <a href="#vsc-pathway"><p className="sidebar_data">Pathway</p></a>
              <a href="#vsc-tools"><p className="sidebar_data">Tools</p></a>
              <a href="#vsc-timeline"><p className="sidebar_data">Timeline</p></a>
              <a href="#vsc-faq"><p className="sidebar_data">FAQ</p></a>
            </div>
          </div>
        </div>

        <Box sx={{ marginBottom: '20px', marginLeft: { sm: '0', md: '22rem' } }} id="training">
          <Typography variant="h6" textAlign={'left'} marginBottom={'20px'} fontFamily={'Poppins'} fontWeight={'500'}>
            Get training, practice in Labs, work on live projects and get work experience in just 1 Month
          </Typography>
          <Box sx={boxStyle}>
            {arr.map((ele, i) => (
              <Box key={i} sx={innerBoxStyle}>
                <Box sx={{ width: '20%' }}>
                  <img src={ele.imgPath} width="80" height="80" />
                </Box>
                <Typography variant="subtitle1" sx={{ fontWeight: '400', width: '80%' }}>
                  {ele.title}
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>

        <div style={{marginTop: '40px' , marginBottom: '40px'}}>
        <ImageMover />

        </div>
        {courseData && <div style={{marginTop: '40px' , marginBottom: '40px' , width: '100%' , display: 'flex' , alignItems: 'center' , justifyContent: 'center'}}>
        <div style={{width: isMobile ? '100%' : '60%'}}>
        {loading ? <CircularProgress/>:<ProgramCurriculum data={courseData} />}
      </div>
        </div>}
        <h2 style={centeredHeadingStyle}><u style={underlineStyle}>Industry Certificates</u></h2>
        <div className="certificate-box-f" id="certificate-box">
          <h2>Work Ex Certificates</h2>
          <p>This program offers a unique <br />opportunity to gain 3 Certificates</p>
          <div className="buttons-certificate-box-f">
            <div className="button-certificate-box-f" onClick={() => setImg(certificate)}>Lab Training</div>
            <div className="button-certificate-box-f" onClick={() => setImg(project)}>Capstone Project</div>
            <div className="button-certificate-box-f" onClick={() => setImg(workex)}>Internship Certificate</div>
          </div>
          <div className="cert-image-container-f">
            <img src={img} alt="certificate" className="certificate-img-f" />
          </div>
        </div>

       
        <div style={{marginTop: '40px' , marginBottom: '40px'}}>
        <IndustryExperts />

        </div>
        <div className="vsc-students-f" id="vsc-students">
          <h1><u style={underlineStyle}>VSC Students</u></h1>
          <Testimonial from='show' />
        </div>
        <div style={{marginTop: '40px' , marginBottom: '40px'}}>
        <Colleges />

        </div>
        <div className="pathway-prompt-f" id="vsc-pathway">
          <h2><u style={underlineStyle}>Pathway</u></h2>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div className="vertical-dots-f">
              <div className="dot-f"></div>
              <div className="dot-f"></div>
              <div className="dot-f"></div>
              <div className="dot-f no-line"></div>
            </div>
            <div className="pathway-container-f">
              <div className="pathway-box-f" style={{ backgroundColor: '#6a58c6', borderRadius: '20px', color: '#fff' }}>
                <h2 style={{ color: '#fff' }}>Week 1 : Training Modules</h2>
                <p>Learn the concepts end to end with self paced modules to help you get the idea on the topics</p>
              </div>
              <div className="pathway-box-f" style={{ backgroundColor: '#FF5757', borderRadius: '20px', color: '#fff' }}>
                <h2 style={{ color: '#fff' }}>Week 2 : Capstone Project</h2>
                <p>Apply Learning from Training in a Capstone Project to where you understand your own foothold on the topics.</p>
              </div>
              <div className="pathway-box-f" style={{ backgroundColor: '#7AFFC9', borderRadius: '20px', color: '#000' }}>
                <h2>Week 3 : Remote Industrial Work Exp.</h2>
                <p>Start Your Remote Corporate Training as a Trainee on the Job training for more understanding of the role</p>
              </div>
              <div className="pathway-box-f" style={{ backgroundColor: '#FFBD59', borderRadius: '20px', color: '#000' }}>
                <h2>Week 4 : Remote Industrial Work Exp.</h2>
                <p>Get help with final portfolio presentation, resume building, Mock Interview, Interview Preparation</p>
              </div>
            </div>
          </div>
        </div>

        <div className="suitable-for-prompt-f">
          <h2><u style={underlineStyle}>Suitable For</u></h2>
          <p style={{display: 'flex' , gap: '10px' , alignItems: 'center'}}><FiberManualRecordIcon sx={{color: '#000'}}/> Students Looking to get Jobs in Finance Roles or build a career in finance </p>
          <p style={{display: 'flex' , gap: '10px' , alignItems: 'center'}}><FiberManualRecordIcon sx={{color: '#000'}}/> Students who are doing MBA</p>
          <p style={{display: 'flex' , gap: '10px' , alignItems: 'center'}}><FiberManualRecordIcon sx={{color: '#000'}}/> Students looking to attain management roles in future in their Job</p>
        </div>

        <div className="borderbox-for-prompt-f" id="vsc-timeline">
          <InfoBox />
        </div>

        <div className="faq-box-prompt-f" id="vsc-faq">
          <h2><u style={underlineStyle}>FAQs</u></h2>
          {faqs.map((faq, index) => (
            <Accordion key={index} question={faq.question} answer={faq.answer} />
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
};

// CSS styles for inline use
const yellowBoxStyle = {
  background: '#FFBD59',
  width: '350px',
  height: '32px',
  fontFamily: 'Poppins',
  fontSize: '20px',
  color: '#000',
  textAlign: 'center',
  marginBottom: '20px'
};

const headingStyle = {
  fontFamily: 'Poppins',
  color: '#000',
  textAlign: 'left',
  marginBottom: '10px',
  fontWeight: 900
};

const tickBoxStyle = {
  display: 'flex',
  alignItems: 'center',
  color: "#000",
  fontFamily: "Open sans",
  fontSize: "20px",
  wordWrap: "break-word",
  width: '100%'
};

const checkCircleStyle = {
  color: 'green',
  margin: '1%',
  fontSize: '35px'
};

const enrollButtonStyle = {
  background: '#6a58c6',
  marginBottom: '20px'
};

const boxStyle = {
  display: 'flex',
  flexDirection: { sm: 'column', md: 'row' },
  justifyContent: 'space-evenly',
  alignItems: 'center',
  flexWrap: 'wrap'
};

const innerBoxStyle = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-evenly',
  width: { sm: '100%', md: '50%' },
  gap: '40px'
};

const centeredHeadingStyle = {
  width: '100%',
  textAlign: 'center'
};

const underlineStyle = {
  textDecorationColor: '#FFBD59',
  textDecorationThickness: '5px'
};

export default FullStackDeveloper;
