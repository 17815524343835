import React from 'react';
import {Divider} from '@mui/material';
import sign from './Signature.png';
import startup from './startup.png';
import vsc from './vsc.png';
import markoknow from './markoknow.png';



const SignStrip = ({isMobile}) => {
    const dividerStyle = {
        bgcolor: '#000',
        height: isMobile?'1px':'2px',
        width: isMobile?'90%':'100%',
      };
  return (
    <div style={{display:'flex' , flexDirection:'row', justifyContent:'space-evenly'}}>
    <div style={{
        textAlign:'center' , 
        fontFamily: 'Montserrat',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        width: '20%' }}>
        <span style={{fontSize:isMobile?'6px':'12px'}}>
        <img src={startup} alt='Startup' width={isMobile?'70':'100'} height={isMobile?'20':'50'}/>
        <Divider sx={dividerStyle} />
        By company recognized by DPIIT Startup India
        </span>
        
    </div>
        
    <div style={{
        textAlign:'center' , 
        fontFamily: 'Montserrat' ,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        width: '20%'
        }}>
        <span style={{fontSize:isMobile?'6px':'12px'}}>
        <img src={markoknow} alt='Markoknow' width={isMobile?'70':'100'} height={isMobile?'20':'50'}/>
        <Divider sx={dividerStyle} />
        Authorized by Markoknow Private Limited
        </span>
        
    </div>
        
    <div style={{
        textAlign:'center' , 
        fontFamily: 'Montserrat',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        width: '20%'
         }}>
        <span style={{fontSize:isMobile?'6px':'12px'}}>
        <img src={vsc} alt='Virtual Startup Campus' width={isMobile?'70':'100'} height={isMobile?'20':'50'}/>
        <Divider sx={dividerStyle} />
        Authorized by Virtual Startup Campus
        </span>
        
    </div>
        
    <div style={{
        textAlign:'center' , 
        fontFamily: 'Montserrat',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',width: '20%' }}>
        
        <span style={{fontSize:isMobile?'6px':'12px'}}>
        <img src={sign} alt='Kaushiki Kesarwani' width={isMobile?'70':'100'} height={isMobile?'20':'50'}/>
        <Divider sx={dividerStyle} />
        Kaushiki Kesarwani
        Chief Executive Officer
        </span>
        
    </div>
        

    </div>
  )
}

export default SignStrip