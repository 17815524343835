import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import { GET_BLOGS , GET_BLOG , IMAGE} from "../../api/blog";
import axios from 'axios';
import {useMediaQuery} from '@mui/material';
import dummy from '../NewVirtualCampusStartup/Images/bmc.png'
import {Divider} from '@mui/material';



export default function BlogRecomendations({ id }) {
  const theme = useTheme();

  const [blog, setBlog] = React.useState([]);
  const [lBlog , setLblog] = React.useState({});
  const [rBlog , setRblog] = React.useState({});
  const [lBlogId , setLblogId] = React.useState('');
  const [rBlogId , setRblogId] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const isMobile= useMediaQuery('(max-width:900px)');


  React.useEffect(() => {
    setLoading(true);
    axios
      .get(GET_BLOG+id, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setBlog(res.data);
        setLblogId(res.data.lBlogId);
        setRblogId(res.data.rBlogId);
        setLoading(false);
      })
      .catch((err) => {
      });
     
  }, []);
  React.useEffect(()=> {

    axios
    .get(GET_BLOG+lBlogId, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
      },
    })
    .then(res => {
      setLblog(res.data);
    })
    .catch(err => {
    })

    axios
    .get(GET_BLOG+rBlogId , {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
      },
    })
    .then(res => {
      setRblog(res.data);
    })
    .catch(err => {
    })
  },[blog])

  return (
    <>
    {((lBlog && lBlog._id && lBlog.title && lBlog.subTitle && lBlog.imagePath) ||
  (rBlog && rBlog._id && rBlog.title && rBlog.subTitle && rBlog.imagePath)) && (
  <>
    <Typography variant="h5" style={{textAlign:'center'}}>Explore More</Typography>
  </>
)}

    <Box display='flex' flexDirection={!isMobile?'row':'column'} padding="10px" margin='10px'>
      {lBlog && lBlog._id && lBlog.title && lBlog.subTitle && lBlog.imagePath &&  (
        (
          <Box margin="1%" width="100%" >
          <Card key={lBlog._id} sx={{ display: 'flex', flexDirection:isMobile?'column' : 'row' , justifyContent:'space-between' , width:'100%' ,height:'100%' }}>
           
            <Box width={"100%"}>
            <CardMedia
              component="img"
              sx={{ width: '100%' , height:'100%'}}
              image={IMAGE+lBlog.imagePath}
              // image={dummy}
              alt={lBlog.title}
            />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column' , width:isMobile?'100%':'60%' , padding:'1%' }}>
              <CardContent sx={{ flex: '1 0 auto' }}>
                <Typography component="div" variant="h5">
                  {lBlog.title}
                </Typography>
                <Typography variant="subtitle1" color="text.secondary" component="div">
                  {lBlog.subTitle&&`${lBlog.subTitle.substring(0,100)}...`}
                </Typography>
              </CardContent>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' , padding:'1%' , marginRight:'10px'}}>
                <a href={`/blog/${lBlog._id}`}>
                  <Button variant="contained" style={{background:'#6A58c6'}}>Read More</Button>
                </a>
              </Box>
            </Box>
          </Card>
          </Box>
        )
      )}
      {rBlog && rBlog._id && rBlog.title && rBlog.subTitle && rBlog.imagePath &&(
        (
          <Box margin="1%" width="100%">
          <Card key={rBlog._id} sx={{ display: 'flex', flexDirection:isMobile?'column' : 'row' , justifyContent:'space-between' , width:'100%' ,height:'100%' }}>
           
            <Box width={isMobile?"100%":"40%"}>
            <CardMedia
              component="img"
              sx={{ width: '100%' , height:'100%'}}
              image={IMAGE+rBlog.imagePath}
              alt={rBlog.title}
            />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column' , width:isMobile?'100%':'60%' , padding:'1%' }}>
              <CardContent sx={{ flex: '1 0 auto' }}>
                <Typography component="div" variant="h5">
                  {rBlog.title}
                </Typography>
                <Typography variant="subtitle1" color="text.secondary" component="div">
                  {`${rBlog.subTitle&&rBlog.subTitle.substring(0,100)}...`}
                </Typography>
              </CardContent>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' , padding:'1%' , marginRight:'10px'}}>
                <a href={`/blog/${rBlog._id}`}>
                  <Button variant="contained" style={{background:'#6A58c6'}}>Read More</Button>
                </a>
              </Box>
            </Box>
          </Card>
          </Box>
        )
      )}
    </Box>
  </>
   
  );
}
