import React from 'react';
import { Box } from '@material-ui/core';
import image1 from '../../Images/100percentsafe .png';
import image2 from '../../Images/secure.png';
import image3 from '../../Images/pngegg.png'


const ImageStrip = () => {
  return (
    <Box
      sx={{
        width: '100%',
        backgroundColor: '#A59ADC',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '2px',
        marginTop:'10px',
        height:'100px'
      }}
    >
      <img
        src={image1}
        alt="Image 1"
        style={{ margin: '0 10px', height: '33%' }}
      />
      <img
        src={image2}
        alt="Image 2"
        style={{ margin: '0 10px', height: '33%' }}
      />
      <img
        src={image3}
        alt="Image 3"
        style={{ margin: '0 10px', height: '33%' }}
      />
    </Box>
  );
};

export default ImageStrip;
