import React from 'react';

import './privacy.css';

function PrivacyPolicy() {
  return (
    <div className="Maincontainer">
      <div style={styles.container}>
        <p style={styles.title}>Privacy Policy</p>
        <p>
          Markoknow Private Limited ("Markoknow", "we" or "us") is committed to protecting and
          respecting your privacy. This policy explains how we use any personal information that you
          provide to us through our website, or customer service. Please read it carefully. We may
          change this policy. We will post any changes on this page, so please check back
          frequently. For the purposes of data protection law, the controller is Markoknow Private
          Limited with a registered office at HD-220 Forum, Cybercity, Phase III, Gurgaon,
          Haryana-122002, India. All correspondence should be sent to the registered address.&nbsp;
        </p>
        <p>INFORMATION WE COLLECT FROM YOU:</p>
        <p>&nbsp;We may collect and process the following information about you:&nbsp;</p>
        <p>INFORMATION YOU PROVIDE TO US:&nbsp;</p>
        <p>
          &nbsp;You may provide us with information in several ways, including by filling in forms
          on our websites or by corresponding with us by phone, email, and live chat. This includes
          information you provide when you register for an account, search for a price comparison or
          quote, enter a contract for the supply of services, enter a competition, promotion, or
          survey, or report problems with our services. The personal information that you provide
          may include ayour name, address, email address, phone number, financial formation and
          other information about you (Or that of somebody you know i.e., consignee) to enable us to
          provide you with our services, including information pertaining to the collection address
          and the delivery address.&nbsp;
        </p>
        <p>INFORMATION COLLECTED BY US VIA YOUR SOCIAL MEDIA ACCOUNTS:</p>
        <p>
          By using our website and opting to log in via social media, you agree to the following:
        </p>
        <ul>
          <li>
            Collection of Data: When you choose to log in to our website using your social media
            account, we may collect certain personal information from your social media profile, as
            permitted by the respective social media platform. This may include your name, email
            address, profile picture, your contact number and other information that you have made
            public or authorized for sharing.
          </li>
        </ul>
        <p>
          <br />
          <br />
        </p>
        <ul>
          <li>
            Use of Data: The data collected through social media logins is primarily used to create
            and maintain your user account on our website. It allows us to authenticate your
            identity, provide a personalized experience, and facilitate seamless interactions. We
            may also use this data to communicate with you, respond to your inquiries, and improve
            our services.
          </li>
        </ul>
        <ul>
          <li>
            Third-Party Access: We may share your personal information collected through social
            media logins with third-party service providers who assist us in operating our website
            and delivering our services. These service providers are bound by strict confidentiality
            agreements and are only authorized to use your information to perform services on our
            behalf.
          </li>
        </ul>
        <ul>
          <li>
            Security Measures: We take reasonable and appropriate security measures to protect your
            personal information from unauthorized access, disclosure, alteration, or destruction.
            However, please note that no method of transmission or storage is completely secure, and
            in the eventuality of a data breach falling outside the terms of the present policy
            Markoknow will not be liable for the same.
          </li>
        </ul>
        <ul>
          <li>
            Data Retention: We retain your personal information collected through social media
            logins for as long as necessary to fulfill the purposes outlined in this Privacy Policy,
            unless a longer retention period is required or permitted by law.&nbsp;
          </li>
        </ul>
        <p>FINANCIAL DATA:</p>
        <p>
          We use a third-party partner namely ______, (Please let us know the name of the
          third-party partner/ platform which would need to be added here). to process the payments.
          You will be required to provide the third party with Financial Data (i.e. Your banking
          details) in order to authorize payment. When You place an order on the Site, your consent
          is given to provide the data necessary to process the payment with Markoknow to this third
          party directly. To make and complete the purchase with such third party, the policies of
          the third party are applicable. The policies as between you and the third party can be
          found here. Except such authorized third party, we will not share financial data with any
          other third parties (including our Markoknow Network) without your prior consent.&nbsp;
        </p>
        <p>
          <br />
          <br />
          <br />
        </p>
        <p>
          We make it clear that our website is not responsible for the handling, usage, or
          protection of customer data by any third-party services or websites that users may access
          through our platform.
        </p>
        <p>INFORMATION WE COLLECT ABOUT YOU:&nbsp;</p>
        <p>
          When you use our website, we automatically collect information about your device,
          including your IP address, information about your visit (including how you got to our
          websites) and how you use our services. We may combine this information with other
          information you have provided to us or which we have received from other sources.&nbsp;
        </p>
        <p>HOW WE USE YOUR PERSONAL INFORMATION:</p>
        <p>
          We use personal information about you in connection with the following purposes:&nbsp;
        </p>
        <ul>
          <li>Providing our services to you and fulfilling your requests.</li>
        </ul>
        <ul>
          <li>
            Your data is used by Markoknow (and the Markoknow Network to the extent necessary to
            fulfill the Markoknow Services) to enable Markoknow to act as a service provider to you
            and is not shared with any person or company without your express consent.&nbsp;
          </li>
        </ul>
        <ul>
          <li>
            Your data may be used by Markonow to establish communication with you. We may use such
            information in order to contact you about a potential purchase or any other allied
            products/ services in which you may be interested via the means of telecommunication,
            phone-calls, emails and messages. Such information may also be used by us in respect of
            other promotional projects, the same being based on the time which consumers spend on
            our website for the purpose of identifying their prospective interests.&nbsp;
          </li>
        </ul>
        <ul>
          <li>Completing any transaction that you do with the Markoknow network.</li>
        </ul>
        <ul>
          <li>Administering promotions or competitions that you enter.&nbsp;</li>
        </ul>
        <ul>
          <li>
            Your personal data may be used by Markoknow to contact a user by post, phone or by email
            when necessary in connection with an order, and in the case of a registered Markoknow
            Member, a Markoknow Account or Markoknow Membership.&nbsp;
          </li>
        </ul>
        <ul>
          <li>
            To minimize the risk of and access to Your Data, we use some of your data to
            authenticate you when using the site and the interactive services.&nbsp;
          </li>
        </ul>
        <p>
          <br />
          <br />
        </p>
        <p>MARKETING AND ADVERTISING:</p>
        <ul>
          <li>
            Information or offers regarding upcoming products, promotions, Markoknow Services or
            customer surveys.
          </li>
        </ul>
        <ul>
          <li>
            Measuring and understanding the effectiveness of advertising we serve to you and
            others.&nbsp;
          </li>
        </ul>
        <p>SERVICE IMPROVEMENT AND ACCOUNT MANAGEMENT:</p>
        <ul>
          <li>
            Ensuring that our content is presented in the most effective manner for you and your
            device.
          </li>
          <li>
            Administering our websites and for internal business administration (for example,
            troubleshooting, data analysis, testing, research, statistical and survey
            purposes).&nbsp;
          </li>
          <li>
            Protecting our interests and those of third parties and as part of our efforts to keep
            our services safe and secure.&nbsp;
          </li>
          <li>Managing and operating your account with us.&nbsp;</li>
        </ul>
        <p>DEMOGRAPHIC INFORMATION:&nbsp;</p>
        <p>
          We may collect demographic information about you, events you like, events you intend to
          participate in, tickets you buy, or any other information provided by you during the use
          of our website. We might collect this as a part of a survey also.
        </p>
        <p>PASSIVE INFORMATION COLLECTION:</p>
        <p>
          We use tracking tools like Google Analytics, Google Webmaster, browser cookies, and web
          beacons for collecting information about your usage of our website.
        </p>
        <p>HOW WE USE YOUR INFORMATION:</p>
        <p>
          We use the information collected to establish communication with you. We may use such
          information in order to contact you about a potential purchase or any other allied
          products/ services in which you may be interested via the means of telecommunication,
          phone-calls, emails and messages. Such information may also be used by us in respect of
          other promotional projects, the same being based on the time which consumers spend on our
          website for the purpose of identifying their prospective interests.&nbsp;
        </p>
        <p>
          We use the information collected to address your inquiries or requests. For instance, we
          may verify your registration for an event or contest.
        </p>
        <p>
          We use the information collected to enhance our products and services. We may utilize this
          information to personalize your experience by tailoring content based on your preferences.
        </p>
        <p>
          We use the information collected to gain insights into trends and customer interests. By
          doing so, we strive to improve both our website and products. Occasionally, we may combine
          the data obtained from you with information obtained from third parties.
        </p>
        <p>
          We use the information collected for security purposes. We take required steps to ensure
          the protection of our company, customers, and websites.
        </p>
        <p>
          We use the information collected for marketing purposes. This includes sending you
          information regarding special promotions, offers, new features, or products. These may
          pertain to our own offerings or products, as well as third-party offerings or products
          that we believe may be of interest to you. For example, if you purchase tickets from us,
          you will be enrolled in our newsletter.
        </p>
        <p>
          We use the information collected for facilitating transactional communications. Such as
          account-related notifications or ticket purchase updates, are sent to you using the
          provided information.
        </p>
        <p>We adhere to applicable laws when utilizing the information.</p>
        <p>OPT OUT:</p>
        <p>
          If you do not wish to be contacted by Markoknow in respect of future marketing
          communications (excluding the welcome email and any emails relating to your order which
          cannot be opted out of), we shall be pleased to provide you with an opportunity of opting
          out of from communications and other solicitations by the means of an email which may be
          titled by you as &ldquo;Unsubscribe&rdquo;.&nbsp;
        </p>
        <p>SHARING PERSONAL INFORMATION:&nbsp;</p>
        <p>
          We may also share your personal information with third parties in the following
          circumstances:&nbsp;
        </p>
        <p>MARKOKNOW NETWORK COMPANIES: TO OBTAIN QUOTES AND BOOK YOUR DELIVERY:</p>
        <p>
          For our daily operations, we use the services of third parties to provide some of our
          business and operational functions. Some of the Markoknow Services are provided by our
          business partners who are part of the Markoknow Network and we need to disclose your
          information to them to provide you with the Markoknow Services.&nbsp;
        </p>
        <p>
          You should be aware that we may release your data when we believe it is necessary to
          comply with laws or regulations, to assist law enforcement, to enforce the terms under
          which you transacted with Markoknow, or to protect the rights, property or safety of
          Markoknow, Markoknow Members or third parties. Your bank or the third party may further
          release your data as per their policies.&nbsp;
        </p>
        <p>ANALYTICS AND SEARCH ENGINE PROVIDERS:&nbsp;</p>
        <p>
          We may provide information about your visit to analytics and search engine providers to
          help us improve and optimize our services. We only share this information in a form that
          does not directly identify you.&nbsp;
        </p>
        <p>DISCLOSURE IN THE COURSE OF PURCHASE/SALE OF BUSINESS ASSETS:</p>
        <p>
          If we sell or buy any business or assets, we may disclose your personal information to the
          prospective seller or buyer of that business or those assets, along with its professional
          advisers. If Markoknow (or substantially all of its assets) is acquired, personal
          information held by it about its users will be one of the transferred assets.&nbsp;
        </p>
        <p>DISCLOSURE IN THE COURSE OF LEGAL OBLIGATION:</p>
        <p>
          We may disclose your personal information if required to do so by law or in response to a
          request from law enforcement or another regulatory authority. We may disclose your
          personal information to enforce our agreements with you or to protect the rights, property
          or safety of Markoknow, its users or others. This includes sharing personal information
          with other organizations for fraud prevention purposes.&nbsp;
        </p>
        <p>YOUR RIGHTS:</p>
        <p>
          You have the right to ask us not to process your personal information for marketing
          purposes. You can do this at any time by sending us an email in response to any marketing
          email we send you.&nbsp;
        </p>
        <p>STORING YOUR PERSONAL INFORMATION:</p>
        <p>
          By submitting your personal information, you agree to this transfer, storing or
          processing. Further, your bank or the third party further releases your data as per their
          policies.&nbsp;
        </p>
        <p>
          You acknowledge that email messages sent over the internet are not encrypted and are not
          secure. Despite efforts to protect your data, we cannot ensure or warrant the security of
          any data you transmit to us, via, to, or from our site. You accept that Markoknow cannot
          be held liable for any breaches of confidentiality that may occur as a result of the use
          of email.&nbsp;
        </p>
        <p>
          Once your payment instructions are received by the third party, they have security
          measures in place to protect the loss, misuse and alteration of the Data under their
          control. 128 bit industry standard Secure Server Software (SSL) is used as a minimum
          standard which encrypts all of your data in respect of a transaction, including credit
          card number, name, and address, so that it cannot be read as the data is
          transmitted.&nbsp;
        </p>
        <p>
          How long we keep your data collected through our site depends on the context in which you
          provide it and the purpose for which we use it. We shall retain your information for a
          period which is required for the purpose for which such information was collected by us or
          as may be required under the applicable provisions of law. However, we may retain data
          related to you if there is a legal obligation to retain the data; if required to comply
          with any applicable statutory or regulatory retention requirement by law; if we believe it
          may be necessary to prevent fraud or future abuse; to enable us to exercise its legal
          rights and/or defend against legal claims; or for other legitimate purposes. We may
          continue to retain your data in anonymised form for analytical and research purposes. We
          will only retain it for as long as is necessary for such purposes, for example, we will
          keep Your Registration Information for as long as You are registered as a Markoknow Member
          on our Site and We will keep records of any transactions you enter into. We may send You
          direct marketing communications for as long as you do not unsubscribe from receiving the
          same from us.&nbsp;
        </p>
        <p>
          You understand and acknowledge that you have no ownership rights in your Markoknow Account
          and that if you cancel your Markoknow Account or your Markoknow Account is terminated, all
          your information including but not limited to Your Historical Consignment Information and
          your Markoknow Member Profile will be deleted from your Markoknow Account and will be
          further deleted from any Markoknow archives. Your Data may continue to be retained by the
          Markoknow Network in accordance with the privacy policies of e.g. Sub-Contractors.&nbsp;
        </p>
        <p>
          If you call our customer service team, we may record your call. These recordings are used
          for training and quality control to ensure that we continuously monitor and improve our
          customer service standards.&nbsp;
        </p>
        <p>
          We keep your personal information for as long as is necessary for the purposes for which
          it is processed. These periods vary depending on the nature of the information and your
          interactions with us.
        </p>
        <p>COOKIES:</p>
        <p>
          We use cookies and other similar technologies to distinguish you from other users of our
          website (including when you browse third party websites). This helps us to provide you
          with a good experience when you use our services (for example, to remember your login
          details and improve our services). We also use cookies and similar technologies to show
          you more personalized advertising, including Markoknow ads on other websites. You may
          adjust the settings on your browser to refuse cookies but some of our services may not
          work if you do so.&nbsp;
        </p>
        <p>
          Markoknow is not responsible for the privacy practices or the content of such websites or
          for the privacy policies, cookie policies and practices of other third parties, so You
          should be careful to read and understand those website policies independently.&nbsp;
        </p>
        <p>LINKS TO THIRD PARTY WEBSITES:</p>
        <p>
          &nbsp;Our website may contain links to other websites. This privacy policy does not apply
          to those other websites and we do not accept liability for the content of any other
          website. Please check the privacy policy of any other website before submitting any
          personal information to it.&nbsp;
        </p>
        <p>
          Markoknow reserves the right to amend the Privacy Policy at any time with or without
          notice. Please check back frequently in the event of changes.&nbsp;
        </p>
        <p>LINKS TO THIRD PARTY WEBSITES:&nbsp;</p>
        <p>
          (a)  We may provide links to other websites on our Platform or in any emails which are
          being sent to you. However, we have no control over such websites nor are we affiliated to
          or associated with any such third-party websites unless specified expressly on the
          website.&nbsp;
        </p>
        <p> &nbsp;</p>
        <p>
          (b) These Linked sites are solely for advertisement purposes and we are not responsible
          for any form of transmission, whatsoever, received by you from any such linked site.&nbsp;
        </p>
        <p> &nbsp;</p>
        <p>
          (c)  Accordingly, Markoknow does not make any representations concerning the privacy
          practices or policies of such third parties or the terms of use of such sites, nor
          does Markoknow control or guarantee the accuracy, integrity, or quality of the
          information, data, text, software, music, sound, photo, messages or other materials which
          may be available on such websites.&nbsp;
        </p>
        <p> &nbsp;</p>
        <p>
          (d) Markoknow shall not be held responsible for any violation of your privacy rights by
          such linked sites nor any loss, damage or injury which may be suffered by you on account
          of such action/ inaction of such linked sites.&nbsp;
        </p>
        <p> &nbsp;</p>
        <p>
          (e)  The information practices of these third-party websites and advertisers are not
          covered by this Policy.&nbsp;
        </p>
        <p>
          Please kindly consider adding a clause/ sub-clause for &ldquo;Security&rdquo; as well in
          the said Privacy Policy:&nbsp;
        </p>
        <p> &nbsp;</p>
        <p>SECURITY:&nbsp;</p>
        <p>
          (a)   Markoknow strives to ensure the security, integrity and privacy of your personal
          information and to protect your personal information against unauthorized access or
          unauthorized alteration, disclosure or destruction. For this purpose, Markoknow adopts the
          best standards and practices for data collection, storage and processing practices
          including security measures.&nbsp;
        </p>
        <p> &nbsp;</p>
        <p>
          (b) Markoknow is not responsible for any breach of your security or for any actions of any
          third party that receives your personal information.&nbsp;
        </p>
        <p>
          (c)  The Platform/ website may be linked to many other sites and Markoknow is not and
          shall not be responsible for their privacy policies or practices as it is beyond our
          control.&nbsp;
        </p>
        <p> &nbsp;</p>
        <p>INFRINGEMENT:</p>
        <p>Users are explicitly prohibited from engaging in the following activities:</p>
        <ul>
          <li>
            Copying, reproducing, or distributing our website's content, including text, images,
            videos, graphics, and trademarks, without prior written consent.
          </li>
          <li>
            Modifying, adapting, or creating derivative works based on our website's content without
            explicit authorization.
          </li>
          <li>
            Unauthorized scraping, data mining, or automated extraction of information from our
            website.
          </li>
          <li>
            Impersonating or falsely representing ownership of our website's content or any part
            thereof.
          </li>
          <li>
            Engaging in any activity that violates intellectual property laws or infringes upon the
            rights of third parties in connection with our website's content.
          </li>
          <li>
            We reserve the right to take appropriate legal action against any individual or entity
            found to be infringing upon our intellectual property rights or engaging in unauthorized
            use of our website's content. Such actions may include but are not limited to, seeking
            injunctive relief, damages, and any other remedies available under applicable laws.
          </li>
        </ul>
        <p>MISREPRESENTATION:</p>
        <p>
          We are committed to maintaining transparency and trust with our users. We strictly
          prohibit any form of misrepresenting our website's name or faking association with it.
          This includes, but is not limited to, actions such as:
        </p>
        <ul>
          <li>
            Impersonating our website or brand through the use of similar domain names, logos, or
            trademarks.
          </li>
          <li>
            Engaging in deceptive practices to mislead users into believing an association with our
            website exists when it does not.
          </li>
          <li>
            Utilizing unauthorized endorsements or testimonials that falsely imply a connection with
            our website.
          </li>
        </ul>
        <p>GENERAL PROVISIONS:&nbsp;</p>
        <p>
          (i)Unless stated otherwise, the Policy applies to all information that Markoknow has about
          you.
        </p>
        <p>
          (ii)If you choose to visit this website, your visit and any dispute over privacy shall be
          subject to this Privacy Policy and the Platform&rsquo;s Terms of Use. You acknowledge that
          this policy along with the terms of use as are updated by us from time to time shall
          govern your relation with Markoknow in respect of the use of the Platform.
        </p>
        <p>
          (iii)We will retain and use your information as necessary to comply with our legal
          obligations, resolve disputes and other enforcement agreements.
        </p>
        <p>
          (iv)You have the right at any time to prevent us from contacting you for marketing
          purposes. When we send a promotional communication to a user, the user can opt out of
          further promotional communications by following the &ldquo;Unsubscribe&rdquo; instructions
          provided in each promotional email.
        </p>
        <p>
          (v) In the course of business, if an employee of  Markoknow partakes in unlawful and
          unethical conduct in his/ her individual capacity, then the same shall be held to be done
          by such an employee in their individual capacity and Markoknow shall not be held liable
          for any such conduct(s). That such unethical activities, conducts or representations if
          any by any such employees in their individual capacity do not constitute to be a part of
          the principles and values of Markoknow nor shall Marknoknow presume any liability for the
          same.
        </p>
        <p>
          (vi)We at Markoknow only carry our business operations within the length and breadth of
          India and as of the present date, we do not have any business operations outside of India,
          thus any false representations and/or warranties which may be made by any third-parties
          situated outside of India do not make Markoknow liable for the same.
        </p>
        <p>
          (vii)We at Marknoknow shall also not be liable for any false representations and
          warranties which may be made by any such third-parties located within India as well.&nbsp;
        </p>
        <p> &nbsp;</p>
        <p>MODIFICATION OF POLICY:&nbsp;</p>
        <p>
          (a) Markoknow reserves the right at its sole discretion or on account of there being
          changes in law, to modify or replace this policy in accordance with the applicable laws
          from time to time by posting the modified version of the Policy on the Platform.&nbsp;
        </p>
        <p>
          (b) Markoknow shall not communicate to you personally about such modifications and it
          shall be your responsibility to check this policy periodically in order to become
          aware.&nbsp;
        </p>
        <p>JURISDICTION:</p>
        <p>
          If you choose to visit the website, your visit and any dispute over privacy is subject to
          this Policy and the website's terms of use. In addition to the foregoing, any disputes
          arising under this Policy shall be governed by the laws of India.
        </p>
        <p>CONTACT US :</p>
        <p>
          If you have questions about this privacy policy or the way in which Markoknow processes
          your personal information, you can contact us using the details below:&nbsp;
        </p>
        <p>Post: HD-220 Forum, Cybercity, Phase III, Gurgaon, Haryana-122002, India</p>
        <p>Email: hello@markoknow.com&nbsp;</p>
      </div>
    </div>
  );
}
const styles = {
  container: {
    padding: '40px',
    borderRadius: '4px',
  },
  title: {
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '10px',
    textAlign: 'center',
  },
};
export default PrivacyPolicy;
