import React, { useState, useEffect , useRef } from 'react';
import { Link } from 'react-router-dom';
import { GET_CASE_STUDIES } from '../../api/casestudy';
import {Box} from '@mui/material';
import './Projects.css';
import EcoBuild from './CaseStudies/EcoBuild';
import EcoThread from './CaseStudies/EcoThread';
import FinEase from './CaseStudies/FinEase';
import MoneyWise from './CaseStudies/MoneyWise';
import NutriFit from './CaseStudies/NutriFit';
import WorkConnect from './CaseStudies/WorkConnect';
import ShieldSafe from './CaseStudies/ShieldSafe';
import AgroTechGreens from './CaseStudies/AgroTechGreens';
import LivestockSense from './CaseStudies/LivestockSense';
import PolicyGenius from './CaseStudies/PolicyGenius';
import LendEasy from './CaseStudies/LendEasy';
import CreditEase from './CaseStudies/CreditEase';
import FleetCharge from './CaseStudies/FleetCharge';
import ChargeX from './CaseStudies/ChargeX';
import Footer from '../Footer/Footer';
import CaseStudyCards from './CaseStudyCards';
import axios from 'axios';
import CaseStudyPng from './CaseStudy.png';
import PolicyGeniusC from '../CaseStudy.jsx/CaseStudyData/PolicyGenius';
import LiveStockSense from '../CaseStudy.jsx/CaseStudyData/LiveStockSense';
import LendEasyC from '../CaseStudy.jsx/CaseStudyData/LendEasy';
import PolicyGeniusI from '../CaseStudy.jsx/Photos/2.png';
import LiveStockSenseI from '../CaseStudy.jsx/Photos/3.png';
import LendEasyI from '../CaseStudy.jsx/Photos/4.png';
import NewCaseStudy from './NewCaseStudy';


const ProjectLogin = ({isAuth}) => {

  const steps = [
    {
      id: 1,
      title: 'Apply in Teams of 2 to 5',
      description:'To encourage group activities and help people develop team working skills, the project is recommended to be taken in teams of 2 to 5. The student could show the certificate (if obtained) as a proof of working in teams',
    },
    {
      id: 2,
      title: 'Pay registration fee per participant',
      description:'To participate in the project, each participant is required to pay the registration fee. This fee covers the administrative and operational costs of the project.',
    },
    {
      id: 3,
      title: 'Get the Case Study in your mail IDs',
      description:'Once the registration fee is paid, the case study will be sent to the registered email IDs of the participants. Make sure to check your email for the case study details.',
    },
    {
      id: 4,
      title: 'Solve it in group and submit within 1 month',
      description:'As a team, work together to analyze and solve the given case study within the given timeframe of 1 month. Collaborate, brainstorm, and apply your skills to come up with innovative solutions.',

    },
    {
      id: 5,
      title: 'Get reviewed and if eligible get your certificate each',
      description:'After submitting your solution, it will be reviewed by the project team. If your solution meets the eligibility criteria, you will be awarded a certificate acknowledging your participation and success in the project.',
    },
  ];
  const [selectedStepIndex, setSelectedStepIndex] = useState(0);
  // const [selectedStep, setSelectedStep] = useState(steps[0]);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [selectedCaseId, setSelectedCaseId] = useState(1);
  const [cs , setCs] = useState([]);
  const CaseStudies = [
    {_id: 1 , title: 'Policy Genius', imagePath: PolicyGeniusI , component: <PolicyGeniusC/>},
    {_id: 2 , title: 'Live Stock Sense',imagePath: LiveStockSenseI, component: <LiveStockSense/>},
    {_id: 3 , title: 'Lend Easy', imagePath:LendEasyI , component: <LendEasyC/>}
  ]
  useEffect(() => {
    axios
    .get(GET_CASE_STUDIES)
    .then(res => {
      setCs(res.data.data);  
    })
    .catch(err => {
    })
  },[])
  

  const caseStudies = [
    { id: 1, title: 'EcoBuild Case Study', component: <EcoBuild /> },
    { id: 2, title: 'EcoThread Case Study', component: <EcoThread /> },
    { id: 3, title: 'FinEase Case Study', component: <FinEase /> },
    { id: 4, title: 'MoneyWise Case Study', component: <MoneyWise /> },
    { id: 5, title: 'NutriFit Case Study', component: <NutriFit /> },
    { id: 6, title: 'WorkConnect Case Study', component: <WorkConnect /> },
    { id: 7, title: 'ShieldSafe Case Study', component: <ShieldSafe /> },
    { id: 8, title: 'AgroTech Greens Case Study', component: <AgroTechGreens /> },
    { id: 9, title: 'ChargeX Case Study', component: <ChargeX /> },
    { id: 10, title: 'CreditEase Case Study', component: <CreditEase /> },
    { id: 11, title: 'FleetCharge Case Study', component: <FleetCharge /> },
    { id: 12, title: 'LendEasy Case Study', component: <LendEasy /> },
    { id: 13, title: 'Livestock Sense Case Study', component: <LivestockSense /> },
    { id: 14, title: 'Policy Genius Case Study', component: <PolicyGenius /> },
    
  ];

  const [selectedCase, setSelectedCase] = useState(caseStudies[0]);
  const targetDivRef = useRef(null);
  const scrollToTop = () => {
    // Find the content container element and scroll it to the top
    const contentContainer = document.querySelector('.content-container');
    if (contentContainer) {
      contentContainer.scrollTop = 0;
    }
  };
  const handleCaseClick = (id) => {
    const selectedCaseData = caseStudies.find((caseStudy) => caseStudy.id === id);
    setSelectedCase(selectedCaseData);
  };
  useEffect(() => {
    

    const stepInterval = setInterval(() => {
      setSelectedStepIndex((prevIndex) => (prevIndex + 1) % steps.length);
    }, 2000);

    return () => {
      clearInterval(stepInterval);
    };
  }, [ steps.length]);


  const handleStepClick = (index) => {
    setSelectedStepIndex(index);
  };
    const selectedStep = steps[selectedStepIndex];
  return (
    <>
    <div className="Maincontainer" >
    <div className="centered-content" ref={targetDivRef}>
        <h1 >Startup Case Studies</h1>
        <p>Now read about startups and strategies from</p>
        <p>various startup case studies</p>
      </div>
      <div className="after-login-screen" >
      <div className="after-login-container">
        <div className="after-login-left-container" >
          {caseStudies.map((caseStudy) => (
            <button
              key={caseStudy.id}
              className={`after-login-button after-login-button-${caseStudy.id}`}
              onClick={() => {
                handleCaseClick(caseStudy.id);
                scrollToTop(); 
              }}
            >
              {caseStudy.title}
            </button>
          ))}
        </div>
        <div className="after-login-right-container">
          <div className="content-container">
            {selectedCase.component}
          </div>
        </div>
      </div>
      </div>

    <div className="startup-container">

        <div className='startup-out'>
        <div className="startup-cases">
          <div className="startup-boxes">
            <h1 className="startup-h1">STARTUP</h1>
            <h1 className="startup-h1">Cases</h1>
          </div>
          <div className="startup-boxes">
            <h2>15+</h2>
            <h2>Case Study</h2>
          </div>
          <div className="startup-boxes">
            <h2>25+</h2>
            <h2>Projects to Work On</h2>
          </div>
          <div className="startup-boxes">
            <h2>50+</h2>
            <h2>Teams Working On</h2>
          </div>
          </div>
          <div style={{width:'100%' , marginTop:'30px' , height:'100%'}}>
           <CaseStudyCards CaseStudies={CaseStudies} handleCaseClick={handleCaseClick} targetDivRef={targetDivRef} isAuth={isAuth} from={`top`}/>
          </div>
        </div>
      
      </div>
      

      

      <div className="centered-content">
        <h1>Real Time Startup Project Case</h1>
      </div>
      <div className="project-real-time">
        <div className="project-cases">
          <div className="project-window">
            <div className="window-video">
              {/* <iframe
                className="window-video"
                src="https://www.youtube.com/embed/MfbK5NncBgU"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe> */}
              <img  
                src={CaseStudyPng}
                style={{width:'100%'}}
              />
            </div>
            <div className="window-button">
              <Link exact="true" to="/participate">
                <button>Participate</button>
              </Link>
            </div>
          </div>
          <div className="project-buttons">
          {steps.map((step, index) => (
              <button
                key={step.id}
                className={`project-button ${selectedStepIndex === index ? '' : ''}`}
                onClick={() => handleStepClick(index)}
              >
                {step.title}
              </button>
            ))}
          </div>
        </div>
      </div>

      <div className="box-cases">
      {steps.map((step, index) => (
          <div
            key={step.id}
            className={`boxes-screen ${selectedStepIndex === index ? 'selected-step' : ''}`}
            onClick={() => handleStepClick(index)}
          >
            {`Step-${step.id}`}
          </div>
        ))}
      </div>

      <div className="startup-container">
        <div className="cases-bar">
          <div className="cases-box">
            <h1>{selectedStep.title}</h1>
            <p>{selectedStep.description}</p>
          </div>
        </div>
      </div>
      <div className="cases-button">
        <Link exact="true" to="/participate">
          <button>Participate</button>
        </Link>
      </div>
      <br />
      <br />
      {cs.length > 0 && (
        <Box>
        <div className="centered-content">
        <h1>Case Studies</h1>
        </div>
          {/* <CaseStudyCards CaseStudies={cs} isAuth={isAuth} from={`bottom`}/> */}
          <NewCaseStudy/>
        </Box>
      )}
    </div>
    <Footer/>
    </>
  );
};

export default ProjectLogin;
