import { Typography } from '@mui/material'
import React , {useState , useEffect} from 'react';
import SideView from './SideView';
import { useMediaQuery } from '@mui/material';

const LearnApplyWorkex = () => {
        const [selected , setSelected] = useState('learn');
        const isMobile = useMediaQuery('(max-width: 600px)');
        const isSmallMobile = useMediaQuery('(max-width: 850px)');
        const isGreaterMobile = useMediaQuery('(max-width: 700px)');
        const isMMobile = useMediaQuery('(max-width: 1109px)')

        const commonDivStyle = {
            cursor: 'pointer',
            width: isSmallMobile?'100%':'400px', 
            padding: '10px', 
            boxSizing: 'border-box', 
          };
        useEffect(() => {
            const intervalId = setInterval(() => {
              setSelected((prevSelected) => {
                switch (prevSelected) {
                  case 'learn':
                    return 'apply';
                  case 'apply':
                    return 'workex';
                  case 'workex':
                    return 'learn';
                  default:
                    return 'learn';
                }
              });
            }, 5000);
            return () => clearInterval(intervalId);
          }, []);
  return (
    <div style={{marginTop:'70px'}}>
        <h2 
         style={{
            color: '#000',
            fontSize: '32px',
            fontFamily: '"Poppins", sans-serif',
            margin: '0px 30px 24px',
            textAlign: 'center',
         }}
        >
        Focused on {" "}
        <u style={{textDecorationColor: '#FFBD59' ,color:'#6a58c6', textDecorationThickness: '3px'}}>Work Experience</u>
        {" "}with{" "}
        <u style={{textDecorationColor: '#FFBD59' ,color:'#6a58c6', textDecorationThickness: '3px'}}>3 Step Model</u>
        </h2>
        <div style={{
            width: '100%',
            display:'flex',
            flexDirection:isMobile?'column':'row',
            gap: isSmallMobile && '20px'
        }}>
            <div style={{
            width: isSmallMobile?'90%':'50%',
            display:'flex',
            flexDirection:'row',
            gap:'40px',
            alignItems:'center',
            justifyContent:'center'
            }}>
            <div style={{
                display:'flex',
                flexDirection:'column',
                gap:'40px',
                alignItems:'center',
                justifyContent:'flex-end'
            }}>
                <div 
                 onClick={(e) => setSelected('learn')}
                 style={{ ...commonDivStyle }}>
                    <Typography 
                        variant='h6'
                        style={{
                            color:selected==='learn'? '#6a58c6': '#000',
                            textAlign:'right'
                        }}    
                    >Learn</Typography>
                    <Typography 
                        style={{
                            color:selected==='learn'?'#6a58c6':'#000' ,
                            textAlign:'right',
                            fontSize: '12px'
                        }}    
                    >With Practical and Labs in recorded and live labs, learn concepts</Typography>
                </div>
                <div
                 onClick={(e) => setSelected('apply')}
                 style={{ ...commonDivStyle }}
                >
                    <Typography 
                        variant='h6'
                        style={{
                            color:selected==='apply'?'#6a58c6':'#000',
                            textAlign:'right'
                        }}    
                    >Apply</Typography>
                    <Typography 
                        style={{
                            color:selected==='apply'?'#6a58c6':'#000',
                            textAlign:'right',
                            fontSize: '12px'
                        }}    
                    >Understand application level of the concept with Capstone Projects </Typography>
                </div>
                <div
                 onClick={(e) => setSelected('workex')}
                 style={{ ...commonDivStyle }}
                >
                <Typography 
                        variant='h6'
                        style={{
                            color:selected==='workex'?'#6a58c6':'#000',
                            textAlign:'right',
                        }}    
                    >Work Ex</Typography>
                    <Typography 
                        style={{
                            color:selected==='workex'?'#6a58c6':'#000',
                            textAlign:'right',
                            fontSize: '12px'
                        }}    
                    >Get Industry Exposure on how your knowledge applies with organizations </Typography>
                </div>
            </div>
            <div className="vertical-dots-l">
                        <div className="dot-l"></div>
                        <div className="dot-l"></div>
                        <div className="dot-l no-line-l"></div>
            </div>
            </div>
            <div style={{
                width: isSmallMobile?'90%':'50%',

            }}
            >
            <SideView selected={selected}/>
            </div>
        </div>
    </div>
  )
}

export default LearnApplyWorkex