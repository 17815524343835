import React , {useRef , useEffect} from 'react';
import certificateImg from './Certificate.png';
import { TextField, useMediaQuery , Typography} from '@mui/material';
import {Divider} from '@mui/material';
import '../CertificatePromptCapstone/index.css';
import Skills from '../CertificatePromptCapstone/Skills';
import SignStrip from '../CertificatePromptCapstone/SignStrip';
import { useLocation , useParams , useNavigate} from 'react-router-dom';
import html2canvas from 'html2canvas';
import Button from '@mui/material/Button';
import axios from 'axios';
import { POST_CERTIFICATE } from '../../api/certificate';
import { LinkedinShareButton , LinkedinIcon , WhatsappShareButton , WhatsappIcon} from 'react-share';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTheme } from '@mui/material/styles';
import QRCode from 'qrcode.react';
import shortid from 'short-uuid';
import { USER_REFFERAL } from '../../api/vsc';

const CertificateProductExperience = () => {
  const productPhrases = ["Product Research Planning", "Advance Product Building", "Product Management"];
    const isMobile = useMediaQuery('(max-width:600px)');
    const isGMobile = useMediaQuery('(max-width:800px)');
    const [unique , setUnique] = React.useState('');
    const [certName , setCertName] = React.useState(window.localStorage.name);
    const [isAuth, setIsAuth] = React.useState(
        window.localStorage.getItem("isAuth") || false
      );
      const params = useParams();
      const location = useLocation();
      const redirectPath = location?.state?.courseName || 'Product To Model';
      const navigate = useNavigate();
      const date = new Date();
      const now = date.toLocaleString();
      const myArray = now.split(',');
      const certificateRef = useRef(null); 
      const uid = 'VSC-'+ shortid().new();
      const cLink = 'https://www.campus.markoknow.com/viewcertificate/'+uid;
      const [open, setOpen] = React.useState(true);
      const theme = useTheme();
      const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
      React.useEffect(() => {
        const new_data = {
          userId: window.localStorage.id,
          courseId: params.id,
          courseName: 'Management Consulting',
          serielNumber: uid,
          userName: certName,
          dateIssue: myArray[0],
          certificateUrl: cLink
      }
      axios
      .get(USER_REFFERAL + window.localStorage.id)
      .then(response => {
        const existingCertificates = response.data.data.earnedCertificates;
        const hasExistingCertificate = existingCertificates.some(
          certificate => certificate.courseName === new_data.courseName
        );
        if (hasExistingCertificate) {
          const certificate = existingCertificates.filter(certificate => certificate.courseName !== new_data.courseName);
          setUnique(certificate.serielNumber);
          setOpen(false);
        } 
      })
      .catch(error => {
      });
      },[])
      const handleClose = () => {
        const new_data = {
          userId: window.localStorage.id,
          courseId: params.id,
          courseName: 'Management Consulting',
          serielNumber: uid,
          userName: certName,
          dateIssue: myArray[0]
      }
      axios
        .post(POST_CERTIFICATE , new_data)
        .then(res=> {
        })
        .catch(err=> {
        })
            setOpen(false);
    }

    const certificateStyles = {
        backgroundImage: `url(${certificateImg})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: isMobile? '100% 95%' :'100% 100%',
        // backgroundSize:'100%',
        width: isMobile?'95vw':'100%',
        // width: '100%',
        // height: isMobile ? '100%' : '100%',
        height: '100%',
        fontSize: isMobile && '10px', 
        display: 'flex ',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#000',
        textAlign: 'center',
        padding:'20px',
        position:'relative'
        // border:'2px #000 solid'
      };
    //   const underlineStyles = {
    //     marginTop:0,
    //     borderBottom: '2px solid #000',
    //     width: isMobile?'30%':'15%', 
    //   };
    const underlineStyles = {
        position: "absolute",
        left: 0,
        bottom: 0,
        width: "100%",
        height: "2px",
        backgroundColor: "#000", // Change this to your desired color
        transform: "translateY(52px)",
    }
    const h1Style = {
        textAlign: 'left',
        fontSize: '52px',
        fontFamily: 'Parisienne',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        fontWeight: '600'
      };
    
      const dividerStyle = {
        bgcolor: '#000',
        height: '2px',
        width: '100%',
      };
      const downloadCertificate = async () => {
        const canvas = await html2canvas(certificateRef.current);
        const dataURL = canvas.toDataURL('image/png');
        const link = document.createElement('a');
        link.href = dataURL;
        link.download = `${window.localStorage.name}-${uid}-certificate.png`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      };
    
  return (
    <div style={{ 
        marginTop: isMobile?'100px':'80px' , 
        padding:!isMobile&&'20px',
        // display:'flex' , 
        // flexDirection:!isMobile?'row':'column' 
        // height: '', 

        }}>
        {open ? (
            <div>
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">
                {isAuth?'Your Certificate Is Ready Click To See':'First Login'}
                </DialogTitle>
                <DialogContent>
                <DialogContentText>
                {isAuth?
                (<>
                  <Typography sx={{marginBottom: '10px'}}>Congrats On Completing The Course</Typography>
                  <TextField
                    id="outlined-helperText"
                      placeholder='Enter Name To Be Displayed'
                      label='Name'
                      value={certName}
                      onChange={(e) => setCertName(e.target.value)}
                      helperText="Please Confirm Your Certificate Name"
                  />
                </>
                ):'Click Here To Login'}
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={()=>
                isAuth?handleClose():navigate('/login')} autoFocus>
                    {isAuth?'Certificate':'Login'}
                </Button>
                </DialogActions>
            </Dialog>
            </div>
        ): (
            <div 
            style={{ display:'flex' , flexDirection:!isMobile?'row':'column' }}>
            <div style={{
        width:!isMobile?'70vw':'100%' , 
        height: !isMobile&&'90vh',
        //  background: ' linear-gradient(180deg, #8ad1ff 0%, #6a58c6 50%, #6a6bfa 100%)',
         marginRight:!isMobile&&'40px'
         }}>
        <div ref={certificateRef} style={certificateStyles}>
        <div style={{marginLeft:isMobile?'26%':'25%'}}>
        <div style={{marginTop:isMobile?'10px':'35px', display:'flex'}}>
        <div style={{width:'90%'}}>
        <h1 style={{textAlign:'left',fontSize:isMobile?'30px':'60px' , fontFamily: 'Alegreya SC',marginLeft:"45px"}}>
          CERTIFICATE
        </h1>
        <p style={{textAlign:'left',fontSize:isMobile?'10px':'20px', fontFamily: 'Alegreya SC',marginLeft:"45px"}}>
         OF EXPERIENCE
        </p>
        </div>
        
        </div>
        <div style={{marginRight:'20px' , display:'flex' , flexDirection:'column' , width:'10%' , position:'absolute' , top:isMobile?25:35 , right:isMobile?10:25}}>
            <div style={{display:'flex', justifyContent:'flex-end'}}>
            <QRCode value={cLink} size={isMobile?35:80}  />
            </div>
            <div style={{display:'flex', justifyContent:'flex-end'}}>
            <p align="center" style={{fontSize:isMobile?'5px':'12px' , fontFamily:'Montserrat' , fontWeight:'900'}}>Certificate Id: <br/> {uid.substring(0,10)} <br/> {uid.substring(11,21)} </p>

            </div>
        </div>
        <div style={{marginTop:isMobile?'10px':'25px'}}>
        <p style={{textAlign:'left',marginBottom:isMobile?'5px':'20px', fontFamily: 'Montserrat',marginLeft:"45px"}}>
        PROUDLY PRESENTED TO :
        </p>
        <h1 style={h1Style}>
        
        <span style={{textAlign:'left',fontSize:isMobile?'26px':'52px' , fontFamily: 'Parisienne' ,fontWeight: '550',marginLeft:"45px"  }}>
        {certName}
        <Divider sx={dividerStyle} />
        </span>
        </h1>
        
        </div>
        <div style={{marginTop:isMobile?'10px':'25px'}}>
        <p style={{fontFamily:'sans-serif', textAlign:'left',marginLeft:"45px"}}>
        Has achieved the skills by completing the certificate for completion at 
        </p>
        <h2 style={{color:'#6a58c6', marginTop:'2px',textAlign:'left' , fontSize:isMobile&&'10px',marginLeft:"45px"}}>
        Management Consulting Program
        </h2>
        </div>
        <div style={{marginTop:isMobile?'5px':'25px'}}>
        <p style={{fontFamily:'sans-serif', textAlign:'left',marginLeft:"45px"}}>
        where the skills achieved involved learning techniques, strategy
        </p>
        <p style={{fontFamily:'sans-serif', textAlign:'left',marginLeft:"45px"}}>
        development and digital transformation for consulting clients
        </p>
         
        {/* <Skills outcomes={productPhrases} isMobile={isMobile}/> */}
        </div>
        <div style={{marginTop:isMobile?'5px':'10px'}}>
        <SignStrip  isMobile={isMobile}/>
        </div>
        </div>
        
        
        </div>
        </div>
        <div style={{display:'flex' , flexDirection:'column' , justifyContent:!isMobile?'flex-start':'center' , marginTop:'20px' ,marginLeft:'10px' }}>
      <p>Link To View Certificate:</p>
      <div className="link">
                <input
                  type="text"
                  id="link"
                  name="link"
                  value={cLink}
                  className="link-input"
                />
                </div>
                <Button variant='contained' onClick={downloadCertificate} style={{ marginTop: isMobile?'15px':'30px' , backgroundColor:'#6A58C6' }}>
                    Download Certificate
                </Button>
                <div style={{marginTop:'10px'}}>
                <LinkedinShareButton url={cLink}>
                    <LinkedinIcon round={true}/>
                </LinkedinShareButton>
                <WhatsappShareButton url={cLink}>
                    <WhatsappIcon round={true}/>
                </WhatsappShareButton>
                </div>
                </div>
            </div>
        )}
      
        </div>
  )
}

export default CertificateProductExperience;