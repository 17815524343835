import React from 'react';
import { makeStyles } from '@mui/styles';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Typography } from '@mui/material';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const InboxTable = () => {
  const classes = useStyles();

  const inboxData = [
    { id: 1, studentName: 'John Doe', course: 'Computer Science', lab: 'Lab A', requests: 2 },
    { id: 2, studentName: 'Jane Smith', course: 'Physics', lab: 'Lab B', requests: 1 },
    // Add more data as needed
  ];

  return (
    <div style={{marginTop:"5vh"}}>
    <Typography variant='h4' marginLeft='45%'>Lab Inbox</Typography>

    </div>
  );
};

const handleSendMessage = (studentId) => {
  // Implement your logic to handle sending messages
  console.log(`Sending message to student with ID ${studentId}`);
};

export default InboxTable;
