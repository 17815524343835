import React, { useState, useEffect } from "react";
import {Button, IconButton, Tooltip, Typography} from '@mui/material';
import axios from 'axios';
import {useNavigate} from 'react-router-dom';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { WATCHNOW_COMPLETE } from "../../api/watchnow";
import { COMPLETED_BLOG } from "../../api/blog";
import { USER } from "../../api/vsc";
import { COMPLETED_CASE_STUDY } from "../../api/casestudy";
export default function CompletedButton({id , bid , wid}) {
const [isButtonDisabled, setIsButtonDisabled] = useState(true);
const [show , setShow] = useState(false);
const [fetched , setFetched] = useState(false);
const navigate = useNavigate();
useEffect(() => {
  axios
        .get(USER, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          if(id && response.data.completedWatchnow.includes(id)){
            setShow(true);
          }
          else if(bid &&response.data.completedBlog.includes(bid)){
            setShow(true);
          }
          else if(wid &&response.data.completedCaseStudy.includes(wid)){
            setShow(true);
          }
          setFetched(false);
        })
        .catch((error) => {
          console.error(error);
        });
    }, [fetched]);
useEffect(() => {
const timeoutId = setTimeout(() => {
setIsButtonDisabled(false);
}, 1000 * 10);
return () => clearTimeout(timeoutId);
}, []);
const handleCompleted = () => {
  if(id){
    axios
    .post(
      WATCHNOW_COMPLETE,
      { watchnowId: id },
      {
        headers: { authorization: window.localStorage.getItem("token") },
      }
    )
    .then((res) => {
      // alert("video added to completed list");
      navigate('/watch_now');
      setFetched(true);
    })
    .catch((err) => {
      alert("try again later");
    });
  }
  if(bid){
    axios
    .post(COMPLETED_BLOG+bid+"/"+window.localStorage.id)
    .then(res => {
      setFetched(true);
      const randomNumber = Math.random();
      const roundedNumber = Math.round(randomNumber);
     if(roundedNumber === 0){
      navigate('/blog/'+res.data.data.lBlogId);
      window.location.reload();

     }
     else {
      navigate('/blog/'+res.data.data.rBlogId);
      window.location.reload();


     }
    })
    .catch(err => {
    })
  }
  if(wid){
    axios
    .post(COMPLETED_CASE_STUDY+wid+"/"+window.localStorage.id)
    .then(res => {
      setFetched(true);
      navigate('/casestudy');
    })
    .catch(err => {
    })
  }
    
}
return (
<div>
{show ? (

 <Tooltip title="Completed" >
  <IconButton>
  <CheckCircleOutlineIcon style={{color: '#00ff00'}} fontSize="large"/>
  </IconButton>
  </Tooltip>
) : (
  <Button variant="contained" style={{backgroundColor: isButtonDisabled?"#000":"#6A58c6" , color: '#fff'}} onClick={handleCompleted} disabled={isButtonDisabled}>MARK AS COMPLETED</Button>
  )}
</div>
);
}