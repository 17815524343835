import React from "react";
import Snackbar from "@mui/material/Snackbar";

export default function MessagePopup({ handleAlertClose, open, message }) {
  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={open}
      onClose={handleAlertClose}
      message={message}
      key={"topenter"}
      style={{
        marginTop: "60px", // Adjust the top margin to position the Snackbar above the fixed navbar
        position: "fixed", // Ensure that the Snackbar remains fixed in the viewport
        zIndex: 9999, // Set a higher z-index value to ensure the Snackbar is displayed above other elements
      }}
    />
  );
}
