import React from 'react'
import workex from '../Images/m.png';
import project from '../Images/m.png';
import certificate from '../Images/m.png';
import "../../../stylesheets/financeanalyst.css";
import { Box } from '@mui/material';


const Certificates = () => {
    const [img , setImg] = React.useState(certificate);
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
      }}
    >
      <div className="certificate-box-f" id="certificate-box"  style={{ padding: '10px' , margin: 'auto'}}>
        <h2>Work Ex Certificates</h2>
        <p>This program offers a unique <br/>opportunity to gain 3 Certificates </p>
        <div className="buttons-certificate-box-f">
            <div className="button-certificate-box-f" onClick={()=> setImg(certificate)}>Lab Training</div>
            <div className="button-certificate-box-f" onClick={()=> setImg(project)}>Capstone Project</div>
            <div className="button-certificate-box-f" onClick={()=> setImg(workex)}>Internship Certificate</div>
        </div>
        <div className="cert-image-container-f">
        <img src={img} alt="certificate" className="certificate-img-f"/>
        
        </div>
        
    </div>
    </Box>
  )
}

export default Certificates