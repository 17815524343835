import * as React from 'react';
import { Box, Card, CardContent, CardMedia, Typography } from '@mui/material';
import { GET_COURSES } from '../../../api/vsc';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook for redirection

const CourseViewer = ({heading }) => {
    const [courses, setCourses] = React.useState([]);
    const history = useNavigate(); 

    React.useEffect(() => {
        axios.get(GET_COURSES)
            .then(res => {
                const sortedCourses = res.data.data.sort((a, b) => {
                    const priorityA = a.priority || 1000;
                    const priorityB = b.priority || 1000;
                    return priorityA - priorityB;
                });
    
                const filteredCourses = sortedCourses.filter(course => course.tags.includes(heading === "Non Coder Friendly" ? "Non Coder Job Friendly":heading));
                
                setCourses(filteredCourses);
            })
            .catch(err => {
                console.error('Error fetching courses:', err);
            });
    }, [heading]);

    const handleCourseClick = (courseId) => {
        history(`/courses`);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                // boxShadow: 'rgba(0, 0, 0, 0.10) 0px 1px 1px, rgb(51, 51, 51) 0px 0px 0px 1px',
                boxShadow: '#6a58c6 0px 2px 5px 0px, #6a58c6 0px 1px 1px 0px',
                // boxShadow: '#6a58c6 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset',
                padding: '20px',
                borderRadius:'20px',
                width: '96%',

            }}>
            <Typography variant="h6" fontWeight="800" style={{ color: '#000',  textAlign:'center' , marginBottom:'10px' }}>
            {heading}
            </Typography>
            <Box
                sx={{
                    display:'flex',
                    flexDirection: {xs: 'column',sm:'row'},
                    gap: '10px'

                }}
            >
            {courses.slice(0, 2).map((course, index) => (
                <Card
                    key={index}
                    sx={{
                        margin: '10px',
                        cursor: 'pointer',
                        width: 300,
                        height: 250,
                        boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px'
                    }}
                    onClick={() => handleCourseClick(course.id)} // Pass course id to handleCourseClick
                >
                    <CardMedia
                        component="img"
                        height={160}
                        image={course.coursePreview} // Assuming coursePreview is the URL of the course image
                        alt={course.courseName} // Assuming courseName is the name of the course
                    />
                    <CardContent>
                    <Typography variant="h6" textAlign="center" fontFamily={'Poppins'}>{course.courseName}</Typography>
                    </CardContent>
                </Card>
            ))}
            </Box>
            <Box 
            sx={{
                    display:'flex',
                    flexDirection: {xs: 'column',sm:'row'},
                    gap: '10px'
                    
                }}
            >
            {courses.slice(2, 4).map((course, index) => (
                <Card
                    key={index}
                    sx={{
                        width: 300,
                        height: 250,
                        margin: '10px',
                        cursor: 'pointer',
                        boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px'
                        
                    }}
                    onClick={() => handleCourseClick(course.id)} // Pass course id to handleCourseClick
                >
                    <CardMedia
                        component="img"
                        height={160}
                        image={course.coursePreview} 
                        alt={course.courseName} 
                    />
                    <CardContent>
                        <Typography variant="h6" textAlign="center" fontFamily={'Poppins'}>{course.courseName}</Typography>
                    </CardContent>
                </Card>
            ))}
            </Box>
            
        </Box>
    );
};

export default CourseViewer;
