import React, { useState , useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import axios from 'axios';
import { USER } from "../../../api/vsc";


const ApexChart = () => {
  const [series, setSeries] = useState([
    {
      data: [0 , 0 , 0, 0],
    },
  ]);
  useEffect(() => {
     axios
    .get(USER, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
    .then((response) => {
      const totalCompletedVideos = response.data.courseDetails.reduce((total, course) => {
        return total + course.videosCompleted.length;
      }, 0);
      setSeries([
        {
          data: [response.data.completedWatchnow.length, totalCompletedVideos , response.data.completedBlog.length , response.data.completedCaseStudy.length , response.data.completedBadges.length],
        },
      ]);
    })
    .catch((error) => {
      console.error(error);
    });
  } , [])
 

  const options = {
    chart: {
      type: 'bar',
      height: 350
    },
    plotOptions: {
      bar: {
        colors: {
          ranges: [{
            from: 0,
            to: 100,
            color: '#6a58c6'
          }]
        },
        borderRadius: 4,
        horizontal: true,
        width: 10
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: ['Watchnows' , 'Videos' , 'Blogs' , 'CaseStudies' , 'Badges'],
      title: {
        text: 'Completed Count',
        offsetX: 0,
        offsetY: 10,
        style: {
          fontSize: '14px',
          fontWeight: 'bold',
        },
      },
    },
    yaxis: {
      title: {
        text: 'Categories',
        offsetX: 0,
        offsetY: 0,
        style: {
          fontSize: '14px',
          fontWeight: 'bold',
        },
      },
    },
  };

  return (
    <div id="chart" style={{ boxShadow: 'rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px',borderRadius:'10px'}}>
      <ReactApexChart options={options} series={series} type="bar"  height={'270px'} />
    </div>
  );
};

export default ApexChart;
