import React, { useState, useEffect } from "react";
import "../../../../stylesheets/Kickstart_details.css";
import { useHistory } from "react-router-dom";
import course_1 from "../../Images/mvp.png";
import course_2 from "../../Images/gtm.png";
import course_3 from "../../Images/bmc.png";
import { Link , useNavigate } from "react-router-dom";
import Accordian from "../Accordian/Accordian";
import underline from "../../Images/underline_2.svg";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import CurrencyRupeeOutlinedIcon from "@mui/icons-material/CurrencyRupeeOutlined";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import DateRangeOutlinedIcon from "@mui/icons-material/DateRangeOutlined";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import RecordVoiceOverOutlinedIcon from "@mui/icons-material/RecordVoiceOverOutlined";
import AutoAwesomeOutlinedIcon from "@mui/icons-material/AutoAwesomeOutlined";
import EventNoteOutlinedIcon from "@mui/icons-material/EventNoteOutlined";
import DateRangeIcon from "@mui/icons-material/DateRange";
import ScheduleIcon from "@mui/icons-material/Schedule";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import Footer from "../../../Footer/Footer";
import axios from "axios";
import { Mail } from "@mui/icons-material";
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import LinearProgressWithLabel from "../VideoPlayer/LinearProgressWithLabel";
import { Button, IconButton, useMediaQuery,Box ,Typography , Modal } from "@mui/material";

const Course1_details = ({ isAuth, setAuth }) => {
  const { MARKOKNOW_KICKSTART, USER , GET_USER , USER_ACCESS } = require("../../../../api/vsc");
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [course1, setCourse1] = useState("");
  const [course2, setCourse2] = useState("");
  const [course3, setCourse3] = useState("");
  const [course1P ,setCourse1P ] = useState(0);
  const [course2P ,setCourse2P ] = useState(0);
  const [course3P ,setCourse3P ] = useState(0);
  const [paid , setPaid] = useState(false);
  const isMobile = useMediaQuery('(max-width:820px)');
  const [openE, setOpenE] = React.useState(false);
  const handleOpen = () => setOpenE(true);
  const handleCloseE = () => setOpenE(false);
  const [activateDate , setActivateDate] = React.useState('');
  const [activateTime , setActivateTime] = React.useState('');
  const [cA , setCa] = React.useState(false);
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: !isMobile?400:'100%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
  const navigate = useNavigate();
  useEffect(() => {
    axios
      .get(USER_ACCESS + window.localStorage.id)
      .then((res) => {
        setPaid(res.data.access);

      })
      .catch((err) => {});
      axios
      .get(GET_USER+window.localStorage.id+"/"+"64fb08d372d0fde41877df63")
      .then(res => {
         setCourse1P((res.data.course.completed/res.data.course.total)*100);
      })
      .catch(err => {
      })
      axios
      .get(GET_USER+window.localStorage.id+"/"+"64fb15b672d0fde41877e193")
      .then(res => {
         setCourse2P((res.data.course.completed/res.data.course.total)*100);
      })
      .catch(err => {
      })
      axios
      .get(GET_USER+window.localStorage.id+"/"+"64fb105b72d0fde41877e101")
      .then(res => {
         setCourse3P((res.data.course.completed/res.data.course.total)*100);
      })
      .catch(err => {
      })
  }, []);
  useEffect(() => {
    axios
      .get(USER, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setData(response.data);
        setCourse1(response.data.course1 || ""); // Set the initial state of course1
        setCourse2(response.data.course2 || ""); // Set the initial state of course2
        setCourse3(response.data.course3 || ""); // Set the initial state of course3
        setCourse1P(((response.data.courseDetails.find(course => course.courseId === "64fb08d372d0fde41877df63").videosCompleted.length+response.data.courseDetails.find(course => course.courseId === "64fb08d372d0fde41877df63").pdfsCompleted.length)/response.data.courseDetails.find(course => course.courseId === "64fb08d372d0fde41877df63").total)*100);
        setCourse2P(((response.data.courseDetails.find(course => course.courseId === "64fb15b672d0fde41877e193").videosCompleted.length+response.data.courseDetails.find(course => course.courseId === "64fb15b672d0fde41877e193").pdfsCompleted.length)/response.data.courseDetails.find(course => course.courseId === "64fb15b672d0fde41877e193").total)*100);
        setCourse3P(((response.data.courseDetails.find(course => course.courseId === "64fb105b72d0fde41877e101").videosCompleted.length+response.data.courseDetails.find(course => course.courseId === "64fb105b72d0fde41877e101").pdfsCompleted.length)/response.data.courseDetails.find(course => course.courseId === "64fb105b72d0fde41877e101").total)*100);
        var currentDate = new Date();
        const course = response.data.certificateActivate.find(
          (course) => course.courseId === "64fb08d372d0fde41877df63"
        ).dateOfActivation;
        const activate = new Date(course)
        var array = activate.toLocaleString().split(',');
        setActivateDate(array[0]);
        setActivateTime(array[1]);
        if (currentDate > activate) {
          setCa(true);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
  }, [data]);

  const user = localStorage.getItem("email");

  const addCourse1 = () => {
    const newCourse1 = course1 === "1" ? "0" : "1";
    const newCourse2 = course2 === "2" ? "0" : "2"; 
    const newCourse3 = course3 === "3" ? "0" : "3"; 
    setCourse1(newCourse1);
    setCourse2(newCourse2); 
    setCourse3(newCourse3); 

    axios
      .post(MARKOKNOW_KICKSTART, {
        course1: newCourse1,
        course2 : newCourse2 ,
        course3 : newCourse3,
        user,
      })
      .then((response) => {})
      .catch((err) => {
      });
  };
  const addCourse2 = () => {
    const newCourse2 = course2 === "2" ? "0" : "2"; // Toggle the value between 0 and 2
    setCourse2(newCourse2); // Update the state with the new value
    axios
      .post(MARKOKNOW_KICKSTART, {
        course2: newCourse2, // Pass the new value in the request body
        course1,
        course3,
        user,
      })
      .then((response) => {})
      .catch((err) => {
      });
  };
  const addCourse3 = () => {
    const newCourse3 = course3 === "3" ? "0" : "3"; // Toggle the value between 0 and 3
    setCourse3(newCourse3); // Update the state with the new value
    axios
      .post(MARKOKNOW_KICKSTART, {
        course3: newCourse3, // Pass the new value in the request body
        course1,
        course2,
        user,
      })
      .then((response) => {})
      .catch((err) => {
      });
  };

  return (
    <>
      <div className="details_main">
        <div className="heading">
          <h1>Startup KickStart</h1>
          <p >
            Know startups and launch your own with understanding of real cases,
            startup challenges, user interviews, founder's perspective of
            approaching and solving problems
          </p>
        </div>
        <div className="video_box">
          {/* <iframe
          src="https://www.youtube.com/embed/6vOmBOvIyPE?autoplay=0&amp;mute=0&amp;controls=0&amp;origin=https%3A%2F%2Fmasaischool.com&amp;playsinline=1&amp;showinfo=0&amp;rel=0&amp;iv_load_policy=3&amp;modestbranding=1&amp;enablejsapi=1&amp;widgetid=1"
          id="widget2"
          title="YouTube video player"
          frameborder="1"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen="1"
          data-gtm-yt-inspected-11="true"
        ></iframe> */}
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/E9j78SVAcfo"
            title="YouTube video player"
            id="widget2"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen="1"
          ></iframe>
        </div>

        <div className="box_of_data">
          <div className="sidebar2"></div>
          <div className="sidebar">
            <div style={{ display: "flex justify-around" }}>
              <p className="sidebar_heading">
                Startup Kickstart <br />
              </p>
              <a href="#course-details">
                <p className="sidebar_data">Course Details</p>
              </a>
              <a href="#our-courses-new">
                <p className="sidebar_data">Our Courses</p>{" "}
              </a>
              <a href="#Playbook Bundle-new">
                <p className="sidebar_data">What is in the Playbook Bundle</p>
              </a>

              <a href="#Recommended-For-new">
                <p className="sidebar_data">Recommended For</p>
              </a>
              <a href="#Recommended-For-new">
                <p className="sidebar_data">Validity</p>
              </a>
              <a href="#enrolment-process-new">
                <p className="sidebar_data">Enrollment Process </p>
              </a>
              <a href="#faq">
                <p className="sidebar_data">FAQ </p>
              </a>

              <div className="apply-now" onClick={()=>{
                  navigate(isAuth?"/program/markoknow-kickstart/courseform/64e0aa4ebd5f2774946f96f9":"/login" , {state:{redirect:'/program/markoknow-kickstart/courseform'}});
              }}>
                  {/* <a href={isAuth?"/program/markoknow-kickstart/courseform":"/login" } > */}
                    <button>Apply Now</button>
                  {/* </a> */}
                {/* <a href="/program/markoknow-kickstart/landing-page">
                  <div className="details">View Details</div>
                </a> */}
              </div>
            </div>
          </div>
          <div className="left_box_of_data">
            <div className="intro2" id="intro-course">
              <div className="intro-data">
                <div className="intro-heading">
                  {/* <a href={isAuth?"/program/markoknow-kickstart/courseform":"/login"} > */}
                    <div className="intro-button-1" onClick={()=>{
                  navigate(isAuth?"/program/markoknow-kickstart/courseform/64e0aa4ebd5f2774946f96f9":"/login" , {state:{redirect:'/program/markoknow-kickstart/courseform'}});
              }}>
                      {/* <AccessTimeOutlinedIcon style={{ marginRight: "1rem" }} /> */}
                      Apply Now
                    </div>
                  {/* </a> */}

                  {/* <a href="/program/markoknow-kickstart/landing-page">
                    <div className="intro-button-2">
                    
                      View Details
                    </div>
                  </a> */}
                </div>

                {/* <div className="part_time_course">
                  Explore Our Part Time Course{" "}
                  <ArrowForwardIosOutlinedIcon
                    style={{
                      fontSize: "1rem",
                      // border: "1px solid red",
                      marginBottom: "0.2rem",
                    }}
                  />
                </div> */}
              </div>
            </div>

            <div id="course-details">
              <h2>Course Details</h2>
              <p
                style={{
                  fontSize: "16px",
                  fontFamily: "Open Sans",
                  color: "#1A202C",
                  margin: "0 0 32px",
                }}
              >
                Get the most on-point playbook on kickstarting the startup
                journey with well curated modules
              </p>
              <div className="details_boxs">
                <div className="box_container">
                  <div className="box1">
                    <h2
                      style={{
                        fontSize: "2.5rem",
                        color: "#6e71cc",
                      }}
                    >
                      15
                    </h2>
                    <p
                      style={{
                        fontFamily: "Poppins",
                        color: "black",
                        fontSize: "18px",
                        fontWeight: "600",
                        padding: "14px 0px 0px",
                      }}
                    >
                      Sessions
                    </p>
                    {/* <div
                    style={{
                      fontFamily: "Poppins",
                      color: "black",
                      fontSize: "18px",
                      fontWeight: "600",
                      // border: "1px solid red",
                    }}
                  >
                    (5 Cases)
                  </div> */}
                  </div>
                  <div className="box1">
                    <h2
                      style={{
                        fontSize: "2.5rem",
                        color: "#6e71cc",
                      }}
                    >
                      5
                    </h2>

                    <p
                      style={{
                        fontFamily: "Poppins",
                        color: "black",
                        fontSize: "18px",
                        fontWeight: "600",
                        padding: "14px 0px 0px",
                      }}
                    >
                      Cases
                    </p>
                    {/* <div
                    style={{
                      fontFamily: "Poppins",
                      color: "black",
                      fontSize: "18px",
                      fontWeight: "600",
                    }}
                  >
                    10am - 5pm Saturday
                  </div> */}
                  </div>
                  <div className="box1" id="our-courses-new">
                    <h2
                      style={{
                        fontSize: "2.5rem",
                        color: "#6e71cc",
                      }}
                    >
                      7
                    </h2>
                    <p
                      style={{
                        fontFamily: "Poppins",
                        color: "black",
                        fontSize: "18px",
                        fontWeight: "600",
                        padding: "14px 0px 0px",
                      }}
                    >
                      business models
                    </p>
                    {/* <div
                    style={{
                      fontFamily: "Poppins",
                      color: "black",
                      fontSize: "18px",
                      fontWeight: "600",
                    }}
                  >
                    (8 Months)
                  </div> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="kickstart_courses_box">
              <h2 id="our-courses">Our Courses</h2>
              <div className="level_courses">
                <div className="course_1">
                  <img
                    src={course_1}
                    style={{
                      objectFit: "fill",
                      width: "100%",
                      height: "auto",
                      display: "block",
                      borderStyle: "none",
                      marginBottom: "20px",
                      borderRadius: "20px 20px 0px 0px",
                    }}
                  />
                  <div className="course_data">
                    <p
                      style={{
                        padding: "2px 10px",
                        backgroundColor: "#F2F6FF",
                        borderRadius: "10px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "233px",
                        height: "20px",
                        color: "#6E71CC",
                        textTransform: "uppercase",
                        fontFamily: "Open Sans",
                        fontSize: "12px",
                        lineHeight: "16px",
                        fontWeight: "600",
                      }}
                    >
                      Undergrad Students or Grad
                    </p>
                    <p
                      style={{
                        color: "#3B3435",
                        fontSize: "1.125rem",
                        fontWeight: "600",
                        marginTop: "10px",
                        fontFamily: "Poppins",
                      }}
                    >
                      MVP Building (Step 1 )
                    </p>
                    <p
                      style={{
                        color: "#21191B",
                        fontSize: "24px",
                        fontWeight: "700",
                        fontFamily: "Poppins",
                      }}
                    >
                      MVP Building
                    </p>
                    <div
                      style={{
                        margin: "10px 0px 0px",
                        padding: "0px 10px",
                      }}
                    >
                      <p
                        style={{
                          color: "#21191B",
                          marginBottom: "10px",
                          fontWeight: "600",
                        }}
                      >
                        <DateRangeIcon
                          style={{
                            color: "gray",
                            fontSize: "18px",
                            position: "relative",
                            top: "5px",
                            marginRight: "5px",
                            marginLeft: "-13px",
                          }}
                        />
                        Minimum Viable Product Building
                      </p>
                      <p
                        style={{
                          color: "#21191B",
                          marginBottom: "10px",
                          fontWeight: "600",
                        }}
                      >
                        <span>
                          <ScheduleIcon
                            style={{
                              color: "gray",
                              fontSize: "18px",
                              position: "relative",
                              top: "5px",
                              marginRight: "5px",
                              marginLeft: "-13px",
                            }}
                          />
                        </span>
                        2 Real Time Cases
                      </p>
                      <p
                        style={{
                          color: "#21191B",
                          marginBottom: "10px",
                          fontWeight: "600",
                        }}
                      >
                        <span>
                          <CurrencyRupeeIcon
                            style={{
                              color: "gray",
                              fontSize: "18px",
                              position: "relative",
                              top: "5px",
                              // marginRight: "5px",
                              marginLeft: "-13px",
                            }}
                          />
                        </span>{" "}
                        Outcome: Problem Selection, Product decision, Product
                        features, Idea Execution
                      </p>
                      <LinearProgressWithLabel value={course1P} color={"#000"}/>
                     <p style={{fontWeight:'900'}}>{Math.round(course1P)}% Completed</p>
                    </div>
                  </div>
                  <div className="course_1_button">
                    {/* <a href="/program/markoknow-kickstart/landing-page">
                      <div className="details">View Details</div>
                    </a> */}
                    {
               paid ? (
                  <div className="course_1_button">
                <a href="/program/videoplayer/64fb08d372d0fde41877df63">
                  <Button variant="contained" style={{backgroundColor:'#6E71CC', color:'#fff'}}>Open</Button>
                </a></div>
        
              ): 
              isAuth ? (
                  <button
                    onClick={addCourse1}
                    style={{
                      backgroundColor:
                        course1 === "1"
                          ? "green"
                          : course1 === "0"
                          ? "#6a58c6"
                          : "initial",
                    }}
                  >
                    {course1 === "1"
                      ? "Course  Added"
                      : course1 === "0"
                      ? "Add Course "
                      : "Button Text"}
                  </button>
                ) : (
                  <button
                    onClick={(e) => {
                        navigate('/login' , {state:{redirect:'/courses'}});
                    }}
                    style={{
                      backgroundColor:"#6a58c6"
                        
                    }}
                  >
                    Read More
                  </button>
            
                )
                }
                  </div>
                </div>
                <div className="course_2">
                  <img
                    src={course_2}
                    style={{
                      objectFit: "fill",
                      width: "100%",
                      height: "auto",
                      display: "block",
                      borderStyle: "none",
                      marginBottom: "20px",
                      borderRadius: "20px 20px 0px 0px",
                    }}
                  />
                  <div className="course_data">
                    <p
                      style={{
                        padding: "2px 10px",
                        backgroundColor: "#F2F6FF",
                        borderRadius: "10px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "233px",
                        height: "20px",
                        color: "#6E71CC",
                        textTransform: "uppercase",
                        fontFamily: "Open Sans",
                        fontSize: "12px",
                        lineHeight: "16px",
                        fontWeight: "600",
                      }}
                    >
                      Undergrad Students or Grad
                    </p>
                    <p
                      style={{
                        color: "#3B3435",
                        fontSize: "1.125rem",
                        fontWeight: "600",
                        marginTop: "10px",
                        fontFamily: "Poppins",
                      }}
                    >
                      GTM Strategy (Step 2)
                    </p>
                    <p
                      style={{
                        color: "#21191B",
                        fontSize: "24px",
                        fontWeight: "700",
                        fontFamily: "Poppins",
                      }}
                    >
                      GTM Strategy
                    </p>
                    <div
                      style={{
                        margin: "10px 0px 0px",
                        padding: "0px 10px",
                      }}
                    >
                      <p
                        style={{
                          color: "#21191B",
                          marginBottom: "10px",
                          fontWeight: "600",
                        }}
                      >
                        <DateRangeIcon
                          style={{
                            color: "gray",
                            fontSize: "18px",
                            position: "relative",
                            top: "5px",
                            marginRight: "5px",
                            marginLeft: "-13px",
                          }}
                        />
                        Go To Market Strategy
                      </p>
                      <p
                        style={{
                          color: "#21191B",
                          marginBottom: "10px",
                          fontWeight: "600",
                        }}
                      >
                        <span>
                          <ScheduleIcon
                            style={{
                              color: "gray",
                              fontSize: "18px",
                              position: "relative",
                              top: "5px",
                              marginRight: "5px",
                              marginLeft: "-13px",
                            }}
                          />
                        </span>
                        2 Real Time Cases
                      </p>
                      <p
                        style={{
                          color: "#21191B",
                          marginBottom: "10px",
                          fontWeight: "600",
                        }}
                      >
                        <span>
                          <CurrencyRupeeIcon
                            style={{
                              color: "gray",
                              fontSize: "18px",
                              position: "relative",
                              top: "5px",
                              // marginRight: "5px",
                              marginLeft: "-13px",
                            }}
                          />
                        </span>{" "}
                        Outcome: Deciding Target Audience,Planning Buyer's
                        Journey, Creating Process of Execution
                      </p>
                      <LinearProgressWithLabel value={course2P} color={"#000"}/>
                      <p style={{fontWeight:'900'}}>{Math.round(course2P)}% Completed</p>
                    </div>
                  </div>
                  <div className="course_2_button" id="Playbook Bundle-new">
                    {/* <a href="/program/markoknow-kickstart/landing-page">
                      <div className="details">View Details</div>
                    </a> */}
                    {
               paid ? (
                  <div className="course_2_button">
                <a href="/program/videoplayer/64fb15b672d0fde41877e193">
                  <Button variant="contained" style={{backgroundColor:'#6E71CC', color:'#fff'}}>Open</Button>
                </a></div>
        
              ): 
              isAuth ? (
                  <button
                    onClick={addCourse1}
                    style={{
                      backgroundColor:
                        course2 === "2"
                          ? "green"
                          : course2 === "0"
                          ? "#6a58c6"
                          : "initial",
                    }}
                  >
                    {course2 === "2"
                      ? "Course  Added"
                      : course2 === "0"
                      ? "Add Course "
                      : "Button Text"}
                  </button>
                ) : (
                  <button
                    onClick={(e) => {
                        navigate('/login' , {state:{redirect:'/courses'}});
                    }}
                    style={{
                      backgroundColor:"#6a58c6"
                        
                    }}
                  >
                    Read More
                  </button>
            
                )
            }
                  </div>
                </div>
                <div className="course_3">
                  <img
                    src={course_3}
                    style={{
                      objectFit: "fill",
                      width: "100%",
                      height: "auto",
                      display: "block",
                      borderStyle: "none",
                      marginBottom: "20px",
                      borderRadius: "20px 20px 0px 0px",
                    }}
                  />
                  <div className="course_data">
                    <p
                      style={{
                        padding: "2px 10px",
                        backgroundColor: "#F2F6FF",
                        borderRadius: "10px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "233px",
                        height: "20px",
                        color: "#6E71CC",
                        textTransform: "uppercase",
                        fontFamily: "Open Sans",
                        fontSize: "12px",
                        lineHeight: "16px",
                        fontWeight: "600",
                      }}
                    >
                      Undergrad Students or Grad
                    </p>
                    <p
                      style={{
                        color: "#3B3435",
                        fontSize: "1.125rem",
                        fontWeight: "600",
                        marginTop: "10px",
                        fontFamily: "Poppins",
                      }}
                    >
                      BMC Creation (Step 3)
                    </p>
                    <p
                      style={{
                        color: "#21191B",
                        fontSize: "24px",
                        fontWeight: "700",
                        fontFamily: "Poppins",
                      }}
                    >
                      BMC Creation
                    </p>
                    <div
                      style={{
                        margin: "10px 0px 0px",
                        padding: "0px 10px",
                      }}
                    >
                      <p
                        style={{
                          color: "#21191B",
                          marginBottom: "10px",
                          fontWeight: "600",
                        }}
                      >
                        <DateRangeIcon
                          style={{
                            color: "gray",
                            fontSize: "18px",
                            position: "relative",
                            top: "5px",
                            marginRight: "5px",
                            marginLeft: "-13px",
                          }}
                        />
                        Business Model Canvas Creation
                      </p>
                      <p
                        style={{
                          color: "#21191B",
                          marginBottom: "10px",
                          fontWeight: "600",
                        }}
                      >
                        <span>
                          <ScheduleIcon
                            style={{
                              color: "gray",
                              fontSize: "18px",
                              position: "relative",
                              top: "5px",
                              marginRight: "5px",
                              marginLeft: "-13px",
                            }}
                          />
                        </span>
                        7 Business Models
                      </p>
                      <p
                        style={{
                          color: "#21191B",
                          marginBottom: "10px",
                          fontWeight: "600",
                        }}
                      >
                        <span>
                          <CurrencyRupeeIcon
                            style={{
                              color: "gray",
                              fontSize: "18px",
                              position: "relative",
                              top: "5px",
                              marginRight: "5px",
                              marginLeft: "-13px",
                            }}
                          />
                        </span>
                        Outcome : Decision factor for business model, shifting
                        business models
                      </p>
                      <LinearProgressWithLabel value={course3P} color={"#000"}/>
                      <p style={{fontWeight:'900'}}>{Math.round(course3P)}% Completed</p>
                    </div>
                  </div>
                  <div className="course_3_button">
                    {/* <a href="/program/markoknow-kickstart/landing-page">
                      <div className="details">View Details</div>
                    </a> */}
                    {
               paid ? (
                  <div className="course_3_button">
                <a href="/program/videoplayer/64fb105b72d0fde41877e101">
                  <Button variant="contained" style={{backgroundColor:'#6E71CC', color:'#fff'}}>Open</Button>
                </a>
                <IconButton
                  onClick={() => {
                    if (course3P<100 || !cA) setOpenE(true);

                    else navigate(`/certificate/64fb105b72d0fde41877e101`, {state:`Product To Model`});
                  }}
                 style={{marginLeft:'10px'}}><CardMembershipIcon/></IconButton>
                </div>
        
              ): 
              isAuth ? (
                  <button
                    onClick={addCourse1}
                    style={{
                      backgroundColor:
                        course3 === "3"
                          ? "green"
                          : course3 === "0"
                          ? "#6a58c6"
                          : "initial",
                    }}
                  >
                    {course3 === "3"
                      ? "Course  Added"
                      : course3 === "0"
                      ? "Add Course "
                      : "Button Text"}
                  </button>
                ) : (
                  <button
                    onClick={(e) => {
                        navigate('/login' , {state:{redirect:'/courses'}});
                    }}
                    style={{
                      backgroundColor:"#6a58c6"
                        
                    }}
                  >
                    Read More
                  </button>
            
                )
            }
                  </div>
                </div>
              </div>
            </div>
            <div className="elligibilty" id="Playbook Bundle">
              <h2>What is in the Playbook Bundle</h2>
              {/* <p
              style={{
                fontFamily: "Open sans",
                fontWeight: "500",
                fontSize: "16px",
                color: "#544d4f",
                marginTop: "8px ",
              }}
            >
              You should meet the following requirements to be eligible for this
              course.
            </p> */}
              <div className="elligibilty_box">
                <div className="box1">
                  <div>
                    <p
                      style={{
                        color: "#0A0103",
                        margin: "0px 0px 0px 48px",
                        fontSize: "16px",
                        fontFamily: "Poppins",

                        fontWeight: "700",
                      }}
                    >
                      MVP :
                    </p>
                    <span
                      style={{
                        position: "absolute",
                        color: "#3470E4",
                        marginTop: "24px",
                        // top: "0px",
                        // border: "1px solid red",
                      }}
                    >
                      <CheckCircleIcon />
                    </span>
                    <p
                      id="box1-p"
                      style={{
                        color: "#544D4F",
                        fontFamily: "Open sans",
                        fontSize: "14px",
                        // width: "24rem",
                        margin: "6px 0px 0px 48px",
                        height: "auto",
                        padding: "1rem 0rem 1rem 0rem",
                        // border: "1px solid red",
                        wordWrap: "break-word",
                      }}
                    >
                      Know how to decide upon the idea to convert into a
                      product.
                    </p>
                    <span
                      style={{
                        position: "absolute",
                        color: "#3470E4",
                        marginTop: "24px",
                        // top: "0px",
                        // border: "1px solid red",
                      }}
                    >
                      <CheckCircleIcon />
                    </span>
                    <p
                      id="box1-p"
                      style={{
                        color: "#544D4F",
                        fontFamily: "Open sans",
                        fontSize: "14px",
                        // width: "24rem",
                        margin: "6px 0px 0px 48px",
                        height: "auto",
                        padding: "1rem 0rem 1rem 0rem",
                        // border: "1px solid red",
                        wordWrap: "break-word",
                      }}
                    >
                      Check the viability of the product in the market.
                    </p>
                  </div>
                  <div className="box1_right">
                    <span
                      style={{
                        position: "absolute",
                        color: "#3470E4",
                        marginTop: "10px",
                      }}
                    >
                      {/* <AutoAwesomeOutlinedIcon /> */}
                    </span>
                    <p
                      style={{
                        color: "white",
                        margin: "0px 0px 0px 48px",
                        fontSize: "16px",
                        fontFamily: "Poppins",
                        fontWeight: "700",
                      }}
                    >
                      Holistic Development
                    </p>
                    <span
                      style={{
                        position: "absolute",
                        color: "#3470E4",
                        marginTop: "24px",
                        // top: "0px",
                        // border: "1px solid red",
                      }}
                    >
                      <CheckCircleIcon />
                    </span>
                    <p
                      id="box1-p"
                      style={{
                        color: "#544D4F",
                        fontFamily: "Open sans",
                        fontSize: "14px",
                        // width: "24rem",
                        margin: "6px 0px 0px 48px",
                        height: "auto",
                        padding: "1rem 0rem 1rem 0rem",
                        // border: "1px solid red",
                        wordWrap: "break-word",
                      }}
                    >
                      Visualize the requirement to build the product.
                    </p>
                    <span
                      style={{
                        position: "absolute",
                        color: "#3470E4",
                        marginTop: "24px",
                        // top: "0px",
                        // border: "1px solid red",
                      }}
                    >
                      <CheckCircleIcon />
                    </span>
                    <p
                      id="box1-p"
                      style={{
                        color: "#544D4F",
                        fontFamily: "Open sans",
                        fontSize: "14px",
                        // width: "24rem",
                        margin: "6px 0px 0px 48px",
                        height: "auto",
                        padding: "1rem 0rem 1rem 0rem",
                        // border: "1px solid red",
                        wordWrap: "break-word",
                      }}
                    >
                      Get to know how to understand user perspective for the
                      product
                    </p>
                  </div>
                </div>
                <div className="box2">
                  <div>
                    <span
                      style={{
                        position: "absolute",
                        color: "#3470E4",
                        marginTop: "10px",
                      }}
                    >
                      {/* <RecordVoiceOverOutlinedIcon /> */}
                    </span>
                    <p
                      style={{
                        color: "#0A0103",
                        margin: "0px 0px 0px 48px",
                        fontSize: "16px",
                        fontFamily: "Poppins",

                        fontWeight: "700",
                      }}
                    >
                      GTM :
                    </p>
                    <span
                      style={{
                        position: "absolute",
                        color: "#3470E4",
                        marginTop: "24px",
                        // top: "0px",
                        // border: "1px solid red",
                      }}
                    >
                      <CheckCircleIcon />
                    </span>
                    <p
                      id="box1-p"
                      style={{
                        color: "#544D4F",
                        fontFamily: "Open sans",
                        fontSize: "14px",
                        // width: "24rem",
                        margin: "6px 0px 0px 48px",
                        height: "auto",
                        padding: "1rem 0rem 1rem 0rem",
                        // border: "1px solid red",
                        wordWrap: "break-word",
                      }}
                    >
                      Test your target audience and figure out the best for you
                    </p>
                    <span
                      style={{
                        position: "absolute",
                        color: "#3470E4",
                        marginTop: "24px",
                        // top: "0px",
                        // border: "1px solid red",
                      }}
                    >
                      <CheckCircleIcon />
                    </span>
                    <p
                      id="box1-p"
                      style={{
                        color: "#544D4F",
                        fontFamily: "Open sans",
                        fontSize: "14px",
                        // width: "24rem",
                        margin: "6px 0px 0px 48px",
                        height: "auto",
                        padding: "1rem 0rem 1rem 0rem",
                        // border: "1px solid red",
                        wordWrap: "break-word",
                      }}
                    >
                      Create a differentiation in product based on competitive
                      landscape
                    </p>
                  </div>
                  <div className="box2_right">
                    <span
                      style={{
                        position: "absolute",
                        color: "#3470E4",
                        marginTop: "10px",
                      }}
                    >
                      {/* <AutoAwesomeOutlinedIcon /> */}
                    </span>
                    <p
                      style={{
                        color: "white",
                        margin: "0px 0px 0px 48px",
                        fontSize: "16px",
                        fontFamily: "Poppins",
                        fontWeight: "700",
                      }}
                    >
                      Holistic Development
                    </p>
                    <span
                      style={{
                        position: "absolute",
                        color: "#3470E4",
                        marginTop: "24px",
                        // top: "0px",
                        // border: "1px solid red",
                      }}
                    >
                      <CheckCircleIcon />
                    </span>
                    <p
                      id="box1-p"
                      style={{
                        color: "#544D4F",
                        fontFamily: "Open sans",
                        fontSize: "14px",
                        // width: "24rem",
                        margin: "6px 0px 0px 48px",
                        height: "auto",
                        padding: "1rem 0rem 1rem 0rem",
                        // border: "1px solid red",
                        wordWrap: "break-word",
                      }}
                    >
                      Create a proper messaging and positioning for your startup
                    </p>
                    <span
                      style={{
                        position: "absolute",
                        color: "#3470E4",
                        marginTop: "24px",
                        // top: "0px",
                        // border: "1px solid red",
                      }}
                    >
                      <CheckCircleIcon />
                    </span>
                    <p
                      id="box1-p"
                      style={{
                        color: "#544D4F",
                        fontFamily: "Open sans",
                        fontSize: "14px",
                        // width: "24rem",
                        margin: "6px 0px 0px 48px",
                        height: "auto",
                        padding: "1rem 0rem 1rem 0rem",
                        // border: "1px solid red",
                        wordWrap: "break-word",
                      }}
                    >
                      Map your go to market strategy to make a cutting edge
                      entry into your industry
                    </p>
                  </div>
                </div>

                <div className="box3">
                  <div>
                    <span
                      style={{
                        position: "absolute",
                        color: "#3470E4",
                        marginTop: "10px",
                      }}
                    >
                      {/* <RecordVoiceOverOutlinedIcon /> */}
                    </span>
                    <p
                      style={{
                        color: "#0A0103",
                        margin: "0px 0px 0px 48px",
                        fontSize: "16px",
                        fontFamily: "Poppins",

                        fontWeight: "700",
                      }}
                    >
                      BMC :
                    </p>
                    <span
                      style={{
                        position: "absolute",
                        color: "#3470E4",
                        marginTop: "24px",
                        // top: "0px",
                        // border: "1px solid red",
                      }}
                    >
                      <CheckCircleIcon />
                    </span>
                    <p
                      id="box1-p"
                      style={{
                        color: "#544D4F",
                        fontFamily: "Open sans",
                        fontSize: "14px",
                        // width: "24rem",
                        margin: "6px 0px 0px 48px",
                        height: "auto",
                        padding: "1rem 0rem 1rem 0rem",
                        // border: "1px solid red",
                        wordWrap: "break-word",
                      }}
                    >
                      Figuring the right business model for a startup
                    </p>
                    <span
                      style={{
                        position: "absolute",
                        color: "#3470E4",
                        marginTop: "24px",
                        // top: "0px",
                        // border: "1px solid red",
                      }}
                    >
                      <CheckCircleIcon />
                    </span>
                    <p
                      id="box1-p"
                      style={{
                        color: "#544D4F",
                        fontFamily: "Open sans",
                        fontSize: "14px",
                        // width: "24rem",
                        margin: "6px 0px 0px 48px",
                        height: "auto",
                        padding: "1rem 0rem 1rem 0rem",
                        // border: "1px solid red",
                        wordWrap: "break-word",
                      }}
                    >
                      Executing the same startup idea in different business
                      models
                    </p>
                  </div>
                  <div className="box3_right">
                    <span
                      style={{
                        position: "absolute",
                        color: "#3470E4",
                        marginTop: "10px",
                      }}
                    >
                      {/* <AutoAwesomeOutlinedIcon /> */}
                    </span>
                    <p
                      style={{
                        color: "white",
                        margin: "0px 0px 0px 48px",
                        fontSize: "16px",
                        fontFamily: "Poppins",
                        fontWeight: "700",
                      }}
                    >
                      Holistic Development
                    </p>
                    <span
                      style={{
                        position: "absolute",
                        color: "#3470E4",
                        marginTop: "24px",
                        // top: "0px",
                        // border: "1px solid red",
                      }}
                    >
                      <CheckCircleIcon id="Recommended-For-new" />
                    </span>
                    <p
                      id="box1-p"
                      style={{
                        color: "#544D4F",
                        fontFamily: "Open sans",
                        fontSize: "14px",
                        // width: "24rem",
                        margin: "6px 0px 0px 48px",
                        height: "auto",
                        padding: "1rem 0rem 1rem 0rem",
                        // border: "1px solid red",
                        wordWrap: "break-word",
                      }}
                    >
                      Prioritizing the business model based on resources
                    </p>
                    <span
                      style={{
                        position: "absolute",
                        color: "#3470E4",
                        marginTop: "24px",
                        // top: "0px",
                        // border: "1px solid red",
                      }}
                    >
                      <CheckCircleIcon />
                    </span>
                    <p
                      id="box1-p"
                      style={{
                        color: "#544D4F",
                        fontFamily: "Open sans",
                        fontSize: "14px",
                        // width: "24rem",
                        margin: "6px 0px 0px 48px",
                        height: "auto",
                        padding: "1rem 0rem 1rem 0rem",
                        // border: "1px solid red",
                        wordWrap: "break-word",
                      }}
                    >
                      Shifting from one business model to the others
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="elligibilty" id="Recommended-For">
              <h2>Recommended For</h2>
              {/* <p
              style={{
                fontFamily: "Open sans",
                fontWeight: "500",
                fontSize: "16px",
                color: "#544d4f",
                marginTop: "8px ",
              }}
            >
              You should meet the following requirements to be eligible for this
              course.
            </p> */}
              <div
                // className="elligibilty_box"
                style={{
                  // border: "1px solid red",
                  display: "flex",
                  alignItems: "start",
                  justifyContent: "start",
                }}
              >
                <div>
                  <span
                    style={{
                      position: "absolute",
                      color: "#3470E4",
                      marginTop: "10px",
                    }}
                  >
                    {/* <RecordVoiceOverOutlinedIcon /> */}
                  </span>
                  <p
                    style={{
                      color: "#0A0103",
                      margin: "0px 0px 20px 0px",
                      fontSize: "16px",
                      fontFamily: "Poppins",

                      fontWeight: "500",
                    }}
                  >
                    1. Undergrad/Post Grad Students interested in startups
                  </p>

                  <div>
                    <span
                      style={{
                        position: "absolute",
                        color: "#3470E4",
                        marginTop: "10px",
                      }}
                    >
                      {/* <RecordVoiceOverOutlinedIcon /> */}
                    </span>
                    <p
                      style={{
                        color: "#0A0103",
                        margin: "0px 0px 20px 0px",
                        fontSize: "16px",
                        fontFamily: "Poppins",

                        fontWeight: "500",
                      }}
                    >
                      2. Candidates looking to contribute at
                      managerial/leadership roles in Startups
                    </p>
                  </div>
                  <div>
                    <span
                      style={{
                        position: "absolute",
                        color: "#3470E4",
                        marginTop: "10px",
                      }}
                    >
                      {/* <RecordVoiceOverOutlinedIcon /> */}
                    </span>
                    <p
                      style={{
                        color: "#0A0103",
                        margin: "0px 0px 20px 0px",
                        fontSize: "16px",
                        fontFamily: "Poppins",

                        fontWeight: "500",
                      }}
                    >
                      3. Candidates planning to start their own startups in
                      future
                    </p>
                  </div>
                  <div>
                    <span
                      style={{
                        position: "absolute",
                        color: "#3470E4",
                        marginTop: "10px",
                      }}
                    >
                      {/* <RecordVoiceOverOutlinedIcon /> */}
                    </span>
                    <p
                      style={{
                        color: "#0A0103",
                        margin: "0px 0px 20px 0px",
                        fontSize: "16px",
                        fontFamily: "Poppins",

                        fontWeight: "500",
                      }}
                    >
                      4. Working Professionals planning side gigs
                    </p>
                  </div>
                  <div>
                    <span
                      style={{
                        position: "absolute",
                        color: "#3470E4",
                        marginTop: "10px",
                      }}
                    >
                      {/* <RecordVoiceOverOutlinedIcon /> */}
                    </span>
                    <p
                      id="enrolment-process-new"
                      style={{
                        color: "#0A0103",
                        margin: "0px 0px 20px 0px",
                        fontSize: "16px",
                        fontFamily: "Poppins",

                        fontWeight: "500",
                      }}
                    >
                      5. Early Stage Founders struggling on thinking broad
                    </p>
                  </div>
                </div>
              </div>
            </div>
            
            <div className="elligibilty" id="#Recommended-For">
            <h2>Validity </h2>
            <p
                      style={{
                        color: "#0A0103",
                        margin: "0px 0px 20px 0px",
                        fontSize: "16px",
                        fontFamily: "Poppins",

                        fontWeight: "500",
                      }}
                    >
                      The course would be available for 1 year of span
                    </p>
            </div>
            <div className="elligibilty" id="enrolment-process">
              <h2>Enrollment Process </h2>
              {/* <p
              style={{
                fontFamily: "Open sans",
                fontWeight: "500",
                fontSize: "16px",
                color: "#544d4f",
                marginTop: "8px ",
              }}
            >
              You should meet the following requirements to be eligible for this
              course.
            </p> */}
              <div
                // className="elligibilty_box"
                style={{
                  // border: "1px solid red",
                  display: "flex",
                  alignItems: "start",
                  justifyContent: "start",
                }}
              >
                <div>
                  <span
                    style={{
                      position: "absolute",
                      color: "#3470E4",
                      marginTop: "10px",
                    }}
                  >
                    {/* <RecordVoiceOverOutlinedIcon /> */}
                  </span>
                  <p
                    style={{
                      color: "#3470E4",
                      margin: "0px 0px 20px 0px",
                      fontSize: "16px",
                      fontFamily: "Poppins",

                      fontWeight: "500",
                    }}
                  >
                    Step 1. Apply through the link on the website for the
                    website
                  </p>

                  <div>
                    <span
                      style={{
                        position: "absolute",
                        color: "#3470E4",
                        marginTop: "10px",
                      }}
                    >
                      {/* <RecordVoiceOverOutlinedIcon /> */}
                    </span>
                    <p
                      style={{
                        color: "#3470E4",
                        margin: "0px 0px 20px 0px",
                        fontSize: "16px",
                        fontFamily: "Poppins",

                        fontWeight: "500",
                      }}
                    >
                      Step 2. Go to the redirect Link for payment
                    </p>
                  </div>
                  <div>
                    <span
                      style={{
                        position: "absolute",
                        color: "#3470E4",
                        marginTop: "10px",
                      }}
                    >
                      {/* <RecordVoiceOverOutlinedIcon /> */}
                    </span>
                    <p
                      style={{
                        color: "#3470E4",
                        margin: "0px 0px 20px 0px",
                        fontSize: "16px",
                        fontFamily: "Poppins",

                        fontWeight: "500",
                      }}
                    >
                      Step 3. Get the link of the course for signup on your mail
                      id
                    </p>
                  </div>
                  <span
                    style={{
                      position: "absolute",
                      color: "#3470E4",
                      marginTop: "17px",
                      // top: "0px",
                      // border: "1px solid red",
                    }}
                  >
                    <CheckCircleIcon />
                  </span>
                  <p
                    id="faq"
                    style={{
                      color: "#544D4F",
                      fontFamily: "Open sans",
                      fontSize: "14px",
                      width: "80%",
                      margin: "6px -200px 0px 48px",
                      height: "auto",
                      padding: "1rem 0rem 1rem 0rem",
                      // border: "1px solid red",
                      wordWrap: "break-word",
                    }}
                  >
                    For any issues, drop a mail at{" "}
                    <a href="mailto:hello@markoknow.com" style={{color:'#000' , fontWeight:"400"}}>
                      hello@markoknow.com{" "}
                    </a>
                    or connect for any issue on live chat with our team.
                  </p>
                </div>
              </div>
            </div>
            <div>
              <Accordian />
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={openE}
        onClose={handleCloseE}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} style={{width:isMobile?'70%':400}}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {!cA ?'Activate The Certicate' :'Complete The Course'}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          {!cA ? `The Certificate Would Be Activated After ${activateTime} on ${activateDate}`:'To Generate Your Certificate First Complete The Course.'}
          </Typography>
        </Box>
      </Modal>
      <div>
        <Footer />
      </div>
    </>
  );
};

export default Course1_details;
