import React from 'react';
import careerpaths from './Images/careerpaths.png';
import freecontent from './Images/freecontent.png';
import practical from './Images/practical.png';
import lpa from './Images/lpa.png';
import { Box } from '@mui/material';
import "../../stylesheets/imagesetup.css";


const ImageSetup = () => {
  return (
    <Box className="image-container" style={{width: '100%',display:'flex' , alignItems: 'center' , justifyContent:' center'}}>
      <Box className="image-box" >
        <img src={freecontent} alt="Free Content" className="rounded-image animation-1" />
        <img src={practical} alt="Practical" className="rounded-image-2 animation-2" />
      </Box>
      <Box className="image-box-2">
        <img src={lpa} alt="LPA" className="rounded-image-large animation-1" />
        <img src={careerpaths} alt="Career Paths" className="rounded-image-small animation-2" />

      </Box>
    </Box>
  )
}

export default ImageSetup