import React from 'react';
import { Container, Grid, Typography, Paper } from '@mui/material';

const ScholarshipSteps = () => {
    const steps = [
        "You apply for a scholarship through this form",
        "Our team will do the background check",
        "Our academic consultant will get in touch",
        "If you qualify for the scholarship, we will provide the required discount",
        "You can enroll with the discount and start learning",
    ];

    return (
        <Container
            style={{
                padding: '32px',
                width: {xs: '100%' , md: '60%'},
                boxSizing: 'border-box',
                marginTop: '50px',
            }}
        >
            <Typography variant="h4" align="center" gutterBottom>
            Step By Step Instruction
            </Typography>
            <Grid container spacing={2}>
                {steps.map((step, index) => (
                    <Grid item xs={12} key={index}>
                        <Paper
                            elevation={3}
                            style={{
                                padding: '16px',
                                marginBottom: '16px',
                                boxSizing: 'border-box',
                            }}
                        >
                            <Typography
                                variant="h6"
                                style={{
                                    fontWeight: 'bold',
                                    marginBottom: '8px',
                                    color: '#3f51b5',
                                }}
                            >
                                Step {index + 1}
                            </Typography>
                            <Typography variant="body1">
                                {step}
                            </Typography>
                        </Paper>
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
};

export default ScholarshipSteps;
