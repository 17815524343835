const { BASE_URL } = require("./client");

const GET_CASE_STUDY = BASE_URL + '/api/casestudy/get/';
const GET_CASE_STUDIES = BASE_URL + '/api/casestudy/get';
const COMPLETED_CASE_STUDY = BASE_URL + '/api/casestudy/completed/';
const SEND_EMAIL = BASE_URL + '/api/casestudy/email/subscribe';


module.exports = {
    GET_CASE_STUDY,
    GET_CASE_STUDIES,
    COMPLETED_CASE_STUDY,
    SEND_EMAIL
}


