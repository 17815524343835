import * as React from 'react';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import course_1 from "../../NewVirtualCampusStartup/Images/mvp.png";
import course_2 from "../../NewVirtualCampusStartup/Images/gtm.png";
import course_3 from "../../NewVirtualCampusStartup/Images/bmc.png";
import './index.css';
import {Link} from 'react-router-dom';
import axios from 'axios';
import { GET_COURSES, USER } from '../../../api/vsc';
import {useNavigate} from 'react-router-dom';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function NewCourse({isAuth , setUrl}) {
  const navigate = useNavigate();
  const [courses , setCourses] = React.useState([]);
  const [user , setUser] = React.useState({});
  React.useEffect(() => {
    axios.get(GET_COURSES)
    .then(res => {
      const sortedCourses = res.data.data.sort((a, b) => {
        const priorityA = a.priority || 1000; 
        const priorityB = b.priority || 1000; 
        return priorityA - priorityB;
      });
        setCourses(sortedCourses.slice(0,3));
    })
    .catch(err => {
    })
    axios
    .get(USER, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
    .then((response) => {
      setUser(response.data);
    })
    .catch((error) => {
      console.error(error);
    });
  })
  return (
    <div style={{boxShadow: 'rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px',borderRadius:'10px' , padding:'2%' , marginTop:'10px',marginBottom:'10px'}}>
    <div style={{display:'flex' , padding:'1%' , justifyContent:'space-between'}}>
    <h2  style={{textAlign:'left'}}>Explore More Courses</h2>
    <Button variant='contained' href="/courses" size='small' style={{background:'#6a58c6' , borderRadius:'10px' , fontSize:'12px' , height:'40px'}}>View All</Button>
    </div>
    
    <div style={{  display:'flex' , justifyContent:'center' , alignItems:'center' }}>
      
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        spacing={{ xs: 1, sm: 2, md: 3 }}
      >
      {courses.map((course , index) => (
        <Box onClick={(e)=> {
          (user.courses && user.courses.length > 0 && user.courses.includes(course._id)) ? navigate("/program/videoplayer/"+course._id) : navigate('/dashboard/'+window.localStorage.id+'?url=allcourses');
          }} sx={{width: {sm:'32%' , xs: '100%'} , cursor: 'pointer'}}>
      <Card className='course_card' sx={{width: '100%' , height: 230}}>
      <CardMedia
        component="img"
        alt="green iguana"
        sx={{
          width: '100%',
          height: 160
        }}
        image={course.coursePreview}
      />
    
      <CardActions style={{display:'flex' , justifyContent:'center', flexDirection:'column'}}>
      <Typography variant="h6" textAlign={'center'} fontSize="16px">
        {course.courseName}
        </Typography>
      {/* <Button variant='contained' size="small" style={{background:'#6a58c6' , borderRadius:'10px'}}>Open</Button> */}

      </CardActions>
    </Card>
    </Box>
      ))}
      </Stack>
    </div>
    </div>
  );
}
