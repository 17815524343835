import * as React from 'react';
import {Box , Typography ,Button , Avatar , Stack , Tooltip  } from '@mui/material';
import Media from '../Media/index';
import '../Profile/index.css';
import TrapFocus from '@mui/material/Unstable_TrapFocus';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import SearchIcon from '@mui/icons-material/Search';
import {useMediaQuery} from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Fade from '@mui/material/Fade';
import CloseIcon from '@mui/icons-material/Close';
import RemoveRedEyeSharpIcon from '@mui/icons-material/RemoveRedEyeSharp';
import Divider from '@mui/material/Divider';
import { GET_ALL_USERS } from '../../../api/user';
import axios from 'axios';
// const rows = [
//     { id: 1, name: 'John', level: '1', lastMonthRank: 'Last Month at 2' },
//     { id: 2, name: 'Jane', level: '2', lastMonthRank: 'Last Month at 4' },
//     // Add more rows as needed
//   ];
const MobileView = () => {
    const [ThisMonthButtonColor, setThisMonthButtonColor] = React.useState("#6a58c6");
  const [ThisMonthButtonTextnColor, setThisMonthButtonTextColor] = React.useState("white");
  const [AllTimeButtonColor, setAllTimeButtonColor] = React.useState("white");
  const [AllTimeButtonTextColor, setAllTimeButtonTextColor] = React.useState("black");
  const isSmallScreen = useMediaQuery('(max-width:600px)'); 
  const [show , setShow] = React.useState(false);
  const [searchQuery, setSearchQuery] = React.useState('');
  const [rows , setRows] = React.useState([]);
  const [row , setRow] = React.useState({});
  const [selected , setSelected] = React.useState('overall')
  const filteredRows = rows.filter(row =>
    row.name.toLowerCase().includes(searchQuery.toLowerCase()) || row.level.toLowerCase().includes(searchQuery.toLowerCase())
  );
  React.useEffect(() => {
    axios
      .get(GET_ALL_USERS)
      .then((res) => {
        let sortedUsers;
        if (selected === "overall") {
          sortedUsers = res.data.sort((a, b) => b.markoknowPoints - a.markoknowPoints);
        } else {
          sortedUsers = res.data.sort((a, b) => b.monthlyMarkoknowPoints - a.monthlyMarkoknowPoints);
        }
  
        const rows = sortedUsers.map((user, index) => ({
          id: user.id,
          name: user.name,
          lastMonthRank: selected === "overall" ? user.markoknowPoints : user.monthlyMarkoknowPoints,
          profileImg: user.profileImg,
          _id: user._id
        }));
  
        setRows(rows);
      })
      .catch((err) => {
        console.error("Error fetching users:", err);
      });
  }, [selected]);
  const handleClose = () => {
    setShow(false);
  }
  return (
    <Box sx={{
        width: '100%',
        // marginTop:'120px'
    }}> 
    <Typography variant='h5' align='center' sx={{fontFamily:'Poppins' , fontWeight: '700'}}>Leaderboard</Typography>
    <Divider />
       <Box 
          sx={{
            width: '100%'
          }}
        >
        <Box
          sx={{
            background: "#6a58c6",
            width: '100%',
            height: 200,
            borderRadius: '20px',
            display:'flex',
            flexDirection:'row',
            justifyContent:'space-evenly',
            alignItems: 'flex-end',
            mt: 2
          }}
        >
        <Box 
        sx={{
          display:'flex',
          flexDirection:'column',
          gap:'10px',
          justifyContent: 'center',
          alignItems: 'center',
          width: '30%', 
        }}>
          <Avatar src={rows[1]?.profileImg}/>
          <Box
            sx={{
              height: 100,
              width: '100%',
              background: 'rgba(255, 255, 255, 0.5)',
              borderTopLeftRadius:'20px',
              borderTopRightRadius:'20px'
            }}
          />
        </Box>
          
        <Box 
        sx={{
          display:'flex',
          flexDirection:'column',
          gap:'10px',
          justifyContent: 'center',
          alignItems: 'center',
          width: '30%', 
        }}>
          <Avatar src={rows[0]?.profileImg}/>
          <Box
            sx={{
              height: 120,
              width: '100%',
              background: 'rgba(255, 255, 255, 0.5)',
              borderTopLeftRadius:'20px',
              borderTopRightRadius:'20px'
            }}
          />
        </Box>
        <Box 
        sx={{
          display:'flex',
          flexDirection:'column',
          gap:'10px',
          justifyContent: 'center',
          alignItems: 'center',
          width: '30%', 
        }}>
          <Avatar src={rows[2]?.profileImg}/>
          <Box
            sx={{
              height: 80,
              width: '100%',
              background: 'rgba(255, 255, 255, 0.5)',
              borderTopLeftRadius:'20px',
              borderTopRightRadius:'20px'
            }}
          />
        </Box>

      
        </Box>
        <Box
            sx={{
                display:'flex',
                alignItems: 'center',
                justifyContent:'center',
                padding: '20px'
            }}
        >
        <Box
                  sx={{
                    backgroundColor: "#6a58c6",
                    width: isSmallScreen ? '60%' : '14.4vw',
                    height: isSmallScreen ? '8vh' : '5vh',
                    borderRadius: "20px",
                    display:'flex' , 
                    flexDirection:'row' , 
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Button
                    sx={{
                      backgroundColor: ThisMonthButtonColor,
                      height: isSmallScreen ? '7vh' : '4.6vh',
                      width: isSmallScreen ? '50%' : '7vw',
                      '&:hover': {
                        backgroundColor: ThisMonthButtonColor
                      },
                      borderRadius: '20px'
                    }}
                    onClick={() => {
                      if (ThisMonthButtonColor === "#6a58c6") {
                        setAllTimeButtonColor("#6a58c6");
                        setThisMonthButtonColor("white")
                        setAllTimeButtonTextColor("white");
                        setThisMonthButtonTextColor("black");
                        setSelected('month');
                      }

                    }}
                  >
                    <Typography sx={{ textTransform: "none", color: ThisMonthButtonTextnColor, cursor: "pointer" }}>
                      This Month
                    </Typography>
                  </Button>
                  <Button
                    sx={{
                      backgroundColor: AllTimeButtonColor,
                      height: isSmallScreen ? '7vh' : '4.6vh',
                      width: isSmallScreen ? '50%' : '7vw',
                      '&:hover': {
                        backgroundColor: AllTimeButtonColor
                      }
                      ,borderRadius: '20px'
                    }}
                    onClick={() => {
                      if (AllTimeButtonColor === "#6a58c6") {
                        setAllTimeButtonColor("white");
                        setThisMonthButtonColor("#6a58c6");
                        setAllTimeButtonTextColor("black");
                        setThisMonthButtonTextColor("white");
                        setSelected('overall');
                      }

                    }}
                  >
                    <Typography sx={{
                      textTransform: "none",
                      cursor: "pointer",
                      color: AllTimeButtonTextColor,
                    }}>
                      All Time
                    </Typography>
                  </Button>
                </Box>
        </Box>
        
        <FormControl sx={{ m: 1, width: '95%' , height: '5vh' }} variant="outlined">
              <OutlinedInput
                id="outlined-adornment-password"
                sx={{
                  height: '5vh',
                }}
                type={'text'}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      edge="end"
                    >
                      {<SearchIcon />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Search"
                placeholder='Search'
              />
            </FormControl>
        <Box sx={{  width: '100%' , height: '100%'}}>
        <TableContainer sx={{ width: '100%' , height: '100%'}}>
          <Table >
            <TableBody
                sx={{
                    display:'flex' , 
                    flexDirection:'column',
                    gap:'10px',
                    justifyContent:'center' , 
                    alignItems:'center'
                }}
            >
              {filteredRows.map((row, index) => (
                <TableRow 
                key={row.id} 
                sx={{
                    background: '#f0f0f0',
                    borderRadius: '20px',
                    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                    width: '95%',
                    display:'flex',
                    flexDirection:'row',
                    justifyContent: 'space-between',

                }}>
                  <TableCell>
                    <Avatar alt={row.name} src={row.avatar} />
                  </TableCell>
                  <TableCell sx={{
                    display:'flex',
                    flexDirection: 'column',
                    justifyContent:'flex-start',
                  }}>
                  <Typography textAlign={'left'}>
                      {row.name}
                  </Typography> 
                  <Typography variant="caption" fontWeight={'900'}>
                  Points: {row.lastMonthRank }
                  </Typography>
                  </TableCell> 
                  <TableCell sx={{  alignSelf: 'flex-end'}}>
                    <Tooltip title="See Profile">
                        <IconButton onClick={(e) => {setShow(true); setRow(row);}} >
                            <RemoveRedEyeSharpIcon /> 
                        </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        </Box>
        </Box>   
        <TrapFocus open disableAutoFocus disableEnforceFocus>
        <Fade appear={false} in={show}>
          <Paper
            role="dialog"
            aria-modal="false"
            aria-label="Cookie banner"
            square
            variant="outlined"
            tabIndex={-1}
            sx={{
              position: 'fixed',
              bottom: 0,
              left: 0,
              right: 0,
              m: 0,
              p: 2,
              borderWidth: 0,
              borderTopWidth: 1,
              // mt: 120,
              zIndex: 9999
            }}
          >
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              justifyContent="space-between"
              gap={2}
            >
              <Box>
        <Box 
          sx={{
            display:'flex',
            flexDirection: 'column' , 
            justifyContent:'center',
            alignItems:'center'
          }}
        >
        <Box
          sx={{
            display:'flex' , 
            justifyContent: 'center' , 
            alignItems: 'center',
            padding: '20px',
            width: '25%'
          }}
        >
          <Avatar sx={{
            width: 60 , 
            height:  60
          }}
            />
        </Box>
          <Box
            sx={{
              display:'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '15px',
              width: '75%',
              gap: '15px'
            }}
          >
          <Typography variant="h6" gutterBottom sx={{width: '100%' , textAlign:'center'}}>{row.name}</Typography>
          <button class="btn" type="button">
            <strong>{row.lastMonthRank + " Points"}</strong>
            <div id="container-stars">
              <div id="stars"></div>
            </div>

            <div id="glow">
              <div class="circle"></div>
              <div class="circle"></div>
            </div>
          </button>
          </Box>
          
        </Box>
        <Box
          sx={{
            padding: '20px'
          }}>
            <Typography variant='h5' textAlign={'center'} fontWeight="700" gutterBottom>Achievements</Typography>
            <Media props="Earned Badges" id={row._id}/>
          </Box>
    </Box>
    <IconButton
              aria-label="Close"
              onClick={handleClose}
              sx={{
                position: 'absolute',
                top: 0,
                right: 0,
              }}
            >
              <Tooltip title="Close">
                <CloseIcon />
              </Tooltip>
            </IconButton>
            </Stack>
          </Paper>
        </Fade>
      </TrapFocus>
    </Box>
  )
}

export default MobileView