import React from 'react';
import { Box , Typography , Button } from '@mui/material';
import "../../../stylesheets/financeanalyst.css";

const Timeline = () => {
  return (
    <Box
        sx={{
            display: 'flex' , 
            flexDirection: 'column',
            justifyContent: 'center' , 
            alignItems: 'center'
        }}
    >
         <Typography variant="h4" align="center" sx={{ mb: 2, width: { xs: '100%', md: '30%' } }}>
                    Pathway for Completing this <span style={{ color: "#6a58c6", fontWeight: 600 }}>Fastrack Program in 1 Month</span>
        </Typography>
        <div style={{display:'flex' , flexDirection:'row' , 
                    justifyContent: 'center'}}>
                <div class="vertical-dots-f">
                        <div class="dot"></div>
                        <div class="dot"></div>
                        <div class="dot"></div>
                        <div class="dot no-line"></div>
                        </div>
                <div className="pathway-container-f" style={{
                    display: 'flex' , 
                    alignItems: 'center' , 
                    justifyContent: 'center'
                }}>
                    <div className="pathway-box-f" style={{ backgroundColor: '#6a58c6', borderRadius: '20px' , color:'#fff' }}>
                        <h2 style={{color:'#fff'}} >Week 1 : Lab Training Modules</h2>
                        <p>Lab Oriented Practical Learning with application of prompts to show the results and experiment with various prompts. Practice in Live Labs various learned concepts.</p>
                    </div>
                    <div className="pathway-box-f" style={{ backgroundColor: '#FF5757', borderRadius: '20px' , color: '#fff' }}>
                        <h2 style={{color:'#fff'}}>Week 2 : Capstone Project</h2>
                        <p>Apply Your Learning from Training and Labs in a Capstone Project to where you understand your own  foothold on the topics.</p>
                    </div>
                    <div className="pathway-box-f" style={{ backgroundColor: '#7AFFC9', borderRadius: '20px' , color: '#000' }}>
                        <h2>Week 3 : Remote Internship Part 1</h2>
                        <p>Get Practice with Mock Interview that is well structured for helping you apply for various jobs. Start Your Remote Internship for Industry Training.</p>
                    </div>
                    <div className="pathway-box-f" style={{ backgroundColor: '#FFBD59', borderRadius: '20px' , color: '#000' }}>
                        <h2>Week 4 : Remote Internship Part 2</h2>
                        <p>Work Experience of Remote Internship for students to broaden their overall concepts and start applying for Product Management Roles. </p>
                    </div>
                    
                    </div>
                    
                        </div>
        
    </Box>
  )
}

export default Timeline