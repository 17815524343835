import React, { useState , useEffect } from 'react';
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
import { IconButton } from '@mui/material';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import useMediaQuery from '@mui/material/useMediaQuery';
import {useParams} from 'react-router-dom';
import axios from 'axios';
import {USER, USER_REFFERAL} from '../../api/vsc';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PdfViewer = () => {
  const [numPages, setNumPages] = useState(null);
  const [show , setShow] = useState(false);
  const params = useParams();
  const [pageNumber, setPageNumber] = useState(1);
  const isMobile = useMediaQuery('(max-width:600px)');
  const pdfUrl =
    'https://www.markoknow.com/coursePdf/toUqsQHbAWDaTizZ3nEQjo-1700294696337.pdf';

  useEffect(()=> {
    axios
      .get(USER_REFFERAL+params.id)
      .then(res => {
        if (res.data.data.unicorncasestudy === true){
          setShow(true);
        }
      })
  },[])

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const goToPreviousPage = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  const goToNextPage = () => {
    if (pageNumber < numPages) {
      setPageNumber(pageNumber + 1);
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center', 
        margin: 0,
        maxWidth: '100%',
        marginTop: '120px',
      }}
    >
      {show ?(<div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginBottom: '10px',
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <IconButton
            onClick={goToPreviousPage}
            disabled={pageNumber <= 1}
            style={{
              backgroundColor: pageNumber <= 1 ? '#000' : '#6A58C6',
            }}
          >
            <ArrowBackIosNewRoundedIcon style={{ color: '#fff' }} />
          </IconButton>
          <span style={{ margin: '0 10px' }}>
            Page {pageNumber} of {numPages}
          </span>
          <IconButton
            onClick={goToNextPage}
            disabled={pageNumber >= numPages}
            style={{
              backgroundColor: pageNumber >= numPages ? '#000' : '#6A58C6',
            }}
          >
            <ArrowForwardIosRoundedIcon style={{ color: '#fff' }} />
          </IconButton>
        </div>
      </div>): (
        <div> You Are Not Allowed </div>
      )}
      {show === true && (<Document
        file={pdfUrl}
        options={{ workerSrc: '/pdf.worker.js' }}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        <Page pageNumber={pageNumber} width={isMobile&&window.innerWidth-100} renderTextLayer={false} />
      </Document>)}
    </div>
  );
};

export default PdfViewer;
