import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import { Box, Card, CardMedia, CardContent, CardActions, Typography, Link } from '@mui/material';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import { useMediaQuery } from '@mui/material';
import { GET_BLOGS,IMAGE } from "../../api/blog";
import dummy from './Images/bmc.png'


const BlogsCarousel = ({ blogs }) => {
  const arrowStyles = {
    position: "absolute",
    top: ".7em",
    bottom: "auto",
    padding: ".4em",
    zIndex: 2,
 };
  const isMobile = useMediaQuery('(max-width: 600px)');
  const isSmallMobile = useMediaQuery('(max-width: 410px)');
  const isGreaterMobile = useMediaQuery('(max-width: 500px)');
  const getNumBlogsPerSlide = () => {
    if (isGreaterMobile) {
      return 1;
    } else if (isSmallMobile) {
      return 2;
    } else {
      return 3;
    }
  };
  const numBlogsPerSlide = getNumBlogsPerSlide();
  const numSlides = Math.ceil(blogs.length / numBlogsPerSlide);

  return (
    <Carousel
     className="carousel"
       style={{ display: 'flex', justifyContent: 'center' , width:'100%', alignItems:'center'}}
       showArrows={true}
       showIndicators={false}
       renderArrowPrev={(onClickHandler, hasPrev, label) =>
    hasPrev && (
      <button
        type="button"
        onClick={onClickHandler}
        title={label}
        style={{...arrowStyles,
          left: isMobile ? '0.5em' : '4.3em', // Adjust positioning for mobile and non-mobile
          top: '50%',
          transform: 'translateY(-50%)', // Center vertically
          backgroundColor: "#6a58c6",
          color: '#fff',
          borderRadius: '50%', // Make it round
          border: 'none',
          padding: '10px',
          zIndex: 1, // Ensure the button is above the carousel content
        }}
      >
        <ArrowBackIosNewRoundedIcon />
      </button>
    )
  }
  renderArrowNext={(onClickHandler, hasNext, label) =>
    hasNext && (
      <button
        type="button"
        onClick={onClickHandler}
        title={label}
        style={{
          ...arrowStyles,
          right: isMobile ? '0.5em' : '4.3em', // Adjust positioning for mobile and non-mobile
          top: '50%',
          transform: 'translateY(-50%)', // Center vertically
          backgroundColor: "#6a58c6",
          color: '#fff',
          borderRadius: '50%', // Make it round
          border: 'none',
          padding: '10px',
          zIndex: 1, // Ensure the button is above the carousel content
        }}
      >
        <ArrowForwardIosRoundedIcon />
      </button>
    )
  }
       >
     {Array.from({ length: numSlides }).map((_, slideIndex) => (
        <div key={slideIndex} 
        
        style={{
              display: 'flex',
              justifyContent: 'center',
              // gap: '1rem',
              alignItems:'center'
        }}>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              gap: '1rem',
              alignItems:'center'
            }}
          >
            {blogs.slice(slideIndex * numBlogsPerSlide, (slideIndex + 1) * numBlogsPerSlide).map((blog) => (
                <Card sx={{
                // height: isGreaterMobile?(isSmallMobile?'90%':''):'530px',
                // width: isGreaterMobile?'90%':'100%'
                width: isGreaterMobile ? '90%' : '30%',
                height:'100%'
              }}>
                <div >
                  <CardMedia
                    component="img"
                    // width="200px"
                    width="100%"
                    height="50%"
                    image={IMAGE + blog.imagePath}
                    // image={dummy}

                    alt="..."
                  />
                </div>
                <CardContent >
                  <Typography gutterBottom variant="h6" component="div">
                    <Box
                      component="div"
                      overflow="hidden"
                      textOverflow="ellipsis"
                      style={{
                        display: '-webkit-box',
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: 'vertical',
                        fontSize: '1.5rem',
                        fontWeight: 'bold',
                        lineHeight: '1.2',
                      }}
                    >
                      {blog.title}
                    </Box>
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    <Box
                      component="div"
                      overflow="hidden"
                      textOverflow="ellipsis"
                      style={{
                        display: '-webkit-box',
                        WebkitLineClamp: 4,
                        WebkitBoxOrient: 'vertical',
                        // fontSize: '1rem',
                        lineHeight: '1.2',
                      }}
                    >
                      {blog.subTitle.substring(0,100)+`...`}
                    </Box>
                  </Typography>
                </CardContent>
                  <CardActions style={{display:'flex' , justifyContent:'flex-end'}}>
                          <a
                                    href={'/blog/'+blog._id}
                                    style={{ 
                                      textDecoration: "none" ,
                                      color: "white",
                                        fontSize: "15px",
                                        padding: "5px",
                                        borderRadius: "5px",
                                        cursor: "pointer",
                                        backdropFilter: "blur(10px)",
                                        backgroundColor: "#6A58c6",
                                      }}
                                  >
                                      Read More
                                  </a>
                  </CardActions>
                </Card>
            ))}
          </Box>
        </div>
      ))}
    </Carousel>
  );
};

export default BlogsCarousel;
