const { BASE_URL } = require("./client");

const GET_DATA = BASE_URL+'/watchnow';
const DELETE_DATA = BASE_URL +"/watchnow/";
const GET_STREAK_VIDEOS = BASE_URL+"/watchnow/streaklist";
const GET_COMPLETE_VIDEOS = BASE_URL +"/watchnow/completedlist";
const STREAK_ADD = BASE_URL +"/watchnow/streak/add";
const STREAK_REMOVE = BASE_URL + '/watchnow/streak/remove';
const LEARN = BASE_URL +'/learn';
const WATCHNOW_COMPLETE = BASE_URL +"/watchnow/complete";
const POSTER = BASE_URL +"/watchnow/";

module.exports = {
    GET_DATA,
    DELETE_DATA,
    GET_STREAK_VIDEOS,
    GET_COMPLETE_VIDEOS,
    STREAK_ADD,
    STREAK_REMOVE,
    LEARN,
    WATCHNOW_COMPLETE,
    POSTER
}