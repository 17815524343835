const { BASE_URL } = require("./client");

const GET_EVENTS = BASE_URL +'/api/event/get';
const GET_EVENT = BASE_URL +'/api/event/get/';
const GET_SINGLE_EVENT_CERTI = BASE_URL +'/api/event/certificate/get/';
const REGISTER_EVENT = BASE_URL +'/api/event/register/user/';
const GET_REGISTERED_EVENTS = BASE_URL + '/api/event/register/get';
const POST_CERTIFICATE_REGISTER = BASE_URL + '/api/event/certificate/register/'
const SET_OPENED = BASE_URL + '/api/event/certificate/opened/';
module.exports = {
    GET_EVENTS,
    REGISTER_EVENT,
    GET_REGISTERED_EVENTS,
    POST_CERTIFICATE_REGISTER,
    GET_EVENT ,
    GET_SINGLE_EVENT_CERTI,
    SET_OPENED
} 