import React, { useState, useEffect } from 'react';
import Confetti from 'react-confetti';

const ConfettiC = () => {
  const [showConfetti, setShowConfetti] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowConfetti(false);
    }, 7000);
    return () => clearTimeout(timeout);
  }, []); 

  return (
    <div>
      {showConfetti && (
        <Confetti
          size={12}
          shape="circle"
          colors={['#f44336', '#9c27b0', '#3f51b5']}
          wind={0.1}
          gravity={0.2}
        />
      )}
    </div>
  );
};

export default ConfettiC;
