import axios from "axios";
import React, { useEffect } from "react";
import MuiAlert from "@mui/material/Alert";
import { Typography } from "@mui/material";
// require("dotenv").config();
// const DOMAIN = process.env.REACT_APP_DOMAIN;
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const EmailVerification = ({ match }) => {
  const { VERIFY_EMAIL } = require("../../api/vsc");
  const [name, setName] = React.useState("");
  const [success, setSuccess] = React.useState(true);

  useEffect(() => {
    if (match?.params?.token) {
      axios
        .post(VERIFY_EMAIL, { token: match.params.token })
        .then((response) => {
          setName(response.data.name);
          setSuccess(true);
        })
        .catch((error) => {
          setSuccess(false);
        });
    }
  }, [match]);
  return (
    <>
      <div
        style={{
          width: "50%",
          height: "50%",
          maxWeight: "500px",
          overflow: "auto",
          margin: "auto",
          position: "absolute",
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
        }}
      >
        {success ? (
          <Alert severity="success">
            <Typography>Successfully Verified</Typography>
            Applicant {name} Verified for Membership
            <strong>
              {/* <NavLink to="/login" exact className="nav-link">
                LOGIN
              </NavLink> */}
            </strong>
          </Alert>
        ) : (
          <Alert severity="error">
            <Typography>Opps! Error Occured</Typography>
            This means — <strong>Link Expired</strong>
          </Alert>
        )}
      </div>
    </>
  );
};

export default EmailVerification;
