import React from "react";
import { Container, Typography, Box } from "@material-ui/core";
import {Button, Modal} from '@mui/material'
import { makeStyles } from "@mui/styles";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { GET_DATA, GET_STREAK_VIDEOS, STREAK_REMOVE, WATCHNOW_COMPLETE } from "../../../../api/watchnow";
import '../../../../stylesheets/watch-now.css';
const StreakButtons = () => {
  // react hooks

  const [openbox, setOpenbox] = useState(false);
  const [streaks, setStreaks] = useState([]);
  const [length, setLength] = useState(0);
  const [streak, setStreak] = useState();
  const [streakname, setStreakname] = useState("Oops, we couldn't find the name");
  const [streakid, setStreakid] = useState();
  const [data, setData] = useState([]);
  const [requestStart, setRequestStart] = useState(true);

  const history = useNavigate();

  // data of all videos
  useEffect(() => {
    axios
      .get(GET_DATA, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
      });
  }, [requestStart]);

  // get list of streak videos
  useEffect(() => {
    axios
      .get(GET_STREAK_VIDEOS, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setStreaks(res.data);
        setLength(res.data.length);
        })
      .catch((err) => {
   
      });
  }, []);
 
  // find required streak when clicked once
  const Findstreak = (id) => {
    data.map((val) => {
      if (val._id == id) {
        setStreak(val);
        setStreakname(val.title);
      }
    });
  };

  // remove from streak
  const RemovefromStreak = (val) => {
    axios
      .post(
        STREAK_REMOVE,
        { watchnowId: val },
        {
          headers: { authorization: window.localStorage.getItem("token") },
        }
      )
      .then((res) => {
        alert("video removed from streak");
        window.location.reload();
      })
      .catch((err) => {
        alert("try again later");
      });
  };

  // complete the streak
  const CompleteStreak = (val) => {
    axios
      .post(
        WATCHNOW_COMPLETE,
        { watchnowId: val },
        {
          headers: { authorization: window.localStorage.getItem("token") },
        }
      )
      .then((res) => {
        alert("video added to completed list");
        window.location.reload();
      })
      .catch((err) => {
        alert("try again later");
      });
  };

  // pop ups
  const viewbox = (
    <Box 
    // className={classes.applyboxContainer}
    style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginTop: "200px",
    }}
    >
      <Box 
      // className={classes.applybox}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "200px",
        width: "30%",
        backgroundColor: "white",
        flexDirection: "column",
        borderRadius: "4px",
      }}
      
      >
        <Typography 
        style={{
          color:'#000'
          }}
        >
          {streakname}
        </Typography>
        <Box 
        // className={classes.applyboxbtns}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
        
        >
          <Button
            // className={classes.viewboxbtn}
            style={{
              backgroundColor: "yellow",
              margin: "3px"
            }}
            onClick={() => {
              history("/watch_now/watch", { state: { link: streak.link } });
            }}
          >
            View
          </Button>
          <Button
            // className={classes.viewboxbtn}
            onClick={() => {
              CompleteStreak(streakid);
            }}
            style={{ margin: "3px",backgroundColor: "yellow", }}
          >
            Completed
          </Button>
          <Button
            // className={classes.viewboxbtn}
            onClick={() => {
              RemovefromStreak(streakid);
            }}
            style={{ margin: "3px" , backgroundColor: "yellow",}}
          >
            Remove
          </Button>
        </Box>
      </Box>
    </Box>
  );

  return (
    <Container 
    // className={classes.allex}
    style={{
      display: "flex",
      margin: 10,
      alignItems: "center",
      justifyContent: "center",
    }}
    >
      <Typography 
      // className={classes.text}
      style={{
        color: "#1a202c",
        margin: 10,
        fontSize: 25,
      }}
      >7 Topic Streak</Typography>
      <Box 
      // className={classes.box} 
      style={{
        margin: 0,
        padding: 0,
      }}
      sx={{ 
        bgcolor: "#86FFCF",
          m: 1,
          borderColor: "black",
          width: "2rem",
          height: "2rem",
          borderRadius: 100,
          margin: 0,
          padding: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          border: 'none' }}>
        {length > 0 ? (
          <Button
            startIcon={
              <FiberManualRecordIcon
                style={{
                  color: "purple",
                  backgroundColor: "purple",
                  borderRadius: "60px",
                  marginLeft: "10px",
                }}
              />
            }
            // className={classes.streakbtn}
            // className='streakbtn'
            style={{
              height: "1rem",
              width: "1rem",
              // backgroundColor: "red",
              borderRadius: 100,
            }}
            onClick={() => {
              setStreakid(streaks[0]);
              Findstreak(streaks[0]);
              setOpenbox(true);
            }}
          />
        ) : null}
      </Box>
      <Box sx={{
         bgcolor: "#86FFCF",
        m: 1,
        borderColor: "black",
        width: "1rem",
        height: "0.3rem",
        margin: 0,
        padding: 0,
         border: 'none' }} />
      <Box sx={{ 
                bgcolor: "#86FFCF",
                m: 1,
                borderColor: "black",
                width: "2rem",
                height: "2rem",
                borderRadius: 100,
                margin: 0,
                padding: 0,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border:'none' 
                }}>
        {length > 1 ? (
          <Button
            startIcon={
              <FiberManualRecordIcon
                style={{
                  color: "purple",
                  backgroundColor: "purple",
                  borderRadius: "60px",
                  marginLeft: "10px",
                }}
              />
            }
            // className={classes.streakbtn}
            // className='streakbtn'
            style={{
              height: "1rem",
              width: "1rem",
              // backgroundColor: "red",
              borderRadius: 100,
            }}

            onClick={() => {
              setStreakid(streaks[1]);
              Findstreak(streaks[1]);
              setOpenbox(true);
            }}
          />
        ) : null}
      </Box>
      <Box sx={{ 
         bgcolor: "#86FFCF",
          m: 1,
          borderColor: "black",
          width: "1rem",
          height: "0.3rem",
          margin: 0,
          padding: 0,
         border: 'none' }} />
      <Box sx={{ bgcolor: "#86FFCF",
          m: 1,
          borderColor: "black",
          width: "1rem",
          height: "0.3rem",
          margin: 0,
          padding: 0, 
          border: 'none' }} />
      <Box sx={{
        bgcolor: "#86FFCF",
        m: 1,
        borderColor: "black",
        width: "2rem",
        height: "2rem",
        borderRadius: 100,
        margin: 0,
        padding: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: 'none' }}>
        {length > 2 ? (
          <Button
            startIcon={
              <FiberManualRecordIcon
                style={{
                  color: "purple",
                  backgroundColor: "purple",
                  borderRadius: "60px",
                  marginLeft: "10px",
                }}
              />
            }
            // className={classes.streakbtn}
            style={{
              height: "1rem",
              width: "1rem",
              // backgroundColor: "red",
              borderRadius: 100,
            }}
            onClick={() => {
              setStreakid(streaks[2]);
              Findstreak(streaks[2]);
              setOpenbox(true);
            }}
          />
        ) : null}
      </Box>
      <Box sx={{ bgcolor: "#86FFCF",
              m: 1,
              borderColor: "black",
              width: "1rem",
              height: "0.3rem",
              margin: 0,
              padding: 0, 
              border: 'none' }} />
      <Box sx={{ bgcolor: "#86FFCF",
  m: 1,
  borderColor: "black",
  width: "1rem",
  height: "0.3rem",
  margin: 0,
  padding: 0, border: 'none' }} />
      <Box sx={{ bgcolor: "#86FFCF",
  m: 1,
  borderColor: "black",
  width: "1rem",
  height: "0.3rem",
  margin: 0,
  padding: 0, border: 'none' }} />
      <Box sx={{ 
        bgcolor: "#86FFCF",
        m: 1,
        borderColor: "black",
        width: "2rem",
        height: "2rem",
        borderRadius: 100,
        margin: 0,
        padding: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
         border: 'none' }}>
        {length > 3 ? (
          <Button
            startIcon={
              <FiberManualRecordIcon
                style={{
                  color: "purple",
                  backgroundColor: "purple",
                  borderRadius: "60px",
                  marginLeft: "10px",
                }}
              />
            }
            // className={classes.streakbtn}
            // className='streakbtn'
            style={{
              height: "1rem",
              width: "1rem",
              // backgroundColor: "red",
              borderRadius: 100,
            }}

            onClick={() => {
              setStreakid(streaks[3]);
              Findstreak(streaks[3]);
              setOpenbox(true);
            }}
          />
        ) : null}
      </Box>
      <Box sx={{ bgcolor: "#86FFCF",
  m: 1,
  borderColor: "black",
  width: "1rem",
  height: "0.3rem",
  margin: 0,
  padding: 0, border: 'none' }} />
      <Box sx={{ bgcolor: "#86FFCF",
  m: 1,
  borderColor: "black",
  width: "1rem",
  height: "0.3rem",
  margin: 0,
  padding: 0, border: 'none' }} />
      <Box sx={{ 
        bgcolor: "#86FFCF",
        m: 1,
        borderColor: "black",
        width: "2rem",
        height: "2rem",
        borderRadius: 100,
        margin: 0,
        padding: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: 'none' }}>
        {length > 4 ? (
          <Button
            startIcon={
              <FiberManualRecordIcon
                style={{
                  color: "purple",
                  backgroundColor: "purple",
                  borderRadius: "60px",
                  marginLeft: "10px",
                }}
              />
            }
            // className={classes.streakbtn}
            // className='streakbtn'
            style={{
              height: "1rem",
              width: "1rem",
              // backgroundColor: "red",
              borderRadius: 100,
            }}

            onClick={() => {
              setStreakid(streaks[4]);
              Findstreak(streaks[4]);
              setOpenbox(true);
            }}
          />
        ) : null}
      </Box>
      <Box sx={{ bgcolor: "#86FFCF",
  m: 1,
  borderColor: "black",
  width: "1rem",
  height: "0.3rem",
  margin: 0,
  padding: 0, border: 'none' }} />
      <Box sx={{ bgcolor: "#86FFCF",
  m: 1,
  borderColor: "black",
  width: "1rem",
  height: "0.3rem",
  margin: 0,
  padding: 0, border: 'none' }} />
      <Box sx={{ bgcolor: "#86FFCF",
  m: 1,
  borderColor: "black",
  width: "1rem",
  height: "0.3rem",
  margin: 0,
  padding: 0, border: 'none' }} />
      <Box sx={{ 
        bgcolor: "#86FFCF",
        m: 1,
        borderColor: "black",
        width: "2rem",
        height: "2rem",
        borderRadius: 100,
        margin: 0,
        padding: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center", 
        border: 'none' }}>
        {length > 5 ? (
          <Button
            startIcon={
              <FiberManualRecordIcon
                style={{
                  color: "purple",
                  backgroundColor: "purple",
                  borderRadius: "60px",
                  marginLeft: "10px",
                }}
              />
            }
            // className={classes.streakbtn}
            // className='streakbtn'
            style={{
              height: "1rem",
              width: "1rem",
              // backgroundColor: "red",
              borderRadius: 100,
            }}

            onClick={() => {
              setStreakid(streaks[5]);
              Findstreak(streaks[5]);
              setOpenbox(true);
            }}
          />
        ) : null}
      </Box>
      <Box sx={{ bgcolor: "#86FFCF",
  m: 1,
  borderColor: "black",
  width: "1rem",
  height: "0.3rem",
  margin: 0,
  padding: 0, border: 'none' }} />
      <Box sx={{ 
        bgcolor: "#86FFCF",
        m: 1,
        borderColor: "black",
        width: "2rem",
        height: "2rem",
        borderRadius: 100,
        margin: 0,
        padding: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center", 
        border: 'none' }}>
        {length > 6 ? (
          <Button
            startIcon={
              <FiberManualRecordIcon
                style={{
                  color: "purple",
                  backgroundColor: "purple",
                  borderRadius: "60px",
                  marginLeft: "10px",
                }}
              />
            }
            // className={classes.streakbtn}
            style={{
              height: "1rem",
              width: "1rem",
              // backgroundColor: "red",
              borderRadius: 100,
            }}

            onClick={() => {
              setStreakid(streaks[6]);
              Findstreak(streaks[6]);
              setOpenbox(true);
            }}
          />
        ) : null}
      </Box>
      <Modal
        open={openbox}
        onClose={() => {
          setOpenbox(false);
        }}
      >
        {viewbox}
      </Modal>
    </Container>
  );
};

export default StreakButtons;
