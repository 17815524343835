const { BASE_URL } = require("./client");

const FORGOT_PASSWORD = BASE_URL + '/forgetpassword';
const RESET_PASSWORD = BASE_URL + '/reset';
const UPDATE_PASSWORD = BASE_URL + '/api/updatepassword'

module.exports = {
    FORGOT_PASSWORD,
    RESET_PASSWORD,
    UPDATE_PASSWORD
}