import React, { useState, useEffect } from "react";
import "../../../stylesheets/Markoknow_kickstart.css";
import { Link } from "react-router-dom";
import Footer from "../../Footer/Footer";
// import Modal from "@material-ui/core/Modal";
import { NavLink } from "react-router-dom";
import { TailSpin } from "react-loader-spinner";
import image from "../Images/kickstart.png";
import course_1 from "../Images/course_1.webp";
import course_2 from "../Images/course_2.webp";
import course_3 from "../Images/course_3.webp";
import RecordVoiceOverOutlinedIcon from "@mui/icons-material/RecordVoiceOverOutlined";
import AutoAwesomeOutlinedIcon from "@mui/icons-material/AutoAwesomeOutlined";
import EventNoteOutlinedIcon from "@mui/icons-material/EventNoteOutlined";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import DateRangeIcon from "@mui/icons-material/DateRange";
import ScheduleIcon from "@mui/icons-material/Schedule";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";

const Markoknow_kickstart = () => {
  const [course1, setCourse1] = useState("");
  const [loading, setLoading] = useState(false);
  const [course2, setCourse2] = useState("");
  const [course3, setCourse3] = useState("");

  return (
    <div className="kickstart">
      <div className="poster">
        <div
          style={{
            maxWidth: "1280px",
            margin: "0 128.8px",
            marginInlineStart: "auto",
            marginInlineEnd: "auto",
            padding: "0 16px",
          }}
        >
          <div className="poster_containt">
            <div className="poster_text">
              <div className="text_box">
                <h2 id="first_text">Markoknow Kickstart</h2>
                <h2 id="second_text">
                  0 Upfront Fee. No Prior Technical Knowledge Needed.
                </h2>
                <h2 id="third_text">Enter The Workforce, Job-Ready.</h2>
                <p id="fourth_text">
                  Launch your career in tech, no matter your educational
                  background. Pay after placement of 5,00,000/- or more, else
                  learn at no cost.
                </p>
                <div className="undergrad_box">
                  <p id="fifth_text">UNDERGRAD STUDENTS OR GRAD</p>
                </div>
                <div>
                  <button id="sixth_text">APPLY NOW</button>
                </div>
              </div>
            </div>

            <div className="poster_img">
              <img src={image} />
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          maxWidth: "1280px",
          margin: "0 128.8px",
          marginInlineStart: "auto",
          marginInlineEnd: "auto",
          padding: "0 16px",
        }}
      >
        <div className="why_kickstart">
          <h2>Why Markoknow Kickstart ?</h2>
          <div className="kickstart_box">
            <div className="box1">
              <div>
                <span
                  style={{
                    position: "absolute",
                    color: "#3470E4",
                    marginTop: "10px",
                  }}
                >
                  <RecordVoiceOverOutlinedIcon />
                </span>
                <p
                  style={{
                    color: "#0A0103",
                    margin: "0px 0px 0px 48px",
                    fontSize: "16px",
                    fontFamily: "Poppins",
                    fontWeight: "700",
                  }}
                >
                  Holistic Development
                </p>
                <p
                  style={{
                    color: "#544D4F",
                    fontFamily: "Open sans",
                    fontSize: "14px",
                    width: "100%",
                    margin: "6px 0px 0px 48px",
                    height: "100%",
                    wordWrap: "break-word",
                  }}
                >
                  Better your soft-skills and English fluency to become
                  interview ready.
                </p>
              </div>
              <div className="box1_right">
                <span
                  style={{
                    position: "absolute",
                    color: "#3470E4",
                    marginTop: "10px",
                  }}
                >
                  <AutoAwesomeOutlinedIcon />
                </span>
                <p
                  style={{
                    color: "#0A0103",
                    margin: "0px 0px 0px 48px",
                    fontSize: "16px",
                    fontFamily: "Poppins",
                    fontWeight: "700",
                  }}
                >
                  Holistic Development
                </p>
                <p
                  style={{
                    color: "#544D4F",
                    fontFamily: "Open sans",
                    fontSize: "14px",
                    width: "100%",
                    margin: "6px 0px 0px 48px",
                    height: "100%",
                    wordWrap: "break-word",
                  }}
                >
                  Better your soft-skills and English fluency to become
                  interview ready.
                </p>
              </div>
            </div>
            <div className="box2">
              <div>
                <span
                  style={{
                    position: "absolute",
                    color: "#3470E4",
                    marginTop: "10px",
                  }}
                >
                  <EventNoteOutlinedIcon />
                </span>
                <p
                  style={{
                    color: "#0A0103",
                    margin: "0px 0px 0px 48px",
                    fontSize: "16px",
                    fontFamily: "Poppins",
                    fontWeight: "700",
                  }}
                >
                  Holistic Development
                </p>
                <p
                  style={{
                    color: "#544D4F",
                    fontFamily: "Open sans",
                    fontSize: "14px",
                    width: "100%",
                    margin: "6px 0px 0px 48px",
                    height: "100%",
                    wordWrap: "break-word",
                  }}
                >
                  Better your soft-skills and English fluency to become
                  interview ready.
                </p>
              </div>
              <div className="box2_right">
                <span
                  style={{
                    position: "absolute",
                    color: "#3470E4",
                    marginTop: "10px",
                  }}
                >
                  <WorkOutlineOutlinedIcon />
                </span>
                <p
                  style={{
                    color: "#0A0103",
                    margin: "0px 0px 0px 48px",
                    fontSize: "16px",
                    fontFamily: "Poppins",
                    fontWeight: "700",
                  }}
                >
                  Holistic Development
                </p>
                <p
                  style={{
                    color: "#544D4F",
                    fontFamily: "Open sans",
                    fontSize: "14px",
                    width: "100%",
                    margin: "6px 0px 0px 48px",
                    height: "100%",
                    wordWrap: "break-word",
                  }}
                >
                  Better your soft-skills and English fluency to become
                  interview ready.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="requirement">
          <h2>Requirements & Eligibility</h2>
          <p
            style={{
              fontFamily: "Open sans",
              fontWeight: "500",
              fontSize: "16px",
              color: "#544d4f",
              marginTop: "8px",
            }}
          >
            You should meet the following requirements to be eligible for this
            course.
          </p>
          <div className="requirement_box">
            <div className="box1">
              <div>
                <span
                  style={{
                    position: "absolute",
                    color: "#3470E4",
                    marginTop: "10px",
                  }}
                >
                  <RecordVoiceOverOutlinedIcon />
                </span>
                <p
                  style={{
                    color: "#0A0103",
                    margin: "0px 0px 0px 48px",
                    fontSize: "16px",
                    fontFamily: "Poppins",
                    fontWeight: "700",
                  }}
                >
                  Holistic Development
                </p>
                <p
                  style={{
                    color: "#544D4F",
                    fontFamily: "Open sans",
                    fontSize: "14px",
                    width: "100%",
                    margin: "6px 0px 0px 48px",
                    height: "100%",
                    wordWrap: "break-word",
                  }}
                >
                  Better your soft-skills and English fluency to become
                  interview ready.
                </p>
              </div>
              <div className="box1_right">
                <span
                  style={{
                    position: "absolute",
                    color: "#3470E4",
                    marginTop: "10px",
                  }}
                >
                  <AutoAwesomeOutlinedIcon />
                </span>
                <p
                  style={{
                    color: "#0A0103",
                    margin: "0px 0px 0px 48px",
                    fontSize: "16px",
                    fontFamily: "Poppins",
                    fontWeight: "700",
                  }}
                >
                  Holistic Development
                </p>
                <p
                  style={{
                    color: "#544D4F",
                    fontFamily: "Open sans",
                    fontSize: "14px",
                    width: "100%",
                    margin: "6px 0px 0px 48px",
                    height: "100%",
                    wordWrap: "break-word",
                  }}
                >
                  Better your soft-skills and English fluency to become
                  interview ready.
                </p>
              </div>
            </div>
            <div className="box2">
              <div>
                <span
                  style={{
                    position: "absolute",
                    color: "#3470E4",
                    marginTop: "10px",
                  }}
                >
                  <EventNoteOutlinedIcon />
                </span>
                <p
                  style={{
                    color: "#0A0103",
                    margin: "0px 0px 0px 48px",
                    fontSize: "16px",
                    fontFamily: "Poppins",
                    fontWeight: "700",
                  }}
                >
                  Holistic Development
                </p>
                <p
                  style={{
                    color: "#544D4F",
                    fontFamily: "Open sans",
                    fontSize: "14px",
                    width: "100%",
                    margin: "6px 0px 0px 48px",
                    height: "100%",
                    wordWrap: "break-word",
                  }}
                >
                  Better your soft-skills and English fluency to become
                  interview ready.
                </p>
              </div>
              <div className="box2_right">
                <span
                  style={{
                    position: "absolute",
                    color: "#3470E4",
                    marginTop: "10px",
                  }}
                >
                  <WorkOutlineOutlinedIcon />
                </span>
                <p
                  style={{
                    color: "#0A0103",
                    margin: "0px 0px 0px 48px",
                    fontSize: "16px",
                    fontFamily: "Poppins",
                    fontWeight: "700",
                  }}
                >
                  Holistic Development
                </p>
                <p
                  style={{
                    color: "#544D4F",
                    fontFamily: "Open sans",
                    fontSize: "14px",
                    width: "100%",
                    margin: "6px 0px 0px 48px",
                    height: "100%",
                    wordWrap: "break-word",
                  }}
                >
                  Better your soft-skills and English fluency to become
                  interview ready.
                </p>
              </div>
            </div>

            <div className="box3">
              <div>
                <span
                  style={{
                    position: "absolute",
                    color: "#3470E4",
                    marginTop: "10px",
                  }}
                >
                  <EventNoteOutlinedIcon />
                </span>
                <p
                  style={{
                    color: "#0A0103",
                    margin: "0px 0px 0px 48px",
                    fontSize: "16px",
                    fontFamily: "Poppins",
                    fontWeight: "700",
                  }}
                >
                  Holistic Development
                </p>
                <p
                  style={{
                    color: "#544D4F",
                    fontFamily: "Open sans",
                    fontSize: "14px",
                    width: "100%",
                    margin: "6px 0px 0px 48px",
                    height: "100%",
                    wordWrap: "break-word",
                  }}
                >
                  Better your soft-skills and English fluency to become
                  interview ready.
                </p>
              </div>
              <div className="box3_right">
                <span
                  style={{
                    position: "absolute",
                    color: "#3470E4",
                    marginTop: "10px",
                  }}
                >
                  <WorkOutlineOutlinedIcon />
                </span>
                <p
                  style={{
                    color: "#0A0103",
                    margin: "0px 0px 0px 48px",
                    fontSize: "16px",
                    fontFamily: "Poppins",
                    fontWeight: "700",
                  }}
                >
                  Holistic Development
                </p>
                <p
                  style={{
                    color: "#544D4F",
                    fontFamily: "Open sans",
                    fontSize: "14px",
                    width: "100%",
                    margin: "6px 0px 0px 48px",
                    height: "100%",
                    wordWrap: "break-word",
                  }}
                >
                  Better your soft-skills and English fluency to become
                  interview ready.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="kickstart_courses">
          <h2>Our Courses</h2>
          <div className="level_courses">
            <div className="course_1">
              <img
                src={course_1}
                style={{
                  objectFit: "fill",
                  width: "100%",
                  height: "auto",
                  display: "block",
                  borderStyle: "none",
                  marginBottom: "20px",
                  borderRadius: "20px 20px 0px 0px",
                }}
              />
              <div className="course_data">
                <p
                  style={{
                    padding: "2px 10px",
                    backgroundColor: "#F2F6FF",
                    borderRadius: "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "233px",
                    height: "20px",
                    color: "#6E71CC",
                    textTransform: "uppercase",
                    fontFamily: "Open Sans",
                    fontSize: "12px",
                    lineHeight: "16px",
                    fontWeight: "600",
                  }}
                >
                  Undergrad Students or Grad
                </p>
                <p
                  style={{
                    color: "#3B3435",
                    fontSize: "1.125rem",
                    fontWeight: "600",
                    marginTop: "10px",
                    fontFamily: "Poppins",
                  }}
                >
                  MVP Building (Step 1 )
                </p>
                <p
                  style={{
                    color: "#21191B",
                    fontSize: "24px",
                    fontWeight: "700",
                    fontFamily: "Poppins",
                  }}
                >
                  MVP Building
                </p>
                <div
                  style={{
                    margin: "10px 0px 0px",
                    padding: "0px 10px",
                  }}
                >
                  <p
                    style={{
                      color: "#21191B",
                      marginBottom: "10px",
                    }}
                  >
                    <DateRangeIcon
                      style={{
                        color: "gray",
                        fontSize: "18px",
                        position: "relative",
                        top: "5px",
                        marginRight: "5px",
                        marginLeft: "-13px",
                      }}
                    />
                    Minimum Viable Product Building
                  </p>
                  <p
                    style={{
                      color: "#21191B",
                      marginBottom: "10px",
                    }}
                  >
                    <span>
                      <ScheduleIcon
                        style={{
                          color: "gray",
                          fontSize: "18px",
                          position: "relative",
                          top: "5px",
                          marginRight: "5px",
                          marginLeft: "-13px",
                        }}
                      />
                    </span>
                    2 Real Time Cases
                  </p>
                  <p
                    style={{
                      color: "#21191B",
                      marginBottom: "10px",
                    }}
                  >
                    <span>
                      <CurrencyRupeeIcon
                        style={{
                          color: "gray",
                          fontSize: "18px",
                          position: "relative",
                          top: "5px",
                          // marginRight: "5px",
                          marginLeft: "-13px",
                        }}
                      />
                    </span>{" "}
                    Outcome: Problem Selection, Product decision, Product
                    features, Idea Execution
                  </p>
                </div>
              </div>
              <div className="course_1_button">
                <Link to={`/program/markoknow_kickstart/course1_details`}>
                  <div className="details">View Details</div>
                </Link>

                {userData.course1 == 1 || course1 == 1 ? (
                  <button
                    style={{ backgroundColor: "green" }}
                    onClick={() => setCourse1("0")}
                  >
                    Course 1 Added
                  </button>
                ) : (
                  <button onClick={() => setCourse1("1")}>Add Course 1</button>
                )}
              </div>
            </div>
            <div className="course_2">
              <img
                src={course_2}
                style={{
                  objectFit: "fill",
                  width: "100%",
                  height: "auto",
                  display: "block",
                  borderStyle: "none",
                  marginBottom: "20px",
                  borderRadius: "20px 20px 0px 0px",
                }}
              />
              <div className="course_data">
                <p
                  style={{
                    padding: "2px 10px",
                    backgroundColor: "#F2F6FF",
                    borderRadius: "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "233px",
                    height: "20px",
                    color: "#6E71CC",
                    textTransform: "uppercase",
                    fontFamily: "Open Sans",
                    fontSize: "12px",
                    lineHeight: "16px",
                    fontWeight: "600",
                  }}
                >
                  Undergrad Students or Grad
                </p>
                <p
                  style={{
                    color: "#3B3435",
                    fontSize: "1.125rem",
                    fontWeight: "600",
                    marginTop: "10px",
                    fontFamily: "Poppins",
                  }}
                >
                  GTM Strategy (Step 2)
                </p>
                <p
                  style={{
                    color: "#21191B",
                    fontSize: "24px",
                    fontWeight: "700",
                    fontFamily: "Poppins",
                  }}
                >
                  GTM Strategy
                </p>
                <div
                  style={{
                    margin: "10px 0px 0px",
                    padding: "0px 10px",
                  }}
                >
                  <p
                    style={{
                      color: "#21191B",
                      marginBottom: "10px",
                    }}
                  >
                    <DateRangeIcon
                      style={{
                        color: "gray",
                        fontSize: "18px",
                        position: "relative",
                        top: "5px",
                        marginRight: "5px",
                        marginLeft: "-13px",
                      }}
                    />
                    Go To Market Strategy
                  </p>
                  <p
                    style={{
                      color: "#21191B",
                      marginBottom: "10px",
                    }}
                  >
                    <span>
                      <ScheduleIcon
                        style={{
                          color: "gray",
                          fontSize: "18px",
                          position: "relative",
                          top: "5px",
                          marginRight: "5px",
                          marginLeft: "-13px",
                        }}
                      />
                    </span>
                    2 Real Time Cases
                  </p>
                  <p
                    style={{
                      color: "#21191B",
                      marginBottom: "10px",
                    }}
                  >
                    <span>
                      <CurrencyRupeeIcon
                        style={{
                          color: "gray",
                          fontSize: "18px",
                          position: "relative",
                          top: "5px",
                          // marginRight: "5px",
                          marginLeft: "-13px",
                        }}
                      />
                    </span>{" "}
                    Outcome: Deciding Target Audience,Planning Buyer's Journey,
                    Creating Process of Execution
                  </p>
                </div>
              </div>
              <div className="course_2_button">
                <div className="details">View Details</div>
                {userData.course2 == 2 || course2 == 2 ? (
                  <button
                    style={{ backgroundColor: "green" }}
                    onClick={() => setCourse2("0")}
                  >
                    Course 2 Added
                  </button>
                ) : (
                  <button onClick={() => setCourse2("2")}>Add Course 2</button>
                )}
              </div>
            </div>
            <div className="course_3">
              <img
                src={course_3}
                style={{
                  objectFit: "fill",
                  width: "100%",
                  height: "auto",
                  display: "block",
                  borderStyle: "none",
                  marginBottom: "20px",
                  borderRadius: "20px 20px 0px 0px",
                }}
              />
              <div className="course_data">
                <p
                  style={{
                    padding: "2px 10px",
                    backgroundColor: "#F2F6FF",
                    borderRadius: "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "233px",
                    height: "20px",
                    color: "#6E71CC",
                    textTransform: "uppercase",
                    fontFamily: "Open Sans",
                    fontSize: "12px",
                    lineHeight: "16px",
                    fontWeight: "600",
                  }}
                >
                  Undergrad Students or Grad
                </p>
                <p
                  style={{
                    color: "#3B3435",
                    fontSize: "1.125rem",
                    fontWeight: "600",
                    marginTop: "10px",
                    fontFamily: "Poppins",
                  }}
                >
                  BMC Creation (Step 3)
                </p>
                <p
                  style={{
                    color: "#21191B",
                    fontSize: "24px",
                    fontWeight: "700",
                    fontFamily: "Poppins",
                  }}
                >
                  BMC Creation
                </p>
                <div
                  style={{
                    margin: "10px 0px 0px",
                    padding: "0px 10px",
                  }}
                >
                  <p
                    style={{
                      color: "#21191B",
                      marginBottom: "10px",
                    }}
                  >
                    <DateRangeIcon
                      style={{
                        color: "gray",
                        fontSize: "18px",
                        position: "relative",
                        top: "5px",
                        marginRight: "5px",
                        marginLeft: "-13px",
                      }}
                    />
                    Business Model Canvas Creation
                  </p>
                  <p
                    style={{
                      color: "#21191B",
                      marginBottom: "10px",
                    }}
                  >
                    <span>
                      <ScheduleIcon
                        style={{
                          color: "gray",
                          fontSize: "18px",
                          position: "relative",
                          top: "5px",
                          marginRight: "5px",
                          marginLeft: "-13px",
                        }}
                      />
                    </span>
                    7 Business Models
                  </p>
                  <p
                    style={{
                      color: "#21191B",
                      marginBottom: "10px",
                    }}
                  >
                    <span>
                      <CurrencyRupeeIcon
                        style={{
                          color: "gray",
                          fontSize: "18px",
                          position: "relative",
                          top: "5px",
                          marginRight: "5px",
                          marginLeft: "-13px",
                        }}
                      />
                    </span>{" "}
                    Outcome : Decision factor for business model, shifting
                    business models
                  </p>
                </div>
              </div>
              <div className="course_3_button">
                <div className="details">View Details</div>
                {userData.course3 == 3 || course3 == 3 ? (
                  <button
                    style={{ backgroundColor: "green" }}
                    onClick={() => setCourse3("0")}
                  >
                    Course 3 Added
                  </button>
                ) : (
                  <button onClick={() => setCourse3("3")}>Add Course 3</button>
                )}
              </div>
            </div>
          </div>
        </div> */}
        <div
          style={{
            // border: "1px solid red",
            marginBottom: "20px",
            display: "flex",
            alignItem: "center",
            justifyContent: "center",
            width: "auto",
          }}
        >
          {/* <button
            onClick={submit}
            style={{
              backgroundColor: "#ed0331",
              color: "white",
              padding: "10px 20px",
              borderRadius: "10px",
              border: "none",
              outline: "none",
              cursor: "pointer",
              fontFamily: "Poppins",
              fontSize: "16px",
              fontWeight: "600",
              lineHeight: "24px",
              letterSpacing: "0.02em",
              textTransform: "uppercase",
              margin: "20px 0px",
            }}
          >
            {loading ? <TailSpin height={25} color="white" /> : "Submit"}
          </button> */}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Markoknow_kickstart;
