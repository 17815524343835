import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import Badge from '@mui/material/Badge';
import Tooltip from '@mui/material/Tooltip';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { DayCalendarSkeleton } from '@mui/x-date-pickers/DayCalendarSkeleton';
import { useMediaQuery } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import axios from 'axios';
import Checkbox from '@mui/material/Checkbox';
import { GET_GOALS, SET_STREAK } from '../../../api/dailystreak';
import { USER_REFFERAL } from '../../../api/vsc';
import {Link} from 'react-router-dom';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

const initialValue = dayjs();

export default function DateCalendarServerRequest({refresh}) {
  const requestAbortController = React.useRef(null);
  const isSMobile = useMediaQuery('(max-width:725px)');
  const [isLoading, setIsLoading] = React.useState(false);
  const [highlightedDays, setHighlightedDays] = React.useState([]);
  const [fetchedData, setFetchedData] = useState([]);
  const [todayGoalId, setTodayGoalId] = useState(null);
  const [isChecked, setIsChecked] = useState(false);
  const [icon , setIcon] = useState(false);
  const formattedDate = initialValue.format("D MMM 'YY");
  const fetchHighlightedDays = async () => {
    try {
      const response = await axios.get(GET_GOALS);
      const data = response.data.data;
      const dates = data.map((item) => dayjs(item.date, 'DD/MM/YYYY'));
      const daysToHighlight = dates.map((date) => date.date());
      setFetchedData(data); 
      setIsLoading(false);
      
    } catch (error) {
      console.error('Error fetching highlighted days:', error);
      setIsLoading(false);
    }
  };
  const fetchHighlightedDatesFromAPI = async () => {
    try {
      const response = await axios.get(USER_REFFERAL+window.localStorage.id);  
      const date = response.data.data.dailyStreak.find((date) => date.date === initialValue.format('DD/MM/YYYY'));
      if(date){
        setIsChecked(true);
        setIcon(true);
      }
      const highlightedDates = response.data.data.dailyStreak.map((date) => dayjs(date.date, 'DD/MM/YYYY').date());
      setHighlightedDays(highlightedDates);
    } catch (error) {
      console.error('Error fetching highlighted dates from API:', error);
    }
  };

  useEffect(() => {
    fetchHighlightedDatesFromAPI()
    fetchHighlightedDays();
    // return () => requestAbortController.current?.abort();
  },[isChecked]);
  const todayGoalData = fetchedData.find((item) => item.date === initialValue.format('DD/MM/YYYY'));

  const handleMonthChange = (date) => {
    if (requestAbortController.current) {
      requestAbortController.current.abort();
    }

    setIsLoading(true);
    setHighlightedDays([]);
  };
  function ServerDay(props) {
    const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;
    const [hovered, setHovered] = useState(false);

    const isSelected =
      !outsideCurrentMonth && highlightedDays.indexOf(day.date()) >= 0;

    return (
      <Tooltip title={`Goal: ${getGoalForDate(day)}`} arrow>
        <Badge
          key={day.toString()}
          overlap="circular"
          badgeContent={
            isSelected ? (
              <CheckIcon sx={{ color: '#00ff00' }} />
            ) : undefined
          }
        >
          <PickersDay
            style={{
              background: isSelected && '#355E3B',
              color: isSelected && '#fff',
              cursor: 'pointer',
            }}
            {...other}
            outsideCurrentMonth={outsideCurrentMonth}
            day={day}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
          />
        </Badge>
      </Tooltip>
    );
  }

  const handleDayClick = (selectedDate) => {
    const goalForSelectedDate = getGoalForDate(selectedDate);
   
  };

  const getGoalForDate = (selectedDate) => {
    const selectedDateFormatted = selectedDate.format('DD/MM/YYYY');
    const goalData = fetchedData.find((item) => item.date === selectedDateFormatted);
    return  `🎯 Daily Goals ${formattedDate} ${goalData ? goalData.goal : 'No goal found'}`;
  };
  const getGoalLink = (selectedDate)=> {
    const selectedDateFormatted = selectedDate.format('DD/MM/YYYY');
    const goalData = fetchedData.find((item) => item.date === selectedDateFormatted);
    return goalData ? goalData.link : '/';
  }
  const todayGoal = getGoalForDate(initialValue);
  const todayLink = getGoalLink(initialValue);
  const handleCheck = (e) => {
    axios
      .post(SET_STREAK + window.localStorage.id + "/" + todayGoalData._id , {
        date: initialValue.format('DD/MM/YYYY')
      })
      .then(res => {
        setIcon(true);
        setIsChecked(true);
        refresh(true);
      })
      .catch(err => {
      });
  }
  return (
    <div style={{
        borderRadius: '10px',
        boxShadow: 'rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px',
        marginBottom: '10px',
        // width:isSMobile?'100%':'61%',
        width:'100%',
        height: '400px'
    }}>
    <div style={{ display: 'flex', alignItems: 'center' , padding:'10px 10px 0 10px' }}>
            {icon?<CheckCircleIcon style={{color: 'green'}} /> :<Checkbox checked={isChecked} onClick={handleCheck} style={{color: 'green'}}/>}
            <Link 
              to={todayLink}
              style={{
                color: '#000',

              }}
              ><Tooltip title={`Today's Goal: ${todayGoal}`} arrow>
              <span>{todayGoal}</span>
            </Tooltip>
            </Link>
          </div>
    <div
      style={{
        width: '100%',
        height: '300px',
      }}
    >

      <LocalizationProvider dateAdapter={AdapterDayjs} style={{ width: '100%' }}>
        <DateCalendar
          defaultValue={initialValue}
          loading={isLoading}
          onMonthChange={handleMonthChange}
          renderLoading={() => <DayCalendarSkeleton />}
          onDayClick={handleDayClick}
          slots={{
            day: ServerDay
          }}
          slotProps={{
            day: {
              highlightedDays,
            },
          }}
        />
      </LocalizationProvider>
    </div>
    </div>

  );
}
