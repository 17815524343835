import * as React from 'react';
import CourseViewer from './CoursesViewer';
import {Box , Typography} from '@mui/material';
import axios from 'axios';
import { GET_COURSES } from '../../api/vsc';
import { Divider , useMediaQuery } from '@mui/material';

const CourseCategory = () => {
  const isMobile = useMediaQuery('(max-width: 600px)');

  const sidebarItems = [
    'Trending Skills',
    'AI Ready',
    'High Packages',
    'Non Coder Friendly',
    'Upcoming Hot Skills',
  ];
  const dividerStyle = {
    bgcolor: '#FFBD59',
    height: '2px',
    width:'100%',
  };
  const [activeIndex , setActiveIndex] = React.useState(0);
  const [timer, setTimer] = React.useState(null); 
  const [show , setShow] = React.useState(false);
  React.useEffect(() => {
    fetchCourses();
  }, []);

  const fetchCourses = async () => {
    try {
        axios.get(GET_COURSES)
        .then(res => {
            if (res.data.data.some((course) => course.tags)) {
            setShow(true);
            }
        })
        .catch(err => {
            console.error('Error fetching courses:', err);
        }); 
    } catch (error) {
      console.error('Error fetching courses:', error);
    }
  };
  React.useEffect(() => {
    const interval = setInterval(() => {
            setActiveIndex(prevIndex => (prevIndex + 1) % sidebarItems.length);
    }, 6000);
    setTimer(interval);
    return () => {
      clearInterval(interval);
    };
  }, []); 
  return (
        <Box sx={{ display: 'flex', flexDirection: 'column', fontFamily: 'Poppins' , marginTop:'60px' , marginBottom: '40px' }}>
      <div style={{ display: 'flex', alignItems: 'center' , flexDirection:isMobile&&'column' , justifyContent:'center' }}>
        <Typography variant="h4" fontWeight="800" style={{ color: '#000', marginRight: !isMobile&&'10px',  textAlign:'center' }}>
          Lab + Project + 
        </Typography>
        <Typography variant="h4" fontWeight="800" style={{ color: '#6a58c6' ,   textAlign:'center' }}>
          Work Ex Programs
          <Divider sx={dividerStyle} />
        </Typography>
      </div>
      <Box sx={{ display: 'flex', justifyContent: {sm: 'space-around', xs:'center'},alignItems: 'center' , flexDirection:{xs: 'column' , sm: 'column' , md: 'row'} }}>
        <Box sx={{ 
        width: {md:'25%' , sm: '95%' , xs: '100%'}, 
        flexGrow: 1, 
        padding: '10px' , 
        display:'flex' , 
        flexDirection: {xs: 'row' , sm:'row' , md: 'column'} , 
        justifyContent:'center' ,
        alignItems: 'center', 
        gap: '10px',
        overflowX: 'auto'
        }}>
          {sidebarItems.map((item, index) => (
            <Box 
            key={index} 
            style={{
              display: 'flex',
              alignItems: 'center',
              margin: '10px 0',
              padding: '10px',
              border: '1px solid #ccc',
              borderRadius: '10px',
              backgroundColor: 'transparent',
              borderColor: activeIndex === index ? '#6a58c6' : '#ccc',
              height: 75, 
              fontFamily: "Poppins",
              cursor:'pointer',
              width: '90%',
              
            }}
            onClick={(e)=> setActiveIndex(index)}
            >
              <span style={{ fontWeight: 'bold', marginRight: '10px' , height: 40 ,width: 40 , background:'#6a58c6',color: '#fff' , borderRadius:'5px' ,display:'flex' , justifyContent:'center' , alignItems:'center', fontFamily: "Poppins"}}>{index + 1}</span>
              <span style={{ fontWeight: 'bold', marginRight: '10px' ,fontSize:'22px',display:'flex' , justifyContent:'center' , alignItems:'center', fontFamily: "Poppins"}}>{item}</span>
            </Box>
          ))}
        </Box>
        <Box sx={{ width: {xs:'90%' , sm:'90%' , md: '75%'} , flexGrow: 2, padding: '10px' }}>
            <CourseViewer heading={sidebarItems[activeIndex]}/>
        </Box>
      </Box>
    </Box>
  );
};

export default CourseCategory;