import React from 'react';
import '../../stylesheets/ImageCarousel.css'; 
import pic1 from './Images/1.png'; 
import pic2 from './Images/2.png'; 
import pic3 from './Images/3.png'; 
import pic4 from './Images/4.png'; 
import pic5 from './Images/5.png'; 
import pic6 from './Images/6.png'; 
import pic7 from './Images/7.png'; 
import pic8 from './Images/8.png'; 
import pic9 from './Images/9.png'; 
import pic10 from './Images/10.png'; 
import pic11 from './Images/11.png'; 
import pic12 from './Images/12.png'; 
import pic13 from './Images/13.png'; 
import pic14 from './Images/14.png'; 
import pic15 from './Images/15.png'; 
import pic16 from './Images/16.png'; 
import pic17 from './Images/17.png'; 
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import {IconButton , useMediaQuery} from '@mui/material';

const ImageCarousel = () => {
  const is = Array.from({ length: 17 }, (_, index) => require(`./Images/${index + 1}.png`));
  const images = [
    '10pnR6mgLG8KdMoBxQmo3NaOQajs_NPUr',
    '14gMPusNdu09JtfYyCikzuFu51OGBZbtF',
    '15fsioNwJMHf7bwJfyNsBNfxEECsv3-BW',
    '1BY7mGK-_EiQ9wvGL73itH7LJWmLxwBTe',
    '1CdxrEV-2fQmYfsJplXe0S2XTtQo00O3i',
    '1EnMLqMEmTZcRcxKpMCBR66uZp_1tPVKX',
    '1NwFtSTsRb3s3VPWRwe1OaZaGr6nzH5F5',
    '1OuxTM4sLZQVmY1MvIpqJNyInj2gDS8Ei',
    '1QXrtFtLF5Rt3ZLIFhwLLZgVuOHMwtU6M',
    '1TvKpGM-FLcfZH_edF7Er-DviGwuRRHZD',
    '1U__i-ihMpRloK3SrbdVD014DMslmP6TX',
    '1VqnQUDTTuXhuQdcTDbcX-YFOJ8SuA_wD',
    '1cYSHFy8N2GSqbDeovALu-BirLTGV0BdS',
    '1d3ZGJrlHyXHiXpuKFO3A-muiPz23crS3',
    '1nvxbiUd9Xp67f1sLpIO8ZujYHVfDJZwv',
    '1rksTLyaI_US5pX5icF4H-neZ0TGhEKN0',
    '1rpCqFThwhw1IotyM2C3KwLwQdvhqg-4H'
  ];
  
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const isGreaterMobile = useMediaQuery('(max-width: 500px)');

  const nextImage = () => {
    const marquee = document.querySelector('.pictureMarqueee');
    marquee.style.animation = 'none'; // Pause the animation
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    setTimeout(() => {
      marquee.style.animation = 'marqueeAnimation 120s linear infinite reverse'; // Resume the animation after a short delay
    }, 0);
  };
  return (
    <div className="PictureSectionn">
      <div className="pictureContainer">
        <div className="pictureMarqueee" style={{ transform: `translateX(-${currentIndex * 300}px)` }}>
          {images.map((image, index) => (
            <div key={index} className="divContainer">
              {/* <img src={'https://drive.google.com/uc?export=view&id='+image} alt={`Image ${index + 1}`} className="carousel-image" /> */}
              <img src={is[index]} alt={`Image ${index + 1}`} className="carousel-image" />

            </div>
          ))}
        </div>
      </div>
      <button
            type="button"
            onClick={nextImage}
            className="nextButton"
          >
            <ArrowForwardIosRoundedIcon style={{color:'#fff'}} />
          </button>
    </div>
  );
};

export default ImageCarousel;

