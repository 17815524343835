/* eslint-disable no-useless-escape */
/* eslint-disable no-useless-computed-key */
import { Box, TextField , Button} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { Link , useParams } from "react-router-dom";
import usePasswordValidator from "../auth/passwordutils";
import Modal from "@material-ui/core/Modal";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { makeStyles } from "@mui/styles";
import "../../stylesheets/loginform.css";
import axios from "axios";
import ReactGA from "react-ga";
import { RESET_PASSWORD, UPDATE_PASSWORD } from "../../api/forgotPassword";
import { Typography } from "@mui/material";
import { useMediaQuery } from "@mui/material";


function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 250,
    background:'#fff',
    padding:'5%',
    border: "2px solid #000",
    margin:'auto',
    
  },
}));

function ResetPassword({ match }) {
  const params = useParams();
  const [email, setEmail] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [password, setPassword, passwordError] = usePasswordValidator({
    min: 8,
    max: 15,
  });
  const [error, setError] = useState("");
  const [response, setResponse] = useState("Error !! Please try again");

  const classes = useStyles();

  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);
  const isMobile = useMediaQuery('(max-width:600px)')

  useEffect(() => {
    if (!confirmPassword || !password) {
      setConfirmPasswordError("");
    } else {
      if (password !== confirmPassword) {
        setConfirmPasswordError("Passwords must match.");
      } else {
        setConfirmPasswordError("");
      }
    }
  }, [password, confirmPassword]);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  useEffect(() => {
    axios
      .get(RESET_PASSWORD, {
        params: {
          resetPasstoken: params.token,
        },
      })
      .then((response) => {
        if (response.data.message === "password reset link a-ok") {
          setEmail(response.data.email);
        } else {
          setError(response.data);
        }
      })
      .catch((err) => {
      });
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const body = (
    <div style={modalStyle} className={classes.paper}>
      {response === "password updated" ? (
        <h2 style={{ color: "green" , textAlign:'center' }} id="simple-modal-title">
          Success
        </h2>
      ) : (
        <h2 style={{ color: "red", textAlign:'center' }} id="simple-modal-title">
          Failure
        </h2>
      )}
      <p id="simple-modal-description" style={{textAlign:'center'}}>{response}</p>
      {response === "password updated" && (
        <p id="simple-modal-description" style={{textAlign:'center'}}>
          Now you can login with your new password.
        </p>
      )}
      <Link
          to="/"
          style={{ textDecoration: "none", color: "black" }}
          className="btn-primary"
        >
      <Button variant="Contained" style={{background:'#6A58C6' , color:'#fff' ,marginTop:'20px' ,marginLeft:'35%'}}>
       
          Home
      </Button>
      </Link>
    </div>
  );

  const clearset = () => {
    setEmail("");
    setPassword("");
    setConfirmPassword("");
  };

  var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;

  const validationForm = () => {
    if (password.length < 8) {
      return false;
    } else if (format.test(password) === false) {
      return false;
    } else if (!password.match(/\d/)) {
      return false;
    } else if (confirmPassword !== password) {
      return false;
    } else {
      return true;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validationForm()) {
      const registered = {
        email: email,
        password: password,
      };
      axios
        .post(UPDATE_PASSWORD, registered)
        .then((response) => {
          setResponse(response.data.message);
          clearset();
          handleOpen();
        })
        .catch((err) => {
          setError("Post Request Error");
        });
    } else {
      setError("Validation Error!! Please fill the form correctly");
    }
  };

  return (
      
        <form 
        className="login_form" 
        style={{marginTop:isMobile?'150px':'200px', width:!isMobile?'35%':'70%' }}
        onSubmit={handleSubmit}>
        {error.length > 0 && (
        <div style={{ width: "100%", margin: "auto" }} className="Alert_type">
          <Alert severity="error">
            <AlertTitle>ERROR OCCURED</AlertTitle>
            <strong>{error}</strong>
          </Alert>
        </div>
        )}
          <Typography variant="h6" style={{textAlign:'center' , fontFamily:'Poppins' , fontWeight:'bold'}}>Reset Password</Typography>

          <span className="error">{passwordError}</span>
          <br/>
          <TextField
            style={{ paddingBottom: "15px", width: "100%" }}
            value={password}
            variant="outlined"
            onChange={(e) => setPassword(e.target.value)}
            type="password"
            required
            label="New Password"
          />

          <span className="error">{confirmPasswordError}</span>
          <TextField
            style={{ paddingBottom: "15px", width: "100%" }}
            value={confirmPassword}
            variant="outlined"
            onChange={(e) => setConfirmPassword(e.target.value)}
            type="password"
            label="Confirm New Password"
          />

          <div className="auth_button">
            <button
              type="submit"
              variant="contained"
              className="button_login"
            >
              Submit
            </button>
          </div>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            {body}
          </Modal>
        </form>
  );
}

export default ResetPassword;
