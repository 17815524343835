const { BASE_URL } = require("./client");

const GET_ANNOUNCEMENT = BASE_URL+'/announcement/read/VSCLANDING';
const GET_DASH_ANNOUNCEMENT = BASE_URL+'/announcement/read/VSCDASHBOARD';
const GET_MAIN_DASH_ANNOUNCEMENT = BASE_URL+'/announcement/read/VSCMAINDASHBOARD';
const GET_EVENT_ANNOUNCEMENT = BASE_URL+'/announcement/read/VSCEVENTS';

module.exports = {
    GET_ANNOUNCEMENT,
    GET_DASH_ANNOUNCEMENT,
    GET_MAIN_DASH_ANNOUNCEMENT,
    GET_EVENT_ANNOUNCEMENT
}