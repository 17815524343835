const BASE_URL = process.env.REACT_APP_DOMAIN;
const googleClientId = process.env.REACT_APP_OAUTH_CLIENT_ID;
const PAYMENT_CHECKOUT = process.env.REACT_APP_PAYMENT_CHECKOUT;
const RAZORPAY_CHECKOUT = process.env.REACT_APP_RAZORPAY_CHECKOUT;
const RAZORPAY_KEY = process.env.REACT_APP_RAZORPAY_KEY;
const LOGO_URL = process.env.REACT_APP_LOGO_URI;



export { BASE_URL, googleClientId , PAYMENT_CHECKOUT , RAZORPAY_CHECKOUT , RAZORPAY_KEY , LOGO_URL};
