import React from "react";
import "../../../stylesheets/Markoknow_workX.css";
import Footer from "../../Footer/Footer";
import image from "../Images/item3.png";
import course_1 from "../Images/course_1.webp";
import course_2 from "../Images/course_2.webp";
import course_3 from "../Images/course_3.webp";
import RecordVoiceOverOutlinedIcon from "@mui/icons-material/RecordVoiceOverOutlined";
import AutoAwesomeOutlinedIcon from "@mui/icons-material/AutoAwesomeOutlined";
import EventNoteOutlinedIcon from "@mui/icons-material/EventNoteOutlined";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import DateRangeIcon from "@mui/icons-material/DateRange";
import ScheduleIcon from "@mui/icons-material/Schedule";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";

const Markoknow_workX = () => {
  return (
    <div className="kickstart">
      <div className="poster">
        <div
          style={{
            maxWidth: "1280px",
            margin: "0 128.8px",
            marginInlineStart: "auto",
            marginInlineEnd: "auto",
            padding: "0 16px",
          }}
        >
          <div className="poster_containt">
            <div className="poster_text">
              <div className="text_box">
                <h2 id="first_text_x">Markoknow Work X</h2>
                <h2 id="second_text_x">Zero Upfront Fee</h2>
                {/* <h2 id="third_text_x">Enter The Workforce, Job-Ready.</h2> */}
                <p id="fourth_text">
                  Starting your journey in tech, or looking to take the next
                  step in your developer journey, if you are a working
                  professional with an ambition, we will invest in your future.
                </p>
                <div className="undergrad_box">
                  <p id="fifth_text_x">FOR WORKING PROFESSIONALS</p>
                </div>
                <div>
                  <button id="sixth_text_x">APPLY NOW</button>
                </div>
              </div>
            </div>

            <div className="poster_img">
              <img
                style={{
                  backgroundRepeat: "no-repeat",
                  maxWidth: "100%",
                  height: "auto",
                  display: "block",
                }}
                src={image}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          maxWidth: "1280px",
          margin: "0 128.8px",
          marginInlineStart: "auto",
          marginInlineEnd: "auto",
          padding: "0 16px",
        }}
      >
        <div className="why_kickstart">
          <h2>
            Get the<span> X.</span> factor
          </h2>
          <div className="kickstart_box">
            <div className="box1">
              <div>
                <span
                  style={{
                    position: "absolute",
                    color: "#3470E4",
                    marginTop: "10px",
                  }}
                >
                  <RecordVoiceOverOutlinedIcon />
                </span>
                <p
                  style={{
                    color: "#0A0103",
                    margin: "0px 0px 0px 48px",
                    fontSize: "16px",
                    fontFamily: "Poppins",
                    fontWeight: "700",
                  }}
                >
                  Holistic Development
                </p>
                <p
                  style={{
                    color: "#544D4F",
                    fontFamily: "Open sans",
                    fontSize: "14px",
                    width: "100%",
                    margin: "6px 0px 0px 48px",
                    height: "100%",
                    wordWrap: "break-word",
                  }}
                >
                  Better your soft-skills and English fluency to become
                  interview ready.
                </p>
              </div>
              <div className="box1_right">
                <span
                  style={{
                    position: "absolute",
                    color: "#3470E4",
                    marginTop: "10px",
                  }}
                >
                  <AutoAwesomeOutlinedIcon />
                </span>
                <p
                  style={{
                    color: "#0A0103",
                    margin: "0px 0px 0px 48px",
                    fontSize: "16px",
                    fontFamily: "Poppins",
                    fontWeight: "700",
                  }}
                >
                  Holistic Development
                </p>
                <p
                  style={{
                    color: "#544D4F",
                    fontFamily: "Open sans",
                    fontSize: "14px",
                    width: "100%",
                    margin: "6px 0px 0px 48px",
                    height: "100%",
                    wordWrap: "break-word",
                  }}
                >
                  Better your soft-skills and English fluency to become
                  interview ready.
                </p>
              </div>
            </div>
            <div className="box2">
              <div>
                <span
                  style={{
                    position: "absolute",
                    color: "#3470E4",
                    marginTop: "10px",
                  }}
                >
                  <EventNoteOutlinedIcon />
                </span>
                <p
                  style={{
                    color: "#0A0103",
                    margin: "0px 0px 0px 48px",
                    fontSize: "16px",
                    fontFamily: "Poppins",
                    fontWeight: "700",
                  }}
                >
                  Holistic Development
                </p>
                <p
                  style={{
                    color: "#544D4F",
                    fontFamily: "Open sans",
                    fontSize: "14px",
                    width: "100%",
                    margin: "6px 0px 0px 48px",
                    height: "100%",
                    wordWrap: "break-word",
                  }}
                >
                  Better your soft-skills and English fluency to become
                  interview ready.
                </p>
              </div>
              <div className="box2_right">
                <span
                  style={{
                    position: "absolute",
                    color: "#3470E4",
                    marginTop: "10px",
                  }}
                >
                  <WorkOutlineOutlinedIcon />
                </span>
                <p
                  style={{
                    color: "#0A0103",
                    margin: "0px 0px 0px 48px",
                    fontSize: "16px",
                    fontFamily: "Poppins",
                    fontWeight: "700",
                  }}
                >
                  Holistic Development
                </p>
                <p
                  style={{
                    color: "#544D4F",
                    fontFamily: "Open sans",
                    fontSize: "14px",
                    width: "100%",
                    margin: "6px 0px 0px 48px",
                    height: "100%",
                    wordWrap: "break-word",
                  }}
                >
                  Better your soft-skills and English fluency to become
                  interview ready.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="requirement">
          <h2>Requirements & Eligibility</h2>
          <p
            style={{
              fontFamily: "Open sans",
              fontWeight: "500",
              fontSize: "16px",
              color: "#544d4f",
              marginTop: "8px",
            }}
          >
            You should meet the following requirements to be eligible for this
            course.
          </p>
          <div className="requirement_box">
            <div className="box1">
              <div>
                <span
                  style={{
                    position: "absolute",
                    color: "#3470E4",
                    marginTop: "10px",
                  }}
                >
                  <RecordVoiceOverOutlinedIcon />
                </span>
                <p
                  style={{
                    color: "#0A0103",
                    margin: "0px 0px 0px 48px",
                    fontSize: "16px",
                    fontFamily: "Poppins",
                    fontWeight: "700",
                  }}
                >
                  Holistic Development
                </p>
                <p
                  style={{
                    color: "#544D4F",
                    fontFamily: "Open sans",
                    fontSize: "14px",
                    width: "100%",
                    margin: "6px 0px 0px 48px",
                    height: "100%",
                    wordWrap: "break-word",
                  }}
                >
                  Better your soft-skills and English fluency to become
                  interview ready.
                </p>
              </div>
              <div className="box1_right">
                <span
                  style={{
                    position: "absolute",
                    color: "#3470E4",
                    marginTop: "10px",
                  }}
                >
                  <AutoAwesomeOutlinedIcon />
                </span>
                <p
                  style={{
                    color: "#0A0103",
                    margin: "0px 0px 0px 48px",
                    fontSize: "16px",
                    fontFamily: "Poppins",
                    fontWeight: "700",
                  }}
                >
                  Holistic Development
                </p>
                <p
                  style={{
                    color: "#544D4F",
                    fontFamily: "Open sans",
                    fontSize: "14px",
                    width: "100%",
                    margin: "6px 0px 0px 48px",
                    height: "100%",
                    wordWrap: "break-word",
                  }}
                >
                  Better your soft-skills and English fluency to become
                  interview ready.
                </p>
              </div>
            </div>
            <div className="box2">
              <div>
                <span
                  style={{
                    position: "absolute",
                    color: "#3470E4",
                    marginTop: "10px",
                  }}
                >
                  <EventNoteOutlinedIcon />
                </span>
                <p
                  style={{
                    color: "#0A0103",
                    margin: "0px 0px 0px 48px",
                    fontSize: "16px",
                    fontFamily: "Poppins",
                    fontWeight: "700",
                  }}
                >
                  Holistic Development
                </p>
                <p
                  style={{
                    color: "#544D4F",
                    fontFamily: "Open sans",
                    fontSize: "14px",
                    width: "100%",
                    margin: "6px 0px 0px 48px",
                    height: "100%",
                    wordWrap: "break-word",
                  }}
                >
                  Better your soft-skills and English fluency to become
                  interview ready.
                </p>
              </div>
              <div className="box2_right">
                <span
                  style={{
                    position: "absolute",
                    color: "#3470E4",
                    marginTop: "10px",
                  }}
                >
                  <WorkOutlineOutlinedIcon />
                </span>
                <p
                  style={{
                    color: "#0A0103",
                    margin: "0px 0px 0px 48px",
                    fontSize: "16px",
                    fontFamily: "Poppins",
                    fontWeight: "700",
                  }}
                >
                  Holistic Development
                </p>
                <p
                  style={{
                    color: "#544D4F",
                    fontFamily: "Open sans",
                    fontSize: "14px",
                    width: "100%",
                    margin: "6px 0px 0px 48px",
                    height: "100%",
                    wordWrap: "break-word",
                  }}
                >
                  Better your soft-skills and English fluency to become
                  interview ready.
                </p>
              </div>
            </div>

            <div className="box3">
              <div>
                <span
                  style={{
                    position: "absolute",
                    color: "#3470E4",
                    marginTop: "10px",
                  }}
                >
                  <EventNoteOutlinedIcon />
                </span>
                <p
                  style={{
                    color: "#0A0103",
                    margin: "0px 0px 0px 48px",
                    fontSize: "16px",
                    fontFamily: "Poppins",
                    fontWeight: "700",
                  }}
                >
                  Holistic Development
                </p>
                <p
                  style={{
                    color: "#544D4F",
                    fontFamily: "Open sans",
                    fontSize: "14px",
                    width: "100%",
                    margin: "6px 0px 0px 48px",
                    height: "100%",
                    wordWrap: "break-word",
                  }}
                >
                  Better your soft-skills and English fluency to become
                  interview ready.
                </p>
              </div>
              <div className="box3_right">
                <span
                  style={{
                    position: "absolute",
                    color: "#3470E4",
                    marginTop: "10px",
                  }}
                >
                  <WorkOutlineOutlinedIcon />
                </span>
                <p
                  style={{
                    color: "#0A0103",
                    margin: "0px 0px 0px 48px",
                    fontSize: "16px",
                    fontFamily: "Poppins",
                    fontWeight: "700",
                  }}
                >
                  Holistic Development
                </p>
                <p
                  style={{
                    color: "#544D4F",
                    fontFamily: "Open sans",
                    fontSize: "14px",
                    width: "100%",
                    margin: "6px 0px 0px 48px",
                    height: "100%",
                    wordWrap: "break-word",
                  }}
                >
                  Better your soft-skills and English fluency to become
                  interview ready.
                </p>
              </div>
            </div>
          </div>
        </div> */}

        {/* <div className="kickstart_courses">
          <h2>Our Courses</h2>
          <div className="level_courses">
            <div className="course_1">
              <img
                src={course_1}
                style={{
                  objectFit: "fill",
                  width: "100%",
                  height: "auto",
                  display: "block",
                  borderStyle: "none",
                  marginBottom: "20px",
                  borderRadius: "20px 20px 0px 0px",
                }}
              />
              <div className="course_data">
                <p
                  style={{
                    padding: "2px 10px",
                    backgroundColor: "#F2F6FF",
                    borderRadius: "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "233px",
                    height: "20px",
                    color: "#6E71CC",
                    textTransform: "uppercase",
                    fontFamily: "Open Sans",
                    fontSize: "12px",
                    lineHeight: "16px",
                    fontWeight: "600",
                  }}
                >
                  Undergrad Students or Grad
                </p>
                <p
                  style={{
                    color: "#3B3435",
                    fontSize: "1.125rem",
                    fontWeight: "600",
                    marginTop: "10px",
                    fontFamily: "Poppins",
                  }}
                >
                  Level 1 - Full Stack
                </p>
                <p
                  style={{
                    color: "#21191B",
                    fontSize: "24px",
                    fontWeight: "700",
                    fontFamily: "Poppins",
                  }}
                >
                  Web Development
                </p>
                <div
                  style={{
                    margin: "10px 0px 0px",
                    padding: "0px 10px",
                  }}
                >
                  <p
                    style={{
                      color: "#21191B",
                      marginBottom: "10px",
                    }}
                  >
                    <DateRangeIcon
                      style={{
                        color: "gray",
                        fontSize: "18px",
                        position: "relative",
                        top: "5px",
                        marginRight: "5px",
                        marginLeft: "-13px",
                      }}
                    />
                    Course starts on 03 Apr 2023
                  </p>
                  <p
                    style={{
                      color: "#21191B",
                      marginBottom: "10px",
                    }}
                  >
                    <span>
                      <ScheduleIcon
                        style={{
                          color: "gray",
                          fontSize: "18px",
                          position: "relative",
                          top: "5px",
                          marginRight: "5px",
                          marginLeft: "-13px",
                        }}
                      />
                    </span>
                    Launch your career in 35 weeks
                  </p>
                  <p
                    style={{
                      color: "#21191B",
                      marginBottom: "10px",
                    }}
                  >
                    <span>
                      <CurrencyRupeeIcon
                        style={{
                          color: "gray",
                          fontSize: "18px",
                          position: "relative",
                          top: "5px",
                          marginRight: "5px",
                          marginLeft: "-13px",
                        }}
                      />
                    </span>{" "}
                    Pay after placement of 5 LPA or above
                  </p>
                </div>
              </div>
              <div className="course_1_button">
                <button>Apply Now</button>
              </div>
            </div>
            <div className="course_2">
              <img
                src={course_2}
                style={{
                  objectFit: "fill",
                  width: "100%",
                  height: "auto",
                  display: "block",
                  borderStyle: "none",
                  marginBottom: "20px",
                  borderRadius: "20px 20px 0px 0px",
                }}
              />
              <div className="course_data">
                <p
                  style={{
                    padding: "2px 10px",
                    backgroundColor: "#F2F6FF",
                    borderRadius: "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "233px",
                    height: "20px",
                    color: "#6E71CC",
                    textTransform: "uppercase",
                    fontFamily: "Open Sans",
                    fontSize: "12px",
                    lineHeight: "16px",
                    fontWeight: "600",
                  }}
                >
                  Undergrad Students or Grad
                </p>
                <p
                  style={{
                    color: "#3B3435",
                    fontSize: "1.125rem",
                    fontWeight: "600",
                    marginTop: "10px",
                    fontFamily: "Poppins",
                  }}
                >
                  Level 1 - Full Stack
                </p>
                <p
                  style={{
                    color: "#21191B",
                    fontSize: "24px",
                    fontWeight: "700",
                    fontFamily: "Poppins",
                  }}
                >
                  Web Development
                </p>
                <div
                  style={{
                    margin: "10px 0px 0px",
                    padding: "0px 10px",
                  }}
                >
                  <p
                    style={{
                      color: "#21191B",
                      marginBottom: "10px",
                    }}
                  >
                    <DateRangeIcon
                      style={{
                        color: "gray",
                        fontSize: "18px",
                        position: "relative",
                        top: "5px",
                        marginRight: "5px",
                        marginLeft: "-13px",
                      }}
                    />
                    Course starts on 03 Apr 2023
                  </p>
                  <p
                    style={{
                      color: "#21191B",
                      marginBottom: "10px",
                    }}
                  >
                    <span>
                      <ScheduleIcon
                        style={{
                          color: "gray",
                          fontSize: "18px",
                          position: "relative",
                          top: "5px",
                          marginRight: "5px",
                          marginLeft: "-13px",
                        }}
                      />
                    </span>
                    Launch your career in 35 weeks
                  </p>
                  <p
                    style={{
                      color: "#21191B",
                      marginBottom: "10px",
                    }}
                  >
                    <span>
                      <CurrencyRupeeIcon
                        style={{
                          color: "gray",
                          fontSize: "18px",
                          position: "relative",
                          top: "5px",
                          marginRight: "5px",
                          marginLeft: "-13px",
                        }}
                      />
                    </span>{" "}
                    Pay after placement of 5 LPA or above
                  </p>
                </div>
              </div>
              <div className="course_2_button">
                <button>Apply Now</button>
              </div>
            </div>
            <div className="course_3">
              <img
                src={course_3}
                style={{
                  objectFit: "fill",
                  width: "100%",
                  height: "auto",
                  display: "block",
                  borderStyle: "none",
                  marginBottom: "20px",
                  borderRadius: "20px 20px 0px 0px",
                }}
              />
              <div className="course_data">
                <p
                  style={{
                    padding: "2px 10px",
                    backgroundColor: "#F2F6FF",
                    borderRadius: "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "233px",
                    height: "20px",
                    color: "#6E71CC",
                    textTransform: "uppercase",
                    fontFamily: "Open Sans",
                    fontSize: "12px",
                    lineHeight: "16px",
                    fontWeight: "600",
                  }}
                >
                  Undergrad Students or Grad
                </p>
                <p
                  style={{
                    color: "#3B3435",
                    fontSize: "1.125rem",
                    fontWeight: "600",
                    marginTop: "10px",
                    fontFamily: "Poppins",
                  }}
                >
                  Level 1 - Full Stack
                </p>
                <p
                  style={{
                    color: "#21191B",
                    fontSize: "24px",
                    fontWeight: "700",
                    fontFamily: "Poppins",
                  }}
                >
                  Web Development
                </p>
                <div
                  style={{
                    margin: "10px 0px 0px",
                    padding: "0px 10px",
                  }}
                >
                  <p
                    style={{
                      color: "#21191B",
                      marginBottom: "10px",
                    }}
                  >
                    <DateRangeIcon
                      style={{
                        color: "gray",
                        fontSize: "18px",
                        position: "relative",
                        top: "5px",
                        marginRight: "5px",
                        marginLeft: "-13px",
                      }}
                    />
                    Course starts on 03 Apr 2023
                  </p>
                  <p
                    style={{
                      color: "#21191B",
                      marginBottom: "10px",
                    }}
                  >
                    <span>
                      <ScheduleIcon
                        style={{
                          color: "gray",
                          fontSize: "18px",
                          position: "relative",
                          top: "5px",
                          marginRight: "5px",
                          marginLeft: "-13px",
                        }}
                      />
                    </span>
                    Launch your career in 35 weeks
                  </p>
                  <p
                    style={{
                      color: "#21191B",
                      marginBottom: "10px",
                    }}
                  >
                    <span>
                      <CurrencyRupeeIcon
                        style={{
                          color: "gray",
                          fontSize: "18px",
                          position: "relative",
                          top: "5px",
                          marginRight: "5px",
                          marginLeft: "-13px",
                        }}
                      />
                    </span>{" "}
                    Pay after placement of 5 LPA or above
                  </p>
                </div>
              </div>
              <div className="course_3_button">
                <button>Apply Now</button>
              </div>
            </div>
          </div>
        </div> */}
      </div>
      <Footer />
    </div>
  );
};

export default Markoknow_workX;
