import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
export default function ScrollDialog() {
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState('paper');

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <React.Fragment>
      <Button variant="contained" onClick={handleClickOpen('paper')} sx={{
        background: '#6a58c6',
        color: '#fff',
      }}>View Rules</Button>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        sx={{
            color: '#000'
        }}
      >
        <DialogTitle id="scroll-dialog-title">How To Earn and Redeem Points on VSC</DialogTitle>
        <DialogContent dividers={scroll === 'paper'}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
            sx={{
            color: '#000'
        }}
          >
            <Typography variant="h6">Earning Points</Typography>
            <List>
              <ListItem>
              <ListItemIcon>
                  <FiberManualRecordIcon sx={{
                    fontSize:'10px'
                  }}/>
                </ListItemIcon>
                <ListItemText primary="Watching Videos: For every hour of video content you watch, earn X points." />
              </ListItem>
              <ListItem>
              <ListItemIcon>
                  <FiberManualRecordIcon sx={{
                    fontSize:'10px'
                  }}/>
                </ListItemIcon>
                <ListItemText primary="Reading Blogs: Each blog post read will add Y points to your account." />
              </ListItem>
              <ListItem>
              <ListItemIcon>
                  <FiberManualRecordIcon sx={{
                    fontSize:'10px'
                  }}/>
                </ListItemIcon>
                <ListItemText primary="Completing Case Studies: Finish a case study to earn Z points." />
              </ListItem>
              <ListItem>
              <ListItemIcon>
                  <FiberManualRecordIcon sx={{
                    fontSize:'10px'
                  }}/>
                </ListItemIcon>
                <ListItemText primary="Enrolling in Courses: Courses come with the highest point rewards. Complete a course to receive A points." />
              </ListItem>
            </List>

            <Typography variant="h6">Tracking Your Points</Typography>
            <Typography paragraph>
              Keeping track of your points is easy. Simply log in to your account and navigate to the "My Points" section. Here, you'll see a detailed breakdown of the points you've earned, including the activity that earned them and the date of acquisition.
            </Typography>

            <Typography variant="h6">Redeeming Points</Typography>
            <Typography paragraph>
              Once you've accumulated enough points, you can redeem them for discount coupons towards future course purchases. Here's how to redeem your points:
            </Typography>
            <List>
              <ListItem>
              <ListItemIcon>
                  <FiberManualRecordIcon  sx={{
                    fontSize:'10px'
                  }}/>
                </ListItemIcon>
                <ListItemText primary="Visit the Redemption Page: Go to the 'Redeem Points' section in your account dashboard." />
              </ListItem>
              <ListItem>
              <ListItemIcon >
                  <FiberManualRecordIcon  sx={{
                    fontSize:'10px'
                  }}/>
                </ListItemIcon>
                <ListItemText primary="Select Your Coupon: Choose the discount coupon you'd like to redeem based on the points required." />
              </ListItem>
              <ListItem>
              <ListItemIcon>
                  <FiberManualRecordIcon  sx={{
                    fontSize:'10px'
                  }}/>
                </ListItemIcon>
                <ListItemText primary="Apply at Checkout: When purchasing a new course, enter your coupon code at checkout to apply your discount." />
              </ListItem>
            </List>

            <Typography variant="h6">Additional Resources for Clarity</Typography>
            <Typography paragraph>
              To ensure you have all the information you need, we've created several resources:
            </Typography>
            <List>
              <ListItem>
              <ListItemIcon>
                  <FiberManualRecordIcon sx={{
                    fontSize:'10px'
                  }}/>
                </ListItemIcon>
                <ListItemText primary="Tutorial Video: Watch our step-by-step guide on how to earn and redeem points." />
              </ListItem>
              <ListItem>
              <ListItemIcon>
                  <FiberManualRecordIcon sx={{
                    fontSize:'10px'
                  }}/>
                </ListItemIcon>
                <ListItemText primary="FAQ Section: Have questions? Our FAQ section has answers to common queries about the points system." />
              </ListItem>
              <ListItem>
              <ListItemIcon>
                  <FiberManualRecordIcon sx={{
                    fontSize:'10px'
                  }}/>
                </ListItemIcon>
                <ListItemText primary="Blog Post: Learn more about the benefits of our points system and how it can enhance your learning experience." />
              </ListItem>
              <ListItem>
              <ListItemIcon>
                  <FiberManualRecordIcon sx={{
                    fontSize:'10px'
                  }}/>
                </ListItemIcon>
                <ListItemText primary="Case Studies: See how other learners have maximized their points and the discounts they've earned." />
              </ListItem>
            </List>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
