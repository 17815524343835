import React, { useState } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Chip,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

const ProgramCurriculum = ({ data  }) => {
  const [expanded, setExpanded] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  if (!data || !data.modules) {
    return <Typography align="center">No curriculum data available.</Typography>;
  }

  return (
    <div style={{ margin: isMobile ? '10px 0':'20px', width: isMobile ? '100%' : '90%'  }}>
      <Typography variant="h4" align="center" sx={{ mb: 5, fontWeight: 600 }}>
        Program Curriculum
      </Typography>
      {data.modules.map((module, index) => (
        <Accordion
          key={index}
          expanded={expanded === index}
          onChange={handleChange(index)}
          style={{ borderRadius: '8px', border: '1px solid #E0E0E0' }}
        >
          <AccordionSummary
            aria-controls={`panel${index}-content`}
            id={`panel${index}-header`}
            style={{
              backgroundColor: '#ffff',
              padding: '2px 10px',
              borderBottom: '1px solid #E0E0E0',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Box display="flex" alignItems="center" gap="10px">
              {expanded === index ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              <Typography style={{ fontWeight: 600 }}>{module.title}</Typography>
            </Box>
            {!isMobile && (
              <Box
                style={{
                  marginLeft: 'auto',
                  display: 'flex',
                  gap: '10px',
                  alignItems: 'center',
                }}
              >
                <Chip
                  icon={<VideoLibraryIcon style={{ color: '#6a58c6' }} />}
                  label={`${module.videos.length} Videos`}
                  style={{
                    backgroundColor: '#f0f0f0',
                    color: '#6a58c6',
                    fontWeight: 600,
                  }}
                />
                <Chip
                  icon={<PictureAsPdfIcon style={{ color: '#5F4FB2' }} />}
                  label={`${module.pdfs.length} PDFs`}
                  style={{
                    backgroundColor: '#f0f0f0',
                    color: '#5F4FB2',
                    fontWeight: 600,
                  }}
                />
              </Box>
            )}
          </AccordionSummary>
          <AccordionDetails>
            <List>
              {module.videos.map((video, vidIndex) => (
                <ListItem key={vidIndex} sx={{ paddingLeft: 0 }}>
                  <ListItemIcon>
                    <VideoLibraryIcon sx={{ color: '#6a58c6' }} />
                  </ListItemIcon>
                  <ListItemText primary={video.title} />
                  {!isMobile && (
                    <Chip label="Video" sx={{ color: '#6a58c6', backgroundColor: 'transparent' }} />
                  )}
                </ListItem>
              ))}
              {module.pdfs.map((pdf, pdfIndex) => (
                <ListItem key={pdfIndex} sx={{ paddingLeft: 0 }}>
                  <ListItemIcon>
                    <PictureAsPdfIcon sx={{ color: '#5F4FB2' }} />
                  </ListItemIcon>
                  <ListItemText primary={pdf.title} />
                  {!isMobile && (
                    <Chip label="PDF" sx={{ color: '#5F4FB2', backgroundColor: 'transparent' }} />
                  )}
                </ListItem>
              ))}
            </List>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default ProgramCurriculum;
