import * as React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import { GET_BLOGS , IMAGE , GET_BLOG} from "../../api/blog";
import { useMediaQuery } from '@mui/material';
import axios from 'axios';
import png from '../CaseStudy.jsx/Photos/1.png'
import { Link } from 'react-router-dom';
import Media from './Media';
import Loader from '../Career/Loader';
import { GET_BADGES } from '../../api/badge';
import CloseIcon from '@mui/icons-material/Close';
import Badge from './index';
import Modal from '@mui/material/Modal';
import {Tooltip , IconButton , Button} from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { USER_REFFERAL } from '../../api/vsc';

export default function NewBlog() {
  const [blogs, setBlogs] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [op, setOp] = React.useState(false);
  const isGreaterMobile = useMediaQuery('(max-width: 500px)');
  const [completedBadges , setCompletedBadges] = React.useState([]);


  const handleClose = () => setOp(false);
  React.useEffect(() => {
    axios
        .get(USER_REFFERAL+window.localStorage.id).then(resp => {
          setCompletedBadges(resp.data.data.completedBadges);
        })
    },[])
  React.useEffect(() => {
    setLoading(true);
    axios
      .get(GET_BADGES)
      .then((res) => {
        setBlogs(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
      });
  }, []);
  return (
    <Box sx={{ marginTop:'100px' }}>
    {!loading ? (
      <>
      <Box display="flex" justifyContent="center">
            <Typography variant="h4" fontWeight="800" style={{color:'#000'}}>
              Badges
            </Typography>
          </Box>
          <Box
            sx={{
              margin: {
                sm: '20px 20px 20px 50px',
                xs: '10px 10px 10px 25px',
              },
            }} alignItems={isGreaterMobile&&'center'}>
        <Button href={"/dashboard/"+window.localStorage.id} variant="contained" style={{backgroundColor: "#6a58c6"}}>
            Earn More Badges
        </Button>
       </Box>
       <Box  margin="50px">

       <Media props={`Earned Badges`}/>

       </Box>  
      
       <Box
      sx={{
        margin: {
          sm: '20px 20px 20px 50px',
          xs: '10px 10px 10px 25px',
        },
      }}
      >
      {blogs.length !== 0 &&<Typography variant="h5" style={{color:'#000' , textAlign:'left', fontFamily:'Poppins' , marginBottom:'10px' , fontWeight:'900'}}>All Badges</Typography>}
       <Box style={{
          display:'flex', 
          flexDirection: isGreaterMobile&&'column' , 
          flexWrap: 'wrap',
          gap: '12px',
          justifyContent: 'center',
          alignItems: 'center'
          
           }}>
       {blogs.map(item => (
        <Box
                sx={{
                  width: 300,
                  height: '100%',
                  background: '#fff',
                  padding: '10px',
                  // clipPath: 'polygon(0 0, 100% 0, 100% 66%, 50% 100%, 0 66%)',
                    '&:hover': {
                      transform: 'scale(1.1)',
                      transition: 'transform 0.3s ease-in-out',
                      boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                      borderRadius: '20px'
                    },
                    boxShadow: 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'
                  ,borderRadius: '10px'

                }}
              >
                {item ? (
                  <img 
                  style={{ width: '100%', height: 180 }} 
                  alt={item.title} 
                src={item.badgeUrl}

                  />
                ) : (
                  <Skeleton variant="rectangular" width={300} height={180} />
                )}

                {item ? (
                  <Box sx={{ pr: 2 }} >
                    <Typography gutterBottom variant="subtitle1" textAlign="center">
                      {item.title}
                    </Typography>
                    <Typography gutterBottom  variant="body2" textAlign="center">
                      {item.message}
                    </Typography>
                    <div style={{display:'flex' , justifyContent:'center'}}><Tooltip  title={completedBadges.includes(item.tag.split(" ").join("")) ?"Earned":"Locked"}>
                        {completedBadges.includes(item.tag.split(" ").join("")) ? <CheckCircleIcon style={{color:'green'}}/> :<LockIcon/> }
                    </Tooltip></div>
                    
                  </Box>
                ) : (
                  <Box sx={{ pt: 0.5 }}>
                    <Skeleton />
                    <Skeleton width="60%" />
                  </Box>
                )}
                <Modal
            open={op}
            onClose={handleClose}
            >
                <Box style={{display:'flex', alignItems:'center' , justifyContent:'center', position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)', border:'none'}}>
                {/* {sBadge === 1 && <MdMilitaryTech className='square' style={{fontSize:'200px'}} />}
                {sBadge === 2 && <LuMedal className='square' style={{fontSize:'200px'}} />}
                {sBadge === 3 && <RiMedalFill className='square' style={{fontSize:'200px'}} />} */}
                <Badge sBadge={item}/>

            </Box>
            
            </Modal>
              </Box>
              
    )) }
    </Box>
                    
       </Box> 
       </>
    ): (
    <>
      {<Loader from={'NewBlog'}/>}
    </>
    )}
        
    </Box>
  );
}