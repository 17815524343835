import React from 'react';
import { useMediaQuery } from "@mui/material";
import axios from 'axios';
import { APPLY_COURSE } from "../../api/course";
import { validateEmail } from "../auth/validateEmail";
import {Typography , Button} from '@material-ui/core';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import Loader from '../Career/Loader';
import Footer from '../Footer/Footer';
import {useNavigate} from 'react-router-dom';
import {useParams} from 'react-router-dom';

const CourseForm = () => {
      const params = useParams();
      const navigate = useNavigate();
      const [clicked, setClicked] = React.useState(false);
      const [loading , setLoading] = React.useState(false);
      const [fName , setFName] = React.useState('');
      const [lName , setLName] = React.useState('');
      const [email , setEmail] = React.useState('');
      const [wNumber , setWnumber] = React.useState('');
      const [year, setYear] = React.useState('');
      const [description , setDescription] = React.useState('');
      const [emailError , setEmailError] = React.useState('');
      const [numberError , setNumberError] = React.useState('');
      const [course, setCourse] = React.useState('')
    
      const isMobile = useMediaQuery('(max-width:838px)');
    
    const validate = () => {
       if(fName.length<=0 && lName.length<=0) return false;
       else if(wNumber.length!==10) return false;
       else if(!validateEmail(email)) return false;
       else return true;
    }
    
    
      const handleApply = () => {
       
        if(validate()){
          const data = {
            fName: fName,
            lName: lName,
            email: email,
            wNumber: wNumber,
            year: year,
            description: description,
            course: course
          }
          axios
          .post(APPLY_COURSE+params.courseid+"/"+window.localStorage.id , data )
          .then(response => {
            setClicked(true);
            if(response.status === 201)navigate('/myDashboard/'+window.localStorage.id);
            
          })
          .catch(err => {
          })
        }
       
      }
  return (
    <>
    <div
    style={{
        marginTop:'120px',
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'center',
        width:'100%',
    }}
    >
    <Box
    style={{
          width:'50%',
          display:'flex',
          flexDirection:'column',
          justifyContent:'center',
          alignItems:'center',
          
          }}
    >
        <Typography variant={!isMobile?"h2":'h4'} 
        style={{
          color:'#000',
          fontWeight: 'bold',
          fontFamily:'Poppins',
          marginBottom:'10px',
          textAlign:'center'
        }}> Register For Markoknow Kickstart </Typography>
        {/* <Typography variant="subtitle1" 
        style={{
          color:'#000',
          fontFamily:'Poppins',
          marginBottom:'10px',
          textAlign:'center',
          fontSize:isMobile?'15px':'24px'

        }}>Reach out to us and raise any issue you are facing during navigation, checkout or anything challenging. </Typography> */}
    </Box>
     <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { m:!isMobile? 1:0.5 },
        width:isMobile?'80%':'50%',
        margin:'2%',
        borderRadius:'10px',
        background:'#6A58C6',
        padding:'2% 6% 2% 6% ',
        display:clicked&&'flex',
        justifyContent:clicked&&'center',
        alignItems:clicked&&'center',
        boxShadow: '2px 2px 5px #6A58C6',
        height:'100%'
        }}
      noValidate
      autoComplete="off"
    >
     {loading && <Loader/>}
    {!loading&&!clicked &&(
        <>
        <div>
      <div style={{
        display:'flex',
        flexDirection:!isMobile?'row':'column',
        width:'100%'
      }}>
      <TextField
          required
          id="outlined-password-input"
          label="First Name"
          InputLabelProps={{
            style:{color:'#000' , borderColor:'#000' , fontFamily:'Poppins'}
          }}
          style={{
            background:'#fff',
            borderRadius:'10px',

            borderColor:'#000',
            width:!isMobile?'50%':'100%'
          }}
          onChange={(e) => setFName(e.target.value)}
        />
         <TextField
          required
          id="outlined-password-input"
          label="Last Name"
          InputLabelProps={{
            style:{color:'#000' , borderColor:'#000' , fontFamily:'Poppins'}
          }}
          style={{
            background:'#fff',
            borderRadius:'10px',
            borderColor:'#000',
            width:!isMobile?'50%':'100%'
          }}
          onChange={(e) => setLName(e.target.value)}
        />
        

      </div>
      <div 
      style={{
        display:'flex',
        flexDirection:!isMobile?'row':'column',
        width:'100%'
      }}
      >
      <TextField
          required
          id="outlined-password-input"
          label="Email"
          type="email"
          InputProps={{
            style:{color:'#000' , borderColor:'#000' , fontFamily:'Poppins' , fontSize:'20px'}
          }}
          InputLabelProps={{
            style:{color:'#000' , borderColor:'#000' , fontFamily:'Poppins'}
          }}
          FormHelperTextProps={{
            style:{color:'red'}
          }}
          style={{
            background:'#fff',
            borderRadius:'10px',
            borderColor:'#000',
            width:!isMobile?'98%':'100%'
          }}
          onChange={(e) => {
            if(!validateEmail(e.target.value) && e.target.value.length>0) setEmailError('Enter A Correct Email');
            else setEmailError('');
            setEmail(e.target.value);
            }}
            helperText={emailError}
        />
       <TextField
            required
          id="outlined-password-input"
          label="Contact Number"
          InputProps={{
            style:{color:'#000' , borderColor:'#000' , fontFamily:'Poppins' , fontSize:'20px'}
          }}
          InputLabelProps={{
            style:{color:'#000' , borderColor:'#000' , fontFamily:'Poppins'}
          }}
          FormHelperTextProps={{
            style:{color:'red'}
          }}
          style={{
            background:'#fff',
            borderRadius:'10px',
            borderColor:'#000',
            width:'100%'
          }}
          onChange={(e) => {
            if(e.target.value.length>10 && e.target.value.length!==0) setNumberError('Enter A Correct Number');
            else setNumberError('')
            setWnumber(e.target.value)}}
            helperText={numberError}
        />
      
      </div>
      
        {/* <div style={{
          display:'flex',
          flexDirection:'column',
          width:'100%'

        }}> */}
        <TextField
          id="outlined-multiline-flexible"
          label="Name Of College Or Organisation Or N/A"
          multiline
          maxRows={4}
          InputLabelProps={{
            style:{color:'#000' , borderColor:'#000' , fontFamily:'Poppins'}
          }}
          style={{
            background:'#fff',
            borderRadius:'10px',

            borderColor:'#000',
            width:!isMobile?'98%':'100%'
          }}
          onChange={(e) => setDescription(e.target.value)}
        />
        {/* </div> */}
      <div 
      style={{
        display:'flex',
        flexDirection:!isMobile?'row':'column',
        width:'100%'
      }}
      >
      <TextField
          id="outlined-password-input"
          label="Course"
          InputProps={{
            style:{color:'#000' , borderColor:'#000' , fontFamily:'Poppins' , fontSize:'20px'}
          }}
          InputLabelProps={{
            style:{color:'#000' , borderColor:'#000' , fontFamily:'Poppins'}
          }}
          FormHelperTextProps={{
            style:{color:'red'}
          }}
          style={{
            background:'#fff',
            borderRadius:'10px',
            borderColor:'#000',
            width:'100%'
          }}
          onChange={(e) => setCourse(e.target.value)}
        />
      
      <TextField
          id="outlined-password-input"
          label="Year"
          InputProps={{
            style:{color:'#000' , borderColor:'#000' , fontFamily:'Poppins' , fontSize:'20px'}
          }}
          InputLabelProps={{
            style:{color:'#000' , borderColor:'#000' , fontFamily:'Poppins'}
          }}
          FormHelperTextProps={{
            style:{color:'red'}
          }}
          style={{
            background:'#fff',
            borderRadius:'10px',
            borderColor:'#000',
            width:'100%'
          }}
          onChange={(e) => setYear(e.target.value)}
        />

      </div>
         
       
        <div 
        style={{
          margin:'1%',
          display:'flex',
          justifyContent:"center",
          flexDirection:'column',
          alignItems:'center'
        }}>
          {/* <Button
          variant="contained"
          component="label"
          style={{
            width:!isMobile?'50%':'100%',
            background:'#000',
            fontFamily:'Poppins',
            marginBottom: '20px'
          }}
          
        >
          Upload Doc
          <input
            type="file"
            onChange={(e) => setResume(e.target.files[0])}
            hidden
          />
        </Button> */}
        <Button
          variant="contained"
          component="label"
          style={{
            width:!isMobile?'50%':'100%',
            background:'#000',
            fontFamily:'Poppins'
          }}
          onClick={handleApply}
          
        >
          Submit
        </Button>
        </div>
        </div>
        </>
    )}
    {!loading&&clicked&&(
        <div 
        style={{
          width:'70%'
        }}>
          <Typography
          variant="h4"
          style={{
            color:'#fff',
            textAlign:'center',
            fontFamily:'Poppins',
            fontWeight:'bold'
          }}
          >
          Thank You
          </Typography>
          <Typography 
          variant="subtitle1"
          style={{
            color:'#fff',
            textAlign:'center',
            fontFamily:'Poppins',
            fontWeight:'bold',
            wordWrap:'break-word'
          }}
          >Thanks for informing, we will be looking into your case and would be reaching out to you on the same</Typography>
        </div>)}
        </Box>
    </div>
    <Footer/>
    </>
  )
}

export default CourseForm