import * as React from 'react';
import {Box , Typography ,Button , Avatar , Tooltip  } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import SearchIcon from '@mui/icons-material/Search';
import {useMediaQuery} from '@mui/material';
import Divider from '@mui/material/Divider';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Profile from './Profile'
import axios from 'axios';
import { GET_ALL_USERS } from '../../api/user';
import TablePagination from '@mui/material/TablePagination';
import RemoveRedEyeSharpIcon from '@mui/icons-material/RemoveRedEyeSharp';
import avatar1 from './Images/avatar1.jpg';
import avatar2 from './Images/avatar2.jpg';
import avatar3 from './Images/avatar3.jpg';
// const rows = [
//   { id: 1, name: 'John', level: '1', lastMonthRank: 'Last Month at 2' },
//   { id: 2, name: 'Jane', level: '2', lastMonthRank: 'Last Month at 4' },
// ];
const Leaderboard = () => {
  const [ThisMonthButtonColor, setThisMonthButtonColor] = React.useState("white");
  const [ThisMonthButtonTextnColor, setThisMonthButtonTextColor] = React.useState("black");
  const [AllTimeButtonColor, setAllTimeButtonColor] = React.useState("#6a58c6");
  const [AllTimeButtonTextColor, setAllTimeButtonTextColor] = React.useState("white");
  const isSmallScreen = useMediaQuery('(max-width:600px)'); 
  const [searchQuery, setSearchQuery] = React.useState('');
  const [rows , setRows] = React.useState([]);
  const [row , setRow] = React.useState({});
  const [page, setPage] = React.useState(0);
  const [selected , setSelected] = React.useState('o')
  const filteredRows = rows.filter(row =>
    row.name.toLowerCase().includes(searchQuery?.toLowerCase()) || row?.level?.toLowerCase()?.includes(searchQuery.toLowerCase())
  );
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, filteredRows.length - page * rowsPerPage);
  React.useEffect(() => {
    axios
      .get(GET_ALL_USERS)
      .then((res) => {
        let sortedUsers;
        if (selected === "overall") {
          sortedUsers = res.data.sort((a, b) => b.markoknowPoints - a.markoknowPoints);
        } else {
          sortedUsers = res.data.sort((a, b) => b.monthlyMarkoknowPoints - a.monthlyMarkoknowPoints);
        }
  
        const rows = sortedUsers.map((user, index) => ({
          id: user.id,
          name: user.name,
          lastMonthRank: selected === "overall" ? user.markoknowPoints : user.monthlyMarkoknowPoints,
          profileImg: user.profileImg,
          _id: user._id
        }));
        const user = rows.filter((row) => row._id === window.localStorage.id);
        setRow(user[0]);
        setRows(rows);
      })
      .catch((err) => {
        console.error("Error fetching users:", err);
      });
  }, [selected]);
  
  return (
    <Box
      sx={{
        width: '90%'
      }}
    >
        <Box 
          sx={{
            display:'flex',
            flexDirection:'row',
            justifyContent: 'space-between',
            alignItems:'center',
            width: '100%'
          }}>
          <Typography variant='h5' align='left' sx={{fontFamily:'Poppins'}}>Leaderboard</Typography>
          <Box 
            sx={{
              display:'flex',
              flexDirection: 'row',
              gap:'10px',
              justifyContent:'center',
              alignItems: 'center'
            }}
          >
          <FormControl sx={{ m: 1, width: '25ch' , height: '5vh' }} variant="outlined">
          <OutlinedInput
            id="outlined-adornment-password"
            sx={{
              height: '5vh',
            }}
            type={'text'}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  edge="end"
                >
                  {<SearchIcon />}
                </IconButton>
              </InputAdornment>
            }
            label="Search"
            placeholder='Search'
          />
        </FormControl>
        <Box
                  sx={{
                    backgroundColor: "#6a58c6",
                    width: isSmallScreen ? '38vw' : '14.4vw',
                    height: isSmallScreen ? '8vh' : '5vh',
                    borderRadius: "20px"
                  }}
                >
                  <Button
                    sx={{
                      backgroundColor: ThisMonthButtonColor,
                      marginLeft: "2px",
                      height: isSmallScreen ? '7vh' : '4.6vh',
                      marginTop: "0.25vh",
                      width: isSmallScreen ? '7vw' : '7vw',
                      '&:hover': {
                        backgroundColor: ThisMonthButtonColor
                      },
                      borderRadius: '20px'
                    }}
                    onClick={() => {
                      if (ThisMonthButtonColor === "#6a58c6") {
                        setAllTimeButtonColor("#6a58c6");
                        setThisMonthButtonColor("white")
                        setAllTimeButtonTextColor("white");
                        setThisMonthButtonTextColor("black");
                        setSelected('jh');
                      }

                    }}
                  >
                    <Typography sx={{ textTransform: "none", color: ThisMonthButtonTextnColor, cursor: "pointer" }}>
                      This Month
                    </Typography>
                  </Button>
                  <Button
                    sx={{
                      backgroundColor: AllTimeButtonColor,
                      marginLeft: "2px",
                      height: isSmallScreen ? '7vh' : '4.6vh',
                      marginTop: "0.25vh",
                      width: isSmallScreen ? '7vw' : '7vw',
                      '&:hover': {
                        backgroundColor: AllTimeButtonColor
                      }
                      ,borderRadius: '20px'
                    }}
                    onClick={() => {
                      if (AllTimeButtonColor === "#6a58c6") {
                        setAllTimeButtonColor("white");
                        setThisMonthButtonColor("#6a58c6");
                        setAllTimeButtonTextColor("black");
                        setThisMonthButtonTextColor("white");
                        setSelected('overall');
                      }

                    }}
                  >
                    <Typography sx={{
                      textTransform: "none",
                      cursor: "pointer",
                      color: AllTimeButtonTextColor,
                    }}>
                      All Time
                    </Typography>
                  </Button>
                </Box>
                </Box>
               
        </Box>
        <Divider />
        <Box width='100%' 
          sx={{
            display:'flex',
            flexDirection:'row',
          }}
        >
        <Box 
          sx={{
            width: '60%'
          }}
        >
        <Box
          sx={{
            background: "#6a58c6",
            width: '100%',
            height: 200,
            borderRadius: '20px',
            display:'flex',
            flexDirection:'row',
            justifyContent:'space-evenly',
            alignItems: 'flex-end',
            mt: 2
          }}
        >
        <Box 
        sx={{
          display:'flex',
          flexDirection:'column',
          gap:'10px',
          justifyContent: 'center',
          alignItems: 'center',
          width: '30%', 
        }}>
          <Avatar src={rows[1]?.profileImg ? rows[1]?.profileImg : avatar2} onClick={(e) => {setRow(rows[1])}}/>
          <Box
            sx={{
              height: 100,
              width: '100%',
              background: 'rgba(255, 255, 255, 0.5)',
              borderTopLeftRadius:'20px',
              borderTopRightRadius:'20px',
              display:'flex',
            flexDirection:'column',
            gap:'10px',
            justifyContent: 'center',
            alignItems: 'center',
            }}
          >
           <Typography variant='h6' fontWeight={"bold"}>{"2"}</Typography>
           <Typography variant='subtitle2' fontWeight={"900"}>{"Points: " + rows[1]?.lastMonthRank}</Typography>
           </Box>
        </Box>
          
        <Box 
        sx={{
          display:'flex',
          flexDirection:'column',
          gap:'10px',
          justifyContent: 'center',
          alignItems: 'center',
          width: '30%', 
        }}>
          <Avatar src={rows[0]?.profileImg ? rows[0]?.profileImg: avatar1} onClick={(e) => {setRow(rows[0])}}/>
          <Box
            sx={{
              height: 120,
              width: '100%',
              background: 'rgba(255, 255, 255, 0.5)',
              borderTopLeftRadius:'20px',
              borderTopRightRadius:'20px',
              display:'flex',
              flexDirection:'column',
              gap:'10px',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
          <Typography variant='h6' fontWeight={"bold"}>{"1"}</Typography>
          <Typography variant='subtitle2' fontWeight={"900"}>{"Points: " + rows[0]?.lastMonthRank}</Typography>
          </Box>
          
        </Box>
        <Box 
        sx={{
          display:'flex',
          flexDirection:'column',
          gap:'10px',
          justifyContent: 'center',
          alignItems: 'center',
          width: '30%', 
        }}>
          <Avatar src={rows[2]?.profileImg ? rows[2]?.profileImg : avatar3} onClick={(e) => {setRow(rows[2])}}/>
          <Box
            sx={{
              height: 80,
              width: '100%',
              background: 'rgba(255, 255, 255, 0.5)',
              borderTopLeftRadius:'20px',
              borderTopRightRadius:'20px',
              display:'flex',
              flexDirection:'column',
              gap:'10px',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
           <Typography variant='h6' fontWeight={"bold"}>{"3"}</Typography>
           <Typography variant='subtitle2' fontWeight={"900"}>{"Points: " + rows[2]?.lastMonthRank}</Typography>
            </Box>
        </Box>

      
        </Box>
        <FormControl sx={{ m: 1, width: '95%' , height: '5vh' }} variant="outlined">
              <OutlinedInput
                id="outlined-adornment-password"
                sx={{
                  height: '5vh',
                }}
                type={'text'}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      edge="end"
                    >
                      {<SearchIcon />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Search"
                placeholder='Search'
              />
            </FormControl>
        <Box sx={{  width: '100%' }}>
        <>
      <TableContainer sx={{ width: '100%' }}>
        <Table>
          <TableBody>
            {(rowsPerPage > 0
              ? filteredRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : filteredRows
            ).map((row, index) => (
              <TableRow key={row.id} >
                <TableCell>
                  <Typography
                    sx={{
                      width: '30%',
                      color: '#000',
                      textAlign: 'center',
                      padding: '10px'
                    }}
                  >
                    {index + 1 + page * rowsPerPage}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Avatar alt={row.name} src={row.profileImg} />
                </TableCell> 
                <TableCell sx={{display:'flex' , flexDirection:'column'}}>
                  <Typography>{row.name}</Typography>
                  <Typography variant="caption">Points: {row.lastMonthRank}</Typography>
                </TableCell>
                <TableCell >
                    <Tooltip title="See Profile">
                        <IconButton onClick={(e) => {setRow(row);}} >
                            <RemoveRedEyeSharpIcon /> 
                        </IconButton>
                    </Tooltip>
                  </TableCell> {/* Last month's rank */}
              </TableRow>
            ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={4} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
        component="div"
        count={filteredRows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
        </Box>
        </Box>
        <Box 
          sx={{
            width: '40%'
          }}
        >
         
          <Profile row={row}/>
        </Box>
        </Box>
        

    </Box>
  )
}

export default Leaderboard