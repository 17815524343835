const { BASE_URL } = require("./client");

const SIGN_UP_API = BASE_URL + "/signup";
const SIGN_IN_API = BASE_URL + "/signin";
const GOGGLE_LOGIN_API = BASE_URL + "/google_login";
const VERIFY_EMAIL = BASE_URL + "/verify_email";
const VERIFY_SIGNUP_EMAIL = BASE_URL + "/verify_signup_email";
const RESEND_EMAIL = BASE_URL + "/resend_email";
const EMAIL_SUGGESTION = BASE_URL + "/api/email/suggestion";
const VERIFY_TESTIMONIAL = BASE_URL + "/verify_testimonial";
const USER = BASE_URL + "/user";
const USER_REFFERAL = BASE_URL + "/user/";


const VIDEO = BASE_URL + "/program/videoplayer/";
const GET_COURSES = BASE_URL +'/program/courses';
const GET_COURSE = BASE_URL +'/program/courses/';
const GET_COURSENAME = BASE_URL +'/program/coursename/';
const GET_MODULES = BASE_URL +'/program/modules/';
const GET_WORKEX = BASE_URL +'/program/workex/';
const GET_LAB = BASE_URL +'/program/lab/';
const GET_PROJECTS = BASE_URL + '/courseupload/project/';
const PDF = BASE_URL+ '/program/pdf/';
const USER_ACCESS = BASE_URL + '/api/useraccess/64fb08d372d0fde41877df63/';
const POST_COMPLETED_VIDEO = BASE_URL + '/api/setcompletedvideo/';
const POST_COMPLETED_PDF = BASE_URL + '/api/setcompletedpdf/';
const QUESTION = BASE_URL +'/courseupload/questions/'

const POST_REMOVED = BASE_URL + '/api/removed/';
const GET_USER = BASE_URL + '/api/user/get/';
const POST_ALL_COMPLETED = BASE_URL+'/api/completedall/';

const GET_PROMOCODES = BASE_URL+ "/api/paymentcheckout/promocode";
const POST_PROJECT = BASE_URL + '/api/videoplayer/saveProject/';
const POST_WORKEX = BASE_URL + '/api/videoplayer/saveWorkEx/';
 

const MARKOKNOW_KICKSTART = BASE_URL + "/markoknow-kickstart";
const MARKOKNOW_CHECKOUT = BASE_URL +'/program/paymentcheckout/'
const SET_COURSEDETAILS = BASE_URL + '/api/setcourseDetails/';
const RECOMMENDED_COURSES = BASE_URL + '/api/videoplayer/recomended';
const BOUGHT_COURSES = BASE_URL + '/api/videoplayer/bought';


export {
  SIGN_UP_API,
  VERIFY_EMAIL,
  VERIFY_SIGNUP_EMAIL,
  VERIFY_TESTIMONIAL,
  GOGGLE_LOGIN_API,
  EMAIL_SUGGESTION,
  USER,
  SIGN_IN_API,
  MARKOKNOW_KICKSTART,
  VIDEO , GET_COURSES , GET_COURSE , GET_MODULES ,PDF,
  GET_PROMOCODES,
  USER_REFFERAL,
  RESEND_EMAIL,
  USER_ACCESS,
  MARKOKNOW_CHECKOUT,
  POST_COMPLETED_VIDEO,
  POST_COMPLETED_PDF,
  BOUGHT_COURSES,
  RECOMMENDED_COURSES,
  POST_REMOVED,
  POST_ALL_COMPLETED,
  GET_USER,
  SET_COURSEDETAILS,
  QUESTION,
  GET_PROJECTS,
  POST_PROJECT,
  GET_WORKEX,
  GET_LAB,
  GET_COURSENAME,
  POST_WORKEX
};

