import React from 'react';
import PropTypes from 'prop-types';
import { Carousel } from 'react-responsive-carousel';
import { Box, Typography, Skeleton } from '@mui/material';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import axios from 'axios';
import { useMediaQuery , Button } from '@mui/material';
import { GET_REDEEM, REDEEM_POINTS } from '../../../api/redeem';
import { USER_REFFERAL } from '../../../api/vsc';

const Media = ({props , points , setRefre , refre}) => {
  const [blogs, setBlogs] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const isMobile = useMediaQuery('(max-width: 600px)');
  const isSmallMobile = useMediaQuery('(max-width: 410px)');
  const isGreaterMobile = useMediaQuery('(max-width: 500px)');
  const [refresh , setRefresh] = React.useState(false);
  const colors = ["#6a58c6" , "#5E17EB" , "#251E4F"];
  const getNumBlogsPerSlide = () => {
    if (isGreaterMobile) {
      return 1;
    } else if (isSmallMobile) {
      return 2;
    } else {
      return 3;
    }
  };
  let chunkSize = getNumBlogsPerSlide();
  const arrowStyles = {
    position: "absolute",
    top: ".7em",
    bottom: "auto",
    padding: ".4em",
    zIndex: 2,
 };
  React.useEffect(() => {
    setLoading(true);
         if(props === "Redeem Wallet"){
            axios
            .get(GET_REDEEM)
            .then(res => {
                axios
                    .get(USER_REFFERAL + window.localStorage.id)
                    .then(resp => {
                        const redeemedIds = resp.data.data.redeems.map(redeem => redeem._id);
                        const filteredBlogs = res.data.filter(blog => !redeemedIds.includes(blog._id));
                        
                        setBlogs(filteredBlogs);
                    })
                    .catch(err => {
                        console.log(err);
                    });
            })
            .catch(err => {
                console.log(err);
            });
         }
         else {
            axios
            .get(USER_REFFERAL + window.localStorage.id)
            .then(res => {
                setBlogs(res.data.data.redeems);
            })
            .catch(err => {
                console.log(err);
            })
         }
           
        setRefresh(false);
           
  }, [refresh , refre]);
  const handleCopyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
        .then(() => {
            alert("Copied to clipboard");
        })
        .catch((error) => console.error('Unable to copy to clipboard: ', error));
};
  const redeem = (item) => {
    axios
    .post(REDEEM_POINTS+window.localStorage.id , item)
    .then(res => {
        setRefresh(true);
        setRefre(true);
    })
    .catch(err => {
        console.log(err);
    })
  }
  const blogChunks = blogs.length > 0 ? blogs.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / chunkSize);

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = []; 
    }

    resultArray[chunkIndex].push(item);
    return resultArray;
  }, []) : [];

  return (
    <>
      <Typography variant="h5" style={{color:'#000' , textAlign:'center', fontFamily:'Poppins' , marginBottom:'10px' , fontWeight:'900'}}>
      {blogChunks.length>0&&`${props==="Redeem Wallet" ? "Redeem" : "Redeem Wallet"}`}
        </Typography>
            <Carousel
      className="carousel"
      style={{
        display: 'flex',
        justifyContent: 'center',
        width: '80%',
        alignItems: 'center',
        flexDirection: 'row',
        background: 'white', 
      }}
      showArrows={true}
      showIndicators={false}
      renderArrowPrev={(onClickHandler, hasPrev, label) =>
        hasPrev && (
          <button
            type="button"
            onClick={onClickHandler}
            title={label}
            style={{
              ...arrowStyles,
              left: !isGreaterMobile?'0.2em':0 ,
              top: '50%',
              transform: 'translateY(-50%)',
              backgroundColor: '#6a58c6',
              color: '#fff',
              borderRadius: '50%',
              border: 'none',
              padding: '10px',
              zIndex: 1,
            }}
          >
            <ArrowBackIosNewRoundedIcon />
          </button>
        )
      }
      renderArrowNext={(onClickHandler, hasNext, label) =>
        hasNext && (
          <button
            type="button"
            onClick={onClickHandler}
            title={label}
            style={{
              ...arrowStyles,
              right: !isGreaterMobile?'0.5em':'0',
              top: '50%',
              transform: 'translateY(-50%)',
              backgroundColor: '#6a58c6',
              color: '#fff',
              borderRadius: '50%',
              border: 'none',
              padding: '10px',
              zIndex: 1,
            }}
          >
            <ArrowForwardIosRoundedIcon />
          </button>
        )
      }
    >
      {blogChunks.length>0&&blogChunks.map((chunk, chunkIndex) => (
        <div key={chunkIndex} style={{ display: 'flex', flexDirection: 'row' ,justifyContent:'center' , alignItems:'center' , padding:!isGreaterMobile?'20px':'5px' , background:'#fff' }}>
          {chunk.map((item, index) => (
              <Box
                sx={{
                  width: 300,
                  height: 250,
                  marginRight: !isGreaterMobile && 0.5,
                  boxShadow: 'rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px',
                  borderRadius: '10px',
                  '&:hover': {
                    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                    borderRadius: '10px'
                  },
                }}
              >
                {item ? (
                  <Box
                    sx={{
                        width: 300, height: 150,
                        display:'flex' , 
                        flexDirection: 'column',
                        justifyContent:'center',
                        alignItems: 'center',
                        background: colors[index % colors.length],
                        color: '#fff',
                        borderRadius:'10px'
                    }}
                    >
                    <Typography variant='caption' textAlign={'left'}>
                       {item.requiredOffer}
                    </Typography>
                    <Typography variant='h2' >
                       {item.percentageDiscount}% Off
                    </Typography>
                  </Box>
                ) : (
                  <Skeleton variant="rectangular" width={300} height={180} />
                )}

                {item ? (
                  <Box sx={{ padding: 5 , display:'flex' , flexDirection:!(props === "Redeem Wallet") ? 'column-reverse': 'row' , justifyContent:  props === "Redeem Wallet" ? 'space-between': 'center' , gap: '10px' ,alignItems: 'center'}}>
                  {props === "Redeem Wallet" && <Button 
                        variant="contained" 
                        disabled={points < item.requiredPoints} 
                        sx={{background: '#6a58c6'}}
                        onClick={(e) => {
                            redeem(item);
                        }}
                        >
                        Redeem
                    </Button>}
                    <Typography>With {item.requiredPoints} Points</Typography>
                    {props !== "Redeem Wallet" && item.code && <input
                                        value={item.code}
                                        style={{
                                            borderRadius:'20px',
                                            width: 100,
                                            height: 20,
                                            fontSize: '10px',
                                            background: '#f0f0f0',
                                            border: 0,
                                            textAlign:'center',
                                            fontWeight:'800',
                                            fontFamily:'Poppins'
                                        }}
                                        onClick={() => handleCopyToClipboard(item.code)}
                                        readOnly
                                    />}
                  </Box>
                ) : (
                  <Box sx={{ pt: 0.5 }}>
                    <Skeleton />
                    <Skeleton width="60%" />
                  </Box>
                )}
                
              </Box>
          ))}
        </div>
      ))}
    </Carousel>
    </>
    
  );
};


export default Media;
