import * as React from "react";
import Box from "@mui/material/Box";
import Rating from "@mui/material/Rating";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/styles";
import StarBorderIcon from "@material-ui/icons/StarBorder";
const StyledRating = styled(Rating)({
  emptyStar: {
    color: "#6A58C6",
  },
});
const useStyles = makeStyles({
  emptyStar: {
    color: "#6A58C6",
  },
});
export default function BasicRating({ rating, setRating }) {
  const classes = useStyles();
  return (
    <Box sx={{ backgroudColor: "white", transform: "scale(3.0)"  }}>
      <StyledRating
        size="small"
        name="simple-controlled"
        value={rating}
        emptyIcon={
          <StarBorderIcon fontSize="inherit" className={classes.emptyStar} 
            style={{
                color: "#6A58C6",
                // fontSize: '20px'
            }}
          />
        }
        onChange={(event, newValue) => {
          setRating(newValue);
        }}
      />
    </Box>
  );
}
