import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import AddIcon  from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import MyAccordion from '../NewVirtualCampusStartup/Program/Accordian/MyAccordian';
import axios from 'axios';
import { GET_CASE_STUDY } from '../../api/casestudy';
import { useParams } from 'react-router-dom';
// import {questions} from '../NewVirtualCampusStartup/Program/Accordian/api'

const CaseComponent = () => {
  const params = useParams();
  const [expanded, setExpanded] = React.useState(false);
  const [subTitle , setSubTitle] =  React.useState('');
  const [questions, setQuestions] = React.useState([]);
  const id = params.id;
  React.useEffect(() => {
    axios
      .get(GET_CASE_STUDY+id)
      .then((res) => {
        setQuestions(res.data.data.qa);
        setSubTitle(res.data.data.subTitle);
      })
      .catch((err) => {
      });
  }, []);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <>
     <Typography
      style={{ lineHeight: 2, marginTop: '20px', marginBottom: '20px' }}
      dangerouslySetInnerHTML={{ __html: subTitle }}
    />
      
     <div style={{flexShrink:3}}>
    {
      questions.map((q,i) => (
        <MyAccordion question={q.q} answer={q.a} />
      ))
    }
      </div>

    </>
  );
};

export default CaseComponent;
