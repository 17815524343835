import React from 'react';
import { Box , Typography , Button } from '@mui/material';
import product from '../Images/productpath.jpg';
import management from '../Images/managementpath.jpg';
import finance from '../Images/financepath.jpg';

const CareerPath = () => {
  return (
    <Box
        sx={{
            display: 'flex' , 
            flexDirection: 'column',
            justifyContent: 'center' , 
            alignItems: 'center'
        }}
    >
         <Typography variant="h4" align="center" sx={{ mb: 2, width: { xs: '100%', md: '30%' } }}>
            <span style={{ color: "#6a58c6", fontWeight: 600 }}>Career Path</span> You can Explore <span style={{ color: "#6a58c6", fontWeight: 600 }}>After The Program</span>
        </Typography>
        <Box
            sx={{
                padding: '20px'
            }}
        >
            <Box
                src={product}
                component="img"
                sx={{
                    width: { xs: '100%', md: '30%' },
                    height: 600
                }}
            />
            <Box
                src={management}
                component="img"
                sx={{
                    width: { xs: '100%', md: '30%' },
                    height: 600
                }}
            />
            <Box
                src={finance}
                component="img"
                sx={{
                    width: { xs: '100%', md: '30%' },
                    height: 600
                }}
            />
        </Box>
        <Typography variant="h5" align="center" sx={{ mb: 2, width: { xs: '100%', md: '30%' } }}>
             and many More Levels..
        </Typography>
    </Box>
  )
}

export default CareerPath