import React from 'react';
import PropTypes from 'prop-types';
import { Carousel } from 'react-responsive-carousel';
import { Box, Typography, Skeleton } from '@mui/material';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useMediaQuery } from '@mui/material';
import {useNavigate} from 'react-router-dom';
import {
    GET_DATA,
    POSTER,
  } from "../../../api/watchnow";


const Media = ({props}) => {
    const navigate = useNavigate();
  const [blogs, setBlogs] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const isMobile = useMediaQuery('(max-width: 600px)');
  const isSmallMobile = useMediaQuery('(max-width: 410px)');
  const isGreaterMobile = useMediaQuery('(max-width: 500px)');
  const getNumBlogsPerSlide = () => {
    if (isGreaterMobile) {
      return 1;
    } else if (isSmallMobile) {
      return 3;
    } else {
      return 5;
    }
  };
  let chunkSize = getNumBlogsPerSlide();
  const arrowStyles = {
    position: "absolute",
    top: ".7em",
    bottom: "auto",
    padding: ".4em",
    zIndex: 2,
 };
  React.useEffect(() => {
    setLoading(true);
        axios
          .get(GET_DATA, {
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem("token")}`,
            },
          })
          .then((res) => {
            if(props === "Recently Added"){
              setBlogs(res.data.reverse());
            }
            else if(props.toLowerCase() === "marketing"){
              setBlogs(res.data.filter(watch => watch.tags.includes(props.toLowerCase())));
            }
            else if(props.toLowerCase() === "founders"){
              setBlogs(res.data.filter(watch => watch.tags.includes(props.toLowerCase())));
            }
            else if(props.toLowerCase() === "startups"){
              setBlogs(res.data.filter(watch => watch.tags.includes(props.toLowerCase())));
            }
            else if(props.toLowerCase() === "finance"){
              setBlogs(res.data.filter(watch => watch.tags.includes(props.toLowerCase())));
            }
            else if(props.toLowerCase() === "funding"){
              setBlogs(res.data.filter(watch => watch.tags.includes(props.toLowerCase())));
            }
            else if(props.toLowerCase() === "tech and design"){
              setBlogs(res.data.filter(watch => watch.tags.includes(props.toLowerCase())));
            }
            else {
              setBlogs(res.data);
            }
          });
      }, []);

  const blogChunks = blogs.length > 0 ? blogs.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / chunkSize);

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = []; // start a new chunk
    }

    resultArray[chunkIndex].push(item);
    return resultArray;
  }, []) : [];

  return (
    <>
      <Typography variant="h5" style={{color:'#000' , textAlign:'left', fontFamily:'Poppins' , margin:'10px' , fontWeight:'900'}}>
      {blogChunks.length>0&&`${props}`}
        </Typography>
            <Carousel
      className="carousel"
      style={{
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        alignItems: 'center',
        flexDirection: 'row',
        background: 'white', 
      }}
      showArrows={true}
      showIndicators={false}
      renderArrowPrev={(onClickHandler, hasPrev, label) =>
        hasPrev && (
          <button
            type="button"
            onClick={onClickHandler}
            title={label}
            style={{
              ...arrowStyles,
              left: !isGreaterMobile?'0.2em':0 ,
              top: '50%',
              transform: 'translateY(-50%)',
              backgroundColor: '#6a58c6',
              color: '#fff',
              borderRadius: '50%',
              border: 'none',
              padding: '10px',
              zIndex: 1,
            }}
          >
            <ArrowBackIosNewRoundedIcon />
          </button>
        )
      }
      renderArrowNext={(onClickHandler, hasNext, label) =>
        hasNext && (
          <button
            type="button"
            onClick={onClickHandler}
            title={label}
            style={{
              ...arrowStyles,
              right: !isGreaterMobile?'0.5em':'0',
              top: '50%',
              transform: 'translateY(-50%)',
              backgroundColor: '#6a58c6',
              color: '#fff',
              borderRadius: '50%',
              border: 'none',
              padding: '10px',
              zIndex: 1,
            }}
          >
            <ArrowForwardIosRoundedIcon />
          </button>
        )
      }
    >
      {blogChunks.length>0&&blogChunks.map((chunk, chunkIndex) => (
        <div key={chunkIndex} style={{ display: 'flex', flexDirection: 'row' , padding:!isGreaterMobile?'20px':'5px' , background:'#fff' }}>
          {chunk.map((item, index) => (
            <Box
                sx={{
                  width: 300,
                  height: 280,
                  // height: '100%',
                  marginRight: !isGreaterMobile && 0.5,
                  boxShadow: 'rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px',
                  '&:hover': {
                    transform: 'scale(1.1)',
                    transition: 'transform 0.3s ease-in-out',
                    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                    borderRadius: '10px'
                  },
                 
                }}
                onClick={()=>{ navigate("/watch_now/watch", { state: { link: item.link, id: item._id, watchnow: blogs } })}}
              >
                {item ? (
                  <img 
                  style={{ width: 300, height: 180 }} 
                  alt={item.title} 
                //   src={png} 
                src={POSTER+item.poster.name}

                  />
                ) : (
                  <Skeleton variant="rectangular" width={300} height={180} />
                )}

                {item ? (
                  <Box sx={{ pr: 2 , display:'flex' , flexDirection:'column' , gap:'10px' , justifyContent:'center' , alignItems: 'center'}}>
                    <Typography gutterBottom variant="body2">
                      {item.title}
                    </Typography>
                    <p
                        style={{
                          padding: '2px 10px',
                          backgroundColor: '#F2F6FF',
                          borderRadius: '10px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          width: '233px',
                          height: '20px',
                          color: '#3470e4',
                          textTransform: 'uppercase',
                          fontFamily: 'Open Sans',
                          fontSize: '12px',
                          lineHeight: '16px',
                          fontWeight: '600',
                        }}
                      >
                        {item.speaker}
                      </p>
                    {/* <Typography variant="caption" color="text.secondary">
                     {`${item.views} • ${item.createdAt}`
                       
                      {`${`Virtual Startup Campus`} • ${`Click To Read`}`}

                    </Typography> */}
                  </Box>
                ) : (
                  <Box sx={{ pt: 0.5 }}>
                    <Skeleton />
                    <Skeleton width="60%" />
                  </Box>
                )}
              </Box>
          ))}
        </div>
      ))}
    </Carousel>
    </>
    
  );
};


export default Media;
