import React, { useState , useEffect } from 'react';
import {
  Button,
  Card,
  Typography,
  Grid,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from '@mui/material';
import axios from 'axios';
import { GET_WORKEX } from '../../api/workexperience';

export default function ManagementTraineeDashboard() {
  const [open, setOpen] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const [file, setFile] = useState(null);
  const [trainee  , setTrainee] = useState({});
  const [loading , setLoading] = useState(true);


    useEffect(() => {
      
      axios
      .get(GET_WORKEX + "65a3c1243f540c1a33798a8b")
      .then(res => {
        setLoading(false);
        setTrainee(res.data.data);
      })
      .catch(err => {
        console.error(err);
      })
    
    }, [])
    
  const handleClickOpen = (task) => {
    setSelectedTask(task);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedTask(null);
  };

  const handleFileUpload = (event) => {
    setFile(event.target.files[0]);
  };

  const handleSubmit = () => {
    if (file) {
      // Handle file submission here, e.g., upload to server
      console.log('Submitted file:', file);
    }
    handleClose();
  };

  return (
    <Box
      sx={{
        mx: 'auto',
        p: 3,
        backgroundColor: 'white',
        borderRadius: '8px',
        boxShadow: 3,
        mt: 20,
        width: '70%'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 3,
        }}
      >
        <Box sx={{ width: 102 }} />
        <Typography variant="h4" fontWeight="bold" textAlign="center" sx={{ flexGrow: 1 }}>
          Management Trainee Role
        </Typography>
        <Button
          variant="contained"
          sx={{
            backgroundColor: 'green',
            '&:hover': { backgroundColor: 'green' },
            color: 'white',
          }}
        >
          Certificate
        </Button>
      </Box>

      <Grid container spacing={2} sx={{ mb: 3 , mt: 3}}>
        <Grid item xs={6}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 2, // Spacing between text and card
            }}
          >
            <Typography variant="h6" fontWeight="bold">
              Total Tasks Completed
            </Typography>
            <Card
              sx={{
                padding: 2,
                backgroundColor: '#6a58c6',
                color: 'white',
                textAlign: 'center',
                width: 200,
                height: 50,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <Typography variant="h4" fontWeight="bold">
                5
              </Typography>
            </Card>
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 2, // Spacing between text and card
            }}
          >
            <Typography variant="h6" fontWeight="bold">
              Days Left to Complete Tasks
            </Typography>
            <Card
              sx={{
                padding: 2,
                backgroundColor: '#6a58c6',
                color: 'white',
                textAlign: 'center',
                width: 200,
                height: 50,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <Typography variant="h4" fontWeight="bold">
                20
              </Typography>
            </Card>
          </Box>
        </Grid>
      </Grid>

      <Grid container spacing={2} sx={{ mt: 4 }}>
         {/* Array.from({ length: 10 }) */}
        {trainee?.days?.map((_, index) => (
          <Grid item xs={12} sm={6} md={4} lg={2.4} key={index}>
            <Card
              sx={{
                width: 150,
                height: 180,
                padding: 2,
                borderRadius: '8px',
                borderColor: '#ccc',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: index === 0 ? 'space-between' : 'center',
              }}
            >
              <Typography variant="h6" fontWeight="bold" mb={2} textAlign='center'>
                Day {index + 1}
              </Typography>
              {index === 0 && (
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                  <Button
                    variant="text"
                    size="small"
                    sx={{ background: '#f0f0f0', color: '#000', borderRadius: '20px' }}
                    onClick={() => handleClickOpen(`Task for Day ${index + 1}`)}
                  >
                    View
                  </Button>
                  <Button
                    variant="text"
                    size="small"
                    sx={{ background: '#f0f0f0', color: '#000', borderRadius: '20px' }}
                    component="label"
                  >
                    Upload
                    <input
                      type="file"
                      accept=".pdf"
                      hidden
                      onChange={handleFileUpload}
                    />
                  </Button>
                  <Button
                    variant="contained"
                    size="small"
                    sx={{
                      backgroundColor: 'green',
                      borderRadius: '20px',
                      '&:hover': { backgroundColor: 'green' },
                      color: 'white',
                    }}
                    onClick={handleSubmit}
                  >
                    Submit
                  </Button>
                </Box>
              )}
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* Dialog for Task Description */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{selectedTask}</DialogTitle>
        <DialogContent>
          <Typography>
            This is a description of the task. You need to complete this task and upload a PDF file of your work.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

