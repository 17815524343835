const { BASE_URL } = require("./client");

const POST_BOOKING = BASE_URL + '/api/booking/';
const AVAILABILITY_SLOTS = BASE_URL + '/api/booking/availability/timeslots';
const AVAILABILITY = BASE_URL + '/api/booking/availability';



module.exports = {
    POST_BOOKING,
    AVAILABILITY,
    AVAILABILITY_SLOTS
}