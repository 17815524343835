import * as React from "react";
import Box from "@mui/material/Box";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Menu from "@mui/material/Menu";
import "./Navbar.css";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import { NavLink } from "react-router-dom";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import Logout from "@mui/icons-material/Logout";
import Avatar from '@mui/material/Avatar';
import {USER , USER_ACCESS} from '../../api/vsc';
import axios from 'axios';
import { Button } from '@material-ui/core';
import Badge from '@mui/material/Badge';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
// import Course_Landing from '../NewVirtualCampusStartup/Program/Course_Landing';
import { MARKOKNOW_CHECKOUT,MARKOKNOW_KICKSTART } from "../../api/vsc";
import { CART_REMOVE_ALL, CART_REMOVE_SINGLE } from "../../api/payment";
import CloseIcon from '@mui/icons-material/Close';
export default function Cart({refresh,coun}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isAuth, setIsAuth] = React.useState(
    window.localStorage.getItem("isAuth") || false
  );
  const navigate = useNavigate();
  const [user , setUser] = React.useState({});
  const open = Boolean(anchorEl);
  const [course1,setCourse1] = React.useState(0);
  const [course2,setCourse2] = React.useState(0);
  const [course3,setCourse3] = React.useState(0);
  const [count , setCount] = React.useState(coun||0);

  const [isButtonEnabled , setIsButtonEnabled] = React.useState(false);
  const [ref , setRef] = React.useState(false);
  const [paid , setPaid] = React.useState(false);
  const [total  , setTotal] = React.useState(0);
    React.useEffect(() => {
    axios
      .get(USER_ACCESS + window.localStorage.id)
      .then((res) => {
        setPaid(res.data.access);
      })
      .catch((err) => {});
  },[]);
  const handleCourseUpdate = () => {
    axios
      .get(USER, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setUser(response.data);
        // setCourseId()
        let totalSum = 0;
        totalSum = response.data.cart.reduce((acc, cart) => acc + cart.price, 0);
        setTotal(totalSum);
        setCount(response.data.cart.length);
        setCourse1(response.data.course1);
        setCourse2(response.data.course2);
        setCourse3(response.data.course3);
      })
      .catch((err) => {
        
      });
  };
    React.useEffect(() => {
          handleCourseUpdate();         
        // setRef(false);
    },[]);

  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    axios
    .get(USER , {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
    .then(response=> {
        setUser(response.data);
        setCourse1(response.data.course1);
        setCourse2(response.data.course2);
        setCourse3(response.data.course3);
    })
    .catch(err => {
        
    })
  };
  const removeItem = (cartItem) => {
     axios
     .post(CART_REMOVE_SINGLE+window.localStorage.id+"/"+cartItem.courseId,{courseName: cartItem.courseName})
     .then(res => {
       setRef(true);
     })
     .catch(err => {
      console.log(err);
     })
  }
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleRemove = () => {

    axios
      .post(CART_REMOVE_ALL+window.localStorage.id)
      .then((response) => { setCount(0); handleCourseUpdate()})
      .catch((err) => {
        
      });
  }
  if (isAuth ) {
    return (
      <React.Fragment>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <Tooltip>
          
            <IconButton
              onClick={handleClick}
              size="small"
              sx={{ ml: 2 }}
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            >
            <Badge badgeContent={count} color="secondary">
              <ShoppingCartIcon sx={{ width: 32, height: 32 }}/>
            </Badge>
            </IconButton>
            
          </Tooltip>
        </Box>
        <Menu
          style={{
            position: "absolute",
            top: 20,
          }}
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
        //   onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",

              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: 1,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",

                top: 80,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
            {user.cart && user.cart.map((cart) => (
              <MenuItem onClick={handleClose} style={{
                display:'flex',
                justifyContent:"space-between",
              }}>
                  {cart.courseName} 
                  <Tooltip title="Remove Items">
                  <IconButton onClick={(e) => removeItem(cart)}>
                  <CloseIcon/>
                  </IconButton> 
                  </Tooltip>
              </MenuItem>
            ))}
          <Divider />
          
          <MenuItem onClick={handleClose} >
          Total : ₹{total}
          </MenuItem>
          <MenuItem>
            <Button 
              variant="contained"
              style={{ marginTop: '16px', width: '100%', borderRadius: '14px', backgroundColor: '#6A58C6',color: '#fff' }}
              onClick={handleRemove}
            >
              Remove Item
            </Button>
          </MenuItem>
          <MenuItem >
             {
              user.cart && user.cart.find((cart) => (cart.courseId === "64fb08d372d0fde41877df63"||cart.courseId === "64fb15b672d0fde41877e193"||cart.courseId === "64fb105b72d0fde41877e101" ))? (
                <a href={MARKOKNOW_CHECKOUT+"64fb08d372d0fde41877df63/"+window.localStorage.id+"/"+window.location.hostname.split('.')[0]} >

                <Button
                variant="contained"
                style={{ marginTop: '16px', width: '100%', borderRadius: '14px', backgroundColor: '#6A58C6',color: '#fff' }}
                >
                PROCEED TO CHECKOUT
                </Button>
                </a>
              ): (
               <Button
                variant="contained"
                style={{ marginTop: '16px', width: '100%', borderRadius: '14px', backgroundColor: '#6A58C6',color: '#fff' }}
                // onClick={()=> navigate(MARKOKNOW_CHECKOUT+user.cart[0]._id+"/"+window.localStorage.id)}
                href={user.cart&&user.cart.length>0 && MARKOKNOW_CHECKOUT+user.cart[0]._id+"/"+window.localStorage.id}
                // href={user.cart&&user.cart.length>0 && "https://virtualstartupcampus.com/program/paymentcheckout/"+window.localStorage.id+"/course"}
                >
                PROCEED TO CHECKOUT
                </Button>
              )
             }
          </MenuItem>
        </Menu>
        
      </React.Fragment>
    )
    
  } 
}
