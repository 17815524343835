import React from 'react';

import {Box } from '@mui/material';
import HeroComponent from './HeroComponet';
import PossibleCareer from './PossibleCareer';
import ImageMover from './ImageMover';
import KeyAspects from './KeyAspects';
import ProgramCovers from './ProgramCovers';
import Timeline from './Timeline';
import CareerPath from './CareerPath';
import IndustryExperts from './IndustryExperts'
import Certificates from './Certificates';
import Testimonials from './Testimonials';
import Colleges from './Colleges';
import InfoBox from '../CourseLandingPage/InfoBox';
import FAQs from './FAQs';
import ApplyStrip from './ApplyStrip';

const CorporateExposureProgram = () => {
  return (
    <Box
        sx={{
            marginTop: '120px',
            marginBottom: '50px'
        }}
    >
    <Box>
        <HeroComponent />
    </Box>
    <Box
        sx={{
            marginTop: '50px',
            marginBottom: '50px'
        }}
    >
        <PossibleCareer/>
    </Box>
    <Box
        sx={{
            marginTop: '50px',
            marginBottom: '50px'
        }}
    >
        <ImageMover/>
    </Box>
    <Box
        sx={{
            marginTop: '50px',
            marginBottom: '50px'
        }}
    >
        <KeyAspects/>
    </Box>
    <Box
        sx={{
            marginTop: '50px',
            marginBottom: '50px'
        }}
    >
        <ProgramCovers/>
    </Box>
    <Box
        sx={{
            marginTop: '50px',
            marginBottom: '50px'
        }}
    >
        <Timeline/>
    </Box>
    <Box
        sx={{
            marginTop: '50px',
            marginBottom: '50px'
        }}
    >
        <CareerPath/>
    </Box>
    <Box
        sx={{
            marginTop: '50px',
            marginBottom: '50px'
        }}
    >
        <IndustryExperts/>
    </Box>
    <Box
        sx={{
            marginTop: '50px',
            marginBottom: '50px'
        }}
    >
        <Certificates/>
    </Box>
    <Box
        sx={{
            marginTop: '50px',
            marginBottom: '50px'
        }}
    >
        <Testimonials/>
    </Box>
    <Box
        sx={{
            marginTop: '50px',
            marginBottom: '50px'
        }}
    >
        <Colleges/>
    </Box>
    <Box
        sx={{
            marginTop: '50px',
            marginBottom: '50px',
            display: 'flex' , 
            justifyContent: 'center', 
            alignItems: 'center',
        }}
    >
        <InfoBox/>
    </Box>
    <Box
        sx={{
            marginTop: '50px',
            marginBottom: '50px'
        }}
    >
        <FAQs/>
    </Box>
    <Box
        sx={{
            marginTop: '50px',
            display: 'flex' , 
            justifyContent: 'center', 
            alignItems: 'center',
            

        }}
    >
        <ApplyStrip/>
    </Box>
    </Box>
  )
}

export default CorporateExposureProgram