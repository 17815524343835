import React, { useState } from "react";
import { Box, Button, TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import Test from "./test";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { POST_FEEDBACK } from "../../api/feedback";
import { useMediaQuery } from '@mui/material';
import Footer from '../Footer/Footer';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const useStyles = makeStyles({
  wrapper: {
    marginTop:'80px',
    display: "flex",
    // background: "linear-gradient(to top left, #6A58C6, #a8f5ff) !important",
    background:'#ffff',
    flexDirection: "column",
    alignItems: "center",
    //justifyContent:'center',
    // border: "1px solid red",

    paddingTop: "0.8% important",
    paddingLeft: "5%",
    paddingRight: "5%",
    paddingBottom: "2rem",
    height: "85vh",
    overflow: "hidden !important",
    "@media only screen and (max-width: 1280px)": {
      height: "100%",
      paddingBottom: "20%",
    },
    "@media only screen and (max-width: 750px)": {
      display: "flex",
    //   background: "linear-gradient(to top left, #6A58C6, #a8f5ff) !important",
     background:'#ffff',
      flexDirection: "column",
      alignItems: "center",
      //justifyContent:'center',
    },
  },

  headingbox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    //backgroundColor:'yellow',
    width: "100%",
    marginTop: "0rem",
    marginBottom: "1.5rem",
    "@media only screen and (max-width: 750px)": {
      marginTop: "0.3rem",
    },
  },
  heading1: {
    fontSize: "48px",
    // color: "#251E4F",
    color: "#000",
    "@media only screen and (max-width: 750px)": {
      fontSize: "36px",
      color: "white",
      marginBottom: "5px",
    },
  },
  heading2: {
    color: "#000",
  },
  container: {
    display: "flex",
    width: "70%",
    color: "white",
    padding: "1rem",
    //backgroundColor:'yellow',
    flexDirection: "column",
    marginTop: "40px",
    borderRadius: "10px",
    background:"#F0F0F0",
    backdropFilter: "blur(15px)",
    boxShadow: "0 8px 32px 0 rgba(31, 38, 135, 0.37)",
    "@media only screen and (max-width: 750px)": {
      display: "flex",
      width: "100%",
      flexDirection: "column",
      color: "white",
    },
  },
  ratingsBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "black",
    width: "100%",
    marginBottom: "1rem",
    "@media only screen and (max-width: 750px)": {
      display: "flex",
      alignItems: "center",
      alignItems: "center",
      justifyContent: "space-between",
      width: "100%",
      flexDirection: "column",

      marginBottom: "2px",
    },
  },
  leftbox: {
    flex: "5",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    //backgroundColor:'blue',
    "@media only screen and (max-width: 750px)": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginBottom: "20px",
      //backgroundColor:'blue',
    },
  },
  rightbox: {
    flex: "7",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "white",
    border:'10px',
    borderColor:'#000',
    "@media only screen and (max-width: 750px)": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: "white",
      //backgroundColor:'red'
    },
  },
  leftboxtext: {
    fontSize: "28px",
    color: "#251E4F",
  },
  ratingicons: {
    //backgroundColor:'blue',
    icon: {
      height: 64,
      width: 64,
    },
  },
  buttonBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    //backgroundColor:'red',
    width: "100%",
    marginBottom: "1.2rem",
    "@media only screen and (max-width: 750px)": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      //backgroundColor:'yellow',
      width: "100%",
      flexDirection: "column",
      marginTop: "10px",
    },
  },
  buttonsets: {
    display: "flex",
    flexDirection: "column",
    //backgroundColor:'blue',
    width: "100%",
  },
  buttonset: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  button: {
    height: "40px",
    width: "20%",
    backgroundColor: "#6A58C6",
    borderRadius: "100px",
    color: "white",
    margin: "5px",
    "&:focus": { backgroundColor: "white", color: "black" },
    "@media only screen and (max-width: 750px)": {
      height: "45px",
      width: "32%",
      fontSize: "12px",
    },
  },
  textBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "white",
    //backgroundColor:'blue',
    width: "100%",

    marginBottom: "1.2rem",
    "@media only screen and (max-width: 750px)": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      //backgroundColor:'yellow',
      width: "100%",
      margin: "auto",
      flexDirection: "column",
      marginTop: "10px",
    },
  },
  text: {
    height: "150px",
    width: "400px",
    backgroundColor: "#6A58C6",
    display: "flex",
    fontFamily: "sans-serif",
    fontSize: "20px",
    borderRadius: "10px",
    padding: "10px",
    fontWeight: "500",
    border: "none",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "wrap",
    color: "white",
    whiteSpace: "initial",
    "@media only screen and (max-width: 900px)": {
      width: "300px",
    },
    "@media only screen and (max-width: 750px)": {
      width: "75vw",
    },
  },
  submitbtnBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer !important",
    width: "100%",
    marginTop: "1rem",
  },
  submitbtn: {
    backgroundColor: "#251E4F",
    borderRadius: "20px",
    color: "white",
    width: "30%",
    padding: "1rem",
    fontWeight: "600",
    fontSize: "1rem",
    border: "none",
    height: "40px",
    marginTop: "0rem",
    "&:hover": {
      backgroundColor: "#6A38B9",
    },
  },
});

const Feedback = () => {
  const classes = useStyles();
  const [rating, setRating] = useState(0);
  const [liked, setLiked] = useState("not given");
  const [state, setState] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [open, setOpen] = useState(false);
  const isMobile = useMediaQuery('(max-width: 875px)');
  

  const MakeAllFalse = () => {
    setRating(0);
    setFeedback("");
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const HandleSubmit = () => {
    setOpen(true);
    axios
      .post(
        POST_FEEDBACK,
        { rating, liked, feedback },
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        MakeAllFalse();
        setOpen(true);
      })
      .catch((err) => {
      });
  };
  return (
   <> <Box 
    className={classes.wrapper}
    style={{
        marginTop:isMobile?'50px':'80px',
    display: "flex",
    background:'#ffff',
    flexDirection: "column",
    alignItems: "center",
    paddingTop: "0.8% important",
    paddingLeft: "5%",
    paddingRight: "5%",
    paddingBottom: "2rem",
    height: isMobile?"100%":"85vh",
    }}
    >
      <Box 
      className={classes.container}
      style={{
        display: "flex",
        width: isMobile?"100%":"70%",
        height:isMobile&&"100%",
        color: "white",
        padding: "1rem",
        flexDirection: "column",
        marginTop: "40px",
        borderRadius: "10px",
        background:"#F0F0F0",
        backdropFilter: "blur(15px)",
        boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,rgba(0, 0, 0, 0.06) 0px 2px 4px -1px',
        margin: isMobile&&'2%'
    // boxShadow: '2px 2px 5px #2773a5' 
        
      }}
      >
        <Box 
        className={classes.headingbox}
        style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            width: "100%",
            marginTop: "0rem",
            marginBottom: "1.5rem",
        }}
        >
          <Typography 
          className={classes.heading1}
          style={{
            fontSize: "48px",
            color: "#000",
            fontFamily:'Poppins',
            fontWeight:'bold'
          }}
          >Feedback</Typography>
          <Typography 
          className={classes.heading2}
          style={{
            color: "#000",
          }}
          >
            Tell us Your Experience with Markoknow.
          </Typography>
        </Box>
        <Box 
        className={classes.ratingsBox}
        style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "black",
            width: "100%",
            marginBottom: "1rem",
        }}
        >
          <Box 
          className={classes.leftbox}
          style={{
            flex: "5",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          >
            <Typography 
            className={classes.leftboxtext}
            style={{
                fontSize: "28px",
                color: "#000",
            }}
            >Rating</Typography>
          </Box>
          <Box 
          className={classes.rightbox}
          style={{
            flex: "7",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "white",
          }}
          >
            <Test rating={rating} setRating={setRating} />
          </Box>
        </Box>
        <Box 
        className={classes.buttonBox}
        style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            marginBottom: "1.2rem",
        }}
        >
          <Box 
          className={classes.leftbox}
          style={{
            flex: "5",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          >
            <Typography 
            className={classes.leftboxtext}
            style={{
                fontSize: "28px",
                color: "#000",
            }}
            >
              What you liked the most?
            </Typography>
          </Box>
          <Box 
          className={classes.rightbox}
          style={{
            flex: "7",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "white",
          }}
          >
            <Box 
            className={classes.buttonsets}
            style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
            }}
            >
              <Box 
              className={classes.buttonset}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              >
                <Button
                  onClick={() => setLiked("Website")}
                  className={classes.button}
                  style={{
                    height: isMobile?'45px':"40px",
                    width: isMobile? '32%':"20%",
                    background: liked==='Website'?'#000':"#6A58C6",
                    borderRadius: "100px",
                    color: "white",
                    margin: "5px",
                  }}
                >
                  Website
                </Button>
                <Button
                  onClick={() => setLiked("Events")}
                  className={classes.button}
                  style={{
                    height: isMobile?'45px':"40px",
                    width: isMobile? '32%':"20%",
                    background: liked==='Events'?'#000':"#6A58C6",

                    borderRadius: "100px",
                    color: "white",
                    margin: "5px",
                  }}
                >
                  Events
                </Button>
                <Button
                  onClick={() => setLiked("Learning")}
                  className={classes.button}
                  style={{
                    height: isMobile?'45px':"40px",
                    width: isMobile? '32%':"20%",
                    background: liked==='Learning'?'#000':"#6A58C6",

                    borderRadius: "100px",
                    color: "white",
                    margin: "5px",
                  }}
                >
                  Learning
                </Button>
                <Button
                  onClick={() => setLiked("Community")}
                  className={classes.button}
                  style={{
                    height: isMobile?'45px':"40px",
                    width: isMobile? '40%':"20%",
                    background: liked==='Community'?'#000':"#6A58C6",

                    borderRadius: "100px",
                    color: "white",
                    margin: "5px",
                  }}
                >
                  Community
                </Button>
              </Box>
              <Box 
              className={classes.buttonset}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              >
                <Button
                  onClick={() => setLiked("Mentors")}
                  className={classes.button}
                  style={{
                    height: isMobile?'45px':"40px",
                    width: isMobile? '32%':"20%",
                    background: liked==='Mentors'?'#000':"#6A58C6",

                    borderRadius: "100px",
                    color: "white",
                    margin: "5px",
                  }}
                >
                  Mentors
                </Button>
                <Button
                  onClick={() => setLiked("NetWorking")}
                  className={classes.button}
                  style={{
                    height: isMobile?'45px':"40px",
                    width: isMobile? '32%':"20%",
                    background: liked==='NetWorking'?'#000':"#6A58C6",

                    borderRadius: "100px",
                    color: "white",
                    margin: "5px",
                  }}
                >
                  Networking
                </Button>
                <Button
                  onClick={() => setLiked("Others")}
                  className={classes.button}
                  style={{
                    height: isMobile?'45px':"40px",
                    width: isMobile? '32%':"20%",
                    background: liked==='Others'?'#000':"#6A58C6",

                    borderRadius: "100px",
                    color: "white",
                    margin: "5px",
                  }}
                >
                  Others
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box 
        className={classes.textBox}
        style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "white",
            width: "100%",

        }}
        >
          <Box 
          className={classes.leftbox}
          style={{
            flex: "5",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          >
            <Typography 
            className={classes.leftboxtext}
            style={{
                fontSize: "28px",
                color: "#000",
            }}
            >Feedback</Typography>
          </Box>
          <Box 
          className={classes.rightbox}
          style={{
            flex: "7",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "#000",
            border:'10px  #000',
          }}
          >
            <textarea
              value={feedback}
              onChange={(e) => setFeedback(e.target.value)}
              className={classes.text}
              style={{
                height: "150px",
                width: isMobile?"75vw":"400px",
                backgroundColor: "#fff",
                border:'5px solid #000',
                borderColor:'#000',
                display: "flex",
                fontFamily: "sans-serif",
                fontSize: "20px",
                borderRadius: "10px",
                padding: "10px",
                fontWeight: "500",
                // border: "none",
                alignItems: "center",
                justifyContent: "center",
                flexWrap: "wrap",
                color: "#000",
                whiteSpace: "initial",
              }}
              multiline
              rows={4}
              variant="outlined"
            />
          </Box>
        </Box>
        <Box 
        className={classes.submitbtnBox}
        style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer !important",
            width: "100%",
            marginTop: "1rem",
        }}
        >
          <Button
            onClick={() => {
              HandleSubmit();
            }}
            className={classes.submitbtn}
            style={{
                backgroundColor: "#6A38B9",
                borderRadius: "20px",
                color: "white",
                width: "30%",
                padding: "1rem",
                fontWeight: "600",
                fontSize: "1rem",
                border: "none",
                height: "40px",
                marginTop: "0rem",
                "&:hover": {
                backgroundColor: "#6A38B9",
                },
            }}
          >
            Submit
          </Button>
        </Box>
      </Box>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Thank you for your feedback!
        </Alert>
      </Snackbar>
    </Box>
    <Footer/>
</>
  );
};

export default Feedback;
