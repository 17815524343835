import { Typography } from '@mui/material'
import React from 'react';
import Footer from '../Footer/Footer';
import {useMediaQuery} from '@mui/material';

const About = () => {
    const isMobile = useMediaQuery('(max-width:800px)');
  return (
    <>
    <div 
    style={{
        marginTop:'120px',
        display:'flex',
        justifyContent:'center',
        flexDirection:'column',
        alignItems:'center'
    }}
    >
        <Typography 
        variant={!isMobile?"h2":'h4'}
        style={{
            textAlign:'center',
            fontFamily: 'Poppins',
            fontWeight: 'bold'
        }}>
            About Us
        </Typography>
        <Typography 
        variant="h6"
        style={{
            textAlign:'center',
            fontFamily: 'Poppins',
            fontSize:isMobile?'20px':'30px'

        }}
        >
        Get to Know the New Generation Business Education
        </Typography>
        <div
        style={{
            display:'flex',
            justifyContent:'center',
            alignItems:'center',
            marginTop:'50px',
            flexDirection:isMobile?'column':'row',
            // flexWrap:'wrap',
            width:isMobile?'90%':'80%',
            borderRadius:'10px',
            border:'#000 solid',
            marginBottom:'50px'
        }}
        >
            <div
            style={{
                width:isMobile?'80%':'50%',
                padding:isMobile?'0.5%':'5%'
            }}
            >
            <Typography 
            variant="h4"
            style={{
                textAlign:'left',
                fontFamily: 'Poppins',
                fontWeight:'900'
            }}>
            Who we are?
            </Typography>
            <br/>
            <Typography
            variant="body1"
            style={{
                // marginTop:'10px',
                textAlign:'left',
                fontFamily: 'Poppins',
                fontSize:'24px'
            }}
            >
            At Virtual Startup Campus, an initiative by Markoknow, a technology company with 50,000+ plus community people, we are a place where you can hang out to learn, experience, practice real time from on demand and live modules about Startup and the New Generation Business Education.
            </Typography>
            <br/>
            <Typography
            variant="body1"
            style={{
                // marginTop:'10px',
                textAlign:'left',
                fontFamily: 'Poppins',
                fontSize:'24px'
            }}
            >
            We are a edtech platform offering modules, learning material, resources, case studies, insights, video from industry experts, workshops and events for startup and business domains. 
            </Typography>

            </div>
            <div
            style={{
                width:isMobile?'80%':'50%',
                padding:isMobile?'':'5%'
            }}
            >
            <br/>
            <Typography
            variant="body1"
            style={{
                textAlign:'left',
                fontFamily: 'Poppins',
                fontSize:'24px'
            }}
            >
            Our Mission is to bridge the gap to bring more business mindset to education and career that will bring more job creators and make people explore more career options and move towards more senior roles with business mindset and result driven approach. 
            </Typography>
            <br/>
            <Typography
            variant="body1"
            style={{
                // marginTop:'10px',
                textAlign:'left',
                fontFamily: 'Poppins',
                fontSize:'24px'
            }}
            >
            Our core values are we look forward to provide a practical and real time approach to learning about startups and businesses and provide avenues to "implement and learn"
            </Typography>

            </div>
        </div>
        
    </div>
    <Footer/>
    </>
  )
}

export default About