import React , {useState , useEffect} from 'react';
import { TextField, Box, Grid , useMediaQuery , MenuItem} from '@mui/material';
import { GET_COURSES } from "../../../api/vsc";
import axios from 'axios';
const BookingForm = ({ formData, setFormData, onSubmit }) => {
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const isMobile = useMediaQuery('(max-width: 600px)');
  const [currencies , setCurrencies] = useState([])
  useEffect(() => {
    axios
    .get(GET_COURSES)
    .then((data) => {
      const fetchedCourses = data.data.data.map((course) => ({
        value: course.courseName,
        label: course.courseName,
      }));
      setCurrencies(fetchedCourses);
    })
    .catch((err) => {
    });
  }, []);

  const years = [
    {
      value: '1st Year',
    },
    {
      value: '2nd Year',
    },
    {
      value: '3rd Year',
    },
    {
      value: '4th Year',
    }
  ]
  return (
    <Box sx={{marginTop: '40px' , display: 'flex' , flexDirection: 'column' , alignItems: 'center' , justifyContent: 'center'}}>
      <h2 style={{textAlign: 'center' , marginBottom: '30px'}}>Fill Your Details</h2>
      <Grid container spacing={2}
        sx={{
        '& .MuiTextField-root': { m:!isMobile? 1:0.5 },
        borderRadius:'10px',
        background:'#6A58C6',
        height:'100%',
        padding:'3% 5% 3% 2% ',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        boxShadow: '2px 2px 5px #6A58C6',
        width: '70%'
        }}
      >
        <Grid item xs={12} sm={6}>
          <TextField
            label="Name"
            name="name"
            fullWidth
            value={formData.name}
            onChange={handleChange}
            InputLabelProps={{
            style:{color:'#000' , borderColor:'#000' , fontFamily:'Poppins'}
          }}
          style={{
            background:'#fff',
            borderRadius:'10px',

            borderColor:'#000',
            width:'100%'
          }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          {/* <TextField
            label="Course"
            name="course"
            fullWidth
            value={formData.course}
            onChange={handleChange}
            InputLabelProps={{
            style:{color:'#000' , borderColor:'#000' , fontFamily:'Poppins'}
          }}
          style={{
            background:'#fff',
            borderRadius:'10px',

            borderColor:'#000',
            width:'100%'
          }}
          /> */}
          <TextField
          id="outlined-select-currency"
          select
          label="Course"
            name="course"
            fullWidth
            value={formData.course}
            onChange={handleChange}
            InputLabelProps={{
            style:{color:'#000' , borderColor:'#000' , fontFamily:'Poppins'}
          }}
          style={{
            background:'#fff',
            borderRadius:'10px',

            borderColor:'#000',
            width:'100%'
          }}
        >
          {currencies.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="College"
            name="college"
            fullWidth
            value={formData.college}
            onChange={handleChange}
            InputLabelProps={{
            style:{color:'#000' , borderColor:'#000' , fontFamily:'Poppins'}
          }}
          style={{
            background:'#fff',
            borderRadius:'10px',

            borderColor:'#000',
            width:'100%'
          }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          {/* <TextField
            label="Year"
            name="year"
            fullWidth
            value={formData.year}
            onChange={handleChange}
            InputLabelProps={{
            style:{color:'#000' , borderColor:'#000' , fontFamily:'Poppins'}
          }}
          style={{
            background:'#fff',
            borderRadius:'10px',

            borderColor:'#000',
            width:'100%'
          }}
          /> */}
           <TextField
          id="outlined-select-currency"
          select
          label="Year"
            name="year"
            fullWidth
            value={formData.year}
            onChange={handleChange}
            InputLabelProps={{
            style:{color:'#000' , borderColor:'#000' , fontFamily:'Poppins'}
          }}
          style={{
            background:'#fff',
            borderRadius:'10px',

            borderColor:'#000',
            width:'100%'
          }}
        >
          {years.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.value}
            </MenuItem>
          ))}
        </TextField>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Email"
            name="email"
            fullWidth
            value={formData.email}
            onChange={handleChange}
            InputLabelProps={{
            style:{color:'#000' , borderColor:'#000' , fontFamily:'Poppins'}
          }}
          style={{
            background:'#fff',
            borderRadius:'10px',

            borderColor:'#000',
            width:'100%'
          }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="WhatsApp Number"
            name="whatsappNumber"
            fullWidth
            value={formData.whatsappNumber}
            onChange={handleChange}
            InputLabelProps={{
            style:{color:'#000' , borderColor:'#000' , fontFamily:'Poppins'}
            }}
            style={{
              background:'#fff',
              borderRadius:'10px',

              borderColor:'#000',
              width:'100%'
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default BookingForm;
