import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { Box, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import { validateEmail } from "../../auth/validateEmail";
import { APPLY_COURSE } from "../../../api/course";
import axios from 'axios';


const CustomTextField = (props) => (
    <TextField
        {...props}
        InputProps={{
            ...props.InputProps,
            sx: {
                '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                        borderColor: 'transparent',
                    },
                    '&:hover fieldset': {
                        borderColor: 'transparent',
                    },
                    '&.Mui-focused fieldset': {
                        borderColor: 'transparent',
                    },
                    color: 'black',
                    background: '#fff',
                    borderRadius: '20px',
                },
                '& input': {
                    color: 'black',
                },
            },
        }}
        InputLabelProps={{
            ...props.InputLabelProps,
            sx: {
                color: 'black',
                '&.Mui-focused': {
                    color: 'black',
                },
            },
        }}
        sx={{
            width: '70%',
            borderRadius: '20px',
            ...props.sx,
        }}
    />
);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ApplyDialog() {
  const [open, setOpen] = React.useState(false);
  const [fName, setFName] = React.useState("");
  const [contactNo, setContactNo] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [collegeName, setCollegeName] = React.useState("");
  const [emailError, setEmailError] = React.useState("");
  const [contactNoError, setContactNoError] = React.useState("");
  const [submitted, setSubmitted] = React.useState(false);

  const MakeAllFalse = () => {
      setFName("");
      setContactNo("");
      setEmail("");
      setCollegeName("");
  };

  const handleSubmit = (e) => {
      e.preventDefault();
      const data = {
          fName: fName,
          wNumber: contactNo,
          email: email,
          description: collegeName,
      };

      axios
          .post(APPLY_COURSE + window.localStorage.id, data)
          .then((res) => {
              MakeAllFalse();
              setSubmitted(true);
          })
          .catch((err) => {
             setSubmitted(true);
          });
  };

  React.useEffect(() => {
      if (!email) {
          setEmailError("");
      } else {
          if (validateEmail(email)) {
              setEmailError("");
          } else {
              setEmailError("Please enter a valid email.");
          }
      }
  }, [email]);

  React.useEffect(() => {
      if (!contactNo) {
          setContactNoError("");
      } else {
          if (contactNo.length === 10) {
              setContactNoError("");
          } else {
              setContactNoError("Please Enter valid contactNo.");
          }
      }
  }, [contactNo]);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Button variant="outlined" onClick={handleClickOpen} sx={{color: 'black' , border: '2px solid #000'}}>
        Know More
      </Button>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle sx={{textAlign: 'center'}}>{"Apply Now"}</DialogTitle>
        <DialogContent>
        <Box
                            sx={{
                                width: '100%',
                                background: '#6a58c6',
                                padding: '5px',
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '20px',
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderRadius: '10px'
                            }}
                        >
                            {submitted ? (
                                <Typography variant="h6" sx={{ color: 'white', textAlign: 'center' }}>
                                    You have successfully applied for the course.
                                </Typography>
                            ) : (
                                <Box
                                    sx={{
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '20px',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    // borderRadius: '10px'
                                }}
                                
                                 component="form" onSubmit={handleSubmit}>
                                    <CustomTextField
                                        id="outlined-basic"
                                        label="First Name"
                                        variant="outlined"
                                        onChange={(e) => setFName(e.target.value)}
                                        sx={{
                                            background: '#fff',
                                            width: '70%',
                                            borderRadius: '10px'
                                        }}
                                    />
                                    <CustomTextField
                                        id="outlined-basic"
                                        label="Contact Number"
                                        variant="outlined"
                                        onChange={(e) => setContactNo(e.target.value)}
                                        sx={{
                                            background: '#fff',
                                            width: '70%',
                                            borderRadius: '10px'

                                        }}
                                        FormHelperTextProps={{ sx: { color: 'red' } }}
                                        helperText={contactNoError}
                                    />
                                    <CustomTextField
                                        id="outlined-basic"
                                        label="Email"
                                        variant="outlined"
                                        onChange={(e) => setEmail(e.target.value)}
                                        sx={{
                                            background: '#fff',
                                            width: '70%',
                                            borderRadius: '10px'

                                        }}
                                        FormHelperTextProps={{ sx: { color: 'red' } }}
                                        helperText={emailError}
                                    />
                                    <CustomTextField
                                        id="outlined-basic"
                                        label="College Name / Organization / Not Applicable"
                                        variant="outlined"
                                        onChange={(e) => setCollegeName(e.target.value)}
                                        sx={{
                                            background: '#fff',
                                            width: '70%',
                                            borderRadius: '10px',
                                            border: 0
                                        }}
                                    />

                                    <Button
                                        variant="contained"
                                        sx={{
                                            background: '#000',
                                            marginTop: '30px'
                                        }}
                                        type="submit"
                                    >
                                        Submit
                                    </Button>
                                </Box>
                            )}
                        </Box>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
