import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
// import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import Chip from '@mui/material/Chip';
import ListItemText from "@mui/material/ListItemText";
import DashboardIcon from "@mui/icons-material/Dashboard";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { GrResources } from "react-icons/gr";
import { components } from "./components.js";
import Alert from "./Alert.jsx";
import Calender from "./Calender.jsx";
import Chart from './Chart.jsx';
import Timeline from './Timeline.jsx';
import { NavLink , useNavigate , useLocation } from "react-router-dom";
import blogPic from '../../auth/Images/blog.png'
import caseStudyPic from '../../auth/Images/case-study-3.png'
import streamingPic from '../../auth/Images/streaming-4.png'
import useMediaQuery from '@mui/material/useMediaQuery';
import './index.css';
import NavbarTopDropdown from '../../Navbar/NavbarTopDropdown';
import '../../../stylesheets/dashboard.css';
import Tooltip from '@mui/material/Tooltip';
import CaseStudy from "./CaseStudy";
import NewCourse from "./NewCourse.jsx";
import Courses from '../../Course/CourseWithPayment.jsx';
import Badge from "./Badge.jsx";
// import Badge from './BadgeCarousel.jsx';
import Profile from "./Profile.jsx";
import PersonIcon from '@material-ui/icons/Person';
import Bundles from './Bundles.jsx';
import WatchnowDash from "./WatchnowDash.jsx";
import ProjectsDash from "./ProjectsDash.jsx";
import Steps from "./Steps.jsx";
import BlogsDash from "./BlogsDash.jsx";
import CircularStatic from "./CircularProgressWithLabel.jsx";
import { USER_REFFERAL } from "../../../api/vsc.js";
import axios from "axios";
import Notifications from "./Notifications.jsx";
import Drawer from '@mui/material/Drawer';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import MuiDrawer from '@mui/material/Drawer';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import EarnedCertificates from "../../EarnedCertificates/index.jsx";
import Leaderboard from "../../Leaderboard/Leaderboard";
import EqualizerIcon from '@mui/icons-material/Equalizer';
import Redeem from '../../Redeem';
import RedeemIcon from '@mui/icons-material/Redeem';
import CollectionsBookmarkIcon from '@mui/icons-material/CollectionsBookmark';
import DataCourse from '../../NewVirtualCampusStartup/Program/VideoPlayer/Courses/DataCourse';
import DropDown from '../DropDown';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Fade from '@mui/material/Fade';
import { GET_DATA } from "../../../api/watchnow.js";
import { GET_BLOGS } from "../../../api/blog.js";
import { GET_CASE_STUDIES } from "../../../api/casestudy.js";
import Cart from '../../Navbar/cart.jsx';
import Logo from '../Images/logo.png';
import CampusAmbassador from '../../CampusAmbassador';
import SchoolIcon from '@mui/icons-material/School';
import Refferal from '../../Refferal';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(2),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: open && `${drawerWidth-20}px`, 
  width: '100%', 
  [theme.breakpoints.down('sm')]: {
    marginLeft: '0',
  },
  display:'flex',
  flexDirection:'column',
  justifyContent:'center',
  alignItems:'center',
  marginTop:'0'

}));


const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}))
// import { styled, useTheme } from '@mui/material/styles';

export default function SideDrawer({isAuth}) {
  const theme = useTheme();
  const [clicked, setClicked] = React.useState(0);
  const isMobile = useMediaQuery('(max-width:995px)');
  const isSMobile = useMediaQuery('(max-width:725px)');
  const isNMobile = useMediaQuery('(max-width:1300px)');
  const n = window.localStorage.getItem("name");
  const [badges , setBadges] = React.useState(0);
  const [streak , setStreak] = React.useState(0);
  const [profileImg , setProfileImg] = React.useState('');
  const [open, setOpen] = React.useState(isMobile?false:true);
  const [refresh , setRefresh] = React.useState(false);
  const [points , setPoints] = React.useState(0);
  const [completed , setCompleted] = React.useState([]);
  const [watchnows  , setWatchnows] = React.useState([]);
  const [user , setUser] = React.useState({});
  const drawerWidth = 240;
  const navigate = useNavigate();
  const location = useLocation();
  const setUrl = (url) => {
    navigate(`?url=${url}`);
  };

  const getUrlQueryParam = () => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get('url');
  };
  const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
  });
  
  const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
      width: `calc(${theme.spacing(8)} + 1px)`,
    },
  });
  
  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  }));
  
  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
  })(({ theme, open }) => ({
    zIndex: !open&&theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));
  
  const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      boxSizing: 'border-box',
      ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
         zIndex: '99999999',
      }),
      ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
      }),
    }),
  );
  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
}
React.useEffect(() => {
  if (!getUrlQueryParam()) {
      setUrl("dashboard");
  }
  components.map((component, index) => {
    if(component.url === getUrlQueryParam()){
      setClicked(index);
    }
  })

  axios.get(USER_REFFERAL + window.localStorage.id)
  .then((res) => {
    setBadges(res.data.data.completedBadges.length);
    setProfileImg(res.data.data.profileImg);
    setPoints(res.data.data.markoknowPoints);
    setUser(res.data.data);
    const completedWatchnowsIds = res.data.data.completedWatchnow;
    const completedBlogsIds = res.data.data.completedBlog;
    const completedCaseStudyIds = res.data.data.completedCaseStudy;

    axios.get(GET_BLOGS, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('token')}`,
      },
    })
    .then(blogRes => {
      const completedBlogs = blogRes.data.filter(blog => completedBlogsIds.includes(blog._id)).map(blog => ({
        title: blog.title,
        tag: 'Blog',
        id: blog._id 
      }));
      console.log(completedBlogs, completedBlogsIds);
      axios.get(GET_DATA, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      })
      .then(watchnowRes => {
        setWatchnows(watchnowRes.data);
        const completedWatchnows = watchnowRes.data.filter(watchnow => completedWatchnowsIds.includes(watchnow._id)).map(watchnow => ({
          title: watchnow.title,
          link: watchnow.link,
          tag: 'Watchnow',
          id: watchnow._id 
        }));

        axios.get(GET_CASE_STUDIES)
        .then(caseStudyRes => {
          const completedCases = caseStudyRes.data.data.filter(caseStudy => completedCaseStudyIds.includes(caseStudy._id)).map(c => ({
            title: c.title,
            tag: "Case Study",
            id: c._id
          }));

          const combinedCompleted = shuffleArray([...completedBlogs, ...completedWatchnows, ...completedCases]);
          setCompleted(combinedCompleted);
        })
        .catch(error => {
          console.error('Error fetching Cases data:', error);
        });
      })
      .catch(error => {
        console.error('Error fetching watchnow data:', error);
      });
    })
    .catch(error => {
      console.error('Error fetching blog data:', error);
    });
  })
  .catch(error => {
    console.error('Error fetching user referral data:', error);
  });

},[getUrlQueryParam()]);


  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box >
      <CssBaseline />
      {/* <div style={{width:'100%'}}> */}
        <AppBar position="fixed" open={open} style={{ backgroundColor: '#F7F7F7' }}>
          <Toolbar
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: !open?'space-between':'flex-end',
            }}
          >
            <IconButton
              color="#000"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{ mr: 2, ...(open && { display: "none" }) }}
            >
              <MenuIcon />
            </IconButton>
             <div className='dashboard_navbar'>
             <div 
              style={{
                        background:'#F0F0F0',
                        borderRadius:'10px',
                        padding:'10px',
                        boxShadow: '2px 2px 5px #6A58C6',
                        margin:'1.5%',
                        whiteSpace: 'nowrap',
                        display:'flex',
                        alignItems:'center',
                        justifyContent:'space-between',
                        color:'#000',
                        // padding: '20px'
                    }}>
              <WhatshotIcon/> {streak}
             </div>
             <div style={{margin:'1%'}}>
             {/* <CircularStatic value={badges}/> */}
             <Cart/>
             </div>
                    <div 
                    style={{
                        background:'#6A58C6',
                        borderRadius:'10px',
                        padding:'2px',
                        boxShadow: '2px 2px 5px #6A58C6',
                        margin:'1.5%',
                        whiteSpace: 'nowrap',
                        cursor: 'pointer'
                    }}
                    onClick={(e) => {
                      setUrl('redeem');
                    }}
                    >
                    <p style={{color:'#fff' , textAlign:'center' , fontSize:'12px' , margin:'0.23%'}}>Markoknow Points</p>
                    <h2 style={{color:'#fff', textAlign:'center' , fontSize:'19px' , fontWeight:'900'}}>{points}</h2>
                    </div>
                    <Tooltip title="Notifications">
                      <Notifications/>
                    </Tooltip>
                    <div>
                    <DropDown profileImg={profileImg} n={n} setUrl={setUrl}/>
                    
                    </div>

                </div> 
          </Toolbar>
        </AppBar>
      {/* </div> */}
      <Drawer
        // sx={{
        //   width: drawerWidth,
        //   flexShrink: 0,
        //   "& .Drawer-paper": {
        //     width: drawerWidth,
        //     boxSizing: "border-box",
        //   },
        // }}
        variant="permanent" 
        open={open}
      >
        <DrawerHeader sx={{display: 'flex' , justifyContent: 'space-between'}}>
        <img 
            src={Logo} 
            alt="logo" 
            style={{
              width: '80%',
              height: 80
            }} 
            />
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {components.map((text, index) => (
            <ListItem key={text.name} disablePadding style={{width:'100%' ,  display:'flex',
                justifyContent:'center',
                alignItems: 'center',}}>
            <div style={{
                // boxShadow: clicked === index && 'rgb(38, 57, 77) 0px 20px 30px -10px',
               
                width: '90%', 
               }}>
              <ListItemButton
                onClick={() => {
                  setUrl(text.url);
                  setClicked(index);
                  isMobile && handleDrawerClose()
                }}
                style={{
                  boxShadow: clicked === index && 'rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px',
                  // boxShadow: clicked === index && '#6A58C6 0px 20px 30px -10px',
                  background: clicked === index && "#6A58C6",
                  
                  color:clicked === index&&'#fff',
                  borderRadius: "10px",
                }}
              >
              
                <ListItemIcon>
                  {index === 0 && <DashboardIcon style={{color:clicked === index&&'#fff',}}/>}
                  {index === 1 && <AssignmentIcon style={{color:clicked === index&&'#fff',}}/>}
                  {index === 2 && <CardMembershipIcon style={{color:clicked === index&&'#fff',}}/>}
                  {index === 3 && <CollectionsBookmarkIcon style={{color:clicked === index&&'#fff',}}/>}
                  {index === 4 && <CollectionsBookmarkIcon style={{color:clicked === index&&'#fff',}}/>}
                  {index === 5 && <EqualizerIcon style={{color:clicked === index&&'#fff',}}/>}
                  {index === 6 && <RedeemIcon style={{color:clicked === index&&'#fff',}} /> }
                  {index === 7 && <SchoolIcon style={{color:clicked === index&&'#fff',}} /> }
                  {index === 8 && <PersonIcon style={{color:clicked === index&&'#fff',}}/>}
                  {index === 9 && <CurrencyExchangeIcon style={{color:clicked === index&&'#fff',}}/>}
                </ListItemIcon>
              
                <ListItemText primary={text.name} sx={{ whiteSpace: 'normal' }}/>
              </ListItemButton>
              </div> 
            </ListItem>
          ))}
        </List>
      </Drawer>
      <Main open={open} style={{width:!isSMobile&&open&&'85%' , paddingLeft: !open&&'60px'}} >
       <DrawerHeader/>
        {(getUrlQueryParam() === "dashboard") && (
          <>
            <div
              style={{ display: "flex", flexDirection: isSMobile?"column":"row", width: "100%" , justifyContent:'center' }}
            >
              <div style={{ width: isSMobile?"100%":"60%" , display:'flex' , flexDirection:'column' , padding:'1%' , paddingTop:'0'}}>
                <Alert user={user}/>
                <Chart />
                <NewCourse isAuth={isAuth} setUrl={setUrl}/>
                <CaseStudy isAuth={isAuth}/>

                {/* <Course isAuth={isAuth}/> */}
              </div>
              <div style={{ width: isSMobile?"100%":"40%" ,  display:'flex' , flexDirection:'column', padding:'1%', paddingTop:'0'}}>
              <div style={{display:'flex' , flexDirection:isSMobile?'column':'row' , justifyContent:'space-between' , width: '100%'}}>
              <Calender refresh={setRefresh}/>
              </div>
                <Badge />
                <Timeline />

              </div>
          
           
            
            </div>
            <div style={{ display:'flex' , flexDirection:'column' , padding:'1%'}}>
              <Bundles isAuth={isAuth}/>
              
            </div>
            <div style={{ width:'100%', padding:'1%'}}>
            <WatchnowDash/>
            </div>
            <div style={{ width:'100%', padding:'1%'}}>
            <BlogsDash/>
            </div>
            
          </>
        )}
        {getUrlQueryParam()=== "profile" && (
          <Profile/>
        )}
        {getUrlQueryParam() === "resources" && (
              <div style={{display:'flex' , flexDirection:'column' , width:'100%'}} >
        <h1 style={{textAlign:"center"}}>Free Courses</h1>
        <div className="cards" >
          <NavLink to="/blog" className="card">
            <img src={blogPic} alt="" width="250px" height="250px" />
            Insights</NavLink>
          <NavLink to="/watch_now" className="card">
            <img src={streamingPic} alt="" />
            Streaming</NavLink>
            
          <NavLink to="/projectLogin" className="card" >
            <img src={caseStudyPic} alt=""  width="250px" height="250px"/>
            Startup Cases</NavLink>
        </div>
        <Box
          sx={{
            marginTop: '50px',
            width: {xs: '100%' ,sm:'90%'},
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
        {completed.map((completed) => (
          <Accordion
          onClick={(e) => {
            if(completed.tag === 'Blog'){
              navigate('/blog/' + completed.id)
            }
            else if(completed.tag === 'Case Study'){
              navigate('/casestudy/' + completed.id)
            }
            else if(completed.tag === 'Watchnow'){
              navigate('/watch_now/watch',  { state: { link: completed?.link, id: completed?._id, watchnow: watchnows }})
            }
          }}
          sx={{
            width: '90%',
          }}
      >
        <AccordionSummary
          sx={{
            display:'flex' , 
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Typography sx={{width: '90%'}}>{completed.title}</Typography>
          <Chip sx={{background: '#FFBD59' , color:'#000'}} label={completed.tag} />
        </AccordionSummary>
      </Accordion>
        ))}
        
        </Box>
        </div>
        )}
        {getUrlQueryParam() === "earnedcertificates" && (
              <EarnedCertificates from="d"/>
        )}
        {getUrlQueryParam() === "leaderboard" && (
              <Leaderboard />
        )}
        {getUrlQueryParam() === "redeem" && (
          <Box sx={{width: '90%'}}>
          <Redeem />
          </Box>
        )}
        {getUrlQueryParam() === "courses" && (
              <DataCourse setUrl={setUrl}/>
        )}
        {getUrlQueryParam() === "allcourses" && (
              <Courses isAuth={true} from={'dash'}/>

        )}
        {getUrlQueryParam() === "campusambassador" && (
              <CampusAmbassador from="dash"/>

        )}
        {getUrlQueryParam() === "refferal" && (
              <Refferal/>

        )}
      </Main>
    </Box>
  );
}
