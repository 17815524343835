import React , {useState,useEffect} from 'react';
import { Box,Typography } from "@material-ui/core";
import axios from 'axios';
import { GET_BLOGS } from '../../../api/blog';
import BlogsCarousel from '../../NewVirtualCampusStartup/BlogCarousel';

const BlogsDash = () => {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    axios
      .get(GET_BLOGS, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        const showVsvTrueElements = res.data.filter((item) => item.showVsc);
        const showVsvFalseElements = res.data.filter((item) => !item.showVsc);
        const updatedData = showVsvTrueElements.slice(0, 4).concat(showVsvFalseElements);
        setBlogs(updatedData);
      })
      .catch((err) => {
      });
  }, []);

  return (
    <div style={{marginTop:'50px' , marginBottom:'0'}}>
      <Box display="flex" justifyContent="center">
            <Typography variant="h4" fontWeight="800" style={{color:'#000'}}>
              InSights
            </Typography>
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            marginTop="10px"
          >
            <Typography variant="h6" style={{color:'#000'}}>
              Go across with diving deep into the stories and journey's of
              various startups from bootstrapped till funded
            </Typography>
          </Box>
          <Box
            // style={{marginTop:'30px'}}
          >
            <BlogsCarousel blogs = {blogs}/>
          </Box>
      </div>
  )
}

export default BlogsDash