import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { MenuItems } from "./MenuItems";
import "./Navbar.css";
import NavbarTopDropdown from "./NavbarTopDropdown";
import NotificationBar from "./NotificationBar";
import Cart from './cart';
import { useMediaQuery } from "@mui/material";
import { GET_ANNOUNCEMENT } from "../../api/announcement";
import Axios from 'axios';
import Logo from './Images/Logo.png';
import EventNotifications from '../Event/EventNotifications';
import blogPic from '../auth/Images/blog.png'
import caseStudyPic from '../auth/Images/case-study-3.png'
import streamingPic from '../auth/Images/streaming-4.png'
import { NavLink , useNavigate  } from "react-router-dom";

const Navbar = ({ isAuth, setAuth }) => {
  const [clicked, setClicked] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [item3Hovered, setItem3Hovered] = useState(false); // Add state for MenuItem 3 hover
  const isSMobile = useMediaQuery('(max-width: 820px)');
  const isMobile = useMediaQuery('(max-width: 1200px)');
  const [closed, setClosed] = useState(false);
  const [show, setShow] = useState(true);
  const [item3Open, setItem3Open] = useState(false); // State for MenuItem 3 dropdown
  const location = useLocation();

  useEffect(() => {
    Axios.get(GET_ANNOUNCEMENT).then((response) => {
      if (response.status !== 200) {
        setClosed(true);
        setShow(false);
      }
    }).catch(err => {
      setClosed(true);
      setShow(false);
    });
  }, []);

  const handleClick = () => {
    setClicked(!clicked);
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const toggleItem3Dropdown = () => {
    setItem3Open(!item3Open);
  };

  return (
    <>
      <nav className={"NavbarItems"} style={{ height: (!closed && location.pathname === '/') || (!closed && location.pathname === '/events') ? (isSMobile ? '170px' : '130px') : '90px' }}>
        {location.pathname === '/' && show && <div style={{ width: '100%' }}><NotificationBar setClosed={setClosed} /></div>}
        {location.pathname === '/events' && show && <div style={{ width: '100%' }}><EventNotifications setClosed={setClosed} /></div>}
        <div className={clicked ? "NavbarItems2" : "NavbarItems1"} style={{ top: (!closed && location.pathname === '/') || (!closed && location.pathname === '/events') ? (isSMobile ? '80px' : '40px') : '0px' }}>
          <Link to="/">
            <img src={Logo} alt="logo" style={{ width: 80, height: 80 }} />
          </Link>
          <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <div className="menu-icons" onClick={handleClick}>
              <div>
                {clicked ? (
                  <CloseIcon style={{ height: "2rem", width: "2rem", marginTop: "0.3rem", borderRadius: "50%" }} />
                ) : (
                  <MenuIcon style={{ height: "2rem", width: "2rem", marginTop: "0.3rem", borderRadius: "50%" }} />
                )}
              </div>
            </div>
            <div className="navbarTopDropdown">
              <Cart />
              <NavbarTopDropdown />
            </div>
          </div>
          <ul className={clicked ? "nav-menu active" : "nav-menu"}>
          <li className="nav-item"
    onMouseEnter={toggleDropdown}
>
  <div className="nav-links" 
       style={{ display: 'flex', alignItems: 'center', justifyContent: isSMobile && 'center' }}>
    {MenuItems[0].title}{dropdownOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
  </div>
  {dropdownOpen && (
    <ul className="dropdown-menu active"
        onMouseLeave={toggleDropdown}  // Close dropdown only when leaving the entire dropdown area
    >
      <li><a href="/financialanalyst" className="dropdown-link">Finance Trainings</a></li>
      <li><a href="/productmanagement" className="dropdown-link">Product Trainings</a></li>
      <li><a href="/managementconsulting" className="dropdown-link">Consulting Trainings</a></li>
      <li><a href="/corporateexposureprogram" className="dropdown-link">Management Trainings</a></li>
    </ul>
  )}
</li>


            {MenuItems.slice(1, 2).map((item, index) => (
              <li key={index}>
                <a className={item.cName} href={item.url} target={item.blank}>
                  {item.title}
                </a>
              </li>
            ))}
            <li
              className="nav-item"
              onMouseEnter={toggleItem3Dropdown}  // Show dropdown on hover
              onMouseLeave={toggleItem3Dropdown}  // Hide dropdown when not hovered
            >
              <a className="nav-links" href="#">
                {MenuItems[2].title} {/* MenuItem 3 */}
              </a>
              {item3Open && (
                <div className="dropdown-box">
                  {/* <div className="dropdown-content">
                    <div className="dropdown-item">
                      <img src="path/to/image1.png" alt="Image 1" />
                      <p>Text for Image 1</p>
                    </div>
                    <div className="dropdown-item">
                      <img src="path/to/image2.png" alt="Image 2" />
                      <p>Text for Image 2</p>
                    </div>
                    <div className="dropdown-item">
                      <img src="path/to/image3.png" alt="Image 3" />
                      <p>Text for Image 3</p>
                    </div>
                  </div> */}
                  <div className="cards" style={{gap: '10px' , flexDirection: isMobile ? 'row' : 'row'}}>
                      <NavLink to="/blog" className="card" style={{width: '100px' , height:'100px' , fontSize: '12px'}}>
                        <img src={blogPic} alt="" width="40px" height="40px" />
                        Insights</NavLink>
                      <NavLink to="/watch_now" className="card" style={{width: '100px' , height:'100px', fontSize: '12px'}}>
                        <img src={streamingPic} alt="" width="40px" height="40px"/>
                        Streaming</NavLink>
                        
                      <NavLink to="/projectLogin" className="card" style={{width: '100px' , height:'100px', fontSize: '12px'}}>
                        <img src={caseStudyPic} alt=""  width="40px" height="40px"/>
                        Startup Cases</NavLink>
                    </div>
                </div>
              )}
            </li>
            {MenuItems.slice(3).map((item, index) => (
              <li key={index}>
                <a className={item.cName} href={item.url} target={item.blank}>
                  {item.title}
                </a>
              </li>
            ))}
            {!isAuth && (
              <a className="nav-links-mobile-vsc" href="/login" style={{ color: '#fff' }}>
                Login
              </a>
            )}
            <div className="NavbarTopDropdown">
              <Cart />
              <NavbarTopDropdown />
            </div>
          </ul>
        </div>
      </nav>
    </>
  );
};


export default Navbar;
