import React from "react";
import axios from "axios";
import GoogleLogin from "react-google-login";
import { googleClientId } from "../../api/client";
import { useNavigate , useLocation} from "react-router-dom";

// require("dotenv").config();
// const DOMAIN = process.env.REACT_APP_DOMAIN;

function LoginwithGoogle({ PriorityAnswer, setIsAuth }) {
  const { GOGGLE_LOGIN_API, EMAIL_SUGGESTION } = require("../../api/vsc");
  //gogole login success handler
  // const history = useHistory();
  const location = useLocation();
  const redirectPath = location?.state?.redirect || '/';
  const params = new URLSearchParams(window.location.search).get("referrer");
  const navigation = useNavigate();
  const responseSuccessGoogle = (response) => {
    axios
      .post(GOGGLE_LOGIN_API, { tokenId: response.tokenId , refferer: params })
      .then((response) => {
        window.localStorage.setItem("name", response.data.user.name);
        window.localStorage.setItem("fullName", response.data.user.fullName);
        window.localStorage.setItem("email", response.data.user.email);
        window.localStorage.setItem("id", response.data.user._id);
        window.localStorage.setItem("token", response.data.token);
        window.localStorage.setItem("isAuth", true);
        window.localStorage.setItem(
          "DontAskAgain",
          response.data.user.DontAskAgain
        );
        window.localStorage.setItem("isAdmin", response.data.user.isAdmin);
        window.localStorage.setItem(
          "isApplyExperienceFilled",
          response.data.user.isApplyExperienceFilled
        );
        window.localStorage.setItem(
          "point",
          response.data.user.markoknowPoints
        );
        window.localStorage.setItem(
          "eventNumbers",
          JSON.stringify(response.data.user.eventNumber)
        );
        window.localStorage.setItem(
          "referralId",
          response.data.user.referralId
        );
        setIsAuth(true);
        sendAnswer();
        navigation(redirectPath);
        window.location.reload();
        // history.push("/");
      })
      .catch((error) => {
      });
  };

  //send priority answer to email on succes login of user
  const sendAnswer = () => {
    if (PriorityAnswer !== undefined) {
      axios
        .post(
          EMAIL_SUGGESTION,
          { description: PriorityAnswer },
          { headers: { authorization: window.localStorage.getItem("token") } }
        )
        .then((res) => {
        })
        .catch((err) => {
        });
    } else {
    }
  };

  //gogole login error handler
  const responseErrorGoogle = (response) => {
  };

  return (
    <GoogleLogin
      clientId={`${googleClientId}`}
      buttonText="Login with Google"
      onSuccess={responseSuccessGoogle}
      onFailure={responseErrorGoogle}
      cookiePolicy={"single_host_origin"}
    />
  );
}

export default LoginwithGoogle;
