import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import { useNavigate , useLocation} from "react-router-dom";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Typewriter from "typewriter-effect";
import "../../stylesheets/signupform.css";
import { validateEmail } from "./validateEmail";
import LoginwithGoogle from './LoginwithGoogle';
import axios from "axios";
import { TextField, InputAdornment, IconButton } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import usePasswordValidator from "./passwordutils";
import { Link } from "react-router-dom";
import { set } from "react-ga";
import { makeStyles } from "@mui/styles";
import Modal from "@material-ui/core/Modal";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 250,
    background: '#fff',
    padding: '5%',
    border: "2px solid #000",
    margin: 'auto',
  },
}));

const Signup = ({ isAuth, setIsAuth }) => {
  const location = useLocation();
  const redirectPath = location?.state?.redirect || '/';
  const history = useNavigate();
  if (isAuth) {
    history(redirectPath);
    // history.push("/profile/" + window.localStorage.getItem("id"));
  }
  if (isAuth) {
    history("/");
  }

  function rand() {
    return Math.round(Math.random() * 20) - 10;
  }

  function getModalStyle() {
    const top = 50 + rand();
    const left = 50 + rand();

    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  }

  const classes = useStyles();

  const { SIGN_UP_API } = require("../../api/vsc");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [password, setPassword, passwordError] = usePasswordValidator({
    min: 8,
    max: 15,
  });
  const [resp, setResp] = useState("");
  const [mess, setMess] = useState("");
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState("");
  const [numUsers, setNumUsers] = useState("");
  const params = new URLSearchParams(window.location.search).get("referrer");
  const [referralId, setReferralID] = useState(params);
  const [modalStyle] = React.useState(getModalStyle);
  const [response, setResponse] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const clearset = () => {
    setEmail("");
    setName("");
    setConfirmPassword("");
    setConfirmPasswordError("");
    setPassword("");
    setEmailError("");
    setMess("");
    setReferralID("");
  };

  var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;

  const validationForm = () => {
    if (name.length < 1) {
      return false;
    } else if (password.length < 8) {
      return false;
    } else if (format.test(password) === false) {
      return false;
    } else if (!password.match(/\d/)) {
      return false;
    } else if (confirmPassword !== password) {
      return false;
    } else {
      return true;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validationForm()) {
      const registered = {
        name: name,
        email: email,
        password: password,
        referralId: referralId,
        path: '/vsc'
      };
      const params = new URLSearchParams(window.location.search).get(
        "referrer"
      );
      axios
        .post(SIGN_UP_API, registered, { params: { referrer: params } })
        .then((response) => {
          setResp(response.data.message);
          if (
            response.data.message ===
            "Verification Email has been sent to your given email."
          ) {
            clearset();
            handleOpen();
            setSeverity("success");
            setMess(response.data.message);
            setResponse(true);
          }
        })
        .catch((err) => {
          handleOpen();
          setMess("Account already exists with given details.");
          setSeverity("error");
        });
    } else {
      handleOpen();
      setMess("Validation Error!! Please fill the form correctly");
      setSeverity("error");
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const body = (
    <div style={modalStyle} className={classes.paper}>
      {response && (
        <p id="simple-modal-description" style={{ textAlign: 'center' }}>
          Verification Email Has Been Sent To Your Account Please Verify!
        </p>
      )}
      <Link
        to="/login"
        style={{ textDecoration: "none", color: "black" }}
        className="btn-primary"
        state={{ redirect: redirectPath }}
      >
        <Button variant="Contained" style={{ background: '#6A58C6', color: '#fff', marginTop: '20px', marginLeft: '35%' }}>
          Login
        </Button>
      </Link>
    </div>
  );

  const [state] = useState({
    title: "Virtual Startup Campus",
    titleTwo: "Welcomes you",
  });

  return (
    <>
      <div className="Signup_Form_container">
        <Grid container>
          <Grid item lg={6} md={12} sm={12} xs={12}>
            <div className="left_box_type">
              <h4 className="main-heading-signup">
                <div className="titleSignup">{state.title}</div>
                <div className="titleTwoSignup">{state.titleTwo}</div>
              </h4>
            </div>
          </Grid>
          <Grid item lg={5} md={12} sm={12} xs={12}>
            <div className="right_box_type">
              <form className="signup_form" onSubmit={handleSubmit}>
                <h3>SignUp</h3>

                <div style={{ margin: "20px", textAlign: "center" }}>
                  <LoginwithGoogle setIsAuth={setIsAuth} />
                </div>

                <TextField
                  style={{ paddingBottom: "15px", width: "100%" }}
                  value={name}
                  variant="outlined"
                  onChange={(e) => setName(e.target.value)}
                  type="text"
                  label="Username"
                />

                <span className="error">{emailError}</span>
                <TextField
                  style={{ paddingBottom: "15px", width: "100%" }}
                  value={email}
                  variant="outlined"
                  onChange={(e) => setEmail(e.target.value)}
                  type="email"
                  required
                  label="Email"
                  autoComplete="off"
                />

                <span className="error">{passwordError}</span>
                <TextField
                  style={{ paddingBottom: "15px", width: "100%" }}
                  value={password}
                  variant="outlined"
                  onChange={(e) => setPassword(e.target.value)}
                  type={showPassword ? "text" : "password"}
                  required
                  label="Password"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />

                <span className="error">{confirmPasswordError}</span>
                <TextField
                  style={{ paddingBottom: "15px", width: "100%" }}
                  value={confirmPassword}
                  variant="outlined"
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  type={showConfirmPassword ? "text" : "password"}
                  label="Confirm Password"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowConfirmPassword}
                          edge="end"
                        >
                          {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />

                <TextField
                  style={{ paddingBottom: "15px", width: "100%" }}
                  value={referralId}
                  variant="outlined"
                  onChange={(e) => setReferralID(e.target.value)}
                  type="text"
                  label="Referral Id"
                />
                <div>
                  Already have an account?{" "}
                  <Link
                    exact="true"
                    to="/login"
                    style={{
                      color: "blue",
                    }}
                  >
                    Login
                  </Link>
                </div>
                <br />

                <div className="auth_button2">
                  <button
                    type="submit"
                    variant="contained"
                    className="btn-primary button_signup"
                  >
                    SignUp
                  </button>

                </div>
                <br />
                <div>
                  By clicking on SignUp, you agree to our{" "}
                  <Link
                    exact="true"
                    to="/terms_conditions"
                    style={{
                      color: "blue",
                    }}
                  >
                    Terms&Conditions
                  </Link>
                  {" "}and {" "}
                  <Link
                    exact="true"
                    to="/privacy"
                    style={{
                      color: "blue",
                    }}
                  >
                    Privacy Policy
                  </Link>
                </div>
              </form>
            </div>
          </Grid>
          <Grid lg={1} md={0}></Grid>
        </Grid>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert
            onClose={handleClose}
            severity={severity}
            sx={{ width: "100%" }}
          >
            {mess}
          </Alert>
        </Snackbar>
        <Modal
          open={response}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {body}
        </Modal>
      </div>
    </>
  );
};

export default Signup;
