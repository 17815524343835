import React, { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {VIMEO} from './embeddLinks';
import { Typography , Box} from '@mui/material';

const VideoPlayer = ({ videoUrl, videoTitle , moduleDesc}) => {
  const [videoLoading, setVideoLoading] = useState(true);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const screenWidth = window.innerWidth;
  useEffect(() => {
    // Reset the loading state when the video URL changes
    setVideoLoading(true);
  }, [videoUrl]);

  const handleVideoReady = () => {
    setVideoLoading(false);
  };

  const handleVideoError = (error) => {
    console.error('Error loading video:', error);
    setVideoLoading(false);
  };

  return (
    <div style={{
      width:'100%',
      marginTop:isMobile&&'0',
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'wrap',
      justifyContent:'center',
      alignItems: 'center',
    }}>
      {videoUrl && (
        <div>
          {videoLoading && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: isMobile ? '300px' : '500px',
              }}
            >
              <CircularProgress style={{ color: '#6A58C6' }} />
            </div>
          )}
          {videoUrl.includes('www.youtube.com/watch') && (
            <iframe
              style={{
                display: videoLoading ? 'none' : 'block' ,
                margin:!isMobile&&'auto'
              }}
              width={isMobile ? '350px' : '900px'}
              height={isMobile ? '250' : '650px'}
              src={`https://www.youtube-nocookie.com/embed/${videoUrl.substring(
                videoUrl.indexOf('=') + 1
              )}`}
              title={videoTitle}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
              onLoad={handleVideoReady}
            ></iframe>
          )}
          {videoUrl.includes('youtu.be/') && (
            <iframe
              style={{
                display: videoLoading ? 'none' : 'block' ,
                margin:!isMobile&&'auto'
              }}
              width={isMobile ? '350px' : '900px'}
              height={isMobile ? '250px' : '650px'}
              src={`https://www.youtube-nocookie.com/embed/${videoUrl.substring(
                videoUrl.lastIndexOf('/') + 1
              )}`}
              title={videoTitle}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
              onLoad={handleVideoReady}
            ></iframe>
          )}
          {videoUrl.includes('vimeo') && (
            <iframe
              width={isMobile ? '350px': '950px'}
              height={isMobile ? '250px' : '650px'}
              src={VIMEO+`${videoUrl.substring(
                videoUrl.lastIndexOf('/') + 1
              )}?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"`}
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
              title={videoTitle}
              onLoad={handleVideoReady}
              style={{
                display: videoLoading ? 'none' : 'block' ,
                margin:!isMobile&&'auto',
                borderRadius:isMobile&&'15px'
              }}
            ></iframe>
          )}
          
        </div>
        
      )}
      {!videoUrl && <p style={{ color: '#000' }}>Please select a video.</p>}
      <br/>
      {moduleDesc && (
        <Box sx={{
              background: '#6A58C6',
              boxShadow: "2px 2px 5px #6A58C6",
              color: '#fff',
              borderRadius:'10px',
              padding: '10px',

              }}>

              <Typography variant="h6" textAlign={'center'} gutterBottom>Module Description</Typography>
              <div dangerouslySetInnerHTML={{__html: moduleDesc}}/>
              </Box>
      )}
      
      
    </div>
  );
};

export default VideoPlayer;
