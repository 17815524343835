import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { TextField, Typography } from '@mui/material';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import { validateEmail } from "../../auth/validateEmail";
import { DOWNLOAD_BROCHURE_POST } from '../../../api/course';
import { IconButton , Tooltip } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const googleDriveLink = 'https://drive.google.com/uc?export=view&id=1m3rVxCSJcGUAws80wunLWfjw2LTNtLCz';
export default function ResponsiveDialog({ isMobile, courseName }) {
  const [open, setOpen] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [wNumber, setWNumber] = React.useState("");
  const [name, setName] = React.useState("");
  const [responded, setResponed] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [emailError, setEmailError] = React.useState("");
  const [contactNoError, setContactNoError] = React.useState("");
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    if (!email) {
      setEmailError("");
    } else {
      if (validateEmail(email)) {
        setEmailError("");
      } else {
        setEmailError("Please enter a valid email.");
      }
    }
  }, [email]);

  React.useEffect(() => {
    if (!wNumber) {
      setContactNoError("");
    } else {
      if (wNumber.length === 10) {
        setContactNoError("");
      } else {
        setContactNoError("Please Enter valid contactNo.");
      }
    }
  }, [wNumber]);

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = googleDriveLink;
    link.download = 'brochure.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleEmail = () => {
    setLoading(true);
    axios
      .post(DOWNLOAD_BROCHURE_POST + window.localStorage.id, { email: email, whatsappNumber: wNumber, name: name, courseName: courseName })
      .then(res => {
        setLoading(false);
        setResponed(true);
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
        setResponed(true); // Assume error means data already exists
      });
  };

  return (
    <React.Fragment >
      <div style={{ width: isMobile ? '80%' : '80%', display: 'flex', justifyContent: isMobile ? 'center' : 'flex-start' }}>
        <Button
          variant="outlined"
          onClick={handleClickOpen}
          className="button-promptl-download"
          style={{
            color: '#6a58c6',
            borderColor: '#6a58c6',
            marginBottom: '20px',
            width: '100%'
          }}
        >
          Details on the Program
        </Button>
      </div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        {loading && (
          <DialogContent>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: isMobile ? '300px' : '500px',
              }}
            >
              <CircularProgress style={{ color: '#6A58C6' }} />
            </div>
          </DialogContent>
        )}
        {!loading && responded ? (
          <>
            <DialogContent style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '20px', flexDirection: 'column' }}>
            <Typography variant="h6">You Can Contact For Details On Whatsapp</Typography>
            <Tooltip title="Support On Whatsapp">
             <Button
              href="https://wa.me/9310148556"
              startIcon={<WhatsAppIcon sx={{color: '#075E54'}}/> }
             >
                WhatsApp Chat 
             </Button>
             </Tooltip>
            </DialogContent>
            <DialogActions>
              <Button variant='contained' style={{ backgroundColor: '#6a58c6' }} autoFocus onClick={handleClose}>
                Close
              </Button>
            </DialogActions>
          </>
        ) : (
          !loading && <>
            <DialogTitle id="responsive-dialog-title">
              Download Brochure
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                To subscribe to our Brochure, please enter the details here.
              </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                id="email"
                label="Email Address"
                type="email"
                fullWidth
                variant="standard"
                onChange={(e) => setEmail(e.target.value)}
              />
              <span style={{ color: "red" }}>{emailError}</span>
              <TextField
                autoFocus
                margin="dense"
                id="wNumbers"
                label="Whatsapp Number"
                type="text"
                fullWidth
                variant="standard"
                onChange={(e) => setWNumber(e.target.value)}
              />
              <span style={{ color: "red" }}>{contactNoError}</span>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Name"
                type="text"
                fullWidth
                variant="standard"
                onChange={(e) => setName(e.target.value)}
              />
            </DialogContent>
            <DialogActions>
              <Button variant='contained' style={{ backgroundColor: '#6a58c6' }} autoFocus onClick={handleClose}>
                Close
              </Button>
              <Button variant='contained' style={{ backgroundColor: '#6a58c6' }} onClick={handleEmail} autoFocus>
                Submit
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </React.Fragment>
  );
}
