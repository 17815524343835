const { BASE_URL } = require("./client");

const POST_CERTIFICATE = BASE_URL+'/program/getcertificate';
const GET_CERTIFICATE = BASE_URL+'/viewcertificate/';
const GET_CERTIFICATES = BASE_URL+'/get/certificates';
const GET_CERTIFICATE_REQUESTED = BASE_URL + '/api/request/certificate/program/';
const GET_CERTIFICATE_ALREADY_REQUESTED = BASE_URL + '/api/request/certificate/program/already/';
const GET_APPROVED_CERTIFCATES = BASE_URL + '/api/approve/certificate/';
const GET_APPROVED_CERTIFCATES_WORKEX = BASE_URL + '/api/approve/certificate/workex/';
const POST_OPENED = BASE_URL + '/api/opened/certificate/';
const GET_CERTIFICATE_REQUESTED_WORKEX = BASE_URL + '/api/request/certificate/workex/';
const GET_CERTIFICATE_ALREADY_REQUESTED_WORKEX = BASE_URL + '/api/request/certificate/workex/already/';


module.exports = {
    POST_CERTIFICATE,
    GET_CERTIFICATE,
    GET_CERTIFICATES,
    GET_CERTIFICATE_REQUESTED,
    GET_CERTIFICATE_ALREADY_REQUESTED,
    GET_APPROVED_CERTIFCATES,
    POST_OPENED,
    GET_CERTIFICATE_REQUESTED_WORKEX,
    GET_CERTIFICATE_ALREADY_REQUESTED_WORKEX,
    GET_APPROVED_CERTIFCATES_WORKEX
}