import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import WebView from './index';
import MobileView from './MobileView';

const PageWithNavbarAndHeading = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 800); 
    };
    handleResize(); 
    window.addEventListener('resize', handleResize); 
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Box sx={{
      width: '100%'
    }}>
      {isMobile ? 
      
      (<Box sx={{width:'100%' , display:'flex'  , justifyContent:'center' , alignItems: 'center'}}><MobileView /></Box>) : (<Box sx={{width:'100%' , display:'flex'  , justifyContent:'center' , alignItems: 'center'}}><WebView /></Box>)}
    </Box>
  );
};

export default PageWithNavbarAndHeading;
