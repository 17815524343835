import "../../../stylesheets/watch-now.css";
import React, { useState, useEffect } from "react";
import { useParams, Link, NavLink, useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import Grid from "@material-ui/core/Grid";

import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { Typography, Button, Modal } from "@material-ui/core";
// import { ThemeProvider } from "@material-ui/core/styles";
import Footer from '../../Footer/Footer';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import ReadMoreAndLess from "react-read-more-less";
import axios from "axios";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Box } from "@material-ui/core";
import { styled } from "@mui/system";
import useMediaQuery from '@mui/material/useMediaQuery';
import WatchnowButtonBox from "./WatchnowButtonBox";
import StreakButtons from "../Learn-Now/Components/StreakButtons";
import Alert from "../MessagePopup";
import DeleteModal from "../DeleteModal";
import Media from './Media';
import Dummy from '../Images/bmc.png'
import {
  DELETE_DATA,
  GET_COMPLETE_VIDEOS,
  GET_DATA,
  POSTER,
} from "../../../api/watchnow";

// const theme = createTheme();
function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
const BootstrapButton = styled(Button)({
  boxShadow: "none",
  borderRadius: "0 0 0.5rem 0.5rem", // Adjusted to make the bottom right corner rounded
  padding: "6px 12px",
  backgroundColor: "#6a58c6",
  borderColor: "#52efda",
  color: "#FFFFFF",
  fontFamily:
    "Open Sans",
  fontWeight: "600",
  fontSize: "18px",
  lineHeight: "27px",
  textTransform: "uppercase",
  cursor: "pointer",
  width: "100%",
  borderRadius: "0 0 0 0.5rem",
  height: "60px",
  fontFamily: [
    "-apple-system",
    "BlinkMacSystemFont",
    '"Segoe UI"',
    "Roboto",
    '"Helvetica Neue"',
    "Arial",
    "sans-serif",
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(","),
  "&:hover": {
    backgroundColor: "#6a58c6",
    borderColor: "#8dffcc",
    boxShadow: "none",
  },
  "&:active": {
    boxShadow: "none",
    backgroundColor: "#6a58c6",
    borderColor: "#8dffcc",
  },
  "&:focus": {
    boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
  },
});
const useStyles = makeStyles({
  paper: {
    position: "absolute",
    width: 350,
    textAlign: "center",
    // backgroundColor: theme.palette.background.paper,
    // boxShadow: theme.shadows[5],
    // padding: theme.spacing(2, 4, 3),
    backgroundColor: '#fff',
    padding: '2%',
    ["@media only screen and (max-width: 420px)"]: {
      width: 220,
      marginLeft: 0, // secondary
    },
  },
  btnbox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    //backgroundColor:'yellow',
    width: "75%",
    marginLeft: "5%",
    "@media only screen and (max-width: 420px)": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      //backgroundColor:'yellow',
      flexDirection: "column",
    },
  },
  btnleftbox: {
    display: "flex",
    flex: "6",
    justifyContent: "flex-start",
    "@media only screen and (max-width: 420px)": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginLeft: "15%",
    },
  },
  btnrightbox: {
    display: "flex",
    flex: "6",
    justifyContent: "flex-end",
    "@media only screen and (max-width: 420px)": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginTop: "10px",
      marginLeft: "15%",
    },
  },
  wrapper: {
    display: "flex",
    // border: "2px solid red",
    backgroundColor: "white",
    alignItems: "center",
    justifyContent: "center",
    color: "white",
    //background-image: linear-gradient(to right,#8dffcc,#57F0D8 ),
    flexDirection: "column",
    //backgroundColor:'#6A58c6',
    // backgroundColor: "#221857",
    marginTop: '100px',
    "@media only screen and (max-width: 420px)": {
      fontSize: "15px",
      textAlign: "center",
    },
  },
  heading1: {
    fontSize: "55px",
    marginTop: "30px",
    width: "75%",
    /* border:1px solid red; */
    // height: "124px",
    padding: " 20px",
    fontWeight: "700",
    lineHeight: "62px",
    fontFamily: "Poppins, sans-serif",
    textAlign: "center",
    color: "#1a202c",
  },
  heading2: {
    fontSize: "1.9rem",
    margin: "5px",
    textAlign: "center",
    color: "#1a202c",
  },
  subbtnbox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  btn: {
    margin: "10px",
    color: "#1a202c",
    border: "1px solid black",
  },
  selbtn: {
    margin: "10px",
    backgroundColor: "#6a58c6",
    color: "white",
    fontSize: "15px",
    fontWeight: "500",

    "&:hover": {
      backgroundColor: "#6a58c6",
      color: "white",
    },
  },
});

function WatchNow({ isAuth, setIsAuth }) {
  const isMobile = useMediaQuery('(max-width:650px)');
  // const navigate = useNavigate();
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [requestStart, setRequestStart] = useState(true);
  const [watchnow, setWatchnow] = useState({});
  const [streaks, setStreaks] = useState([]);
  const [completedlist, setCompletedlist] = useState([]);
  //alert
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const handleAlertClose = () => setOpenAlert(false);

  //delete modal
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  //tags
  const [all, setAll] = useState(true);
  const [startups, setStartups] = useState(false);
  const [marketing, setMarketing] = useState(false);
  const [finance, setFinance] = useState(false);
  const [funding, setFunding] = useState(false);
  const [founders, setFounders] = useState(false);
  const [techD, setTechandD] = useState(false);
  const [others, setOthers] = useState(false);
  //button styling
  const [allbtnstyle, setAllbtnstyle] = useState({ margin: "10px", backgroundColor: "#6a58c6", color: "white", fontSize: "15px", fontWeight: "500", "&:hover": { backgroundColor: "#6a58c6", color: "white", }, });
  const [starbtnstyle, setStarbtnstyle] = useState({ margin: "10px", color: "#1a202c", border: "1px solid black", });
  const [marbtnstyle, setMarbtnstyle] = useState({ margin: "10px", color: "#1a202c", border: "1px solid black", });
  const [finbtnstyle, setFinbtnstyle] = useState({ margin: "10px", color: "#1a202c", border: "1px solid black", });
  const [funbtnstyle, setFunbtnstyle] = useState({ margin: "10px", color: "#1a202c", border: "1px solid black", });
  const [foubtnstyle, setFoubtnstyle] = useState({ margin: "10px", color: "#1a202c", border: "1px solid black", });
  const [tecbtnstyle, setTecbtnstyle] = useState({ margin: "10px", color: "#1a202c", border: "1px solid black", });
  const [othbtnstyle, setOthbtnstyle] = useState({ margin: "10px", color: "#1a202c", border: "1px solid black", });
  const [randomWatchNow , setRandomWatchNow] = useState({});
  const [show, setShow] = useState(false);
  const [clickedVideoId, setClickedVideoId] = useState(null);
  const [watchnowId, setWatchnowId] = useState(null);
  const DOMAIN = process.env.REACT_APP_DOMAIN;

  const handleIncreaseViews = async (watchnowId) => {
    try {
      const response = await axios.post(`${DOMAIN}/watchnow/increase-views/${watchnowId}`);

      // Check if the response status is within the success range (2xx status codes)
      if (response.status >= 200 && response.status < 300) {
        console.log("success");
        // Handle the response data as needed, for example:
        // const responseData = response.data;
      } else {
        // Handle non-successful response (optional)
        console.error(`Request failed with status ${response.status}`);
      }
    } catch (error) {
      // Handle errors more gracefully, e.g., display a user-friendly error message
      console.error("An error occurred:", error.message);
    }
  };



  //tag functions
  const All = () => {
    all ? setAll(false) : setAll(true);
    all ? setAllbtnstyle({ margin: "10px", color: "#1a202c", border: "1px solid black", }) : setAllbtnstyle({ margin: "10px", backgroundColor: "#6a58c6", color: "white", fontSize: "15px", fontWeight: "500", "&:hover": { backgroundColor: "#6a58c6", color: "white", }, });
  };
  const Startups = () => {
    startups ? setStartups(false) : setStartups(true);
    startups ? setStarbtnstyle({ margin: "10px", color: "#1a202c", border: "1px solid black", }) : setStarbtnstyle({ margin: "10px", backgroundColor: "#6a58c6", color: "white", fontSize: "15px", fontWeight: "500", "&:hover": { backgroundColor: "#6a58c6", color: "white", }, });
  };
  const Marketing = () => {
    marketing ? setMarketing(false) : setMarketing(true);
    marketing ? setMarbtnstyle({ margin: "10px", color: "#1a202c", border: "1px solid black", }) : setMarbtnstyle({ margin: "10px", backgroundColor: "#6a58c6", color: "white", fontSize: "15px", fontWeight: "500", "&:hover": { backgroundColor: "#6a58c6", color: "white", }, });
  };
  const Finance = () => {
    finance ? setFinance(false) : setFinance(true);
    finance ? setFinbtnstyle({ margin: "10px", color: "#1a202c", border: "1px solid black", }) : setFinbtnstyle({ margin: "10px", backgroundColor: "#6a58c6", color: "white", fontSize: "15px", fontWeight: "500", "&:hover": { backgroundColor: "#6a58c6", color: "white", }, });
  };
  const Funding = () => {
    funding ? setFunding(false) : setFunding(true);
    funding ? setFunbtnstyle({ margin: "10px", color: "#1a202c", border: "1px solid black", }) : setFunbtnstyle({ margin: "10px", backgroundColor: "#6a58c6", color: "white", fontSize: "15px", fontWeight: "500", "&:hover": { backgroundColor: "#6a58c6", color: "white", }, });
  };
  const Founders = () => {
    founders ? setFounders(false) : setFounders(true);
    founders ? setFoubtnstyle({ margin: "10px", color: "#1a202c", border: "1px solid black", }) : setFoubtnstyle({ margin: "10px", backgroundColor: "#6a58c6", color: "white", fontSize: "15px", fontWeight: "500", "&:hover": { backgroundColor: "#6a58c6", color: "white", }, });
  };
  const Tech = () => {
    techD ? setTechandD(false) : setTechandD(true);
    techD ? setTecbtnstyle({ margin: "10px", color: "#1a202c", border: "1px solid black", }) : setTecbtnstyle({ margin: "10px", backgroundColor: "#6a58c6", color: "white", fontSize: "15px", fontWeight: "500", "&:hover": { backgroundColor: "#6a58c6", color: "white", }, });
  };
  const Others = () => {
    others ? setOthers(false) : setOthers(true);
    others ? setOthbtnstyle({ margin: "10px", color: "#1a202c", border: "1px solid black", }) : setOthbtnstyle({ margin: "10px", backgroundColor: "#6a58c6", color: "white", fontSize: "15px", fontWeight: "500", "&:hover": { backgroundColor: "#6a58c6", color: "white", }, });
  };
  const navigate = useNavigate();
  useEffect(() => {
    axios
      .get(GET_DATA, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        const randomIndex = Math.floor(Math.random() * res.data.length);
        setRandomWatchNow(res.data[randomIndex]);
        setData(res.data);
      });
  }, [requestStart]);

  const deleteHandler = () => {
    axios
      .delete(DELETE_DATA + watchnow._id, {
        headers: { authorization: window.localStorage.getItem("token") },
      })
      .then((res) => {
        setAlertMessage("Successfully watchnow deleted!");
        setOpenAlert(true);
        setOpenDeleteModal(false);
        setRequestStart(false);
      })
      .catch((err) => {
        if (err) {
          setAlertMessage(err.response?.data.Error);
          setOpenAlert(true);
          setRequestStart(false);
        }
      });
  };


  const GetCompletedVideos = () => {
    axios
      .get(GET_COMPLETE_VIDEOS, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setCompletedlist(res.data);
      })
      .catch((err) => {
      });
  };

  useEffect(() => {
    GetCompletedVideos();
  }, []);

  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);
  const isAuthUser = isAuth;

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {


    setOpen(false);
  };

  const body = (
    <div style={modalStyle}
      className={classes.paper}

    >
      <h2 id="simple-modal-title">You are not Logged In</h2>
      <p className="simple-modal-desc" id="simple-modal-description">
        TO VIEW THIS PAGE, YOU NEED TO BE LOGGED IN TO THE SITE
      </p>
      <div className="buttons">

        <Button
          variant="contained"
          style={{
            width: '20%',
            margin: '10px auto auto auto',
            background: '#6a58c6',
            color: '#fff'
          }}
          onClick={(e) => { navigate('/login', { state: { redirect: '/watch_now' } }); }}
        >Login</Button>
      </div>
    </div>
  );

  React.useEffect(() => {
    if (isAuthUser) {

      handleClose();
    }
  });
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
      <div
        style={{
          backgroundColor: "white",
          height: "120%",
          paddingBottom: "50px",
        }}
      >
        <Alert
          open={openAlert}
          handleAlertClose={handleAlertClose}
          message={alertMessage}
        />
        <DeleteModal
          open={openDeleteModal}
          setOpen={setOpenDeleteModal}
          deleteHandler={deleteHandler}
        />
        <Box
          // className={classes.wrapper} 
          style={{
            boxBorder: "10",
            display: "flex",
            backgroundColor: "white",
            alignItems: "center",
            justifyContent: "center",
            color: "white",
            flexDirection: "column",
            marginTop: '80px',
            "@media only screen and (max-width: 420px)": {
              fontSize: "15px",
              textAlign: "center",
            },
          }}


        >
          {/* <div style={{
             display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              height: '450px',
              marginTop: '20px',
              padding: '30px 0px',
          }}> */}
          <Typography
            // className={classes.heading1}
            style={{
              borderRadius: "20px", // Adjust the value based on the desired curvature
              fontSize: "45px",
              width: "75%",
              padding: "20px",
              fontWeight: "700",
              lineHeight: "62px",
              fontFamily: "Poppins, sans-serif",
              textAlign: "center",
              color: "#1a202c",
            }}

          >Watch Now</Typography>
          <Typography
            // className={classes.heading2}
            style={{
              fontSize: "25px",
              width: "75%",
              padding: "20px",
              margin: 'auto',
              // fontWeight: "700",
              marginTop: "-2rem",
              fontFamily: "Poppins, sans-serif",
              textAlign: "center",
              color: "#1a202c",
            }}

          >
            Explore and learn from diverse, relevant videos to fuel your growth.
          </Typography>
          {/* </div> */}
          <Typography variant="h5" style={{color:'#000' , textAlign:'center', fontFamily:'Poppins' , margin:'10px' , fontWeight:'900'}}>
           Top Pick
        </Typography>
          <Box sx={{display:'flex' , justifyContent:'flex-start' , alignItems:'flex-start' , flexDirection:{xs:'column' ,  sm:'row'} , flexWrap:'wrap' , width:{ xs: '90%' , sm: '80%' } }}>
            {/* {twoRandomItems.map((evt) => ( */}
                    <Card
                      sx={{ 
                        width: {xs: '100%'} , 
                        borderRadius: '25px',
                        margin: '10px',
                        height:{xs: '100%' , sm: '100%' , md: '98%'},
                        display:'flex',
                        flexDirection:{xs: 'column',sm:'row'}
                        , padding: '10px'
                        }}
                      key={randomWatchNow?._id}
                    >
                      <CardMedia
                        sx={{
                          width: {xs: '100%' , sm:500},
                          height: {xs: '100%' , sm: '90%'},
                          borderRadius: '20px'
                          
                        }}
                        component="img"
                        src={POSTER + randomWatchNow.poster?.name}
                        // src={Dummy}
                        alt="..."
                      />
                      <CardContent style={{
                        display:'flex',
                        flexDirection:'column',
                        gap:'10px',
                        width: {xs: '100%' }
                      }}>
                      <Typography variant='h5' style={{textAlign:'left', marginLeft:'20px', marginTop:'20px'}}>                    
                      {randomWatchNow?.title}
                      </Typography>
                      <p
                        style={{
                          padding: '2px 10px',
                          backgroundColor: '#F2F6FF',
                          borderRadius: '10px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          width: '233px',
                          height: '20px',
                          color: '#3470e4',
                          textTransform: 'uppercase',
                          fontFamily: 'Open Sans',
                          fontSize: '12px',
                          lineHeight: '16px',
                          fontWeight: '600',
                          marginLeft: '20px'
                        }}
                      >
                        {randomWatchNow?.speaker}
                      </p>
                      <Typography variant='h6' style={{textAlign:'left', marginTop:'20px', marginLeft:'20px'}}>                    
                      {randomWatchNow?.aboutSpeaker?.substring(0,100) +"..."}
                      </Typography>
                      <CardActions sx={{display:'flex', width: '100%',justifyContent:{xs: 'center' , sm:'flex-end'},alignItems:{xs: 'center' , sm:'flex-end'}}}>
                  <Button
                      style={{
                        marginTop:'40px',
                        backgroundColor: "#6a58c6",
                        paddingLeft: '3rem',
                        paddingRight: '3rem',
                        paddingTop: '10px',
                        paddingBottom: '10px',
                        borderRadius: '10px'

                      }}
                      onClick={() => {
                        if (isAuthUser) {
                          handleIncreaseViews(randomWatchNow?._id);
                          navigate("/watch_now/watch", { state: { link: randomWatchNow?.link, id: randomWatchNow?._id, watchnow: data } })
                        }
                        else {
                          handleOpen();
                        }

                      }}
                      variant="contained"
                    >
                      Watch Now
                    </Button>
                      </CardActions>
                      </CardContent>
                      
                    </Card>
            {/* ))} */}
          </Box>
        <Media props="Recently Added"/>

        <Media props="Most Viewed"/>
        <Media props="Marketing"/>
        <Media props="Startups"/>
        <Media props="Finance"/>
        <Media props="Funding"/>
        <Media props="Founders"/>
        <Media props="Tech And Design"/>

                  











        <StreakButtons />
          <Box
            // className={ classes.btnbox}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: !isMobile ? 'row' : 'column',
            }}
          >
            <Box
              // className={classes.subbtnbox}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}

            >
              <Button

                style={allbtnstyle}
                onClick={All}
              >
                All
              </Button>
              <Button

                style={starbtnstyle}
                onClick={Startups}
              >
                Startups
              </Button>
              <Button

                style={marbtnstyle}
                onClick={Marketing}
              >
                Marketing
              </Button>
            </Box>
            <Box
              // className={classes.subbtnbox}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button

                style={finbtnstyle}
                onClick={Finance}
              >
                Finance
              </Button>
              <Button

                style={funbtnstyle}
                onClick={Funding}
              >
                Funding
              </Button>
              <Button

                style={foubtnstyle}
                onClick={Founders}
              >
                Founders
              </Button>
            </Box>
            <Box
              // className={classes.subbtnbox}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button

                style={tecbtnstyle}
                onClick={Tech}
              >
                Tech & Design
              </Button>
              <Button

                style={othbtnstyle}
                onClick={Others}
              >
                Others
              </Button>
            </Box>
          </Box>
        </Box>

        <Typography variant="h5" style={{color:'#000' , textAlign:'center', fontFamily:'Poppins' , margin:'10px' , fontWeight:'900'}}>
         All Videos
        </Typography>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexWrap: 'wrap',
            '@media screen and (max-width: 480px)': {
              marginLeft: '0px !important',
              paddingLeft: '20px',
              alignItems: 'center',
            },
          }}
        >
          {data
            .filter((video) => {
              let tags = video.tags;
              let a = 0,
                mar = 0,
                star = 0,
                fin = 0,
                fun = 0,
                fou = 0,
                tec = 0,
                oth = 0;

              tags.forEach((tag) => {
                if (tag === 'all') a = 1;
                if (tag === 'marketing') mar = 1;
                if (tag === 'startups') star = 1;
                if (tag === 'finance') fin = 1;
                if (tag === 'funding') fun = 1;
                if (tag === 'founders') fou = 1;
                if (tag === 'tech and design') tec = 1;
                if (tag === 'others') oth = 1;
              });

              if (all || a) return video;
              if (startups && star) return video;
              if (marketing && mar) return video;
              if (finance && fin) return video;
              if (funding && fun) return video;
              if (founders && fou) return video;
              if (techD && tec) return video;
              if (others && oth) return video;
            })
            .sort((a, b) => new Date(a.date) - new Date(b.date)) // Sort videos in ascending order of date
            .map((evt) => (
              <div key={evt._id}>
                <Card
                  onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.1)'}
                  onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}
                  style={{
                    position: 'relative',
                    flexDirection: 'column',
                    minWidth: '0',
                    wordWrap: 'break-word',
                    backgroundColor: '#fff',
                    backgroundClip: 'border-box',
                    border: '1px solid rgba(0, 0, 0, 0.125)',
                    borderRadius: '0.25rem',
                    width: '22rem !important',
                    height: '27rem !important',
                    display: 'inline-table',
                    margin: '10px',
                  }}
                  sx={{ width: 345 }}
                  key={evt._id}
                >
                  <CardMedia
                    style={{
                      borderTopLeftRadius: 'calc(0.25rem - 1px)',
                      borderTopRightRadius: 'calc(0.25rem - 1px)',
                      minHeight: '180px',
                      maxHeight: '180px',
                      width: '100%',
                    }}
                    component="img"
                    src={POSTER + evt.poster.name}
                    alt="..."
                  />
                  <CardContent>
                    <h4
                      style={{
                        color: '#1A202C',
                        textAlign: 'center',
                        fontFamily: 'Poppins',
                        fontSize: '24px',
                        fontWeight: '800',
                        lineHeight: '36px',
                      }}
                    >
                      {evt.title}
                    </h4>
                    <div
                      style={{
                        marginTop: '10px',
                        textAlign: 'center',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginBottom: '1rem',
                      }}
                    >
                      <p
                        style={{
                          padding: '2px 10px',
                          backgroundColor: '#F2F6FF',
                          borderRadius: '10px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          width: '233px',
                          height: '20px',
                          color: '#3470e4',
                          textTransform: 'uppercase',
                          fontFamily: 'Open Sans',
                          fontSize: '12px',
                          lineHeight: '16px',
                          fontWeight: '600',
                        }}
                        onClick={() => setClickedVideoId(evt._id)}
                      >
                        {evt.speaker}
                      </p>
                    </div>
                    {clickedVideoId === evt._id && (
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        style={{
                          fontFamily: 'Open Sans',
                          fontWeight: '600',
                          fontSize: '16px',
                          lineHeight: '24px',
                          color: '#21191b',
                          display: 'flex',
                          alignItems: 'center',
                          padding: '2px 0px 2px 10px',
                        }}
                      >
                        <ReadMoreAndLess charLimit={50} readMoreText={'Read more'} readLessText={'Read less'}>
                          {evt.aboutSpeaker}
                        </ReadMoreAndLess>
                      </Typography>
                    )}
                  </CardContent>
                  <CardActions className="card-actions">
                    <BootstrapButton
                      onClick={() => {
                        if (isAuthUser) {

                          setWatchnow(evt._id);
                          handleIncreaseViews(evt._id);
                          navigate('/watch_now/watch', { state: { link: evt.link, id: evt._id, watchnow: data } });
                        } else {
                          handleOpen();
                        }
                      }}
                      variant="contained"
                    >
                      Watch Now
                    </BootstrapButton>
                    <WatchnowButtonBox Id={evt._id} completedlist={completedlist} streaks={streaks} />
                  </CardActions>
                </Card>
              </div>
            ))}
        </div>
      </div>







      <Footer />
    </>
  );
}

export default WatchNow;
