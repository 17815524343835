import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import { Box, Card, CardMedia, CardContent, CardActions, Typography, Link } from '@mui/material';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import { useMediaQuery } from '@mui/material';
import { GET_BLOGS,IMAGE } from "../../api/blog";
import dummy from './CaseStudy.png';
import PolicyGenius from '../CaseStudy.jsx/CaseStudyData/PolicyGenius';
import LiveStockSense from '../CaseStudy.jsx/CaseStudyData/LiveStockSense';
import LendEasy from '../CaseStudy.jsx/CaseStudyData/LendEasy';
import PolicyGeniusI from '../CaseStudy.jsx/Photos/2.png';
import LiveStockSenseI from '../CaseStudy.jsx/Photos/3.png';
import LendEasyI from '../CaseStudy.jsx/Photos/4.png';
import { useNavigate } from 'react-router-dom';


const CaseStudyCards = ({ CaseStudies , handleCaseClick , targetDivRef , isAuth , from}) => {
  
  const arrowStyles = {
    position: "absolute",
    top: ".7em",
    bottom: "auto",
    padding: ".4em",
    zIndex: 2,
 };
  const breaking = useMediaQuery('(max-width: 1265px)');
  const isMobile = useMediaQuery('(max-width: 600px)');
  const isSmallMobile = useMediaQuery('(max-width: 947px)');
  const isGreaterMobile = useMediaQuery('(max-width: 700px)');
  const navigate = useNavigate();
  const handleViewMoreClick = (id) => {
    handleCaseClick(id); 
    targetDivRef.current.scrollIntoView({ behavior: 'smooth' }); // Scroll to the target div
  };
  const getNumBlogsPerSlide = () => {
    if (isGreaterMobile) {
      return 1;
    } else if (isSmallMobile) {
      return 2;
    } else {
      return !from? 3: 2;
    }
  };
  const numBlogsPerSlide = getNumBlogsPerSlide();
  const numSlides = Math.ceil(CaseStudies.length / numBlogsPerSlide);

  return (
    <Carousel
     className="carousel"
       style={{ display: 'flex', justifyContent: 'center' , width:'100%' , background:'#fff' , height:'90%'}}
       showArrows={true}
       showIndicators={false}
       renderArrowPrev={(onClickHandler, hasPrev, label) =>
         hasPrev && (
      <button
        type="button"
        onClick={onClickHandler}
        title={label}
        style={{...arrowStyles,
          left: isMobile ? '0.5em' : '4.3em', // Adjust positioning for mobile and non-mobile
          top: '50%',
          transform: 'translateY(-50%)', // Center vertically
          backgroundColor: "#6a58c6",
          color: '#fff',
          borderRadius: '50%', // Make it round
          border: 'none',
          padding: '10px',
          zIndex: 1, // Ensure the button is above the carousel content
        }}
      >
        <ArrowBackIosNewRoundedIcon />
      </button>
    )
  }
  renderArrowNext={(onClickHandler, hasNext, label) =>
    hasNext && (
      <button
        type="button"
        onClick={onClickHandler}
        title={label}
        style={{
          ...arrowStyles,
          right: isMobile ? '0.5em' : '4.3em', // Adjust positioning for mobile and non-mobile
          top: '50%',
          transform: 'translateY(-50%)', // Center vertically
          backgroundColor: "#6a58c6",
          color: '#fff',
          borderRadius: '50%', // Make it round
          border: 'none',
          padding: '10px',
          zIndex: 1, // Ensure the button is above the carousel content
        }}
      >
        <ArrowForwardIosRoundedIcon />
      </button>
    )
  }
       >

      
     {Array.from({ length: numSlides }).map((_, slideIndex) => (
        <div key={slideIndex} style={{background:'#fff'}}> 
          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              gap: '1rem',
            }}
          >
            {CaseStudies.slice(slideIndex * numBlogsPerSlide, (slideIndex + 1) * numBlogsPerSlide).map((blog) => (
                <Card sx={{
                // height: isGreaterMobile?(isSmallMobile?'90%':'500px'):'530px',
                // height:'100%',
                // width: isGreaterMobile?'90%':'100%'
                width: isGreaterMobile ? '90%' : '30%',
                boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
              }}>
                <div >
                  <CardMedia
                    component="img"
                    width="100%"
                    // image={IMAGE + blog.imagePath}
                    image={from==="top"?blog.imagePath:blog.casestudy}

                    alt="..."
                  />
                </div>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    <Box
                      component="div"
                      overflow="hidden"
                      textOverflow="ellipsis"
                      style={{
                        display: '-webkit-box',
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: 'vertical',
                        fontSize: '1.5rem',
                        fontWeight: 'bold',
                        lineHeight: '1.2',
                      }}
                    >
                      {blog.title}
                    </Box>
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    <Box
                      component="div"
                      overflow="hidden"
                      textOverflow="ellipsis"
                      style={{
                        display: '-webkit-box',
                        WebkitLineClamp: 4,
                        WebkitBoxOrient: 'vertical',
                        fontSize: '1rem',
                        lineHeight: '1.2',
                      }}
                    >
                      {/* {blog.component} */}
                    </Box>
                  </Typography>
                </CardContent>
                  <CardActions style={{display:'flex' , justifyContent:'flex-end'}}>
                          <a
                                    onClick={() => {
                                      isAuth?navigate((from==="top"?'/casestudies/':'/casestudy/')+blog._id):navigate('/login' , {state:{redirect:(from==="top"?'/casestudies/':'/casestudy/')+blog._id}} )
                                    }}
                                    // href={'/casestudies/'+blog.id}
                                    // onClick={() => handleViewMoreClick(blog.id)}
                                    style={{ 
                                      textDecoration: "none" ,
                                      color: "white",
                                        fontSize: "15px",
                                        padding: "5px",
                                        borderRadius: "5px",
                                        cursor: "pointer",
                                        backdropFilter: "blur(10px)",
                                        backgroundColor: "#6A58c6",
                                      }}
                                  >
                                      View More
                                  </a>
                  </CardActions>
                </Card>
            ))}
          </Box>
        </div>
      ))}
    </Carousel>
  );
};

export default CaseStudyCards;
