export const faqs = [
    {
      question: "What is the Medium of Instruction for the Program?",
      answer:
        "The entire program, encompassing training modules, laboratory exercises, projects, and work experience components, is delivered exclusively through an online platform.",
    },
    {
      question: "How Can I Receive Assistance for Clarifying Doubts?",
      answer:
        "We offer dedicated support for any queries or doubts. You can schedule a 15-minute consultation via a Google Meet link, available for booking on a slot basis. During this time, you are welcome to ask any questions. Additionally, email support is available at support@virtualstartupcampus.com for further assistance.",
    },
    {
      question: "Are There Any Live Classes Included in the Program?",
      answer:
        "The program does not include live classes. However, we offer a unique 'Pop and Question' format in our 15-minute Google Meet sessions, where you can book a slot to address live questions and get immediate assistance with your queries.",
    },
    {
      question: "Is It Possible to Advance Directly to Week 3 of the Program?",
      answer:
        "While it is feasible to complete the training modules and labs ahead of schedule, access to Week 3 content is structured to begin on a pre-set date.",
    },
    {
      question: "What If I Choose Not to Participate in Week 4?",
      answer:
        "To fully benefit from the work experience component and gain comprehensive industry insight, we strongly recommend completing both Weeks 3 and 4. Please note that a certificate for work experience will only be awarded upon completion of the entire program.",
    },
    {
      question: "Will Completion of the Program Result in a Degree or Certificate?",
      answer:
        "Upon successful completion of the program, participants will receive Certificates. Please be aware that this program does not confer academic Degrees.",
    },
    {
      question: "What Employment Opportunities Are Available After Completing This Course?",
      answer:
        "Completing this course qualifies you for Prompt Engineering roles. Additionally, this course enhances your resume by equipping you with current and relevant skill sets, potentially boosting your application for various other roles.",
    },
    {
      question: "Does VSC Assist with Job Placement?",
      answer:
        "While VSC provides comprehensive training modules, labs, projects, and remote internships geared towards helping you acquire certificates and practical work experience, we do not offer direct job placement services or support.",
    },
    {
      question: "Is Attendance Mandatory for Any Part of the Program?",
      answer:
        "To obtain certificates for each component of the program, completion of all parts is mandatory. There are no live classes requiring attendance. However, for the remote internship, maintaining attendance is essential to gain meaningful work experience.",
    },
    {
      question: "What Happens If My Capstone Project Is Rejected?",
      answer:
        "The capstone project requires a certain standard of quality and practical application. If your project is initially rejected due to insufficient quality, you will be given an opportunity to resubmit. Should the project not meet the required standards upon resubmission, you will be ineligible for the project certificate. However, you may still receive other certificates for completing the initial training week and subsequent work experience tasks.",
    },
    {
      question: "Will I Receive a Certificate If My Work or Project Lacks Quality?",
      answer:
        "To align with industry standards, substandard work or projects must be resubmitted. Failure to meet quality standards upon resubmission may result in the denial of certification.",
    },
    {
      question: "What Flexibility Does the Course Offer If My Schedule Is Conflicting?",
      answer:
        "The first part of the 4-week program is self-paced, allowing for flexible learning. For the capstone project in Week 2, timely submission (within a week) is encouraged for optimal evaluation. Weeks 3 and 4 involve a remote internship that offers flexibility, though regular check-ins and reporting are mandatory.",
    },
    {
      question: "What Is the Refund Policy?",
      answer:
        "Refunds are available within 48 hours of course enrollment. To request a refund, please email hello@virtualstartupcampus.com. Refunds are processed within 7 working days.",
    },
    {
      question: "Are There Any Eligibility Requirements for the Course?",
      answer:
        "There are no specific eligibility criteria to enroll in the course.",
    },
    {
      question: "Do I Need to Purchase Any External Tools to Participate in the Course?",
      answer:
        "No additional tools are required for the course. However, creating a free ChatGPT account is necessary for some modules.",
    },
    {
      question: "What Is the Course Fee?",
      answer:
        "Course fees can be found in the fee section of our website.",
    },
    {
      question: "How Can I Contact Virtual Startup Campus?",
      answer:
        "For inquiries, please email hello@virtualstartupcampus.com.",
    },
    {
      question: "Where Is Virtual Startup Campus Located?",
      answer:
        "Our address is HD 220 Forum, Cyber City, DLF Phase 3, Gurugram, Haryana 122002.",
    },
    {
      question: "Is an Internship Guaranteed as Part of the Course?",
      answer:
        "Yes, the course includes a 2-week virtual internship in Weeks 3 and 4, providing practical work experience and industry insights. All participants are eligible for this internship.",
    },
  ];