const { BASE_URL } = require("./client");

const GET_BLOGS = BASE_URL + '/blogs';
const GET_BLOGS_ALL = BASE_URL + '/blogs/all';
const GET_BLOG = BASE_URL + '/blog/';
const IMAGE = BASE_URL + '/';
const COMPLETED_BLOG = BASE_URL +'/completed/'

module.exports = {
    GET_BLOGS,
    GET_BLOG,
    IMAGE,
    COMPLETED_BLOG,
    GET_BLOGS_ALL
}