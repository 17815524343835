const { BASE_URL } = require("./client");

const APPLY_COURSE = BASE_URL + '/api/applycourse/';
const DOWNLOAD_BROCHURE_POST = BASE_URL +'/api/post/download/brochure/';
const GET_COURSE = BASE_URL+'/api/course/'

module.exports = {
    APPLY_COURSE,
    DOWNLOAD_BROCHURE_POST,
    GET_COURSE
} 