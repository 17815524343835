import * as React from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import axios from 'axios';
import { GET_PROMOCODES } from '../../../../api/vsc';
import {TbDiscountCheck} from 'react-icons/tb';


export default function CouponList({handleSetCode}) {
  const [open, setOpen] = React.useState(false);
  const [codes , setCodes] = React.useState([]);
   
  React.useEffect(()=> {
    axios.get(GET_PROMOCODES)
    .then(response => {
      const filteredCodes = response.data.data.filter(code => code.suggest === "YES");
      setCodes(filteredCodes);

    })
    .catch(err=> {
    })
  },[]);

  const handleClick = () => {
    setOpen(!open);
    
  };

  return (
    <List
      sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' , marginBottom:'50px'}}
      component="nav"
      aria-labelledby="nested-list-subheader"
    >
      <ListItemButton onClick={handleClick}>
        <ListItemText primary="Show Available Codes" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      {codes.map((code) => (
        <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton key={code._id} sx={{ pl: 4 }} onClick={() => handleSetCode(code)}>
            <ListItemIcon>
              <TbDiscountCheck size='30px'/>
            </ListItemIcon>
            <ListItemText  >{code.code}</ListItemText>
            <ListItemText  >{`${code.details.discountpercentage*100}%`}</ListItemText>
          </ListItemButton>
        </List>
      </Collapse>
      ))}
      
    </List>
  );
}
