import * as React from "react";
// import CopyToClipboard from "react-copy-to-clipboard";
import {Box ,TextField, Typography , InputAdornment , IconButton} from '@mui/material';
import axios from "axios";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { LinkedinShareButton , LinkedinIcon , WhatsappShareButton , WhatsappIcon , FacebookShareButton , FacebookIcon , TwitterIcon , TwitterShareButton} from 'react-share';
import ShieldIcon from './ShieldIcon';
import MedalIcon from './MedalIcon';
import StarIcon from './StarIcon';
import vscchampion from './Images/vscchampion.png';
import vscvolunteer from './Images/vscvolunteer.png';
import campus from './Images/campus.jpg';
import Footer from '../Footer/Footer.js';

const ReferralLink = ({from}) => {
  const [copied, setCopied] = React.useState(false);
  const { USER } = require("../../api/vsc");
  const [userData, setUserData] = React.useState({});
  const [successfullRefferal , setSuccessfullRefferal] = React.useState(0);
  React.useEffect(() => {
    axios
      .get(USER, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setUserData(response.data);
        const successfulReferrals = response.data.referredEmails
        .filter((referral) => referral.paid === true);
        setSuccessfullRefferal(successfulReferrals.length);
      })
      .catch((error) => {
        // handle error
        console.error(error);
      });
  }, []);
  const link = `https://campus.markoknow.com/signup?referrer=${userData.referralId}`;

  function handleCopy() {
    navigator.clipboard.writeText(link);
    alert("Link copied to clipboard!");
  }
 
  return (
    <>
        <Box
            sx={{
                marginTop: from ? '20px':'120px',
                display:'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '10px',
                marginBottom: '50px',
                width: from &&'100%'
            }}
        >
            <Box
                sx={{
                    background:'#6a58c6',
                    padding: '20px',
                    borderRadius: '20px',
                    display:'flex' , 
                    flexDirection:'column',
                    gap: '20px',
                    width: {sm: '75%' , xs: from ? '100%':'78%'}
                }}
            >
                <Typography variant="h5" sx={{
                    color: '#fff',
                    fontFamily:'Poppins',
                    fontWeight:'900'
                }}>
                    Introduction:
                </Typography>
                <Typography variant="h6"sx={{
                    color: '#fff',
                    fontFamily:'Poppins',
                    fontWeight:'900'
                }}>
                    Welcome to the Virtual Startup Campus, Campus Ambassador Program! As a pivotal part of our community outreach, this program is designed to foster leadership and business skills in students who are eager to make a difference. It’s your chance to stand out, connect, and catapult your potential on and off-campus.
                </Typography>
                <Typography variant="h5" sx={{
                    color: '#fff',
                    fontFamily:'Poppins',
                    fontWeight:'900'
                }}>
                    Why Join?
                </Typography>
                <Typography variant="h6"sx={{
                    color: '#fff',
                    fontFamily:'Poppins',
                    fontWeight:'900'
                }}>
                    As a Campus Ambassador, you become the face and voice of Virtual Startup Campus,  gaining unparalleled exposure to real-world experiences and networking opportunities that will pave your path to a successful future.                </Typography>
                <Typography variant="caption" sx={{
                    color: '#fff',
                    fontFamily:'Poppins',
                    fontWeight:'900'
                }}>
                    Copy your unique referral link below & Share it with your friends and Earn Free Perks
                </Typography>
                <TextField 
                    type="text"
                    id="link"
                    name="link"
                    value={link}
                    sx={{
                        width: '80%',
                        background: '#fff',
                        '& .MuiOutlinedInput-root': {
                        borderColor: 'gray', // Initial border color
                        '&:focus': {
                            borderColor: 'black', 
                        },
                        },
                        '& .MuiInputLabel-root': {
                        color: 'gray', // Initial label color
                        '&.Mui-focused': {
                            color: 'black', // Label color on focus
                        },
                        },
                        borderRadius: '10px'
            }}
            InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                        
                            <IconButton onClick={handleCopy}>
                                <ContentCopyIcon />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />

            <Box
                sx={{
                    display:'flex' , 
                    gap: '15px'
                }}
            >
            <LinkedinShareButton 
                // url={png}
            >
            <LinkedinIcon round={true} size={'30px'}/>
            </LinkedinShareButton>
            <WhatsappShareButton 
                // url={png}
            >
                <WhatsappIcon round={true} size={'30px'}/>
            </WhatsappShareButton>
            <FacebookShareButton 
                // url={png}
            >
                <FacebookIcon round={true} size={'30px'}/>
            </FacebookShareButton>
            <TwitterShareButton 
                // url={png}
            >
                <TwitterIcon round={true} size={'30px'}/>
            </TwitterShareButton>
            </Box>
            </Box>
            <Box
            sx={{
                textAlign: 'center',
                marginTop: '30px',
                padding: '20px',
                background: 'white',
                borderRadius: '10px',
                boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                width: {sm: '90%' , xs: from ? '100%' :'78%'}
            }}
            >
                <Box sx={{
                    display:'flex' , 
                    flexDirection: 'column' , 
                    justifyContent:'center' , 
                    alignItems: 'center',
                    marginBottom: '10px',
                    gap:'10px',
                }}>
                <Typography variant="h5"  sx={{
                    fontFamily:'Poppins',
                    fontWeight:'900',
                    
                }}>
                Level of Achievement
                 </Typography>
                 <Box
                 sx={{
                    background: '#6a58c6',
                    width: {xs: '80%', sm:'30%'},
                    padding: '10px',
                    borderRadius: '20px',
                    color: '#fff',
                    fontFamily:'Poppins' , 
                    fontWeight: 400
                 }}
                  >
                    Total Refferals : {userData.referralCount}
                 </Box>
                </Box>
            
            <Box sx={{ display: 'flex', justifyContent: {xs: 'center' , sm: 'space-between' }, alignItems: {xs:'center', sm: 'flex-end'},flexDirection: {xs: 'column' , sm: 'row' } , gap: '10px' }}>
                <Box sx={{
                    textAlign: 'center',
                    marginTop: '30px',
                    padding: '20px',
                    background: 'white',
                    borderRadius: '10px',
                    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                    width: {sm: '28%' , xs: from ? '100%' : '70%'}
                }}>
                <Box style={{ margin: 'auto', marginBottom: '2' }}>
                    <img 
                        src={vscvolunteer}
                        alt="Champion"
                        style={
                            {
                                width: 200,
                                height: 200
                            }
                        }
                     />
                </Box>
                <Typography variant="body1" sx={{
                    fontFamily:'Poppins',
                    fontWeight:'900',
                }} className="font-bold text-sm">
                    0 Referrals
                </Typography>
                <Typography variant="body2" sx={{
                    fontFamily:'Poppins',
                    fontWeight:'900',
                }} className="text-gray-700 mt-1">
                    Level 1
                </Typography>
                <Typography variant="body2" sx={{
                    fontFamily:'Poppins',
                    fontWeight:'900',
                }} className="text-gray-700 mt-1">
                    VSC Campus Volunteer
                </Typography>
                <Typography variant="body2" sx={{
                    fontFamily:'Poppins',
                    fontWeight:'700',
                }}>
                Start Your Journey<br/>
                Begin as a Volunteer and learn the ropes of being a Campus Ambassador. Get to grips with our mission and start making an impact from day one.
                </Typography>
                </Box>
                <Box sx={{
                    textAlign: 'center',
                    marginTop: '30px',
                    padding: '20px',
                    background: 'white',
                    borderRadius: '10px',
                    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                    width: {sm: '30%' , xs: from ? '100%' : '70%'}
                }}>
                <Box style={{ margin: 'auto', marginBottom: '2' }}>
                <img 
                        src={campus}
                        alt="Champion"
                        style={
                            {
                                width: 200,
                                height: 200
                            }
                        }
                     />
                </Box>
                <Typography variant="body1" sx={{
                    fontFamily:'Poppins',
                    fontWeight:'900',
                }} className="font-bold text-sm">
                    25 Referrals
                </Typography>
                <Typography variant="body2" sx={{
                    fontFamily:'Poppins',
                    fontWeight:'900',
                }} className="text-gray-700 mt-1">
                    Level 2
                </Typography>
                <Typography variant="body2" sx={{
                    fontFamily:'Poppins',
                    fontWeight:'900',
                }} className="text-gray-700 mt-1">
                    VSC Campus Ambassador
                </Typography>
                <Typography variant="body2" sx={{
                    fontFamily:'Poppins',
                    fontWeight:'700',
                }}>
                Elevate Your Impact<br/>
                Advance to become a Campus Ambassador by hitting the 25 referral milestone. Drive initiatives and lead campaigns that resonate across the campus.                </Typography>
                <Typography variant="caption" sx={{
                    fontFamily:'Poppins',
                    fontWeight:'700',
                }}>
                Perks: <br/>
                    Access to exclusive training materials<br/>
                    Official certificate of participation<br/>
                    </Typography>
                </Box>
                <Box sx={{
                    textAlign: 'center',
                    marginTop: '30px',
                    padding: '20px',
                    background: 'white',
                    borderRadius: '10px',
                    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                    width: {sm: '30%' , xs: from ? '100%' : '70%'}
                }}>
                <Box style={{ margin: 'auto', marginBottom: '2' }}>
                     <img 
                        src={vscchampion}
                        alt="Champion"
                        style={
                            {
                                width: 200,
                                height: 200
                            }
                        }
                     />
                </Box>
                <Typography variant="body1" sx={{
                    fontFamily:'Poppins',
                    fontWeight:'900',
                }} className="font-bold text-sm">
                    100 Referrals
                </Typography>
                <Typography variant="body2" sx={{
                    fontFamily:'Poppins',
                    fontWeight:'900',
                }} className="text-gray-700 mt-1">
                    Level 3
                </Typography>
                <Typography variant="body2" sx={{
                    fontFamily:'Poppins',
                    fontWeight:'900',
                }} className="text-gray-700 mt-1">
                    VSC Champion
                </Typography>
                <Typography variant="body2" sx={{
                    fontFamily:'Poppins',
                    fontWeight:'700',
                }}>
                Reach the Pinnacle<br/>
                Join the elite as a Champion with 100 referrals. As a top-tier ambassador, lead from the front and enjoy the prestige of being a role model.
                </Typography>
                <Typography variant="caption" sx={{
                    fontFamily:'Poppins',
                    fontWeight:'700',
                }}>
                    Perks: <br/>
                    Personalized mentorship sessions<br/>
                    Enhanced networking opportunities<br/>
                    Featured profile on campus.markoknow.com<br/>
                    Win Exclusive Surprise Gifts like Voucher, Gift Package<br/>
                </Typography>
                </Box>
            </Box>
            </Box>
        </Box>
        {!from &&<Footer />}
        </>
    );
};

export default ReferralLink;