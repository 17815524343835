import React from 'react';
import MyAccordion from '../../NewVirtualCampusStartup/Program/Accordian/MyAccordian';


const LendEasy = () => {
    const questions= [
        {
            question:'Proactive Regulatory Compliance:',
            answer: "LendEasy established a dedicated team to monitor regulatory changes and ensure proactive compliance with RBI guidelines. They collaborated with legal experts to interpret evolving regulations and adapt their platform accordingly.\nOutcome: Proactive regulatory compliance efforts resulted in minimal disruptions due to changing guidelines and improved investor confidence."
        },
        {
            question: 'Data-Driven Credit Risk Assessment:',
            answer: "LendEasy adopted data analytics and machine learning algorithms to analyze alternative data sources, such as digital transactions, utility payments, and social media activity, for assessing borrower credit risk. The platform also facilitated financial literacy programs to educate borrowers on responsible borrowing practices.\nOutcome: Data-driven credit risk assessment improved the accuracy of borrower evaluations, leading to a 25% reduction in default rates."
        },
        {
            question: 'Investor Education and Portfolio Diversification:',
            answer: "To build trust among investors, LendEasy conducted investor education webinars, published investment guides, and provided transparent data on historical loan performance. They emphasized the benefits of portfolio diversification and prudent risk management.<br/><br/>Outcome: The investor education initiatives resulted in a 40% increase in lender registrations and a more diversified lender base."
        },
        {
            question: 'How can LendEasy enhance its credit risk assessment models to accommodate a broader range of borrowers, including those from informal sectors, while maintaining an acceptable level of risk for investors?',
            answer: "To enhance its credit risk assessment models and accommodate a broader range of borrowers, including those from informal sectors, LendEasy can implement the following strategies:\na. Incorporation of Alternative Data: LendEasy can include additional alternative data points from the informal sector, such as rental payment history, utility bill payments, and trade references. This data, when analyzed using machine learning algorithms, can provide valuable insights into the creditworthiness of borrowers without formal credit histories.\nb. Collaborations with Informal Lending Institutions: LendEasy can collaborate with microfinance institutions and other informal lending organizations to gain access to their lending data and customer repayment behavior. This data can be used as supplementary information for credit risk assessment.\nc. Social Collateral and Community Endorsements: LendEasy can consider social collateral and community endorsements as part of its credit risk assessment. In some cases, borrowers from informal sectors may have strong community ties and reputations, which can act as a form of collateral and reduce the risk for investors.\nOutcome: By incorporating alternative data and fostering collaborations, LendEasy can improve its credit risk assessment models and cater to a broader pool of borrowers, increasing financial inclusion."
        },
        {
            question: 'What marketing strategies can LendEasy employ to attract borrowers and investors from diverse regions of India and ensure balanced growth in loan demand and investor interest?',
            answer: "To attract borrowers and investors from diverse regions of India, LendEasy can employ the following marketing strategies:\na. Regional Outreach and Language Localization: LendEasy can run targeted marketing campaigns in different regions, tailoring advertisements and content in local languages to resonate with the local audience. This approach will make the platform more accessible and relatable to borrowers and investors from various regions.\nb. Referral Programs: Implementing referral programs that incentivize both borrowers and investors can help attract new users to the platform. Referral bonuses for successful transactions can encourage users to invite their friends and acquaintances, thereby expanding the user base.\nc. Collaborations with Local Partners: Partnering with local financial advisors, community organizations, and microfinance institutions can help LendEasy establish a strong presence in specific regions and build trust among potential users.\nOutcome: Through targeted marketing and strategic collaborations, LendEasy can attract borrowers and investors from diverse regions, ensuring balanced growth in loan demand and investor interest."
        },
        {
            question: 'How can LendEasy collaborate with credit bureaus and financial institutions to access more comprehensive credit data for borrowers, thereby improving the accuracy of credit risk evaluations?',
            answer: "To access more comprehensive credit data for borrowers and improve credit risk evaluations, LendEasy can explore the following collaboration strategies: \n a. API Integration with Credit Bureaus: LendEasy can integrate its platform with credit bureau systems through Application Programming Interfaces (APIs). This integration will enable real-time access to credit scores and credit reports, providing a more comprehensive view of borrowers' creditworthiness.\nb. Partnerships with Financial Institutions: Collaborating with banks and financial institutions can allow LendEasy to access transaction data, loan repayment histories, and other financial information. This data can be used to augment the credit risk assessment process and validate borrowers' financial credibility.\nc. Data Sharing Agreements: LendEasy can enter into data sharing agreements with credit bureaus and financial institutions, ensuring secure and compliant exchange of borrower data. This collaboration will enable LendEasy to access a broader pool of credit data.\nOutcome: By collaborating with credit bureaus and financial institutions, LendEasy can access more comprehensive credit data, leading to more accurate credit risk evaluations and improved decision-making for lenders and investors."
        }
    ]
  return (
    <div>
        <h1>LendEasy: Empowering Peer-to-Peer Lending in the Indian Market</h1>

        <h2>Introduction:</h2>
        <p>LendEasy is a fintech startup in India that operates a peer-to-peer (P2P) lending marketplace. The platform connects borrowers seeking loans with individual lenders willing to invest their funds. LendEasy aims to provide an efficient and transparent alternative to traditional lending channels, promoting financial inclusion and access to credit for underserved individuals and small businesses. This case study focuses on LendEasy's business problems in the Indian P2P lending market and their efforts to overcome these challenges.</p>

        <h2>Business Problems:</h2>
        <ol>
            <li><strong>Regulatory Uncertainties and Compliance Burden:</strong> One of the primary challenges for LendEasy was navigating the evolving regulatory landscape for P2P lending in India. The Reserve Bank of India (RBI) had issued guidelines for P2P lending platforms, but frequent changes and ambiguities required constant adaptations, leading to a compliance burden. <br/><br/><em>Assumed Figure: LendEasy allocated approximately 25% of its operational budget for regulatory compliance and legal consultations.</em></li>

            <li><strong>Borrower Credit Risk Assessment:</strong> LendEasy faced challenges in assessing the creditworthiness of borrowers, especially those without sufficient credit histories. Determining the risk profile of potential borrowers accurately was crucial to attract lenders and maintain a sustainable lending marketplace. <br/><br/><em>Assumed Figure: Approximately 45% of loan applications on LendEasy's platform were from borrowers with limited or no traditional credit histories.</em></li>

            <li><strong>Building Trust Among Investors:</strong> As a relatively new player in the P2P lending industry, LendEasy had to build trust and credibility among potential investors who were unfamiliar with this alternative investment model. Convincing lenders to invest their funds in the platform and diversify their investment portfolio posed a significant challenge. <br/><br/><em>Assumed Figure: Only 35% of potential lenders expressed a high level of trust in P2P lending platforms as a reliable investment option.</em></li>
        </ol>

        <h2>Solutions and Outcomes:</h2>
        <p>LendEasy implemented several strategies to address these business problems and achieve success in the Indian P2P lending marketplace.</p>
        <br/>
        <br/>
        <div style={{flexShrink:3}}>
                {
                questions.map((q,i) => (
                    <MyAccordion question={q.question} answer={q.answer} />
                ))
                }
            </div>
    </div>
  )
}

export default LendEasy