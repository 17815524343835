import * as React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import Grid from "@material-ui/core/Grid";
import { Link , useNavigate} from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import "../../stylesheets/about.css";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import PeopleIcon from "@mui/icons-material/People";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { Button } from "@mui/material";
import CallIcon from '@mui/icons-material/Call';
import Avatar from '@mui/material/Avatar';
import EmailIcon from '@mui/icons-material/Email';
import Email from "@mui/icons-material/Email";
import BusinessIcon from '@mui/icons-material/Business';

const Footer = () => {
  const [isAuth, setIsAuth] = React.useState(window.localStorage.getItem('isAuth') || false);
  const navigate = useNavigate();

  const handleClick = () => {
    // Navigate to the home page with state
    navigate('/', { state: { from: 'footer' } });
  };
  return (
    <div className="footer_about">
      <BrowserView>
        <div className="community">
          <div className="text">
            Connect with a growing community of learners
          </div>
          <Link to={`/virtual_campus`}>
            {" "}
            <div className="whatsapp">Join our Community</div>
          </Link>
        </div>
        <hr className="solid1" />
        <div className="follow">
          <div className="follow_text">Follow us- </div>
          <div className="follow_icons">
            <a
              target="_blank"
              href="https://www.instagram.com/virtualstartupcampus/"
              rel="noopener noreferrer"
            >
              <InstagramIcon
                style={{
                  marginRight: "1rem",
                  fontSize: "1.3rem",

                  transition: "transform 0.2s", // Use transition instead of translate
                }}
                className="hover-effect" // Add the hover effect class
              />
            </a>
            <a
              target="_blank"
              href="https://www.linkedin.com/company/virtualstartupcampus/"
              rel="noopener noreferrer"
            >
              <LinkedInIcon
                style={{
                  marginRight: "1rem",
                  fontSize: "1.3rem",

                  transition: "transform 0.2s", // Use transition instead of translate
                }}
                className="hover-effect" // Add the hover effect class
              />
            </a>
            <a
              target="_blank"
              href="https://mobile.twitter.com/markoknow1"
              rel="noopener noreferrer"
            >
              <TwitterIcon
                style={{
                  marginRight: "1rem",
                  fontSize: "1.3rem",

                  transition: "transform 0.2s", // Use transition instead of translate
                }}
                className="hover-effect" // Add the hover effect class
              />
            </a>
            <a
              target="_blank"
              href="https://www.facebook.com/markoknow1/"
              rel="noopener noreferrer"
            >
              <FacebookIcon
                style={{
                  marginRight: "1rem",
                  fontSize: "1.3rem",
                  transition: "transform 0.2s", // Use transition instead of translate
                }}
                className="hover-effect" // Add the hover effect class
              />
            </a>
            <a
              target="_blank"
              href="https://www.youtube.com/@markoknow"
              rel="noopener noreferrer"
            >
              <YouTubeIcon
                style={{
                  marginRight: "1rem",
                  fontSize: "1.3rem",
                  transition: "transform 0.2s", // Use transition instead of translate
                }}
                className="hover-effect" // Add the hover effect class
              />
            </a>
          </div>
        </div>
        <hr className="solid2" />
        <Grid container>
          <Grid className="footer_grid" item md={4} sm={12} xs={12}>
            <h1 className="footer_head1">About</h1>
            <p className="footer_para">
            The One Stop for learn {"->"} apply {"->"} experience model for real time application driven learning 
            </p>
          </Grid>
          <Grid className="footer_grid" item md={2} sm={12} xs={12}>
            <h1 className="footer_head1" >Navigate</h1>
            {/* <p className="footer_para">
              <a
                href="/fund"
                // target="_blank"
                className="footer_link"
              >
                Apply for funding
              </a>
            </p> */}
            <p className="footer_para">
            <div style={{cursor: 'pointer'}} onClick={handleClick} className="footer_link">
              Home
            </div>
            </p>
            <p className="footer_para">
              <a
                href={isAuth?"myDashboard/"+window.localStorage.id:"/login"}
                className="footer_link"
              >
                Profile
              </a>
            </p>
            <p className="footer_para">
              <Link to={`/courses`} className="footer_link">
                Courses
              </Link>
            </p>
            <p className="footer_para">
              <Link to={`/watch_now`} className="footer_link">
                Watch Now
              </Link>
            </p>

            <p className="footer_para">
              <Link to={isAuth?`/projects`:`/projectsLogin`} className="footer_link">
                Projects
              </Link>
            </p>
            <p className="footer_para">
              <a
                href="/Career"
                // target="_blank"
                className="footer_link"
              >
                Career
              </a>
            </p>
            <p className="footer_para">
              <a
                href="/mentor"
                // target="_blank"
                className="footer_link"
              >
                Become A Trainee
              </a>
            </p>
            <p className="footer_para">
              <a
                href="/hire"
                // target="_blank"
                className="footer_link"
              >
                 Hire with VSC

              </a>
            </p>
            <p className="footer_para">
              <a
                href="/scholarship"
                // target="_blank"
                className="footer_link"
              >
                 Apply For Scholarship

              </a>
            </p>
            <p className="footer_para">
              <a
                href="/blog"
                // target="_blank"
                className="footer_link"
              >
                Blogs
              </a>
            </p>
            <p className="footer_para">
              <a
                href="/events"
                // target="_blank"
                className="footer_link"
              >
                Events
              </a>
            </p>
            <p className="footer_para">
              <a
                href="/feedback"
                // target="_blank"
                className="footer_link"
              >
                Feedback
              </a>
            </p>
            <p className="footer_para">
              <a
                href="/verifycertificate"
                className="footer_link"
              >
                Verify Certificate
              </a>
            </p>
            <p className="footer_para">
              <a
                href="/campusambassador"
                className="footer_link"
              >
                Campus Ambassador
              </a>
            </p>
          </Grid>
          <Grid className="footer_grid" item md={1} sm={12} xs={12}>
          <h1 className="footer_head1">Quick Link</h1>
            <p className="footer_para">
              <a
                href="/privacy"
                className="footer_link"
              >
                Privacy Policy
              </a>
            </p>
            <p className="footer_para">
              <Link to={`/terms_conditions`} className="footer_link">
                Terms Of Use
              </Link>
            </p>
            <p className="footer_para">
              <Link to={`/refundpolicy`} className="footer_link">
                Refund Policy
              </Link>
            </p>
            <p className="footer_para">
              <Link to={`/about`} className="footer_link">
                About Us
              </Link>
            </p>
            <p className="footer_para">
              <Link to={`/contact`} className="footer_link">
                Contact
              </Link>
            </p>
          </Grid>
          <Grid className="footer_grid" item md={2} sm={12} xs={12}>
            <h1 className="footer_head1" >Programs</h1>
            {/* <p className="footer_para">
              <a
                href="/fund"
                // target="_blank"
                className="footer_link"
              >
                Apply for funding
              </a>
            </p> */}
            <p className="footer_para">
              <a
                href={"/corporateexposureprogram"}
                className="footer_link"
              >
                Full Stack Corporate Training Programs
              </a>
            </p>
            <p className="footer_para">
              <a
                href={"/"}
                className="footer_link"
              >
                Management Stack Full Stack Coder
              </a>
            </p>
            <p className="footer_para">
              <Link to={`/financialanalyst`} className="footer_link">
              Financial Analysis & Management Program 
              </Link>
            </p>
            <p className="footer_para">
              <Link to={`/managementconsulting`} className="footer_link">
               Management Consulting Program
              </Link>
            </p>

            <p className="footer_para">
              <Link to={'/productmanagement'} className="footer_link">
               Product Management Program 
              </Link>
            </p>
            <p className="footer_para">
              <a
                href="/fullstackdeveloper"
                // target="_blank"
                className="footer_link"
              >
               MERN Stack Coder Program
              </a>
            </p>
            <p className="footer_para">
              <a
                href="/pythoncoder"
                // target="_blank"
                className="footer_link"
              >
                Python Program 
              </a>
            </p>
            <p className="footer_para">
              <a
                href="/"
                // target="_blank"
                className="footer_link"
              >
                AI/ML Program
              </a>
            </p>
            <p className="footer_para">
              <a
                href="/"
                // target="_blank"
                className="footer_link"
              >
                Entrepreneurship Program
              </a>
            </p>
          </Grid>
          <Grid className="footer_grid" item md={2} sm={12} xs={12}>
            <h1 className="footer_head1">Contact Us</h1>
            <p style={{display:'flex' , flexDirection:'row' , alignItems:'center'}} >
            <Avatar  style={{background:'#000'}}>
              <CallIcon style={{color:'#f0f0f0'}}/> 
            </Avatar>
            +91-9310148556
            </p>
            <div
            style={{
              display:'flex',
              flexDirection:'row',
              alignItems:'center'
            }}
            >
            
            <Avatar 
            style={{
              background:'#000'
            }}
            >
                  <EmailIcon style={{color:'#fff'}}/> 
            </Avatar>
            <a 
                  href="mailto:hello@markoknow.com" 
                  className="footer_link"

                  >
                    hello@markoknow.com
                  </a>

            </div>
            <div
            style={{
              display:'flex',
              flexDirection:'row',
            }}
            >
            <Avatar 
            style={{
              background:'#000'
            }}
            >
                  <BusinessIcon style={{color:'#fff'}}/> 
            </Avatar>
              <p>
              Forum, Cyber City, DLF Forum, DLF Tower 10th Rd, Phase
              III, Gurugram, Haryana 122002
              </p>
            </div>
            
          </Grid>
          <Grid
            className="footer_grid"
            item
            md={3}
            sm={12}
            xs={12}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                width: "200px",
                textAlign: "center",
                fontSize: "1.8rem",
                fontWeight: "600",
                lineHeight: "24px",
                color: "#ffffff",
              }}
            >
              Get Started
              <p
                style={{
                  color: "#ffdb66",
                  fontSize: "1rem",
                  lineHeight: "28px",
                  fontWeight: "600",
                }}
              >
                Join the Program
              </p>
            </div>
            <div>
              <Link to={isAuth ? `/dashboard/${window.localStorage.id}` :`/signup`}>
                <Button
                  variant="contained"
                  style={{
                    width: "100%",
                    padding: "13px",
                    borderRadius: "12px",
                    fontFamily: "Open Sans",
                    fontWeight: "600",
                    lineHeight: "24px",
                    fontSize: "16px",
                    backgroundColor: "#6a58c6",
                    textTransform: "capitalize",
                  }}
                >
                  Get started
                </Button>
              </Link>
            </div>
          </Grid>
        </Grid>
        <p style={{color:'#fff' , textAlign:'center'}}>© 2023 Markoknow - All Rights Reserved</p>

        <hr className="solid3" />
      </BrowserView>
      <MobileView>
        <div className="community_mobile">
          <div className="text_mobile">
            Connect with a growing community of learners
          </div>
          <Link to={`/virtual_campus`}>
            <div className="whatsapp_mobile">Join our Community</div>
          </Link>
        </div>
        <hr className="solid1_mobile" />

        <Grid container>
          <Grid className="footer_grid" item md={6} sm={12} xs={12}>
            <h1 className="footer_head1">About</h1>
            <p className="footer_para">
            The One Stop for learn {"->"} apply {"->"} experience model for real time application driven learning 
            </p>
          </Grid>
        <Grid className="footer_grid" item md={2} sm={12} xs={12}>
            <h1 className="footer_head1" >Navigate</h1>
            {/* <p className="footer_para">
              <a
                href="/fund"
                // target="_blank"
                className="footer_link"
              >
                Apply for funding
              </a>
            </p> */}
            <p className="footer_para">
            <div style={{cursor: 'pointer'}} onClick={handleClick} className="footer_link">
              Home
            </div>
            </p>
            <p className="footer_para">
              <a
                href={isAuth?"myDashboard/"+window.localStorage.id:"/login"}
                className="footer_link"
              >
                Profile
              </a>
            </p>
            <p className="footer_para">
              <Link to={`/courses`} className="footer_link">
                Courses
              </Link>
            </p>
            <p className="footer_para">
              <Link to={`/watch_now`} className="footer_link">
                Watch Now
              </Link>
            </p>

            <p className="footer_para">
              <Link to={isAuth?`/projects`:`/projectsLogin`} className="footer_link">
                Projects
              </Link>
            </p>
            <p className="footer_para">
              <a
                href="/Career"
                // target="_blank"
                className="footer_link"
              >
                Career
              </a>
            </p>
            <p className="footer_para">
              <a
                href="/mentor"
                // target="_blank"
                className="footer_link"
              >
                Become A Trainee
              </a>
            </p>
            <p className="footer_para">
              <a
                href="/hire"
                // target="_blank"
                className="footer_link"
              >
                 Hire with VSC

              </a>
            </p>
            <p className="footer_para">
              <a
                href="/scholarship"
                // target="_blank"
                className="footer_link"
              >
                 Apply For Scholarship

              </a>
            </p>
            <p className="footer_para">
              <a
                href="/blog"
                // target="_blank"
                className="footer_link"
              >
                Blogs
              </a>
            </p>
            <p className="footer_para">
              <a
                href="/events"
                // target="_blank"
                className="footer_link"
              >
                Events
              </a>
            </p>
            <p className="footer_para">
              <a
                href="/feedback"
                // target="_blank"
                className="footer_link"
              >
                Feedback
              </a>
            </p>
          </Grid>
          <Grid className="footer_grid" item md={1} sm={12} xs={12}>
          <h1 className="footer_head1">Quick Link</h1>
            <p className="footer_para">
              <a
                href="/privacy"
                className="footer_link"
              >
                Privacy Policy
              </a>
            </p>
            <p className="footer_para">
              <Link to={`/terms_conditions`} className="footer_link">
                Terms Of Use
              </Link>
            </p>
            <p className="footer_para">
              <Link to={`/refundpolicy`} className="footer_link">
                Refund Policy
              </Link>
            </p>
            <p className="footer_para">
              <Link to={`/about`} className="footer_link">
                About Us
              </Link>
            </p>
            
            <p className="footer_para">
              <Link to={`/contact`} className="footer_link">
                Contact
              </Link>
            </p>
          </Grid>
          <Grid className="footer_grid" item md={2} sm={12} xs={12}>
            <h1 className="footer_head1" >Programs</h1>
            {/* <p className="footer_para">
              <a
                href="/fund"
                // target="_blank"
                className="footer_link"
              >
                Apply for funding
              </a>
            </p> */}
            <p className="footer_para">
              <a
                href={"/corporateexposureprogram"}
                className="footer_link"
              >
                Full Stack Corporate Training Programs
              </a>
            </p>
            <p className="footer_para">
              <a
                href={"/"}
                className="footer_link"
              >
                Management Stack Full Stack Coder
              </a>
            </p>
            <p className="footer_para">
              <Link to={`/financialanalyst`} className="footer_link">
              Financial Analysis & Management Program 
              </Link>
            </p>
            <p className="footer_para">
              <Link to={`/managementconsulting`} className="footer_link">
               Management Consulting Program
              </Link>
            </p>

            <p className="footer_para">
              <Link to={'/productmanagement'} className="footer_link">
               Product Management Program 
              </Link>
            </p>
            <p className="footer_para">
              <a
                href="/fullstackdeveloper"
                // target="_blank"
                className="footer_link"
              >
               MERN Stack Coder Program
              </a>
            </p>
            <p className="footer_para">
              <a
                href="/pythoncoder"
                // target="_blank"
                className="footer_link"
              >
                Python Program 
              </a>
            </p>
            <p className="footer_para">
              <a
                href="/"
                // target="_blank"
                className="footer_link"
              >
                AI/ML Program
              </a>
            </p>
            <p className="footer_para">
              <a
                href="/"
                // target="_blank"
                className="footer_link"
              >
                Entrepreneurship Program
              </a>
            </p>
          </Grid>
          <Grid className="footer_grid" item md={2} sm={12} xs={12}>
            <h1 className="footer_head1">Contact Us</h1>
            <p style={{display:'flex' , flexDirection:'row' , alignItems:'center'}} >
            <Avatar  style={{background:'#000'}}>
              <CallIcon style={{color:'#f0f0f0'}}/> 
            </Avatar>
            +91-9310148556
            </p>
            <div
            style={{
              display:'flex',
              flexDirection:'row',
              alignItems:'center'
            }}
            >
            
            <Avatar 
            style={{
              background:'#000'
            }}
            >
                  <EmailIcon style={{color:'#fff'}}/> 
            </Avatar>
            <a 
                  href="mailto:hello@markoknow.com" 
                  className="footer_link"

                  >
                    hello@markoknow.com
                  </a>

            </div>
            <div
            style={{
              display:'flex',
              flexDirection:'row',
            }}
            >
            <Avatar 
            style={{
              background:'#000'
            }}
            >
                  <BusinessIcon style={{color:'#fff'}}/> 
            </Avatar>
              <p>
              Forum, Cyber City, DLF Forum, DLF Tower 10th Rd, Phase
              III, Gurugram, Haryana 122002
              </p>
            </div>
            
          </Grid>
          <Grid
            className="footer_grid"
            item
            md={12}
            sm={12}
            xs={12}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              marginTop: "2rem",
            }}
          >
            <div
              style={{
                width: "200px",
                textAlign: "center",
                fontSize: "1.8rem",
                fontWeight: "600",
                lineHeight: "24px",
                color: "#ffffff",
              }}
            >
              Get Started
              <p
                style={{
                  color: "#ffdb66",
                  fontSize: "1rem",
                  lineHeight: "28px",
                  fontWeight: "600",
                }}
              >
                Join the Program
              </p>
            </div>
            <div
              style={{
                width: "200px",
              }}
            >
              <Link to={isAuth ? `/dashboard/${window.localStorage.id}` :`/signup`}>
                <Button
                  variant="contained"
                  style={{
                    width: "100%",
                    padding: "1rem",
                    borderRadius: "12px",
                    fontFamily: "Open Sans",
                    fontWeight: "500",
                    lineHeight: "24px",
                    fontSize: "1.3rem",
                    backgroundColor: "#6a58c6",
                    textTransform: "capitalize",
                    marginTop: "8px",
                  }}
                >
                  Get started
                </Button>
              </Link>
            </div>
          </Grid>
          <hr className="solid2_mobile" />
          <div className="follow_mobile">
            <div style={{ padding: "0.7rem" }}>
              <a
                target="_blank"
                href="https://www.instagram.com/virtualstartupcampus/"
                rel="noopener noreferrer"
              >
                <InstagramIcon className="follow_icons_mobile" />
              </a>
              <a
                target="_blank"
                href="https://www.linkedin.com/company/virtualstartupcampus/"
                rel="noopener noreferrer"
              >
                <LinkedInIcon className="follow_icons_mobile" />
              </a>
              <a
                target="_blank"
                href="https://mobile.twitter.com/markoknow1"
                rel="noopener noreferrer"
              >
                <TwitterIcon className="follow_icons_mobile" />
              </a>
              <a
                target="_blank"
                href="https://www.facebook.com/markoknow1/"
                rel="noopener noreferrer"
              >
                <FacebookIcon className="follow_icons_mobile" />
              </a>
              <a
                target="_blank"
                href="https://www.youtube.com/channel/UCXY7oCF6cZ0DjqHhTxiKCJQ"
                rel="noopener noreferrer"
              >
                <YouTubeIcon
                  className="follow_icons_mobile"
                  style={{
                    marginRight: "0rem",
                  }}
                />
              </a>
            </div>
          </div>
        </Grid>
        <p style={{color:'#fff' , textAlign:'center'}}>© 2023 Markoknow - All Rights Reserved</p>
      </MobileView>
    </div>
  );
};

export default Footer;
