import React from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const Skills = ({isMobile}) => {
  return (
    <div style={{marginTop:'0'}}>
            <p style={{fontFamily:'sans-serif', textAlign:'left'}}>
            where the skills achieved :
            </p>
            {['Product Building Skils', 'Startup Marketing' , 'Crating Business Model'].map((skill) => (
                <ul style={{listStyle:'none' , lineHeight:isMobile ?'1' :'1.5', paddingLeft:'10px'}}>
                <li style={{textAlign:'left', display:'flex' , alignItems:'center' }}><CheckCircleIcon style={{color:'green', margin:isMobile?'1px':'5px', fontSize:isMobile&&'10px'}}/> {skill}</li>
                </ul>
            ))}
            
    </div>
  )
}

export default Skills