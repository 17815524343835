import React, { useState, useEffect } from "react";
import "../../../stylesheets/PromptAi.css";
import { Link , useNavigate } from "react-router-dom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Footer from "../../Footer/Footer";
import { Button, IconButton, useMediaQuery,Box ,Typography , Modal } from "@mui/material";
import practical from '../Images/practical.jpg';
import workex from '../Images/productworkex.png';
import project from '../Images/productproject.png';
import chatgpt from '../Images/chatgpt.png';
import openai from '../Images/openai.jpg';
import {faqs} from '../FAQDATA';
import certificate from '../Images/productpractical.png';
import DownloadBrochureDialog from '../DownloadForOthers';
import weekend from '../Images/weekend.png';
import resume from '../Images/resume.png';
import mock from '../Images/mock.png';
import internship from '../Images/internship.png';
import management from '../Images/management.png';
import capstone from '../Images/capstone.png';
import profile from '../Images/profile.png';
import senior from '../Images/senior.png';


import {blogs} from '../../Testimonial/testmonialData';

import Testimonial from "../../Testimonial";

import Accordion from "../Accordion/index";
import InfoBox from "../InfoBox";
const FullStackDeveloper= ({ isAuth, setAuth }) => {
  isAuth = true;
  const arr = [
    {
      "title": "8 Weekend Corporate Training Live Session",
      "imgPath": weekend
    },
    {
      "title": "Resume Building Session",
      "imgPath": resume
    },
    {
      "title": "Mock Interview Practice Scheduling",
      "imgPath": mock
    },
    {
      "title": "Intership/Job Profile Review",
      "imgPath": internship
    },
    {
      "title": "Senior Management Interaction",
      "imgPath": senior
    },
    {
      "title": "Management Portfolio Building",
      "imgPath": management
    },
    {
      "title": "Capstone Project",
      "imgPath": capstone
    },
    {
      "title": "Profile SWOT Analysis",
      "imgPath": profile
    }
  ]
  
  const training_elements = [
    "Explore Management Career",
    "Learn Practical Implementation of Topics",
    "Training on Management Roles",
    ];
  const isMobile = useMediaQuery('(max-width:820px)');
  const [img , setImg] = React.useState(certificate);
  const navigate = useNavigate();
  
  return (
    <>
      <div className="details_main_prompt">
        <div className="headingp">
           <h1>AI/ML CODER</h1>
           <Typography 
                varian="h4"
                className="yellow-box"
                style={{
                fontFamily:'Poppins',
                color: '#000',
                textAlign: 'left',
                marginBottom:'10px',
                fontWeight: 900    
                }}>You Get To:</Typography>
           <div className="tick-boxes-prompt-box3">
                {
                    training_elements.map((t,i) => (
                    <p
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        color: "#000",
                        fontFamily: "Open sans",
                        fontSize: "20px",
                        wordWrap: "break-word",
                        // width:'100%'
                      }}
                    >
                      <CheckCircleIcon style={{color: 'green', margin:'1%' ,  fontSize:'35px'}}/>
                      {t}
                    </p>
                    ))
                }
            </div>
            <div className="buttons-promtl" >
                <Button 
                    href="/virtual_campus"
                     variant="contained"
                    className="button-promptl-enroll"
                    style={{
                        background: '#6a58c6',
                        marginBottom: '20px'
                    }}
                 >Enroll Now</Button>
                {/* <Button
                    variant="outlined"
                    className="button-promptl-download"
                    style={{
                      color: '#6a58c6',
                      borderColor: '#6a58c6',
                      marginBottom: '20px'
                    }}
                    // onClick={()=> {isAuth && handleDownload();}}
                  >
                    Download Brochure
                  </Button> */}
                  <DownloadBrochureDialog isMobile={isMobile} courseName={'Product Management'}/>


            </div>
            {/* <div className="batch-box" style={{border: '2px #000 solid'}}> 
                <Typography style={{color:'#6a58c6' , fontSize:'20px' , marginLeft:'0' , width:'50%'}}>Batch Staring<br/> On </Typography>
                <Typography  style={{fontSize:'20px', marginLeft:'0' , display:'flex', width:'50%' , alignItems:'center'}}>{'7th June 2024'}</Typography>
            </div> */}

        </div>
        
        <div className="box_of_data">
          <div className="sidebar2"></div>
          <div className="sidebar">
            <div style={{ display: "flex justify-around" }}>
              <p className="sidebar_heading">
                AI/ML CODER<br />
              </p>
              <a href="#outcome-box">
                <p className="sidebar_data">Programme Snapshot</p>
              </a>
              <a href="#certificate-box">
                <p className="sidebar_data">Work Ex Certificates</p>{" "}
              </a>
              <a href="#vsc-students">
                <p className="sidebar_data">Vsc Students</p>
              </a>

              <a href="#vsc-pathway">
                <p className="sidebar_data">Pathway</p>
              </a>
              <a href="#vsc-tools">
                <p className="sidebar_data">Tools</p>
              </a>
              <a href="#vsc-timeline">
                <p className="sidebar_data">Timeline </p>
              </a>
              <a href="#vsc-faq">
                <p className="sidebar_data">FAQ </p>
              </a>
            </div>
          </div>
        </div>
        <Box  sx={{
          marginBottom: '20px',
          marginLeft: {sm:'0' , md: '22rem'}
        }}>
                  <Typography variant="h6" textAlign={'left'} marginBottom={'20px'} fontFamily={'Poppins'} fontWeight={'500'}>
                  Get training, practice in Labs, work on live projects and get work experience in just 1 Month
                  </Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection:{sm: 'column' , md: 'row'},
                      jutifyContent: 'space-evenly',
                      alignItems:'center' , 
                      flexWrap: 'wrap'
                    }}
                  >
                  {arr.map((ele , i) => (
                    <Box
                      sx={{display:'flex' , flexDirection: 'row' , alignItems:'center' , justifyContent:'space-evenly' , width: {sm: '100%' , md: '50%'} , gap: '40px'}}
                    >
                      <Box sx={{
                        width: '20%'
                      }}>
                      <img src={ele.imgPath} width="80" height="80"/>
                      </Box>
                      
                      <Typography variant="subtitle1" sx={{fontWeight: '400' , width: '80%'}}>
                        {ele.title}
                      </Typography>
                    </Box>
                  ))}
                    
                  </Box>
        </Box>
                <h2 style={{width: '100%' , textAlign:'center'}}>
                <u style={{textDecorationColor: '#FFBD59' , textDecorationThickness: '5px'}}>Industry Certificates</u>
                </h2>
        <div className="certificate-box" id="certificate-box">
                
                <h2>Work Ex Certificates</h2>
                <p>This program offers a unique <br/>opportunity to gain 3 Certificates </p>
                <div className="buttons-certificate-box">
                    <div className="button-certificate-box" onClick={()=> setImg(certificate)}>Lab Training</div>
                    <div className="button-certificate-box" onClick={()=> setImg(project)}>Capstone Project</div>
                    <div className="button-certificate-box" onClick={()=> setImg(workex)}>Internship Certificate</div>
                </div>
                <div className="cert-image-container">
                <img src={img} alt="certificate" className="certificate-img"/>
                
                </div>
                
        </div>
       
        <div className="vsc-students" id="vsc-students">  
                <h1>
                <u style={{textDecorationColor: '#FFBD59' , textDecorationThickness: '5px'}}>VSC Students</u>
                </h1>
                <Testimonial from="promptai"/>
        </div>
        <div className="pathway-prompt" id="vsc-pathway">
                <h2>
                <u style={{textDecorationColor: '#FFBD59' , textDecorationThickness: '5px'}}>Pathway</u>
                </h2>
                <div style={{display:'flex' , flexDirection:'row'}}>
                <div class="vertical-dots">
                        <div class="dot"></div>
                        <div class="dot"></div>
                        <div class="dot"></div>
                        <div class="dot no-line"></div>
                        </div>
                <div className="pathway-container">
                    <div className="pathway-box" style={{ backgroundColor: '#6a58c6', borderRadius: '20px' , color:'#fff' }}>
                        <h2 style={{color:'#fff'}} >Week 1 : Training Modules</h2>
                        <p>Learn the concepts end to end with self paced modules to help you get the idea on the topics</p>
                    </div>
                    <div className="pathway-box" style={{ backgroundColor: '#FF5757', borderRadius: '20px' , color: '#fff' }}>
                        <h2 style={{color:'#fff'}}>Week 2 : Capstone Project</h2>
                        <p>Apply Learning from Training in a Capstone Project to where you understand your own foothold on the topics.</p>
                    </div>
                    <div className="pathway-box" style={{ backgroundColor: '#7AFFC9', borderRadius: '20px' , color: '#000' }}>
                        <h2>Week 3 : Remote Industrial Work Exp.</h2>
                        <p>Start Your Remote Corporate Training as a Trainee  on the Job training for more understanding of the role</p>
                    </div>
                    <div className="pathway-box" style={{ backgroundColor: '#FFBD59', borderRadius: '20px' , color: '#000' }}>
                        <h2>Week 4 : Remote Industrial Work Exp.</h2>
                        <p>Get help with final portfolio presentation, resume building, Mock Interview, Interview Preparation </p>
                    </div>
                    
                    </div>
                    
                        </div>
        </div>
        <div className="suitable-for-prompt">
                    <h2><u style={{textDecorationColor: '#FFBD59' , textDecorationThickness: '5px'}}>Suitable For</u></h2>
                    <p><span class="dot-span"></span>Students of All Streams Looking To Pursue Management Career </p>
                    <p><span class="dot-span"></span>Students who are doing MBA</p>
                    <p><span class="dot-span"></span>Students looking to attain management roles in future in their Job</p>
        </div>
        <div className="borderbox-for-prompt" id="vsc-timeline">
                    {/* <h2><u style={{textDecorationColor: '#FFBD59' , textDecorationThickness: '5px'}}>Important Info</u></h2>
                    <div className="border-boxes-row-prompt">
                    <div className="batch-box-2"> 
                        <Typography style={{color:'#6a58c6' , fontSize:'20px' , marginLeft:'0' , width:'50%'}}>Batch Staring<br/> On </Typography>
                        <Typography  style={{fontSize:'20px', marginLeft:'0' , display:'flex', width:'50%' , alignItems:'center'}}>{'4th March 2024'}</Typography>
                    </div>
                    <div className="batch-box-2"> 
                        <Typography style={{color:'#6a58c6' , fontSize:'20px' , marginLeft:'0' , width:'50%' , display:'flex' , alignItems: 'center'}}>Duration<br/>  </Typography>
                        <Typography  style={{fontSize:'20px', marginLeft:'0' , display:'flex', width:'50%' , alignItems:'center'}}>1 Month</Typography>
                    </div>
                    </div> */}
                    <InfoBox/>
        </div>
        <div className="faq-box-prompt" id="vsc-faq">
            <h2><u style={{textDecorationColor: '#FFBD59' , textDecorationThickness: '5px'}}>FAQs</u></h2>
                  {faqs.map((faq) => (
                    <Accordion question={faq.question} answer={faq.answer}/>
                  ))}  
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </>
  );
};

export default FullStackDeveloper;
