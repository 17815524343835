import { Box , Button, Typography } from '@mui/material'
import * as React from 'react';
import axios from 'axios';
import { GET_C_L_COMPONENT } from '../../../api/courselandingcomponent';

const InfoBox = () => {
    const [data , setData] = React.useState({});
    const currentUrl = window.location.href;
const segments = currentUrl.split('/');
const lastSegment = segments[segments.length - 1];
const [show , setShow] = React.useState(false);
React.useEffect(() => {
    axios
        .get(GET_C_L_COMPONENT)
        .then(res => {
            const matchingCourse = res.data.data?.filter(course => {
                return course?.courseName.split(" ").join("").toLowerCase() === lastSegment;
            });
            if (matchingCourse.length > 0) {
                setData(matchingCourse[0]);
                setShow(true);
            }
        })
        .catch(err => {
            console.error(err);
        });
}, [lastSegment]);
  return (
    <>
    {show && (<Box
        sx={{
            // background: '#000',
            background: 'linear-gradient(#6a58c6 , #000)',
            // background: 'linear-gradient(#000 , #6a58c6)',
            color: '#fff',
            boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px',
            borderRadius: '10px',
            padding: '20px',
            display: 'flex',
            flexDirection: {xs: 'column' , sm: 'row'},
            alignItems: {sm:'flex-start' , xs: 'center'},
            justifyContent : {sm: 'flex-start' , xs: 'center'},
            width: {xs: '100%' , sm:'70%'},
            height: {xs:'100%' }
        }}
    >
        <Box
            sx={{
                width: {xs: "100%" , sm: "80%"}
            }}
        >
            <Typography variant="h4" 
                sx={{
                    textAlign: {sm: 'left' , xs: 'center'}
                }}
            > {data.courseName}</Typography>
            <Typography variant="h5" gutterBottom sx={{
                    textAlign: {sm: 'left' , xs: 'center'}
                }}>@<span style={{textDecoration:'line-through' }}>&#8377;{data.crossedPrice}</span>  &#8377;{data.finalPrice}</Typography>
            <Typography variant="caption" sx={{marginTop: '10px', fontSize: '16px' , textAlign: {sm: 'left' , xs: 'center'}}}>{data.subheading}</Typography>
            <Box
                sx={{
                    display:'flex',
                    flexDirection: {sm: 'row' ,  xs: 'column' },
                    gap: '10px',
                    marginTop: '20px',
                    marginBottom: '10px'
                }}
            >
                <Button
                    href={data.btn1Link}
                    sx={{
                        border: ' 0.5px solid #FFBD59',
                        color: '#fff'
                    }}
                >
                    {data.btn1Name}
                </Button>
                <Button
                    href={data.btn2Link}
                    sx={{
                        background: '#FFBD59',
                        color: '#000'

                    }}
                >
                    {data.btn2Name}
                </Button>

            </Box>
            <Typography sx={{
                    textAlign: {sm: 'left' , xs: 'center'}
                    , fontSize: '16px' 
                }} variant="caption">{data.scc}</Typography>
        </Box>
        <Box
            sx={{
                width: {xs: "100%" ,sm:"20%"},
                mt: {xs: '10px' , sm: '0'},
                display: 'flex', 
                flexDirection:'column',
                gap:'10px',
                justifyContent: {sm: 'flex-start' , xs: 'center'}
            }}
        >
            <Typography sx={{
                    textAlign: {sm: 'left' , xs: 'center'}
                    , fontSize: '20px' 
                }} variant="subtitle2">Schedule</Typography>
            <Typography 
            sx={{
                    textAlign: {sm: 'left' , xs: 'center'},
                    width: '100%',
                    fontSize: '16px'
                }} variant="subtitle" >{data.duration}</Typography>
            <Typography sx={{
                    textAlign: {sm: 'left' , xs: 'center'}, fontSize: '20px'
                }} variant="subtitle2">Starting Date</Typography>  
            <Typography sx={{
                    textAlign: {sm: 'left' , xs: 'center'},
                    width: '100%',
                    fontSize: '16px'
                }} variant="subtitle">{data.startingDate}
                
                </Typography>      
        </Box>            
    </Box>)}
    </>
  )
}

export default InfoBox