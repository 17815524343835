import React, { useState, useEffect } from "react";
import "../../stylesheets/PromptAi.css";
import { Link , useNavigate } from "react-router-dom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Footer from "../Footer/Footer";
import { Button, IconButton, useMediaQuery,Box ,Typography , Modal } from "@mui/material";
import practical from './Images/practical.jpg';
import workex from './Images/workex.jpg';
import project from './Images/project.jpg';
import chatgpt from './Images/chatgpt.png';
import dalle from './Images/dalle.png';
import openai from './Images/openai.jpg';
import {faqs} from './FAQDATA';
import axios from 'axios';
import { useParams } from 'react-router-dom';  // Import useParams
import Testimonial from "../Testimonial";
import Accordion from "./Accordion";
const DOMAIN = process.env.REACT_APP_DOMAIN;

const PromptAI = ({ isAuth, setAuth }) => {
    const { id } = useParams();

  const isMobile = useMediaQuery('(max-width:820px)');
  const [img , setImg] = React.useState(practical);
  const navigate = useNavigate();
  const [heading, setHeading] = useState(null);
  const [duration, setDuration] = useState(null);
  const [PathwayWeek1Content, setPathwayWeek1Content] = useState(null);
  const [PathwayWeek2Content, setPathwayWeek2Content] = useState(null);
  const [PathwayWeek3Content, setPathwayWeek3Content] = useState(null);
  const [PathwayWeek4Content, setPathwayWeek4Content] = useState(null);

  const [PathwayWeek1Heading, setPathwayWeek1Heading] = useState(null);
  const [PathwayWeek2Heading, setPathwayWeek2Heading] = useState(null);
  const [PathwayWeek3Heading, setPathwayWeek3Heading] = useState(null);
  const [PathwayWeek4Heading, setPathwayWeek4Heading] = useState(null);
  
  const [SuitableFor1, setSuitableFor1] = useState(null);
  const [SuitableFor2, setSuitableFor2] = useState(null);
  const [SuitableFor3, setSuitableFor3] = useState(null);

  const [batchStarting, setbatchStarting] = useState(null);

  const [programSnapshot1, setprogramSnapshot1] = useState(null);
  const [programSnapshot2, setprogramSnapshot2] = useState(null);
  const [programSnapshot3, setprogramSnapshot3] = useState(null);
  const [programSnapshot4, setprogramSnapshot4] = useState(null);
  const [programSnapshot5, setprogramSnapshot5] = useState(null);
  const [programSnapshot6, setprogramSnapshot6] = useState(null);
  const [programSnapshot7, setprogramSnapshot7] = useState(null);
  const [programSnapshot8, setprogramSnapshot8] = useState(null);

  const [subHeading, setsubHeading] = useState(null);

  const [threePointersContent1, setthreePointersContent1] = useState(null);
  const [threePointersContent2, setthreePointersContent2] = useState(null);
  const [threePointersContent3, setthreePointersContent3] = useState(null);

  const [threePointersHeading1, setthreePointersHeading1  ] = useState(null);
  const [threePointersHeading2, setthreePointersHeading2  ] = useState(null);
  const [threePointersHeading3, setthreePointersHeading3  ] = useState(null);

  const [toolsToLearn1, setthreetoolsToLearn1  ] = useState(null);
  const [toolsToLearn2, setthreetoolsToLearn2  ] = useState(null);
  const [toolsToLearn3, setthreetoolsToLearn3  ] = useState(null);
  const [yellowPart, setyellowPart  ] = useState(null);
  const [certificate, setCertificate  ] = useState(null);
  const [brochureSyllabus, setbrochureSyllabus  ] = useState(null);

  const training_elements = [
    programSnapshot1,
    programSnapshot2,
    programSnapshot3,
    programSnapshot4,
    ];
    const modules = [
      programSnapshot5,
      programSnapshot6,
      programSnapshot7,
      programSnapshot8,
      ];

  useEffect(() => {
    console.log(id);
    // Make the GET request using Axios
      axios.get(`${DOMAIN}/api/getuniqueURLbyId/${id}`)
      .then(response => {
        console.log('Response data:', response.data);
  
          // Assuming the API response is an array of items
          const firstItem = response.data;
          console.log('First item heading:', firstItem.heading);
          setHeading(firstItem.heading);
          setDuration(firstItem.Duration);
          setPathwayWeek1Content(firstItem.PathwayWeek1Content);
          setPathwayWeek2Content(firstItem.PathwayWeek2Content);
          setPathwayWeek3Content(firstItem.PathwayWeek3Content);
          setPathwayWeek4Content(firstItem.PathwayWeek4Content);

          setPathwayWeek1Heading(firstItem.PathwayWeek1Heading);
          setPathwayWeek2Heading(firstItem.PathwayWeek2Heading);
          setPathwayWeek3Heading(firstItem.PathwayWeek3Heading);
          setPathwayWeek4Heading(firstItem.PathwayWeek4Heading);

          setSuitableFor1(firstItem.SuitableFor1);
          setSuitableFor2(firstItem.SuitableFor2);
          setSuitableFor3(firstItem.SuitableFor3);

          setbatchStarting(firstItem.batchStarting);

          setprogramSnapshot1(firstItem.programSnapshot1);
          setprogramSnapshot2(firstItem.programSnapshot2);
          setprogramSnapshot3(firstItem.programSnapshot3);
          setprogramSnapshot4(firstItem.programSnapshot4);
          setprogramSnapshot5(firstItem.programSnapshot5);
          setprogramSnapshot6(firstItem.programSnapshot6);
          setprogramSnapshot7(firstItem.programSnapshot7);
          setprogramSnapshot8(firstItem.programSnapshot8);

          setsubHeading(firstItem.subHeading);
          
          setthreePointersContent1(firstItem.threePointersContent1);
          setthreePointersContent2(firstItem.threePointersContent2);
          setthreePointersContent3(firstItem.threePointersContent3);

          setthreePointersHeading1(firstItem.threePointersHeading1);
          setthreePointersHeading2(firstItem.threePointersHeading2);
          setthreePointersHeading3(firstItem.threePointersHeading3);

          setthreetoolsToLearn1(firstItem.threetoolsToLearn1);
          setthreetoolsToLearn2(firstItem.threetoolsToLearn2);
          setthreetoolsToLearn3(firstItem.threetoolsToLearn3);

          setyellowPart(firstItem.yellowPart);

          setCertificate(firstItem.brochureSyllabus);
          setbrochureSyllabus(firstItem.brochurePath);

          setthreetoolsToLearn1(firstItem.toolsToLearn1);
          setthreetoolsToLearn2(firstItem.toolsToLearn2);
          setthreetoolsToLearn3(firstItem.toolsToLearn3);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, [id]);
   // Empty dependency array means this effect runs once after the initial render

  return (
    <>
      <div className="details_main_prompt">
        <div className="headingp">
           <h1>{heading}</h1>
           <Typography 
                className="yellow-box"
                style={{
                background:'#FFBD59',
                width: '280px',
                height: '32px',
                fontFamily:'Poppins',
                fontSize:'20px',
                color: '#000',
                textAlign: 'center',
                marginBottom:'20px'
                }}>{yellowPart}</Typography>
           <p>{subHeading} </p>
            <div className="buttons-promtl" >
                <Button 
                    href="/virtual_campus"
                     variant="contained"
                    className="button-promptl-enroll"
                    style={{
                        background: '#6a58c6',
                        marginBottom: '20px'
                    }}
                 >Enroll Now</Button>
                <Button 
                        onClick={() => window.location.href = `http://localhost:3002/files/CourseProgram/${brochureSyllabus}`}
                        variant="outlined"
                         className="button-promptl-download"
                         style={{
                        color: '#6a58c6',
                        borderColor: '#6a58c6',
                        marginBottom: '20px'
                    }}
                    >Download Brochure</Button>

            </div>
            <div className="batch-box"> 
                <Typography style={{color:'#6a58c6' , fontSize:'20px' , marginLeft:'0' , width:'50%'}}>Batch Staring<br/> On </Typography>
                <Typography  style={{fontSize:'20px', marginLeft:'0' , display:'flex', width:'50%' , alignItems:'center'}}>{batchStarting}</Typography>
            </div>

        </div>
        
        <div className="box_of_data">
          <div className="sidebar2"></div>
          <div className="sidebar">
            <div style={{ display: "flex justify-around" }}>
              <p className="sidebar_heading">
                Prompt Engineering <br />
              </p>
              <a href="#outcome-box">
                <p className="sidebar_data">Programme Snapshot</p>
              </a>
              <a href="#certificate-box">
                <p className="sidebar_data">Work Ex Certificates</p>{" "}
              </a>
              <a href="#vsc-students">
                <p className="sidebar_data">Vsc Students</p>
              </a>

              <a href="#vsc-pathway">
                <p className="sidebar_data">Pathway</p>
              </a>
              <a href="#vsc-tools">
                <p className="sidebar_data">Tools</p>
              </a>
              <a href="#vsc-timeline">
                <p className="sidebar_data">Timeline </p>
              </a>
              <a href="#vsc-faq">
                <p className="sidebar_data">FAQ </p>
              </a>
            </div>
          </div>
        </div>
        <div className="outcome-box" id="outcome-box">
            <h3>Programme Snapshot</h3>
            <div className="tick-boxes-prompt">
            <div className="tick-boxes-prompt-box1">
                {
                    training_elements.map((t,i) => (
                    <p
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        color: "#000",
                        fontFamily: "Open sans",
                        fontSize: "18px",
                        wordWrap: "break-word",
                        margin: '20px',
                        width:'100%'
                      }}
                    >
                      <CheckCircleIcon style={{color: 'green', margin:'1%' ,  fontSize:'35px'}}/>
                      {t}
                    </p>
                    ))
                }
            </div>
            <div className="tick-boxes-prompt-box2">
            {
                    modules.map((t,i) => (
                        <p style={{
                            display: 'flex',
                            alignItems: 'center',
                            color: '#000',
                            fontFamily: 'Open sans',
                            fontSize: '18px',
                            wordWrap: 'break-word',
                            margin: '20px',
                            width:'100%'
                            }}>
                            <CheckCircleIcon style={{ color: 'green', margin: '1%' , fontSize:'35px'}} />
                            {t}
                        </p>
                    ))
                }
            </div>
            </div>
            <div className="download-syllabus-button">
            {/* <Button variant="contained" style={{ backgroundColor:'#6a58c6'}}>Download Syllabus</Button> */}
            </div>
        </div>
        <div className="certificate-box" id="certificate-box">
                <h2>Work Ex Certificates</h2>
                <p>This program offers a unique <br/>opportunity to gain 3 Certificates </p>
                <div className="buttons-certificate-box">
                    <div className="button-certificate-box" onClick={()=> setImg(practical)}>Lab Training</div>
                    <div className="button-certificate-box" onClick={()=> setImg(project)}>Capstone Project</div>
                    <div className="button-certificate-box" onClick={()=> setImg(workex)}>Internship Certificate</div>
                </div>
                <div className="cert-image-container">
                <img src={`http://localhost:3002/files/CourseProgram/${certificate}`} alt="certificate" className="certificate-img" />
                <span style={{textAlign:'left',fontSize:isMobile?'26px':'40px' , fontFamily: 'Parisienne' , position:'absolute' , top:isMobile?'34%':'40%' , left:'35%' }}>{window.localStorage.name}</span>
                </div>
                
        </div>
        <div className="list-div-container">
                <ul className="concentric-bullet-ul">
                    <li className="concentric-bullet">{threePointersHeading1}<br/><span style={{fontSize:'14px',  fontWeight:'400'}}>{threePointersContent1}</span></li>
                    <li className="concentric-bullet">{threePointersHeading2}<br/><span style={{fontSize:'14px',  fontWeight:'400'}}>{threePointersContent2}</span></li>
                    <li className="concentric-bullet">{threePointersHeading3}<br/><span style={{fontSize:'14px',  fontWeight:'400'}}>{threePointersContent3}</span></li>
                </ul>
                <Button variant="contained" style={{ backgroundColor:'#6a58c6' , marginTop:'40px'}}>Download Syllabus</Button>
        </div>
        <div className="vsc-students" id="vsc-students">  
                <h1>
                <u style={{textDecorationColor: '#FFBD59' , textDecorationThickness: '5px'}}>VSC Students</u>
                </h1>
                <Testimonial from={"promptai"}/>
        </div>
        <div className="pathway-prompt" id="vsc-pathway">
                <h2>
                <u style={{textDecorationColor: '#FFBD59' , textDecorationThickness: '5px'}}>Pathway</u>
                </h2>
                <div style={{display:'flex' , flexDirection:'row'}}>
                <div class="vertical-dots">
                        <div class="dot"></div>
                        <div class="dot"></div>
                        <div class="dot"></div>
                        <div class="dot no-line"></div>
                        </div>
                <div className="pathway-container">
                    <div className="pathway-box" style={{ backgroundColor: '#6a58c6', borderRadius: '20px' , color:'#fff' }}>
                        <h2 style={{color:'#fff'}} >{PathwayWeek1Heading}</h2>
                        <p>{PathwayWeek1Content}</p>
                    </div>
                    <div className="pathway-box" style={{ backgroundColor: '#FF5757', borderRadius: '20px' , color: '#fff' }}>
                        <h2 style={{color:'#fff'}}>{PathwayWeek2Heading}</h2>
                        <p>{PathwayWeek2Content}</p>
                    </div>
                    <div className="pathway-box" style={{ backgroundColor: '#7AFFC9', borderRadius: '20px' , color: '#000' }}>
                        <h2>{PathwayWeek3Heading}</h2>
                        <p>{PathwayWeek3Content}</p>
                    </div>
                    <div className="pathway-box" style={{ backgroundColor: '#FFBD59', borderRadius: '20px' , color: '#000' }}>
                        <h2>{PathwayWeek4Heading}</h2>
                        <p>{PathwayWeek4Content}</p>
                    </div>

                    
                    </div>
                    
                        </div>
        </div>
        <div className="tools-box-prompt" id="vsc-tools">
                <h2><u style={{textDecorationColor: '#FFBD59' , textDecorationThickness: '5px'}}>Tools You Will Learn</u></h2>
                <div className="image-container-prompt">
                    <Typography variant='h4'>
                    {toolsToLearn1}
                    </Typography>
                    <Typography variant='h4'>
                    {toolsToLearn2}
                    </Typography>
                    <Typography variant='h4'>
                    {toolsToLearn3}
                    </Typography>
                </div>
                
        </div>
        <div className="suitable-for-prompt">
                    <h2><u style={{textDecorationColor: '#FFBD59' , textDecorationThickness: '5px'}}>Suitable For</u></h2>
                    <p><span class="dot-span"></span>{SuitableFor1}</p>
                    <p><span class="dot-span"></span>{SuitableFor2}</p>
                    <p><span class="dot-span"></span>{SuitableFor3}</p>
        </div>
        <div className="borderbox-for-prompt" id="vsc-timeline">
                    <h2><u style={{textDecorationColor: '#FFBD59' , textDecorationThickness: '5px'}}>Important Info</u></h2>
                    <div className="border-boxes-row-prompt">
                    <div className="batch-box-2"> 
                        <Typography style={{color:'#6a58c6' , fontSize:'20px' , marginLeft:'0' , width:'50%'}}>Batch Staring<br/> On </Typography>
                        <Typography  style={{fontSize:'20px', marginLeft:'0' , display:'flex', width:'50%' , alignItems:'center'}}>{batchStarting}</Typography>
                    </div>
                    <div className="batch-box-2"> 
                        <Typography style={{color:'#6a58c6' , fontSize:'20px' , marginLeft:'0' , width:'50%' , display:'flex' , alignItems: 'center'}}>Duration<br/>  </Typography>
                        <Typography  style={{fontSize:'20px', marginLeft:'0' , display:'flex', width:'50%' , alignItems:'center'}}>{duration}</Typography>
                    </div>
                    </div>
        </div>
        <div className="faq-box-prompt" id="vsc-faq">
            <h2><u style={{textDecorationColor: '#FFBD59' , textDecorationThickness: '5px'}}>FAQs</u></h2>
                
                  {faqs.map((faq) => (
                    <Accordion question={faq.question} answer={faq.answer}/>
                  ))}  
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </>
  );
};

export default PromptAI;
