import { Box, Grid, TextField } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { validateEmail } from "./validateEmail";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import "../../stylesheets/loginform.css";
import axios from "axios";
import ReactGA from "react-ga";
import LoginwithGoogle from "./LoginwithGoogle";
import Typewriter from "typewriter-effect";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { IconButton, Button } from "@mui/material";
import InputAdornment from '@mui/material/InputAdornment';

const Login = ({ isAuth, setIsAuth }) => {

  const location = useLocation();
  const redirectPath = location?.state?.redirect || '/';
  const history = useNavigate();
  if (isAuth) {
    history(redirectPath);
    // history.push("/profile/" + window.localStorage.getItem("id"));
  }

  const { SIGN_IN_API , SIGN_UP_API , RESEND_EMAIL } = require("../../api/vsc");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [password, setPassword] = useState("");
  const [resp, setResp] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error , setError] = useState('');
  const navigation = useNavigate();
  
  useEffect(() => {
    if (!email) {
      setEmailError("");
    } else {
      if (validateEmail(email)) {
        setEmailError("");
      } else {
        setEmailError("Please enter a valid email.");
      }
    }
  }, [email]);

  const clearset = () => {
    setEmail("");
    setPassword("");
    setEmailError("");
    setResp("");
  };
  const handleResendVerify = (e) => {
      e.preventDefault();
      const sendMail = {
        email: email,
        path: '/vsc',
        resend: true
      }
      axios
      .post(RESEND_EMAIL , sendMail)
      .then(res => {});

  }
  const handleSubmit = (e) => {
    e.preventDefault();
    const registered = {
      email: email,
      password: password,
    };
 

    axios
      .post(SIGN_IN_API, registered)
      .then((response) => {
        if (response.status === 200) {
          window.localStorage.setItem("name", response.data.user.name);
          window.localStorage.setItem("fullName", response.data.user.fullName);
          window.localStorage.setItem("email", response.data.user.email);
          window.localStorage.setItem("id", response.data.user._id);
          window.localStorage.setItem("token", response.data.token);
          window.localStorage.setItem("isAuth", true);
          window.localStorage.setItem(
            "DontAskAgain",
            response.data.user.DontAskAgain
          );
          window.localStorage.setItem("isAdmin", response.data.user.isAdmin);
          window.localStorage.setItem(
            "isApplyExperienceFilled",
            response.data.user.isApplyExperienceFilled
          );
          window.localStorage.setItem(
            "point",
            response.data.user.markoknowPoints
          );
          window.localStorage.setItem(
            "eventNumbers",
            JSON.stringify(response.data.user.eventNumber)
          );
          window.localStorage.setItem(
            "referralId",
            response.data.user.referralId
          );
          setIsAuth(true);
          clearset();
          navigation(redirectPath);
          window.location.reload();
        } else if (response.status === 203) {
          setResp(response.data.message);
        }
      })
      .catch((error) => {
        clearset();
      });
  };
  
  const handlePasswordVisibility = () => {
    setShowPassword(!showPassword);
  }; 
  const [state1] = useState({
    title: "Virtual Startup Campus",
    titleTwo: "Welcomes you",
  });
  return (
    <div className="Login_Form">
      <Grid container>
        <Grid item lg={6} md={12} sm={12}>
          <div className="left_box">
            <h4 className="main-heading_vsc">
              <div className="titleLogin">{state1.title}</div>
              <div className="titleTwoLogin">{state1.titleTwo}</div>
              {/* <div className="titleThreeLogin">{state1.titleThree}</div> */}
            </h4>
            {/* <div className="textLogin">
              <Typewriter
                options={{
                  autoStart: true,
                  loop: true,
                  delay: 40,
                  strings: [
                    "50,000+ community platform for learners and professionals to get ",
                      "Industry Ready Application Driven Skills",
                  ],
                }}
              />
            </div> */}
          </div>
        </Grid>
        <Grid item lg={6} md={12} sm={12}>
          <div className="right_box">
            <form className="login_form" onSubmit={handleSubmit}>
              <div
                style={{
                  textAlign: "center",
                  paddingBottom: "15px",
                }}
              >
              {resp&&<div style={{ width: "100%", margin: "auto" }} className="Alert_type">
                <Alert severity="error">
                  <AlertTitle>ERROR OCCURED</AlertTitle>
                  <strong>{resp} {resp === 'Email is not verified' && <Button variant="contained" onClick={handleResendVerify} style={{background: '#6A58C6' , color:'#fff'}}>Resend Email</Button>}</strong>
                </Alert>
              </div>}
                <h3>Login</h3>
                <div style={{ margin: "20px" }}>
                  <LoginwithGoogle setIsAuth={setIsAuth} />
                </div>
              </div>

              <span className="error">{emailError}</span>
              <div>
                <TextField
                  style={{ paddingBottom: "15px", width: "100%" }}
                  value={email}
                  variant="outlined"
                  onChange={(e) => {
                        if(e.target.value.length===0){
                          setResp('');  
                        }
                        
                        setEmail(e.target.value)}}
                  type="email"
                  required
                  label="Email"
                />

                <TextField
                      style={{ paddingBottom: '15px', width: '100%' }}
                      value={password}
                      variant="outlined"
                      onChange={(e) => {
                        if(e.target.value.length===0){
                          setResp('');  
                        }
                        
                        setPassword(e.target.value)}}
                      type={showPassword ? 'text' : 'password'}
                      label="Password"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={handlePasswordVisibility} edge="end">
                              {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    {/* <p style={{color:'red'}}>{resp}</p> */}
              </div>

              <div className="not_member">
                Don't have an account?{" "}
                <Link exact="true" state={{ redirect: redirectPath }} to="/signup" style={{ color: "#2773a5" }}>
                  Sign Up
                </Link>
                <a
                  href="/forgotpassword"
                  style={{ paddingLeft: "0.9rem", color: "#2773a5" }}
                >
                  Forgot Password?
                </a>
              </div>
              <br />

              <div className="auth_button">
                <button
                  type="submit"
                  variant="contained"
                  className="btn-primary button_login"
                >
                  Login
                </button>
              </div>
            </form>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default Login;
