import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Collapse from '@mui/material/Collapse';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ListItemText from '@mui/material/ListItemText';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import LinearWithValueLabel from './LinearProgressWithLabel';
import CheckIcon from '@mui/icons-material/Check';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import congrats from './congrats.png';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import PDFViewer from './PDFViewer';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import PropTypes from 'prop-types';
import Slide from '@mui/material/Slide';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { GET_COURSES, GET_COURSE, VIDEO, GET_MODULES, PDF , USER, POST_REMOVED, POST_COMPLETED, POST_ALL_COMPLETED, GET_USER, SET_COURSEDETAILS, POST_COMPLETED_VIDEO, POST_COMPLETED_PDF, QUESTION, GET_PROJECTS, GET_WORKEX, GET_LAB } from '../../../../api/vsc';
import VideoPlayer from './VideoPlayer';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import useMediaQuery from '@mui/material/useMediaQuery';
import '../../../../stylesheets/SideMenu.css';
import Fade from '@mui/material/Fade';
import CourseBanner from './CourseBanner';
import { useNavigate } from 'react-router-dom';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {TiTick} from 'react-icons/ti';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import AssignmentIcon from '@mui/icons-material/Assignment';
import QuestionViewer from './QuestionViewer';
import ProjectViewer from './ProjectViewer';
import WorkExViewer from './WorkExViewer';
import Lab from '../../../Lab';
import Logo from '../../../Dashboard/Images/logo.png';
import { GET_CERTIFICATE_ALREADY_REQUESTED, GET_CERTIFICATE_REQUESTED } from '../../../../api/certificate';

const drawerWidth = 300;

function parseDate(dateString) {
  const [day, month, year] = dateString.split("/").map(Number);
  return new Date(year, month - 1, day);
}
function parseTime(timeString) {
  const timeComponents = timeString.match(/(\d+):(\d+):(\d+)\s+(am|pm)/);
  const hours = parseInt(timeComponents[1]);
  const minutes = parseInt(timeComponents[2]);
  const seconds = parseInt(timeComponents[3]);
  const isPM = timeComponents[4].toLowerCase() === "pm";
  if (isPM && hours !== 12) {
    hours += 12;
  } else if (!isPM && hours === 12) {
    hours = 0;
  }

  return new Date(0, 0, 0, hours, minutes, seconds);
}


const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  flexGrow: 1,
  padding:theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: open && `${drawerWidth-20}px`,  // Updated
  width: '100vw', // Updated
  [theme.breakpoints.down('sm')]: {
    marginLeft: '0',
  },
  display:'flex',
  justifyContent:'center',
  alignItems:'center'

}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

function SideMenu({ props  }) {
  const navigate = useNavigate();
  const theme = useTheme();
  const params = useParams();
  const isMobile = useMediaQuery('(max-width:820px)');
  const [open, setOpen] = React.useState(!isMobile?true:false);
  const [selectedTab, setSelectedTab] = React.useState('');
  const [course, setCourse] = React.useState('');
  const [courseOpen, setCourseOpen] = React.useState(true);
  const [communityOpen, setCommunityOpen] = React.useState(false);
  const [videoUrl, setVideoUrl] = React.useState('');
  const [videoFullScreen, setVideoFullscreen] = React.useState(false);
  const [videoIndex, setVideoIndex] = React.useState(0);
  const [openHeader, setOpenHeader] = React.useState(null);
  const [modules, setModules] = React.useState([]);
  const [videoTitle, setVideoTitle] = React.useState('');
  const [videos, setVideos] = React.useState([]);
  const [pdfs, setPdfs] = React.useState([]);
  const [totalCount, setTotalCount] = React.useState(0);
  const [progress, setProgress] = React.useState(0);
  const [moduleId, setModuleId] = React.useState(null);
  const [moduleIndex, setModuleIndex] = React.useState(null);
  const [pdfUrl, setPdfUrl] = React.useState('');
  const [clicked, setClicked] = React.useState(0);
  const [modalopen, setmodalOpen] = React.useState(false);
  const [readStatus, setReadStatus] = React.useState([]);
  const [completed, setCompleted] = React.useState(false);
  const [isChecked, setIsChecked] = React.useState(false);
  const [completedCount , setCompletedCount] = React.useState(0);
  const [completedStatus, setCompletedStatus] = React.useState([]);
  const [openE, setOpenE] = React.useState(false);
  const [videoId , setVideoId] = React.useState(null);
  const [pdfId , setpdfId] = React.useState(null);
  const [activateDate , setActivateDate] = React.useState('');
  const [activateTime , setActivateTime] = React.useState('');
  const [questions , setQuestions] = React.useState([]);
  const [courseName , setCourseName] = React.useState('');
  const [completedVideos , setCompletedVideos] = React.useState([]);
  const [comp , setComp] = React.useState(false);
  const [cA , setCa] = React.useState(true);
  const [completedPdfs, setCompletedPdfs] = React.useState([]);
  const [q,setQ] =  React.useState([]);
  const [projects , setProjects] = React.useState([]);
  const [projectData , setProjectData] = React.useState({});
  const [workEx , setWorkEx] = React.useState({});
  const [workExData , setWorkExData] = React.useState({});
  const [lab , setLab] = React.useState({});
  const [labData , setLabData] = React.useState({});
  const handleOpen = () => setOpenE(true);
  const handleClose = () => setOpenE(false);
  const [user , setUser] = React.useState({});
  const [moduleDesc , setModuleDesc] = React.useState('');
  const [mark , setMark] = React.useState(true);
  const [completedLabs , setCompletedLabs] = React.useState([]);
  const [ref , setRef] = React.useState(false);
  const [submitted , setSubmitted] = React.useState(false);
  const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    flexGrow: 1,
    padding: !labData.labHeadings && theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: open && `${drawerWidth-20}px`,  // Updated
    width: '100%', // Updated
    [theme.breakpoints.down('sm')]: {
      marginLeft: '0',
    },
    display:'flex',
    justifyContent:'center',
    alignItems:'center'
  
  }));
  React.useEffect(() => {
    axios.post(SET_COURSEDETAILS+params.id+"/"+window.localStorage.id).then(res=>{}).catch(err=>{}); 
    axios
    .get(GET_CERTIFICATE_ALREADY_REQUESTED+window.localStorage.id+"/"+params.id)
    .then(res => {
      setSubmitted(res.data.message);
    })
    .catch(err => {
      console.log(err);
    })
  },[]);
  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      setMark(false);
      }, 1000 * 10);
      return () => clearTimeout(timeoutId);
  },[]);
  React.useEffect(() => {
    axios
      .get(USER, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
          setCompletedVideos(response.data.courseDetails.find(course => course.courseId === params.id).videosCompleted);
          setCompletedLabs(response.data.completedLab);
          setCompletedPdfs(response.data.courseDetails.find(course => course.courseId === params.id).pdfsCompleted);
          setTotalCount(response.data.courseDetails.find(course => course.courseId === params.id).total);
          setProgress(((response.data.courseDetails.find(course => course.courseId === params.id).videosCompleted.length+response.data.courseDetails.find(course => course.courseId === params.id).pdfsCompleted.length)/response.data.courseDetails.find(course => course.courseId === params.id).total)*100);
          setComp(false);
          setUser(response.data);
              var currentDate = new Date();
              const course = response.data.certificateActivate.find(
                (course) => course.courseId === "64fb08d372d0fde41877df63"
              ).dateOfActivation;
              const activate = new Date(course)
              var array = activate.toLocaleString().split(',');
              setActivateDate(array[0]);
              setActivateTime(array[1]);
              setRef(false);

    if (currentDate > activate) {
      setCa(true);
    }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [comp,true , ref]);
  React.useEffect(() => {
    axios
    .get(GET_MODULES + params.id)
    .then((moduledata) => {
      setModules(moduledata.data.message);

      let totalCount = 0;
      moduledata.data.message.forEach((module) => {
        totalCount += module.files;
      });
      axios
      .get(GET_USER+window.localStorage.id+"/"+params.id)
      .then(res => {
        if (res.data.course.completed > 0) {
          var CompletedStatus = new Array(totalCount).fill(false);
          for (var i = 0; i < res.data.course.completed; i++) {
            CompletedStatus[i] = true;
          }
          setCompletedStatus(CompletedStatus);
        }
      }) 
      .catch(err => {
        console.log(err);
      })
     
    })
    .catch((err) => {
      console.log(err);
    });
    axios.get(GET_PROJECTS + `${params.id}`)
    .then((projectdata) => {
      setProjects(projectdata.data.message);
    })
    .catch((err) => {
      console.log(err);
    });
  },[]);
  React.useEffect(() => {
    axios
      .get(GET_USER+window.localStorage.id+"/"+params.id)
      .then(res => {
        if (res.data.course.completed > 0) {
          var CompletedStatus = new Array(videos.length + pdfs.length).fill(false);
          for (var i = 0; i < res.data.course.completed; i++) {
            CompletedStatus[i] = true;
          }
          setCompletedStatus(CompletedStatus);
        }
        else {
          setCompletedStatus(new Array(videos.length + pdfs.length).fill(false));
        }
      }) 
      .catch(err => {
        console.log(err);
      })
      
  }, [videos, pdfs]);
  

  const handlemodalClose = () => setmodalOpen(false);
  const handleCheckboxChange = () => {
    if(videoId !== null){
      axios
    .post(POST_COMPLETED_VIDEO+videoId+"/"+window.localStorage.id+"/"+params.id)
    .then(res =>{
      setComp(true);

      // console.log(res);
      if (videoIndex < videos.length + pdfs.length - 1) {
              const nextIndex = videoIndex+1;
          
              if (nextIndex < videos.length) {
                setVideoUrl(videos[nextIndex].link);
                setVideoIndex(nextIndex);
                setVideoId(videos[nextIndex]._id);
                setpdfId(null);
              }
              else {
                setVideoUrl('');
                setPdfUrl(pdfs[nextIndex - videos.length].link);
                setVideoIndex(nextIndex);
                setVideoId(null);
                setpdfId(pdfs[nextIndex - videos.length]._id);
              }
            } else {
            }
    } )
    .catch(err => console.log(err)); 
    }
    else{
      axios
      .post(POST_COMPLETED_PDF+pdfId+"/"+window.localStorage.id+"/"+params.id)
      .then(res =>{
        setComp(true);
        // console.log(res);
        if (videoIndex < videos.length + pdfs.length - 1) {
                const nextIndex = videoIndex+1;
            
                  setVideoUrl('');
                  setPdfUrl(pdfs[nextIndex - videos.length].link);
                  setVideoIndex(nextIndex);
                  setVideoId(null);
                  setpdfId(pdfs[nextIndex - videos.length]._id);
              } else {
              }
      } )
      .catch(err => console.log(err)); 
    }
    
   
  };

  const fetchVideos = React.useCallback(() => {
    axios
      .get(VIDEO + moduleId)
      .then((data) => {
        // console.log(data);
        setVideos(data.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [moduleId]);

  React.useEffect(() => {
    axios
      .get(GET_COURSE + params.id)
      .then((response) => {
        setCourse(response.data.data[0]);
        // console.log(course);
        fetchModules();
        fetchWorkex();
        fetchLab();
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

 


  const handleVideoItemClick = (videoIndex) => {
    setPdfUrl('');
    setVideoIndex(videoIndex);
    setSelectedTab('course');
  };

  const handlePdfItemClick = (pdfIndex) => {
    setVideoUrl('');
    setVideoIndex(videoIndex + pdfIndex);
    setSelectedTab('course');
  };

  React.useEffect(() => {
    if (moduleId) {
      fetchVideos();
      fetchPdfs();
      fetchQuestions();
      setModuleId(null);
    }
  }, [moduleId]);
  const fetchQuestions = () => {
    axios
      .get(QUESTION + moduleId)
      .then((data) => {
        setQuestions(data.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  const fetchPdfs = () => {
    // console.log(moduleId);
    axios
      .get(PDF + moduleId)
      .then((data) => {
        // console.log(data);
        setPdfs(data.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };


  const handleDrawerOpen = () => {
    setOpen(true);
    setVideoFullscreen(false);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    setVideoFullscreen(true);
  };

  const handleCourseOpen = () => {
    setSelectedTab('course');
    setCourseOpen((prevCourseOpen) => !prevCourseOpen);
    setCommunityOpen(false);
  };

  const handleCommunityOpen = () => {
    setSelectedTab('community');
    setCourseOpen(false);
    setCommunityOpen((prevCommunityOpen) => !prevCommunityOpen);
  };

  const fetchModules = () => {
    axios
      .get(GET_MODULES + params.id)
      .then((moduledata) => {
        setModules(moduledata.data.message);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const fetchWorkex = () => {
    axios
      .get(GET_WORKEX + params.id)
      .then((moduledata) => {
        setWorkEx(moduledata.data.message[0]);
       
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const fetchLab = () => {
    axios
      .get(GET_LAB + params.id)
      .then((moduledata) => {
        setLab(moduledata.data.message[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  React.useState(() => {
    axios
    .get(GET_USER+window.localStorage.id+"/"+params.id)
    .then(res => {
      setCompletedCount(res.data.course.completed);
      setTotalCount(res.data.course.total);
    }) 
    .catch(err => {
      console.log(err);
    })
   

},[clicked]);
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: !isMobile?400:'100%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
  const handleCompletedPdf = (index , pdfid) => (event) => {
    setClicked((prevState) => prevState+1);
    const newState = event.target.checked;
    setComp(true);
    axios
      .post(POST_COMPLETED_PDF+pdfid+"/"+window.localStorage.id+"/"+params.id)
      .then(res => {})
      .catch(err => console.log(err)); 
  

  }
  const handleCompletedVideo =(index , id ) => (event) => {
    setClicked((prevState) => prevState+1);
    const newState = event.target.checked;
    setComp(true);
 
      axios
      .post(POST_COMPLETED_VIDEO+id+"/"+window.localStorage.id+"/"+params.id)
      .then(res => {})
      .catch(err => console.log(err)); 
    

  }
  const startCourse = () => {
    setModuleId(modules[0]._id);
          axios
        .get(VIDEO + modules[0]._id)
        .then((data) => {
          if(data.data.data.length > 0)
               setVideoUrl(data.data.data[0].link);
          else 
              axios
              .get(PDF + modules[0]._id)
              .then((data) => {
                setPdfUrl(data.data.data[0].link)
              })
              .catch((err) => {
                console.log(err);
              });

        })
        .catch((err) => {
          console.log(err);
        });
    
  };
  const approveCertificate = () => {
    axios
    .post(GET_CERTIFICATE_REQUESTED+window.localStorage.id+"/"+params.id , {sent: true})
    .then(res => {
      setSubmitted(true);
    })
    .catch(err => {
      console.log(err);
    })
  }
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: !isMobile&&'center' }}>
      <CssBaseline />
        <AppBar position="fixed" open={open} style={{ backgroundColor: '#F7F7F7'  }}>
          <Toolbar
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <IconButton
              color="#000"
              aria-label="open drawer"
              onClick={() => {
                handleDrawerOpen();
              }}
              edge="start"
              sx={{ mr: 2, ...(open && { display: 'none' }) }}
            >
              <KeyboardDoubleArrowRightIcon />
            </IconButton>
            <h1 
            className="sidemenu_toolbar_h1"
            >{course.courseName}</h1>
            <Button onClick={handleCheckboxChange} disabled={mark} style={{background:'#6A58C6' , borderRadius:'10px' , color:'#fff',fontSize:isMobile&&'12px'}}>Completed And Next <NavigateNextIcon/></Button>
          </Toolbar>
        </AppBar>
        {/* </div> */}
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
              marginTop: '0',
            },
          }}
          variant={isMobile ? 'temporary' : 'persistent'}
          anchor="left"
          open={open}
          onClose={isMobile ? handleDrawerClose : null}
          ModalProps={{
            keepMounted: true,
          }}
        >
          <List style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
          <img 
            src={Logo} 
            alt="logo" 
            style={{
              width: '80%',
              height: 80
            }} 
            />
            <DrawerHeader
              style={{ display: 'flex', justifyContent: 'space-between', backgroundColor: '#6A58C6' }}
            >
              <IconButton>
                <KeyboardBackspaceIcon fontSize="small" />
                <a href="/courses" style={{ fontSize: '10px', color: '#fff' }}>
                  Back to previous Page
                </a>
              </IconButton>
              <IconButton onClick={handleDrawerClose}>
                {theme.direction === 'ltr' ? (
                  <KeyboardDoubleArrowLeftIcon />
                ) : (
                  <KeyboardDoubleArrowRightIcon />
                )}
              </IconButton>
            </DrawerHeader>
            <ListItem
              key={course.title}
              disablePadding
              style={{ outerHeight: '100px', innerHeight: '100px' }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  padding: '20px',
                  backgroundColor: '#6A58C6',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <h1 className="sidemenu_h1">{course.courseName}</h1>
                <LinearWithValueLabel value={progress} color={"#fff"}/>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
                  <IconButton
                    onClick={() => {
                      fetchModules();
                      handleCourseOpen();
                    }}
                    className={selectedTab === 'course' ? 'selected' : ''}
                  >
                    <p className="sidemenu_p" style={{ fontSize: '12px', fontWeight: 'Bold' }}>Course</p>
                  </IconButton>
                  <IconButton
                    onClick={handleCommunityOpen}
                    className={selectedTab === 'community' ? 'selected' : ''}
                  >
                    <p className="sidemenu_p" style={{ fontSize: '12px', fontWeight: 'Bold' }}>Community</p>
                  </IconButton>
                </div>
              </div>
            </ListItem>
          </List>
          <List style={{ padding: '0' }}>
            <Collapse in={courseOpen}>
              {modules.map((module, index) => (
                <ListItem
                  key={module._id}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                  }}
                >
                  <ListItemButton
                    style={{ width: '100%' }}
                    onClick={() => {
                      setModuleIndex(index);
                      setModuleId(module._id);
                      setModuleDesc(module.description);
                      setOpenHeader((prevState) => (prevState === index ? null : index));
                    }}
                  >
                    <ListItemText primary={`${module.title}`} />
                    {openHeader === index ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                  </ListItemButton>
                  <Collapse in={openHeader === index}>
                    <List component="div" disablePadding style={{ marginLeft: '20px', backgroundColor: '#F0F0F0'  }}>
                      {videos &&
                        videos.map((video, index) => (
                          <ListItem
                            style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
                          >
                            <ListItemButton
                              key={index}
                              style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
                              onClick={() => {
                                setVideoUrl(video.link);
                                handleVideoItemClick(index);
                                setVideoIndex(index);
                                setVideoTitle(video.title);
                                setVideoId(video._id);
                                setpdfId(null);
                                setOpen(isMobile ? false : true);
                                setProjectData({});
                                setMark(true);
                                setWorkExData({});
                                setLabData({})
                                setTimeout(() => {
                                  setMark(false);
                                }, 10 * 1000);
                                // setClicked((prevClicked) => prevClicked + 1);
                              }}
                            >
                              <PlayCircleIcon fontSize="small" style={{ marginRight: '10px' }} />
                              <ListItemText primary={video.title} />
                            </ListItemButton>
                            {(videoUrl!==''&&video.link === videoUrl) && !completedVideos.includes(video._id) &&
                            (<Checkbox disabled={mark}  onChange={handleCompletedVideo(index , video._id)}/>)
                            }
                            {completedVideos.includes(video._id)&&(<CheckCircleIcon style={{color: 'green'}} />)}    
                           
                          </ListItem>
                        ))}
                      {pdfs &&
                        pdfs.map((pdf, index) => (
                          <ListItem
                            style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
                          >
                            <ListItemButton
                              key={index}
                              style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
                              onClick={() => {
                                setPdfUrl(pdf.link);
                                handlePdfItemClick(index);
                                setVideoIndex(videoIndex + index);
                                setpdfId(pdf._id);
                                setVideoId(null);
                                setOpen(isMobile ? false : true);
                                setProjectData({});
                                setWorkExData({});
                                setLabData({});
                                setMark(true);
                                setTimeout(() => {
                                  setMark(false);
                                }, 60 * 1000);
                              }}
                            >
                              <PictureAsPdfIcon style={{ color: '#000', marginRight: '10px' }} />
                              <ListItemText primary={pdf.title} />
                            </ListItemButton>
                            {(pdfUrl!==''&&pdf.link === pdfUrl) && !completedPdfs.includes(pdf._id) &&
                            ( <Checkbox disabled={mark}  onChange={handleCompletedPdf(videos.length+index, pdf._id)}/>)
                            }    
                            {completedPdfs.includes(pdf._id)&&(<CheckCircleIcon style={{color: 'green'}} />)}
  
                           
                            
                          </ListItem>
                        ))}
                        {questions &&
                        questions.map((pdf, index) => (
                          <ListItem
                            style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
                          >
                            <ListItemButton
                              key={index}
                              style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
                              onClick={() => {
                                setQ(pdf.questions);
                                // handlePdfItemClick(index);
                                // setVideoIndex(videoIndex + index);
                                // setQuestionId(pdf._id);
                                setProjectData({});
                                setVideoId(null);
                                setOpen(isMobile ? false : true);

                              }}
                            >
                              <AssignmentIcon style={{ color: '#000', marginRight: '10px' }} />
                              <ListItemText primary={pdf.title} />
                            </ListItemButton>     
                          </ListItem>
                        ))}
                    </List>
                  </Collapse>
                </ListItem>
              ))}
              {
                lab && (
                  <ListItem
                  key={lab._id}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    background: '#7AFFC9',
                    fontWeight: '900',
                    width: '100%',
                    marginBottom: (!workEx && projects.length === 0 )&&'50px'
                  }}
                >
                <ListItemButton
                     style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    background: '#7AFFC9',
                    fontWeight: '900',
                    width: '100%'
                  }}
                    onClick={() => {
                      setLabData(lab)
                      setWorkExData({});
                      setProjectData({});
                      setPdfUrl('');
                      setVideoUrl('');
                      setOpen(false);

                    }}
                  >
                    <ListItemText style={{ width: '100%',  textAlign:'center' }} primary={`LAB`} />
                
                </ListItemButton>
                </ListItem>
                )
              }
              {
              projects.map((project) => (
                <ListItem
                  key={project._id}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    background: '#FFBD59',
                    fontWeight: '900',
                    width: '100%',
                    marginBottom: (!workEx)&&'50px'
                  }}
                >
                <ListItemButton
                     style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    background: '#FFBD59',
                    fontWeight: '900',
                    width: '100%'
                  }}
                    onClick={() => {
                      setProjectData(project);
                      setPdfUrl('');
                      setVideoUrl('');
                      setWorkExData({});
                      setLabData({});

                    }}
                  >
                    <ListItemText style={{ width: '100%',  textAlign:'center' }} primary={`PROJECT`} />
                
                </ListItemButton>
                </ListItem>
              ))
            }
             {
              workEx && (
                <ListItem
                  key={workEx._id}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    background: '#FF5757',
                    fontWeight: '900',
                    width: '100%',
                    marginBottom: '50px'
                  }}
                >
                <ListItemButton
                     style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    background: '#FF5757',
                    fontWeight: '900',
                    width: '100%'
                  }}
                    onClick={() => {
                      setWorkExData(workEx);
                      setProjectData({});
                      setPdfUrl('');
                      setVideoUrl('');
                      setLabData({});

                    }}
                  >
                    <ListItemText style={{ width: '100%',  textAlign:'center' }} primary={`WORKEX`} />
                
                </ListItemButton>
                </ListItem>
              )
             }
              
            </Collapse>
               
            <div style={{
                        position: "fixed",
                        bottom: 0,
                        textAlign: "center",
                        paddingBottom: 5,
                        display:'flex',
                        justifyContent:'center',
                        alignItems:'center',
                        width:isMobile?'25%':'18%',
                    }}>
                      <Button variant='contained' style={{background:'#6A58C6'}} startIcon={<CardMembershipIcon/>} 
                          onClick={() => {
                          if (progress>=100) setmodalOpen(true);
                          else  setOpenE(true);
                         }}>Certificate</Button>
                  </div>
            <Collapse in={communityOpen}>
              <h6 style={{ color: '#000' }}>Community page under construction</h6>
            </Collapse>
            
          </List>
          
        </Drawer>
        <Main open={open} style={{marginTop:'80px' , display:'flex' , justifyContent:'center'}}>
          {videoUrl && <VideoPlayer videoUrl={videoUrl} videoTitle={videoTitle} moduleDesc={moduleDesc}/>}
          {pdfUrl && <PDFViewer pdfUrl={pdfUrl} />}
          {projectData.heading && <ProjectViewer ProjectData={projectData} courseName={course.courseName}/>} 
          {workExData.heading !== undefined && (
            <WorkExViewer WorkExData={workExData} courseName={course.courseName}/>
          )}
          {
            labData.labHeadings && (
              <Lab labData = {labData} completedLabs={completedLabs} setRef={setRef}/>
            )
          }
          {q.length > 0 && <QuestionViewer q={q} />}
          {!labData.labHeadings && !workExData.heading && !videoUrl && !pdfUrl && q.length===0 && !projectData.heading && (<CourseBanner startCourse={startCourse} course={course}/>)}
          

        </Main>
     
      <Modal
        open={modalopen}
        onClose={handlemodalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <img src={congrats} style={{ width: '100%' }} alt="Congrats Image"/>
          {!submitted?<div style={{marginTop:'10px',display:'flex' , justifyContent:'center' , flexDirection:'column'}}>
          <Typography variant='subtitle1' style={{textAlign:'center'}}>Click To Get Your Certificate Approved</Typography>
          <Button
          onClick={approveCertificate}
           variant='contained' style={{backgroundColor:'#6A58C6' }}>Certificate</Button>
          </div>: <div style={{marginTop:'10px',display:'flex' , justifyContent:'center' , flexDirection:'column'}}>
          <Typography variant='subtitle1' style={{textAlign:'center'}}>{'Your Request Is Accepted And You Will Receive Certificate In next 48 hours'}</Typography>
          </div>}
        </Box>
      </Modal>
      <Modal
        open={openE}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {!cA ?'Activate The Certicate' :'Complete The Course'}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          {!cA ? `The Certificate Would Be Activated After ${activateTime} on ${activateDate}`:'To Generate Your Certificate First Complete The Course.'}
          </Typography>
        </Box>
      </Modal>
    </Box>
  );
}

export default SideMenu;
