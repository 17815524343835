import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

const Loader = ({from}) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' , height:'70vh'}}>
      <CircularProgress style={{ color: from==='NewBlog' ? '#6A58C6' :'#fff' }} />
    </div>
  );
};

export default Loader;