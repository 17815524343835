import React from 'react';
import MyAccordion from '../../NewVirtualCampusStartup/Program/Accordian/MyAccordian';


const LiveStockSense = () => {
    const questions = [
        {
            question: 'Awareness and Education Campaigns:',
            answer: `LivestockSense conducted extensive awareness campaigns, farmer workshops, and training programs to educate farmers about the benefits of IoT-based livestock monitoring. They partnered with agricultural cooperatives and local organizations to reach a wider audience.\n\nOutcome: Awareness and education campaigns led to a 50% increase in farmer awareness about IoT-based livestock monitoring solutions, sparking interest in adopting the technology.`
        },
        {
            question: 'Strategic Partnerships with Agri-Input Retailers:',
            answer: "To address the fragmented supply chain, LivestockSense formed strategic partnerships with agri-input retailers and livestock feed suppliers. These partnerships allowed LivestockSense to leverage the existing network of agri-input retailers to distribute their monitoring solutions to farmers.\n\nOutcome: Strategic partnerships with agri-input retailers expanded LivestockSense's distribution network, reaching 60% of potential farmers in remote and less accessible areas."
        },
        {
            question: 'Leasing and Subscription Models:',
            answer: "To alleviate affordability concerns, LivestockSense introduced leasing and subscription models for their IoT-based livestock monitoring solutions. Farmers could now access the technology at a more manageable cost, paying on a per-month or per-animal basis.\n\nOutcome: The introduction of leasing and subscription models led to a 40% increase in the adoption of LivestockSense's monitoring solutions among small and medium-scale farmers."
        },
        {
            question: 'How can LivestockSense integrate data analytics and machine learning to provide actionable insights from livestock monitoring data, helping farmers optimize feeding and health management practices?',
            answer: "To integrate data analytics and machine learning for actionable insights, LivestockSense can take the following steps:<br/><br/>a. Data Collection and Aggregation: LivestockSense can collect data from IoT sensors attached to livestock, including temperature, heart rate, feeding patterns, and activity levels. They can aggregate this data in a centralized database.\n\nb. Data Analytics for Health Monitoring: By applying data analytics algorithms to the collected data, LivestockSense can detect patterns and anomalies associated with potential health issues in livestock. Machine learning models can identify early signs of diseases or distress.<br/><br/>c. Feeding Optimization: LivestockSense can utilize machine learning algorithms to analyze feeding patterns and recommend optimized feeding schedules based on livestock behavior and nutritional requirements.<br/><br/>Outcome: Integration of data analytics and machine learning will empower farmers with actionable insights to proactively manage the health and feeding practices of their livestock, leading to improved overall productivity and animal welfare."
        },
        {
            question: 'What marketing strategies can LivestockSense adopt to target large-scale commercial livestock farms and demonstrate the scalability and cost-effectiveness of their IoT-based monitoring solutions?',
            answer: "To target large-scale commercial livestock farms and demonstrate scalability and cost-effectiveness, LivestockSense can adopt the following marketing strategies:<br/><br/>a. Case Studies and Testimonials: LivestockSense can create case studies and testimonials showcasing successful implementations of their IoT-based monitoring solutions on large-scale farms. These success stories can highlight the scalability and tangible benefits experienced by farmers.<br/><br/>b. Performance Demonstrations: LivestockSense can conduct live demonstrations of their monitoring solutions on large-scale farms to provide firsthand experience to potential customers. These demonstrations can illustrate the ease of integration and immediate results.<br/><br/>c. Customized Packages: LivestockSense can offer customized packages for large-scale farms, tailoring their solutions to meet the specific needs and requirements of each farm. Bulk discounts and long-term contracts can demonstrate cost-effectiveness.<br/>Outcome: Implementing these marketing strategies will establish LivestockSense as a reliable and scalable solution provider for large-scale commercial livestock farms, increasing their market share in this segment."
        },
        {
            question: 'How can LivestockSense collaborate with veterinary institutions and research organizations to validate the effectiveness of their monitoring solutions and gain credibility among potential customers?',
            answer: "To collaborate with veterinary institutions and research organizations for validation and credibility, LivestockSense can take the following approach:\n\na. Joint Research Projects: LivestockSense can initiate joint research projects with veterinary institutions to study the impact of their IoT-based monitoring solutions on livestock health and welfare. Collaborative research can provide scientific evidence of the effectiveness of their technology.<br/><br/>b. Peer-Reviewed Publications: LivestockSense can encourage researchers from veterinary institutions to publish their findings in peer-reviewed journals, showcasing the benefits and validation of their monitoring solutions.<br/><br/>c. Testimonial from Experts: LivestockSense can seek testimonials and endorsements from renowned veterinarians and experts in livestock management. Expert validation will increase the credibility of their solutions among potential customers.<br/><br/>Outcome: Collaborating with veterinary institutions and research organizations will validate LivestockSense's monitoring solutions scientifically, enhancing their credibility and trustworthiness among potential customers."
        }
    ]
  return (
    <div>
          <h1>LivestockSense: Revolutionizing Livestock Monitoring in the Indian Market with IoT</h1>

            <p>LivestockSense is a startup in India that specializes in providing IoT-based livestock monitoring solutions. The company aims to enhance the efficiency and productivity of livestock farming by utilizing advanced technology to monitor the health and well-being of livestock. This case study focuses on LivestockSense's business problems in the Indian market and their efforts to overcome these challenges.</p>

            <h2>Business Problems:</h2>
            <ol>
                <li>
                <strong>Limited Awareness and Adoption:</strong> 
                One of the primary challenges for LivestockSense was the limited awareness and adoption of IoT-based livestock monitoring solutions among farmers in India. Traditional farming practices and a lack of exposure to technology hindered the widespread adoption of these advanced solutions. 
                <br/><br/>
                <em>Assumed Figure: Only 10% of Indian farmers were aware of IoT-based livestock monitoring solutions as a viable means to improve livestock management and health.</em>
                </li>

                <li>
                <strong>Fragmented Supply Chain and Distribution:</strong> 
                LivestockSense faced challenges in establishing an efficient supply chain and distribution network in the vast and diverse Indian market. The lack of standardized channels and logistic complexities made it difficult to reach farmers in remote regions. 
                <br/><br/>
                <em>Assumed Figure: LivestockSense's distribution network covered only 20% of potential farmers in remote and less accessible areas.</em>
                </li>

                <li>
                <strong>Affordability Concerns:</strong> 
                Affordability was a significant concern for small and medium-scale farmers in India. The perceived high upfront cost of implementing IoT-based livestock monitoring solutions deterred many farmers from adopting the technology. 
                <br/><br/>
                <em>Assumed Figure: LivestockSense's monitoring solutions were considered affordable by only 15% of small and medium-scale farmers.</em>
                </li>
            </ol>

            <h2>Solutions and Outcomes:</h2>
            <p>LivestockSense implemented several strategies to address these business problems and achieve success in the Indian IoT-based livestock monitoring market.</p>
            <br/>
            <br/>
            <div style={{flexShrink:3}}>
                {
                questions.map((q,i) => (
                    <MyAccordion question={q.question} answer={q.answer} />
                ))
                }
            </div>
    </div>
  )
}

export default LiveStockSense;