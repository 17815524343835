import React from 'react';
import {Box } from '@mui/material';
import {faqs} from '../../CourseLandingPage/FAQDATA';
import Accordion from "../../CourseLandingPage/Accordion/index";


const FAQs = () => {
  return (
    <Box
        sx={{
            padding: '20px'
        }}
    >
            <h2 style={{marginBottom: '20px'}}><u style={{textDecorationColor: '#FFBD59' , textDecorationThickness: '5px' }}>FAQs</u></h2>
            {faqs.map((faq) => (
                    <Accordion question={faq.question} answer={faq.answer}/>
                ))}  
    </Box>
  )
}

export default FAQs