import React from 'react';
import {Box , Typography} from '@mui/material';
import product from '../Images/productsalaries.jpg';
import management from '../Images/managementsalaries.jpg';
import finance from '../Images/financesalaries.jpg';


const PossibleCareer = () => {
  const salaries = [product , management , finance];

  return (
    <Box>
    <Box
        sx={{
            width: '100%' , 
            display: 'flex' , 
            justifyContent: 'center' , 
            alignItems: 'center' ,
            flexDirection:'column'
        }}
    >
    <Typography variant="h4" align="center" sx={{mb: 2 , width: {xs: '100%' , md: '30%'}}}>
            Possible <span style={{color: "#6a58c6" , fontWeight: 600}}>Career that Opens Up</span> in Future
        </Typography>
    </Box>
    <Box
        sx={{
            padding: '30px',
            display: 'flex' , 
            justifyContent: 'space-between',
            alignItems: 'center',
            flexWrap: 'wrap',
            gap: '20px'
        }}
    >
    {salaries.map(path => (
        <Box
            component="img"
            src={path}
            sx={{
                width: {xs: '100%'  , md:'30%'},
                height: 300,
                boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                borderRadius: '20px',
                padding: 2
            }}
        />
    ))}
    </Box>
       
    </Box>
  )
}

export default PossibleCareer