import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardActions, Button, Typography, CardMedia, Box, CircularProgress } from '@mui/material';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { GET_COURSES, USER_ACCESS, USER_REFFERAL, RECOMMENDED_COURSES, BOUGHT_COURSES, GET_CART } from '../../../../../api/vsc';
import LinearProgressWithLabel from './LinearProgressWithLabel';
import { CART_UPDATE, CART_UPDATE_ALL, CART_REMOVE, CART_REMOVE_THREE } from '../../../../../api/payment';

const addCourse1 = (refresh, price, courseName, setCart) => {
  if (
    refresh === "64fb08d372d0fde41877df63" ||
    refresh === "64fb15b672d0fde41877e193" ||
    refresh === "64fb105b72d0fde41877e101"
  ) {
    axios.post(
      CART_UPDATE_ALL +
        "64fb08d372d0fde41877df63" +
        "/64fb15b672d0fde41877e193/" +
        "64fb105b72d0fde41877e101/" +
        window.localStorage.id,
      [
        {
          courseId: "64fb08d372d0fde41877df63",
          price: 0,
          courseName: "Minimum Viable Product",
        },
        {
          courseId: "64fb15b672d0fde41877e193",
          price: 0,
          courseName: "Go To Market",
        },
        {
          courseId: "64fb105b72d0fde41877e101",
          price: 350,
          courseName: "Business Model Planning",
        },
      ]
    ).then((res) => setCart(true))
      .catch((err) => {});
  } else {
    axios.post(CART_UPDATE + refresh + "/" + window.localStorage.id, { price, courseName })
      .then(res => setCart(true))
      .catch(err => {});
  }
};

const removeCourse = (refresh, setCart , courseName) => {
  if (refresh === "64fb08d372d0fde41877df63" || refresh === "64fb15b672d0fde41877e193" || refresh === "64fb105b72d0fde41877e101") {
    axios.post(CART_REMOVE_THREE + "64fb105b72d0fde41877e101/" + window.localStorage.id)
      .then(res => setCart(true))
      .catch(err => {});
  } else {
    axios.patch(CART_REMOVE + refresh + "/" + window.localStorage.id,{courseName:courseName})
      .then(res => setCart(true))
      .catch(err => {});
  }
};

export default function DataCourse({ setUrl }) {
  const [boughtCourses, setBoughtCourses] = useState([]);
  const [recommendedCourses, setRecommendedCourses] = useState([]);
  const [cart, setCart] = useState([]);
  const [refresh , setRefresh] = useState(false);
  const [loadingBought, setLoadingBought] = useState(true);
  const [loadingRecommended, setLoadingRecommended] = useState(true);

  useEffect(() => {
    const fetchBoughtCourses = async () => {
      try {
        const userResponse = await axios.get(BOUGHT_COURSES, {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("token")}`,
          },
        });
        setBoughtCourses(userResponse.data.data.reverse());
      } catch (err) {
        console.log('Error fetching bought courses', err);
      } finally {
        setLoadingBought(false);
      }
    };

    const fetchRecommendedCourses = async () => {
      try {
        const response = await axios.get(RECOMMENDED_COURSES, {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("token")}`,
          },
        });
        setRecommendedCourses(response.data.data.reverse());
      } catch (err) {
        console.log('Error fetching recommended courses', err);
      } finally {
        setLoadingRecommended(false);
      }
    };

    const fetchCart = async () => {
      try {
        const cartResponse = await axios.get(USER_REFFERAL+window.localStorage.id, {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("token")}`,
          },
        });
        setCart(cartResponse.data.data.cart);
        setRefresh(false);
      } catch (err) {
        console.log('Error fetching cart', err);
      }
    };

    fetchBoughtCourses();
    fetchRecommendedCourses();
    fetchCart();
  }, [refresh]);

  return (
    <div>
      <Typography variant="h3" textAlign="center" style={{ fontFamily: 'Poppins', fontWeight: '900' }}>My Courses</Typography>
      <div
        style={{
          marginTop: '50px',
          display: 'flex',
          flexWrap: 'wrap',
          gap: '16px',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {loadingBought ? (
          <CircularProgress />
        ) : (
          boughtCourses.length > 0 ? (
            boughtCourses.map((c) => (
              <Card
                key={c._id}
                style={{
                  width: 300,
                  height: 250,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}
              >
                <CardMedia image={c.coursePreview} alt="Course Thumbnail" sx={{ width: 300, height: 180 }} />
                <CardContent>
                  <Typography variant="body2" fontWeight={'900'}>{c.courseName}</Typography>
                </CardContent>
                <Box sx={{ width: '100%', padding: '0 5px 0 10px' }}>
                  <LinearProgressWithLabel value={c.percentage ? c.percentage : 0} color={"#6A58C6"} />
                </Box>
                <CardActions style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button variant="contained" color="primary" style={{ alignSelf: 'flex-end', backgroundColor: '#6A58C6' }}>
                    <Link to={`/program/videoplayer/${c._id}`} style={{ textDecoration: 'none', color: '#fff' }}>
                      Open
                    </Link>
                  </Button>
                </CardActions>
              </Card>
            ))
          ) : (
            <Typography variant='h1' sx={{ textAlign: 'center' }}>No Courses Bought</Typography>
          )
        )}
      </div>
      <Box sx={{ marginTop: '60px' }}>
        <Typography variant="h5" textAlign="center" style={{ fontFamily: 'Poppins', fontWeight: '900' }}>Recommended Courses</Typography>
        <div
          style={{
            marginTop: '50px',
            display: 'flex',
            flexWrap: 'wrap',
            gap: '16px',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {loadingRecommended ? (
            <CircularProgress />
          ) : (
            recommendedCourses.length > 0 && (
              recommendedCourses.map((c) => {
                const inCart = cart.some(cartItem => cartItem.courseId === c._id);
                return (
                  <Card
                    key={c._id}
                    style={{
                      width: 300,
                      height: 250,
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                    }}
                  >
                    <CardMedia image={c.coursePreview} alt="Course Thumbnail" sx={{ width: 300, height: 180 }} />
                    <CardContent>
                      <Typography variant="body2" fontWeight={'900'}>{c.courseName}</Typography>
                    </CardContent>
                    <Box sx={{ width: '100%', padding: '0 5px 0 10px' }}>
                      <LinearProgressWithLabel value={c.percentage ? c.percentage : 0} color={"#6A58C6"} />
                    </Box>
                    <CardActions style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      {inCart ? (
                        <Button
                          onClick={() => removeCourse(c._id, setRefresh , c.courseName)}
                          variant="contained"
                          color="secondary"
                          style={{ alignSelf: 'flex-end', backgroundColor: 'green' }}
                        >
                          Remove
                        </Button>
                      ) : (
                        <Button
                          onClick={() => addCourse1(c._id, c.price, c.courseName, setRefresh)}
                          variant="contained"
                          color="primary"
                          style={{ alignSelf: 'flex-end', backgroundColor: '#6A58C6' }}
                        >
                          Add
                        </Button>
                      )}
                    </CardActions>
                  </Card>
                );
              })
            )
          )}
        </div>
      </Box>
    </div>
  );
}
