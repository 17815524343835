import React, { useState } from 'react';
import './ProjectParticipate.css';
import PersonDetailsForm from './PersonDetailsForm';

const Participate = () => {
  const [step, setStep] = useState(1);
  const [teamName, setTeamName] = useState('');
  const [numberOfMembers, setNumberOfMembers] = useState(2);
  const [currentMember, setCurrentMember] = useState(1);
  const [members, setMembers] = useState(Array(numberOfMembers).fill({}));

  const handleNextStep = () => {
    if (step < 3) {
      setStep((prevStep) => prevStep + 1);
    } else {
      handleSubmit(); // If on Step 3, handle the form submission logic
    }
  };

  const handlePreviousStep = () => {
    setStep((prevStep) => prevStep - 1);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setTeamName(value);
  };

  const handleNumberOfMembersChange = (e) => {
    const value = parseInt(e.target.value);
    setNumberOfMembers(value);
    setMembers(Array(value).fill({}));
    setCurrentMember(1); // Reset to the first member when the number of members changes
  };

  const handleMemberChange = (index, e) => {
    const { name, value } = e.target;
    setMembers((prevMembers) => {
      const updatedMembers = [...prevMembers];
      updatedMembers[index] = { ...updatedMembers[index], [name]: value };
      return updatedMembers;
    });
  };

  const handleNextPerson = () => {
    // Save the current member's details and move to the next member's details
    if (currentMember < numberOfMembers) {
      setCurrentMember((prevMember) => prevMember + 1);
    } else {
      handleNextStep(); // If all members' details have been entered, move to the next step
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
    // You can send the form data to the backend or perform any other actions
  };

  return (
    <div className='Maincontainer2'>
    <div >
      {step === 1 && (
        <div className="team-registration-form">
          <h1>Team Registration Form - Step 1</h1>
          <form onSubmit={handleNextStep}>
            <div className="form-group">
              <label htmlFor="teamName">Team Name:</label>
              <input
                type="text"
                id="teamName"
                name="teamName"
                value={teamName}
                onChange={handleInputChange}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="numberOfMembers">Total Members:</label>
              <input
                type="number"
                id="numberOfMembers"
                name="numberOfMembers"
                min="2"
                value={numberOfMembers}
                onChange={handleNumberOfMembersChange}
                required
              />
            </div>

            <button className='button-payment' type="submit">Next</button>
          </form>
        </div>
      )}

      {step === 2 && (
        <div className="team-registration-form">
          <h1>Team Registration Form - Step 2</h1>
          {/* Render PersonDetailsForm for current member */}
          <PersonDetailsForm
            personNumber={currentMember}
            person={members[currentMember - 1]}
            handleInputChange={(e) => handleMemberChange(currentMember - 1, e)}
          />

          {/* Navigation buttons */}
          {currentMember > 1 && (
            <button className='registrationButton' type="button" onClick={handlePreviousStep}>
              Previous
            </button>
          )}
          {currentMember < numberOfMembers && (
            <button className='registrationButton' type="button" onClick={handleNextPerson}>
              Next Person
            </button>
          )}
          {currentMember === numberOfMembers && (
            <button className='registrationButton' type="submit" onClick={handleNextStep}>
              Next
            </button>
          )}
        </div>
      )}

      {step === 3 && (
        <div className="confirmation-summary">
          <h1>Team Registration Form - Step 3</h1>
          {/* Render the Confirmation Summary */}
          <div className="confirmation-summary-content">
            <h2>Confirmation Summary</h2>
            <p>Team Name: {teamName}</p>
            <p>Total Members: {numberOfMembers}</p>

            {members.map((member, index) => (
              <div className="confirmation-summary-content-2" key={index}>
                <h3>Person {index + 1} Details:</h3>
                <p>Name: {member.name}</p>
                <p>College: {member.college}</p>
                <p>Course: {member.course}</p>
                <p>Email: {member.email}</p>
                <p>WhatsApp Number: {member.whatsapp}</p>
                <p>LinkedIn Id: {member.linkedin}</p>
                {/* Add other member details here */}
              </div>
            ))}
          </div>

          {/* Navigation buttons */}
          <div className="confirmation-summary-buttons">
            <button className='confirmation-summary-button'  type="button" onClick={handlePreviousStep}>
              Previous
            </button>
            <button className='confirmation-summary-button'  type="submit" onClick={handleSubmit}>
              Proceed to Payment
            </button>
          </div>
        </div>
      )}
    </div>
    </div>
  );
};

export default Participate;