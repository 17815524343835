import React from 'react';
import { Box, Typography } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import green from '../Images/green.jpg'

const SuccessPage = () => {
  return (
    <Box 
      sx={{ 
        display: 'flex', 
        flexDirection: 'column', 
        justifyContent: 'center', 
        alignItems: 'center', 
        minHeight: '100%', 
        textAlign: 'center' ,
        gap: 5
      }}
    >
      <Box
        component={'img'}
        src={green}
        sx={{ 
          width: 200, 
          height: 200,
          mt: 10 
        }} 
      />
      <Box>
      <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#333' }}>
        Thank you!
      </Typography>
      <Typography variant="body1" sx={{ color: '#666' }}>
        Your submission has been sent.
      </Typography>
      </Box>
      
    </Box>
  );
};

export default SuccessPage;
