import * as React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import { useMediaQuery } from '@mui/material';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Media from './Media';
import Loader from '../../Career/Loader';
import { GET_CASE_STUDIES } from '../../../api/casestudy';
import RecentCard from '../../Blog/RecentCard';



export default function NewCaseStudy() {
  const [caseStudies, setCaseStudies] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  
  React.useEffect(() => {
    setLoading(true);
    axios
      .get(GET_CASE_STUDIES)
      .then((res) => {
        setCaseStudies(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
      });
  }, []);
  const getRandomIndex = () => {
    const randomIndex = Math.floor(Math.random() * caseStudies.length);
    return randomIndex;
  };
  let index = getRandomIndex();
  return (
    <Box sx={{ marginTop:'120px'}}>
    {!loading ? (
      <>
          <Box  margin="50px" marginLeft="10px" width="90%">
          <Typography variant="h5" style={{color:'#000' , textAlign:'left', fontFamily:'Poppins' , marginBottom:'10px' , fontWeight:'900'}}>
              Top Pick 
            </Typography>
                <RecentCard 
                id={caseStudies[index]?._id}
                src={caseStudies[index]?.casestudy}
                title={caseStudies[index]?.title}
                channel={caseStudies[index]?.subTitle}
                views={'10k'}
                createdAt='1 day ago'
                from="case"
                />
            </Box>  
       <Box  margin="50px">

       <Media props={`Recently Added`}/>

       </Box>   
       <Box  margin="50px">
       <Media props={'Most Viewed'}/>

       </Box>  
       <Box  margin="50px">
       <Media props={`Product`}/>

       </Box>  
       <Box  margin="50px">
       <Media props={`Startups`}/>

       </Box>
       <Box  margin="50px">
       <Media props={`Marketing`}/>

       </Box>
       <Box  margin="50px">
       <Media props={`Finance`}/>

       </Box>
       <Box  margin="50px">
       <Media props={`Technology`}/>

       </Box>
       </>
    ): (
      <Loader from={'NewBlog'}/>
    )}
      
    </Box>
  );
}