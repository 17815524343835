import React from 'react';
import { Box,Typography, Button , useMediaQuery } from "@mui/material";
import { Divider } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import certificateImg from './Images/Certificate.jpg';
import { POST_CERTIFICATE } from '../../api/certificate';



const CertLanding = () => {
    const isMobile = useMediaQuery('(max-width:600px)');
    const dividerStyle = {
        bgcolor: '#FFBD59',
        height: '2px',
        width:'100%',
      };
  return (
    <div style={{marginTop: '70px'}}>
        <Box display="flex" justifyContent="center" marginBottom={'15px'} >
        <div style={{ display: 'flex', alignItems: 'center' , flexDirection:'column' }}>
        <Typography variant="h3" fontWeight="800" style={{ color: '#000' , textAlign:'center'  }}>
          Certificate To Validate
        </Typography>
        <Typography variant="h3" fontWeight="800" style={{ color: '#6a58c6' }} >
          Your Skillsets
          <Divider sx={dividerStyle} />
        </Typography>
         </div>

          </Box>
          <Box style={{display:'flex' , flexDirection:isMobile?'column':'row'}}>
            <div style={{width:isMobile?'100%':'50%' , position:'relative'}}>
                <img 
                    src={certificateImg}
                    width='100%'
                    height='90%'
                    alt="Certificate Image"
                    style={{padding:!isMobile?'1%':'2%'}}
                />
                <span style={{textAlign:'left',fontSize:isMobile?'26px':'40px' , fontFamily: 'Parisienne' , position:'absolute' , top:'36%' , left:'35%' }}>{window.localStorage.name}</span>
            </div>
            <div style={{width:isMobile?'100%':'50%' , height:'100%',padding:'2%' , display:'flex' , flexDirection:'column' , alignItems:'center' , justifyContent:'center'}}>
                <Typography variant='h6' gutterBottom style={{padding:isMobile?'2%':'3%',   width:'70%'}}>
                Virtual Startup Campus offers Certificate for various On Demand Self Paced Courses that enhances practical skillset and offers certificate :
                </Typography>
                <div style={{marginTop:'10px' , display:'flex' , flexDirection:'column' , alignItems:'center' , justifyContent:'center'}}>
                <ul style={{listStyle:'none' , lineHeight:'3' , padding:'0'}}>
                        {['Get Certificates that Validate Your Skills', 'Get Validated for Multiple Skills to Showcase achievement' , 'Showcase skills on Resume & Linkedin'].map((skill) => (
                        
                        <Typography variant='h6' style={{textAlign:'left', display:'flex' , alignItems:'center'  }}><CheckCircleIcon style={{color:'green', margin: '2%' , fontSize:'50px'}}/> {skill}</Typography>
                       
                    ))}
                    </ul>
                    <Button variant='contained' href="/courses"  style={{width:'80%',fontWeight:'bold',  background:'#6a58c6',  margin: '10px' ,  height:'50px' , fontSize:'24px' , textTransform: 'none'}}>{'Get The Certificate'}</Button>
                </div>
            </div>
          </Box>
          </div>
  )
}

export default CertLanding