import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  TextField,
  Divider,
  Modal,
} from "@material-ui/core";
import CouponCard from "./CouponCard";
import PaymentCard from "./PaymentCard";
import ImageStrip from "./ImageStrip";
import axios from 'axios';
import { GET_COURSE, GET_PROMOCODES, USER_REFFERAL } from "../../../../api/vsc";
import CouponList from './CouponList';
import '../../../../stylesheets/paymentcheckout.css';
import { useParams, useNavigate } from 'react-router-dom';
import Footer from '../../../Footer/Footer';

// LoginModal component
const LoginModal = ({ open, handleClose }) => {
  const navigate = useNavigate();
  
  const handleLogin = () => {
    // Store the current URL
    const currentUrl = window.location.pathname;
    // Navigate to the login page with state
    navigate('/login', { state: { redirect: currentUrl } });
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Box
          p={4}
          bgcolor="white"
          borderRadius={4}
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Typography variant="h6" gutterBottom>
            Please Log In
          </Typography>
          <Typography variant="body1" gutterBottom>
            You need to log in to continue with the payment process.
          </Typography>
          <Button variant="contained" color="primary" onClick={handleLogin}>
            Log In
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

const PaymentCheckout = () => {
  const params = useParams();
  const [couponCode, setCouponCode] = useState("");
  const [isCouponApplied, setIsCouponApplied] = useState(false);
  const [codes, setCodes] = useState("");
  const [selectedCode, setSelectedCode] = useState([]);
  const [message, setMessage] = useState('');
  const [course, setCourse] = useState([]);
  const [user, setUser] = useState({});
  const [total, setTotal] = useState(0);
  const [showLoginModal, setShowLoginModal] = useState(false);

  useEffect(() => {
    const token = window.localStorage.getItem("token");

    if (!token) {
      // Show login modal if user is not logged in
      setShowLoginModal(true);
    } else {
      // Fetch user data if token is present
      axios
        .get(USER_REFFERAL + params.id, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setUser(response.data.data);
          const totalSum = response.data.data.cart
            .filter(
              (cart) =>
                cart.courseId !== "64fb08d372d0fde41877df63" &&
                cart.courseId !== "64fb15b672d0fde41877e193" &&
                cart.courseId !== "64fb105b72d0fde41877e101"
            )
            .reduce((acc, cart) => {
              const price = params.category === 'training' && cart.trainingEnabled ? cart.trainingPrice : cart.price;
              return acc + price;
            }, 0);

          setTotal(totalSum);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [params.category, params.id]);

  const handleCouponCodeChange = (event) => {
    setCouponCode(event.target.value);
    if(event.target.value === ""){
      setIsCouponApplied(false);
      setMessage('');
      setSelectedCode([]);
    }
  };

  const handleSetCode = (value) => {
    setSelectedCode(value);
    setCouponCode(value.code);
  };

  const handleApplyCoupon = async () => {
    try {
      const response = await axios.get(GET_PROMOCODES);
      const couponCodes = response.data.data;
      couponCodes.map((code) => {
        if(couponCode === code.code){
          setSelectedCode(code);
        }
      });
  
      const selectedC = couponCodes.find(data => data.code === couponCode);
      if (selectedC) {
        setIsCouponApplied(true);
        setMessage('Applied');
      } 
      else if(!selectedC){
        setIsCouponApplied(true);
        setMessage('No Code');
      }
      
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
    <Box p={0}
    sx={{
      marginTop:'100px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between'
    }}>
      <Box mb={2} pl={3} pt={3}>
        <Typography variant="h5" gutterBottom style={{ textAlign: 'left' , color:'#6A58C6'}}>
          Payment Summary
        </Typography>
      </Box>

      <Divider style={{marginBottom:'20px'}}/>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent:'space-between',
          alignItems: 'center',
          '@media only screen and (min-width: 764px)': {
            flexDirection: 'row',
            alignItems: 'flex-start',
          },
        }}
        className='box_payment'
      >
      <Box sx={{
        width:'100%',
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'center'
      }}>
      <Box
        sx={{
          width: '70%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '10px',
          marginBottom: '40px',
          '@media only screen and (min-width: 764px)': {
            marginBottom:'100px'
          },
        }}
      >
        <div>
          {user.cart &&
            user.cart.find(
              (cart) =>
                cart.courseId === "64fb08d372d0fde41877df63" ||
                cart.courseId === "64fb15b672d0fde41877e193" ||
                cart.courseId === "64fb105b72d0fde41877e101"
            ) && (
              <>
                <Typography
                  variant="subtitle1"
                  color={"#6A58C6"}
                  style={{ fontWeight: "900" }}
                >
                  Product To Model : Startup Playbook
                </Typography>
                <Typography variant="subtitle1" color={"#6A58C6"}>
                  {" "}
                  Minimum Viable Product Building
                </Typography>
                <Typography variant="subtitle1" color={"#6A58C6"}>
                  {" "}
                  Go To Market Strategy
                </Typography>
                <Typography variant="subtitle1" color={"#6A58C6"}>
                  Business Model Canvas Creation
                </Typography>
              </>
            )}
          {user.cart &&
            user.cart
              .filter(
                (cart) =>
                  cart.courseId !== "64fb08d372d0fde41877df63" &&
                  cart.courseId !== "64fb15b672d0fde41877e193" &&
                  cart.courseId !== "64fb105b72d0fde41877e101"
              )
              .map((cart) => (
                <Typography
                  key={cart.courseId}
                  variant="subtitle1"
                  color={"#6A58C6"}
                >
                  {cart.courseName}
                </Typography>
              ))}

          <Typography variant="caption">Happy Learning !</Typography>
        </div>
      </Box>

      <Box mt={2} display="flex" flexDirection="column" width={'70%'}>
        <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '8px' }}>
          <TextField
            variant="standard"
            size="small"
            value={couponCode}
            onChange={handleCouponCodeChange}
            placeholder="Enter The Coupon Code"
          />
          <Button
            variant="standard"
            onClick={handleApplyCoupon}
            style={{color:'#6A58C6'}}
          >
            Apply
          </Button>
        </div>

        {isCouponApplied ? (
          <div style={{width:'100%',height:'100%'}} >
            <CouponCard 
              couponCode={selectedCode.code} 
              message={message}
              selectedCode={selectedCode}
            /> 
          </div>
        ) : <CouponList handleSetCode={handleSetCode}/>}
      </Box>
      </Box>

      <Box sx={{display:'flex' , justifyContent:'center'  , width:'100%' , height:'100%'}}>
        <PaymentCard 
          isCouponApplied={isCouponApplied} 
          selectedCode={selectedCode}
          course={course}
          total={total}
        />
      </Box>
      </Box>
      <Box style={{marginTop:'100px', marginBottom:'20px'}}>
        <ImageStrip />
      </Box>
    </Box>
    <Footer/>
    <LoginModal open={showLoginModal} handleClose={() => setShowLoginModal(false)} />
    </>
  );
};

export default PaymentCheckout;
