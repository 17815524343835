import React from 'react';
import { Button, Box, Typography, styled } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const StyledBox = styled(Box)(({ theme }) => ({
  border: '2px dashed #6a58c6',
  padding: '20px',
  borderRadius: '8px',
  textAlign: 'center',
  cursor: 'pointer',
 '&:hover': {
    backgroundColor: '#e0dfff', // Lightened shade of #6a58c6
    backdropFilter: 'blur(5px)', 
    cursor: 'pointer' 
  },
  height: 200,
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center'
}));

const ResumeUpload = ({ formData, setFormData }) => {
  const handleResumeChange = (e) => {
    setFormData({ ...formData, resume: e.target.files[0] });
  };

  return (
    <Box sx={{marginTop: '40px' , display: 'flex' , alignItems: 'center' , justifyContent: 'center'}}>
    <Box sx={{with: '60%'}}>
      <Typography variant="h6" gutterBottom textAlign={'center'} sx={{ color: '#6a58c6'}}>
        File Upload
      </Typography>
      <StyledBox>
        <CloudUploadIcon sx={{fontSize: '100px' , color: '#6a58c6'}}/>
        <Typography variant="body1" gutterBottom>
          Drag and Drop Files
        </Typography>
        <Typography variant="body2" gutterBottom>
          or
        </Typography>
        <Button variant="contained" component="label" sx={{backgroundColor: '#6a58c6'}}>
          Browse
          <input
            type="file"
            hidden
            onChange={handleResumeChange}
            accept="application/pdf"
          />
        </Button>
      </StyledBox>
      {formData.resume && (
        <Typography variant="body2" gutterBottom>
          Uploaded Resume: {formData.resume.name}
        </Typography>
      )}
      </Box>
    </Box>
  );
};

export default ResumeUpload;
