import * as React from 'react';
import { makeStyles } from '@mui/styles';
import { TextField, Button, Typography, Box  } from '@mui/material';
import axios from 'axios';
import { GET_CERTIFICATES } from '../../api/certificate';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import CertificateGen from '../Event/CertificateGen';
import Footer from '../Footer/Footer';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CertificateVerification() {
    const [certId , setCertId] = React.useState('');
    const [name , setName] = React.useState('');
    const [open , setOpen] = React.useState(false);
    const [present , setPresent] = React.useState(false);
    const [nameD , setNameD] = React.useState('');
    const [certificateId , setCertificateId] = React.useState('');
    const handleClickOpen = () => {
        setOpen(true);
      };
    
      const handleClose = () => {
        setOpen(false);
      };
      const refresh = () => {
        setCertId('');
        setName('');
      }
    const handleCheck = () => {
        axios
        .post(GET_CERTIFICATES , {certId: certId , name: name})
        .then(res => {
            if(res.data.data) {
                setCertificateId(res.data.data.serielNumber);
                setNameD(res.data.data.userName);
                setPresent(true);
                refresh();

            }
            else {
                setPresent(false);
            }
            handleClickOpen();
        })
        .catch(err => {
            console.error(err);
        });
    }
  return (
    <>
    <Box sx ={{
        marginTop:'120px',
        display: 'flex',
            flexDirection:'column',
            gap: '10px',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: '50px'
    }}>
      
        <Box item xs={12} className="text-center">
          <Typography variant="h4" component="h1" gutterBottom sx={{textAlign: 'center' , fontFamily:'Poppins' , fontWeight: '900'}}>
            Verify Certificate
          </Typography>
          <Typography variant="body1" color="textSecondary" sx={{textAlign: 'center', fontFamily:'Poppins' , fontWeight: '900'}}>
            Enter the Certificate ID and the Name of the person to verify the authenticity of the certificate.
          </Typography>
        </Box>
        <Box sx={{
            display: 'flex',
            flexDirection:'column',
            gap: '10px',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '60px' , 
            background: '#6A58C6',
            width: 300 , 
            height: 300,
            boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px',
            borderRadius: '10px'
        }}>
        <Box item xs={12}>
          <TextField
            id="certificate-id"
            label="Certificate ID"
            variant="outlined"
            placeholder="Enter Certificate ID"
            fullWidth
            value={certId}
            onChange={(e) => {
                setCertId(e.target.value);
            }}
            sx={{
                background: '#fff',
                '& .MuiOutlinedInput-root': {
                borderColor: 'gray', // Initial border color
                '&:focus': {
                    borderColor: 'black', 
                },
                },
                '& .MuiInputLabel-root': {
                color: 'gray', // Initial label color
                '&.Mui-focused': {
                    color: 'black', // Label color on focus
                },
                },
                borderRadius: '10px'
      }}
      InputProps={{
        disableUnderline: true, // Remove default underline
      }}
          />
        </Box>
        <Box item xs={12}>
          <TextField
            id="name"
            label="Name"
            variant="outlined"
            placeholder="Enter Name"
            fullWidth
            value={name}
            onChange={(e) => {
                setName(e.target.value);
            }}
            sx={{
        background: '#fff',
        '& .MuiOutlinedInput-root': {
          borderColor: 'gray', // Initial border color
          '&:focus': {
            borderColor: 'black', 
            
          },
        },
        '& .MuiInputLabel-root': {
          color: 'gray', // Initial label color
          '&.Mui-focused': {
            color: 'black', // Label color on focus
          },
        },
        borderRadius: '10px'
      }}
      InputProps={{
        disableUnderline: true, // Remove default underline
      }}
          />
        </Box>
        <Box item xs={12}>
          <Button 
            variant="contained" 
            fullWidth 
            sx={{background: '#000'}}
            onClick={(e) => {
                if(certId === ''){
                    alert("Certificate Id Is Mandatory");
                }
                else {
                    handleCheck();
                }
            }}
        >
            Verify
          </Button>
        </Box>
        </Box>
        <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description" sx={{display:'flex' , justifyContent:'center' , alignItems:'center' , flexDirection:'column'}}>
             {present ? <CheckCircleIcon 
             sx={{color: 'green' , fontSize: '70px'}}/>: <CancelIcon 
             sx={{color: 'red' , fontSize: '70px'}}/>}

             {present ? <Typography textAlign='center' variant="h5" sx={{fontFamily: 'Poppins' , fontWeight: '900'}}>This Certificate Is VSC Verified !</Typography> : <Typography textAlign='center' variant="h5" sx={{fontFamily: 'Poppins' , fontWeight: '900'}}>This Certificate Is Not VSC Verified !</Typography>}
             {present ? <Typography textAlign='left' variant="subtitle2" sx={{fontFamily: 'Poppins' , fontWeight: '900' , color: '#000'}}>Issued To : {nameD}</Typography> : <Typography textAlign='center' variant="subtitle2" sx={{fontFamily: 'Poppins' , fontWeight: '900'}}>{""}</Typography>}
             {present ? <Typography textAlign='center' variant="subtitle2" sx={{fontFamily: 'Poppins' , fontWeight: '900' , color: '#000'}}>Certificate Id : {certificateId}</Typography> : <Typography textAlign='center' variant="subtitle2" sx={{fontFamily: 'Poppins' , fontWeight: '900'}}>{""}</Typography>}


          </DialogContentText>
        </DialogContent>
      </Dialog>
      </Box>
      <Footer />
      </>
  );
}
