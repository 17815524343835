import * as React from 'react';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { Global } from '@emotion/react';
import { styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { grey } from '@mui/material/colors';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';

const drawerBleeding = 56;

const Root = styled('div')(({ theme }) => ({
    height: '100%',
    backgroundColor:
        theme.palette.mode === 'light' ? grey[100] : theme.palette.background.default,
}));

const StyledBox = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : grey[800],
}));

const Puller = styled(Box)(({ theme }) => ({
    width: 30,
    height: 6,
    backgroundColor: theme.palette.mode === 'light' ? grey[300] : grey[900],
    borderRadius: 3,
    position: 'absolute',
    top: 8,
    left: 'calc(50% - 15px)',
}));

function SwipeableEdgeDrawer(props) {
    const { window, Step1, Step2, Step3 } = props;

    const [open, setOpen] = React.useState(false);

    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
    };

    // This is used only for the example
    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <Root>
            <CssBaseline />
            <Global
                styles={{
                    '.MuiDrawer-root > .MuiPaper-root': {
                        height: `calc(50% - ${drawerBleeding}px)`,
                        overflow: 'visible',
                    },
                }}
            />
            <SwipeableDrawer
                container={container}
                anchor="bottom"
                open={open}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
                swipeAreaWidth={drawerBleeding}
                disableSwipeToOpen={false}
                ModalProps={{
                    keepMounted: true,
                }}
            >
                <StyledBox
                    sx={{
                        position: 'absolute',
                        top: -drawerBleeding,
                        borderTopLeftRadius: 8,
                        borderTopRightRadius: 8,
                        visibility: 'visible',
                        right: 0,
                        left: 0,
                    }}
                >
                    <Puller />
                    <Typography sx={{ p: 2, color: 'text.secondary' }}>0 of 4 completed</Typography>
                </StyledBox>
                <StyledBox
                    sx={{
                        px: 2,
                        pb: 2,
                        height: '100%',
                        overflow: 'auto',
                    }}
                >
                    <Grid item xs={2.6}>
                        <Box
                            sx={{
                                borderWidth: "0px 4px 0px 0px",
                                width: "100%",
                                height: '40vh',
                                boxShadow: '5'
                            }}>
                            <Grid container>
                                <Grid item xs={0.5}></Grid>
                                <Grid item xs={11.5}>
                                    <Typography>
                                        0 of 4 completed
                                    </Typography>
                                </Grid>
                                <Grid item xs={0.5}></Grid>
                                <Grid item xs={0.5} style={{ marginTop: "10px" }}>
                                    <Typography>
                                        <span style={{ borderRadius: "50%", border: '1px solid silver', padding: "6px" }}>1</span>
                                    </Typography>
                                </Grid>
                                <Grid item xs={11}>
                                    <Button>
                                        <Typography style={{ textTransform: "none", textAlign: "left", color: "black", cursor: "pointer" }}>
                                            {Step1}
                                        </Typography>
                                    </Button>
                                </Grid>
                                <Grid item xs={0.5}></Grid>
                                <Grid item xs={0.5} style={{ marginTop: "10px" }}>
                                    <Typography>
                                        <span style={{ borderRadius: "50%", border: '1px solid silver', padding: "6px" }}>2</span>
                                    </Typography>
                                </Grid>
                                <Grid item xs={11}>
                                    <Button>
                                        <Typography style={{ textTransform: "none", textAlign: "left", color: "black", cursor: "pointer" }}>
                                            {Step2}
                                        </Typography>
                                    </Button>
                                </Grid>
                                <Grid item xs={0.5}></Grid>
                                <Grid item xs={0.5} style={{ marginTop: "10px" }}>
                                    <Typography>
                                        <span style={{ borderRadius: "50%", border: '1px solid silver', padding: "6px" }}>3</span>
                                    </Typography>
                                </Grid>
                                <Grid item xs={11}>
                                    <Button>
                                        <Typography style={{ textTransform: "none", textAlign: "left", color: "black", cursor: "pointer" }}>
                                            {Step3}
                                        </Typography>
                                    </Button>
                                </Grid>
                                <Grid item xs={0.5}></Grid>
                                <Grid item xs={0.5} style={{ marginTop: "10px" }}>
                                    <Typography>
                                        <span style={{ borderRadius: "50%", border: '1px solid silver', padding: "6px" }}>4</span>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </StyledBox>
            </SwipeableDrawer>
        </Root>
    );
}

SwipeableEdgeDrawer.propTypes = {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */

    window: PropTypes.func,
};

export default SwipeableEdgeDrawer;
