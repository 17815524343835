import React from 'react';
import pic1 from './Images/1.png'; 
import pic2 from './Images/2.png'; 
import pic3 from './Images/3.png'; 
import pic4 from './Images/4.png'; 
import pic5 from './Images/5.png'; 
import pic6 from './Images/6.png'; 
import pic7 from './Images/7.png'; 
import pic8 from './Images/8.png'; 
import pic9 from './Images/9.png'; 
import pic10 from './Images/10.png'; 
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import {IconButton , useMediaQuery} from '@mui/material';
import './ImageStrip.css';


const ImageStrip = () => {
    const images = [pic1, pic2, pic3, pic4, pic5, pic6, pic7, pic8, pic9, pic10];
    const [currentIndex, setCurrentIndex] = React.useState(0);
    const isMobile = useMediaQuery('(max-width: 500px)');
  
    const nextImage = () => {
      const marquee = document.querySelector('.pictureMarqueee');
      marquee.style.animation = 'none'; // Pause the animation
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
      setTimeout(() => {
        marquee.style.animation = 'marqueeAnimation 120s linear infinite reverse'; // Resume the animation after a short delay
      }, 0);
    };
  return (
    <div className="PictureSectionnn">
      <div className="pictureContainerr">
        <div className="pictureMarqueeee" style={{ transform: `translateX(-${currentIndex * 300}px)` }}>
          {images.map((image, index) => (
            <div key={index} className="divContainerr">
              <img src={image} alt={`Image ${index + 1}`} className="carousel-imagee" />
            </div>
          ))}
        </div>
      </div>
      {isMobile&&<button
            type="button"
            onClick={nextImage}
            className="nextButton"
          >
            <ArrowForwardIosRoundedIcon style={{color:'#fff'}} />
          </button>}
    </div>
  )
}

export default ImageStrip