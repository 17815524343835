import * as React from 'react';
import { Carousel } from 'react-responsive-carousel';
import { Box, Card, CardMedia, CardContent, CardActions, Typography, Link } from '@mui/material';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import { useMediaQuery } from '@mui/material';
import dummy from '../../NewVirtualCampusStartup/Images/bmc.png'
import { GET_DATA } from '../../../api/watchnow';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import ReadMoreAndLess from "react-read-more-less";
import { POSTER } from '../../../api/watchnow';


const WatchnowCarousel = ({ blogs }) => {
    const [isAuth, setIsAuth] = React.useState(window.localStorage.getItem('isAuth') || false);
    const [watchnows , setWatchnows] = React.useState([]);
    const navigate = useNavigate();
    React.useEffect(() => {
        axios
          .get(GET_DATA, {
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem("token")}`,
            },
          })
          .then((res) => {
            const showVsvTrueElements = res.data.filter((item) => item.showVsc);
            const showVsvFalseElements = res.data.filter((item) => !item.showVsc);
            const updatedData = showVsvTrueElements.slice(0, 4).concat(showVsvFalseElements);
            setWatchnows(updatedData);
          });
      }, []);
  const arrowStyles = {
    position: "absolute",
    top: ".7em",
    bottom: "auto",
    padding: ".4em",
    zIndex: 2,
 };
  const isMobile = useMediaQuery('(max-width: 600px)');
  const isSmallMobile = useMediaQuery('(max-width: 410px)');
  const isGreaterMobile = useMediaQuery('(max-width: 500px)');
  const getNumBlogsPerSlide = () => {
    if (isGreaterMobile) {
      return 1;
    } else if (isSmallMobile) {
      return 2;
    } else {
      return 3;
    }
  };
  const numBlogsPerSlide = getNumBlogsPerSlide();
  const numSlides = Math.ceil(watchnows.length / numBlogsPerSlide);

  return (
    <div style={{width:'100%'}}>
    <Carousel
     className="carousel"
       style={{ display: 'flex', justifyContent: 'center' , width:'100%'}}
       showArrows={true}
       showIndicators={false}
       renderArrowPrev={(onClickHandler, hasPrev, label) =>
    hasPrev && (
      <button
        type="button"
        onClick={onClickHandler}
        title={label}
        style={{...arrowStyles,
          left: '0.5em', // Adjust positioning for mobile and non-mobile
          top: '50%',
          transform: 'translateY(-50%)', // Center vertically
          backgroundColor: "#6a58c6",
          color: '#fff',
          borderRadius: '50%', // Make it round
          border: 'none',
          padding: '10px',
          zIndex: 1, // Ensure the button is above the carousel content
        }}
      >
        <ArrowBackIosNewRoundedIcon />
      </button>
    )
  }
  renderArrowNext={(onClickHandler, hasNext, label) =>
    hasNext && (
      <button
        type="button"
        onClick={onClickHandler}
        title={label}
        style={{
          ...arrowStyles,
          right:  '0.5em'  , // Adjust positioning for mobile and non-mobile
          top: '50%',
          transform: 'translateY(-50%)', // Center vertically
          backgroundColor: "#6a58c6",
          color: '#fff',
          borderRadius: '50%', // Make it round
          border: 'none',
          padding: '10px',
          zIndex: 1, // Ensure the button is above the carousel content
        }}
      >
        <ArrowForwardIosRoundedIcon />
      </button>
    )
  }
       >
     {Array.from({ length: numSlides }).map((_, slideIndex) => (
        <div key={slideIndex}>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              gap: '1rem',
            }}
          >
            {watchnows.slice(slideIndex * numBlogsPerSlide, (slideIndex + 1) * numBlogsPerSlide).map((blog) => (
                <Card sx={{
                // height: isGreaterMobile?(isSmallMobile?'90%':''):'530px',
                // width: isGreaterMobile?'90%':'100%'
                width: isGreaterMobile ? '90%' : '30%'
              }}>
                <div >
                  <CardMedia
                    component="img"
                    width="200px"
                    image={POSTER + blog.poster.name}
                    // image={dummy}
                    alt="..."
                  />
                </div>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    <Box
                      component="div"
                      overflow="hidden"
                      textOverflow="ellipsis"
                      style={{
                        display: '-webkit-box',
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: 'vertical',
                        fontSize: '1.5rem',
                        fontWeight: 'bold',
                        lineHeight: '1.2',
                      }}
                    >
                      {blog.title}
                    </Box>
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    <Box
                      component="div"
                      overflow="hidden"
                      textOverflow="ellipsis"
                      style={{
                        display:'flex',
                        justifyContent:'center'
                      }}
                    >
                     <p
                        style={{
                          padding: "2px 10px",
                          backgroundColor: "#F2F6FF",
                          borderRadius: "10px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "233px",
                          height: "20px",
                          color: "#3470e4",
                          textTransform: "uppercase",
                          fontFamily: "Open Sans",
                          fontSize: "12px",
                          lineHeight: "16px",
                          fontWeight: "600",
                        }}
                      >
                        {blog.speaker}
                      </p>
                    </Box>
                  </Typography>
                </CardContent>
                  <CardActions style={{display:'flex' , justifyContent:'flex-end'}}>
                          <a
                                    onClick={()=> {
                                        if(isAuth){
                                            navigate("/watch_now/watch", { state: { link: blog.link } })
                                        }   
                                        else {
                                            navigate('/login' , {state:{redirect:'/watch_now'}})
                                        }
                                        
                                    }}
                                    style={{ 
                                      textDecoration: "none" ,
                                      color: "white",
                                        fontSize: "15px",
                                        padding: "5px",
                                        borderRadius: "5px",
                                        cursor: "pointer",
                                        backdropFilter: "blur(10px)",
                                        backgroundColor: "#6A58c6",
                                      }}
                                  >
                                      View
                                  </a>
                                  <a
                                    onClick={()=> {
                                        if(isAuth){
                                            navigate("/watch_now")
                                        }   
                                        else {
                                            navigate('/login' , {state:{redirect:'/watch_now'}})
                                        }
                                        
                                    }}
                                    // href={'/blog/'+blog._id}
                                    style={{ 
                                      textDecoration: "none" ,
                                      color: "white",
                                        fontSize: "15px",
                                        padding: "5px",
                                        borderRadius: "5px",
                                        cursor: "pointer",
                                        backdropFilter: "blur(10px)",
                                        backgroundColor: "#6A58c6",
                                      }}
                                  >
                                      Go To Watchnow {">"}
                                  </a>
                  </CardActions>
                </Card>
            ))}
            
          </Box>
        </div>
      ))}
      
    </Carousel>
    </div>
  );
};

export default WatchnowCarousel;
