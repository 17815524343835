import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions , Box } from '@mui/material';
import {useNavigate} from 'react-router-dom';
import dayjs from 'dayjs';
import 'dayjs/locale/en'; 
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);
export default function EventCard({data }) {
  const inputDateString = data?.date;
  const navigate = useNavigate();
  const convertedDate = inputDateString
    ? inputDateString.split('/').reverse().join('-')
    : null;
  const formattedDate = convertedDate
    ? dayjs(convertedDate).locale('en').format('D MMMM YYYY dddd')
    : null;
  const isAuth = window.localStorage.id ? true: false;
  return (
    <Card 
        sx={{ 
            width: {sm: '75%' , xs: '90%' }, 
            height: '100%' , 
            boxShadow: 'rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px',
            borderRadius: '25px'
     }}>
      <CardActionArea>
        <CardMedia
          component="img"
          height="70%"
          image={data?.eventPoster}
          alt="green iguana"
        />
        <CardContent 
            sx={{
                display:'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                height: '30%'
            }}
        >
         <Box
            sx={{
                alignSelf:'flex-start'
            }}
         >
          <Typography 
            gutterBottom 
            variant="h4" 
            component="div"
            fontFamily={'Poppins'}
            fontWeight="800"
            sx={{
              fontSize: {sm: '36px' , xs: '24px' }
            }}
            >
            {data?.title}
          </Typography>
          <Typography 
            variant="body2" 
            sx={{
                backgroundColor: '#FFBD59',
                width: 'fit-content',
                padding: '2px',
                borderRadius:'5px'
            }}>
            {formattedDate}
          </Typography>
          </Box>
          <Button 
            variant="contained"
            sx={{
                textTransform: "none",
                background: '#6a58c6',
                height: 50
            }}
            onClick={() => 
            {
              isAuth ? navigate("/eventsform/"+data?._id): navigate('/login' , {state:{redirect:"/eventsform/"+data?._id }})
            }
            }
           >
           Register
          </Button>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
