import React , {useState , useEffect} from "react";
// import Footer from "../About/Footer.js";
import { useHistory } from "react-router-dom";
import "../../stylesheets/virtualCampusStartup.css";
import image from "./Images/scribble.png";
// import Shirish from "./Images/Shirish.jpeg";
import Karan from "./Images/karan.jpg";
import Santanu from "./Images/Santanu.jpeg";
import Ganesh from "./Images/Ganesh.jpeg";
import Bajendra from "./Images/Bajendra.jpeg";
import Vivek from "./Images/Vivek.png"
import underline from "./Images/underline.svg";
import item1 from "./Images/item1.png";
import item_w from "./Images/item1-w.png";
import ApplyNow from "./Images/ApplyNow.png";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { useLocation } from "react-router-dom";
import dummy from "./Images/bmc.png";

import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import InventoryOutlinedIcon from "@mui/icons-material/InventoryOutlined";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import { Link , useNavigate } from "react-router-dom";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Footer from "../Footer/Footer";
import Typewriter from "typewriter-effect";
import { Button } from "@material-ui/core";
import { GET_BLOGS,IMAGE } from "../../api/blog";
import axios from 'axios';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActions } from '@mui/material';
import { Box,Typography } from "@material-ui/core";
import { useMediaQuery } from '@mui/material';
import {makeStyles} from '@mui/styles';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import BlogsCarousel from "./BlogCarousel";
import WatchnowCarousel from "./WatchnowCarousel";
import ImageSetup from "./ImageSetup";
import { IconButton } from "@mui/joy";
import ImageCarousel from "./ImageCarousel";
import DowloadCaseStudyDialog from "./DowloadCaseStudyDialog";
import Testimonial from '../Testimonial/index';
import News from '../News/index';
import CertLanding from "../CertLanding";
import LearnApplyWorkex from "../LearnApplyWorkexLanding";
import CourseCategory from '../CourseCategory/index';
import LandingCourses from "../LandingCourses";

const NewVirtualCampusStartup = ({isAuth , setIsAuth}) => {
  const location = useLocation();
  const from = location.state?.from;
  if(isAuth && !from){
    window.location.href = "/dashboard/"+window.localStorage.id;
  }
  const classes = u();
  const navigate = useNavigate();
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(false);
  const isMobile = useMediaQuery('(max-width: 900px)');
  const isSmallMobile = useMediaQuery('(max-width: 410px)');
  const isGreaterMobile = useMediaQuery('(max-width: 500px)')

  useEffect(() => {
    setLoading(true);
    axios
      .get(GET_BLOGS, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        const showVsvTrueElements = res.data.filter((item) => item.showVsc);
        const showVsvFalseElements = res.data.filter((item) => !item.showVsc);
        const updatedData = showVsvTrueElements.slice(0, 4).concat(showVsvFalseElements);
        setBlogs(updatedData);
        setLoading(false);
      })
      .catch((err) => {
      });
  }, []);
  return (
    <div className="v_main" >
      <section style={{marginTop:isMobile?'170px':'80px' , display:'flex' , flexDirection:!isMobile?'row':'column' , height:'100%' , justifyContent:'center' , alignItems:'center'}}>
      <Box style={{width:isMobile?'100%':'50%' , height:'100%' , marginBottom:isMobile&&'0'}}>
        <div style={{display:'flex' , flexDirection:'column' , justifyContent:isMobile?'center':'flex-start' , alignItems:isMobile?'center':'flex-start' , paddingLeft:!isMobile&&'20px'  }}>
        {/* <div style={{width:'70%' , display:'flex' }}> */}
        <div style={{
          background:'#FFBD59',
          width: '200px',
          height: '40px',
          textAlign: 'left',
          display:'flex' , 
          justifyContent:'center',
          alignItems:'center',
          marginBottom: !isMobile&&'30px',
          fontWeight:'700',
          fontFamily:'Poppins'
        }}>

          <p>Virtual Startup Campus</p>
        </div>
        {/* </div> */}
       
        <h1 style={{textAlign:isMobile?'center':'left' , fontWeight:'900' , marginBottom:isMobile&&'10px' ,width:isMobile&&'95%' , fontSize:isMobile? '26px':'42px' }}>Get Industry Exposure</h1>
        <h2 style={{textAlign:isMobile?'center':'left' ,  fontWeight:'900',width:isMobile&&'95%',fontSize:isMobile?'25px':'30px' , marginBottom:'10px'}}>
         Hands on 
        <span style={{marginLeft:'10px',fontWeight:'900',color:'#6a58c6'}}>
        <u style={{textDecorationColor: '#FFBD59' , textDecorationThickness: '3px'}}>Experience</u>
        </span>
        </h2>
        </div>
        
        

        <div   style={{marginBottom:isMobile?'40px':'30px',width:isMobile?'100%':'95%' , height:isMobile? '90%':'70%' , paddingLeft:!isMobile&&'20px' }}>
          <p className="p1" style={{width:'100%',fontFamily:'Poppins',color:'#000',textAlign:isMobile?'center':'left',marginBottom:isMobile&&'5px' , wordWrap:'break-word' , fontSize:isMobile?'20px':'24px'}}>
          With AI Disrupting the Job Market, Get Ready with 21st Century On Demand Skillsets, Mini Projects, Startup Experience and Community Interaction</p>
          
        </div>
        <div  style={{width:isMobile?'100%':'95%'  ,marginTop:isMobile&&'50px', display: 'flex',flexDirection:'column' , justifyContent:isMobile?'center':'flex-start' , alignItems:isMobile?'center':'flex-start'  , padding:'3%'}}>
        <Button onClick={(e) => {
          navigate(isAuth?'/virtual_campus':'/login' , {state:{redirect:'/virtual_campus'}});
        }} 
        variant="contained" 
        style={{ width: isMobile ? '80%' : '55%',  background:'#6a58c6' , marginBottom:'10px', height:'40px' }}>
         Join Corporate Experience Training Program
        </Button>
        <DowloadCaseStudyDialog isMobile={isMobile}/>
        </div>

        </Box>
        <Box style={{width:isMobile?'100%':'50%',display:'flex' , flexDirection:'row'}} >
             <ImageSetup/>
        </Box>
      </section>
      <Link to="/courses">
      <section className="PictureSection" style={{width:isMobile&&'100%'}} >
        <div className="pictureMarquee">
          <div className="StudentImage1"></div>
          <div className="StudentImage2"></div>
          <div className="StudentImage3"></div>
          <div className="StudentImage3"></div>
        </div>
      </section>
      </Link>
      
      <CourseCategory/>
      <div className="packageRalatiedNews">
        <div className="packageDetailsAndEnrollment">
          <div className="Enrolled">
            {/* <span>
              <span>2500+</span>Community Students
            </span> */}
            <span>
            <span>2500</span> Users Enrolled <br />
            </span>
            <span
              style={{
                marginTop: "1rem",
                marginBottom: "1rem",
              }}
            >
              <span>50+</span> City Presence
            </span>
            <span>
              <span>93%</span> Industry  <u style={{textDecorationColor: '#FFBD59' , textDecorationThickness: '2px'}}>Ready SkillSuit</u>
            </span>
            <img
              src={underline}
              style={{
                position: "relative",
                right: "14rem",
              }}
            />
          </div>
          <div style={{ display: "flex" }}>
            <div className="lowestLPA">
              <span style={{ color: "#ffdb66", fontWeight: "700" }}>65

 </span>{" "}
 Topics Covered<br />
              <span style={{ color: "#ffdb66", fontWeight: "700" }}>2000</span>
              beta users scoped in
            </div>
            <div className="heightLPA">
              Our Presence at:
              <br />
              IIT Delhi,
              <br />
              IIT Bombay,
              <br />
              IIM Shillong
            </div>
          </div>
        </div>
        {/* <div className="MasaiInTheNews">
          <img src="/Images/Masai In News/media.png" />
          <div class="MasaiInTheNews_text">Masai In The News</div>
          <button>KNOW MORE</button>
        </div> */}
      </div>
      <div style={{width:'100%' , display:'flex', flexDirection:'column' , justifyContent:'center'}}>
      <div className="Instructors_top">
          <h1>Our Presence</h1>
          <p>
          We are continuously striving to bring “Application Driven Industry Ready” Skillsets at Campuses.
          </p>
        </div>
          <ImageCarousel/>
      </div>
      <br/>
      <br/>
        <Box
           sx={{
              // marginTop: {xs: '1700px', sm: '1500px' , md: '1450px' ,lg: '100px'}
              marginTop: '50px'
            }}
        >
          <LandingCourses/>
        </Box>
      
          <Box
            sx={{
              // marginTop: {xs: '1700px', sm: '1500px' , md: '1450px' ,lg: '100px'}
              marginTop: {xs: '20px' , md: '70px'}
            }}
          >
            <LearnApplyWorkex/>
          </Box>
          <Box>
            <Testimonial />
          </Box>
          <Box>
            <CertLanding />
          </Box>
          <Box>
            <News />
          </Box>
          
      <div className="main_program">
        <div className="program">
          <div className="textProgram">
            <h2 style={{fontSize:isMobile&&'25px'}}>
            Why Virtual Startup Campus is your “Parallel College Destination” to Career Growth?

            </h2>
            <br/>
            {isMobile && (<><br/><br/></>)}
            <p style={{marginBottom:'10px'}}>
            Virtual Startup Campus provides you the one place to get all “Next Generation Application Driven Education” at the most outcome driven, real time case oriented and hands on practical exposure that helps you not miss out on the growth you can achieve beyond regular education.
            </p>
            <Button onClick= {(e)=> {navigate(isAuth?'/virtual_campus':'/login' , {state:{redirect:'/virtual_campus'}});}}>
            <div className="ApplyNowButton_2">Start Learning</div>
            </Button>
          </div>
          <div className="programvideo">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/CFMuII2gwQE"
              title="YouTube video player"
              id="widget2-video"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen="1"
            ></iframe>
          </div>
        </div>
        {/* <div className="applyButtonContainer_2">
          <a href="/virtual_campus" >
            <div className="ApplyNowButton_2">Start Learning</div>
          </a>
        </div> */}
      </div>
      <div className="Instructors">
        <div className="Instructors_top">
          <h1>Meet Your Mentors</h1>
          <p>
            Meet the panel of mentors who bring their valuable insights on the
            table for you.
          </p>
        </div>
        <div className="Instructors_profiledes">
          {/* <div className="Instructors_profile">
            <img src={Shirish} />
            <h3 style={{display:'flex' , alignItems:'center'}}>Shirish <Link to="https://www.linkedin.com/in/shirish-surti/"><IconButton><LinkedInIcon/></IconButton></Link></h3>
            <p>Ex CTO Chaipoint </p>
            <hr className="solid_virtual" />  
          </div> */}
          <div className="Instructors_profile">
            <img src={Karan} />
            <h3 style={{display:'flex' , alignItems:'center'}}>Karan Chandwani<Link to="https://www.linkedin.com/in/karan-chandwani04"><IconButton><LinkedInIcon/></IconButton></Link></h3>
            <p>Management Consultant </p>
            <hr className="solid_virtual" />  
          </div>
          <div className="Instructors_profile">
            <img src={Santanu} />
            <h3 style={{display:'flex' , alignItems:'center'}}>Santanu <Link to="https://www.linkedin.com/in/santanub/"><IconButton><LinkedInIcon/></IconButton></Link></h3>
            <p>Ex Growth, Facebook </p>

            <hr className="solid_virtual" />
            {/* <div className="Instructors_company">
              <img src="https://masai-website-images.s3.ap-south-1.amazonaws.com/ex_mercuri_129d690457.svg" />
            </div> */}
          </div>

          <div className="Instructors_profile">
            <img src={Ganesh} />
            <h3 style={{display:'flex' , alignItems:'center'}}>Ganesh <Link to="https://www.linkedin.com/in/blganesh/"><IconButton><LinkedInIcon/></IconButton></Link></h3>
            <p>Co Founder, Neoastra</p>

            <hr className="solid_virtual" />
            {/* <div className="Instructors_company">
              <img src="https://masai-website-images.s3.ap-south-1.amazonaws.com/2560px_Amazon_logo_svg_77deda1bdc.png" />
            </div> */}
          </div>

          <div className="Instructors_profile">
            <img src={Bajendra} />
            <h3 style={{display:'flex' , alignItems:'center'}}>Bajendra <Link to="https://www.linkedin.com/in/tomarb4/"><IconButton><LinkedInIcon/></IconButton></Link></h3>
            <p >Co Founder, Finayo</p>

            <hr className="solid_virtual" />
            
          </div>
          <div className="Instructors_profile">
            <img src={Vivek} />
            <h3 style={{display:'flex' , alignItems:'center' }}>Vivek Mishra <Link to="https://www.linkedin.com/in/vivek-mishra-11a7b433/"><IconButton><LinkedInIcon/></IconButton></Link></h3>
            <p style={{textAlign:'center' }}>Founder, Fibroheal Woundcare</p>

            <hr className="solid_virtual" />
            
          </div>
        </div>

        
      </div>
      <div>

      </div>
      <div style={{marginTop:'50px' , marginBottom:'0'}}>
      <Box display="flex" justifyContent="center">
            <Typography variant="h4" fontWeight="800" style={{color:'#000'}}>
              InSights
            </Typography>
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            marginTop="10px"
          >
            <Typography variant="h6" style={{color:'#000'}}>
              Go across with diving deep into the stories and journey's of
              various startups from bootstrapped till funded
            </Typography>
          </Box>
          <Box
            // style={{marginTop:'30px'}}
          >
            <BlogsCarousel blogs = {blogs}/>
          </Box>
      </div>
      <div style={{marginTop:'0' , display:'flex' , justifyContent:'center'}}>
      <div className='startup-out' 
      style=
      {{
        width:isMobile?'100%':'80%' , 
        borderRadius: '25px',
        boxShadow: 'rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px',
        padding:'2%'
        
        }}>
        <div className="startup-cases" style={{marginTop:'0' , padding:'2%' , width:'100%'}}>
          <div className="startup-boxes">
          <Button variant="contained"
          style={{
            background: '#6a58c6',
            borderRadius:'25px',
            display:'block',
            width:'100%'
          }}
          href="/casestudy"
          >
            <h3 className="startup-h1" style={{textAlign:'center'}}>STARTUP</h3>
            <h3 className="startup-h1" style={{textAlign:'center'}}>Cases</h3>
          </Button>
            
          </div>
          <div className="startup-boxes">
            <h2>15+</h2>
            <h2>Case Study</h2>
          </div>
          <div className="startup-boxes">
            <h2>25+</h2>
            <h2>Projects to Work On</h2>
          </div>
          <div className="startup-boxes">
            <h2>50+</h2>
            <h2>Teams Working On</h2>
          </div>
          </div>
        </div>
      </div>
      <div style={{marginTop:'50px'}}>
      <Box display="flex" justifyContent="center">
            <Typography variant="h4" fontWeight="800" style={{color:'#000'}}>
              Watchnow
            </Typography>
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            marginTop="10px"
            textAlign="center"
          >
            <Typography variant="h6" style={{color:'#000'}}>
              Explore and learn from diverse, relevant videos to fuel your growth.
            </Typography>
          </Box>
          <Box
            style={{marginTop:'30px'}}
          >
            <WatchnowCarousel blogs = {blogs}/>
          </Box>
          
      </div>
      <div>
      <Footer />

      </div>
    </div>
  );
};
const u = makeStyles({
  box: {
    width: "30%",
    marginTop:'15px',
    marginBottom: '0.5%',
    marginLeft:'35%',
    marginRight:'50%',
    display: "flex",
    "@media only screen and (max-width: 1200px)": {
      width: "90vw",
      marginTop: "15px",
      margin: "2%"
    },
    "@media only screen and (max-width: 726px)": {
      flexDirection: "column",
      margin: "2%"
    },
  },
  textBox: {
    display: "block",
    width: "60%",
    fontFamily:
      "source-serif-pro, Georgia, Cambria, Times New Roman, Times, serif",
    fontSize: "20px",
    color: "#EDEDF1",
    // border: "1px solid red",
    "@media only screen and (max-width: 726px)": {
      width: "100%",
      marginBottom: "1rem",
    },
  },
  imgBox: {
    objectFit: "cover",
    width: "40%",
    height: "25vh",
    marginTop: "auto",
    marginRight: "1rem",
    marginBottom: "auto",
    "@media only screen and (max-width: 726px)": {
      width: "100%",
      marginBottom: "1rem",
    },
  },
  img: {
    height: "100%",
    width: "100%",
    borderRadius: "10px",
  },
  body: {
    marginTop:'120px',
    color: "white",
    paddingTop: "20px",
    paddingBottom: "50px",
    width: "100%",
    minHeight: "100vh",
    // border: "0px solid blue",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    // border: "1px solid red",
    "@media only screen and (max-width: 726px)": {
      marginRight: "10px",
      marginLeft: "10px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  heading: {
    textAlign: "center",
    fontWeight: "bold",
    fontSize: "25px",
    backgroundColor: "#000",
    alignItems: "center",
    wordWrap: "break-word",
    borderRadius: "12px",
    whiteSpace: "initial",
    margin:'2%',
    "@media only screen and (max-width: 726px)": {
      width: "100%",
      padding: ".5rem",
      fontSize: "16px",
      borderRadius: "8px",
    },
  },
  subhead: {
    wordWrap: "break-word",
    paddingTop: "10px",
  },
});
export default NewVirtualCampusStartup;