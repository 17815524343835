import React from 'react';

const EcoBuild = () => {
  return (
    <>
      <p>
        <strong className="center">
          Title: EcoBuild: Driving Sustainable and Efficient Construction Practices in the Indian
          Market
        </strong>
      </p>
      <p>
        <strong>Introduction:</strong>
      </p>
      <p>
        EcoBuild is an Indian startup dedicated to promoting sustainable and efficient construction
        practices. The company aims to revolutionize the construction industry by offering
        innovative solutions that reduce environmental impact, enhance energy efficiency, and
        prioritize sustainable materials and techniques. This case study focuses on EcoBuild's
        business problems in the Indian market and their efforts to overcome these challenges.
      </p>
      <p>
        <strong>Business Problems:</strong>
      </p>
      <p>
        EcoBuild faced several key business problems while operating in the Indian market. These
        challenges were influenced by the unique characteristics of the construction industry in
        India, including traditional practices, limited awareness of sustainable construction, and
        cost considerations.
      </p>
      <ol>
        <li>Traditional Construction Practices:</li>
      </ol>
      <p>
        One of the primary challenges for EcoBuild was the prevalence of traditional construction
        practices in the Indian market. The industry often relied on conventional methods that
        involved high energy consumption, excessive waste generation, and limited use of sustainable
        materials and techniques.
      </p>
      <p>
        Assumed Figure: Traditional construction practices accounted for approximately 80% of the
        overall construction activities in India.
      </p>
      <ol start="2">
        <li>Limited Awareness of Sustainable Construction:</li>
      </ol>
      <p>
        EcoBuild encountered a challenge in raising awareness and educating construction
        professionals about sustainable construction practices. Many industry stakeholders,
        including architects, contractors, and developers, had limited knowledge of green building
        certifications, energy-efficient designs, and sustainable construction materials.
      </p>
      <p>
        Assumed Figure: Only 30% of construction professionals in India had a basic understanding of
        sustainable construction practices and their benefits.
      </p>
      <ol start="3">
        <li>Cost Considerations:</li>
      </ol>
      <p>
        EcoBuild faced the challenge of cost considerations and perceptions that sustainable
        construction practices were more expensive. The emphasis on upfront costs often deterred
        developers and contractors from adopting sustainable construction solutions, despite
        long-term benefits such as energy savings and environmental impact reduction.
      </p>
      <p>
        Assumed Figure: Less than 10% of construction projects in India allocated a significant
        budget for sustainable and efficient construction practices.
      </p>
      <p>
        <strong>Solutions and Outcomes:</strong>
      </p>
      <p>
        EcoBuild implemented several strategies to address these business problems and achieve
        success in the Indian market.
      </p>
      <ol>
        <li>Sustainable Construction Education and Awareness Programs:</li>
      </ol>
      <p>
        EcoBuild conducted extensive education and awareness programs to promote sustainable
        construction practices. They organized workshops, seminars, and industry conferences to
        disseminate knowledge about green building certifications, energy-efficient designs, and
        sustainable construction materials. These programs targeted architects, engineers, and
        construction professionals, aiming to increase awareness and change perceptions about
        sustainable construction.
      </p>
      <ol start="2">
        <li>Cost-Benefit Analysis and Financial Incentives:</li>
      </ol>
      <p>
        EcoBuild developed comprehensive cost-benefit analyses to demonstrate the long-term economic
        advantages of sustainable construction. They highlighted energy cost savings, reduced
        maintenance expenses, and potential government incentives for green building certifications.
        By showcasing the financial viability and return on investment, EcoBuild aimed to overcome
        the perception that sustainable construction practices were more expensive.
      </p>
      <ol start="3">
        <li>Collaboration with Developers and Government Bodies:</li>
      </ol>
      <p>
        EcoBuild actively collaborated with developers, government bodies, and industry associations
        to incorporate sustainable construction practices into building codes and regulations. They
        engaged in dialogues with these stakeholders to advocate for the adoption of green building
        standards, tax incentives for sustainable projects, and the integration of sustainable
        practices into infrastructure development projects.
      </p>
      <p>
        <strong>Outcomes:</strong>
      </p>
      <p>
        - EcoBuild's education and awareness programs resulted in a 50% increase in the adoption of
        sustainable construction practices among architects, engineers, and construction
        professionals.
      </p>
      <p>
        - The cost-benefit analyses conducted by EcoBuild demonstrated up to 20% cost savings over
        the lifecycle of sustainable construction projects, influencing developers to consider
        sustainable practices in their construction projects.
      </p>
      <p>
        - The collaboration with developers and government bodies led to the inclusion of
        sustainable construction provisions in building codes, incentivizing the adoption of
        sustainable and efficient construction practices in the Indian market.
      </p>
      <p>
        <strong>Case Solving </strong>
      </p>
      <ol>
        <li>
          How can EcoBuild leverage emerging technologies such as 3D printing, prefabrication, or
          Building Information Modeling (BIM) to enhance efficiency, reduce waste, and promote
          sustainability in the construction process?
        </li>
      </ol>
      <p>Solution:</p>
      <p>EcoBuild can leverage emerging technologies in the following ways:</p>
      <ol>
        <li>
          3D Printing: EcoBuild can explore the use of 3D printing technology to construct building
          components with minimal material waste and precise measurements. By printing customized
          and prefabricated elements on-site or off-site, construction processes can be streamlined,
          reducing material waste and labor requirements.
        </li>
      </ol>
      <ol>
        <li>
          Prefabrication: EcoBuild can promote the use of prefabricated building components
          manufactured in controlled factory settings. Prefabrication allows for efficient resource
          utilization, reduction of construction waste, and accelerated construction timelines. By
          collaborating with prefabrication companies, EcoBuild can encourage the adoption of
          prefabrication techniques in the Indian construction industry.
        </li>
      </ol>
      <ol>
        <li>
          Building Information Modeling (BIM): EcoBuild can advocate for the widespread adoption of
          BIM technology in construction projects. BIM enables detailed digital modeling of
          buildings, allowing for better visualization, clash detection, and optimization of
          designs. By integrating sustainability factors into BIM software, EcoBuild can help
          architects and engineers make informed decisions that reduce energy consumption, optimize
          material use, and enhance overall construction efficiency.
        </li>
      </ol>
      <ol start="2">
        <li>
          What strategies can EcoBuild employ to engage and educate small and medium-sized
          enterprises (SMEs) and local contractors in adopting sustainable construction practices,
          considering their limited resources and different construction practices?
        </li>
      </ol>
      <p>Solution:</p>
      <p>
        To engage and educate SMEs and local contractors, EcoBuild can employ the following
        strategies:
      </p>
      <ol>
        <li>
          Capacity Building Workshops: EcoBuild can organize capacity building workshops
          specifically tailored to the needs of SMEs and local contractors. These workshops can
          cover topics such as sustainable construction techniques, resource optimization, waste
          management, and cost-effective strategies for implementing sustainable practices.
          Practical demonstrations and case studies showcasing successful sustainable construction
          projects can inspire and motivate participants.
        </li>
      </ol>
      <ol>
        <li>
          Collaboration with Industry Associations: EcoBuild can collaborate with local industry
          associations that represent SMEs and local contractors. By engaging with these
          associations, EcoBuild can provide educational resources, training programs, and access to
          experts in sustainable construction. This collaboration can also facilitate
          knowledge-sharing platforms and forums where SMEs and contractors can exchange experiences
          and best practices.
        </li>
      </ol>
      <ol>
        <li>
          Pilot Projects and Demonstrations: EcoBuild can partner with SMEs and local contractors to
          initiate pilot projects that demonstrate the benefits and feasibility of sustainable
          construction practices. By providing financial and technical support for these projects,
          EcoBuild can showcase the positive outcomes, such as energy savings, reduced construction
          waste, and improved building performance. These pilot projects can serve as successful
          examples and encourage broader adoption of sustainable practices.
        </li>
      </ol>
      <ol start="3">
        <li>
          How can EcoBuild collaborate with material suppliers and manufacturers to encourage the
          development and availability of sustainable construction materials at affordable prices,
          facilitating their widespread adoption in the Indian market?
        </li>
      </ol>
      <p>Solution:</p>
      <p>
        EcoBuild can collaborate with material suppliers and manufacturers in the following ways:
      </p>
      <ol>
        <li>
          Research and Development Partnerships: EcoBuild can establish research and development
          partnerships with material suppliers and manufacturers to promote the development of
          sustainable construction materials. By collaborating on innovative materials that are
          environmentally friendly, energy-efficient, and cost-effective, EcoBuild can help drive
          the availability and affordability of sustainable alternatives.
        </li>
      </ol>
      <ol>
        <li>
          Certification and Standards Advocacy: EcoBuild can advocate for certifications and
          standards that encourage the production and use of sustainable construction materials. By
          working with material suppliers and manufacturers, EcoBuild can promote compliance with
          recognized certifications and standards that assure the sustainability and quality of
          materials. This collaboration can also incentivize material suppliers to invest in
          research and development for sustainable alternatives.
        </li>
      </ol>
      <ol>
        <li>
          Supplier Engagement and Education: EcoBuild can engage directly with material suppliers
          and manufacturers, conducting educational workshops and seminars on sustainable
          construction materials and techniques. By providing information on the benefits,
          applications, and market potential of sustainable materials, EcoBuild can encourage
          suppliers to invest in the production and distribution of such materials. Creating demand
          through awareness and education can drive down prices and improve availability.
        </li>
      </ol>
    </>
  );
};

export default EcoBuild;
