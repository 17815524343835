import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import List from "@mui/material/List";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { CART_LINK_UPDATE } from "../../api/payment";
import { GET_COURSENAME } from "../../api/vsc";
import { Typography } from "@mui/material";
import { PAYMENT_CHECKOUT } from "../../api/client";

const DirectCheckoutLink = ({ isAuth }) => {
  const params = useParams();
  const arr = params.arr.slice(1, -1);
  const array = arr.split(",");
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const [courseData, setCourseData] = React.useState([]);
  const [totalPrice, setTotalPrice] = React.useState(0);
  const [courseIds, setCourseIds] = React.useState([]);
  const isTraining = window.location.href.includes("training");

  const handleLogin = () => {
    navigate("/login", { state: { redirect: window.location.href } });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleRedirect = () => {
    axios
      .post(CART_LINK_UPDATE + window.localStorage.id, { cartItem: courseIds , category: isTraining ? 'training' : 'course' })
      .then((res) => {
        window.location.href = !isTraining ? PAYMENT_CHECKOUT + courseIds[0] + "/" + window.localStorage.id : "httpsd://virtualstartupcampus.com/program/paymentcheckout/" + window.localStorage.id + (!isTraining ? "/course" : "/training");

      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleClose = () => {
    navigate("/");
    setOpen(false);
  };

  React.useEffect(() => {
    const fetchCourseData = async () => {
      try {
        const data = await Promise.all(
          array.map(async (courseId) => {
            const response = await axios.get(GET_COURSENAME + `${courseId}`);
            return response.data.data[0];
          })
        );
        setCourseData(data);
        const total = data.reduce((acc, course) => acc + (isTraining ? course.trainingPrice : course.price), 0);
        setTotalPrice(total);
        setOpen(true);
        const extractedCourseIds = data.map((course) => course._id);
        setCourseIds(extractedCourseIds);
      } catch (error) {
        console.error(error);
      }
    };
    fetchCourseData();
  }, [array]);

  return (
    <div style={{ marginTop: "120px" }}>
      {isAuth === false && (
        <Dialog
          open={open}
          onClose={handleClickOpen}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Login To Proceed To Checkout
          </DialogTitle>
          <DialogActions>
            <Button onClick={handleClose}>Disagree</Button>
            <Button onClick={handleLogin} autoFocus>
              Login
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {isAuth && (
        <Dialog
          open={open}
          onClose={handleClickOpen}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Proceed To Review Checkout
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Courses:
            </DialogContentText>
            <List>
              {courseData.map(({ courseName, price, trainingPrice }) => (
                <li key={courseName}>
                  Course: {courseName} - Price: {isTraining ? trainingPrice : price}
                </li>
              ))}
            </List>
            <Typography>Total Price: {totalPrice}</Typography>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" sx={{ border: '2px solid #6a58c6', color: '#6a58c6' }} onClick={handleClose}>Disagree</Button>
            <Button variant="contained" sx={{ background: '#6a58c6' }} onClick={handleRedirect} autoFocus>
              Proceed To Checkout
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
};

export default DirectCheckoutLink;
