import React from 'react';

import {Box , Typography , useTheme , useMediaQuery , Button} from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DownloadForOthers from '../../CourseLandingPage/DownloadForOthers';
import EnrollUserDialog from  '../../CourseLandingPage/EnrollUserDialog';

const HeroComponent = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Box
    sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: {xs:'column' , sm: 'column' , md: 'row'}
    }}
>
    <Box
        sx={{
            width: {md: '80%' , sm: '100%' , xs: '100%'},
            padding: {md: '30px' , xs: '10px 30px 30px 30px'},
            gap: '20px',
            display: 'flex' , 
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: {xs: 'flex-start' , md: 'flex-start'}
        }}
    >
    <Typography variant={isMobile ? 'h3' :'h1'} padding="10px" fontWeight={900} textAlign={isMobile? 'left': 'left'}  fontSize={'48px'} lineHeight={1.1}>Management Trainee <br/><span style={{color: "#6a58c6" , fontWeight: 600}}>Corporate Exposure</span> <br/>Program in 1 Month</Typography>
    <Typography 
                sx={{
                background:'#FFBD59',
                width: '350px',
                height: '32px',
                fontFamily:'Poppins',
                fontSize:'20px',
                color: '#000',
                textAlign: 'center',
                marginBottom:'20px',
                marginLeft: 1
                }}>{'Learn -> Apply ->  Job Ready'}</Typography>
    <Typography variant='subtitle1' sx={{fontWeight: 600 , textAlign: {xs: 'left' , md: 'left'} , width: {xs: '100%' , md: '80%'} , marginLeft: 1}}>Exploratory Program to help you understand Product, Management and Finance field and help you decide your interest with work experience in multiple domains for MBA in Future</Typography>
    
    
    <Box
        sx={{
            padding: 2,
            width: '100%'
        }}
    >
    <EnrollUserDialog isMobile={isMobile} courseName={'Corporate Exposure Program'}/>
    <DownloadForOthers />

    </Box>
    </Box>
    <Box
        sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: {xs: 'flex-end' , sm: 'flex-end' , md:'center'},
            alignItems: 'flex-end',
            width: {md: '30%' , sm: '100%' , xs: '100%'},
            padding: '20px 0 0 20px'
        }}
    >
   <Accordion
    sx={{
        background: '#ff5757',
        width: '100%'
    }}
    defaultExpanded={true}
>
    <AccordionSummary
        expandIcon={<ExpandMoreIcon sx={{background: '#000' , color: '#fff' , borderRadius: '50%'}}/>}
        aria-controls="panel1-content"
        id="panel1-header"
        sx={{
            flexDirection: 'row-reverse',
            gap: '20px',
            fontWeight: 600,
            textAlign: 'center'                }}
    >
        Financial <br/> Manager
    </AccordionSummary>
     <AccordionDetails sx={{
                fontWeight: 600
            }}>
            Staring Salary in India: 
            <p style={{width: 140 , height: 40 , background: '#fff' , fontSize: '20px' , color: 'red'  , textAlign: 'center' , display: 'flex' , alignItems: 'center' , justifyContent: 'center'}}>₹6L - ₹19.9L</p>
            </AccordionDetails>
</Accordion>
        <Accordion
            sx={{
            background: '#ffbd59',
            width: '90%'
            }}
        >
                <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{background: '#000' , color: '#fff' , borderRadius: '50%'}}/>}
                aria-controls="panel1-content"
                id="panel1-header"
                sx={{
                    flexDirection: 'row-reverse',
                    gap: '20px'

                }}
                >
                Management Consultant
                </AccordionSummary>
                <AccordionDetails sx={{
                fontWeight: 600
            }}>
            Staring Salary in India: 
            <p style={{width: 140 , height: 40 , background: '#fff' , fontSize: '20px' , color: '#ffdf00'  , textAlign: 'center' , display: 'flex' , alignItems: 'center' , justifyContent: 'center'}}>₹6L - ₹20L</p>
            </AccordionDetails>
            </Accordion>
        <Accordion
            sx={{
            background: '#7affc9',
            width: '80%'
            }}
        >
            <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{background: '#000' , color: '#fff' , borderRadius: '50%'}}/>}
            aria-controls="panel1-content"
            id="panel1-header"
            sx={{
                 flexDirection: 'row-reverse',
                 gap: '20px'
            }}
            >
            Product Manager
            </AccordionSummary>
            <AccordionDetails sx={{
                fontWeight: 600
            }}>
            Staring Salary in India: 
            <p style={{width: 140 , height: 40 , background: '#fff' , fontSize: '20px' , color: 'green'  , textAlign: 'center' , display: 'flex' , alignItems: 'center' , justifyContent: 'center'}}>₹5L - ₹13.5L</p>
            </AccordionDetails>
        </Accordion>
    </Box>
</Box>
  )
}

export default HeroComponent