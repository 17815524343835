import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import logo from '../../Images/Applied.png';
import noCode from '../../Images/NoCode.png';
import { useMediaQuery } from '@mui/material';
export default function CouponCard({ couponCode , message ,selectedCode}) {
  const theme = useTheme();
  const isMobile = useMediaQuery('(max-width:600px)');
  return (
    <Card sx={{ 
      display: 'flex',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      borderRadius: '8px',
      width: isMobile?'100%':'60%',
      height:'70%', 
      marginBottom:'50px'
      }}>
    <CardMedia
        component="img"
        sx={{ width: '10%' }}
        image={message === 'No Code'?noCode:logo}
        alt="Applied"
      />
      {message==='Applied'?(<Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <CardContent sx={{ flex: '1 0 auto' }}>
         <Typography variant="h6" style={{ color: '#000' , textAlign:'left', lineHeight:2}}>
         {couponCode}
         </Typography>
         <Typography variant="caption" style={{ color: 'red'  , textAlign:'left', lineHeight:1}}>
          Discount: {selectedCode? `${selectedCode.details.discountpercentage*100}%`:'0'}
         </Typography> 
         <br/>
         <Typography variant="caption" style={{ color: '#6a58c6' , textAlign:'left', lineHeight:2}}>
         Max Discount Value:{selectedCode?`₹${selectedCode.details.maximumdiscountvalue}`:''}
        </Typography>
         <Typography variant="body2" style={{ color: '#000' , textAlign:'left', lineHeight:1}}>
         {selectedCode?`${selectedCode.description}`:''}
        </Typography>
        </CardContent>
        
      </Box>):(
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <CardContent sx={{ flex: '1 0 auto' }}>
            <Typography variant='h6'>Try Another Code!</Typography>
        </CardContent>
        </Box>
      )}
      
      
    </Card>
  );
}
