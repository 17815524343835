import axios from "axios";
import React from "react";
import { Typography } from "@material-ui/core";
import MuiAlert from "@mui/material/Alert";
import { NavLink } from "react-router-dom";
// require("dotenv").config();
// const DOMAIN = process.env.REACT_APP_DOMAIN;
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const EmailTestimonial = ({ match }) => {
  const { VERIFY_TESTIMONIAL } = require("../../api/vsc");
  const [name, setName] = React.useState("");
  const [success, setSuccess] = React.useState(true);
  axios
    .post(VERIFY_TESTIMONIAL, { token: match.params.token })
    .then((response) => {
      setName(response.data.name);
      setSuccess(true);
    })
    .catch((error) => {
      setSuccess(false);
    });
  return (
    <>
      <div
        style={{
          width: "50%",
          height: "50%",
          maxWeight: "500px",
          overflow: "auto",
          margin: "auto",
          position: "absolute",
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
        }}
      >
        {success ? (
          <Alert severity="success">
            <Typography>Successfully Verified</Typography>
            Testimonial application of {name} is now verified ... you can check{" "}
            {` `}
            <strong>
              <NavLink
                to="/"
                style={{ textDecoration: "none" }}
                exact
                className="nav-link"
              >
                here
              </NavLink>
            </strong>
          </Alert>
        ) : (
          <Alert severity="error">
            <Typography>Opps! Error Occured</Typography>
            This means <strong>link expired</strong>
          </Alert>
        )}
      </div>
    </>
  );
};

export default EmailTestimonial;
