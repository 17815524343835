import * as React from 'react';
import { Link } from 'react-router-dom'
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import { useMediaQuery } from '@mui/material';

export default function RecentCard({id, src , title , channel , views , createdAt , from }) {
  const theme = useTheme();
  const isMobile = useMediaQuery('(max-width: 600px)');

  return (
    <Card sx={{ display: 'flex', padding:isMobile ?'10px':'2%' , width:'100%' , flexDirection:isMobile&&'column' }}>
        <CardMedia
        component="img"
        sx={{ width: isMobile? '100%': 500  , height:isMobile?'100%': 300}}
        image={from? src :`https://www.markoknow.com/${src}`}
        alt={title}
        />
      <Box sx={{ display: 'flex', flexDirection: 'column' , width:'100%'}}>
        <CardContent sx={{ flex: '1 0 auto' }}>
          <Typography component="div" variant="h5">
            {title}
          </Typography>
          {!isMobile&&<Typography variant="subtitle1" color="text.secondary" component="div">
            {from ? channel?.replace(/<[^>]*>/g, '').substring(0,250) :channel}
          </Typography>}
        </CardContent>
        
        <CardActions style={{display:'flex' , alignItems:'flex-end' , justifyContent:'flex-end' , width:'100%'}}>
        <Link
                        to={from ? '../casestudy/'+id :id}
                        style={{ textDecoration: "none"  }}
                      >
                        <Typography
                          style={{
                            color: "white",
                            fontSize: "15px",
                            padding: "5px",
                            borderRadius: "5px",
                            cursor: "pointer",
                            backdropFilter: "blur(10px)",
                            backgroundColor: "#6A58c6",
                          }}
                        >
                          Read More
                        </Typography>
                      </Link>
        </CardActions>
      </Box>
      
    </Card>
  );
}
