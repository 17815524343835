import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import TextField from '@mui/material/TextField';
import { validateEmail } from "../../auth/validateEmail";
import { APPLY_COURSE } from "../../../api/course";
import axios from 'axios';


const CustomTextField = (props) => (
    <TextField
        {...props}
        InputProps={{
            ...props.InputProps,
            sx: {
                '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                        borderColor: 'transparent',
                    },
                    '&:hover fieldset': {
                        borderColor: 'transparent',
                    },
                    '&.Mui-focused fieldset': {
                        borderColor: 'transparent',
                    },
                    color: 'black',
                    background: '#fff',
                    borderRadius: '20px',
                },
                '& input': {
                    color: 'black',
                },
            },
        }}
        InputLabelProps={{
            ...props.InputLabelProps,
            sx: {
                color: 'black',
                '&.Mui-focused': {
                    color: 'black',
                },
            },
        }}
        sx={{
            width: '70%',
            borderRadius: '20px',
            ...props.sx,
        }}
    />
);

const KeyAspects = () => {
    const [fName, setFName] = React.useState("");
    const [contactNo, setContactNo] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [collegeName, setCollegeName] = React.useState("");
    const [open, setOpen] = React.useState(false);
    const [emailError, setEmailError] = React.useState("");
    const [contactNoError, setContactNoError] = React.useState("");
    const [submitted, setSubmitted] = React.useState(false);

    const MakeAllFalse = () => {
        setFName("");
        setContactNo("");
        setEmail("");
        setCollegeName("");
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const data = {
            fName: fName,
            wNumber: contactNo,
            email: email,
            description: collegeName,
        };

        axios
            .post(APPLY_COURSE + window.localStorage.id, data)
            .then((res) => {
                MakeAllFalse();
                setSubmitted(true);
                setOpen(true);
            })
            .catch((err) => {
                if (err.response && err.response.status === 400 && err.response.data.message === "Already applied for this course") {
                    setOpen(true);
                    setTimeout(() => {
                        setOpen(false);
                    }, 6000);
                }
            });
    };

    React.useEffect(() => {
        if (!email) {
            setEmailError("");
        } else {
            if (validateEmail(email)) {
                setEmailError("");
            } else {
                setEmailError("Please enter a valid email.");
            }
        }
    }, [email]);

    React.useEffect(() => {
        if (!contactNo) {
            setContactNoError("");
        } else {
            if (contactNo.length === 10) {
                setContactNoError("");
            } else {
                setContactNoError("Please Enter valid contactNo.");
            }
        }
    }, [contactNo]);

    return (
        <Box>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column'
                }}
            >
                <Typography variant="h4" align="center" sx={{ mb: 2, width: { xs: '100%', md: '30%' } }}>
                    Key aspects to look for and <span style={{ color: "#6a58c6", fontWeight: 600 }}>Enroll for Demo</span>
                </Typography>
            </Box>
            <Box
                sx={{
                    padding: '20px',
                    display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', md: 'row' },
                        gap: '10px',
                        alignItems: 'center',
                        justifyContent: 'space-evenly',
                        width: {xs: '90%' , md: '80%'}
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '10px',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: { md: '100%', xs: '100%' }
                        }}
                    >
                        <Box
                            sx={{
                                width: '100%',
                                height: 300,
                                borderRadius: '10px',
                                background: '#000',
                                display: 'flex',
                                flexDirection: 'column', justifyContent: 'center', alignItems: 'center'
                            }}
                        >
                            <Typography variant="h1" sx={{ color: '#ffbd59', textAlign: 'center' }}>3500+</Typography>
                            <Typography variant="h5" sx={{ color: '#c7bdfa', textAlign: 'center' }}>Students Enrolled</Typography>

                        </Box>
                        <Box
                            sx={{
                                width: '100%',
                                height: 300,
                                borderRadius: '10px',
                                background: '#6a58c6',
                                display: 'flex',
                                flexDirection: 'column', justifyContent: 'center', alignItems: 'center'
                            }}
                        >
                            <Typography variant="h1" sx={{ color: '#ffbd59', textAlign: 'center' }}>10+</Typography>
                            <Typography variant="h5" sx={{ color: '#c7bdfa', textAlign: 'center' }}>Live Experience with Industry Head</Typography>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '10px',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: { md: '100%', xs: '100%' }
                        }}
                    >
                        <Box
                            sx={{
                                width: '100%',
                                height: 300,
                                borderRadius: '10px',
                                background: '#000',
                                display: 'flex',
                                flexDirection: 'column', justifyContent: 'center', alignItems: 'center'
                            }}
                        >
                            <Typography variant="h1" sx={{ color: '#ffbd59', textAlign: 'center' }}>50K+</Typography>
                            <Typography variant="h5" sx={{ color: '#c7bdfa', textAlign: 'center' }}>Community Members</Typography>
                        </Box>
                        <Box
                            sx={{
                                width: '100%',
                                height: 300,
                                borderRadius: '10px',
                                background: '#6a58c6',
                                display: 'flex',
                                flexDirection: 'column', justifyContent: 'center', alignItems: 'center'
                            }}
                        >
                            <Typography variant="h1" sx={{ color: '#ffbd59', textAlign: 'center' }}>3</Typography>
                            <Typography variant="h5" sx={{ color: '#c7bdfa', textAlign: 'center' }}>Verifiable Certificates</Typography>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '20px',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: { md: '100%', xs: '100%' },
                            background: '#ffbd59',
                            padding: '20px',
                            borderRadius: '20px',
                            height: '100%'
                        }}
                    >
                        <Typography variant="h5" sx={{ mb: 7 }}>Enroll to get Connected</Typography>
                        <Box
                            sx={{
                                width: '80%',
                                background: '#6a58c6',
                                padding: '20px',
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '20px',
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderRadius: '10px'
                            }}
                        >
                            {submitted ? (
                                <Typography variant="h6" sx={{ color: 'white', textAlign: 'center' }}>
                                    You have successfully applied for the course.
                                </Typography>
                            ) : (
                                <Box
                                    sx={{
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '20px',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    // borderRadius: '10px'
                                }}
                                
                                 component="form" onSubmit={handleSubmit}>
                                    <CustomTextField
                                        id="outlined-basic"
                                        label="First Name"
                                        variant="outlined"
                                        onChange={(e) => setFName(e.target.value)}
                                        sx={{
                                            background: '#fff',
                                            width: '70%',
                                            borderRadius: '10px'
                                        }}
                                    />
                                    <CustomTextField
                                        id="outlined-basic"
                                        label="Contact Number"
                                        variant="outlined"
                                        onChange={(e) => setContactNo(e.target.value)}
                                        sx={{
                                            background: '#fff',
                                            width: '70%',
                                            borderRadius: '10px'

                                        }}
                                        FormHelperTextProps={{ sx: { color: 'red' } }}
                                        helperText={contactNoError}
                                    />
                                    <CustomTextField
                                        id="outlined-basic"
                                        label="Email"
                                        variant="outlined"
                                        onChange={(e) => setEmail(e.target.value)}
                                        sx={{
                                            background: '#fff',
                                            width: '70%',
                                            borderRadius: '10px'

                                        }}
                                        FormHelperTextProps={{ sx: { color: 'red' } }}
                                        helperText={emailError}
                                    />
                                    <CustomTextField
                                        id="outlined-basic"
                                        label="College Name / Organization / Not Applicable"
                                        variant="outlined"
                                        onChange={(e) => setCollegeName(e.target.value)}
                                        sx={{
                                            background: '#fff',
                                            width: '70%',
                                            borderRadius: '10px',
                                            border: 0
                                        }}
                                    />

                                    <Button
                                        variant="contained"
                                        sx={{
                                            background: '#000',
                                            marginTop: '30px'
                                        }}
                                        type="submit"
                                    >
                                        Submit
                                    </Button>
                                </Box>
                            )}
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

export default KeyAspects;
