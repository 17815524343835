import * as React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import RecentCard from './RecentCard';
import { GET_BLOGS , IMAGE , GET_BLOG} from "../../api/blog";
import { useMediaQuery } from '@mui/material';
import axios from 'axios';
import png from '../CaseStudy.jsx/Photos/1.png'
import { Link } from 'react-router-dom';
import Media from './Media';
import Loader from '../Career/Loader';




export default function NewBlog() {
  const [blogs, setBlogs] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  
  React.useEffect(() => {
    setLoading(true);
    axios
      .get(GET_BLOGS, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem('token')}`,
        },
      })
      .then((res) => {
        setBlogs(res.data);
        setLoading(false);
      })
      .catch((err) => {
      });
  }, []);
  const getRandomIndex = () => {
    const randomIndex = Math.floor(Math.random() * blogs.length);
    return randomIndex;
  };
  let index = getRandomIndex();
  return (
    <Box sx={{ marginTop:'120px'}}>
    {!loading ? (
      <>
      <Box display="flex" justifyContent="center">
            <Typography variant="h4" fontWeight="800" style={{color:'#000'}}>
              InSights
            </Typography>
          </Box>
          <Box  margin="50px" marginLeft="10px" width="90%">
          <Typography variant="h5" style={{color:'#000' , textAlign:'left', fontFamily:'Poppins' , marginBottom:'10px' , fontWeight:'900'}}>
              Top Pick 
            </Typography>
                <RecentCard 
                id={blogs[index]?._id}
                src={blogs[index]?.imagePath}
                title={blogs[index]?.title}
                channel={blogs[index]?.subTitle}
                views={'10k'}
                createdAt='1 day ago'
                />
            </Box>  
       <Box  margin="50px">

       <Media props={`Recently Added`}/>

       </Box>   
       <Box  margin="50px">
       <Media props={'Most Viewed'}/>

       </Box>  
       <Box  margin="50px">
       <Media props={`Product`}/>

       </Box>  
       <Box  margin="50px">
       <Media props={`Startup`}/>

       </Box>
       <Box  margin="50px">
       <Media props={`Marketing`}/>

       </Box>
       <Box  margin="50px">
       <Media props={`Finance`}/>

       </Box>
       <Box  margin="50px">
       <Media props={`AI & Trending`}/>

       </Box>
       <Box  margin="50px">
       <Media props={`Jobs/Internships`}/>

       </Box>
       <Box  margin="50px">
       <Media props={`Colleges`}/>

       </Box>
       </>
    ): (
      <Loader from={'NewBlog'}/>
    )}
      
    </Box>
  );
}