import React from 'react';
import { useMediaQuery } from "@mui/material";
import axios from 'axios';
import { Typography, Button } from '@material-ui/core';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import { POST_WORKEX, USER_REFFERAL } from '../../../../../api/vsc';
import { useParams } from 'react-router-dom';
import { GET_CERTIFICATE_ALREADY_REQUESTED_WORKEX, GET_CERTIFICATE_REQUESTED_WORKEX } from '../../../../../api/certificate';

const WorkExViewer = ({ WorkExData , courseName }) => {
    const params = useParams();
    const [clicked, setClicked] = React.useState(false);
    const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const [approved, setApproved] = React.useState(false);
    const [showAlert, setShowAlert] = React.useState(false);
    const [submitted, setSubmitted] = React.useState(false);
    const [certificateLink, setCertificateLink] = React.useState('');

    const isMobile = useMediaQuery('(max-width:838px)');

    React.useEffect(() => {
        // axios.get(USER_REFFERAL + window.localStorage.id).then(res => {
        //     const workex = res.data.data.completedWorkexs.find((workex) => workex.workexId === WorkExData._id)
        //     if (workex && workex.isApproved !== "submit") setClicked(true);
        //     if (workex && workex.isApproved === "Approved") {
        //         setApproved(true);
        //     }
        //     if (workex && workex.isApproved === "submit") {
        //         setApproved(false);
        //         setSubmitted(true);
        //     }
        //     if (workex && workex.isApproved === "Rejected") {
        //         setClicked(false);
        //         setShowAlert(true);
        //     }
        // }).catch(err => console.log(err))
        // const cN = courseName.toLowerCase();
        let link = '';

        // if (cN.includes('product management')) {
        //     link = `/certificate/product/workex/`;
        // } else if (cN.includes('prompt')) {
        //     link = `/certificate/prompt/internship/`;
        // } else if (cN.includes('business model')) {
        //     link = `/certificate/`;
        // } else if (cN.includes('management consulting')) {
        //     link = `/certificate/managementconsulting/workex/`;
        // }

        setCertificateLink(link);
        axios
        .get(GET_CERTIFICATE_ALREADY_REQUESTED_WORKEX+ window.localStorage.id + '/' + params.id)
        .then(res => {
            setSubmitted(res.data.message);
        })
        .catch(err => {
            console.log(err);
        })
    }, []);

    const handleLinkOpen = () => {
        axios.post(GET_CERTIFICATE_REQUESTED_WORKEX + window.localStorage.id + '/' + params.id, { link: WorkExData.buttonLink  , heading: WorkExData.heading})
            .then(res => {
                window.open(WorkExData.buttonLink, '_blank');
                setClicked(true);
                setSubmitted(true);
            })
            .catch(err => {
                console.error(err);
                setOpenSnackbar(true);
            });
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };

    return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {submitted ? (
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { m: !isMobile ? 1 : 0.5 },
                        width: { xs: 300, s: 350, lg: 1000, md: 500 },
                        margin: '2%',
                        borderRadius: '10px',
                        background: '#6A58C6',
                        padding: '2% 6% 2% 6% ',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        gap: '20px',
                        boxShadow: '2px 2px 5px #6A58C6',
                        height: '100%'
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <Typography
                        variant="h4"
                        style={{
                            color: '#fff',
                            textAlign: 'center',
                            fontFamily: 'Poppins',
                            fontWeight: 'bold'
                        }}
                    >
                        {submitted ? 'Thank You' : 'Your Work Experience'}
                    </Typography>
                    <Typography
                        variant="subtitle1"
                        style={{
                            color: '#fff',
                            textAlign: 'center',
                            fontFamily: 'Poppins',
                            fontWeight: 'bold',
                            wordWrap: 'break-word'
                        }}
                    >
                        {submitted && 'Work Experience Certificate Request Is Submitted You Will Receive Your Certificate On Dashboard Within 48 hours'}
                    </Typography>
                </Box>
            ) : (
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { m: !isMobile ? 1 : 0.5 },
                        width: { xs: 300, s: 350, lg: 1000, md: 500 },
                        margin: '2%',
                        borderRadius: '10px',
                        background: '#6A58C6',
                        padding: '2% 6% 2% 6% ',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        gap: '20px',
                        boxShadow: '2px 2px 5px #6A58C6',
                        height: '100%'
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <Typography
                        variant="h4"
                        style={{
                            color: "white",
                            textAlign: 'center'
                        }}
                        gutterBottom
                    >
                        {WorkExData?.heading}
                    </Typography>
                    <div
                        style={{
                            color: "white",
                            textAlign: 'center'
                        }}
                        dangerouslySetInnerHTML={{ __html: WorkExData?.subHeading }}
                    />
                    <Button
                        variant='contained'
                        onClick={handleLinkOpen}
                        sx={{
                            background: '#FF5757'
                        }}
                    >
                        {WorkExData?.buttonName}
                    </Button>
                </Box>
            )}

            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                message="An error occurred while processing your request. Please try again."
            />
        </div>
    );
}

export default WorkExViewer;
