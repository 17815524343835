import React from 'react';
import { Box , Typography , Button } from '@mui/material';
import optum from '../Images/optum.jpg';
import mastercard from '../Images/Mastercard.png';
import aws from '../Images/aws.jpg';
import ibm from '../Images/ibm.jpg';
import udemy from '../Images/udemy.jpg'

const Timeline = () => {
    const images = [optum , mastercard , aws , ibm , udemy];
  return (
    <Box
        sx={{
            display: 'flex' , 
            flexDirection: 'column',
            justifyContent: 'center' , 
            alignItems: 'center'
        }}
    >
         <Typography variant="h4" align="center" sx={{ mb: 2, width: { xs: '100%', md: '30%' } }}>
                    <span style={{ color: "#6a58c6", fontWeight: 600 }}>Industry Experts </span> from Companies & Startups
        </Typography>
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center' , 
                alignItems: 'center',
                gap: '20px',
                flexWrap: 'wrap',
                width: '100%',
                marginTop: '50px'
            }}
        >
        {images.map(path => (
            <Box
            component="img"
            src={path}
            sx={{
                width: {xs: '40%' , md: 150},
                height: 100,
                padding: '1%',
                borderRadius: '10px',
                boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px'
                }}
            />
        ))}
        

        </Box>
    </Box>
  )
}

export default Timeline