import React from 'react';
import { makeStyles } from '@mui/styles';
import { Card, CardContent ,Typography, Button } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import {Link , useNavigate} from 'react-router-dom';



const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    marginTop:'80px'
  },
  tickIcon: {
    
  },
  message: {
    // marginBottom: theme.spacing(2),
    color:'#6A58C6'
  },
  button: {
    // color: theme.palette.common.white,
    // backgroundColor: theme.palette.primary.main,
    '&:hover': {
    //   backgroundColor: theme.palette.primary.dark,
    },
  },
}));

function PaymentSuccess() {
  const navigate = useNavigate();
  const classes = useStyles();
  const [countdown, setCountdown] = React.useState(5);
  React.useEffect(() => {
    const timer = setTimeout(() => {
      if (countdown > 1) {
        setCountdown(countdown - 1);
      } else {
        navigate('/');
      }
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [countdown, navigate]);
  return (
    <div className={classes.container}>
      <CheckCircleOutlineIcon className={classes.tickIcon} sx={{
      fontSize: '10rem',
      color: '#4CAF50',
      marginBottom: '10px'
      }}/>
      <Typography variant="h5" className={classes.message}>
        Payment Successful!
      </Typography>
       <Card style={{ textAlign:'center' , display:'flex' , flexDirection:'column' , justifyContent:'center' , bordetStyle:'dotted'}}> 
        <CardContent style={{ textAlign:'center' , display:'flex' , flexDirection:'column' , padding:'30px' ,justifyContent:'center', alignItems:'center' }}>
          <Typography variant='subtitle2' style={{marginBottom:'10px' , fontWeight:'Bold'}}>
               Happy Learning !
          </Typography>
          <Typography variant='caption' style={{ textAlign:'left' , fontWeight:'Bold'}}>
          A brief on how you can proceed to acquire the knowledge from your favourite course
          
          </Typography>
          <div style={{ textAlign:'center' , display:'flex' , flexDirection:'column' , padding:'16px', paddingLeft:'30px' , marginBottom:'10px'}}>
          <Typography variant='caption' style={{ textAlign:'left' ,fontSize:'10px'}}>
          1. Please practice along with your instructor, this is a major benefit of self paced courses.
          </Typography>
          <Typography variant='caption' style={{ textAlign:'left' ,fontSize:'10px'}}>
          2. In case of doubts, you can take help from your mentor by dropping the message in the chat box in the course page. 

          </Typography>
          <Typography variant='caption' style={{ textAlign:'left' ,fontSize:'10px' }}>
          3. Submit all your assignments and exercises to the mentor and we will provide you with feedback. 
          </Typography>
          <Typography variant='caption' style={{ textAlign:'left' ,fontSize:'10px'}}>
          4. At the end of the course you can ask your mentor for the certificate. 
          </Typography>
          <Typography variant='caption' style={{ textAlign:'left' ,fontSize:'10px' }}>
          5. You will also avail the Feesback once you have completed the course within 3 months of enrollment
          
          </Typography>

          </div>
          <Typography variant='overline' style={{ textAlign:'left' , fontWeight:'Bold' , marginBottom:'10px'}}>
           All The Best For Your Learning Experience
          
          </Typography>
          <Typography variant="caption" style={{color:'red'}} className={classes.timer}>
          Redirecting to Home in {countdown} seconds...
         </Typography>
          
          <Button variant="outlined" style={{color:'#6A58C6' , borderColor:'#6A58C6' ,width:'50%' }}>
          <Link to="/" style={{color:'#6A58C6'}}>Continue To Homepage</Link> 
          </Button>
        </CardContent>
       </Card>
      
      
    </div>
  );
}
export default PaymentSuccess;