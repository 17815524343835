import React, { useState } from 'react';
import './RotatingBadge.css'; // Import the CSS file for styling
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import Modal from '@mui/material/Modal';
import { LuMedal } from 'react-icons/lu';
import { MdMilitaryTech } from 'react-icons/md';
import { RiMedalFill } from 'react-icons/ri';
import png from './SignUpBadge.png';
import { LinkedinShareButton , LinkedinIcon , WhatsappShareButton , WhatsappIcon} from 'react-share';
 // Import react-confetti

const RotatingBadge = ({ sBadge }) => {
  const [showConfetti, setShowConfetti] = useState(false); 
  const handleOpenBadge = () => {
    setShowConfetti(true); 
  };

  return (
    <>
    <div className="rotating-badge-container">
      <div className="rotating-badge" style={{ width: 300, height: '100%', clipPath: 'polygon(0 0, 100% 0, 100% 66%, 50% 100%, 0 66%)', border: 'solid #000 5px' }}>
        {/* Add badge content or text here */}
        <img src={sBadge.badgeUrl} width="100%" height='180' onClick={handleOpenBadge} />
        <p align="center" style={{ fontWeight: 'bold' }}>{sBadge.title}</p>
        {/* <p align="center" style={{ fontWeight:'bold' }}>{sBadge.message}</p> */}
        <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'center' }}>
          <LinkedinShareButton url={png}>
            <LinkedinIcon round={true} size={'30px'} />
          </LinkedinShareButton>
          <WhatsappShareButton url={png}>
            <WhatsappIcon round={true} size={'30px'} />
          </WhatsappShareButton>
        </div>
      </div>
    </div>
    </>
  );
};

export default RotatingBadge;
