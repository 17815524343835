import React, { useState, useEffect } from "react";
import "../../../stylesheets/PromptAi.css";
import { Link , useNavigate } from "react-router-dom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Footer from "../../Footer/Footer";
import { Button, IconButton, useMediaQuery,Box ,Typography , Modal , CircularProgress } from "@mui/material";
import practical from '../Images/practical.jpg';
import workex from '../Images/workex.png';
import project from '../Images/project.jpg';
import chatgpt from '../Images/chatgpt.png';
import openai from '../Images/openai.jpg';
import {faqs} from '../FAQDATA';
import DownloadBrochureDialog from '../DownloadForOthers';
import { GET_COURSE } from "../../../api/course";
import { GET_MODULES, VIDEO , PDF} from "../../../api/vsc";
import axios from 'axios';
import ProgramCurriculum from "../ProgramCurriculum";
import InfoBox from "../InfoBox";
import Testimonial from "../../Testimonial";
import Accordion from "../Accordion/index";
const PromptAI = ({ isAuth, setAuth }) => {
  isAuth = true;
  const training_elements = [
    "Hands On Training",
    "Practicals in Labs",
    "Capstone Project Work",
    "Live QnA"
    ];
    const modules = [
        "Up to Date Modules",
        "15 hrs Total Dedication",
        "3 Certificates",
        "Industry Work Experience"
      ];
  const isMobile = useMediaQuery('(max-width:820px)');
  const [img , setImg] = React.useState(practical);
  const navigate = useNavigate();
  const [courseData, setCourseData] = useState(null);
  const [loading , setLoading] = useState(true);


  useEffect(() => {
    const fetchData = async () => {
      try {
        // Step 1: Fetch the course
        const courseResponse = await axios.get(`${GET_COURSE}Prompt Techniques`);
        const courseId = courseResponse.data.data._id;

        // Step 2: Fetch the modules for the course
        const modulesResponse = await axios.get(`${GET_MODULES}${courseId}`);
        const modules = modulesResponse.data.message;

        // Step 3: Fetch the videos and PDFs for each module
        const modulesWithContent = await Promise.all(modules.map(async (module) => {
          const [videosResponse, pdfsResponse] = await Promise.all([
            axios.get(`${VIDEO}${module._id}`),
            axios.get(`${PDF}${module._id}`)
          ]);

          return {
            ...module,
            videos: videosResponse.data.data,
            pdfs: pdfsResponse.data.data
          };
        }));

        // Step 4: Set the course data with modules and their content
        setCourseData({
          ...courseResponse.data,
          modules: modulesWithContent
        });
        setLoading(false);
        console.log({
          ...courseResponse.data,
          modules: modulesWithContent
        })
      } catch (error) {
        console.error("Error fetching course data:", error);
      }
    };

    fetchData();
  }, []);
  return (
    <>
      <div className="details_main_prompt">
        <div className="headingp">
           <h1>Learn Prompt Engineering
           <br/> in 1 Month</h1>
           <Typography 
                className="yellow-box"
                style={{
                background:'#FFBD59',
                width: '350px',
                height: '32px',
                fontFamily:'Poppins',
                fontSize:'20px',
                color: '#000',
                textAlign: 'center',
                marginBottom:'20px'
                }}>{'Learn -> Apply -> Job Ready'}</Typography>
           <p>Get training, practice in Labs, work on live projects and get work experience in just 1 Month </p>
            <div className="buttons-promtl" >
                <Button 
                    href="/virtual_campus"
                     variant="contained"
                    className="button-promptl-enroll"
                    style={{
                        background: '#6a58c6',
                        marginBottom: '20px'
                    }}
                 >Enroll Now</Button>
                {/* <Button
                    variant="outlined"
                    className="button-promptl-download"
                    style={{
                      color: '#6a58c6',
                      borderColor: '#6a58c6',
                      marginBottom: '20px'
                    }}
                    // onClick={()=> {isAuth && handleDownload();}}
                  >
                    Download Brochure
                  </Button> */}
                  <DownloadBrochureDialog courseName={'Prompt Engineering'}/>


            </div>
            {/* <div className="batch-box"> 
                <Typography style={{color:'#6a58c6' , fontSize:'20px' , marginLeft:'0' , width:'50%'}}>Batch Staring<br/> On </Typography>
                <Typography  style={{fontSize:'20px', marginLeft:'0' , display:'flex', width:'50%' , alignItems:'center'}}>{'4th Feb 2024'}</Typography>
            </div> */}

        </div>
        
        <div className="box_of_data">
          <div className="sidebar2"></div>
          <div className="sidebar">
            <div style={{ display: "flex justify-around" }}>
              <p className="sidebar_heading">
                Prompt Engineering <br />
              </p>
              <a href="#outcome-box">
                <p className="sidebar_data">Programme Snapshot</p>
              </a>
              <a href="#certificate-box">
                <p className="sidebar_data">Work Ex Certificates</p>{" "}
              </a>
              <a href="#vsc-students">
                <p className="sidebar_data">Vsc Students</p>
              </a>

              <a href="#vsc-pathway">
                <p className="sidebar_data">Pathway</p>
              </a>
              <a href="#vsc-tools">
                <p className="sidebar_data">Tools</p>
              </a>
              <a href="#vsc-timeline">
                <p className="sidebar_data">Timeline </p>
              </a>
              <a href="#vsc-faq">
                <p className="sidebar_data">FAQ </p>
              </a>
            </div>
          </div>
        </div>
        <div className="outcome-box" id="outcome-box">
            <h3>Programme Snapshot</h3>
            <div className="tick-boxes-prompt">
            <div className="tick-boxes-prompt-box1">
                {
                    training_elements.map((t,i) => (
                    <p
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        color: "#000",
                        fontFamily: "Open sans",
                        fontSize: "18px",
                        wordWrap: "break-word",
                        margin: '20px',
                        width:'100%'
                      }}
                    >
                      <CheckCircleIcon style={{color: 'green', margin:'1%' ,  fontSize:'35px'}}/>
                      {t}
                    </p>
                    ))
                }
            </div>
            <div className="tick-boxes-prompt-box2">
            {
                    modules.map((t,i) => (
                        <p style={{
                            display: 'flex',
                            alignItems: 'center',
                            color: '#000',
                            fontFamily: 'Open sans',
                            fontSize: '18px',
                            wordWrap: 'break-word',
                            margin: '20px',
                            width:'100%'
                            }}>
                            <CheckCircleIcon style={{ color: 'green', margin: '1%' , fontSize:'35px'}} />
                            {t}
                        </p>
                    ))
                }
            </div>
            </div>
            <div className="download-syllabus-button">
            {/* <Button variant="contained" style={{ backgroundColor:'#6a58c6'}}>Download Syllabus</Button> */}
            </div>
        </div>
        {courseData && <div style={{marginTop: '40px' , marginBottom: '40px' , width: '100%' , display: 'flex' , alignItems: 'center' , justifyContent: 'center'}}>
        <div style={{width: isMobile ? '100%' : '60%'}}>
        {loading ? <CircularProgress/>:<ProgramCurriculum data={courseData} />}
      </div>
        </div>}
        <div className="certificate-box" id="certificate-box">
                <h2>Work Ex Certificates</h2>
                <p>This program offers a unique <br/>opportunity to gain 3 Certificates </p>
                <div className="buttons-certificate-box">
                    <div className="button-certificate-box" onClick={()=> setImg(practical)}>Lab Training</div>
                    <div className="button-certificate-box" onClick={()=> setImg(project)}>Capstone Project</div>
                    <div className="button-certificate-box" onClick={()=> setImg(workex)}>Internship Certificate</div>
                </div>
                <div className="cert-image-container">
                <img src={img} alt="certificate" className="certificate-img"/>
                <span style={{textAlign:'left',fontSize:isMobile?'26px':'40px' , fontFamily: 'Parisienne' , position:'absolute' , top:isMobile?'34%':'40%' , left:'35%' }}>{window.localStorage.name}</span>
                </div>
                
        </div>
        <div className="list-div-container">
                <ul className="concentric-bullet-ul">
                    <li className="concentric-bullet">Verifiable Certificates<br/><span style={{fontSize:'14px',  fontWeight:'400'}}>Get Certificates that can easily be verified by your recruiters </span></li>
                    <li className="concentric-bullet">3 in 1 Certificates<br/><span style={{fontSize:'14px',  fontWeight:'400'}}>Get 3 Certificates in 1 Program that solves your Learn, Apply, Work Ex</span></li>
                    <li className="concentric-bullet">Virtual Startup Campus <br/> Alumni Status<br/><span style={{fontSize:'14px',  fontWeight:'400'}}>Get connected with Industry People and our alumni Network</span></li>
                </ul>
                <Button variant="contained" style={{ backgroundColor:'#6a58c6' , marginTop:'40px'}} >Download Syllabus</Button>
        </div>
        <div className="vsc-students" id="vsc-students">  
                <h1>
                <u style={{textDecorationColor: '#FFBD59' , textDecorationThickness: '5px'}}>VSC Students</u>
                </h1>
                <Testimonial from={"promptai"}/>
        </div>
        <div className="pathway-prompt" id="vsc-pathway">
                <h2>
                <u style={{textDecorationColor: '#FFBD59' , textDecorationThickness: '5px'}}>Pathway</u>
                </h2>
                <div style={{display:'flex' , flexDirection:'row'}}>
                <div class="vertical-dots">
                        <div class="dot"></div>
                        <div class="dot"></div>
                        <div class="dot"></div>
                        <div class="dot no-line"></div>
                        </div>
                <div className="pathway-container">
                    <div className="pathway-box" style={{ backgroundColor: '#6a58c6', borderRadius: '20px' , color:'#fff' }}>
                        <h2 style={{color:'#fff'}} >Week 1 : Lab Training Modules</h2>
                        <p>Lab Oriented Practical Learning with application of prompts to show the results and experiment with various prompts. Practice in Live Labs various learned concepts.</p>
                    </div>
                    <div className="pathway-box" style={{ backgroundColor: '#FF5757', borderRadius: '20px' , color: '#fff' }}>
                        <h2 style={{color:'#fff'}}>Week 2 : Capstone Project</h2>
                        <p>Apply Your Learning from Training and Labs in a Capstone Project to where you understand your own  foothold on the topics.</p>
                    </div>
                    <div className="pathway-box" style={{ backgroundColor: '#7AFFC9', borderRadius: '20px' , color: '#000' }}>
                        <h2>Week 3 : Remote Internship Part 1</h2>
                        <p>Get Practice with Mock Interview that is well structured for helping you apply for various jobs. Start Your Remote Internship for Industry Training.</p>
                    </div>
                    <div className="pathway-box" style={{ backgroundColor: '#FFBD59', borderRadius: '20px' , color: '#000' }}>
                        <h2>Week 4 : Remote Internship Part 2</h2>
                        <p>Work Experience of Remote Internship for students to broaden their overall concepts and start applying for Prompt Engineering Roles. </p>
                    </div>
                    
                    </div>
                    
                        </div>
        </div>
        <div className="tools-box-prompt" id="vsc-tools">
                <h2><u style={{textDecorationColor: '#FFBD59' , textDecorationThickness: '5px'}}>Tools You Will Learn</u></h2>
                <div className="image-container-prompt">
                    <img src={chatgpt}  width={100} height={80}/>
                    {/* <img src={dalle} width={100} height={80}/> */}
                    <img  src={openai} width={100} height={80}/>
                </div>
                
        </div>
        <div className="suitable-for-prompt">
                    <h2><u style={{textDecorationColor: '#FFBD59' , textDecorationThickness: '5px'}}>Suitable For</u></h2>
                    <p><span class="dot-span"></span>Students of All Streams Looking To Learn Prompt Engineering</p>
                    <p><span class="dot-span"></span>Students Exploring AI Career for Future</p>
                    <p><span class="dot-span"></span>Professionals Looking to Upgrade and Switch to AI Jobs</p>
        </div>
        <div className="borderbox-for-prompt" id="vsc-timeline">
              <InfoBox/>
        </div>
        <div className="faq-box-prompt" id="vsc-faq">
            <h2><u style={{textDecorationColor: '#FFBD59' , textDecorationThickness: '5px'}}>FAQs</u></h2>
                
                  {faqs.map((faq) => (
                    <Accordion question={faq.question} answer={faq.answer}/>
                  ))}  
        </div>
        
      </div>
      <div>
        <Footer />
      </div>
    </>
  );
};

export default PromptAI;
