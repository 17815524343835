import React from 'react';
import {Box , Typography , Avatar } from '@mui/material';
import Rating from '@mui/material/Rating';
import './index.css'
import Media from '../Media/indexWeb';
import axios from 'axios';
const Profile = ({row}) => {
    
  return (
    <Box>
        <Box 
          sx={{
            display:'flex',
            flexDirection: 'row' , 
            justifyContent:'center',
            alignItems:'center'
          }}
        >
        <Box
          sx={{
            display:'flex' , 
            justifyContent: 'center' , 
            alignItems: 'center',
            padding: '20px',
            width: '25%'
          }}
        >
          <Avatar sx={{
            width: 80 , 
            height:  80,
          }}
          src={row?.profileImg}
            />
        </Box>
          <Box
            sx={{
              display:'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '20px',
              width: '75%'
            }}
          >
          <Typography variant="h6" gutterBottom sx={{width: '60%'}}>{row.name}</Typography>
          
          </Box>
          
        </Box>
        <Box
          sx={{
            display:'flex',
            justifyContent:'center',
            alignItems: 'center',
          }}
        >
        <button  class="btn" type="button">
            <strong className="strong-1">{row.lastMonthRank + " Points"}</strong>
            <div id="container-stars">
              <div id="stars"></div>
            </div>

            <div id="glow">
              <div class="circle"></div>
              <div class="circle"></div>
            </div>
          </button>
        </Box>
        
        <Box
          sx={{
            padding: '20px'
          }}>
            <Typography variant='h5' fontWeight={'700'} gutterBottom>Achievements</Typography>
            <Media props="Earned Badges" id={row._id}/>
          </Box>
    </Box>
  )
}

export default Profile