import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ViewCertificate({ open, setOpen, certificate }) {
  const downloadCertificate = () => {
    const certificateElement = document.getElementById('certificate');
    if (!certificateElement) {
      console.error('Certificate element not found');
      return;
    }

    // Wait for the image to load fully
    const img = new Image();
    img.src = certificate.image;
    img.crossOrigin = 'Anonymous'; // Handle cross-origin images
    img.onload = () => {
      html2canvas(certificateElement, { useCORS: true }).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('l', 'mm', 'a4'); // 'l' for landscape orientation
        pdf.addImage(imgData, 'PNG', 0, 0, 297, 210); // A4 size in landscape: 297 x 210 mm
        pdf.save('certificate.pdf');
      }).catch(error => {
        console.error('Error generating PDF', error);
      });
    };

    img.onerror = () => {
      console.error('Error loading image');
    };
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative', background: '#6A58C6' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {certificate?.courseName}
            </Typography>
            <Button autoFocus color="inherit" onClick={downloadCertificate}>
              Download Certificate
            </Button>
          </Toolbar>
        </AppBar>

        <Box
          id="certificate"
          component={'img'}
          sx={{
            width: { xs: '100%', sm: '95%', md: '80%' },
            height: { xs: '45vh', sm: '60vh', md: '95vh' }
          }}
          src={certificate?.image}
        />
      </Dialog>
    </React.Fragment>
  );
}
