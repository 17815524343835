import React , {useRef , useEffect} from 'react';
import certificateImg from '../../../Certificate/Certificate.png';
import signature from './signature.png';
import { useLocation , useParams} from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { useMediaQuery } from '@mui/material';
import axios from 'axios';
import { GET_CERTIFICATE, POST_CERTIFICATE } from '../../../../api/certificate';
import { useState } from 'react';
import QRCode from 'qrcode.react';
import SignStrip from '../../../Certificate/SignStrip';
import Skills from '../../../Certificate/Skills';
import {Divider} from '@mui/material';

const CertificateView = () => {
  const params = useParams();
  const location = useLocation();
  const date = new Date();
  const now = date.toLocaleString();
  const myArray = now.split(',');
  const certificateRef = useRef(null); 
  const isMobile = useMediaQuery('(max-width:600px)');
  const uid = uuid();
  const [certificate , setCertificate] = useState({});


  useEffect(() => {
    axios
    .get(GET_CERTIFICATE+params.id)
    .then(res=> {
        setCertificate(res.data.data[0]);
    })
    .catch(err=> {
        console.log(err);
    })
  },[]);
  const cLink = 'https://www.campus.markoknow.com/viewcertificate/'+params.id;
  
  const certificateStyles = {
    backgroundImage: `url(${certificateImg})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: isMobile? '100% 90%' :'100% 100%',
    // backgroundSize:'100%',
    width: isMobile?'95vw':'90%',
    // width: '100%',
    // height: isMobile ? '100%' : '100%',
    height: '100%',
    fontSize: isMobile && '10px', 
    display: 'flex ',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#000',
    textAlign: 'center',
    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
    padding:'20px'
    // border:'2px #000 solid'
  };
  const signatureStyles = {
    width: isMobile?'30%':'30%',
    height: 'auto',
    marginTop: '20px',
    
  };

  const underlineStyles = {
    marginTop:0,
    borderBottom: '2px solid #000',
    width: isMobile?'30%':'15%', 
  };

  const ceoNameStyles = {
    marginTop: '10px',
    fontWeight:'bold'
  };

  const ceoDesignationStyles = {
    fontSize: isMobile?'10px':'18px',
  };
  const h1Style = {
    textAlign: 'left',
    fontSize: '52px',
    fontFamily: 'Parisienne',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
  };

  const dividerStyle = {
    bgcolor: '#000',
    height: '2px',
    width: '100%',
  };

  return (
    <div style={{ marginTop: '120px' , display:'flex' , flexDirection:!isMobile?'row':'column' , padding:!isMobile&&'3%' }}>
        <div ref={certificateRef} style={certificateStyles}>
        <div style={{marginLeft:isMobile?'25%':'30%'}}>
        <div style={{marginTop:isMobile?'10px':'30px', display:'flex'}}>
        <div style={{width:'90%'}}>
        <h1 style={{textAlign:'left',fontSize:isMobile?'26px':'52px' , fontFamily: 'Cinzel Decorative'}}>
          CERTIFICATE
        </h1>
        <p style={{textAlign:'left',fontSize:isMobile?'10px':'20px', fontFamily: 'Cinzel Decorative'}}>
         OF ACHIEVEMENT
        </p>
        </div>
        <div style={{marginRight:'20px' , display:'flex' , flexDirection:'column' , justifyContent:'center' , alignItems:'center', width:'10%'}}>
            {/* <img src={qr} alt="Qr Code" width="50" height="50" />
             */}
             <QRCode value={cLink} size={isMobile?20:50} />
            <p align="center" style={{fontSize:isMobile?'2px':'8px' , fontFamily:'Montserrat' , fontWeight:'900'}}>Certificate Id: <br/> {uid.substring(0,10)} <br/> {uid.substring(11,21)} </p>
        </div>
        </div>
        <div style={{marginTop:isMobile?'10px':'20px'}}>
        <p style={{textAlign:'left',marginBottom:isMobile?'5px':'20px', fontFamily: 'Montserrat'}}>
        PROUDLY PRESENTED TO :
        </p>
        <h1 style={h1Style}>
        <span style={{textAlign:'left',fontSize:isMobile?'26px':'52px' , fontFamily: 'Parisienne' }}>
        {window.localStorage.name}
        <Divider sx={dividerStyle} />
        </span>
        </h1>
        
        </div>
        <div style={{marginTop:isMobile?'10px':'20px'}}>
        <p style={{fontFamily:'sans-serif', textAlign:'left'}}>
        Has achieved the skills by completing the certificate for completion at 
        </p>
        <h2 style={{color:'#6a58c6', marginTop:'2px',textAlign:'left' , fontSize:isMobile&&'10px'}}>
        PRODUCT TO MODEL COURSE
        </h2>
        </div>
        <div style={{marginTop:isMobile?'5px':'20px'}}>
        <Skills isMobile={isMobile}/>
        </div>
        <div style={{marginTop:isMobile?'5px':'10px'}}>
        <SignStrip isMobile={isMobile}/>
        </div>
        </div>
        
        
        </div>
    </div>
  );
};

export default CertificateView;
