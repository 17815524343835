import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';

const Navbar = (props) => {
    const { link } = props;

    return (
        <div>
            <Box sx={{
                width: "100%",
                boxShadow: "5",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between"

            }}>
                <Typography variant='h4' style={{
                    marginTop: '10px', paddingBottom: '10px', marginLeft: '1%'
                }}>
                    Learning to apply various prompts
                </Typography>
                <Button
                    sx={{
                        display: "flex",
                        backgroundColor: 'lightgreen',
                        marginLeft: "auto",
                        marginRight: "10px"
                    }}
                >
                    <Typography variant="h4" style={{ color: "black", textTransform: "none", fontSize: "20px" , fontWeight:'bold'}}>
                        <Link to={link} style={{ textDecoration: 'none', color: 'inherit' ,  }}>
                            Launch Lab
                        </Link>
                    </Typography>
                </Button>
            </Box>
        </div>
    );
}

export default Navbar;
