import React from "react";
import { useStyles } from "../NewVirtualCampusStartup/VirtualCampus/VirtualCampusStyle";
import {useNavigate  , useParams} from 'react-router-dom';
import { useState, useEffect } from "react";
import { validateEmail } from "../auth/validateEmail";
import axios from "axios";
import { Typography } from "@material-ui/core";
import { GET_REGISTERED_EVENTS, REGISTER_EVENT } from "../../api/event";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { IconButton , Button} from "@mui/material";

const EventFormPage = () => {
  const [isAuth, setIsAuth] = React.useState(window.localStorage.getItem('isAuth') || false);
  const navigate = useNavigate();
  const params = useParams();
  const [fName, setFName] = useState("");
  const [lName, setLName] = useState("");
  const [contactNo, setContactNo] = useState("");
  const [email, setEmail] = useState("");
  const [collegeName, setCollegeName] = useState("");
  const [topics , setTopics] = useState('');
  const [open, setOpen] = useState(false);
  const [courseId  , setCourseId] = useState(null);
  const [emailError, setEmailError] = useState("");
  const [contactNoError, setContactNoError] = useState("");
  const [submitted , setSubmitted] = useState("");

  useEffect(() => {
    axios
    .get(GET_REGISTERED_EVENTS)
    .then(res => {
      const registeredEvents = res.data.data;
    const isSubmitted = registeredEvents.some((event) => event.userId === window.localStorage.id);
    let isEvent = false;
    if(isSubmitted){
      isEvent = registeredEvents.some(event => event.eventId === params.eventId)
    }
    setSubmitted(isEvent);
    })
    .catch(err => {
    })
  },[])

  useEffect(() => {
    if (!email) {
      setEmailError("");
    } else {
      if (validateEmail(email)) {
        setEmailError("");
      } else {
        setEmailError("Please enter a valid email.");
      }
    }
  }, [email]);

  useEffect(() => {
    if (!contactNo) {
      setContactNoError("");
    } else {
      if (contactNo.length === 10) {
        setContactNoError("");
      } else {
        setContactNoError("Please Enter valid contactNo.");
      }
    }
  }, [contactNo]);
  const MakeAllFalse = () => {
    setFName("");
    setLName("");
    setContactNo("");
    setEmail("");
    setCollegeName("");
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      name: fName+" "+lName,
      wNumber: contactNo,
      email: email,
      collegeName: collegeName,
      topics: topics
    };


    axios
      .post(REGISTER_EVENT+params.eventId+"/"+window.localStorage.id, data)
      .then((res) => {
        MakeAllFalse();
        setSubmitted(true);
        setOpen(true);
      })
      .catch((err) => {});
  };
  const classes = useStyles();
  const styles = {
    radioButton: {
      display: "inline-block",
      marginRight: "5px",
      backgroundColor: "red",
      borderRadius: "50%",
      width: "30px",
      height: "30px",
    },
    label: {
      display: "flex",
      fontFamily: "sans-serif",
      fontSize: "20px",
      fontWeight: "bold",
      color: "black",
    },
  };
  return (
    <>
      <div className={classes.fullpage} style={{marginTop:'120px'}}>
        <div className={classes.centerdiv}>
          <Typography variant="h3" className={classes.head}>
            Events 
          </Typography>
          <div className={classes.formdiv}>
          {submitted ? (
            <>
            <Typography variant="h6" className={classes.head}>
            The form was submitted successfully.
          </Typography>
          <Typography variant="body2" className={classes.head}>
            Click <a href="https://chat.whatsapp.com/L4QMRnE0xQgLPh7uQA0dgo" style={{}}>Here</a> To Join The Whatsapp Group
          </Typography>
          <IconButton href="https://chat.whatsapp.com/L4QMRnE0xQgLPh7uQA0dgo" style={{background:'#000'  , margin: '2px'}}>
          <WhatsAppIcon style={{color:'green'}}/>
          </IconButton>
          <Button variant="contained" href="/courses" style={{background:'#000'}}>
            Courses
          </Button>
          </>
          ): (
            
            <form className={classes.form} onSubmit={handleSubmit}>
              <div className={classes.rowinput}>
                <div className={classes.inputcontainer}>
                  <input
                    type="text"
                    class={classes.input_box}
                    required
                    value={fName}
                    onChange={(e) => setFName(e.target.value)}
                    placeholder="First Name"
                  />
                </div>
                <div className={classes.inputcontainer}>
                  <input
                    type="text"
                    class={classes.input_box}
                    required
                    value={lName}
                    onChange={(e) => setLName(e.target.value)}
                    placeholder="Last Name"
                  />
                </div>
              </div>
              <span style={{ color: "red" }}>{contactNoError}</span>
              <div className={classes.rowinput}>
                <input
                  type="text"
                  class={classes.input_box2}
                  required
                  value={contactNo}
                  onChange={(e) => setContactNo(e.target.value)}
                  placeholder="Contact No."
                />
              </div>
              <span style={{ color: "red" }}>{emailError}</span>
              <div className={classes.rowinput}>
                <input
                  type="email"
                  class={classes.input_box2}
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Email"
                />
              </div>
              <div className={classes.rowinput}>
                <input
                  type="text"
                  class={classes.input_box2}
                  value={collegeName}
                  onChange={(e) => setCollegeName(e.target.value)}
                  placeholder="College Name / Organization / Not Applicable (N/A)"
                />
              </div>
              <div className={classes.rowinput}>
                <input
                  type="text"
                  class={classes.input_box2}
                  value={topics}
                  onChange={(e) => setTopics(e.target.value)}
                  placeholder="What Topics Are You Interested In ?"
                />
              </div>
             
              <div className={classes.rowinput}>
                <button className={classes.submitbtn} onClick={handleSubmit}>
                  Submit
                </button>
              </div>
            </form>
          
          )}
          </div>
        </div>
      </div>
    </>
  );
};

export default EventFormPage;
