import * as React from 'react';
import { Typography , IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import EventCard from './EventCard';
import axios from 'axios';
import { GET_EVENTS } from '../../api/event';
import CircularProgress from '@mui/material/CircularProgress';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import EventNotifications from './EventNotifications'

const Events = ({isAuth}) => {
  const theme = useTheme();
  const [events , setEvents] = React.useState([]);
  const [loading , setLoading] = React.useState(true);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  React.useEffect(() => {
    axios
    .get(GET_EVENTS)
    .then(res => {
      setEvents(res.data.data.reverse());
      setLoading(false);
    })
    .catch(err => {
    })
  },[])
  return (
    <Box sx={{
        marginTop:{sm:'140px' , xs: '160px'}
    }}>
    {loading? (
      <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: isMobile ? '300px' : '500px',
              }}
            >
              <CircularProgress style={{ color: '#6A58C6' }} />
            </div>
    ): (
      <>
      
      <Typography 
            variant="h4" 
            fontWeight="800" 
            style={{ 
                color: '#000', 
                textAlign:'center' 
        }}>
          Upcoming Events
        </Typography>
        <Grid 
            container 
            rowSpacing={2} 
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            marginTop="60px"
            flexWrap={'wrap'}
            >
            {
              events.map((event) => (
                <Grid 
                item 
                xs={12}
                sm={6} 
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                >
                <EventCard data={event} isAuth={isAuth}/>
            </Grid>
              ))
            }
        </Grid>
        </>
    )}
        
    </Box>
  )
}

export default Events