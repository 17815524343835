import React from 'react';
import { Box, Card, CardContent, CardMedia, Typography, CardActionArea, IconButton, Snackbar, Alert } from '@mui/material';
import { useParams } from 'react-router-dom';
import { ContentCopy } from '@mui/icons-material';
import axios from 'axios';
import { USER_REFFERAL } from '../../api/vsc';
import ProductToModel from '../CertLanding/Images/Certificate.jpg';
import PromptWorkx from '../CourseLandingPage/Images/workex.png';
import PromptProject from '../CourseLandingPage/Images/project.png';
import Prompt from '../CourseLandingPage/Images/practical.png';
import Productworkx from '../CourseLandingPage/Images/productworkex.png';
import ProductProject from '../CourseLandingPage/Images/productproject.png';
import Product from '../CourseLandingPage/Images/productpractical.png';
import Python from './Images/pythoncoding.png';
import PythonProject from './Images/pythonproject.png';
import ManagementConsulting from './Images/managementconsulting.png';
import ManagementConsultingWorkx from './Images/managementconsultingworkex.png';
import ManagementConsultingProject from './Images/managementconsultingproject.png';
import ViewCertficate from './ViewCertificate';

const EarnedCertificates = ({ from }) => {
  const params = useParams();
  const [earnedCertificates, setEarnedCertificates] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [certificate, setCertificate] = React.useState({});
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState('');

  React.useEffect(() => {
    axios
      .get(USER_REFFERAL + params.id)
      .then(res => {
        const existingCertificates = res.data.data.earnedCertificates.map(certificate => {
          let image;
  
          if (certificate.certificateUrl) {
            image = certificate.certificateUrl;
          } else {
            switch (certificate.courseName) {
              case "Product To Model":
                image = ProductToModel;
                break;
              case "Product Management Project Experience":
                image = Productworkx;
                break;
              case "Prompt Engineering":
                image = Prompt;
                break;
              case "Prompt Engineering Work Experience":
                image = PromptWorkx;
                break;
              case "Prompt Engineering Project Experience":
                image = PromptProject;
                break;
              case "Product Management Work Experience":
                image = ProductProject;
                break;
              case "Product Management":
                image = Product;
                break;
              case "Python Coder Project Experience":
                image = PythonProject;
                break;
              case "Python Coder":
                image = Python;
                break;
              case "Management Consulting":
                image = ManagementConsulting;
                break;
              case "Management Consulting Work Experience":
                image = ManagementConsultingWorkx;
                break;
              case "Management Consulting Project Experience":
                image = ManagementConsultingProject;
                break;
              default:
                image = null; // or some default image if certificate.certificateUrl is not provided
                break;
            }
          }
  
          return {
            ...certificate,
            image: image
          };
        });
        setEarnedCertificates(existingCertificates);
      })
      .catch(err => {
        console.error(err);
      });
  }, [params.id]);

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      setSnackbarMessage('Serial number copied to clipboard!');
      setSnackbarOpen(true);
    }).catch(err => {
      console.error('Failed to copy text: ', err);
    });
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <div
      style={{
        marginTop: from ? '20px' : '120px',
        padding: '20px'
      }}
    >
      <Typography variant="h4" fontWeight="800" style={{ color: '#000' }}>
        My Certificates
      </Typography>
      <Box
        sx={{
          display: 'flex',
          marginTop: '20px',
          flexWrap: 'wrap',
          gap: '20px',
          flexDirection: { sm: 'row', xs: 'column' }
        }}
      >
        {
          earnedCertificates && earnedCertificates.length > 0 && earnedCertificates.map((earnedCertificate, index) => (
            <Card
              key={index}
              onClick={() => {
                setCertificate(earnedCertificate);
                setOpen(true);
              }}
              sx={{
                width: { sm: '30%', xs: '100%' },
                boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px'
              }}
            >
              <CardActionArea>
                <Box sx={{ position: 'relative' }}>
                  <CardMedia
                    component="img"
                    width="98%"
                    image={earnedCertificate.image}
                    alt="Certificate"
                  />
                  {/* {<Typography
                    sx={{
                      position: 'absolute',
                      top: earnedCertificate.courseName.toLowerCase().includes('python coder project experience') ? '45%' :'38%',
                      left: earnedCertificate.courseName.toLowerCase().includes('python coder project experience') ? '10%' : '30%',
                      fontFamily: 'Parisienne',
                      fontWeight: '550',
                    }}
                  >
                    {earnedCertificate.userName} 
                  </Typography>} */}
                </Box>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    {earnedCertificate.courseName}
                  </Typography>
                  <Typography gutterBottom variant="caption" component="div">
                    {earnedCertificate.serielNumber}
                    <IconButton size="small" sx={{ marginLeft: 1 }} onClick={(e) => {
                      e.stopPropagation();
                      handleCopy(earnedCertificate.serielNumber);
                    }}>
                      <ContentCopy fontSize="small" />
                    </IconButton>
                  </Typography>
                  <Typography gutterBottom variant="caption" component="div">
                    {earnedCertificate.date}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          ))
        }
      </Box>
      <ViewCertficate open={open} setOpen={setOpen} certificate={certificate} />
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default EarnedCertificates;
