import React from 'react'
import Typewriter from "typewriter-effect";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import InventoryOutlinedIcon from "@mui/icons-material/InventoryOutlined";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import item1 from "../../NewVirtualCampusStartup/Images/item1.png";
import item_w from "../../NewVirtualCampusStartup/Images/item1-w.png";
import "./index.css";

const Bundles = () => {
  return (
        <div className="courses-main-d" >
        <div className="main-heading_virtual-d">
          <div className="textVSC-d">
            <Typewriter
              options={{
                autoStart: true,
                loop: true,
                delay: 40,
                strings: ["Learn . Apply . Work Ex"],
              }}
            />
          </div>
          <p>
          Get Job Ready with Outcome Driven Industry Exposure through Labs, Capstone Projects and Virtual Internships
          </p>
        </div>
        
        <div className="v_courses-d">
          <div className="box_2-d">
            <div className="course_box_2-d">
              <div
                style={{
                  width: "100%",
                  height: "128px",
                  backgroundColor: "#6a58c6",
                  borderRadius: "0.5rem 0.5rem 0 0",
                }}
              />
              <div className="course_box_2_image-d">
                <img
                  src={item_w}
                  style={{
                    objectFit: "fill",
                    width: "100%",
                    height: "100%",
                    borderRadius: "50%",
                  }}
                />
              </div>
              <h2
                style={{
                  color: "#1A202C",
                  textAlign: "center",
                  marginTop: "-40px",
                  fontFamily: "Poppins",
                  fontSize: "24px",
                  fontWeight: "800",
                  lineHeight: "36px",
                }}
              >
                Finance Analyst
              </h2>
              <p
                style={{
                  color: "#1A202C",
                  textAlign: "center",
                  fontFamily: "Open Sans",
                  fontSize: "16px",
                  fontWeight: "400",
                  marginTop: "10px",
                  lineHeight: "24px",
                }}
              >
                Learn {"->"} Apply {"->"} Work Ex 
              </p>

              {/* <div
                style={{
                  marginTop: "10px",
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <p
                  style={{
                    padding: "2px 10px",
                    backgroundColor: "#F2F6FF",
                    borderRadius: "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "233px",
                    height: "20px",
                    color: "#3470e4",
                    textTransform: "uppercase",
                    fontFamily: "Open Sans",
                    fontSize: "12px",
                    lineHeight: "16px",
                    fontWeight: "600",
                  }}
                >
                  Undergrad/Postgrad Students
                </p>
              </div> */}
              <div style={{ marginTop: "20px" }}>
                <p
                  style={{
                    fontFamily: "Open Sans",
                    fontWeight: "600",
                    fontSize: "16px",
                    lineHeight: "24px",
                    color: "#21191b",
                    display: "flex",
                    alignItems: "center",
                    // border: "1px solid red",
                    padding: "2px 0px 2px 10px",
                  }}
                >
                  <span
                    style={{
                      color: "gray",
                      marginRight: "5px",
                      marginTop: "2px",
                    }}
                  >
                    <ManageAccountsOutlinedIcon />
                  </span>
                  Grad/Professionals 
                </p>
                <p
                  style={{
                    fontFamily: "Open Sans",
                    fontWeight: "600",
                    fontSize: "16px",
                    lineHeight: "24px",
                    color: "#21191b",
                    display: "flex",
                    alignItems: "center",
                    // border: "1px solid red",
                    padding: "2px 0px 2px 10px",
                  }}
                >
                  <span
                    style={{
                      color: "gray",
                      marginRight: "5px",
                      marginTop: "2px",
                    }}
                  >
                    <ManageAccountsOutlinedIcon />
                  </span>
                  Finance in Excel, Financial Modelling, Financial Decision Making                </p>
                <p
                  style={{
                    fontFamily: "Open Sans",
                    fontWeight: "600",
                    fontSize: "16px",
                    lineHeight: "24px",
                    color: "#21191b",
                    display: "flex",
                    alignItems: "center",
                    padding: "2px 0px 2px 10px",
                  }}
                >
                  <span
                    style={{
                      color: "gray",
                      marginRight: "5px",
                      marginTop: "2px",
                    }}
                  >
                    <WorkOutlineOutlinedIcon />
                  </span>
                  Benefits : Financial Career Building
                </p>
                <p
                  style={{
                    fontFamily: "Open Sans",
                    fontWeight: "600",
                    fontSize: "16px",
                    lineHeight: "24px",
                    color: "#21191b",
                    display: "flex",
                    alignItems: "center",
                    // border: "1px solid red",
                    padding: "2px 0px 2px 10px",
                  }}
                >
                  <span
                    style={{
                      color: "gray",
                      marginRight: "5px",
                      marginTop: "2px",
                    }}
                  >
                    <InventoryOutlinedIcon />
                  </span>
                  Outcome : 
                  <span style={{ marginLeft: "3px", color: "#3470e4" }}>
                    {" "}
                    Finance Job Ready Profile
                  </span>
                </p>
              </div>
              {/* <a href="/program/markoknow_workX"> */}
              <a href="/financialanalyst">
                <div
                  style={{
                    width: "100%",
                    height: "60px",
                    marginTop: "30px",
                    marginBottom: "-5px",
                    backgroundColor: "#6a58c6",
                    borderRadius: "0 0 0.5rem 0.5rem",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "#FFFFFF",
                    fontFamily: "Open Sans",
                    fontWeight: "600",
                    fontSize: "18px",
                    lineHeight: "27px",
                    textTransform: "uppercase",
                    cursor: "pointer",
                  }}
                >
                  {/* View Course */}
                  View Course
                </div>
              </a>
            </div>

          </div>
          <div className="box_2-d">
            <div className="course_box_2-d">
              <div
                style={{
                  width: "100%",
                  height: "128px",
                  backgroundColor: "#6a58c6",
                  borderRadius: "0.5rem 0.5rem 0 0",
                }}
              />
              <div className="course_box_2_image-d">
                <img
                  src={item_w}
                  style={{
                    objectFit: "fill",
                    width: "100%",
                    height: "100%",
                    borderRadius: "50%",
                  }}
                />
              </div>
              <h2
                style={{
                  color: "#1A202C",
                  textAlign: "center",
                  marginTop: "-40px",
                  fontFamily: "Poppins",
                  fontSize: "24px",
                  fontWeight: "800",
                  lineHeight: "36px",
                }}
              >
                Management Consulting
              </h2>
              <p
                style={{
                  color: "#1A202C",
                  textAlign: "center",
                  fontFamily: "Open Sans",
                  fontSize: "16px",
                  fontWeight: "400",
                  marginTop: "10px",
                  lineHeight: "24px",
                }}
              >
                Learn {"->"} Apply {"->"} Work Ex 
              </p>

              {/* <div
                style={{
                  marginTop: "10px",
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <p
                  style={{
                    padding: "2px 10px",
                    backgroundColor: "#F2F6FF",
                    borderRadius: "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "233px",
                    height: "20px",
                    color: "#3470e4",
                    textTransform: "uppercase",
                    fontFamily: "Open Sans",
                    fontSize: "12px",
                    lineHeight: "16px",
                    fontWeight: "600",
                  }}
                >
                  Undergrad/Postgrad Students
                </p>
              </div> */}
              <div style={{ marginTop: "20px" }}>
                <p
                  style={{
                    fontFamily: "Open Sans",
                    fontWeight: "600",
                    fontSize: "16px",
                    lineHeight: "24px",
                    color: "#21191b",
                    display: "flex",
                    alignItems: "center",
                    // border: "1px solid red",
                    padding: "2px 0px 2px 10px",
                  }}
                >
                  <span
                    style={{
                      color: "gray",
                      marginRight: "5px",
                      marginTop: "2px",
                    }}
                  >
                    <ManageAccountsOutlinedIcon />
                  </span>
                  Grad/Professionals 
                </p>
                <p
                  style={{
                    fontFamily: "Open Sans",
                    fontWeight: "600",
                    fontSize: "16px",
                    lineHeight: "24px",
                    color: "#21191b",
                    display: "flex",
                    alignItems: "center",
                    // border: "1px solid red",
                    padding: "2px 0px 2px 10px",
                  }}
                >
                  <span
                    style={{
                      color: "gray",
                      marginRight: "5px",
                      marginTop: "2px",
                    }}
                  >
                    <ManageAccountsOutlinedIcon />
                  </span>
                  Problem Solving Framework, Strategy Development, Digital Transformation                </p>
                <p
                  style={{
                    fontFamily: "Open Sans",
                    fontWeight: "600",
                    fontSize: "16px",
                    lineHeight: "24px",
                    color: "#21191b",
                    display: "flex",
                    alignItems: "center",
                    padding: "2px 0px 2px 10px",
                  }}
                >
                  <span
                    style={{
                      color: "gray",
                      marginRight: "5px",
                      marginTop: "2px",
                    }}
                  >
                    <WorkOutlineOutlinedIcon />
                  </span>
                  Benefits : Management Career Building
                </p>
                <p
                  style={{
                    fontFamily: "Open Sans",
                    fontWeight: "600",
                    fontSize: "16px",
                    lineHeight: "24px",
                    color: "#21191b",
                    display: "flex",
                    alignItems: "center",
                    // border: "1px solid red",
                    padding: "2px 0px 2px 10px",
                  }}
                >
                  <span
                    style={{
                      color: "gray",
                      marginRight: "5px",
                      marginTop: "2px",
                    }}
                  >
                    <InventoryOutlinedIcon />
                  </span>
                  Outcome : 
                  <span style={{ marginLeft: "3px", color: "#3470e4" }}>
                    {" "}
                    Consulting Job Ready Profile
                  </span>
                </p>
              </div>
              {/* <a href="/program/markoknow_workX"> */}
              <a href="/managementconsulting">
                <div
                  style={{
                    width: "100%",
                    height: "60px",
                    marginTop: "30px",
                    marginBottom: "-5px",
                    backgroundColor: "#6a58c6",
                    borderRadius: "0 0 0.5rem 0.5rem",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "#FFFFFF",
                    fontFamily: "Open Sans",
                    fontWeight: "600",
                    fontSize: "18px",
                    lineHeight: "27px",
                    textTransform: "uppercase",
                    cursor: "pointer",
                  }}
                >
                  {/* View Course */}
                  View Course
                </div>
              </a>
            </div>

          </div>
          <div className="box_1-d">
            <div className="course_box_1-d" >
              <div
                style={{
                  width: "100%",
                  height: "128px",
                  backgroundColor: "#6a58c6",
                  borderRadius: "0.5rem 0.5rem 0 0",
                }}
              />
              <div className="course_box_1_image-d">
                <img
                  src={item1}
                  style={{
                    objectFit: "fill",
                    width: "100%",
                    height: "100%",
                    borderRadius: "50%",
                  }}
                />
              </div>
              <h2
                style={{
                  color: "#1A202C",
                  textAlign: "center",
                  marginTop: "-40px",
                  fontFamily: "Poppins",
                  fontSize: "24px",
                  fontWeight: "800",
                  lineHeight: "36px",
                }}
              >
                Product Management
              </h2>
              <p
                style={{
                  color: "#1A202C",
                  textAlign: "center",
                  fontFamily: "Open Sans",
                  fontSize: "16px",
                  fontWeight: "400",
                  marginTop: "10px",
                  lineHeight: "24px",
                }}
              >
                Get started on Product Management Career
              </p>

              {/* <div
                style={{
                  marginTop: "10px",
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <p
                  style={{
                    padding: "2px 10px",
                    backgroundColor: "#F2F6FF",
                    borderRadius: "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "233px",
                    height: "20px",
                    color: "#3470e4",
                    textTransform: "uppercase",
                    fontFamily: "Open Sans",
                    fontSize: "12px",
                    lineHeight: "16px",
                    fontWeight: "600",
                  }}
                >
                  Undergrad/Grad Students
                </p>
              </div> */}
              <div style={{ marginTop: "20px" }}>
                <p
                  style={{
                    fontFamily: "Open Sans",
                    fontWeight: "600",
                    fontSize: "16px",
                    lineHeight: "24px",
                    color: "#21191b",
                    display: "flex",
                    alignItems: "center",
                    // border: "1px solid red",
                    padding: "2px 0px 2px 10px",
                  }}
                >
                  <span
                    style={{
                      color: "gray",
                      marginRight: "5px",
                      marginTop: "2px",
                    }}
                  >
                    <ManageAccountsOutlinedIcon />
                  </span>
                  Undergrad/Grad Students
                </p>
                <p
                  style={{
                    fontFamily: "Open Sans",
                    fontWeight: "600",
                    fontSize: "16px",
                    lineHeight: "24px",
                    color: "#21191b",
                    display: "flex",
                    alignItems: "center",
                    // border: "1px solid red",
                    padding: "2px 0px 2px 10px",
                  }}
                >
                  <span
                    style={{
                      color: "gray",
                      marginRight: "5px",
                      marginTop: "2px",
                    }}
                  >
                    <ManageAccountsOutlinedIcon />
                  </span>
                  Product Management | Experience working in Product Role
                </p>
                <p
                  style={{
                    fontFamily: "Open Sans",
                    fontWeight: "600",
                    fontSize: "16px",
                    lineHeight: "24px",
                    color: "#21191b",
                    display: "flex",
                    alignItems: "center",
                    padding: "2px 0px 2px 10px",
                  }}
                >
                  <span
                    style={{
                      color: "gray",
                      marginRight: "5px",
                      marginTop: "2px",
                    }}
                  >
                    <WorkOutlineOutlinedIcon />
                  </span>
                  Benefits : Build, Market & Ship
                </p>
                <p
                  style={{
                    fontFamily: "Open Sans",
                    fontWeight: "600",
                    fontSize: "16px",
                    lineHeight: "24px",
                    color: "#21191b",
                    display: "flex",
                    alignItems: "center",
                    // border: "1px solid red",
                    padding: "2px 0px 2px 10px",
                  }}
                >
                  <span
                    style={{
                      color: "gray",
                      marginRight: "5px",
                      marginTop: "2px",
                    }}
                  >
                    <InventoryOutlinedIcon />
                  </span>
                  Outcome :
                  <span style={{ marginLeft: "3px", color: "#3470e4" }}>
                    {" "}
                    Get Started in Product Career
                  </span>
                </p>
              </div>
              <a href="/productmanagement">
                <div
                  style={{
                    width: "100%",
                    height: "60px",
                    marginTop: "30px",
                    marginBottom: "-5px",
                    backgroundColor: "#6a58c6",
                    borderRadius: "0 0 0.5rem 0.5rem",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "#FFFFFF",
                    fontFamily: "Open Sans",
                    fontWeight: "600",
                    fontSize: "18px",
                    lineHeight: "27px",
                    textTransform: "uppercase",
                    cursor: "pointer",
                  }}
                >
                  View Course
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
  )
}

export default Bundles