const { BASE_URL } = require("./client");

const GET_REDEEM = BASE_URL+'/api/redeem/get';
const POST_REEDOM = BASE_URL +"/api/redeem/post";
const DELETE_REEDOM = BASE_URL +"/api/redeem/delete";
const REDEEM_POINTS = BASE_URL + "/api/redeem/points/user/";

module.exports = {
    POST_REEDOM,
    GET_REDEEM,
    DELETE_REEDOM,
    REDEEM_POINTS
}