import * as React from 'react';
import CourseCard from './CourseCard';
import Grid from '@mui/material/Grid';
import axios from 'axios';
import { GET_COURSES } from '../../api/vsc';

const Course = () => {
  const dummyArray = Array.from({ length: 30 }, (_, index) => `Element ${index + 1}`);
  const [courses , setCourses] = React.useState([]);
  React.useEffect(() => {
    axios.get(GET_COURSES)
    .then(res => {
      const sortedCourses = res.data.data.sort((a, b) => {
        const priorityA = a.priority || 1000; 
        const priorityB = b.priority || 1000; 
        return priorityA - priorityB;
      });
        setCourses(sortedCourses);
    })
    .catch(err => {
    })
  })

  return (
    <div style={{ marginTop: '120px' , width: '100%'}}>
          <CourseCard courses = {courses} />
    </div>
  );
};

export default Course;
