import * as React from 'react';
import axios from 'axios';
import { useMediaQuery } from '@mui/material';
import {Link , useNavigate} from 'react-router-dom';
import { Card, CardContent, CardActions, Button, Typography } from '@mui/material';
import DateRangeIcon from "@mui/icons-material/DateRange";
import ScheduleIcon from "@mui/icons-material/Schedule";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import './Course.css';
import Grow from '@mui/material/Grow';
import Zoom from '@mui/material/Zoom';
import Fade from '@mui/material/Fade';
import CircularProgress from '@mui/material/CircularProgress';



const ImgMediaCard = ({ courses }) => {
  const isSmallMobile = useMediaQuery('(max-width: 410px)');
  const isMobile = useMediaQuery('(max-width: 500px)');
  const navigate = useNavigate();
  const course1 = 1;
  const paid = true;
  const isAuth = true;
  const course1P = 100;
  const [isHovered, setIsHovered] = React.useState(false);
  const [hoveredIndex, setHoveredIndex] = React.useState(null);
  const [checked, setChecked] = React.useState(false);
  React.useEffect(() => {
    setChecked(true);
  },[])

  return (
    <div>
      <Typography variant="h3" textAlign="center" style={{fontFamily:'Poppins' , fontWeight:'bold'}}>Courses</Typography>

    <div
      style={{
        marginTop: '50px',
        display: 'flex',
        flexWrap: 'wrap',
        gap: '30px',
        justifyContent: 'center', 
        alignItems: 'center', 
        padding: '5px'
      }}
    >
    

      {courses.length > 0 ? (
        courses.map((c, index) => (
          <Fade
          in={checked}
        >
          <Card
            key={c._id}
            onMouseEnter={() =>{ setIsHovered(true); setHoveredIndex(index)}}
            onMouseLeave={() =>{ setIsHovered(false); setHoveredIndex(null)}}
            style={{
              maxWidth: 400,
              height: 600 ,
              transform: hoveredIndex === index && 'scale(1)' ,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between', // Push button to the left corner
              boxShadow: 'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset'
            }}
          >
            <img src={c.coursePreview} alt="Course Thumbnail" style={{ width: 500 , height: 300 }} />
            <CardContent>
              <Typography variant="h5" textAlign={'center'} fontWeight={'bold'}>{c.courseName}</Typography>
              <div 
               style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                   
                  }}>
                  <p
                  style={{
                    padding: "2px 10px",
                    backgroundColor: "#F2F6FF",
                    borderRadius: "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "233px",
                    height: "20px",
                    color: "#6E71CC",
                    textTransform: "uppercase",
                    fontFamily: "Open Sans",
                    fontSize: "12px",
                    lineHeight: "16px",
                    fontWeight: "600",
                    textAlign:'center'
                  }}
                  >
                  Undergrad Students or Grad
                  </p>
              </div>
              <div
                  style={{
                    margin: "5px 0px 0px",
                    padding: "0px 5px",
        
                  }}
                >
                  <p
                    style={{
                      color: "#21191B",
                      marginBottom: "10px",
                      fontWeight: "600",
                    }}
                  >
                    <DateRangeIcon
                      style={{
                        color: "gray",
                        fontSize: "18px",
                        position: "relative",
                        top: "5px",
                        marginRight: "5px",
                        marginLeft: "-13px",
                      }}
                    />
                    {c.courseName}
                  </p>
                  <p
                    style={{
                      color: "#21191B",
                      marginBottom: "10px",
                      fontWeight: "600",
                    }}
                  >
                    <span>
                      <ScheduleIcon
                        style={{
                          color: "gray",
                          fontSize: "18px",
                          position: "relative",
                          top: "5px",
                          marginRight: "5px",
                          marginLeft: "-13px",
                        }}
                      />
                    </span>
                    2 Real Time Cases
                  </p>
                  <p
                    style={{
                      color: "#21191B",
                      marginBottom: "10px",
                      fontWeight: "600",
                    }}
                  >
                    <span>
                      <CurrencyRupeeIcon
                        style={{
                          color: "gray",
                          fontSize: "18px",
                          position: "relative",
                          top: "5px",
                          marginLeft: "-13px",
                        }}
                      />
                    </span>{" "}
                    Outcome: Problem Selection, Product decision, Product
                    features, Idea Execution
                  </p>
                  </div>
            </CardContent>
            <CardActions style={{display:'flex' , justifyContent:'flex-end'}}>
              {paid && isHovered && index === hoveredIndex && (
                <Button variant="contained" color="primary" style={{ alignSelf: 'flex-end' , backgroundColor: '#6A58C6' }}>
                  <Link to={`/program/videoplayer/${c._id}`} style={{ textDecoration: 'none' , color:'#fff'}}>
                    Open
                  </Link>
                </Button>
              )}
            </CardActions>
          </Card>
          </Fade>
        ))
      ) : (
        <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress style={{ color: '#6A58C6' }} />
            </div> 
      )}
      
    </div>
   
    </div>
  );
};

export default ImgMediaCard;
