import * as React from 'react';
import {Box,Typography, Button} from '@mui/material';
// import Box from '@mui/joy'
import CircularProgress from '@mui/joy/CircularProgress';
import {USER} from '../../../api/vsc';
import axios from 'axios';
import {Link} from 'react-router-dom'
const Timeline = () => {
    const [data , setData] = React.useState({});
    const [course1P ,setCourse1P ] = React.useState(0);
    const [course2P ,setCourse2P ] = React.useState(0);
    const [course3P ,setCourse3P ] = React.useState(0);
    const [course1Pt ,setCourse1Pt ] = React.useState(0);
    const [course2Pt ,setCourse2Pt ] = React.useState(0);
    const [course3Pt ,setCourse3Pt ] = React.useState(0);
    const [course1Pc ,setCourse1Pc] = React.useState(0);
    const [course2Pc ,setCourse2Pc ] = React.useState(0);
    const [course3Pc ,setCourse3Pc ] = React.useState(0);


  React.useEffect(() => {
    axios
      .get(USER, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then(response => {
        setCourse1P(((response.data.courseDetails.find(course => course.courseId === "64fb08d372d0fde41877df63").videosCompleted.length+response.data.courseDetails.find(course => course.courseId === "64fb08d372d0fde41877df63").pdfsCompleted.length)/response.data.courseDetails.find(course => course.courseId === "64fb08d372d0fde41877df63").total)*100);
        setCourse1Pt(response.data.courseDetails.find(course => course.courseId === "64fb08d372d0fde41877df63").total);
        setCourse1Pc(response.data.courseDetails.find(course => course.courseId === "64fb08d372d0fde41877df63").videosCompleted.length+response.data.courseDetails.find(course => course.courseId === "64fb08d372d0fde41877df63").pdfsCompleted.length);
        setCourse2P(((response.data.courseDetails.find(course => course.courseId === "64fb15b672d0fde41877e193").videosCompleted.length+response.data.courseDetails.find(course => course.courseId === "64fb15b672d0fde41877e193").pdfsCompleted.length)/response.data.courseDetails.find(course => course.courseId === "64fb15b672d0fde41877e193").total)*100);
        setCourse2Pc(response.data.courseDetails.find(course => course.courseId === "64fb15b672d0fde41877e193").videosCompleted.length+response.data.courseDetails.find(course => course.courseId === "64fb15b672d0fde41877e193").pdfsCompleted.length);
        setCourse2Pt(response.data.courseDetails.find(course => course.courseId === "64fb15b672d0fde41877e193").total);
        setCourse3P(((response.data.courseDetails.find(course => course.courseId === "64fb105b72d0fde41877e101").videosCompleted.length+response.data.courseDetails.find(course => course.courseId === "64fb105b72d0fde41877e101").pdfsCompleted.length)/response.data.courseDetails.find(course => course.courseId === "64fb105b72d0fde41877e101").total)*100);
        setCourse3Pt(response.data.courseDetails.find(course => course.courseId === "64fb105b72d0fde41877e101").total);
        setCourse3Pc(response.data.courseDetails.find(course => course.courseId === "64fb105b72d0fde41877e101").videosCompleted.length+response.data.courseDetails.find(course => course.courseId === "64fb105b72d0fde41877e101").pdfsCompleted.length);


      })
      .catch(err => {
      })
  },[])
  return (
    <div style={{borderRadius:'10px' , padding:'4%' , lineHeight:'1' ,  boxShadow: 'rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px',}}>
        <div style={{display:'flex' , padding:'1%' , justifyContent:'space-between' , marginBottom:'10px'}}>
            <h2  style={{textAlign:'left'}}>Timeline</h2>
            <Button href={`../courses`} variant='contained' size='small' style={{background:'#6a58c6' , borderRadius:'10px' , fontSize:'12px' , height:'40px'}}>View All</Button>
            </div>
        {/* {data.map((Course) => ( */}
            {/* <div style={{display:'flex' , flexDirection:'row' , justifyContent:'space-between' , marginBottom:'10px'}}>
        <Link 
        to={"/program/videoplayer/64fb08d372d0fde41877df63"}
        style={{
            borderRadius:'10px',
            background:'#6a58c6',
            width:'30%',
            height:'30px',
            marginBottom: '10px',
            display:'flex',
            alignItems:'center',
            justifyContent:'center'
        }}>
        <Box
        // style={{
        //     borderRadius:'10px',
        //     background:'#6a58c6',
        //     width:'30%',
        //     height:'30px',
        //     marginBottom: '10px',
        //     display:'flex',
        //     alignItems:'center',
        //     justifyContent:'center'
        // }}
        >
            <Typography style={{color:'#fff' }}>MVP</Typography>
        </Box>
        </Link>
        <Box>
            <Typography>{course1Pc}/{course1Pt}</Typography>
        </Box> 
        <Box style={{display:'flex' , justifyContent:'center' , alignItems:'center'}}>
        <CircularProgress size="md" determinate value={course1P} style={{color:'#6a58c6'}}>
         {course1P}%
        </CircularProgress>
        </Box>       
        </div>
        ))} */}
        
        <div style={{display:'flex' , flexDirection:'row' , justifyContent:'space-between' , marginBottom:'10px'}}>
        <Link 
        to="/program/videoplayer/64fb15b672d0fde41877e193"
        style={{
            borderRadius:'10px',
            background:'#6a58c6',
            width:'30%',
            height:'30px',
            marginBottom: '10px',
            display:'flex',
            alignItems:'center',
            justifyContent:'center'
        }}>
        <Box
        // style={{
        //     borderRadius:'10px',
        //     background:'#6a58c6',
        //     width:'30%',
        //     height:'30px',
        //     marginBottom: '10px',
        //     display:'flex',
        //     alignItems:'center',
        //     justifyContent:'center'
        // }}
        >
            <Typography style={{color:'#fff' }}>GTM</Typography>
        </Box>
        </Link>
        <Box>
            <Typography>{course2Pc}/{course2Pt}</Typography>
        </Box> 
        <Box style={{display:'flex' , justifyContent:'center' , alignItems:'center'}}>
        <CircularProgress size="md" determinate value={course2P} style={{color:'#6a58c6'}}>
         {course2P}%
        </CircularProgress>
        </Box>       
        </div>
        <div style={{display:'flex' , flexDirection:'row' , justifyContent:'space-between' , marginBottom:'10px'}}>
        <Link 
        to="/program/videoplayer/64fb105b72d0fde41877e101"
        style={{
            borderRadius:'10px',
            background:'#6a58c6',
            width:'30%',
            height:'30px',
            marginBottom: '10px',
            display:'flex',
            alignItems:'center',
            justifyContent:'center'
        }}>
        <Box
        // style={{
        //     borderRadius:'10px',
        //     background:'#6a58c6',
        //     width:'30%',
        //     height:'30px',
        //     marginBottom: '10px',
        //     display:'flex',
        //     alignItems:'center',
        //     justifyContent:'center'
        // }}
        >
            <Typography style={{color:'#fff' }}>BMC</Typography>
        </Box>
        </Link>
        <Box>
            <Typography>{course3Pc}/{course3Pt}</Typography>
        </Box> 
        <Box style={{display:'flex' , justifyContent:'center' , alignItems:'center'}}>
        <CircularProgress size="md" determinate value={course3P} style={{color:'#6a58c6'}}>
         {course3P}%
        </CircularProgress>
        </Box>       
        </div>

    </div>
  )
}

export default Timeline