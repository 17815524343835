import React from 'react';
import {Box,Typography, Button , useMediaQuery} from '@mui/material';
// import Box from '@mui/joy'
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import Modal from '@mui/material/Modal';
import {LuMedal} from 'react-icons/lu';
import {MdMilitaryTech} from 'react-icons/md';
import {RiMedalFill} from 'react-icons/ri';
import './index.css';
import Badges from '../../Badge/index';
import axios from 'axios';
import { GET_BADGES } from '../../../api/badge';
import LockIcon from '@mui/icons-material/Lock';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { USER_REFFERAL } from '../../../api/vsc';
const Badge = () => {
  const isSMobile = useMediaQuery('(max-width:725px)');
  const [open, setOpen] = React.useState(false);
  const [sBadge , setSBadge] = React.useState({});
  const [badges, setBadges] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [isHovered, setHovered] = React.useState(false);
  const [completedBadges , setCompletedBadges] = React.useState([]);

  const containerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
    cursor: 'pointer',
    flexDirection: 'column',
    borderRadius:'10px',
    gap: '10px' 
    
  };

  const imageStyle = {
    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
    cursor: 'pointer',
    width: '120px',
    height: '120px',
    transform: isHovered ? 'scale(1.1)' : 'scale(1)',
    transition: 'transform 0.3s ease-in-out',
    borderRadius: isHovered ? '20px' : '0px',
    '&:hover': {
      boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
    },
  };
  const handleOpen = (index) =>{
    setOpen(true);
    setSBadge(index);
  
}
  const handleClose = () => setOpen(false);
  React.useEffect(() => {
    axios
        .get(USER_REFFERAL+window.localStorage.id).then(resp => {
          setCompletedBadges(resp.data.data.completedBadges);
        })
    },[])
  React.useEffect(() => {
    setLoading(true);
    axios
      .get(GET_BADGES)
      .then((res) => {
        setBadges(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
      });
  }, []);
  return (
    <Box style={{borderRadius:'10px' , padding:'4%' , boxShadow: 'rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px' , width:'100%', height:'100%'}}>
        <Box style={{display:'flex' , padding:'1%',alignItems:'center' , justifyContent:'space-between' , marginBottom:'10px'}}>
            <h2  style={{textAlign:'left' , fontSize:'20px'}}>Badge</h2>
            <Button variant='contained' size='small' href='/badges'  style={{background:'#6a58c6' , borderRadius:'10px' , fontSize:'8px' , height:'40px' , width:'50px'}}>View All</Button>
            </Box>
            <Box sx={{display:'flex' , flexDirection: {xs: 'column' ,  sm:'row'}, gap: {xs:"20px"} ,justifyContent:'space-evenly' ,alignItems:'center'}}>

           
           {badges.slice(0,3).map((badge) => (
            <Box style={{display:'flex' , flexDirection:'row' , justifyContent:'space-between', alignItems:'center'}}>
        <Box style={containerStyle}
      onClick={() =>completedBadges.includes(badge.tag.split(" ").join("")) && handleOpen(badge)}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}>
        <img 
          onClick={()=>completedBadges.includes(badge.tag.split(" ").join("")) &&handleOpen(badge)} 
          src={badge.badgeUrl} 
          alt={badge.title} 
          style={imageStyle}

          />
           {completedBadges.includes(badge.tag.split(" ").join("")) ? <CheckCircleIcon style={{color:'green', margin:'10px'}}/> :<LockIcon style={{margin:'10px'}}/> }
        </Box>  
                
        </Box>
           ))}
           </Box>
        <Modal
  open={open}
  onClose={handleClose}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
    <Box style={{display:'flex', alignItems:'center' , justifyContent:'center', position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',}}>
    <Badges sBadge={sBadge}/>

  </Box>
  
</Modal>
    </Box>
  )
}

export default Badge;