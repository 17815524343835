
import { Box, TextField, Typography , Button} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { validateEmail } from "../auth/validateEmail";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@mui/styles";
import "../../stylesheets/loginform.css"
import axios from "axios";
import ReactGA from "react-ga";
import { FORGOT_PASSWORD } from "../../api/forgotPassword";
import { useMediaQuery } from "@mui/material";
function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 250,
    background:'#fff',
    padding:'5%',
    border: "2px solid #000",
    margin:'auto',
    
  },
}));

function ForgetPass() {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [response, setResponse] = useState("Error !! Please try again");
  const isMobile = useMediaQuery('(max-width:600px)')

  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    if (!email) {
      setEmailError("");
    } else {
      if (validateEmail(email)) {
        setEmailError("");
      } else {
        setEmailError("Please enter a valid email.");
      }
    }
  }, [email]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  const body = (
    <div 
    
    style={modalStyle} 
    
    className={classes.paper}>
      {response === "saved succesfully" ? (
        <h2 style={{ color: "green" , textAlign:'center'}} id="simple-modal-title">
          Success
        </h2>
      ) : (
        <h2 style={{ color: "red",textAlign:'center'}} id="simple-modal-title">
          Failure
        </h2>
      )}
      <p id="simple-modal-description" style={{textAlign:"center"}}>{response}</p>
      {response === "saved succesfully" && (
        <p id="simple-modal-description" style={{textAlign:'center'}}>
          Reset Password Link has been sent to your email.
        </p>
      )}
      <Link
          to="/"
          style={{ textDecoration: "none", color: "black" }}
          className="btn-primary"
        >
      <Button variant="Contained" style={{background:'#6A58C6' , color:'#fff' ,marginTop:'20px' ,marginLeft:'35%'}}>
       
          Home
      </Button>
      </Link>
    </div>
  );

  const clearset = () => {
    setEmail("");
    setEmailError("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const registered = {
      email: email,
      path:'/vsc'
    };
    axios
      .post(FORGOT_PASSWORD, registered)
      .then((response) => {
        setResponse(response.data.message);
        clearset();
        handleOpen();
      })
      .catch((err) => {
        setResponse("Post Request Error");
      });
  };

  return (
        <form className="login_form" 
        style={{marginTop:isMobile?'300px':'200px', width:!isMobile?'35%':'70%' }}
        onSubmit={handleSubmit}>
          <h3>Forgot Password</h3>
          
          <span className="error">{emailError}</span>
          <br/>
          <TextField
            style={{ paddingBottom: "15px", width: "100%" }}
            value={email}
            variant="outlined"
            onChange={(e) => setEmail(e.target.value)}
            type="email"
            required
            label="Email"
          />

          <div className="auth_button">
            <button
              type="submit"
              variant="contained"
              className="button_login"
            >
              Submit
            </button>
          </div>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            {body}
          </Modal>
        </form>
  );
}

export default ForgetPass;
