import React from 'react';
import { Box, Typography, Button } from '@mui/material';

const ApplyStrip = () => {
  return (
    <Box
      sx={{
        background: '#d2cdee',
        width: {md: '100%' , xs: '95%'},
        position: 'fixed',
        bottom: 0,
        display: 'flex',
        flexDirection: { xs: 'row', md: 'row' },
        justifyContent: { md: 'space-evenly', xs: 'center' },
        alignItems: 'center',
        gap: { xs: '20px', md: 0 },
        padding: '10px',
        zIndex: 1000,  // Ensure it appears above other content
      }}
    >
      <Typography variant="h6" sx={{ textAlign: { xs: 'center', md: 'left' } , fontSize: {xs: '14px' , md: '22px'} }}>
        Get Corporate Exposure of Product, Finance & Management
      </Typography>
      <Button
        variant="contained"
        href={"/program/paymentcheckout/6692a1cb002c853cf6110a39/"+window.localStorage.id}
        sx={{ background: '#6a58c6' , fontSize: {xs: '14px' , md: '22px'} , width: {xs: '40%' , md: '20%'}}}
      >
        Enroll Now
      </Button>
    </Box>
  );
};

export default ApplyStrip;
