import React from 'react';
import Certificate from './Images/Certificate.jpg'

const CertificateAndGuidelines = () => {
  return (
    <div
        style={{
            display: "flex",
            flexDirection:'column',
            alignItems:"center",
            width:'100%'
        }}
    >
        <img  
            src={Certificate} 
            alt="certificate" 
            style={{
                width: '80%',
            }}    
            />
            <div style={{ fontSize: '14px' }}>
        <p>
        Please Note:
        </p>
        <ol style={{textAlign:'left'}}>
        <li>Once you submit the form, your request would be validated with our database of whether you have attended the event or not.</li>
        <li>Your request would then be approved by the team. The approval process takes 3-4 days of verifying the request.</li>
        <li>You will receive the certificate within 10 days of submitting this form.</li>
        <li>If not received or to follow up, you can connect at <a style={{color:'#000'}} href="mailto:support@virtualstartupcampus.com">support@virtualstartupcampus.com</a>.</li>
      </ol>
    </div>
    </div>
  )
}

export default CertificateAndGuidelines