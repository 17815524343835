import React, { useState, useEffect } from 'react';
import { Link , useNavigate } from 'react-router-dom';
import Footer from '../Footer/Footer';
import caseStudy from './CaseStudy.png';
import './Projects.css';
import CaseStudyCards from './CaseStudyCards';
import { GET_CASE_STUDIES } from '../../api/casestudy';
import axios from 'axios';
import {Box} from '@mui/material';
import PolicyGenius from '../CaseStudy.jsx/CaseStudyData/PolicyGenius';
import LiveStockSense from '../CaseStudy.jsx/CaseStudyData/LiveStockSense';
import LendEasy from '../CaseStudy.jsx/CaseStudyData/LendEasy';
import PolicyGeniusI from '../CaseStudy.jsx/Photos/2.png';
import LiveStockSenseI from '../CaseStudy.jsx/Photos/3.png';
import LendEasyI from '../CaseStudy.jsx/Photos/4.png';
const Projects = ({ isAuth }) => {
  const navigate = useNavigate();
  const [selectedCaseIndex, setSelectedCaseIndex] = useState(0);
  // const [selectedStep, setSelectedStep] = useState(steps[0]);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [selectedStepIndex, setSelectedStepIndex] = useState(0);
  const [cs , setCs] = useState([]);
  const CaseStudies = [
    {_id: 1 , title: 'Policy Genius', imagePath: PolicyGeniusI , component: <PolicyGenius/>},
    {_id: 2 , title: 'Live Stock Sense',imagePath: LiveStockSenseI, component: <LiveStockSense/>},
    {_id: 3 , title: 'Lend Easy', imagePath:LendEasyI , component: <LendEasy/>}
  ]
  const cases = [
    {
      id: 1,
      title: 'Fitness App',
      description:
        'In this case, you get to plan the strategic approach for product planning for a fitness app thinking like a product Manager',
    },
    {
      id: 2,
      title: 'Vertical Expansion',
      description:
        'In this case, you will explore opportunities for vertical expansion and develop a strategic plan to expand the business',
    },
    {
      id: 3,
      title: 'AgriTech Innovation',
      description:
        'In this case, you will analyze the agriculture technology market and come up with innovative ideas to improve farming practices',
    },
    {
      id: 4,
      title: 'Artificial Intelligence',
      description:
        'In this case, you will explore the applications of artificial intelligence in various industries and develop a plan for implementing AI solutions',
    },
    {
      id: 5,
      title: 'Mobile Economy',
      description:
        'In this case, you will study the mobile economy and its impact on various sectors, and devise strategies to leverage mobile technology for business growth',
    },
    {
      id: 6,
      title: 'Electric Vehicles',
      description:
        'In this case, you will explore the electric vehicle segment and its financial projections',
    },
    {
      id: 7,
      title: 'Fintech Startups',
      description: 'In this case, you will see how financial technology companies are built ',
    },
  ];
  const steps = [
    {
      id: 1,
      title: 'Apply in Teams of 2 to 5',
      description:
        'To encourage group activities and help people develop team working skills, the project is recommended to be taken in teams of 2 to 5. The student could show the certificate (if obtained) as a proof of working in teams',
    },
    {
      id: 2,
      title: 'Pay registration fee per participant',
      description:
        'To participate in the project, each participant is required to pay the registration fee. This fee covers the administrative and operational costs of the project.',
    },
    {
      id: 3,
      title: 'Get the Case Study in your mail IDs',
      description:
        'Once the registration fee is paid, the case study will be sent to the registered email IDs of the participants. Make sure to check your email for the case study details.',
    },
    {
      id: 4,
      title: 'Solve it in group and submit within 1 month',
      description:
        'As a team, work together to analyze and solve the given case study within the given timeframe of 1 month. Collaborate, brainstorm, and apply your skills to come up with innovative solutions.',
    },
    {
      id: 5,
      title: 'Get reviewed and if eligible get your certificate each',
      description:
        'After submitting your solution, it will be reviewed by the project team. If your solution meets the eligibility criteria, you will be awarded a certificate acknowledging your participation and success in the project.',
    },
  ];
  useEffect(() => {
    axios
    .get(GET_CASE_STUDIES)
    .then(res => {
      setCs(res.data.data);  
    })
    .catch(err => {
    })
  },[])

  useEffect(() => {
    const caseInterval = setInterval(() => {
      setSelectedCaseIndex((prevIndex) => (prevIndex + 1) % cases.length);
    }, 5000);

    const stepInterval = setInterval(() => {
      setSelectedStepIndex((prevIndex) => (prevIndex + 1) % steps.length);
    }, 2000);

    return () => {
      clearInterval(caseInterval);
      clearInterval(stepInterval);
    };
  }, [cases.length, steps.length]);


  const handleCaseClick = (index) => {
    setSelectedCaseIndex(index);
  };
  const selectedCase = cases[selectedCaseIndex];

  const handleStepClick = (index) => {
    setSelectedStepIndex(index);
  };
    const selectedStep = steps[selectedStepIndex];

  if(isAuth){
    return navigate('/projectLogin');
  }
  return (
    <>
    <div className="Maincontainer">
      <div className="container">
        <div className="left-section">
          <h1 className="left-real-time">Real Time</h1>
          <h1 className="left-real-time-2">Startup Cases</h1>
          <h1 className="left-real-time-4">and Projects</h1>
          <p className="left-real-time-3">
            Nominate and Braisntorm in a team of 3-5 friends and get
          </p>
          <p className="left-real-time-3">certified for team work and critical thinking</p>
          {/* <Link exact="true" to={isAuth ? '/projectLogin' : '/'}> */}
            <button 
            onClick ={(e) => {
              isAuth ? navigate('/projectLogin') : navigate('/login' , {state:{redirect:'/projectLogin'}})
            }} className="container-button">Participate</button>
          {/* </Link> */}
        </div>
        <div className="right-section">
            <div className="box-right-1">
              <h1>Case Study</h1>
              <h3>Trending Stratups</h3>
              <h3>Industry Case Study</h3>
              <div className="number">15+</div>
            </div>
            <div className="box-right-2">
              <h1>Community</h1>
              <h1>Growth</h1>
              <div className="number">56%</div>
            </div>
            <div className="box-right-3">
              <h2>Real Time Project</h2>
              <h2>Participate</h2>
              <h4>Get the acknowldegement for the particpation in teams</h4>
            </div>
          </div>
      </div>
      <div className="startup-container">

        <div className='startup-out'>
        <div className="startup-cases">
          <div className="startup-boxes">
            <h1 className="startup-h1">STARTUP</h1>
            <h1 className="startup-h1">Cases</h1>
          </div>
          <div className="startup-boxes">
            <h2>15+</h2>
            <h2>Case Study</h2>
          </div>
          <div className="startup-boxes">
            <h2>25+</h2>
            <h2>Projects to Work On</h2>
          </div>
          <div className="startup-boxes">
            <h2>50+</h2>
            <h2>Teams Working On</h2>
          </div>
          </div>
          <div style={{width:'100%' , marginTop:'30px' , height:'100%'}}>
           <CaseStudyCards CaseStudies={CaseStudies} handleCaseClick={handleCaseClick} isAuth={isAuth} from={`top`}/>
          </div>
        </div>
      
      </div>
      <div className="centered-content">
        <h1>Startup Case Studies</h1>
        <p>Now read about startups and strategies from</p>
        <p>various startup case studies</p>
      </div>
      <div className="box-cases">
        {cases.map((caseItem, index) => (
          <div
            className={`boxes-${index + 1} ${selectedCaseIndex === index ? 'selected' : ''}`}
            onClick={() => handleCaseClick(index)}
            key={caseItem.id}
          >
            {caseItem.title}
          </div>
        ))}
      </div>

      <div className={`startup-container ${isTransitioning ? 'transitioning' : ''}`}>
        <div className={`cases-bar ${isTransitioning ? 'hidden' : ''}`}>
          <div className="cases-box">
            <h1>{selectedCase.title}</h1>
            <p>{selectedCase.description}</p>
          </div>
        </div>
      </div>
      <div className="cases-button">
        <Link exact="true" to={isAuth ? '/projectLogin' : '/signup'}>
          <button>Read More</button>
        </Link>
      </div>
      <div className="centered-content">
        <h1>Real Time Startup Project Case</h1>
      </div>
      <div className="project-real-time">
        <div className="project-cases">
          <div className="project-window">
            <div className="window-video">
              {/* <iframe
                className="window-video"
                src="https://www.youtube.com/embed/MfbK5NncBgU"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe> */}
              <img  
                src={caseStudy}
                style={{width:'100%'}}
              />
            </div>
            <div className="window-button">
              <Link exact="true" to="/signup">
                <button>Participate</button>
              </Link>
            </div>
          </div>
          <div className="project-buttons">
            {steps.map((step) => (
              <button
                key={step.id}
                className={`project-button ${selectedStep === step ? 'selected' : ''}`}
                onClick={() => handleStepClick(step)}
              >
                {step.title}
              </button>
            ))}
          </div>
        </div>
      </div>

      <div className="box-steps">
      {steps.map((step, index) => (
          <div
            key={step.id}
            className={`boxes-screen ${selectedStepIndex === index ? 'selected-step' : ''}`}
            onClick={() => handleStepClick(index)}
          >
            {`Step-${step.id}`}
          </div>
        ))}
      </div>

      <div className="startup-container">
        <div className="cases-bar">
          <div className="cases-box">
            <h1>{selectedStep.title}</h1>
            <p>{selectedStep.description}</p>
          </div>
        </div>
      </div>
      <div className="cases-button">
        {/* <Link exact="true" to={isAuth ? '/projectLogin' : '/signup'}> */}
          <button
          onClick={() => {
            isAuth?navigate('/projectLogin'):navigate('/login' , {state:{redirect:'/projects'}} )
          }}
          >Read More</button>
        {/* </Link> */}
      </div>
      <br />
      <br />
      {cs.length > 0 && (
        <Box>
          <CaseStudyCards CaseStudies={cs} isAuth={isAuth} from={`bottom`}/>
        </Box>
      )}
    </div>
    <Footer/>
    </>
  );
};

export default Projects;
