const { BASE_URL } = require("./client");

const POST_LAB = BASE_URL+'/api/postlab';
const GET_LAB = BASE_URL +"/api/labs";
const SET_COMPLETED_LAB = BASE_URL +"/api/setcompletedlab/";

module.exports = {
    POST_LAB,
    GET_LAB,
    SET_COMPLETED_LAB
}