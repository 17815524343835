import React from 'react';
import { Card, CardContent, Typography, Button, Box } from '@mui/material';
import SchoolIcon from '@mui/icons-material/School';
import WorkIcon from '@mui/icons-material/Work';
import BuildIcon from '@mui/icons-material/Build';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import pic from './Images/item1.png';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import ContentPasteGoIcon from '@mui/icons-material/ContentPasteGo';
const ManagementConsultingCard = ({heading , tagline , scholar , problem , benefits , outcome , link , image}) => {
  const textSize = {
    fontSize: 20,
    fontWeight: 800
  }
  const iconColor={
    color: 'grey'
  }
  return (
    <Card style={{ width: 450, borderRadius: 15 , position: 'relative' , height: '100%' ,boxShadow: 1 , border: '1px solid #E2E8F0' }}>
      <Box style={{ backgroundColor: '#6a58c6', padding: '20px', textAlign: 'center' , height: 80  , }}>
      </Box>
      <Box sx={{borderRadius: '50%' , position: 'absolute' , right: '37%' , top: '10%' , backgroundColor: '#fff' , height: 125 , display: 'flex' , alignItems: 'center' , width: 130, justifyContent: 'center'}}>
      <img src={image} alt="Passport" style={{ height: '120px' ,borderRadius: '50%' , width: 125 }} />
      </Box>
      <CardContent sx={{marginTop:'50px'}}>
        <Typography variant="h5" style={{ fontWeight: 'bold', marginTop: '15px' , textAlign: 'center' , fontSize: '30px'}}>
         {heading}
        </Typography>
        <Typography variant="h6" style={{ margin: '15px 0' , textAlign: 'center' , fontSize: '20px' }}>
          {tagline}
        </Typography>
        <Box style={{ display: 'flex', alignItems: 'center', marginBottom: '15px',...textSize }}>
          <ManageAccountsIcon style={{ marginRight: '8px' , ...iconColor }} />
          <Typography variant="body2" fontSize={'20px'} fontWeight={500}>{scholar}</Typography>
        </Box>
        <Box style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' ,...textSize}}>
          <ManageAccountsIcon style={{ marginRight: '8px', ...iconColor }} />
          <Typography variant="body2" fontSize={'20px'} fontWeight={500}>
            {problem ==="Product Management | Experience working in Product Role" ? <>Product Management | Experience working in Product Role</> :problem}
          </Typography>
        </Box>
        <Box style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
          <WorkOutlineIcon style={{ marginRight: '8px', ...iconColor }} />
          <Typography variant="body2" fontSize={'20px'} fontWeight={500}>{benefits === "Benefits : Build, Market & Ship" ? <>Benefits : Build, Market & Ship</> : benefits}</Typography>
        </Box>
        <Box style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' , ...textSize }}>
          <ContentPasteGoIcon style={{ marginRight: '8px' , ...iconColor}} />
          <Typography variant="body2" fontSize={'20px'} fontWeight={500} display="flex" alignItems="center" gap={2} >
            Outcome: <Typography style={{ color: '#6a58c6', textDecoration: 'none' ,fontWeight:800 , fontSize: 20 }}>{outcome}</Typography>
          </Typography>
        </Box>
        
      </CardContent>
      <Box onClick={(e) => window.location.pathname = link} variant="contained" style={{ backgroundColor: '#6a58c6', color: 'white' , width: '100%' , height: 70 , alignItems: 'center' , justifyContent: 'center' , display:'flex' , cursor: 'pointer' ,  }}>
          <Typography variant='h6' sx={{alignItems: 'center' , justifyContent: 'center' , display:'flex'}} alignItems={'center'} textAlign={'center'} height={50} >View Course</Typography>
        </Box>
    </Card>
  );
}; 

export default ManagementConsultingCard;
