import React, { useState, useEffect } from 'react';
import Navbar from './Navbar'
import { Grid, Button, Typography, Box, TextField, Container , Tooltip , IconButton } from '@mui/material'
import LinkIcon from '@mui/icons-material/Link';
import MobileBox from './MobileBox'
import { useTheme, useMediaQuery } from '@mui/material';
import axios from 'axios';
import DownloadIcon from '@mui/icons-material/Download';
import { GET_LAB, SET_COMPLETED_LAB } from '../../api/lab';
import { useParams } from 'react-router-dom';  // Import useParams
import { Link } from 'react-router-dom';
import FolderCopyIcon from '@mui/icons-material/FolderCopy';
import Inbox from "./Inbox"
import RenderMultilineText from './RenderMultilineText';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const Lab = ({labData , completedLabs , setRef}) => {
    const [activeButton, setActiveButton] = useState('tasks');
    const { id } = useParams();
    const [buttonColors, setButtonColors] = useState(['silver', 'silver', 'silver']); // Initial state
    const handleButtonClick = (index) => {
        const updatedColors = buttonColors.map((color, i) => (i === index ? 'black' : 'silver'));
        setButtonColors(updatedColors);
    };
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [selectedLabHeading, setSelectedLabHeading] = useState(null);
    const [selectedDescription, setSelectedDescription] = useState(null);
    const [step , setStep] = useState("");
    const [path1, setPath1] = useState(null);
    const [path2, setPath2] = useState(null);
    const [path3, setPath3] = useState(null);
    const handleStep1Click1 = (index) => {
        handleButtonClick(index);
        setStep(labData.steps[index]);
        setSelectedLabHeading(labData.labHeadings[index]);
        setSelectedDescription(labData.descriptions[index]);
        setPath1(labData.filePaths[0]);
        setPath2(labData.filePath);
        setPath3(labData.filePath3);
    }
    const handleCompleted = () => {
        axios
        .post(SET_COMPLETED_LAB+labData._id+`/${window.localStorage.id}`, {step: step})
        .then((res) => {
            setRef(true);
        })
        .catch((err) => {
            console.log(err);
        })
    }
    return (
        <div style={{width: '100%'}}>
            <Navbar link={labData.link}/>
            <Grid container spacing={18}>
                <Grid item sm={1.5}>
                    <Box borderBottom={activeButton === 'inbox' ? 2 : 0}>
                        <Button onClick={() => setActiveButton('inbox')}>
                            <Typography style={{ color: 'black', fontSize: '20px', textTransform: 'none' }}>Inbox</Typography>
                        </Button>
                    </Box>
                </Grid>
                <Grid item sm={1.5}>
                    <Box borderBottom={activeButton === 'tasks' ? 2 : 0}>
                        <Button onClick={() => setActiveButton('tasks')}>
                            <Typography style={{ color: 'black', fontSize: '20px', textTransform: 'none' }}>Tasks</Typography>
                        </Button>
                    </Box>
                </Grid>
            </Grid>
            {activeButton === 'tasks' ? (
                isSmallScreen ? (
                    <MobileBox Step1={labData.Step1} Step2={labData.Step12} Step3={labData.Step13} />
                ) : (
                    <Grid container>
                        {isSmallScreen ? (null) : (
                            <Grid item sm={2.6}>
                                <Box
                                    sx={{
                                        borderWidth: "0px 4px 0px 0px",
                                        // borderStyle: "solid",
                                        // borderColor: "black",
                                        width: "100%",
                                        height: '90vh',
                                        boxShadow: '5'
                                    }}>
                                    <Grid container>
                                        <Grid item sm={0.5}></Grid>
                                        <Grid item sm={11.5}>
                                            <Typography>{completedLabs.filter((lab) => lab.labId === labData._id).length}of {labData.labHeadings.length} completed</Typography>
                                        </Grid>
                                        {labData.steps.map((step , i) => (
                                            <Grid container sx={{ marginBottom: '10px' , display:'flex' ,  flexDirection:'row' , alignItems:'center'}}>
                                            <Grid item sm={0.5}></Grid>
                                            <Grid item sm={1} style={{ marginTop: '10px' }}>
                                                <Typography
                                                    style={{
                                                        borderRadius: '50%',
                                                        border: '1px solid silver',
                                                        display: 'inline-block',
                                                        width: '20px',
                                                        height: '22px',
                                                        color: buttonColors[i],
                                                        textAlign:'center',
                                                    }}
                                                >
                                                    {i+1}
                                                </Typography>
                                            </Grid>
                                            <Grid item sm={10.5}>
                                                <Button
                                                    onClick={(e)=>handleStep1Click1(i)}
                                                    disableRipple
                                                    sx={{
                                                        '&:focus': {
                                                            outline: 'none',
                                                        },
                                                        color: buttonColors[0],
                                                    }}
                                                >
                                                    <Typography
                                                        style={{
                                                            textTransform: 'none',
                                                            textAlign: 'left',
                                                            color: buttonColors[i],
                                                            cursor: 'pointer',
                                                            marginTop: '6px',
                                                            marginLeft: '14px',
                                                        }}
                                                    >
                                                        {step}
                                                    </Typography>
                                                </Button>
                                            </Grid>
                                        </Grid>
                                        ))}
                                    </Grid>
                                </Box>
                            </Grid>
                        )}


                        <Grid item sm={6} style={{ marginTop: "20px" }}>
    <Container>
        <Typography>
            {selectedLabHeading}
        </Typography>
        <RenderMultilineText text={selectedDescription} />
    </Container>
</Grid>


                        <Grid item sm={3.0} style={{ marginTop: "20px" }}>
                            <Container>
                                <Typography style={{ fontWeight: "800", fontSize: "30px" }}>
                                    <FolderCopyIcon /> Resources
                                    <hr style={{ border: "1px solid silver" }} />
                                    {labData.filePaths.map((path, index) => (
                                        <Button
                                            key={index}
                                            component={Link}
                                            to={path}
                                            style={{ marginRight: "10px" }}
                                        >
                                            <DownloadIcon />
                                        </Button>
                                        ))}

                                </Typography>
                                <Typography style={{ fontWeight: "700", fontSize: "18px" }}>
                                    Documentation
                                </Typography>
                                <Button startIcon={<LinkIcon />}>
                                    Installing Helm
                                </Button>
                                <br />
                                
                                <br />
                                <br />
                                <TextField
                                    multiline
                                    rows={4}

                                    sx={{ width: "100%" }}
                                    label="Ask Doubt"
                                />


                                <Grid container justifyContent="flex-end" flexDirection={'column'} gap={'10px'}>
                                    <Button
                                        sx={{
                                            border: "2px solid black",
                                            marginTop: "10px",
                                            backgroundColor: "black",
                                            '&:hover': {
                                                backgroundColor: 'silver',
                                            },
                                        }}
                                    >
                                        <Typography style={{ cursor: "pointer", color: "white" }}>Send doubt</Typography>
                                    </Button>
                                    {completedLabs.find((lab) => lab.step === step) ? (
                                        <Tooltip title="Completed" >
                                                    <IconButton>
                                                    <CheckCircleOutlineIcon style={{color: '#00ff00'}} fontSize="large"/>
                                                    </IconButton>
                                                    </Tooltip>
                                    ): (
                                        <Button
                                    sx={{
                                        border: "2px solid black", marginTop: "10px", backgroundColor: "black", '&:hover': {
                                            backgroundColor: 'silver',
                                        },
                                    }}
                                    disabled={completedLabs.find((lab) => lab.step === step)}
                                    onClick={handleCompleted}
                                    >
                                    
                                    <Typography style={{ cursor: "pointer", color: "white", }}>Mark as completed</Typography>

                                </Button>
                                    )}
                                    
                                </Grid>
                                <br />
                            </Container>
                        </Grid>
                    </Grid>
                )) : (
                <Inbox />
            )}


        </div>


    )

}

export default Lab