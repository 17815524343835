import React from 'react';
import { Box , Typography} from '@mui/material';
import { keyframes } from '@emotion/react';
import wework from '../Images/wework.jpg';
import razorpay from '../Images/razorpay.png';
import juspay from '../Images/juspay.png';
import uc from '../Images/uc.jpg';
import ti from '../Images/ti.avif';
import tata from '../Images/tata.webp';
import lenskart from '../Images/Lenskart.png';
import myntra from '../Images/myntra.png';
import navi from '../Images/Navi.png';
import glance from '../Images/glance.jpg';
import american from '../Images/American.png';
import groww from '../Images/groww.jpg';
import bcg from '../Images/BGG.png';
import healthify from '../Images/healthifyme.webp';
import crisil from '../Images/crisil.avif';
import adobe from '../Images/Adobe.png';
import ff from '../Images/ff.png';
import upstox from '../Images/upstox.png';


const images1 = [
  wework, razorpay, juspay, uc, ti, tata, lenskart, myntra, navi
];
const images2 = [
    glance , american , groww , bcg , healthify , crisil , adobe , ff , upstox
]

const imagesTop = [...images1, ...images1 , ...images1];
const imagesBottom = [...images2, ...images2 , ...images2];

const slideLeft = keyframes`
  0% { transform: translateX(0); }
  100% { transform: translateX(-10%); }
`;

const slideRight = keyframes`
  0% { transform: translateX(0); }
  100% { transform: translateX(10%); }
`;

const ImageSlider = () => {
  return (
    <Box
        sx={{
            width: '100%' , 
            display: 'flex' , 
            justifyContent: 'center' , 
            alignItems: 'center' ,
            flexDirection:'column',
            overflow: 'hidden'
        }}
    >
    <Typography variant="h4" align="center" sx={{mb: 6 , width: {xs: '100%' , md: '60%'} }}>
            <span style={{color: "#6a58c6" , fontWeight: 600}}>Corporate Trainings</span> Designed based on <br/>Internships and Jobs in  <span style={{color: "#6a58c6" , fontWeight: 600}}>1500+ Orgs</span>
        </Typography>
    <Box sx={{ overflow: 'hidden', whiteSpace: 'nowrap', position: 'relative', height: '230px' , }}>
       
      <Box sx={{
        display: 'flex',
        animation: `${slideLeft} 30s linear infinite`,
        alignItems: 'center',
        gap: '20px',
        marginBottom: '40px'
      }}>
        {imagesTop.map((image, index) => (
          <Box
            key={index}
            component="img"
            src={image}
            alt={`slide-top-${index}`}
            sx={{ width: 150, height: 100, flexShrink: 0, boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px', borderRadius: 3 }}
          />
        ))}
      </Box>
      <Box sx={{
        display: 'flex',
        animation: `${slideRight} 30s linear infinite`,
        alignItems: 'center',
        gap: '20px',
        position: 'absolute',
        top: '120px', 
      }}>
        {imagesBottom.map((image, index) => (
          <Box
            key={index + imagesTop.length}
            component="img"
            src={image}
            alt={`slide-bottom-${index}`}
            sx={{  width: 150, height: 100, flexShrink: 0, boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px', borderRadius: 3 }}
          />
        ))}
      </Box>
    </Box>
    </Box>
  );
};

export default ImageSlider;
