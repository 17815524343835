import { React, useState, useEffect } from 'react';
import { Box, Typography, Button , TextField , IconButton } from '@mui/material';
import { useMediaQuery } from '@mui/material';
import axios from 'axios';
import { USER_REFFERAL } from '../../api/vsc';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LockIcon from '@mui/icons-material/Lock';
import { GET_REDEEM , REDEEM_POINTS } from '../../api/redeem';
import Media from './Media'
import { FileCopy } from '@mui/icons-material';
import Rules from './Rules';

const Redeem = () => {
    const [points, setMarkoknowPoints] = useState();
    const [userRewards, setuserRewards] = useState([]);
    const isSmallScreen = useMediaQuery('(max-width:600px)');
    const isMediumScreen = useMediaQuery('(min-width: 601px) and (max-width: 900px)');
    const isLargeScreen = useMediaQuery('(min-width:901px)');
    const [data, setData] = useState([]);
    const [refresh , setRefresh] = useState(false);
    const colors = [ "#5E17EB" , "#251E4F" , "#6a58c6" ];
    const [selected , setSelected] = useState('wallet');

    useEffect(() => {
        axios
            .get(USER_REFFERAL + window.localStorage.id)
            .then(resp => {
                setMarkoknowPoints(resp.data.data.markoknowPoints);
                axios
                .get(GET_REDEEM)
                .then(res => {
                    const redeemedIds = resp.data.data.redeems.map(redeem => redeem._id);
                        const filteredBlogs = res.data.filter(blog => !redeemedIds.includes(blog._id));
                        
                setData(filteredBlogs);
                })
                .catch(err => {
                console.log(err);
                })
                setuserRewards(resp.data.data.redeems.reverse());
            })
            .catch(err => {
                console.log(err);
            })
            
            setRefresh(false);
    }, [refresh]);
    const [showReward, setShowReward] = useState(0);
    const [ThisMonthButtonColor, setThisMonthButtonColor] = useState("#6a58c6");
    const [ThisMonthButtonTextnColor, setThisMonthButtonTextColor] = useState("white");
    const [AllTimeButtonColor, setAllTimeButtonColor] = useState("white");
    const [AllTimeButtonTextColor, setAllTimeButtonTextColor] = useState("black");
    const [copied, setCopied] = useState(false); // State to track whether code is copied or not

    const handleCopyToClipboard = (text) => {
        navigator.clipboard.writeText(text)
            .then(() => {
                setCopied(true);
                alert("Copied to clipboard")
                setTimeout(() => setCopied(false), 3000); // Reset copied state after 3 seconds
            })
            .catch((error) => console.error('Unable to copy to clipboard: ', error));
    };
    const redeem = (item) => {
        axios
        .post(REDEEM_POINTS+window.localStorage.id , item)
        .then(res => {
            setRefresh(true);
        })
        .catch(err => {
            console.log(err);
        })
      }
    return (
        <div >
            {!isSmallScreen && (
                <div
                    style={{position: 'relative'}}
                >
                    <Box
                        sx={{
                            width: '100%',
                            height: 'auto',
                            display:'flex' , 
                            justifyContent:'center',
                            alignItems:'center',
                            padding: '20px' , 
                            borderRadius:'20px',
                            
                        }}
                    >
                        <Typography
                            style={{
                                paddingTop: '2%',
                                paddingBottom: '2%',
                                textAlign: 'center',
                                    display:'flex' , 
                                justifyContent:'center',
                                alignItems:'center',
                                padding: '20px' , 
                                borderRadius:'20px',
                                flexDirection:'column',
                                boxShadow: '#6a58c6 0px 3px 8px',
                                fontFamily:'Poppins' , marginBottom:'10px' , fontWeight:'900'
                            }}
                        >
                            <span style={{ fontSize: '20px', marginLeft: '6%' }}>Your Points <br /></span>
                            <span style={{ fontSize: '60px' }}>{points} points</span>
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            position: 'absolute',
                            top: 0,
                            right: 0
                        }}
                    >   
                        <Rules />
                    </Box>
                    <Typography variant='h3' style={{ textAlign: 'center', marginTop: '30px' ,fontFamily:'Poppins' , marginBottom:'10px' , fontWeight:'900'}}>
                        Turn points into reward discounts
                    </Typography>
                    <Box sx={{marginTop: '50px' , display: 'flex' , justifyContent: 'center' , alignItems:'center'}}>
                        <Box 
                                sx={{
                                    width: '100%',
                                    backgroundColor: 'white',
                                    padding: '10px',
                                }}
                            >
                                <Media props="Redeem Wallet"  points={points}  setRefre={setRefresh}/>
                            
                        </Box>
                    </Box>
                    <Box sx={{marginTop: '50px' , display: 'flex' , justifyContent: 'center' , alignItems:'center'}}>
                        <Box 
                                sx={{
                                    width: '100%',
                                    backgroundColor: 'white',
                                    padding: '10px',
                                }}
                            >
                                <Media props="Redeem History" refre={refresh} setRefre={setRefresh}/>
                        </Box>
                    </Box>
                </div>

            )}
            {isSmallScreen && (
                <div style={{ marginTop: "40px" , display:'flex' , flexDirection:'column' , justifyContent:'center' , alignItems:'center' }}>
                <Box
                        sx={{
                            marginBottom:'20px'
                        }}
                    >   
                        <Rules />
                    </Box>
                <Box sx={{
                                paddingTop: '2%',
                                paddingBottom: '2%',
                                textAlign: 'center',
                                    display:'flex' , 
                                justifyContent:'center',
                                alignItems:'center',
                                padding: '20px' , 
                                borderRadius:'20px',
                                flexDirection:'column',
                                boxShadow: '#6a58c6 0px 3px 8px',
                                fontFamily:'Poppins' , marginBottom:'10px' , fontWeight:'900',
                                width: '80%'
                }}>
                    <Typography variant='h6' style={{ alignItems: 'center', textAlign: 'center' }}>
                        Your balance
                    </Typography>
                    <Typography variant='h4' style={{ alignItems: 'center', textAlign: 'center' }}>
                        <span style={{ fontWeight: "700" }}>{points}</span> points
                    </Typography>
                </Box>
                    
                    <div item xs={12} sm={3} style={{marginTop: '50px',display:'flex' , flexDirection:'row' , justifyContent:'center' ,alignItems:'center' }} >
                    <Box
                  sx={{
                    backgroundColor: "#6a58c6",
                    width: isSmallScreen ? '100%' : '80%',
                    height: isSmallScreen ? '8vh' : '5vh',
                    borderRadius: "20px",
                    display:'flex' , 
                    flexDirection:'row' , 
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Button
                    sx={{
                      backgroundColor: ThisMonthButtonColor,
                      height: isSmallScreen ? '7vh' : '4.6vh',
                      width: isSmallScreen ? '50%' : '7vw',
                      '&:hover': {
                        backgroundColor: ThisMonthButtonColor
                      },
                      borderRadius: '20px'
                    }}
                    onClick={() => {
                      if (ThisMonthButtonColor === "#6a58c6") {
                        setAllTimeButtonColor("#6a58c6");
                        setThisMonthButtonColor("white")
                        setAllTimeButtonTextColor("white");
                        setThisMonthButtonTextColor("black");
                        setSelected('wallet');
                        setRefresh(true);
                      }

                    }}
                  >
                    <Typography sx={{ textTransform: "none", color: ThisMonthButtonTextnColor, cursor: "pointer" }}>
                      Redeem 
                    </Typography>
                  </Button>
                  <Button
                    sx={{
                      backgroundColor: AllTimeButtonColor,
                      height: isSmallScreen ? '7vh' : '4.6vh',
                      width: isSmallScreen ? '50%' : '7vw',
                      '&:hover': {
                        backgroundColor: AllTimeButtonColor
                      }
                      ,borderRadius: '20px'
                    }}
                    onClick={() => {
                      if (AllTimeButtonColor === "#6a58c6") {
                        setAllTimeButtonColor("white");
                        setThisMonthButtonColor("#6a58c6");
                        setAllTimeButtonTextColor("black");
                        setThisMonthButtonTextColor("white");
                        setSelected('overall');
                        setRefresh(true);
                      }

                    }}
                  >
                    <Typography sx={{
                      textTransform: "none",
                      cursor: "pointer",
                      color: AllTimeButtonTextColor,
                    }}>
                      Redeem Wallet
                    </Typography>
                  </Button>
                </Box>
                    </div>
                    {selected === 'wallet' ? (
                        <div>
                            <Typography style={{ alignItems: 'center', textAlign: 'center', marginTop: '20px', color: '#b4b4b4' }}>
                                Redeem coupons towards your next purchase
                            </Typography>
                            {data.map((item, index) => (
                                <Box
                                    sx={{
                                        marginTop: "20px",
                                        width: '98%',
                                        height: '150px',
                                        backgroundColor: 'white',
                                        boxShadow: '3',
                                        borderRadius: '20px',

                                    }}
                                >
                                    <Box
                                        sx={{
                                            width: '100%',
                                            height: '90px',
                                            backgroundColor: colors[index % colors.length],
                                            boxShadow: '3',
                                            borderRadius: '20px 20px 20px 20px',
                                            display:'flex', 
                                            flexDirection: 'column' , 
                                            justifyContent:'center' , 
                                            alignItems: 'center',
                                        }}
                                    >
                                      <Typography variant='caption' style={{ alignItems: 'center', textAlign: 'center', color: 'white' }}>
                                      {item.requiredOffer}
                                        </Typography>
                                        <Typography variant='h6' style={{ alignItems: 'center', textAlign: 'center', color: 'white' }}>
                                            <span style={{ fontSize: "30px", fontWeight: "700" }}>{item.percentageDiscount}% OFF</span> 
                                        </Typography>
                                    </Box>
                                    <Box sx={{padding: '10px' ,   display:'flex' , flexDirection:'row' , justifyContent:  'space-between' , alignItems: 'center'}}>
                                             <Button 
                                                    variant="outlines" 
                                                    disabled={points < item.requiredPoints} 
                                                    sx={{border: '1px solid #6a58c6'}}
                                                    onClick={(e) => {
                                                        redeem(item);
                                                    }}
                                                    >
                                                    Redeem
                                                </Button>
                                                <Typography>With {item.requiredPoints} Points</Typography>
                                            </Box>

                                </Box>
                            ))}
                        </div>
                    ): (
                        <>
                            {userRewards.map((item, index) => (
                                <Box
                                    sx={{
                                        marginTop: "20px",
                                        width: '98%',
                                        height: '150px',
                                        backgroundColor: 'white',
                                        boxShadow: '3',
                                        borderRadius: '20px',

                                    }}
                                >
                                    <Box
                                        sx={{
                                            width: '100%',
                                            height: '90px',
                                            backgroundColor: colors[index % colors.length],
                                            boxShadow: '3',
                                            borderRadius: '20px 20px 20px 20px',
                                            display:'flex', 
                                            flexDirection: 'column' , 
                                            justifyContent:'center' , 
                                            alignItems: 'center',
                                        }}
                                    >
                                      <Typography variant='caption' style={{ alignItems: 'center', textAlign: 'center', color: 'white' }}>
                                      {item.requiredOffer}
                                        </Typography>
                                        <Typography variant='h6' style={{ alignItems: 'center', textAlign: 'center', color: 'white' }}>
                                            <span style={{ fontSize: "30px", fontWeight: "700" }}>{item.percentageDiscount}% OFF</span> 
                                        </Typography>
                                    </Box>
                                    <Box sx={{padding: '10px' ,   display:'flex' , flexDirection:'column' , justifyContent:  'center' , alignItems: 'center'}}>
                                    {item.code && <input
                                        value={item.code}
                                        style={{
                                            borderRadius:'20px',
                                            width: 100,
                                            height: 20,
                                            fontSize: '10px',
                                            background: '#f0f0f0',
                                            border: 0,
                                            textAlign:'center',
                                            fontWeight:'800',
                                            fontFamily:'Poppins'
                                        }}
                                        onClick={() => handleCopyToClipboard(item.code)}
                                    />}
                                                <Typography sx={{fontFamily:'Poppins'}}>With {item.requiredPoints} Points</Typography>
                                            </Box>

                                </Box>
                            ))}

                        </>
                    )}

                </div>
            )}


        </div>
    );
};

export default Redeem;
