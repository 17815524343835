import React , {useState , useEffect} from 'react'
import { Box,Typography } from "@material-ui/core";
import { useMediaQuery } from '@mui/material';
import WatchnowCarousel from './WatchnowCarousel';
import { GET_BLOGS } from '../../../api/blog';
import axios from 'axios';

const WatchnowDash = () => {
    const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(false);
  const isMobile = useMediaQuery('(max-width: 800px)');
  const isSmallMobile = useMediaQuery('(max-width: 410px)');
  const isGreaterMobile = useMediaQuery('(max-width: 500px)');
  return (
    <div style={{marginTop:'50px' , width:'100%' }}>
      <Box display="flex" justifyContent="center">
            <Typography variant="h4" fontWeight="800" style={{color:'#000'}}>
              Watchnow
            </Typography>
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            marginTop="10px"
            textAlign="center"
          >
            <Typography variant="h6" style={{color:'#000'}}>
              Explore and learn from diverse, relevant videos to fuel your growth.
            </Typography>
          </Box>
          <Box
            style={{marginTop:'30px' , width:'100%' }}
          >
            <WatchnowCarousel />
          </Box>
          
      </div>
  )
}

export default WatchnowDash