import React, { useState, useEffect } from "react";
import { Box,Typography } from "@material-ui/core";
import {makeStyles} from '@mui/styles';
import axios from "axios";
import { CircularProgress } from "@mui/material";
import { Link} from "react-router-dom";
import { useMediaQuery } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { Button, CardActionArea, CardActions } from '@mui/material';
import dummy from './CaseStudy.png';
import EcoBuild from './CaseComponent';
import EcoThread from './CaseStudies/EcoThread';
import FinEase from './CaseStudies/FinEase';
import MoneyWise from './CaseStudies/MoneyWise';
import NutriFit from './CaseStudies/NutriFit';
import WorkConnect from './CaseStudies/WorkConnect';
import ShieldSafe from './CaseStudies/ShieldSafe';
import AgroTechGreens from './CaseStudies/AgroTechGreens';
import LivestockSense from './CaseStudies/LivestockSense';
import PolicyGenius from './CaseStudies/PolicyGenius';
import LendEasy from './CaseStudies/LendEasy';
import CreditEase from './CaseStudies/CreditEase';
import FleetCharge from './CaseStudies/FleetCharge';
import ChargeX from './CaseStudies/ChargeX';
import { GET_CASE_STUDIES } from "../../api/casestudy";
import { USER } from "../../api/vsc";




const CaseStudy = () => {
  const classes = u();
  const [caseStudies, setCaseStudies] = useState([]);
  const [loading, setLoading] = useState(false);
  const isMobile = useMediaQuery('(max-width: 600px)');
  const [completedCaseStudies, setCompletedCaseStudies] = useState([]);
  const isSmallMobile = useMediaQuery('(max-width: 410px)');
  const isGreaterMobile = useMediaQuery('(max-width: 500px)');
  useEffect(() => {
    axios
    .get(USER, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
    .then((response) => {
      if (response.data.completedCaseStudy) {
        setCompletedCaseStudies(response.data.completedCaseStudy);
      }
    })
    .catch((error) => {
      console.error(error);
    });
  },[])
  useEffect(() => {
    setLoading(true);
    axios
    .get(GET_CASE_STUDIES)
    .then(res => {
      setCaseStudies(res.data.data);
      setLoading(false);
    })
    .catch(err => {
    })
  },[])
  return (
    <div className={classes.body}>
      {loading ? (
        <CircularProgress style={loader} size={45} />
      ) : (
        <div className={classes.container}>
          <Box display="flex" justifyContent="center">
            <Typography variant="h4" fontWeight="800" style={{color:'#000'}}>
              Case Studies
            </Typography>
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            marginTop="10px"
            textAlign="center"
          >
            <Typography variant="h6" style={{color:'#000'}}>
            Unlock the success blueprints of startups through compelling case studies.
            </Typography>
          </Box>
          <Box
        display="flex"
        justifyContent="center"
        flexDirection={isMobile ? "column" : "row"}
        alignItems="center"
        flexWrap="wrap"
      >
        {caseStudies.map((c) => {
          const isCompleted = completedCaseStudies.includes(c._id);
          return (
            <Box className={classes.box}>
              <Card sx={{
              //  height: isGreaterMobile?(isSmallMobile?'450px':'500px'):'530px',
                width: '500px',
                height:'400px',
                boxShadow: 'rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px'
              }}>
                <div >
                  <CardMedia
                    component="img"
                    // width="100%"
                    width = "400"
                    height= "200"
                    image={c.casestudy}
                    // image={dummy}
                    alt="..."
                  />
                </div>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    {/* Limit the title text to 2 lines */}
                    <Box
                      component="div"
                      overflow="hidden"
                      textOverflow="ellipsis"
                      style={{
                        display: '-webkit-box',
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: 'vertical',
                        // Adjust the font size as needed
                        fontSize: '1rem',
                        fontWeight: 'bold',
                        lineHeight: '1.2',
                        textAlign:'center'
                      }}
                    >
                      {c.title}
                    </Box>
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    <Box
                      component="div"
                      overflow="hidden"
                      textOverflow="ellipsis"
                      style={{
                        display: '-webkit-box',
                        WebkitLineClamp: 4,
                        WebkitBoxOrient: 'vertical',
                        // Adjust the font size as needed
                        fontSize: '1rem',
                        lineHeight: '1.2',
                      }}
                    >
                      {c.subTitle.substring(0,100)+`...`}
                    </Box>
                  </Typography>
                </CardContent>
      <CardActions style={{display:'flex' , justifyContent:'flex-end'}}>
          <Link
                        to={`/casestudy/${c._id}`}
                        style={{ textDecoration: "none" }}
                      >
                        <Typography
                          style={{
                            color: "white",
                            fontSize: "15px",
                            padding: "5px",
                            borderRadius: "5px",
                            cursor: "pointer",
                            backdropFilter: "blur(10px)",
                            backgroundColor: "#6A58c6",
                          }}
                        >
                          View More
                        </Typography>
                      </Link>
                      {isCompleted && (
                        <Typography
                          style={{
                            color: "green",
                            fontSize: "20px",
                            marginLeft: "10px",
                          }}
                        >
                          &#10003;
                        </Typography>
                      )}
      </CardActions>
    </Card>
                </Box>
              );
            })}
          </Box>
        </div>
      )}
    </div>
  );
};

const u = makeStyles({
  box: {
    width: "30%",
    marginTop:'25px',
    margin:'0.5%',
    display: "flex",
    "@media only screen and (max-width: 1200px)": {
      width: "90vw",
      marginTop: "15px",
    },
    "@media only screen and (max-width: 726px)": {
      flexDirection: "column",
    },
  },
  textBox: {
    display: "block",
    width: "60%",
    fontFamily:
      "source-serif-pro, Georgia, Cambria, Times New Roman, Times, serif",
    fontSize: "20px",
    color: "#EDEDF1",
    // border: "1px solid red",
    "@media only screen and (max-width: 726px)": {
      width: "100%",
      marginBottom: "1rem",
    },
  },
  imgBox: {
    objectFit: "cover",
    width: "40%",
    height: "25vh",
    marginTop: "auto",
    marginRight: "1rem",
    marginBottom: "auto",
    "@media only screen and (max-width: 726px)": {
      width: "100%",
      marginBottom: "1rem",
    },
  },
  img: {
    height: "100%",
    width: "100%",
    borderRadius: "10px",
  },
  body: {
    marginTop:'120px',
    color: "white",
    paddingTop: "20px",
    paddingBottom: "50px",
    width: "100%",
    minHeight: "100vh",
    // border: "0px solid blue",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    // border: "1px solid red",
    "@media only screen and (max-width: 726px)": {
      marginRight: "10px",
      marginLeft: "10px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  heading: {
    textAlign: "center",
    fontWeight: "bold",
    fontSize: "25px",
    backgroundColor: "#000",
    alignItems: "center",
    wordWrap: "break-word",
    borderRadius: "12px",
    whiteSpace: "initial",
    margin:'2%',
    "@media only screen and (max-width: 726px)": {
      width: "100%",
      padding: ".5rem",
      fontSize: "16px",
      borderRadius: "8px",
    },
  },
  subhead: {
    wordWrap: "break-word",
    paddingTop: "10px",
  },
});

const loader = {
  // backgroundColor: 'rgba(52, 52, 52, 0.8)',
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  color: "#6A58c6",
};

export default CaseStudy;
