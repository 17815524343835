import * as React from 'react';
import { Button, TextField, Typography } from '@mui/material';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CheckCircle from '@mui/icons-material/CheckCircle';

const QuestionViewer = ({q}) => {
    const [answers , setAnswers] = React.useState([]);
    const [submit , setSubmit] = React.useState(false);
    const handleChange = (e) => {
        const newAnswers = [...answers , e.target.value];
        setAnswers(newAnswers);
    }
  return (
    <div>
        <h1 style={{textAlign:'center'}}>Assignment Question To Solve</h1>

        {!submit ? (
            <div style={{boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px' , padding:'12px' , marginTop:'20px'}}>
        
        {q.map((question,index) => (
        <>
        <p>Q{index+1}.{question}</p>
        <TextField
         label="Answer"
         onChange={handleChange}
         style={{width:'100%' , margin:'10px 0 10px 0'}}
        />
        </>
        ))}
        <div style={{display:'flex' , alignItems:'center' , justifyContent:'center'}} onClick={()=> setSubmit(true)}>
            <Button variant='contained' style={{background: '#6A58C6'}}>Submit</Button>
        </div>
        </div>
        ): (
            <div style={{boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px' , padding:'12px' , marginTop:'20px' , display:'flex' , flexDirection:'column' , justifyContent:'center' , alignItems:'center'}}>
            <CheckCircleIcon style={{fontSize:'50px' , color: 'green'}} />
            <Typography
          variant="h5"
          style={{
            color:'#000',
            textAlign:'center',
            fontFamily:'Poppins',
            fontWeight:'bold'
          }}
          >
          Congratulations On Completing The Module
          </Typography>
          <Typography 
          variant="subtitle1"
          style={{
            color:'#000',
            textAlign:'center',
            fontFamily:'Poppins',
            fontWeight:'bold',
            wordWrap:'break-word'
          }}
          > Now You Are Good To Move To The Next Module.
          </Typography>
        </div>
        )}
    </div>
  )
}

export default QuestionViewer