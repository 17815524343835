import React from 'react';
import {Box , Typography} from '@mui/material';
import ManagementConsultingCard from './CourseCard';
import passport from './CourseCard/Images/item1-w.png';
import pic from './CourseCard/Images/item1.png';
import Typewriter from "typewriter-effect";

const LandingCourses = () => {
  return (
    <Box>
         <div className="main-heading_virtual">
          {/* <h2>Startup . Scaleup. Takeoff.</h2> */}
          <div className="textVSC">
            <Typewriter
              options={{
                autoStart: true,
                loop: true,
                delay: 40,
                strings: ["Learn . Apply . Work Ex"],
              }}
            />
          </div>
          <p>
          Get Job Ready with Outcome Driven Industry Exposure through Labs, Capstone Projects and Virtual Internships
          </p>
        </div>
    <Box
        sx={{
            display: 'flex',
            justifyContent: 'space-evenly' , 
            alignItems: 'center' , 
            gap: {md: 4 , xs: 5},
            mt: 10,
            flexWrap: {xs: 'wrap'},
            padding: {md: '0 80px' , xs: 0}
        }}
    >
        <ManagementConsultingCard 
            heading="Financial Analyst"
            tagline={'Learn -> Apply -> Work Ex'}
            scholar="Grad/PostGrad"
            problem="Finance in Excel, Financial Modelling, Financial Decision Making" 
            benefits= "Benefits : Financial Career Building"
            outcome="Finance Job Ready Profile"
            link="/financialanalyst"
            image={passport}
        />
        <ManagementConsultingCard 
            heading="Management Consulting"
            tagline={'Learn -> Apply -> Work Ex'}
            scholar="Grad/Professional"
            problem="Problem Solving Framework, Strategy Development, Digital Transformation" 
            benefits= "Benefits : Management Career Building"
            outcome="Consulting Job Ready Profile"
            link="/managementconsulting"
            image={pic}
        />
        <ManagementConsultingCard 
            heading="Product Management"
            tagline={'Get started on Product Management Career'}
            scholar="Undergrad/Grad Students"
            problem="Product Management | Experience working in Product Role" 
            benefits= "Benefits : Build, Market & Ship"
            outcome="Get Started in Product Career"
            link="/productmanagement"
            image={passport}
        />
    </Box>
    </Box>
  )
}

export default LandingCourses