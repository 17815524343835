import * as React from 'react';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import Badges from './index';
import Modal from '@mui/material/Modal';
import axios from 'axios';
import { GET_BADGES , COMPLETE_BADGES } from '../../api/badge';


export default function TransitionAlerts({o}) {
  const [open, setOpen] = React.useState(o);
  const [op, setOp] = React.useState(false);
  const [badge , setBadge] = React.useState({});
  const [badges  , setBadges] = React.useState([]);
  const [showNotification , setShowNotification] = React.useState(true);
 
  React.useEffect(() => {
    axios
    .get(GET_BADGES)
    .then((res) => {
          setBadges(res.data.data);
          setBadge(res.data.data.find((badge) => badge.tag === o));
    })
  },[])
  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setShowNotification(false);
    }, 7000);
    return () => clearTimeout(timeout);
  }, []);
  const handleReceived = (tag) => {
    axios
      .post(COMPLETE_BADGES+tag.split(" ").join("")+'/'+window.localStorage.id)
      .then(res => {
        })
      .catch(err => {
      })
  }

  const handleClose = () => setOp(false);
  return (
    showNotification && badges.length> 0 && (
    <Box sx={{

        width: op? '100%' :150,
        position: 'fixed', 
        top: {sm: '140px' , xs:'120px'},
        left: {sm: '50%' , xs: '30%'},
        zIndex: 9999, 
      }}>
      <Collapse in={open}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpen(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          <span style={{cursor: 'pointer' , fontWeight:'bold'}} onClick={() =>{ setOp(true); setOpen(false); handleReceived(badge.tag);}}>Badge</span>
        </Alert>
      </Collapse>
      <Modal
  open={op}
  onClose={handleClose}
>
    <Box style={{display:'flex', alignItems:'center' , justifyContent:'center', position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)', border:'none' , height:'auto'}}>
    <Badges sBadge={badge} />

  </Box>
  
</Modal>
    </Box>

  ) );
}
