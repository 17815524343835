import React from 'react';
import { TextField, InputAdornment, IconButton, Button, Typography, Grid, Box } from '@mui/material';
import { Facebook as FacebookIcon, Twitter as TwitterIcon, LinkedIn as LinkedInIcon, Add as PlusIcon, Share as ShareIcon, People as UsersIcon, PersonAdd as UserPlusIcon, School as SchoolIcon } from '@mui/icons-material';
import { USER } from '../../api/vsc';
import axios from 'axios';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import RefferalTable from '../auth/referralTable';

export default function Component() {
  const primaryColor = '#6A58C6';
  const [userData, setUserData] = React.useState({});
  const [successfullRefferal, setSuccessfullRefferal] = React.useState(0);

  React.useEffect(() => {
    axios
      .get(USER, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setUserData(response.data);
        const successfulReferrals = response.data.referredEmails
          .filter((referral) => referral.paid === true);
        setSuccessfullRefferal(successfulReferrals.length);
      })
      .catch((error) => {
        // handle error
        console.error(error);
      });
  }, []);

  const link = `https://campus.markoknow.com/signup?referrer=${userData.referralId}`;

  function handleCopy() {
    navigator.clipboard.writeText(link);
    alert("Link copied to clipboard!");
  }

  return (
    <>
      <Box sx={{ fontFamily: 'Poppins', background: `#6A58C6`, color: 'white', p: 4, boxShadow: 'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset', borderRadius: '20px' }}>
        <Box sx={{ maxWidth: '100%', margin: 'auto', flexDirection: 'column', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Typography variant="h5" sx={{ mb: 2, fontWeight: 'bold', textAlign: 'center' }}>Earn ₹100 on every successful referral</Typography>
          <Grid container spacing={2} alignItems="center" justifyContent="center" sx={{ mb: 2, width: '100%' }}>
            <Grid item xs={12}>
              <TextField
                type="text"
                id="link"
                name="link"
                value={link}
                sx={{
                  width: '100%',
                  background: '#fff',
                  '& .MuiOutlinedInput-root': {
                    borderColor: 'gray',
                    '&:focus': {
                      borderColor: 'black',
                    },
                  },
                  '& .MuiInputLabel-root': {
                    color: 'gray',
                    '&.Mui-focused': {
                      color: 'black',
                    },
                  },
                  borderRadius: '10px'
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleCopy}>
                        <ContentCopyIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
          <Grid container justifyContent="center" spacing={2}>
            <Grid item><FacebookIcon sx={{ color: 'white' }} /></Grid>
            <Grid item><TwitterIcon sx={{ color: 'white' }} /></Grid>
            <Grid item><LinkedInIcon sx={{ color: 'white' }} /></Grid>
          </Grid>
        </Box>
      </Box>
      <Box sx={{ background: 'white', p: 4, marginTop: '20px' }}>
        <Box sx={{ maxWidth: '100%', margin: 'auto' }}>
          <Typography variant="h6" sx={{ mb: 4, fontWeight: 'bold', color: '#000', textAlign: 'center', fontFamily: 'Poppins' }}>It's easy to earn credits in few simple steps</Typography>
          <Box sx={{ display: 'flex', flexDirection: {xs:'column' , sm: 'row'}, alignItems: 'center', textAlign: 'center' , gap: '10px' }}>
            <Box sx={{ boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px', borderRadius: '20px', padding: '20px', width: '100%' }}>
              <UsersIcon sx={{ color: '#000', width: 72, height: 72 }} />
              <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold', color: '#000', fontFamily: 'Poppins' }}>REFER A FRIEND</Typography>
              <Typography>Share referral link to every friend you know to signup on vsc.</Typography>
            </Box>
            <Box sx={{ boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px', borderRadius: '20px', padding: '20px', width: '100%', marginTop: '20px' }}>
              <UserPlusIcon sx={{ color: '#000', width: 72, height: 72 }} />
              <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold', color: '#000', fontFamily: 'Poppins' }}>MAKE SIGN UP</Typography>
              <Typography>When your friend signup through your referral link, you will get a referral.</Typography>
            </Box>
            <Box sx={{ boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px', borderRadius: '20px', padding: '20px', width: '100%', marginTop: '20px' }}>
              <SchoolIcon sx={{ color: '#000', width: 72, height: 72 }} />
              <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold', color: '#000', fontFamily: 'Poppins' }}>DO ENROLLS</Typography>
              <Typography>When your friend enrolls a course, you will gain ₹100.</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box sx={{ background: 'white', p: 4 }}>
        <Box sx={{ maxWidth: '100%', margin: 'auto' }}>
          <Typography variant="h6" sx={{ mb: 4, fontWeight: 'bold', color: '#000', textAlign: 'center', fontFamily: 'Poppins' }}>My Referrals</Typography>
          <Box sx={{ display: 'flex', flexDirection:{xs:'column' , sm: 'row'}, alignItems: 'center', textAlign: 'center' , gap:'10px' }}>
            <Box sx={{ boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px', borderRadius: '20px', padding: '20px', width: '100%', marginBottom: '20px' }}>
              <Typography variant="subtitle2">Referred People</Typography>
              <Typography variant="h4" sx={{ mb: 2, fontWeight: 'bold', color: '#000', fontFamily: 'Poppins' }}>{userData.referralCount}</Typography>
            </Box>
            <Box sx={{ boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px', borderRadius: '20px', padding: '20px', width: '100%', marginBottom: '20px' }}>
              <Typography variant="subtitle2">Successful referral</Typography>
              <Typography variant="h4" sx={{ mb: 2, fontWeight: 'bold', color: '#000', fontFamily: 'Poppins' }}>{successfullRefferal}</Typography>
            </Box>
            <Box sx={{ boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px', borderRadius: '20px', padding: '20px', width: '100%' }}>
              <Typography variant="subtitle2">Earning</Typography>
              <Typography variant="h4" sx={{ mb: 2, fontWeight: 'bold', color: '#000', fontFamily: 'Poppins' }}>₹{successfullRefferal * 100}</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box sx={{ marginTop: '20px', width: {xs: '100%' , sm: '80%'}}}>
        <RefferalTable userId={userData._id} />
      </Box>
    </>
  );
}
