import "../../../stylesheets/watch-now.css";
import * as React from "react";
import { useLocation } from "react-router-dom";
import CompletedButton from '../../CompletedButton/index';
import WatchnowRecomendations from './WatchnowRecomendations'
import {Divider, Typography, useMediaQuery} from '@mui/material';
 import{
  DELETE_watchnow,
  GET_COMPLETE_VIDEOS,
  GET_watchnow,
  POSTER,
} from "../../../api/watchnow";
import axios  from "axios";

function Video() {
  let { link, id , watchnow } = useLocation().state;
  link = link.split("/")[3];
  const isMobile = useMediaQuery('(max-width:600px)');
  const isLMobile = useMediaQuery('(max-width:700px)');
  const [randomIndexes, setRandomIndexes] = React.useState([]);
  React.useEffect(() => {
    const randomIndex1 = Math.floor(Math.random() * watchnow.length);
    let randomIndex2;
    
    do {
      randomIndex2 = Math.floor(Math.random() * watchnow.length);
    } while (randomIndex2 === randomIndex1);

    setRandomIndexes([randomIndex1, randomIndex2]);
  }, [watchnow]); 

  return (
    <>
      <div
        style={{
          marginTop: isLMobile?'0px':'100px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          height: '100%',
          overflow: 'hidden',
          paddingBottom: '1%',
        }}
      >
      <div style={{
        display:'flex',
        flexDirection: isLMobile? 'column':'row',
        justifyContent: isLMobile?'center':'space-between',
        alignItems: isLMobile&&'center',
        width: '100%',
        marginTop:isLMobile&&'180px'
      }}>
        <div
          className="video_container"
          style={{
            width: isMobile?'90vw':'85vw', // Set the width to 80% of the viewport width
            height: '70vw', // Set the height to 45% of the viewport width
            maxWidth: '900px', // Maximum width of 800px
            maxHeight: '500px', // Maximum height of 450px
            overflow: 'hidden',
            borderRadius: '30px',
            marginLeft:'20px',
            boxShadow: 'rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px'
          }}
        >
          <iframe
            style={{
              width: '100%',
              height: '100%',
            }}
            src={`https://www.youtube-nocookie.com/embed/${link}`}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
        <div
            style={{
              width: isLMobile ? '90vw' : '30vw',
              margin: '20px',
              justifySelf:'flex-end'
            }}
          >
           <Typography variant="h5" gutterBottom style={{fontFamily:'Poppins',textAlign:'center'}}>
            Recommendations
           </Typography>
           <Divider/>
           <br/>
            {watchnow.length > 0 && (
              <>
                <WatchnowRecomendations
                  poster={watchnow[randomIndexes[0]]?.poster?.name}
                  title={watchnow[randomIndexes[0]]?.title}
                  speaker={watchnow[randomIndexes[0]]?.speaker}
                  id={watchnow[randomIndexes[0]]?._id}
                  link={watchnow[randomIndexes[0]]?.link}
                  watchnow={watchnow}
                />
                <br />
                <WatchnowRecomendations
                  poster={watchnow[randomIndexes[1]]?.poster?.name}
                  title={watchnow[randomIndexes[1]]?.title}
                  speaker={watchnow[randomIndexes[1]]?.speaker}
                  id={watchnow[randomIndexes[1]]?._id}
                  link={watchnow[randomIndexes[1]]?.link}
                  watchnow={watchnow}
                />
              </>
            )}
          </div>
        </div>
        <div style={{ marginTop: '30px' }}>
          <CompletedButton id={id} />
        </div>
      </div>
    </>
  );
}

export default Video;
