import axios from "axios";
import React, { useEffect, useState } from "react";

import MuiAlert from "@mui/material/Alert";
import { useParams, NavLink } from "react-router-dom";
import { Typography } from "@mui/material";
// require("dotenv").config();
// const DOMAIN = process.env.REACT_APP_DOMAIN;
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const EmailVerSignup = ({ match }) => {
  const { token } = useParams();
  const { VERIFY_SIGNUP_EMAIL } = require("../../api/vsc");
  const [name, setName] = useState("");
  const [success, setSuccess] = useState("");

  useEffect(() => {
    axios
      .post(VERIFY_SIGNUP_EMAIL, { token })
      .then((response) => {
        setName(response.data.name);
        setSuccess("true");
      })
      .catch((error) => {
        setSuccess("false");
      });
  }, [token]);

  return (
    <>
      <div
        style={{
          width: "50%",
          height: "50%",
          maxWeight: "500px",
          overflow: "auto",
          margin: "auto",
          position: "absolute",
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
        }}
      >
        {success === "true" ? (
          <Alert severity="success">
            <Typography>Successfully Verified</Typography>
            Thank you for Verification {name} you can {}
            <strong>
              <NavLink
                to="/login"
                style={{ textDecoration: "none" , color:'#fff' }}
                exact
                className="nav-link"
              >
                LOGIN
              </NavLink>
            </strong>
            {} Now!
          </Alert>
        ) : (
          <Alert severity="error">
            <Typography>Opps! Error Occured</Typography>
            This means your link expired — <strong>Contact to Admin!</strong>
          </Alert>
        )}
      </div>
    </>
  );
};

export default EmailVerSignup;
