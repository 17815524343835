import * as React from 'react';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import NavbarTopDropdown from '../Navbar/NavbarTopDropdown';
import '../../stylesheets/dashboard.css';
import {Divider} from '@mui/material';
import SideDrawer from './SideDrawer/index';
import useMediaQuery from '@mui/material/useMediaQuery';
import Footer from '../Footer/Footer';


const Dashboard = ({isAuth,setIsAuth}) => {
  const isMobile = useMediaQuery('(max-width:820px)');

    return (
        (<div>
            {isAuth&&(
                <>
                <SideDrawer isAuth={isAuth}/>
                </>
            )}
        </div>)
    );
}

export default Dashboard;