const { BASE_URL } = require("./client");

const PAID = BASE_URL + '/program/courses/paid/';
const PP = BASE_URL + '/api/payments';
const CART_UPDATE = BASE_URL + '/api/cartupdate/';
const CART_LINK_UPDATE = BASE_URL + '/api/cartupdate/';
const CART_UPDATE_ALL = BASE_URL + '/api/cartupdate/all/';
const CART_REMOVE = BASE_URL + '/api/cartremove/';
const CART_REMOVE_THREE = BASE_URL + '/api/cartremove/three/';
const CART_REMOVE_ALL = BASE_URL + '/api/cartremove/all/';
const CART_REMOVE_SINGLE = BASE_URL + '/api/cartremove/single/';
const CREATE_ORDER = BASE_URL + '/api/razorpay/create-order';
const POST_CALLBACK = BASE_URL + '/api/razorpay/post-callback/';


export {PAID , PP,CART_LINK_UPDATE ,CART_UPDATE,CART_REMOVE,CART_UPDATE_ALL,CART_REMOVE_ALL,CART_REMOVE_THREE,CART_REMOVE_SINGLE , CREATE_ORDER , POST_CALLBACK};