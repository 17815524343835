const isAuth = window.localStorage.getItem('isAuth') || false;

export const MenuItems = [
  // {
  //   title: isAuth?"Dashboard":"Home",
  //   url: isAuth?`/dashboard/${window.localStorage.id}`:"/",
  //   cName: "nav-links",
  // },
  {
    title: "Corporate Trainings",
    url: "/courses",
    cName: "nav-links",
  },
  {
    title: "Courses",
    url: "/courses",
    cName: "nav-links",
  },
  {
    title: "Free Resources",
    url: '/',
    cName: "nav-links",
  } ,
  {
    title: "Become A Trainee",
    url: "/mentor",
    cName: "nav-links",
  },
  {
    title: "Hire with VSC",
    url: "/hire",
    cName: "nav-links",
  },
  // {
  //   title: "Contact Us",
  //   url: "/contact",
  //   cName: "nav-links",
  // }
  // {
  //   title: "Apply For Funding",
  //   url: "/fund",
  //   cName: "nav-links",
  // },
  // {
  //   title: "Login",
  //   url: "/login",
  //   cName: "nav-links-mobile-vsc",
  // },
];
