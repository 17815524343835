  import React from 'react';
  import { Box,Typography, Button , useMediaQuery } from "@mui/material";
import { Divider } from '@mui/material';
import headline from './News.png';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { IconButton } from '@mui/material';
import { Link } from 'react-router-dom';
import ImageStrip from './ImageStrip';
  
  const News = () => {
  const isMobile = useMediaQuery('(max-width: 600px)');

    const dividerStyle = {
        bgcolor: '#FFBD59',
        height: '2px',
        width:'100%',
      };
    return (
        <div style={{marginTop: '70px'}}>
        <Box display="flex" justifyContent="center">
        <div style={{ display: 'flex', flexDirection:isMobile&&'column',alignItems: 'center' }}>
        <Typography variant="h2" fontWeight="800" style={{ color: '#000', marginRight: '10px'  }}>
          We Are in
        </Typography>
        <Typography variant="h2" fontWeight="800" style={{ color: '#6a58c6' }} >
          News
          <Divider sx={dividerStyle} />
        </Typography>
         </div>

          </Box>
          <div style={{width:'100%'  , display:'flex' , flexDirection:isMobile?'column':'row' , justifyContent:'center' , alignItems:'center'  ,  padding:isMobile?'5px':'20px', marginTop:'10px' }}>
            <div style={{width:isMobile?'90%':'40%'   ,height:'70%' ,  background: 'black' , borderRadius:'25px' , marginBottom:isMobile &&'10px'}}>
                <div style ={{display:'flex' , padding:'10px' }}>
                <div style={{ width: 20, height: 20, backgroundColor: 'red', borderRadius: '50%', marginRight: 10 }}></div>
                <div style={{ width: 20, height: 20, backgroundColor: 'yellow', borderRadius: '50%', marginRight: 10 }}></div>
                <div style={{ width: 20, height: 20, backgroundColor: 'green', borderRadius: '50%' }}></div>
                </div>
                <div style={{display:'flex', justifyContent:'center' , width:'100%' , height:'60%'}}>
                    <img src={headline} alt="headline" width="98%" height={'60%'} style={{borderRadius:'25px'}}/>
                </div>

            </div>
            <div style={{display:'flex' , flexDirection:'column' ,justifyContent:'center' , alignItems:'center' , width:isMobile?'100%':'40%'}}>
                <div style={{width: isMobile?'90%':'80%' }}>
                <Card sx={{ width: '100%', height: '60%',  display:'flex', boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px, rgb(51, 51, 51) 0px 0px 0px 3px' }}>
                <span style={{fontSize:'60px' , color:'#6a58c6' , fontWeight:'700' }}> &#x275D;</span>
                <CardContent style={{width:'100%'}}>
                    <Typography variant="subtitle1" style={{color:'#000', fontWeight:'500' , fontSize:isMobile&&'15px' , width:'100%'  }}>
                        VSC addresses the gap in traditional college education by preparing students for a future where Al renders some skills redundant. The platform aims to be a <u style={{textDecorationColor: '#6a58c6' , textDecorationThickness: '3px'}}>comprehensive "plug for college & school</u> students, offering structured content, rapid updates to stay relevant, and <u style={{textDecorationColor: '#6a58c6' , textDecorationThickness: '3px'}}>opportunities for internships, paid projects, and hands-on industrial training.</u>
                    </Typography>
                </CardContent>
                </Card>
                </div>
                <Button variant='contained' href="https://hindustanpioneer.com/virtual-startup-campus-this-gurgaon-based-startup-is-solving-the-job-market-after-ai-takes-over/" style={{width:'70%',fontWeight:'bold',  background:'#6a58c6',  margin: '10px' ,  height:'50px' , fontSize:'24px' , textTransform: 'none'}}>{'Read More'}</Button>
            </div>
          </div>
          <div style={{marginTop:'5px' }}>
            <ImageStrip />
          </div>
          </div>
    )
  }
  
  export default News