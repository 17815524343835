import React from 'react';
import { useMediaQuery } from "@mui/material";
import axios from 'axios';
import { Typography, Button } from '@material-ui/core';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import { POST_PROJECT, USER_REFFERAL } from '../../../../api/vsc';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Snackbar from '@mui/material/Snackbar';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';

const ProjectViewer = ({ ProjectData , courseName }) => {
    const [clicked, setClicked] = React.useState(false);
    const [description, setDescription] = React.useState('');
    const [resume, setResume] = React.useState(null);
    const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const [approved, setApproved] = React.useState(false);
    const [showAlert, setShowAlert] = React.useState(false);
    const isMobile = useMediaQuery('(max-width:838px)');
    const [certificateLink, setCertificateLink] = React.useState('');

    const handleEditorChange = (content, delta, source, editor) => {
        setDescription(content);
    };

    React.useEffect(() => {
        axios.get(USER_REFFERAL + window.localStorage.id).then(res => {
            const project = res.data.data.completedProjects.find((project) => project.projectId === ProjectData._id)
            if (project && project.isApproved !== "submit") setClicked(true);
            if (project && project.isApproved === "Approved") {
                setApproved(true);
            }
            if (project && project.isApproved === "submit") {
                setApproved(false);
            }
        }).catch(err => console.log(err))
        const cN = courseName.toLowerCase();
        let link = '';

        if (cN.includes('product management')) {
            link = `/certificate/product/project/`;
        } else if (cN.includes('prompt')) {
            link = `/certificate/prompt/capstone/`;
        } else if (cN.includes('business model')) {
            link = `/certificate/`;
        } else if (cN.includes('management consulting')) {
            link = `/certificate/managementconsulting/project/`;
        }

        setCertificateLink(link);
    }, []);

    const handleApply = () => {
        if (!description) {
            setShowAlert(true);
            return;
        }

        const data = new FormData();
        data.append('solution', description);
        data.append('title', ProjectData.heading);
        data.append('description', ProjectData.description);
        data.append('courseId', ProjectData.courseId);
        if (resume) data.append('Project', resume);

        axios
            .post(POST_PROJECT + ProjectData._id + "/" + window.localStorage.id, data)
            .then(response => {
                setClicked(true);
            })
            .catch(err => {
                console.log(err);
            })
    };

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];

        if (selectedFile) {
            if (selectedFile.size <= 50 * 1024 * 1024) {
                setResume(selectedFile);
                setShowAlert(false);
            } else {
                setOpenSnackbar(true);
            }
        }
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };

    return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {clicked ? (
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { m: !isMobile ? 1 : 0.5 },
                        width: { xs: 300, s: 350, lg: 1000, md: 500 },
                        margin: '2%',
                        borderRadius: '10px',
                        background: '#6A58C6',
                        padding: '2% 6% 2% 6% ',
                        display: clicked && 'flex',
                        justifyContent: clicked && 'center',
                        alignItems: clicked && 'center',
                        boxShadow: '2px 2px 5px #6A58C6',
                        height: '100%'
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <div
                        style={{
                            width: '70%',
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            alignItems: 'center',
                            gap: '20px'
                        }}>
                        <Typography
                            variant="h4"
                            style={{
                                color: '#fff',
                                textAlign: 'center',
                                fontFamily: 'Poppins',
                                fontWeight: 'bold'
                            }}
                        >
                            Thank You
                        </Typography>
                        <Typography
                            variant="subtitle1"
                            style={{
                                color: '#fff',
                                textAlign: 'center',
                                fontFamily: 'Poppins',
                                fontWeight: 'bold',
                                wordWrap: 'break-word'
                            }}
                        >Your Project Has Been Submitted Successfully</Typography>
                        <Typography
                            variant="subtitle1"
                            style={{
                                color: '#fff',
                                textAlign: 'center',
                                fontFamily: 'Poppins',
                                fontWeight: 'bold',
                                wordWrap: 'break-word'
                            }}
                        >{approved ? "Your Project Is Approved You Can Download Your Certificate Now " : "Wait For Approval After It Gets Approved You Can Download Certificate From Here"}</Typography>
                        {approved && <Button href={certificateLink+ window.localStorage.id} variant='contained' style={{ background: '#FFBD59', color: '#000' }} startIcon={<CardMembershipIcon style={{ color: '#000' }} />}
                        >Certificate</Button>}
                    </div>
                </Box>
            ) : (
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { m: !isMobile ? 1 : 0.5 },
                        width: { xs: 370, s: 700, lg: 1200, md: 800 },
                        margin: '2%',
                        borderRadius: '10px',
                        background: '#6A58C6',
                        padding: '2% 6% 2% 6% ',
                        display: clicked && 'flex',
                        justifyContent: clicked && 'center',
                        alignItems: clicked && 'center',
                        boxShadow: '2px 2px 5px #6A58C6',
                        height: '100%'
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <div style={{ marginBottom: "30px" }}>
                        <Typography style={{
                            color: "white",
                            textAlign: 'center'
                        }} variant='h4'>
                            {ProjectData.heading}
                        </Typography>
                        <Typography style={{
                            color: "#fff",
                        }}>
                            <div dangerouslySetInnerHTML={{ __html: ProjectData.description }} />
                        </Typography>
                    </div>

                    <div>
                        <ReactQuill
                            theme="snow"
                            value={description}
                            onChange={handleEditorChange}
                            style={{ background: '#fff', width: !isMobile ? '98%' : '100%' }}
                            placeholder="Solution Description"
                            modules={{
                                toolbar: [
                                    ['bold', 'italic', 'underline', 'strike'],
                                    ['blockquote', 'code-block'],
                                    [{ 'header': 1 }, { 'header': 2 }],
                                    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                    [{ 'script': 'sub' }, { 'script': 'super' }],
                                    [{ 'indent': '-1' }, { 'indent': '+1' }],
                                    [{ 'direction': 'rtl' }],
                                    [{ 'size': ['small', false, 'large', 'huge'] }],
                                    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                                    [{ 'color': [] }, { 'background': [] }],
                                    [{ 'font': [] }],
                                    [{ 'align': [] }],
                                    ['clean']
                                ],
                            }}
                        />
                        <div
                            style={{
                                margin: '1%',
                                display: 'flex',
                                justifyContent: "center",
                                flexDirection: 'column',
                                alignItems: 'center'
                            }}>
                            <Button
                                variant="contained"
                                component="label"
                                style={{
                                    width: !isMobile ? '50%' : '100%',
                                    background: '#000',
                                    fontFamily: 'Poppins',
                                    marginBottom: '20px',
                                }}
                            >
                                Upload Doc
                                <input
                                    type="file"
                                    onChange={handleFileChange}
                                    hidden
                                />
                            </Button>

                            <Snackbar
                                open={openSnackbar}
                                autoHideDuration={6000}
                                onClose={handleCloseSnackbar}
                                message="Please upload a valid file with a maximum size of 50MB."
                            />
                            {/* {showAlert && ( */}
                              <Collapse in={showAlert}>
                                <Alert
                                severity="error"
                                  action={
                                    <IconButton
                                      aria-label="close"
                                      color="inherit"
                                      size="small"
                                      onClick={() => {
                                        setShowAlert(false);
                                      }}
                                    >
                                      <CloseIcon fontSize="inherit" />
                                    </IconButton>
                                  }
                                  sx={{ mb: 2 }}
                                >
                                  Description Field Needs TO Be Filled
                                </Alert>
                              </Collapse>
                            {/* )} */}
                            <Button
                                variant="contained"
                                component="label"
                                style={{
                                    width: !isMobile ? '50%' : '100%',
                                    background: '#000',
                                    fontFamily: 'Poppins'
                                }}
                                onClick={handleApply}
                            >
                                Submit
                            </Button>
                        </div>
                    </div>
                </Box>)
            }
        </div>
    )
}

export default ProjectViewer
