import React, { useState, useEffect } from "react";
import { Grid, Typography, Box, Button  } from "@material-ui/core";
import { makeStyles} from "@mui/styles";
import Footer from "../Footer/Footer";
import { useParams } from "react-router-dom";
import axios from "axios";
import { CircularProgress , IconButton } from "@mui/material";
import { GET_CASE_STUDY } from "../../api/casestudy";
import dummy from './CaseStudy.png';
import Avatar from '@mui/material/Avatar';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import {useMediaQuery} from '@mui/material';
import {Divider} from '@mui/material';
// import EcoBuild fro./CaseComponentild';
import EcoThread from './CaseStudies/EcoThread';
import FinEase from './CaseStudies/FinEase';
import MoneyWise from './CaseStudies/MoneyWise';
import NutriFit from './CaseStudies/NutriFit';
import WorkConnect from './CaseStudies/WorkConnect';
import ShieldSafe from './CaseStudies/ShieldSafe';
import AgroTechGreens from './CaseStudies/AgroTechGreens';
import LivestockSense from './CaseStudies/LivestockSense';
import PolicyGenius from './CaseStudies/PolicyGenius';
import LendEasy from './CaseStudies/LendEasy';
import CreditEase from './CaseStudies/CreditEase';
import FleetCharge from './CaseStudies/FleetCharge';
import ChargeX from './CaseStudies/ChargeX';
import CaseStudyCards from './CaseStudyCards';
import favicon from './favicon.ico';
import CaseComponent from './CaseComponent';
import CompletedButton from '../CompletedButton/index'


function Reader() {


  const classes = u();
  const { id } = useParams();
  const [questions, setQuestions] = useState(null);
  const [caseStudy , setCaseStudy] = useState({});
  const [loading, setLoading] = useState(false);
  const isLMobile = useMediaQuery('(max-width:1200px)');
  const isSMobile = useMediaQuery('(max-width:1040px)');

  useEffect(() => {
    axios
      .get(GET_CASE_STUDY+id)
      .then((res) => {
        setCaseStudy(res.data.data);
          setLoading(false);
      })
      .catch((err) => {
      });
  }, []);
  const isMobile=useMediaQuery('(max-width:600px)');
  return (
    <>
      <div className={classes.body}>
        {loading ? (
          <CircularProgress style={loader} size={45} />
        ) : (
          <div 
          style={{
            marginTop:'50px',
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
          >
          <div style={{display:'flex' , justifyContent:'center' , flexDirection:'column' , marginLeft:!isLMobile?'20%':isMobile?'1%':'5%'}}>   
            
            <Box style={{width:!isMobile?(isSMobile?'80%':'60%'):'100%' , boxShadow: 'rgba(17, 17, 26, 0.1) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px' , padding:'4%' ,lineHeight:2}}    >
          <Typography variant="h4" className={classes.heading} style={{marginBottom:'20px' , textAlign:'center' , fontWeight:'900'}}>{caseStudy.title}</Typography>
          

          <Box style={{display:'flex',justifyContent:'center' , width:'100%'}}> 
            <img
              src={caseStudy.casestudy}
            // src={dummy}
            alt="..."
            className={classes.img}
            style={{width:isMobile?'100%':'60%', margin:'auto'}}
            ></img>
          </Box>
             <CaseComponent id={caseStudy._id}/>
             <div style={{margin: '20px' , display:'flex' , alignItems: 'center' , justifyContent:'center'}}>
              <CompletedButton wid={caseStudy._id}/>
            </div>
          </Box> 
         

            </div>
          </div>
        )}
        
      </div>
     
      <Footer />
    </>
  );
}

const loader = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  color: "#000",
};
const u = makeStyles({
  box: {
    padding: "10px",
    borderRadius: "10px",
    marginTop: "25px",
    marginBottom: "25px",
    display: "flex",
    "@media only screen and (max-width: 1200px)": {
      width: "90vw",
      marginTop: "15px",
    },
    "@media only screen and (max-width: 726px)": {
      flexDirection: "column",
    },
  },
  textBox: {
    display: "block",
    width: "60%",
    fontFamily:
      "source-serif-pro, Georgia, Cambria, Times New Roman, Times, serif",
    fontSize: "20px",
    color: "#EDEDF1",
    "@media only screen and (max-width: 726px)": {
      width: "100%",
      marginBottom: "1rem",
    },
  },
  imgBox: {
    objectFit: "cover",
    width: "50%",
    height: "50%",
    margin:'3%',
    // boxShadow: 'rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px',
    "@media only screen and (max-width: 726px)": {
      width: "100%",
      marginBottom: "1rem",
    },
  },
  img: {
    height: "100%",
    width: "100%",
    borderRadius: "10px",
  },
  body: {
    marginTop:'50px',
    paddingTop: "20px",
    paddingBottom: "50px",
    width: "100%",
    minHeight: "100vh",
    display:'flex',
    justifyContent:'center'
  },
  container: {
    marginTop:'80px',
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "@media only screen and (max-width: 1200px)": {
      marginRight: "10px",
      marginLeft: "10px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "center",
    },
  },
  heading: {
    textAlign: "left",
    fontWeight: "bold",
    fontFamily:"Poppins",
    fontSize: "28px",
    padding: "3px 5px 3px 5px",
    // backgroundColor: "#6A58c6",
    // width: "90%",
    alignItems: "center",
    wordWrap: "break-word",
    marginBottom: "30px",
    borderRadius: "8px",
    whiteSpace: "initial",
    "@media only screen and (max-width: 726px)": {
      marginLeft: "0px",
      width: "100%",
      borderRadius: "5px",
    },
  },
  subhead: {
    wordWrap: "break-word",
    paddingTop: "10px",
    width:'50%',
   
  },

  box2: {
    // width: "77vw",
    // backgroundColor: "#6A58c6",
    padding: "5px",
    borderRadius: "10px",
    marginTop: "25px",
    marginBottom: "25px",
    display: "flex",
    // border: "1px solid red",
    "@media only screen and (max-width: 1200px)": {
      width: "90vw",
      marginTop: "15px",
    },
    "@media only screen and (max-width: 726px)": {
      flexDirection: "column",
    },
  },
  descriptionBox: {
    display: "block",
    width: "100%",
    fontFamily:"Poppins",
    fontSize:'18px',
    // border: "1px solid red",
    "@media only screen and (max-width: 726px)": {
      width: "100%",
      marginBottom: "1rem",
    },
  },
  description: {
    fontFamily: "source-serif-pro, Georgia, Cambria, Times, serif",
    // fontSize: "20px",
    // lineHeight: "32px",
    // color: "#ededf1",
    // fontWeight: "400",
    wordWrap: "break-word",
    paddingTop: "10px",
  },
});


export default Reader;

