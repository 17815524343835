import React , {useEffect , useState} from 'react';
import axios from 'axios';
import RefferalTable from '../../auth/referralTable';
import eye from "../../auth/Images/eye.svg";
import check from "../../auth/Images/check.svg";


const Refferal = () => {
    const { USER } = require("../../../api/vsc");
    const [userData, setUserData] = useState({});
    const [successfullRefferal , setSuccessfullRefferal] = useState(0);
    useEffect(() => {
      axios
        .get(USER, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          setUserData(response.data);
          const successfulReferrals = response.data.referredEmails
          .filter((referral) => referral.paid === true);
          setSuccessfullRefferal(successfulReferrals.length);
        })
        .catch((error) => {
          // handle error
          console.error(error);
        });
    }, []);
  
    const link = `https://campus.markoknow.com/signup?referrer=${userData.referralId}`;
    function handleCopy() {
      navigator.clipboard.writeText(link);
      alert("Link copied to clipboard!");
    }
  
    async function handleShare() {
      const shareData = {
        title: "Check out this link!",
        text: "Hey, check out this cool link!",
        url: link,
      };
  
      try {
        await navigator.share({
          title: shareData.title,
          text: shareData.text,
          url: shareData.url,
          shareTarget: [
            {
              target: "whatsapp",
              action: "share",
              params: { text: shareData.text + " " + shareData.url },
            },
            {
              target: "email",
              action: "share",
              params: {
                subject: shareData.title,
                body: shareData.text + " " + shareData.url,
              },
            },
          ],
        });
      } catch (error) {
        console.error("Error sharing link:", error);
      }
    }
  
    return (
      <>
        <div>
          <div className="referral">
            <h2>Referral </h2>
  
            <div className="referral-box">
              <div className="referral-box-1">
                <div className="referral-box-1-1">
                  <img
                    src={eye}
                    style={{
                      width: "30px",
                      height: "30px",
                      color: "rgba(0, 0, 0, 0.88)",
                    }}
                  />
                  <div className="referral-box-1-1-1">
                    <p>Referred People</p>
                    <div className="referral-box-1-1-1-1">
                      {userData.referralCount}
                    </div>
                  </div>
                </div>
              </div>
  
              <div className="referral-box-2">
                <div className="referral-box-2-1">
                  <img
                    src={eye}
                    style={{
                      width: "30px",
                      height: "30px",
                      color: "rgba(0, 0, 0, 0.88)",
                    }}
                  />
                  <div className="referral-box-2-1-1">
                    <p>Successful referral</p>
                    <div className="referral-box-2-1-1-1">
                      {successfullRefferal}
                    </div>
                  </div>
                </div>
              </div>
              <div className="referral-box-3">
                <div className="referral-box-3-1">
                  <img
                    src={check}
                    style={{
                      width: "30px",
                      height: "30px",
                      color: "rgba(0, 0, 0, 0.88)",
                    }}
                  />
                  <div className="referral-box-3-1-1">
                    <p>Earning</p>
                    <div className="referral-box-3-1-1-1">₹{successfullRefferal*100}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="referral-box-4">
            <div id="Left-box">
              <div id="heading-leftBox">
                <h2>Invite & Get</h2>
                <div className="left-box-1">
                  <p >Rs 100 
                    On inviting Your friends and making them to their first purchase
                  </p>
                </div>
              </div>
  
              <p
                style={{
                  marginTop: "15px",
                  fontFamily: "sans-serif",
                  fontSize: "14px",
                }}
              >
                Share Your link
              </p>
              <div>
                <div className="link">
                  <input
                    type="text"
                    id="link"
                    name="link"
                    value={link}
                    className="link-input"
                  />
                  <button
                    type="button"
                    onClick={handleCopy}
                    style={{
                      background: "#000",
                      color:'#fff',
                      borderRadius: "0.3rem",
                      border: "none",
                      fontSize: "12px",
                      fontWeight: "600",
                      width: "5rem",
                      padding: "0px 6px",
                      border: "1px solid gray",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "start",
                    }}
                  >
                    Copy Link
                  </button>
                </div>
                <button
                  className="share-button"
                  type="button"
                  onClick={handleShare}
                >
                  Share Link
                </button>
              </div>
              <RefferalTable userId={userData._id}/>  
            </div>
          </div>
        </div>
      </>
    );
}

export default Refferal;