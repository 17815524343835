import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import SideMenu from './SideMenu';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const Play_Video = ({ isAuth, data }) => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate a delay for checking access
    const checkAccess = async () => {
      await new Promise(resolve => setTimeout(resolve, 1000)); 
      setLoading(false);
    };

    checkAccess();
  }, []);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  const hasAccess = data && data.courses && data.courses.includes(id);
  let access = false;

  if (hasAccess) {
    const courseAccess = data.accessWorkEx && data.accessWorkEx.find(entry => entry.courseId === id);
    access = courseAccess ? courseAccess.access : false;
  }

  return (
    <Box style={{
      alignItems: 'center' , 
      justifyContent: 'center'
    }}>
      {isAuth ? (
        hasAccess ? (
          <SideMenu access={access} />
        ) : (
          <Typography variant="h5" textAlign={'center'} margin="auto">You don't have access to this course</Typography>
        )
      ) : (
        'First Login'
      )}
    </Box>
  );
};

export default Play_Video;
