import React from 'react';
import CertificateGenerateForm from './CertificateGenForm';
import CertificateAndGuidelines from './CertificateAndGuideLines';
import { useMediaQuery } from '@mui/material';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const CertificateGen = ({isAuth}) => {
  const isMobile = useMediaQuery('(max-width: 650px)');
  const navigate = useNavigate();
  return (
    <div
        style={{marginTop:'120px'}}
    >
    {isAuth? (
        <div
          style={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            justifyContent:'center',
            alignItems:'center'
          }}
          >
          <div style={{
            width: isMobile? '100%' : '70%',

          }}>
          <CertificateGenerateForm />
          </div>
          <div style={{
            width: isMobile? '100%' : '30%',

          }}>
          <CertificateAndGuidelines />
          </div>
        </div>

    ): (
        <div>
          <p>Login First</p>
          <Button 
            variant="contained"
             style={{background:'#6a58c6'}}
             onClick={(e) => {
              navigate('/login' , {state:{redirect:'/events/certificate/generate'}})
             }}> Login </Button>
        </div>
    )}
    </div>
  )
}

export default CertificateGen