import React from 'react';

function TermsConditions() {
  return (
    <div className="Maincontainer">
      <div>
        <div style={styles.container}>
          <p style={styles.title}>Terms & Conditions</p>
          <p>
            <strong>STANDARD TERMS OF USE</strong>
          </p>
          <ol>
            <li>Introduction</li>
          </ol>
          <p>
            These Standard Terms of Use (these &ldquo;Terms&rdquo; or these &ldquo;Standard Terms
            And Conditions&rdquo;) contained herein on this webpage, shall govern your use of this
            Website, including all pages within this website (collectively referred herein below as
            this &ldquo;Website&rdquo;). These Terms apply in full force and effect to your use of
            this Website and by using this Website, you expressly accept all terms and conditions
            contained herein in full. You must not use this Website if you have any objection to any
            of these Standard Terms And Conditions.
          </p>
          <ol start="2">
            <li>Intellectual Property Rights</li>
          </ol>
          <p>
            Other than content you own, which you may have opted to include on this Website, under
            these Terms, Markoknow and/or its licensors own all rights to the intellectual property
            and material contained in this Website, and all such rights are reserved.
          </p>
          <p>
            You are granted a limited license only, subject to the restrictions provided in these
            Terms, for purposes of viewing the material contained on this Website,
          </p>
          <ol start="3">
            <li>Users Restrictions</li>
          </ol>
          <p>&nbsp;The use of this website is subject to the following terms of use:</p>
          <ul>
            <li>
              The content of the pages of this website is for your general information and use only.
              It is subject to change without notice.
            </li>
            <li>
              This website uses cookies to monitor browsing preferences. If you do allow cookies to
              be used, the following personal information may be stored by us for use by third
              parties.
            </li>
            <li>
              Neither we nor any third parties provide any warranty or guarantee as to the accuracy,
              timeliness, performance, completeness or suitability of the information and materials
              found or offered on this website for any particular purpose. You acknowledge that such
              information and materials may contain inaccuracies or errors and we expressly exclude
              liability for any such inaccuracies or errors to the fullest extent permitted by law.
            </li>
            <li>
              Your use of any information or materials on this website is entirely at your own risk,
              for which we shall not be liable. It shall be your own responsibility to ensure that
              any products, services or information available through this website meet your
              specific requirements.
            </li>
            <li>
              This website contains material which is owned by or licensed to us. This material
              includes, but is not limited to, the design, layout, look, appearance and graphics.
              Reproduction is prohibited other than in accordance with the copyright notice, which
              forms part of these terms and conditions.
            </li>
            <li>
              All trademarks reproduced in this website which are not the property of, or licensed
              to, the operator are acknowledged on the website.
            </li>
            <li>
              Unauthorised use of this website may give rise to a claim for damages and/or be a
              criminal offence.
            </li>
            <li>
              From time to time this website may also include links to other websites. These links
              are provided for your convenience to provide further information. They do not signify
              that we endorse the website(s). We have no responsibility for the content of the
              linked website(s).
            </li>
            <li>
              Certain areas of this Website are restricted from access by you and Markoknow may
              further restrict access by you to any areas of this Website, at any time, in its sole
              and absolute discretion. Any user ID and password you may have for this Website are
              confidential and you must maintain confidentiality of such information.
            </li>
          </ul>
          <ol start="4">
            <li>Your Content</li>
          </ol>
          <p>
            In these Website Standard Terms And Conditions, &ldquo;Your Content&rdquo; shall mean
            any audio, video, text, images or other material you choose to display on this Website.
            With respect to Your Content, by displaying it, you grant Markoknow a non-exclusive,
            worldwide, irrevocable, royalty-free, sublicensable license to use, reproduce, adapt,
            publish, translate, and distribute it in any and all media.
          </p>
          <p>
            Your Content must be your own and must not be infringing on any third party&rsquo;s
            rights. Markoknow reserves the right to remove any of Your Content from this Website at
            any time, and for any reason, without notice.
          </p>
          <ol start="5">
            <li>No warranties</li>
          </ol>
          <p>
            This Website is provided &ldquo;as is,&rdquo; with all faults, and Markoknow makes no
            express or implied representations or warranties, of any kind related to this Website or
            the materials contained on this Website. Additionally, nothing contained on this Website
            shall be construed as providing consult or advice to you.
          </p>
          <ol start="6">
            <li>Limitation of liability</li>
          </ol>
          <p>
            In no event shall Markoknow, nor any of its officers, directors and employees, be liable
            to you for anything arising out of or in any way connected with your use of this
            Website, whether such liability is under contract, tort or otherwise, and Markoknow,
            including its officers, directors and employees shall not be liable for any indirect,
            consequential or special liability arising out of or in any way related to your use of
            this Website.&nbsp;
          </p>
          <ol start="7">
            <li>Indemnification</li>
          </ol>
          <p>
            You hereby indemnify to the fullest extent Markoknow from and against any and all
            liabilities, costs, demands, causes of action, damages and expenses (including
            reasonable attorney&rsquo;s fees) arising out of or in any way related to your breach of
            any of the provisions of these Terms.
          </p>
          <ol start="8">
            <li>Severability</li>
          </ol>
          <p>
            If any provision of these Terms is found to be unenforceable or invalid under any
            applicable law, such unenforceability or invalidity shall not render these Terms
            unenforceable or invalid as a whole, and such provisions shall be deleted without
            affecting the remaining provisions herein.
          </p>
          <ol start="9">
            <li>Variation of Terms</li>
          </ol>
          <p>
            Markoknow is permitted to revise these Terms at any time as it sees fit, and by using
            this Website you are expected to review such Terms on a regular basis to ensure you
            understand all terms and conditions governing use of this Website.
          </p>
          <ol start="10">
            <li>Assignment</li>
          </ol>
          <p>
            Markoknow shall be permitted to assign, transfer, and subcontract its rights and/or
            obligations under these Terms without any notification or consent required. However, you
            shall not be permitted to assign, transfer, or subcontract any of your rights and/or
            obligations under these Terms.
          </p>
          <ol start="11">
            <li>Entire Agreement</li>
          </ol>
          <p>
            These Terms, including any legal notices and disclaimers contained on this Website,
            constitute the entire agreement between Markoknow and you in relation to your use of
            this Website, and supersede all prior agreements and understandings with respect to the
            same.
          </p>
          <ol start="12">
            <li>Governing Law &amp; Jurisdiction</li>
          </ol>
          <p>
            These Terms will be governed by and construed in accordance with the laws of India, and
            you submit to the non-exclusive jurisdiction of India.&nbsp;
          </p>
          <ol start="13">
            <li>CONTACT US</li>
          </ol>
          <p>
            In order to resolve a complaint regarding the Services or to receive further information
            regarding use of the Services, please contact us at
          </p>
          <p>Markoknow Private Limited</p>
          <p>
            Forum, Cyber City, DLF Forum, DLF Tower 10th Rd, Phase lll, Gurugram, Haryana 122002
          </p>
          <p>Gurgaon, Haryana 122002</p>
          <p>India</p>
          <p>hello@markoknow.com</p>
          <p>
            <br />
            <br />
          </p>
        </div>
      </div>
    </div>
  );
}
const styles = {
  container: {
    padding: '40px',
    borderRadius: '4px',
  },
  title: {
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '10px',
    textAlign: 'center',
  },
};
export default TermsConditions;
