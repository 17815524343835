import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import iitd from '../Images/iitd.png';
import du from '../Images/du.png';
import iits from '../Images/iims.png';
import iitb from '../Images/IITB.png';
import vnit from '../Images/VNIT.png';
import christ from '../Images/christ.png';
import ssc from '../Images/ssc.jpg';
import nitw from '../Images/nitw.jpg';
import iitk from '../Images/iiitk.png';
import iimi from '../Images/iimi.png';
import svc from '../Images/svc.png';
import iimn from '../Images/iimn.jpg';
import skit from '../Images/skit.png';
import dtu from '../Images/dtu.png';
import miranda from '../Images/miranda.png';

const images = [
  { src: iitd, alt: 'IITD' },
  { src: du, alt: 'DU' },
  { src: iits, alt: 'IITS' },
  { src: iitb, alt: 'IITB' },
  { src: vnit, alt: 'VNIT' },
  { src: christ, alt: 'Christ' },
  { src: ssc, alt: 'SSC' },
  { src: nitw, alt: 'NITW' },
  { src: iitk, alt: 'IITK' },
  { src: iimi, alt: 'IIMI' },
  { src: svc, alt: 'SVC' },
  { src: iimn, alt: 'IIMN' },
  { src: skit, alt: 'SKIT' },
  { src: dtu, alt: 'DTU' },
  { src: miranda, alt: 'Miranda' },
];

const PossibleCareer = () => {
  return (
    <Box>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Typography variant="h4" align="center" sx={{ mb: 2, width: { xs: '100%', md: '30%' } }}>
          Our Trainees from <span style={{ color: "#6a58c6", fontWeight: 600 }}>150+ Colleges </span> in India
        </Typography>
      </Box>
      <Box 
        sx={{
            display: 'flex',
            justifyContent: 'center' , 
            alignItems: 'center',
            flexWrap: 'wrap',
            gap: '20px'
        }}
      >
        {images.map((image, index) => (
          <Box >
            <Box
              component="img"
              src={image.src}
              alt={image.alt}
              sx={{
                width: {xs: 150 , md: 150},
                height: 100,
                padding: '1%',
                borderRadius: '10px',
                boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px'
                }}
            />
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default PossibleCareer;
