import * as React from 'react';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import { GET_MAIN_DASH_ANNOUNCEMENT } from "../../../api/announcement";
import axios from 'axios';
import { Typography } from '@mui/material';
import {useNavigate} from 'react-router-dom';
export default function AlertComponent({user}) {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(true);
  const [title, setTitle] = React.useState('');
  const [body, setBody] = React.useState('');
  const [link , setLink] = React.useState('');
  const [count, setCount] = React.useState(0);
  const dividerStyle = {
    bgcolor: '#FFBD59',
    height: '2px',
    width:'100%',
  };
  React.useEffect(() => {
    axios.get(GET_MAIN_DASH_ANNOUNCEMENT)
      .then(res => {
        setTitle(res.data.title);
        setBody(res.data.body);
        setLink(res.data.link);
        res.data.title && setCount(1);
      })
      .catch(err => {
        console.error("Error fetching announcement:", err);
      });
  }, []);
  return (
    <Box sx={{ width: '100%', height: '100%' ,  boxShadow: 'rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px', marginBottom:'10px' , borderRadius:'10px'}}>
      <Collapse in={open}>
        <Alert
          icon={null}
          severity="info"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpen(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
          style={{
            background:'#6A58C6',
            opacity:'200',
            color:'#fff',
            width:'100%',
            height:'100%',
            cursor: 'pointer'
          }}
          
        >
        <Typography variant="h6">
          Hello {user.name} 👋
        </Typography> 
          <div style={{display:'flex' ,  flexDirection:'column', marginTop:'10px', height: title ? '60%' :'50px' , color:'#fff'}}>
          {title ? <a href={link?link: ""}>
          <Typography variant='h6'>
          <u style={{textDecorationColor: '#FFBD59' ,color:'#fff', textDecorationThickness: '3px'}}>
          {title}
          </u>
          </Typography>
          </a> :""}
          <Typography>
            {body ? body : ""}
          </Typography>
          </div>

          
        </Alert>
      </Collapse>

    </Box>
  );
}
