import React, { useState, useEffect } from "react";
import { Grid, Typography, Box, Button  } from "@material-ui/core";
import { makeStyles} from "@mui/styles";
import Footer from "../Footer/Footer";
import { useParams } from "react-router-dom";
import axios from "axios";
import { CircularProgress , IconButton } from "@mui/material";
import { GET_BLOGS , GET_BLOG , IMAGE } from "../../api/blog";
import dummy from '../NewVirtualCampusStartup/Images/bmc.png';
import Avatar from '@mui/material/Avatar';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import {useMediaQuery} from '@mui/material';
import favicon from './favicon.ico';
import BlogRecomendations from "./blogRecommendations";
import {Divider} from '@mui/material';
import CompletedButton from '../CompletedButton/index';
import SEO from '../SEO';

function convertHtmlToPlainText(html) {
  const parser = new DOMParser();
  const parsedHtml = parser.parseFromString(html, "text/html");
  return parsedHtml.body.textContent || "";
}
function BlogPage() {


  const classes = u();
  const { id } = useParams();
  const [blog, setBlog] = useState({
    title: "",
    imagePath: "",
    subTitle: "",
    description: "",
  });
  const [loading, setLoading] = useState(false);
  const isLMobile = useMediaQuery('(max-width:1200px)');

  useEffect(() => {
    setLoading(true);
    axios
      .get(GET_BLOG+id, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setBlog(res.data);
        setLoading(false);
      })
      .catch((err) => {
      });
  }, [blog.id]);
  const isMobile=useMediaQuery('(max-width:600px)');
  function formatDate(dateString) {
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    const formattedDate = new Date(dateString).toLocaleDateString(undefined, options);
    return formattedDate;
  }
  const htmlContent = blog.description;
  const plainText = convertHtmlToPlainText(htmlContent);
  const description = plainText;
  const sentences = description.split(/(?<=\. )/);
  const date = new Date();
  const formattedDate = formatDate(date);
  
  const formattedText = sentences.map((sentence, index) => (
    <React.Fragment key={index}>
      <span style={{fontFamily:'Poppins'}}>{sentence}</span>
      {index !== sentences.length - 1 && <br />}
      <br />
    </React.Fragment>
  ));
  return (
    <>
      <div className={classes.body}>
        {loading ? (
          <CircularProgress style={loader} size={45} />
        ) : (
          <div 
          style={{
            marginTop:'80px',
            display: "flex",
            flexDirection: "column",
            justifyContent: isLMobile?"flex-start":"center",
            alignItems: "center",
          }}
          
          >
          <SEO 
            title={blog.title}
            description={blog.description.replace(/<[^>]*>?/gm, '')}
            name={'Virtual Startup Campus'}
            type="Blog"
          />
          <div style={{display:'flex' , justifyContent:'center' , flexDirection:'column' , marginLeft:!isLMobile?'20%':isMobile?'1%':'5%'}}>          
          <Box display="flex" flexDirection="column" justifyContent="flex-start" alignItems="flex-start" width={isMobile?'100%':'50%'} height="50%">
          <IconButton style={{fontSize:'15px' , color:'#6A58c6' , fontFamily:'Poppins' , marginBottom:'20px'}}>
          <ArrowBackIosIcon/><a href="/blog" style={{color:'#6A58c6'}}>Back To Blog</a> 
          </IconButton>
          <Typography variant="caption" style ={{ marginBottom:'20px'}}>{formattedDate}</Typography>
          <Typography variant="h4" className={classes.heading} style={{marginBottom:'20px'}}>{blog.title}</Typography>
          <Box display="flex"  flexDirection="row" justifyContent="center" alignItems="center" marginBottom='20px'>
          <Avatar
          src={favicon}
          sx={{ width: 45, height: 45 }}
          />
          <Typography variant="h6" style={{ marginLeft: '5px', marginBottom: '1px' , padding:0}}>
          <Box display="flex" flexDirection="column" justifyContent="flex-start"> 
            Virtual Startup Campus
            <Box display="flex" justifyContent="flex-start" alignItems="flex-start" >
              <a href="/" style={{ textDecoration: 'underline', color: '#000', fontSize: '10px', margin: '0' }}>
                @vsc
              </a>
            </Box>
          </Box>
        </Typography>
          </Box>
          </Box>
          
          <Box className={classes.imgBox}>
                <img
                  src={IMAGE+blog.imagePath}
                  alt="..."
                  className={classes.img}
                  style={{width:isMobile&&'100%'}}
                ></img>
                
                
            </Box>
            <Typography className={classes.subhead} style={{ fontSize:'25px', width:isMobile&&'100%'}}>
                  {blog.subTitle}
              </Typography>
            <Box style={{width:!isMobile?'50%':'100%'}}    >
              <Box className={classes.box2}>
              <Box className={classes.descriptionBox} style={{ fontSize:'18px' , fontFamily:'Poppins' , color:'#000'}}>
                <div dangerouslySetInnerHTML={{ __html: htmlContent }}/>
              </Box>
            </Box>
            </Box>
            </div>
            <Box style={{margin: '20px'}}>
              <CompletedButton bid = {id}/>
            </Box>
            <Box>
            <BlogRecomendations id = {id}/>
            </Box>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
}

const loader = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  color: "#000",
};
const u = makeStyles({
  box: {
    padding: "10px",
    borderRadius: "10px",
    marginTop: "25px",
    marginBottom: "25px",
    display: "flex",
    "@media only screen and (max-width: 1200px)": {
      width: "90vw",
      marginTop: "15px",
    },
    "@media only screen and (max-width: 726px)": {
      flexDirection: "column",
    },
  },
  textBox: {
    display: "block",
    width: "60%",
    fontFamily:
      "source-serif-pro, Georgia, Cambria, Times New Roman, Times, serif",
    fontSize: "20px",
    color: "#EDEDF1",
    "@media only screen and (max-width: 726px)": {
      width: "100%",
      marginBottom: "1rem",
    },
  },
  imgBox: {
    objectFit: "cover",
    width: "50%",
    height: "50%",
    margin:'3%',
    "@media only screen and (max-width: 726px)": {
      width: "100%",
      marginBottom: "1rem",
    },
  },
  img: {
    height: "100%",
    width: "100%",
    borderRadius: "10px",
  },
  body: {
    marginTop:'30px',
    paddingTop: "20px",
    paddingBottom: "50px",
    width: "100%",
    minHeight: "100vh"
  },
  container: {
    marginTop:'80px',
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "@media only screen and (max-width: 1200px)": {
      marginRight: "10px",
      marginLeft: "10px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "center",
    },
  },
  heading: {
    textAlign: "left",
    fontWeight: "bold",
    fontFamily:"Poppins",
    fontSize: "28px",
    padding: "3px 5px 3px 5px",
    // backgroundColor: "#6A58c6",
    // width: "90%",
    alignItems: "center",
    wordWrap: "break-word",
    marginBottom: "30px",
    borderRadius: "8px",
    whiteSpace: "initial",
    "@media only screen and (max-width: 726px)": {
      marginLeft: "0px",
      width: "100%",
      borderRadius: "5px",
    },
  },
  subhead: {
    wordWrap: "break-word",
    paddingTop: "10px",
    width:'50%',
   
  },

  box2: {
    // width: "77vw",
    // backgroundColor: "#6A58c6",
    padding: "5px",
    borderRadius: "10px",
    marginTop: "25px",
    marginBottom: "25px",
    display: "flex",
    // border: "1px solid red",
    "@media only screen and (max-width: 1200px)": {
      width: "90vw",
      marginTop: "15px",
    },
    "@media only screen and (max-width: 726px)": {
      flexDirection: "column",
    },
  },
  descriptionBox: {
    display: "block",
    width: "100%",
    fontFamily:"Poppins",
    fontSize:'18px',
    // border: "1px solid red",
    "@media only screen and (max-width: 726px)": {
      width: "100%",
      marginBottom: "1rem",
    },
  },
  description: {
    fontFamily: "source-serif-pro, Georgia, Cambria, Times, serif",
    // fontSize: "20px",
    // lineHeight: "32px",
    // color: "#ededf1",
    // fontWeight: "400",
    wordWrap: "break-word",
    paddingTop: "10px",
  },
});


export default BlogPage;

