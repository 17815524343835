export const components = [
    {name: 'Dashboard' , url: 'dashboard'},
    {name: 'Resources' , url: 'resources'},
    {name: 'My Certificate' , url: 'earnedcertificates'},
    {name: 'My Courses' , url: 'courses'},
    {name: 'All Courses' , url: 'allcourses'},
    {name: 'Leaderboard' , url: 'leaderboard'},
    {name: 'Redeem' , url: 'redeem'},
    {name: 'Campus Ambassador' , url:'campusambassador'},
    {name: 'My Profile' , url: 'profile'},
    {name: 'Refferal' , url : 'refferal'},
]