import React from 'react';
import './ProjectParticipate.css';

const PersonDetailsForm = ({ personNumber, person, handleInputChange }) => {
  return (
    <div>
      <h2>Person {personNumber} Details</h2>
      <div className="form-group">
        <label htmlFor={`person${personNumber}Name`}>Name:</label>
        <input
          type="text"
          id={`person${personNumber}Name`}
          name="name"
          value={person.name || ''}
          onChange={handleInputChange}
          required
        />
      </div>

      <div className="form-group">
        <label htmlFor={`person${personNumber}College`}>College:</label>
        <input
          type="text"
          id={`person${personNumber}College`}
          name="college"
          value={person.college || ''}
          onChange={handleInputChange}
          required
        />
      </div>
      <div className="form-group">
        <label htmlFor={`person${personNumber}Course`}>Course:</label>
        <input
          type="text"
          id={`person${personNumber}Course`}
          name="course"
          value={person.course || ''}
          onChange={handleInputChange}
          required
        />
      </div>

      <div className="form-group">
        <label htmlFor={`person${personNumber}Email`}>Email(Markoknow Email):</label>
        <input
          type="email"
          id={`person${personNumber}email`}
          name="email"
          value={person.email || ''}
          onChange={handleInputChange}
          required
        />
      </div>

      <div className="form-group">
        <label htmlFor={`person${personNumber}whatsapp`}>WhatsApp Number:</label>
        <input
          type="number"
          id={`person${personNumber}whatsapp`}
          name="whatsapp"
          value={person.whatsapp || ''}
          onChange={handleInputChange}
          required
        />
      </div>

      <div className="form-group">
        <label htmlFor={`person${personNumber}linkedin`}>LinkedIn Id:</label>
        <input
          type="text"
          id={`person${personNumber}linkedin`}
          name="linkedin"
          value={person.linkedin || ''}
          onChange={handleInputChange}
          required
        />
      </div>

    </div>
  );
};

export default PersonDetailsForm;
