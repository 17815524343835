import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import { useNavigate } from 'react-router-dom';
import png from '../Images/bmc.png';
import {
    DELETE_DATA,
    GET_COMPLETE_VIDEOS,
    GET_DATA,
    POSTER,
  } from "../../../api/watchnow";
import { CardActions , Tooltip } from '@mui/material';

export default function WatchnowRecomendations({poster , title , speaker , id , link , watchnow}) {
  const theme = useTheme();
    const navigate = useNavigate();
  return (
    <Card sx={{ display: 'flex' ,borderRadius:'20px' , boxShadow: 'rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset' }}>
    <CardMedia
        component="img"
        sx={{ width: 180  }}
        image={POSTER+poster}
        // image={png}
        alt={title}
      />
      <Box sx={{ display: 'flex', flexDirection: 'column'  }}>
        <CardContent sx={{ flex: '1 0 auto' }}>
          <Typography component="div" variant="h6" style={{fontSize:'16px'}}>
            {title}
          </Typography>
          <Typography variant="subtitle3" color="text.secondary" component="div" style={{fontSize:'12px'}}>
            {speaker}
          </Typography>
          <CardActions style={{display:'flex' , justifyContent:'flex-end' , width:'100%'}}>
        <Tooltip title="Play">
        <IconButton
                onClick={() =>{
                        navigate("/watch_now/watch", { state: { link:link  , id: id , watchnow: watchnow} })
                        }}
            ><PlayCircleIcon/></IconButton>
        </Tooltip>
            
        </CardActions>
        </CardContent>
        
      </Box>
      
    </Card>
  );
}
