import React, { useState, useRef, useEffect } from 'react';
import './PopupChat.css';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Tooltip } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import ChatIcon from '@mui/icons-material/Chat';
import axios from 'axios';
import { SEND_EMAIL } from '../../api/popupchat';

const PopupChat = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [messages, setMessages] = useState([`Hey ${window.localStorage.name || 'Guest'} `, "Write Your Query?"]);
  const [newMessage, setNewMessage] = useState('');
  const [showMessage, setShowMessage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState(window.localStorage.name || '');
  const [email, setEmail] = useState(window.localStorage.email || '');
  const [whatsapp, setWhatsapp] = useState(window.localStorage.whatsapp || '');
  const [isNameEmailRequired, setIsNameEmailRequired] = useState(!window.localStorage.name || !window.localStorage.email || !window.localStorage.whatsapp);
  const chatRef = useRef(null);

  const toggleChat = () => {
    setIsOpen(!isOpen);
  };

  const handleInputChange = (e) => {
    setNewMessage(e.target.value);
  };

  const handleSendMessage = () => {
    if (newMessage.trim() !== '') {
      if (!name.trim() || !email.trim() || !whatsapp.trim()) {
        setMessages([...messages, "Please provide your name, email, and WhatsApp number to continue."]);
        setIsNameEmailRequired(true);
        return;
      }
      setIsLoading(true);
      axios.post(SEND_EMAIL, {
        name: name,
        email: email,
        whatsapp: whatsapp,
        chat: newMessage
      })
        .then(res => {
          if (res.data.message === 'Successful') {
            setShowMessage(true);
          }
          setMessages([...messages, newMessage]);
          setNewMessage('');
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const handleClickOutside = (event) => {
    if (chatRef.current && !chatRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  const handleNameEmailSubmit = () => {
    if (name.trim() && email.trim() && whatsapp.trim()) {
      window.localStorage.setItem('name', name);
      window.localStorage.setItem('email', email);
      window.localStorage.setItem('whatsapp', whatsapp);
      setMessages([`Hey ${name}`, "Write Your Query?"]);
      setIsNameEmailRequired(false);
    }
  };

  return (
    <div className={`popup-chat ${isOpen ? 'open' : ''}`} ref={chatRef}>
      <Tooltip title={isOpen ? "Close" : "Chat"}>
        <IconButton className="toggle-button" style={{ background: !isOpen && '#6A58C6' }} onClick={toggleChat}>
          {isOpen ? <CloseIcon /> : <ChatIcon style={{ color: '#fff' }} />}
        </IconButton>
      </Tooltip>

      {isOpen && (
        <div className="chat-content">
          <div className="message-container">
            {messages.map((message, index) => (
              <div key={index} className="message">
                {message}
              </div>
            ))}
          </div>
          {isLoading ? (
            <div style={{ display: 'flex', justifyContent: 'center', height: '50px' }}>
              <div className="loader"></div>
            </div>
          ) : (
            showMessage ? (
              <div className="message">
                Your Query Is Received You Would Be Replied Back Within 48 hours<br />
                Thank You<br />
                Virtual Startup Campus.
              </div>
            ) : (
              isNameEmailRequired ? (
                <div style={{display: 'flex'}}>
                <div className="input-container" style={{flexDirection: 'column'}}>
                  <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Enter your name..."
                    style={{marginBottom: 2}}
                  />
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Enter your email..."
                    style={{marginBottom: 2}}
                  />
                  <input
                    type="text"
                    value={whatsapp}
                    onChange={(e) => setWhatsapp(e.target.value)}
                    placeholder="Enter your WhatsApp number..."
                  />
                </div>
                <div >
                <IconButton onClick={handleNameEmailSubmit}><SendIcon sx={{color: '#6a58c6'}}/></IconButton>
                </div>
                </div>
              ) : (
                <div className="input-container">
                  <input
                    type="text"
                    value={newMessage}
                    onChange={handleInputChange}
                    placeholder="Type your message..."
                  />
                  <IconButton onClick={handleSendMessage}><SendIcon /></IconButton>
                </div>
              )
            )
          )}
        </div>
      )}
    </div>
  );
};

export default PopupChat;
