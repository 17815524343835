// RenderMultilineText.js

import React from 'react';

const RenderMultilineText = ({ text }) => {
  // Check if text is null or undefined before processing
  if (text == null) {
    return null; // or return an empty string, depending on your use case
  }

  return (
    <div
      style={{ color: 'rgb(84, 84, 84)' }}
      dangerouslySetInnerHTML={{ __html: text}}
    />
  );
};

export default RenderMultilineText;
